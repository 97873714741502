import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { editRoleNumber } from "../../redux/Role/RoleInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";

const RoleList = ({
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    editRoleId,
    addTokenToState,
}) => {
    // Check Token validity & fetch new token if expired

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getRoles();
    }

    const history = useHistory();

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    async function checkToken() {
        // console.log("Checking token");
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getRoles() {
        var data = await fetchData({ requestingPage: "roleList", method: "get", url: "fetch/role", headers: { token: isLogged.accessToken,module: "Role" } });
        if (data.msg === "success") {
            setRoleList(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            // console.log(data);
        }
        setLoading(false);
    }

    const [roleList, setRoleList] = useState([]);

    const renderRoleList = () => {
        return roleList.map((role) => {
            return (
                <tr className="roleList" key={role.role_id}>
                    <td className="roleName">{role.role}</td>
                    <td className="roleAction">
                        <img
                            className="roleEditImage"
                            src={role.role_id === 1 ? editIconDisabled : isLogged.access.Role === "Edit" ? editIcon : editIconDisabled}
                            alt="Edit Role"
                            onClick={() => {
                                if (role.role_id !== 1 && isLogged.access.Role === "Edit") {
                                    editRoleId(role.role_id);
                                    setSection("editRole");
                                }
                            }}
                            style={
                                role.role_id === 1
                                    ? { cursor: "not-allowed" }
                                    : isLogged.access.Role === "Edit"
                                    ? { cursor: "pointer" }
                                    : { cursor: "not-allowed" }
                            }
                            title={
                                role.role_id === 1
                                    ? "Can't edit Admin Role name"
                                    : isLogged.access.Role === "Edit"
                                    ? "Edit Role Name"
                                    : "No Edit Access"
                            }
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <div className="rolePagesContainer">
            <div className="roleListArea">
                <table className="roleListTable">
                    <thead>
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Role Name</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {roleList.length > 0 ? renderRoleList() : null}
                    </tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editRoleId: (roleId) => dispatch(editRoleNumber(roleId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
