import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { useHistory } from "react-router-dom";
import FormElement from "../../SmallComponents/FormElement";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import { addToCustomerLedgerParams } from "../../../redux/Store/StoreInfoActions";

const CustomerLedgerParams = ({ isLogged, addTokenToState, setCurrentSection, addToCustomerLedgerParams }) => {

  const [customerLedgerParams, setCustomerLedgerParams] = useState({
    assignee: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Assignee",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Assignee",
      mandatory: true,
      disable:false

    },
    customerId: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Available Customers",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A customer",
      mandatory: false,
      disable:false
    },
    parentPlantId :{
      inputType: "options",
      value: "",
      options: [],
      hintText: "Parent Plant",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Parent Plant",
      mandatory: false,
      disable:false
    },

    plantId: {
      inputType: "multiSelect",
      hintText: "Plant Name",
      value: [],
      options: isLogged.plantOptions,
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Plant Name",
      mandatory: true,
      disable:false
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "From Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select a From Date Range",
      mandatory: true,
      disable:false
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select a To Date Range",
      mandatory: true,
      disable:false
    }
  });

  const [customerDetails, setCustomerDetail] = useState([])

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    var customerLedgerParamsCopy = Object.assign(customerLedgerParams);
    if (customerLedgerParams.assignee.value) {
      //filtering customers based on assignee choosed
      let customerList =[];       

      customerLedgerParams.assignee.value.forEach((assignee)=>{
      customerDetails.forEach((item) => {
        if(assignee.value===-1){
          customerList=customerDetails;
        }else{

          if (item.customer.assignedEmployees.includes(assignee.value)) {
            customerList.push(item);          
          }
        }
        })
      })
      customerList.slice(0,0,{optionId:-1,optionName:"All"})


      //assigned filtered customers into customers drop down input
      customerLedgerParamsCopy = update(customerLedgerParamsCopy, {
        customerId: { options: { $set: customerList
          // customerDetail 
        } },
      });

    } else {
      customerLedgerParamsCopy = update(customerLedgerParamsCopy, {
        customerId: { options: { $set: [] }, value: {$set:""} },
      });
    }
    setCustomerLedgerParams(customerLedgerParamsCopy);
  }, [customerLedgerParams.assignee.value])

  async function getInformation() {
    await checkToken();
    let parentCusList = await getParents();
    await getEmployees(parentCusList);
    await getCustomers();
  }

  const history = useHistory();

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getParents() {
    var data = await fetchData({
        requestingPage: "getParents",
        method: "get",
        url: "fetch/customer-list",
        headers: { token: isLogged.accessToken ,module:"Customer"},
    });
    if (data.msg === "success") {
        var parentOptions = [];
        data.asset.forEach((parent) => {
            var newParent = { optionId: parent.customerId, optionName: parent.customer };
            parentOptions.push(newParent);
        });
        return parentOptions;
    }
}

  async function getEmployees(parentCusList) {
    var data = await fetchData({
      requestingPage: "employeeList",
      method: "POST",
      url: "fetch/employees",
      headers: { token: isLogged.accessToken, module: "Employee" },
    });
    if (data.msg === "success") {
      if(data.asset.length){
        data.asset.splice(0,0,{optionId:-1,optionName:"All"})

      }
      var customerLedgerParamsCopy = Object.assign(customerLedgerParams);
      customerLedgerParamsCopy = update(customerLedgerParamsCopy, {
        assignee: { options: { $set: data.asset } },
        parentPlantId : {options:{$set :parentCusList}}
      });

      console.log(customerLedgerParamsCopy.assignee,"assignee")
      setCustomerLedgerParams(customerLedgerParamsCopy);

    } else {
      return [];
    }
  }

  async function getCustomers() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: "fetch/customers",
      headers: { token: isLogged.accessToken, module: "Customer" },
    });
    if (data.msg === "success") {
      setCustomerDetail(data.asset)
    } else {
      return [];
    }
  }

  const updateParameter = (element, key, value) => {
    console.log(element,value,"value")
    let ledgerCopy = Object.assign(customerLedgerParams);
    if(element ==="customerId" ){
 
        ledgerCopy = update(ledgerCopy, {
          parentPlantId: { disable: { $set: value.length?true:false } },
        });
    }
    if(element === "parentPlantId"){
        ledgerCopy = update(ledgerCopy, {
          customerId: { disable: { $set: value?true:false } },
        });
    }

    ledgerCopy = update(ledgerCopy, {
      [element]: { [key]: { $set: value } },
    });
    setCustomerLedgerParams(ledgerCopy);
  };

  const [customerLedgerError, setCustomerLedgerError] = useState([]);
  const checkCustomerLedgerError = () => {
    var errorList = [];
    var paramsCopy = Object.assign(customerLedgerParams);
    let queryParams = {}
    Object.keys(paramsCopy).forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } })
      }
        if(item ==="assignee" || item ==="customerId" || item === "plantId"){
          let list = paramsCopy[item].value.map(item=>item.value)
          if(list.includes(-1)){
            list = paramsCopy[item].options.map(item=>item.optionId)
          }
            
          queryParams[item] =list
      
        }
        else{
          queryParams[item] = paramsCopy[item].value
        }
      // }
      if (paramsCopy[item].error)
        errorList.push(paramsCopy[item].errorMessage);
    })
    if (errorList.length === 0) {
      addToCustomerLedgerParams(queryParams);
      setCurrentSection('customerLedgerList');
    } else {
      setCustomerLedgerError(errorList)
    }
  };

  const renderFormElements = ({ elementList, param }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                setInput={(value) => updateParameter(element, "value", value)}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options"||param[element].inputType === "multiSelect"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                key={ind}
                disabled={param[element].disable}
              />
            </span>
          );
        })}
        <FormElement
          inputType="addButton"
          value="Submit"
          colSpan={5}
          boxWidth="80px"
          setInput={() => {
            setCustomerLedgerError([]);
            checkCustomerLedgerError();
          }}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="formArea" style={{ marginTop: "0", padding: "0" }}>
        <div
          style={{
            width: "1000px",
            height: "500px",
            margin: "0 2rem 4rem",
            padding: "1rem",
            border: "1px solid inherit",
            borderRadius: "5px",
            backgroundColor: "inherit",
          }}>
          {/* <div style={{ display:"inline-flex",maxWidth: "inherit", marginLeft: "25px" }} className="purchaseOrderGrid"> */}
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            {renderFormElements({
              elementList: ["assignee", "customerId", "plantId","parentPlantId","fromDate", "toDate"],
              param: customerLedgerParams,
            })}
          </div>
          <p className="formSubmitErrorTextArea" style={{ textAlign: "center", fontSize: "16px" }}>
            {customerLedgerError[0]}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    addToCustomerLedgerParams: (value) => dispatch(addToCustomerLedgerParams(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLedgerParams);
