import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import {
  editProductionOrderId,
  setProductionOrderTab,
  viewProductionOrderId,
} from "../../redux/PurchaseOrder/PoInfoActions";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FormElement from "../SmallComponents/FormElement";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import Pagination from "../CommonFunctions/pagination";

const ProductionList = ({
  setSection,
  setApproval,
  // state
  isLogged,
  poInfo,

  // dispatch
  setProductionOrderTab,
  addTokenToState,
  viewProductionOrderId,
  editProductionOrderId,
}) => {
  const [productionOrderList, setProductionOrderList] = useState([]);
  const [approvedProductionOrderList, setApprovedProductionOrderList] =
    useState([]);
  const [currentPage,setCurrentPage]=useState(1);
  const [PageSize,setRowsPerPage]=useState(10);
  const [loading, setLoading] = useState(true);
  const [noPoRecords, setNoPoRecords] = useState(false);
  const [noPoApprovedRecords, setNoPoApprovedRecords] = useState(false);
  const [plantId, setPlantId] = useState("");
  const ProductionUnApprovedCurrentPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return productionOrderList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage,productionOrderList,PageSize]);
  const ProductionApprovedCurrentPage = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return approvedProductionOrderList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage,approvedProductionOrderList,PageSize]);
  useEffect(() => {
    getInformation();
  }, []);

  async function getInformation() {
    await checkToken();
    await getProductionOrdersList();
  }

  const history = useHistory();
  async function checkToken() {

    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
     
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getProductionOrdersList() {
    var data = await fetchData({
      requestingPage: "productionList",
      method: "post",
      url: "production-fetch/request-unapproved-list",
      headers: { token: isLogged.accessToken, module: "Production Order" },
      data: {plantId}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoPoRecords(true);
      }

      var data2 = [];
      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });

      setProductionOrderList(data2);
    }
    setLoading(false);
  }

  async function getApprovedProductionOrdersList() {
    var data = await fetchData({
      requestingPage: "productionList",
      method: "post",
      url: "production-fetch/request-approved-list",
      headers: { token: isLogged.accessToken, module: "Production Order" },
      data: {plantId}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoPoApprovedRecords(true);
      }

      var data2 = [];
      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });

      setApprovedProductionOrderList(data2);
    }
    setLoading(false);
  }

  const tablist = [
    { id: "productionOrderList", name: "Production Order List" },
    {
      id: "approvedProductionOrderList",
      name: "Approved Production Order List",
    },
  ];

  const RenderTabList = ({ tablist }) => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            poInfo.productionOrderTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={() => setProductionOrderTab(tab.id)}>
          {tab.name}
        </div>
      );
    });
  };

  useEffect(async () => {
    setLoading(true);
    await checkToken();

    switch (poInfo.productionOrderTab) {
      case "productionOrderList":
        setProductionOrderList([]);
        getProductionOrdersList();
        break;

      case "approvedProductionOrderList":
        setApprovedProductionOrderList([]);
        getApprovedProductionOrdersList([]);
        break;

      default:
        break;
    }
  }, [poInfo.productionOrderTab,plantId]);

  const editItemCollapse = (list, index) => {
    var listCopy = [];

    switch (list) {
      case "productionOrderList":
        listCopy = productionOrderList.slice();
        break;

      case "approvedProductionOrderList":
        listCopy = approvedProductionOrderList.slice();
        break;
    }

    listCopy.forEach((item, j) => {
      if (j === index) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });

    switch (list) {
      case "productionOrderList":
        setProductionOrderList(listCopy);

      case "approvedProductionOrderList":
        setApprovedProductionOrderList(listCopy);
        break;
    }
  };
  const access = isLogged.access["Production Order"];
  const renderPoList = () => {
    return ProductionUnApprovedCurrentPage.map((item, i) => {
        return (
          <React.Fragment>
            <tr
              className="vendorList"
              key={item.requestNo}
              onClick={(e) => {
                e.stopPropagation();
                editItemCollapse("productionOrderList", i);
              }}>
              <td className="vendorListInfo stickyFirstColumn">{item.requestNo}</td>
              <td className="vendorListInfo">{item.soNo || "Make To Stock"}</td>
              <td className="vendorListInfo">{item.requestType}</td>

              <td className="vendorListInfo">{item.expectDate}</td>
              <td className="vendorListInfo">{item.createdBy}</td>

              <td className="vendorListInfoActions">
                <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Production Request"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSection("productionOrderDetail");
                    setApproval(false);
                    viewProductionOrderId(item.requestId);
                  }}
                />

                <img
                  alt="Approve Record"
                  className="vendorActionImages"
                  src={approveIcon}
                  title={
                    access === "App/Dec"
                      ? "Approve Production Request"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "App/Dec") {
                      setSection("productionOrderDetail");
                      setApproval(true);
                      viewProductionOrderId(item.requestId);
                    }
                  }}
                  style={
                    access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />

                <img
                  alt="Edit Record"
                  className="vendorActionImages"
                  src={
                    access === "Edit" || access === "App/Dec"
                      ? editIcon
                      : editIconDisabled
                  }
                  title={
                    access === "Edit" || access === "App/Dec"
                      ? "Edit Production Request"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "Edit" || access === "App/Dec") {
                      setSection("editProductionOrder");
                      editProductionOrderId(item.requestId);
                    }
                  }}
                  style={
                    access === "Edit" || access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />

                <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={item.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse("productionOrderList", i);
                  }}
                />
              </td>
            </tr>
            <tr>
              {!item.collapse ? (
                <RenderSubTable tab="unapprovedSo" data={item.lineItems} />
              ) : null}
            </tr>
          </React.Fragment>
        );
    });
  };

  const renderApprovedPoList = () => {
    return ProductionApprovedCurrentPage.map((item, i) => {
        return (
          <React.Fragment>
            <tr
              className="vendorList"
              key={item.requestNo}
              onClick={(e) => {
                e.stopPropagation();
                editItemCollapse("approvedProductionOrderList", i);
              }}>
              <td className="vendorListInfo stickyFirstColumn">{item.requestNo}</td>
              <td className="vendorListInfo">{item.soNo || " "}</td>
              <td className="vendorListInfo">{item.requestType}</td>

              <td className="vendorListInfo">{item.expectDate}</td>
              <td className="vendorListInfo">{item.createdBy}</td>
              <td className="vendorListInfo">{item.approvedBy}</td>
              <td className="vendorListInfoActions">
                <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Invoice Detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setApproval(false);
                    setSection("productionOrderDetail");
                    viewProductionOrderId(item.requestId);
                  }}
                />

                <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={item.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse("approvedProductionOrderList", i);
                  }}
                />
              </td>
            </tr>
            <tr>
              {!item.collapse ? (
                <RenderSubTable tab="unapprovedSo" data={item.lineItems} />
              ) : null}
            </tr>
          </React.Fragment>
        );
    });
  };

  const RenderSubTable = ({ tab, data, reason }) => {
    return (
      <td
        colSpan={tab === "unapprovedSo" ? "7" : "8"}
        className="vendorListInnerTableWrapper">
        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
              <td className="vendorListInnerTableHeading">Item Code</td>
              {isLogged.showHideWeight ? (
                <>
                  {" "}
                  <td className="vendorListInnerTableHeading">
                    Weight From(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Weight To(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Net Weight(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Gross Weight(gms)
                  </td>{" "}
                </>
              ) : null}
              <td className="vendorListInnerTableHeading">HSN Code</td>
              <td className="vendorListInnerTableHeading">UOM</td>
              <td className="vendorListInnerTableHeading">Production Quantity</td>
              <td className="vendorListInnerTableHeading">DC Quantity</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr
                  className="vendorListInnerTable"
                  key={row.invoiceLineItemId}>
                  <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                  <td className="vendorListInnertableInfo">{row.itemCode}</td>
                  {isLogged.showHideWeight ? (
                    <>
                      <td className="vendorListInnertableInfo">
                        {row.from ? row.from : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.to ? row.to : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.netWeight ? row.netWeight : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.grossWeight ? row.grossWeight : " - "}
                      </td>{" "}
                    </>
                  ) : null}
                  <td className="vendorListInnerTableInfo">
                    {row.hsnCode ? row.hsnCode : " - "}
                  </td>
                  <td className="vendorListInnertableInfo">{row.uom}</td>
                  <td className="vendorListInnertableInfo">{row.quantity}</td>
                  <td className="vendorListInnertableInfo">{row.dcQuantity}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    );
  };
  const [order, setOrder] = useState({ col: "requestNo", state: true, data: "" });

  const CommonColumns=({data})=>{
    return(
        <>
        <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "requestNo",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "requestNo",
                        state: order.state,
                      });
                    }}>Request No<img
                    className="sortArrow"
                    src={
                         order.col === "requestNo"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                    <td className="vendorListHeading" onClick={(e)=>{
                      setOrder({col:"soNo",
                    state: !order.state,});
                    handleSort({data, col: "soNo",
                  state: order.state});
                    }}>
                      SO No
                      <img className="sortArrow" src={order.col==="soNo" ? order.state ? SortUpGreen : SortDownGreen : null}/>
                    </td>
        <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "requestType",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "requestType",
                        state: order.state,
                      });
                    }}>Request Type<img
                    className="sortArrow"
                    src={
                         order.col === "requestType"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
        <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "expectedDate",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "expectedDate",
                        state: order.state,
                      });
                    }}>Expected Date<img
                    className="sortArrow"
                    src={
                         order.col === "expectedDate"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
        <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>Created by<img
                    className="sortArrow"
                    src={
                         order.col === "createdBy"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
    </>
    )
  }

  const renderSubSection = () => {
    switch (poInfo.productionOrderTab) {
      case "productionOrderList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader"  style={{zIndex:1}}>
              <CommonColumns data={ProductionUnApprovedCurrentPage}/>
              <td className="vendorListHeadingActions">Actions</td>
              </tr>
              </thead>
              <tbody>
                {productionOrderList.length > 0 ? renderPoList() : null}
              </tbody>
            </table>
            {noPoRecords ? (
              <div className="noRecordsYet">No items available</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
            <Pagination 
             className="pagination-bar"
             currentPage={currentPage}
             totalCount={productionOrderList.length}
             pageSize={PageSize}
             onPageChange={page => setCurrentPage(page)}/>
          </React.Fragment>
        );

      case "approvedProductionOrderList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
             <thead>
              <tr className="vendorListHeader"  style={{zIndex:1}}>
             <CommonColumns data={ProductionApprovedCurrentPage}/>
             <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "aprovedBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: ProductionApprovedCurrentPage ,
                        col: "approvedBy",
                        state: order.state,
                      });
                    }}>Approved By<img
                    className="sortArrow"
                    src={
                         order.col === "approvedBy"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
             <td className="vendorListHeadingActions">Actions</td>
              </tr>
              </thead>
              <tbody>
                {approvedProductionOrderList.length > 0
                  ? renderApprovedPoList()
                  : null}
              </tbody>
            </table>
            {noPoApprovedRecords ? (
              <div className="noRecordsYet">No items available</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
            <Pagination 
             className="pagination-bar"
             currentPage={currentPage}
             totalCount={approvedProductionOrderList.length}
             pageSize={PageSize}
             onPageChange={page => setCurrentPage(page)}
            />
          </React.Fragment>
        );
    
      default:
        return null;
      }
  };

  return (
    <React.Fragment>
      <div className="poListTabs">
        <RenderTabList tablist={tablist} />
      </div>
      <div style={{ display:"inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setPlantId(value);
          }}
          inputType={"options"}
          value={plantId}
          hintText={"Plant Name"}
          options={isLogged.plantOptions}
          colSpan={1}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
      </div>
      <div className="vendorListArea">{renderSubSection()}</div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    setProductionOrderTab: (tab) => dispatch(setProductionOrderTab(tab)),
    viewProductionOrderId: (productionId) =>
      dispatch(viewProductionOrderId(productionId)),
    editProductionOrderId: (productionId) =>
      dispatch(editProductionOrderId(productionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionList);
