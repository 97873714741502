import React, { useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import statementIcon from "../../../assets/statement_mono.svg";
import { useHistory } from "react-router-dom";
import OutStandingParams from "./outStandingStatementParams";
import OutStandingStatementList from "./outStandingStatementList";
import { exportPDF } from "../../CommonFunctions/ExportPDF";

const OutStandingStatement=()=>{
    const [currentSection, setCurrentSection] = useState("outStandingStatement");
    const history = useHistory();
    
    var goBackButton = (props)=>
      ({
        buttonName: "Back",
        className: "goBackButton",
        ...props
      });
  
    var downloadButton = (props) =>
      ({
        buttonName: "Download",
        className: "exportButton",
        ...props
      });

    const renderSection = (section) => {
      switch (section) {
        case "outStandingStatement":
          return (
            <React.Fragment>
              <PageTitle
                imgSrc={statementIcon}
                pageTitle={"OutStanding Statement"}
                buttonList={[goBackButton( {setSection: (route)=>history.push(route),sectionName: "/salesreport"})]}
              />
              <OutStandingParams setCurrentSection={setCurrentSection}/>
            </React.Fragment>
          );
  
        case "outStandingStatementList":
          return(<React.Fragment>
            <PageTitle 
            imgSrc={statementIcon}
            pageTitle={"Outstanding Statement List"}
            buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"outStandingStatement"}),
              downloadButton( {setSection: async () => { await exportPDF({}, "outstandingStatement") },sectionName: "outstandingStatement"})]}
            />
            <OutStandingStatementList setCurrentSection={setCurrentSection}/>
          </React.Fragment>)
          default :return null
      }
    };
 
    return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Reports" selectedSubMenu="Sales Report" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
    )
}


export default OutStandingStatement;