import React from "react";
import Modal from "react-modal";

const CreateEditModal = ({ modalIsOpen, dataSubmitted, submitOkClick, modalText }) => {
    // console.log(modalIsOpen, dataSubmitted, submitOkClick, modalText);

    Modal.setAppElement("body");

    return (
        <Modal
            isOpen={modalIsOpen}
            contentLabel="Example Modal"
            style={{
                overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                content: {
                    textAlign: "center",
                    width: "30%",
                    height: "15%",
                    position: "fixed",

                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",

                    // borderRadius: "5px",
                    boxShadow: "0 0 5px gray",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                },
            }}
        >
            {dataSubmitted ? null : <div className="loader" />}
            <div style={{ lineHeight: "2rem", fontSize: "20px", marginBottom: "1rem" }}>{modalText}</div>
            {dataSubmitted ? (
                <button className="modalButton" onClick={submitOkClick}>
                    Ok
                </button>
            ) : null}
        </Modal>
    );
};

export default CreateEditModal;
