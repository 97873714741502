import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import fetchData from "../../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import editIcon from "../../../assets/edit.svg";
import { editItemTypeId } from "../../../redux/Item/ItemInfoActions";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const EditItemType = ({
    setSection,

    // state
    isLogged,
    itemInfo,

    // dispatch
    editItemType,
    addTokenToState,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getRoles();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getRoles() {
        var data = await fetchData({
            requestingPage: "itemTypeList",
            method: "get",
            url: "fetch/item-types",
            headers: { token: isLogged.accessToken,module:"Item Type" },
        });
        if (data.msg === "success") {
            setItemTypeList(data.asset);
            setNewItemType(data.asset.filter((i) => i.itemTypeId === itemInfo.editItemTypeId)[0].itemTypeName);
        } else {
            console.log(data);
        }
    }

    const [itemTypeList, setItemTypeList] = useState([]);

    const renderItemTypeList = () => {
        return itemTypeList.map((itemType) => {
            return itemType.itemTypeId !== itemInfo.editItemTypeId ? (
                <tr className="roleList" key={itemType.itemTypeId}>
                    <td className="roleName">{itemType.itemTypeName}</td>
                    <td className="roleAction">
                        <img
                            className="roleEditImage"
                            src={editIcon}
                            alt="Edit Role"
                            onClick={() => {
                                editItemType(itemType.itemTypeId);
                                setNewItemType(itemType.itemTypeName);
                                setSection("editItemType");
                            }}
                        />
                    </td>
                </tr>
            ) : (
                <tr className="roleList">
                    <td className="createRole">
                        <input
                            className="createRoleInput"
                            value={newItemType}
                            onChange={(e) => setNewItemType(e.target.value)}
                            autoFocus
                            onKeyUp={(e) => {
                                e.preventDefault();
                                if (e.key === "Enter" || e.keyCode === 13) {
                                    var submit = document.querySelector("#SubmitCreate");
                                    submit.click();
                                }
                            }}
                        />
                        <button
                            className="cancelRoleButton"
                            onClick={() => {
                                // setNewItemType("");
                                editItemType(null);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            id="SubmitCreate"
                            className="createRoleButton"
                            onClick={() => {
                                if (newItemType.trim() !== "") {
                                    createItemType();
                                    setIsOpen(true);
                                }
                            }}
                        >
                            Save
                        </button>
                    </td>
                    <td>&nbsp;</td>
                </tr>
            );
        });
    };

    async function createItemType() {
        setDataSubmitted(false);

        var dataSent = await fetchData({
            requestingPage: "editItemType",
            method: "put",
            url: "edit/item-type",
            headers: { token: isLogged.accessToken,module:"Item Type" },
            data: { itemTypeId: itemInfo.editItemTypeId, itemTypeName: newItemType },
        });
        if (dataSent.msg === "success") {
            setModalText("Successfully edited Item Type!");
            setNewItemType("");
            getInformation();
            editItemType(null);
        } else {
            setModalText(`Upload failed: ${dataSent.desc}`);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    const [newItemType, setNewItemType] = useState("");

    return (
        <div className="rolePagesContainer">
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="roleListArea">
                <table className="roleListTable">
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Role Name</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>

                    {renderItemTypeList()}
                </table>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editItemType: (itemTypeId) => dispatch(editItemTypeId(itemTypeId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItemType);
