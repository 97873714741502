import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import CreateEmployee from "./CreateEmployee";
import EmployeeList from "./EmployeeList";
import "../styles/Employee.css";
import EmployeeDetail from "./EmployeeDetail";
import EditEmployee from "./EditEmployee";
import employeeIcon from "../../assets/employee.svg";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import { exportData } from "../CommonFunctions/ExportExcel";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const Employee = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("employeeList");

    var createEmployeeButton = { buttonName: "Create Employee", className: "employeeButton", setSection: setCurrentSection, sectionName: "createEmployee" };

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "employeeList" };

    var IReportButton = { buttonName: "Export", className: "exportButton", setSection: async () => { await exportData(isLogged, "employeeDetails") }, sectionName: "createEmployee" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "employeeDetails") }, sectionName: "employeeList" };

    const renderSection = (section) => {
        switch (section) {
            case "employeeList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={employeeIcon}
                            pageTitle="Employee List"
                            buttonList={[isLogged.access.Employee === "Create" || isLogged.access.Employee === "Edit" ? createEmployeeButton : null, IReportButton]}
                        />
                        <EmployeeList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createEmployee":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={employeeIcon} pageTitle="Create Employee" buttonList={[goBackButton]} />
                        <CreateEmployee setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "employeeDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={employeeIcon} pageTitle="Employee Details" buttonList={[goBackButton, downloadButton]} />
                        <EmployeeDetail setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editEmployee":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={employeeIcon} pageTitle="Edit Employee Details" buttonList={[goBackButton]} />
                        <EditEmployee setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page yet to be created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Employee" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
    // return <CreateEmployee />;
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

export default connect(mapStateToProps, null)(Employee);
