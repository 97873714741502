import React, { useState,useEffect } from 'react';
import Modal from 'react-modal';
import FormElement from '../SmallComponents/FormElement';
import editIcon from '../../assets/edit.svg';
import deleteIcon from '../../assets/delete.svg';
import { validateMandatory } from '../CommonFunctions/ValidateFields';
import fetchData from '../../serverCall/fetchData';
import update from 'immutability-helper';
import { getUom } from "serverCall/fetchData";

const BomModal=({modalIsOpen,contentLabel= "Example",modalText,isLogged,submitOkClick,ALTlineItems,paramsTemplate})=>{
    Modal.setAppElement('body');
    const [errors,setErrors] = useState([]);

    const [ALTlineItemsParams,setALTlineItemsParams]=useState({...paramsTemplate});
    const [lineItems,setLineItems] = useState(ALTlineItems || []);
    const lineItemsParams =  isLogged.showHideWeight ? ["itemId","from","to","netWeight","grossWeight","uomId","quantity","allocation"] :  ["itemId","uomId","quantity","allocation"];
    const [allItemUomList, setAllItemUomList] = useState({});
    const [childBOMParams,setChildBOMParams] = useState({
      bomId: {
        inputType: "options",
        options: [],
        value: "",
        hintText: "Select BOM",
        colSpan: 6,
        mandatory: false,
        error: false,
        errorMessage: "",
        childBOMDetails:{},
      }
    })
    useEffect(()=>{
      getInformation();
  },[])

  const getBOMsByFinishedGoods=async(IdObject)=>{
    var result = await fetchData({
      requestingPage: 'BillOfMaterial',
      url: 'bill-of-material/get-bom-detail',
      method: 'post',
      data: IdObject,
      headers: { token: isLogged.accessToken, module: "Bill Of Material" },
    });

    if(result && result.msg==='success'){
      return result.asset;
    }else{
      submitOkClick({name: modalText,bomId: ""})
    }
  }
  useEffect(() => {
    if (ALTlineItems && modalText!=='childBOM' && modalIsOpen) {
      setLineItems(ALTlineItems);
    }else if(modalText==='childBOM'){
      let copychildBOM = {
                      bomId: {inputType: "options",options: [],value: "",
                              hintText: "Select BOM",colSpan: 6,mandatory: false,
                              error: false,errorMessage: "",childBOMDetails: {},} 
                          }
      if (ALTlineItems?.childBOMObject) {
          getBOMsByFinishedGoods({ fgItemId: ALTlineItems.fgItemId }).then(resp => {
          if (ALTlineItems?.childBOMObject?.bomId) {
            copychildBOM = update(copychildBOM, { ["bomId"]: { ["options"]: { $set: resp },
                                                              ["childBOMDetails"]: { $set: ALTlineItems.childBOMObject.childBOMDetails },
                                                              ["value"]: { $set: ALTlineItems.childBOMObject.bomId }
                                                            }
                                    });
          } else {
            copychildBOM = update(copychildBOM, { ["bomId"]: { ["options"]: { $set: resp } } })
          }
          setChildBOMParams(copychildBOM)
        });

      }else{
        setLineItems([]);
        setChildBOMParams(copychildBOM);
      }
    }else if(modalIsOpen){
      setLineItems([])
    }
  }, [modalIsOpen,ALTlineItems,modalText])

  async function getInformation(){
    var [itemListCopy, itemUomList] = await getUom(isLogged);
      var paramsCopy1 = Object.assign(ALTlineItemsParams);
      paramsCopy1 = update(paramsCopy1, {
        itemId: { options: { $set: itemListCopy } },
        uomId: { value: { $set: "" } },
      });
      setALTlineItemsParams(paramsCopy1);
      setAllItemUomList(itemUomList);
  }

  const updateParams = ({ section, paramName, key, value }) => {
    if(section !== "childBOM")
    {let ALTCopy = Object.assign(ALTlineItemsParams);
    ALTCopy = update(ALTCopy, { [paramName]: { [key]: { $set: value } } });
    setALTlineItemsParams(ALTCopy);
  } else{
      let childBOMParamsCopy = Object.assign(childBOMParams);
      childBOMParamsCopy = update(childBOMParamsCopy,{[paramName]: {[key] : {$set: value} } });
      setChildBOMParams(childBOMParamsCopy)
    }
  };

  useEffect(()=>{
    if(childBOMParams.bomId.value){
      getBOMsByFinishedGoods({bomId:childBOMParams.bomId.value})
        .then(({finishedGoods,rawMaterialLineItems})=>{
        updateParams({section:"childBOM",paramName: "bomId",
        key: "childBOMDetails",value: {finishedGoods:[finishedGoods],rawMaterialLineItems}});
    });
    } else{
      updateParams({section:"childBOM",paramName:"bomId",key:"childBOMDetails",value:{}});
    }
  },[childBOMParams.bomId.value])
const renderFormElements = ({ elementList, param, section }) => {

  return elementList.map((element) => {
     if((section ==="rawMaterialsALT" && element !== "allocation") || section !== "rawMaterialsALT" )
        return (
          <FormElement
            key={element}
            inputType={param[element].inputType}
            value={param[element].value !== null ? param[element].value : ""}
            setInput={(value) => {
              updateParams({
                section,
                paramName: element,
                key: "value",
                value: value,
              });
            }}
            hintText={param[element].hintText}
            mandatory={param[element].mandatory}
            colSpan={param[element].colSpan}
            options={
              param[element].inputType === "options"
                ? param[element].options
                : null
            }
            error={param[element].error}
            rowSpan={
              element === "vendorLogo" || element === "otherBusinessDetails"
                ? param[element].rowSpan
                : null
            }
            placeholder={param[element].placeholder}
            title={param[element].title}
            disabled={param[element].disabled}
            mindate={param[element].mindate}
          />
        );
      }
    );
  };
  const editSubSectionItem = (index, action,modalText) => {
    var lineItemsListCopy = lineItems?.slice() || [];
    var paramsCopy = Object.assign(ALTlineItemsParams);
    var allItemListCopy = ALTlineItemsParams.itemId.options.slice();

    if (action === "edit") {
      var lineItemAllClear = true;

      lineItemsParams.forEach((item) => {
        if (paramsCopy[item].value !== "") {
          lineItemAllClear = false;
        }
      });
      var yes = false;
      if (!lineItemAllClear) {
        yes = window.confirm(
          "Unsaved data found. Are you sure you want to overwrite it?"
        );
      }
      if (lineItemAllClear || yes) {
        var editObj = lineItemsListCopy.splice(index, 1)[0];

        allItemListCopy.push(editObj.itemOption);

        paramsCopy = update(paramsCopy, {
          itemId: {
            value: { $set: editObj.itemId },
            options: { $set: allItemListCopy },
          },
          from: { value: { $set: editObj.from } },
          to: { value: { $set: editObj.to } },
          netWeight: { value: { $set: editObj.netWeight } },
          grossWeight: { value: { $set: editObj.grossWeight } },
          uomId: {
            value: { $set: editObj.uomId },
            options: { $set: allItemUomList[editObj.itemId] },
            serverCall: { $set: false },
          },
          quantity: { value: { $set: editObj.quantity } },
          allocation: {value: {$set: editObj.allocation} }
        });
      }
    }

    if (action === "delete") {
      var editObj = lineItemsListCopy.splice(index, 1)[0];
      allItemListCopy.push(editObj.itemOption);

      paramsCopy = update(paramsCopy, {
        itemId: { options: { $set: allItemListCopy } },
      });
    }

    setALTlineItemsParams(paramsCopy);
    setLineItems(lineItemsListCopy);
  };
  useEffect(() => {
    var paramsCopy1 = Object.assign(ALTlineItemsParams);
    if (ALTlineItemsParams.itemId.value !== "") {
      paramsCopy1 = update(paramsCopy1, {
        uomId: {
          options: {
            $set: allItemUomList[paramsCopy1.itemId.value],
          },
        },
        from: {
          value: { $set: allItemUomList[paramsCopy1.itemId.value][0]["from"] },
        },
        to: {
          value: { $set: allItemUomList[paramsCopy1.itemId.value][0]["to"] },
        },
        netWeight: {
          value: {
            $set: allItemUomList[paramsCopy1.itemId.value][0]["netWeight"],
          },
        },
        grossWeight: {
          value: {
            $set: allItemUomList[paramsCopy1.itemId.value][0]["grossWeight"],
          },
        },
      });
    } else {
      paramsCopy1 = update(paramsCopy1, { uomId: { options: { $set: [] },value:{$set: ""} } });
    }
    setALTlineItemsParams(paramsCopy1);
  }, [ALTlineItemsParams.itemId.value]);
  const tableColumns={
    finishedGoodsALT : isLogged.showHideWeight ? ["item","from","to","netWeight","grossWeight","uom","quantity","allocation"]  : ["item","uom","quantity","allocation"],
    rawMaterialsALT: isLogged.showHideWeight ? ["item","from","to","netWeight","grossWeight","uom","quantity"] : ["item","uom","quantity"],
  }
  const RenderAltTable=({tableName,rowItems})=>{
    return(
      <React.Fragment>
        <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Item</td>
            {isLogged.showHideWeight ? (
              <>
                {" "}
                <td>Weight From(gms)</td>
                <td>Weight To(gms)</td>
                <td>Net Weight(gms)</td>
                <td>Gross Weight(gms)</td>{" "}
              </>
            ) : null}
            <td>UOM</td>
            <td>Quantity</td>
            {tableName !== "rawMaterialsALT" ? <td>Cost Allocation</td> : null}
            {modalText !== "childBOM" ? <td>Actions</td> : null}
          </tr>
        </thead>
        <tbody>
        { rowItems?.length > 0 ? rowItems.map((row,i)=>(
          <tr key={i} className="createVendorContactsTableRows">
          {tableColumns[tableName].map((col,j)=>{
            return <td key={j}>{row[col] ? row[col] : '-'}</td>
          })}
          {modalText !== "childBOM" ?
            <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(i, "edit",tableName);
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(i, "delete",tableName);
                }
              }}
            />
          </td> : null}
        </tr>
        )): <tr className="createVendorContactsTableRows"> 
          {tableColumns[tableName].map((col)=><td key={col}>&nbsp;</td>)}
          {modalText!=='childBOM' ? <td>&nbsp;</td> : null} 
          </tr>}
        </tbody>
        </table>
      </React.Fragment>
    )
  }

  const checkLineItemError = () => {
    var paramsCopy = Object.assign(ALTlineItemsParams);
    var errorList = [];
    lineItemsParams.forEach((item) => {
      if(paramsCopy[item].mandatory){
        if((modalText ==="rawMaterialsALT" && item !== "allocation") || modalText !== "rawMaterialsALT" )
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
      }

      if ((item == "quantity") || (item === "allocation" && modalText === 'finishedGoodsALT')) {
        if (paramsCopy[item].value !== "") {
          let constraint = item === 'allocation' ? 0 : 1;
          if (isNaN(paramsCopy[item].value) || parseFloat(paramsCopy[item].value) < constraint) {
            paramsCopy = update(paramsCopy, {[item]: { error: { $set: true } }, });
          } else {
            paramsCopy = update(paramsCopy, { [item]: { error: { $set: false } }, });
          }
        }
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });
    if (errorList.length === 0) {
      var lineCopy = lineItems?.slice() || [];
      var newItem = {
        itemId: paramsCopy.itemId.value,
        item: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0].optionName,
        itemOption: paramsCopy.itemId.options.filter(
          (item) => item.optionId === paramsCopy.itemId.value
        )[0],
        from: paramsCopy.from.value,
        to: paramsCopy.to.value,
        netWeight: paramsCopy.netWeight.value,
        grossWeight: paramsCopy.grossWeight.value,
        uomId: paramsCopy.uomId.value,
        uom: paramsCopy.uomId.options.filter(
          (item) => item.optionId === paramsCopy.uomId.value
        )[0].optionName,
        quantity: paramsCopy.quantity.value,
        allocation: paramsCopy.allocation.value
      };

      lineCopy.push(newItem);

      var trimmedItemList = paramsCopy.itemId.options.filter(
        (item) => item.optionId !== paramsCopy.itemId.value
      );

      paramsCopy = update(paramsCopy, {
        itemId: { value: { $set: "" }, options: { $set: trimmedItemList } },
        from: { value: { $set: "" } },
        to: { value: { $set: "" } },
        netWeight: { value: { $set: "" } },
        grossWeight: { value: { $set: "" } },
        uomId: { value: { $set: "" } },
        quantity: { value: { $set: "" } },
        allocation: {value: {$set: ""}}
      });
      setLineItems(lineCopy);
    };

    setErrors(errorList);
    setALTlineItemsParams(paramsCopy);
  }
  const renderBomModalContent = (modalText) => {

    switch(modalText){

        case "finishedGoodsALT":
        return( 
        <div className="purchaseOrderSubSectionArea" style={{minHeight:"400px",overflowY:"scroll"}}>
            <div className="vendorSummaryRole">Finished Goods Alternate Items</div>
                <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows: "60px",zIndex:"60"}}>
                {renderFormElements({ elementList:lineItemsParams, param: ALTlineItemsParams, section: "finishedGoodsALT" })}
                <FormElement inputType="addButton" value="+ Add" colSpan={3} setInput={() => {checkLineItemError();}}/>                        
            </div> 
            <RenderAltTable tableName={"finishedGoodsALT"} rowItems={lineItems}/>
        </div>)

        case "rawMaterialsALT":
            return(
                <div className="purchaseOrderSubSectionArea" style={{minHeight:"400px", overflowY: "scroll"}}>
                    <div className="vendorSummaryRole">Raw Materials Alternate Items</div>
                        <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows: "60px",zIndex:"60"}}>
                        {renderFormElements({ elementList: lineItemsParams, param: ALTlineItemsParams, section: "rawMaterialsALT" })}
                        <FormElement inputType="addButton" value="+ Add" colSpan={5} setInput={() => {checkLineItemError();}}/>                        
                    </div>
                    <RenderAltTable tableName={"rawMaterialsALT"} rowItems={lineItems}/> 
                </div>
            );

            case "childBOM":
                return(
                    <div className='purchaseOrderSubSectionArea' style={{minHeight: "400px",overflowY:"scroll"}}>
                        <div className='vendorSummaryRole'>Child BOM</div>
                        <div className='purchaseOrderIndividualItemsArea' style={{gridAutoRows:"60px",zIndex:"60"}}>
                        {renderFormElements({elementList: ["bomId"], param: childBOMParams, section: "childBOM"})}
                        </div>
                        <div className='vendorSummaryRole'>Finished Goods</div>
                        <RenderAltTable tableName={"finishedGoodsALT"} rowItems={childBOMParams.bomId?.childBOMDetails?.finishedGoods}/>
                        <div className='vendorSummaryRole'>Raw Materials</div>
                        <RenderAltTable tableName={"rawMaterialsALT"} rowItems={childBOMParams.bomId?.childBOMDetails?.rawMaterialLineItems}/>
                    </div>
                );
    }
  }
  const errorClearBeforeClose=async()=>{
    if(modalText==='childBOM'){
      setLineItems([]);
      submitOkClick({name: modalText,childBOMObject:{bomId: childBOMParams.bomId.value,
        childBOMDetails:Object.assign(childBOMParams.bomId.childBOMDetails)}})
    }else{
    var paramsCopy = Object.assign(ALTlineItemsParams);
    var lineItemAllClear=true;
    lineItemsParams.forEach((item) => {
      if (paramsCopy[item].value >  0) {
        lineItemAllClear = false;
        paramsCopy[item].error = true;
      }
    });
    
    if (!lineItemAllClear) {
       window.alert(
        "Unsaved data found. Please add to Table Or Clear Before Closing"
      );
      setALTlineItemsParams(paramsCopy);
    } else{
      lineItemsParams.forEach((item) => {
       paramsCopy[item].error=false;
      });
      setALTlineItemsParams(paramsCopy);
      setLineItems([])
      submitOkClick({name: modalText,lineItems: lineItems.slice()});
    }}
  }
  
    return(
        <React.Fragment>
        <style jsx="scope">
            {`.bomModalButton{
                    width: 6.5rem;
                    font-size: 14px;
                    margin: 0 auto;
                    padding: 11px 4px;
                    border-radius: 10px;
            }
            .ReactModal__Overlay {
                opacity: 0;
                transition: opacity 500ms ease-in-out;
            }
            
            .ReactModal__Overlay--after-open{
                opacity: 1;
            }
            
            .ReactModal__Overlay--before-close{
                opacity: 0;
                transition: opacity 500ms ease-in-out;
            }
            .vendorSummaryRole{
              padding-top: 18px;
              padding-left: 18px;
            }
            `}
        </style>
        <Modal
        isOpen={modalIsOpen}
        contentLabel={contentLabel}
        closeTimeoutMS={400}
        style={{
            overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
            content: {
                width: "80%",
                height: "70%",
                position: "fixed",
                top: "50%",
                left: "50%",
                borderRadius:"16px",
                transform: "translate(-50%,-50%)",
                boxShadow: "0 0 5px gray",
            },
        }}
    >
        <div style={{ lineHeight: "1rem", fontSize: "20px", marginBottom: "1rem"}}>{renderBomModalContent(modalText)}</div>
        <button className="modalButton bomModalButton" 
        onClick={(e)=>{errorClearBeforeClose(); }}>
          Save & Close</button>
        </Modal>
        </React.Fragment>
    )
}

export default BomModal;