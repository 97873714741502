import React, { useEffect, useState } from "react";
import FormElement from "../../SmallComponents/FormElement";
import FormHint from "../../SmallComponents/FormHint";
import update from "immutability-helper";
import editIcon from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import resetIcon from "../../../assets/return.svg";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import fetchData from "../../../serverCall/fetchData";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import { getUom } from "serverCall/fetchData";

const itemParameterList = [
    [
        "categoryId",
        "subCategoryId",
        "brandId",
        "itemTypeId",
        "itemCode",
        "item",
        "modelName",
        "hsnCode",

        "taxType",
        "taxApplicableDate",
        "taxPercentage",
        "mrp",

        "defaultUomId",


        "netWeight",
        "grossWeight",
        "minStockLevel",
        "maxStockLevel",
        "activeStatus",
        "itemLength",
        "itemWidth",
        "itemHeight",
        
        "description",
        "itemImage",
        "from","to",
    ],
    ["uom", "uomCount", "baseUom"],
    [],
    ["plant", "storage"],
    ["batchNo", "stockDate", "quantity", "price"],
    ["rawItem", "uomConversion", "quantity"],
    ["stepNo", "process", "quantity", "processDays", "processHours", "processMinutes"],
    ["diagram", "diagramFileName"],
];

const EditItem = ({
    // props
    setSection,

    // state
    isLogged,
    itemInfo,

    // dispatch
    addTokenToState,
}) => {
    const [subCategoryList, setSubCategoryList] = useState({});
    const [storageLocationList, setStorageLocationList] = useState({});
    const [itemSpecifUomList, setItemSpecifUomList] = useState({});
    const [allUomList, setUomList] = useState([]);
    const [itemImageFromServer, setItemImageFromServer] = useState(null);
    const [itemDetails,setItemDetails] = useState({})


    const [itemGeneralInfo, setItemGeneralInfo] = useState({
        categoryId: {
            inputType: "options",
            value: "",
            hintText: "Item Main Category",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select item main category",
        },
        subCategoryId: {
            inputType: "options",
            setServerSelection: true,
            value: "",
            hintText: "Item Sub Category",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "",
        },
        brandId: {
            inputType: "options",
            value: "",
            hintText: "Brand Name",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select brand name",
        },
        itemTypeId: {
            inputType: "options",
            value: "",
            hintText: "Item Type",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select Item Type",
        },
        itemCode: {
            inputType: "text",
            value: "",
            hintText: "Item Code",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please provide Item Code",
        },
        item: {
            inputType: "text",
            value: "",
            hintText: "Item Name",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Item Name",
        },
        modelName: {
            inputType: "text",
            value: "",
            hintText: "Model Name",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
        hsnCode: {
            inputType: "text",
            value: "",
            hintText: "HSN Code",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
        itemLength: {
            inputType: "text",
            value: "",
            hintText: "Dimensions (mm)",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Enter a number",
            placeholder: "L",
            title: "Length",
        },
        itemWidth: {
            inputType: "text",
            value: "",
            hintText: "",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Enter a number",
            placeholder: "B",
            title: "Breadth",
        },
        itemHeight: {
            inputType: "text",
            value: "",
            hintText: "",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Enter a number",
            placeholder: "H",
            title: "Height",
        },
        netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight (gm)",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Provide numbers only",
        },
        grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross weight (gm)",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Provide numbers only",
        },
        minStockLevel: {
            inputType: "text",
            value: "",
            hintText: "Min Stock Level",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "",
        },
        maxStockLevel: {
            inputType: "text",
            value: "",
            hintText: "Max Stock Level",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "",
        },
        mrp: {
            inputType: "text",
            value: "",
            hintText: "MRP",
            mandatory: true,
            colSpan: 3,
            error: false,
            errorMessage: "Please enter MRP value",
        },
        defaultUomId: {
            inputType: "options",
            value: "",
            hintText: "Default UOM",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select Default UOM",
        },
        activeStatus:{
            inputType: "options",
            value:"",
            hintText:"Item Active Status",
            options:[{optionId:"Active",optionName:"Active"},
            {optionId:"Inactive",optionName:"Inactive"}],
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Item Active Status is Mandatory"
        },
        taxType: {
            inputType: "options",
            value: "",
            hintText: "Tax type",
            mandatory: false,
            colSpan: 4,
            options: [
                { optionId: "Taxable", optionName: "Taxable" },
                { optionId: "Nil Tax", optionName: "Nil Tax" },
                { optionId: "Exempted", optionName: "Exempted" },
            ],
            error: false,
            errorMessage: "",
        },
        taxPercentage: {
            inputType: "text",
            value: "",
            hintText: "Tax Percentage",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "",
        },
        taxApplicableDate: {
            inputType: "dateFromEditPage",
            value: null,
            hintText: "Tax Applicable Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Enter Tax Applicable date",
        },
        description: {
            inputType: "multilineText",
            value: "",
            hintText: "Description",
            mandatory: false,
            colSpan: 6,
            rowSpan: 3,
            error: false,
            errorMessage: "",
        },
        itemImage: {
            inputType: "photo",
            value: "",
            hintText: "Item Image",
            mandatory: false,
            colSpan: 8,
            error: false,
            errorMessage: "",
        },
        from:{
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Provide numbers only",
        },
        to:{
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 3,
            error: false,
            errorMessage: "Provide numbers only",
       
        },
    });

    const [itemUomConversion, setItemUomConversion] = useState({
        uom: {
            inputType: "options",
            value: "",
            hintText: "UOM",
            mandatory: true,
            colSpan: 5,
            options: [],
            error: false,
            errorMessage: "Select an UOM",
        },
        uomCount: {
            inputType: "number",
            value: "",
            hintText: "Count",
            mandatory: true,
            colSpan: 5,
            error: false,
            errorMessage: "Provide enter a valid number",
        },
        baseUom: {
            inputType: "options",
            value: "",
            hintText: "Base UOM",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Base UOM",
        },
        uomConversionId: { value: null },
    });

    const [itemUomList, setItemUomList] = useState([]);

    const [itemOpeningStock, setItemOpeningStock] = useState({
        plant: {
            inputType: "options",
            value: "",
            hintText: "Plant Name",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Plant Name",
        },
        storage: {
            inputType: "options",
            value: "",
            hintText: "Storage Location Name",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select Storage Location",
        },
        address: {
            inputType: "options",
            value: "",
            hintText: "Address",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "",
        },
        batchNo: {
            inputType: "text",
            value: "",
            hintText: "Batch No",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
        stockDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Date",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Date",
        },
        quantity: {
            inputType: "text",
            value: "",
            hintText: "Quantity",
            mandatory: true,
            colSpan: 2,
            error: false,
            errorMessage: "Enter a Quantity",
        },
        price: {
            inputType: "text",
            value: "",
            hintText: "Price",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
    });

    const [includeOpeningStock, setIncludeOpeningStock] = useState(false);

    const [itemOpeningStockList, setItemOpeningStockList] = useState([]);

    const [billOfMaterial, setBillOfMaterial] = useState({
        rawItem: {
            inputType: "options",
            value: "",
            hintText: "Raw Item",
            mandatory: true,
            colSpan: 5,
            options: [],
            error: false,
            errorMessage: "Select Raw Item",
        },
        uomConversion: {
            inputType: "options",
            value: "",
            hintText: "UOM",
            mandatory: true,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Select UOm",
        },
        quantity: {
            inputType: "text",
            value: "",
            hintText: "Quantity",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter a quantity",
        },
    });

    const [billOfMaterialList, setBillOfMaterialList] = useState([]);

    const [processFlow, setProcessFlow] = useState({
        stepNo: {
            inputType: "text",
            value: "",
            hintText: "Step No",
            mandatory: true,
            colSpan: 2,
            error: false,
            errorMessage: "Please enter a number",
        },
        process: {
            inputType: "options",
            value: "",
            hintText: "Process",
            mandatory: true,
            colSpan: 3,
            options: [],
            error: false,
            errorMessage: "Select a process",
        },
        quantity: {
            inputType: "text",
            value: "",
            hintText: "Quantity",
            mandatory: false,
            colSpan: 2,
            error: false,
            errorMessage: "Enter a number",
        },
        processDays: {
            inputType: "text",
            value: "",
            hintText: "Days",
            mandatory: false,
            colSpan: 2,
            error: false,
            errorMessage: "Enter a number",
        },
        processHours: {
            inputType: "text",
            value: "",
            hintText: "HH",
            mandatory: false,
            colSpan: 2,
            error: false,
            errorMessage: "Enter a number",
        },
        processMinutes: {
            inputType: "text",
            value: "",
            hintText: "MM",
            mandatory: false,
            colSpan: 2,
            error: false,
            errorMessage: "Enter a number",
        },
    });

    const [processFlowList, setProcessFlowList] = useState([]);

    const [diagram, setDiagram] = useState({
        diagram: {
            inputType: "text",
            value: "",
            hintText: "Diagram Name",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Provide Diagram Name",
        },
        diagramFileName: {
            inputType: "upload",
            value: "",
            hintText: "Upload Diagram",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Upload Diagram",
        },
    });

    const [diagramList, setDiagramList] = useState([]);

    useEffect(() => {
        setIsOpen(true);
        setModalText("Getting Item Information");
        getInformation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (itemGeneralInfo.categoryId.value !== null && itemGeneralInfo.categoryId.value !== "") {
            var itemParams = Object.assign(itemGeneralInfo);

            if (itemGeneralInfo.subCategoryId.setServerSelection) {
                itemParams = update(itemParams, {
                    subCategoryId: { options: { $set: subCategoryList[itemGeneralInfo.categoryId.value] }, setServerSelection: { $set: false } },
                });
            } else {
                itemParams = update(itemParams, {
                    subCategoryId: { options: { $set: subCategoryList[itemGeneralInfo.categoryId.value] }, value: { $set: "" } },
                });
            }

            setItemGeneralInfo(itemParams);
        }
        // eslint-disable-next-line
    }, [itemGeneralInfo.categoryId]);

    useEffect(() => {
        if (itemOpeningStock.plant.value !== null && itemOpeningStock.plant.value !== "") {
            var itemParams = Object.assign(itemOpeningStock);
            itemParams = update(itemParams, {
                storage: { options: { $set: storageLocationList[itemOpeningStock.plant.value] }, value: { $set: "" } },
            });
            setItemOpeningStock(itemParams);
        }
        // eslint-disable-next-line
    }, [itemOpeningStock.plant]);

    useEffect(() => {
        if (billOfMaterial.rawItem.value !== null && billOfMaterial.rawItem.value !== "") {
            var itemParams = Object.assign(billOfMaterial);
            itemParams = update(itemParams, {
                uomConversion: { options: { $set: itemSpecifUomList[billOfMaterial.rawItem.value] }, value: { $set: "" } },
            });
            setBillOfMaterial(itemParams);
        }
        // eslint-disable-next-line
    }, [billOfMaterial.rawItem]);

    async function getInformation() {
        await checkToken();

        var [categoryList, subCatList] = await getCategory();
        var brandList = await getBrands();
        var itemTypeList = await getItemType();
        var defaultUomList = await getUomOnlyList();
        var [plantList, storageLocList] = await getPlants();
        var [bomList, itemSpecificUomList] = await getBom();
        var processList = await getProcess();

        var itemData = await getItemData();
        
        var itemImageFormatted = null;
        if (itemData.item[0].itemImage !== null) {
            itemImageFormatted = await getItemImage();
        }

        // ========================================================================
        // Set General Info Options
        var itemParams = Object.assign(itemGeneralInfo);
        itemImageFormatted !== null
            ? (itemParams = update(itemParams, {
                  categoryId: { options: { $set: categoryList } },
                  brandId: { options: { $set: brandList } },
                  itemTypeId: { options: { $set: itemTypeList } },
                  defaultUomId: { options: { $set: defaultUomList } },
                  itemImage: { value: { $set: itemImageFormatted }, url: { $set: URL.createObjectURL(itemImageFormatted) } },
              }))
            : (itemParams = update(itemParams, {
                  categoryId: { options: { $set: categoryList } },
                  brandId: { options: { $set: brandList } },
                  itemTypeId: { options: { $set: itemTypeList } },
                  defaultUomId: { options: { $set: defaultUomList } },
              }));

        itemParameterList[0].forEach((item) => {
            if (item === "subCategoryId") {
                itemParams.subCategoryId.options = subCatList[itemParams.categoryId.value];
            }

            if (item === "itemImage") {
                itemParams.itemImage.value = itemImageFormatted;
            }
             else {
                itemParams[item].value = itemData.item[0][item];
            }
        });

        // ========================================================================
        // Store options List required later
        setUomList(defaultUomList);
        setSubCategoryList(subCatList);

        // ========================================================================
        // remove options in Uom Conversion which are already present
        var trimmedUom = defaultUomList.slice();
        itemData.uomConversion.forEach((uomFromServer) => {
            trimmedUom = trimmedUom.filter((item) => item.optionId !== uomFromServer.uomId);
        });

        // Set UOM Conversion  Options
        var itemUom = Object.assign(itemUomConversion);
        itemUom = update(itemUom, {
            uom: { options: { $set: trimmedUom } },
            baseUom: { options: { $set: defaultUomList } },
        });
        setItemUomConversion(itemUom);
        setItemUomList(itemData.uomConversion);

        // ========================================================================
        // Set Opening stock Options

        var itemPlant = Object.assign(itemOpeningStock);
        itemPlant = update(itemPlant, {
            plant: { options: { $set: plantList } },
        });
        setItemOpeningStock(itemPlant);

        itemData.openingStock.forEach((item, i) => {
            var date = new Date(item.stockDate);

            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var dateValue = date.getDate();

            if (month.toString().length === 1) month = `0${month}`;
            if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;
            var formattedDate = `${year}-${month}-${dateValue}`;
            itemData.openingStock[i].stockDate = formattedDate;
        });

        setStorageLocationList(storageLocList);
        setItemOpeningStockList(itemData.openingStock);

        // ========================================================================
        // Set Process Flow Options

        var itemProcess = Object.assign(processFlow);
        itemProcess = update(itemProcess, {
            process: { options: { $set: processList } },
        });
        setProcessFlow(itemProcess);
        setProcessFlowList(itemData.processFlow);

        // ========================================================================
        // Set BOM Options
        var itemBom = Object.assign(billOfMaterial);
        itemBom = update(itemBom, {
            rawItem: { options: { $set: bomList } },
        });
        setBillOfMaterial(itemBom);
        setItemSpecifUomList(itemSpecificUomList);

        itemData.bom.forEach((item2, j) => {
            itemData.bom[j].uomConversion = item2.uom;
        });

        setBillOfMaterialList(itemData.bom);
        setItemGeneralInfo(itemParams);

        await getDiagramImage(itemData.diagram);

        setIsOpen(false);
        setItemDetails(itemData.item[0])
    }

    const history = useHistory();
    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
        return true;
    }

    async function getCategory() {
        var categoryData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/categories",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var categoryList = [];
        var subCategoryList = {};

        if (categoryData.msg === "success") {
            categoryData.asset.forEach((categ) => {
                var catObj = {};
                catObj.optionId = categ.category.categoryId;
                catObj.optionName = categ.category.categoryName;
                categoryList.push(catObj);

                subCategoryList[categ.category.categoryId] = [];

                categ.subCategory.forEach((subCateg) => {
                    var subCatObj = {};
                    subCatObj.optionId = subCateg.subCategoryId;
                    subCatObj.optionName = subCateg.subCategoryName;

                    subCategoryList[categ.category.categoryId].push(subCatObj);
                });
            });
        }

        return [categoryList, subCategoryList];
    }

    async function getBrands() {
        var brandData = await fetchData({
            requestingPage: "getBrand",
            method: "get",
            url: "fetch/brand-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var brandList = [];

        if (brandData.msg === "success") {
            brandData.asset.forEach((brand) => {
                var brandObj = {};
                brandObj.optionId = brand.brandId;
                brandObj.optionName = brand.brandName;
                brandList.push(brandObj);
            });
        }
        return brandList;
    }

    async function getUomOnlyList() {
        var uomData = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/uom-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var uomList = [];

        if (uomData.msg === "success") {
            uomData.asset.forEach((uom) => {
                var uomObj = {};
                uomObj.optionId = uom.uomId;
                uomObj.optionName = uom.uomName;
                uomList.push(uomObj);
            });
        }
        return uomList;
    }

    async function getItemType() {
        var itemTypeData = await fetchData({
            requestingPage: "getItemType",
            method: "get",
            url: "fetch/item-type-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var itemTypeList = [];

        if (itemTypeData.msg === "success") {
            itemTypeData.asset.forEach((itemType) => {
                var itemTypeObj = {};
                itemTypeObj.optionId = itemType.itemTypeId;
                itemTypeObj.optionName = itemType.itemTypeName;
                itemTypeList.push(itemTypeObj);
            });
        }
        return itemTypeList;
    }

    async function getPlants() {
        var plantsData = await fetchData({
            requestingPage: "plantList",
            method: "get",
            url: "fetch/plants",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var plantList = [];
        var storageLocList = {};

        if (plantsData.msg === "success") {
            plantsData.asset.forEach((plant) => {
                var plantObj = {};
                plantObj.optionId = plant.plant.plantId;
                plantObj.optionName = plant.plant.plantName;
                plantObj.address =
                    plant.plant.plantName +
                    ", " +
                    plant.plant.city +
                    ", " +
                    plant.plant.state +
                    (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                    plant.plant.country;
                plantList.push(plantObj);

                storageLocList[plant.plant.plantId] = [];
                plant.storage.forEach((store) => {
                    var storeObj = {};
                    storeObj.optionId = store.storageId;
                    storeObj.optionName = store.storageName;
                    storageLocList[plant.plant.plantId].push(storeObj);
                });
            });
        }
        return [plantList, storageLocList];
    }

    async function getBom() {
        return await getUom(isLogged);
    }

    async function getProcess() {
        var processData = await fetchData({
            requestingPage: "getProcess",
            method: "get",
            url: "fetch/process-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var processList = [];

        if (processData.msg === "success") {
            processData.asset.forEach((process) => {
                var processObj = {};
                processObj.optionId = process.processId;
                processObj.optionName = process.processName;
                processList.push(processObj);
            });
        }
        return processList;
    }

    async function getItemData() {
        var data = await fetchData({
            requestingPage: "itemDetail",
            method: "get",
            url: "fetch/item/" + itemInfo.editItemId,
            headers: { token: isLogged.accessToken,module:"Items" },
        });
        if (data.msg === "success") {
            return data.asset;
        } else return null;
    }

    async function getItemImage() {
        var data = await fetchData({
            requestingPage: "itemImage",
            method: "get",
            url: "image/item-image/" + itemInfo.editItemId,
            headers: { token: isLogged.accessToken,module:"Items" },
        });
        if (data !== "") {

            setItemImageFromServer(data);

            var data2 = `data:image/png;base64,${data}`;
            const imgFile = DataURIToBlob(data2, "Item Image");

            return imgFile;
        } else return null;
    }

    function DataURIToBlob(dataURI, fileName) {
        const splitDataURI = dataURI.split(",");
        const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

        var file = new Blob([ia], { type: mimeString });
        var imgFile = new File([file], fileName);
        return imgFile;
    }

    async function getDiagramImage(diagramListFromServer) {
        var diagListWithImages = [];
        for (var diag of diagramListFromServer) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/item-diagram/" + diag.diagramFileName,
                headers: { token: isLogged.accessToken,module:"Items" },
            });
            if (data.msg !== "failure") {
                var data2 = `data:image/png;base64,${data}`;
                const imgFile = DataURIToBlob(data2, diag.diagramFileName);

                diagListWithImages.push(imgFile);
            }
        }

        diagListWithImages.forEach((diag, i) => {
            diagramListFromServer[i].uploadDiagram = diagListWithImages[i];
        });
        setDiagramList(diagramListFromServer);
    }

    const updateItemParameter = ({ paramName, section, key, value }) => {

        switch (section) {
            case "generalInfo":
                var itemParams = Object.assign(itemGeneralInfo);
                itemParams = update(itemParams, { [paramName]: { [key]: { $set: value } } });
                setItemGeneralInfo(itemParams);
                break;

            case "uomConversion":
                var itemParams1 = Object.assign(itemUomConversion);
                itemParams1 = update(itemParams1, { [paramName]: { [key]: { $set: value } } });
                setItemUomConversion(itemParams1);
                break;

            case "storageOpeningStock":
                var itemParams3 = Object.assign(itemOpeningStock);
                itemParams3 = update(itemParams3, { [paramName]: { [key]: { $set: value } } });
                setItemOpeningStock(itemParams3);
                break;

            case "billOfMaterial":
                var itemParams4 = Object.assign(billOfMaterial);
                itemParams4 = update(itemParams4, { [paramName]: { [key]: { $set: value } } });
                setBillOfMaterial(itemParams4);
                break;

            case "processFlow":
                var itemParams5 = Object.assign(processFlow);
                itemParams5 = update(itemParams5, { [paramName]: { [key]: { $set: value } } });
                setProcessFlow(itemParams5);
                break;

            case "diagram":
                var itemParams6 = Object.assign(diagram);
                itemParams6 = update(itemParams6, { [paramName]: { [key]: { $set: value } } });
                setDiagram(itemParams6);
                break;

            default:
                break;
        }
    };

    const [uomConversionError, setUomConversionError] = useState([]);
    const checkUomConversionError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(itemUomConversion);
        itemParameterList[1].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }
            if (element === "uomCount") {
                if (paramsCopy[element].value !== "" && !isNaN(paramsCopy[element].value)) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: paramsCopy[element].value % 1 !== 0 } } });
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }

            setItemUomConversion(paramsCopy);
            setUomConversionError(errorList);
        });

        if (errorList.length === 0) {
            var newUom = {};
            itemParameterList[1].forEach((element) => {

                if (paramsCopy[element].inputType === "options") {
                    var abc = paramsCopy[element].options.filter((opt) => opt.optionId === parseInt(paramsCopy[element].value))[0].optionName;
                    newUom[element] = abc;
                    newUom[`${element}Id`] = paramsCopy[element].options.filter(
                        (opt) => opt.optionId === parseInt(paramsCopy[element].value)
                    )[0].optionId;

                    if (element === "uom") {
                        var newOptions = paramsCopy[element].options.filter((opt) => opt.optionId !== parseInt(paramsCopy[element].value));
                        paramsCopy[element].options = newOptions;
                    }
                } else {
                    newUom[element] = paramsCopy[element].value;
                }
                paramsCopy[element].value = "";
            });

            newUom.uomConversionId = paramsCopy.uomConversionId.value;
            paramsCopy.uomConversionId.value = null;

            setItemUomList([...itemUomList, newUom]);
            setItemUomConversion(paramsCopy);
        }
    };

    const [storageStockErrors, setStorageStockErrors] = useState([]);
    const checkStorageStockErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(itemOpeningStock);

        itemParameterList[3].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (includeOpeningStock) {
            itemParameterList[4].forEach((element) => {
                if (paramsCopy[element].mandatory) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
                }

                if (paramsCopy[element].error) {
                    errorList.push(paramsCopy[element].errorMessage);
                }
            });
        } else {
            var canAdd = false;
            itemParameterList[3].forEach((element) => {
                if (paramsCopy[element].value !== "" && paramsCopy[element].value.length > 0 && !canAdd) canAdd = true;
            });
        }

        if (errorList.length === 0) {
            var newStoreObj = {};
            itemParameterList[3].forEach((element) => {
                if (paramsCopy[element].inputType === "options") {
                    var abc = paramsCopy[element].options.filter((opt) => opt.optionId === parseInt(paramsCopy[element].value))[0].optionName;
                    newStoreObj[element] = abc;
                    newStoreObj[`${element}Id`] = paramsCopy[element].options.filter(
                        (opt) => opt.optionId === parseInt(paramsCopy[element].value)
                    )[0].optionId;
                } else {
                    newStoreObj[element] = paramsCopy[element].value;
                }
                paramsCopy[element].value = "";
            });
            if (includeOpeningStock) {
                itemParameterList[4].forEach((element) => {
                    newStoreObj[element] = itemOpeningStock[element].value;
                    paramsCopy[element].value = "";
                });
            }
            setItemOpeningStockList([...itemOpeningStockList, newStoreObj]);
        }
        setItemOpeningStock(paramsCopy);
        setStorageStockErrors(errorList);
    };

    const [processFlowErrors, setProcessFlowErrors] = useState([]);
    const checkProcessFlowErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(processFlow);

        itemParameterList[6].forEach((element) => {
            if (["stepNo", "quantity", "processHours", "processMinutes", "processDays"].includes(element)) {
                if (paramsCopy[element].value !== "") {
                    if (!isNaN(paramsCopy[element].value)) {
                        paramsCopy = update(paramsCopy, { [element]: { error: { $set: paramsCopy[element].value % 1 !== 0 } } });
                    } else {
                        paramsCopy[element].error = true;
                    }
                } else {
                    paramsCopy[element].error = false;
                }
            }

            if (element === "process") {
                if (paramsCopy[element].value !== "") {
                    paramsCopy[element].error = false;
                } else {
                    paramsCopy[element].error = true;
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newProcess = {};
            itemParameterList[6].forEach((element) => {
                if (paramsCopy[element].inputType === "options") {
                    var abc = paramsCopy[element].options.filter((opt) => opt.optionId === parseInt(paramsCopy[element].value))[0].optionName;
                    newProcess[element] = abc;
                    newProcess[`${element}Id`] = paramsCopy[element].options.filter(
                        (opt) => opt.optionId === parseInt(paramsCopy[element].value)
                    )[0].optionId;
                } else {
                    newProcess[element] = paramsCopy[element].value;
                }
                paramsCopy[element].value = "";
            });

            setProcessFlowList([...processFlowList, newProcess]);
        }

        setProcessFlow(paramsCopy);
        setProcessFlowErrors(errorList);
    };

    const [billOfMaterialErrors, setBillOfMaterialErrors] = useState([]);
    const checkBillOfMaterialsError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(billOfMaterial);
        itemParameterList[5].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newBomObj = {};
            itemParameterList[5].forEach((element) => {
                if (paramsCopy[element].inputType === "options") {
                    var abc = paramsCopy[element].options.filter((opt) => opt.optionId === parseInt(paramsCopy[element].value))[0].optionName;
                    newBomObj[element] = abc;
                    newBomObj[`${element}Id`] = paramsCopy[element].options.filter(
                        (opt) => opt.optionId === parseInt(paramsCopy[element].value)
                    )[0].optionId;
                } else {
                    newBomObj[element] = paramsCopy[element].value;
                }
                paramsCopy[element].value = "";
            });

            setBillOfMaterialList([...billOfMaterialList, newBomObj]);
        }

        setBillOfMaterialErrors(errorList);
        setBillOfMaterial(paramsCopy);
    };

    const [diagramErrors, setDiagramErrors] = useState([]);
    const checkDiagramErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(diagram);
        itemParameterList[7].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (errorList.length === 0) {
            var newDiagram = {};
            // ["diagram", "diagramFileName", "uploadDiagram"].forEach((element) => {
            //     newDiagram[element] = paramsCopy[element].value;
            //     paramsCopy[element].value = "";
            // });

            newDiagram["diagram"] = paramsCopy.diagram.value;
            paramsCopy["diagram"].value = "";

            newDiagram["diagramFileName"] = paramsCopy.diagramFileName.value.name;
            newDiagram["uploadDiagram"] = paramsCopy.diagramFileName.value;
            paramsCopy["diagramFileName"].value = "";

            setDiagramList([...diagramList, newDiagram]);
        }

        setDiagramErrors(errorList);
        setDiagram(paramsCopy);
    };

    const [generalInfoError, setGeneralInfoError] = useState([]);
    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(itemGeneralInfo);

        itemParameterList[0].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }

            if (["itemLength", "itemWidth", "itemHeight", "netWeight", "grossWeight", "mrp", "taxPercentage","from","to"].includes(element)) {
                if (paramsCopy[element].value !== "") {
                    if (isNaN(paramsCopy[element].value)) {
                        paramsCopy = update(paramsCopy, { [element]: { error: { $set: true } } });
                    } else {
                        paramsCopy = update(paramsCopy, { [element]: { error: { $set: false } } });
                    }
                }
            }

            if (["minStockLevel", "maxStockLevel"].includes(element)) {
                if (paramsCopy[element].value !== "") {
                    if (!isNaN(paramsCopy[element].value)) {
                        paramsCopy = update(paramsCopy, { [element]: { error: { $set: paramsCopy[element].value % 1 !== 0 } } });
                    } else {
                        paramsCopy = update(paramsCopy, { [element]: { error: { $set: true } } });
                    }
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        setItemGeneralInfo(paramsCopy);
        setGeneralInfoError(errorList);

        if (errorList.length === 0) {

            var uomAllClear = true;
            var storageAllClear = true;
            var billAllClear = true;
            var processAllClear = true;
            var diagramAllClear = true;

            // UOM Conversion
            if (itemUomList.length > 0) {
                itemParameterList[1].forEach((item) => {
                    if (itemUomConversion[item].value !== "") {
                        uomAllClear = false;
                        setUomConversionError(["Unsaved Information Found"]);
                    }
                });
            } else {
                uomAllClear = false;
                setUomConversionError(["Enter atleast 1 UOM conversion"]);
            }

            // Storage & Opening Stock
            itemParameterList[3].forEach((item) => {
                if (itemOpeningStock[item].value !== "") {
                    storageAllClear = false;
                    setStorageStockErrors(["Unsaved Information Found"]);
                }
            });
            itemParameterList[4].forEach((item) => {
                if (itemOpeningStock[item].value !== "") {
                    storageAllClear = false;
                    setStorageStockErrors(["Unsaved Information Found"]);
                }
            });

            // Bill of Material
            itemParameterList[5].forEach((item) => {
                if (billOfMaterial[item].value !== "") {
                    billAllClear = false;
                    setBillOfMaterialErrors(["Unsaved Information Found"]);
                }
            });

            //  Process Flow
            itemParameterList[6].forEach((item) => {
                if (processFlow[item].value !== "") {
                    processAllClear = false;
                    setProcessFlowErrors(["Unsaved Information Found"]);
                }
            });

            // Diagram
            itemParameterList[7].forEach((item) => {
                if (diagram[item].value !== "") {
                    diagramAllClear = false;
                    setDiagramErrors(["Unsaved Information Found"]);
                }
            });

            if (uomAllClear && storageAllClear && billAllClear && processAllClear && diagramAllClear) {
                prepareData(paramsCopy);
            }
        }
    };

    const prepareData = (paramsCopy) => {
        var dataToServer = new FormData();

        // Gen Info
        itemParameterList[0].forEach((element) => {
            if (paramsCopy[element].value !== "" && paramsCopy[element].value !== null && element !== "categoryId") {
                dataToServer.append(element, paramsCopy[element].value);
            }
        });
        // Uom Conversion List
        var uomListToServer = [];
        itemUomList.forEach((item) => {
            var newUom = {};
            newUom.uomId = item.uomId;
            newUom.baseUomId = item.baseUomId;
            newUom.uomCount = item.uomCount;
            newUom.uomConversionId = item.uomConversionId;

            uomListToServer.push(newUom);
        });
        dataToServer.append("uomConversion", JSON.stringify(uomListToServer));

        // Storage & Opening Stock
        if (itemOpeningStockList.length > 0) {
            var storageLocationData = [];
            itemOpeningStockList.forEach((item) => {
                storageLocationData.push({ storageId: item.storageId });
            });
            dataToServer.append("storageLocation", JSON.stringify(storageLocationData));

            var openingStockData = [];
            itemOpeningStockList.forEach((item) => {
                var itemKeys = ["storageId", "batchNo", "stockDate", "quantity", "price"];
                var newStock = {};
                itemKeys.forEach((key) => {
                    if (item[key] !== null && item[key] !== "") {
                        newStock[key] = item[key];
                    }
                });
                openingStockData.push(newStock);
            });
            dataToServer.append("openingStock", JSON.stringify(openingStockData));
        }

        if (billOfMaterialList.length > 0) {
            var billOfMaterialData = [];
            billOfMaterialList.forEach((item) => {
                var newBom = {};
                newBom.uomConversionId = item.uomConversionId;
                newBom.quantity = item.quantity;
                billOfMaterialData.push(newBom);
            });
            dataToServer.append("bom", JSON.stringify(billOfMaterialData));
        }

        if (processFlowList.length > 0) {
            var processFlowData = [];
            processFlowList.forEach((item) => {
                var newProcess = {};
                var processKeys = ["stepNo", "processId", "quantity", "processDays", "processHours", "processMinutes"];

                processKeys.forEach((key) => {
                    if (item[key] !== null && item[key] !== "") {
                        newProcess[key] = item[key];
                    }
                });
                processFlowData.push(newProcess);
            });
            dataToServer.append("processFlow", JSON.stringify(processFlowData));
        }

        // dataToServer.append('diag')
        var diagKeyList = [
            "itemDiagramA",
            "itemDiagramB",
            "itemDiagramC",
            "itemDiagramD",
            "itemDiagramE",
            "itemDiagramF",
            "itemDiagramG",
            "itemDiagramH",
            "itemDiagramI",
            "itemDiagramJ",
            "itemDiagramK",
            "itemDiagramL",
            "itemDiagramM",
            "itemDiagramN",
            "itemDiagramO",
        ];
        if (diagramList.length > 0) {
            var itemDiagram = [];
            diagramList.forEach((diag, i) => {
                var newDiag = {};
                newDiag.diagram = diag.diagram;
                itemDiagram.push(newDiag);
                dataToServer.append(`${diagKeyList[i]}`, diag.uploadDiagram);
            });

            dataToServer.append("itemDiagram", JSON.stringify(itemDiagram));
        }

        dataToServer.append("itemId", itemInfo.editItemId);

        setIsOpen(true);
        setModalText("Uploading form. Please wait... ");
        if(itemDetails.activeStatus === "Approval Pending"){
            dataToServer.set('activeStatus',"Approval Pending")
        }
        submitData(dataToServer);
    };

    const [itemServerError, setItemServerError] = useState([]);

    async function submitData(data) {
        setDataSubmitted(false);

        var result = await fetchData({
            requestingPage: "createItem",
            method: "put",
            url: "edit/item",
            headers: { token: isLogged.accessToken,module:"Items" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully edited Item!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setItemServerError([result.desc]);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        if (itemServerError.length === 0) {
            setSection("itemList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const renderErrorMessage = () => {
        if (generalInfoError.length > 0) return `General Info : ${generalInfoError[0]}`;
        else if (uomConversionError.length > 0) return `UOM Conversion : ${uomConversionError[0]}`;
        else if (storageStockErrors.length > 0) return `Storage & Stock : ${storageStockErrors[0]}`;
        else if (billOfMaterialErrors.length > 0) return `Bill Of Materials : ${billOfMaterialErrors[0]}`;
        else if (processFlowErrors.length > 0) return `Process Flow : ${processFlowErrors[0]}`;
        else if (diagramErrors.length > 0) return `Diagram : ${diagramErrors[0]}`;
        else if (itemServerError.length > 0) return `Error : ${itemServerError[0]}`;
        else return null;
    };

    const tablist = [
        { id: "uomConversion", name: "Item UOM" },
        { id: "storageOpeningStock", name: "Storage & Opening Stock" },
        { id: "billOfMaterial", name: "Bill Of Material" },
        { id: "processFlow", name: "Process Flow" },
        { id: "diagram", name: "Diagram" },
    ];
    const [selectedTab, setSelectedTab] = useState("uomConversion");
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={selectedTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                    {tab.id === "uomConversion" ? selectedTab === "uomConversion" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const renderFormElements = ({ elementList, section }) => {
        return elementList.map((item) => {
            switch (item) {
                case "itemImage":
                    return (
                        <div className="itemPhoto" key={item}>
                            <FormHint hintText={itemGeneralInfo[item].hintText} mandatory={itemGeneralInfo[item].mandatory} />

                            {/* eslint-disable-next-line */}
                            <img style={{opacity:"1"}}
                                src={
                                    itemGeneralInfo[item].url
                                        ? itemGeneralInfo[item].url
                                        : itemImageFromServer !== null
                                        ? `data:image/png;base64,${itemImageFromServer}`
                                        : null
                                }
                                className="itemLogo"
                                //  src={employeeParameters[param].url ? employeeParameters[param].url : `data:image/png;base64,${profileImage}`}
                            />
                            <label
                                className={itemGeneralInfo[item].error ? "uploadEditProfilePhotoError" : "uploadEditProfilePhoto"}
                                htmlFor="uploadItemlogo"
                            >
                                {(itemGeneralInfo[item].value !== null &&
                                    itemGeneralInfo[item].value.name !== null &&
                                    itemGeneralInfo[item].value.name !== undefined &&
                                    itemGeneralInfo[item].value.name.length > 0) ||
                                itemImageFromServer !== null
                                    ? "EDIT"
                                    : "UPLOAD"}
                            </label>
                            <input
                                className="uploadEditInput"
                                id="uploadItemlogo"
                                type="file"
                                accept="images/*"
                                onChange={(event) => {
                                    var itemGenInfoCopy = Object.assign(itemGeneralInfo);
                                    itemGenInfoCopy = update(itemGenInfoCopy, {
                                        [item]: {
                                            value: { $set: event.target.files[0] },
                                            url: { $set: URL.createObjectURL(event.target.files[0]) },
                                        },
                                    });
                                    setItemGeneralInfo(itemGenInfoCopy);
                                }}
                            />
                        </div>
                    );

                case "description":
                    return (
                        <div className="itemMultiline" key={item}>
                            <FormHint hintText={itemGeneralInfo[item].hintText} mandatory={itemGeneralInfo[item].mandatory} />

                            <textarea
                                value={itemGeneralInfo[item].value}
                                className="itemMultilineInput"
                                rowSpan={itemGeneralInfo[item].rowSpan}
                                rows="5"
                                cols="50"
                                name="text"
                                placeholder="Enter your notes"
                                style={{ boxSizing: "border-box", fontFamily: "sans-serif", padding: "10px", resize: "none" }}
                                onChange={(e) => {
                                    updateItemParameter({ section, paramName: item, key: "value", value: e.target.value });
                                }}
                            />
                        </div>
                    );

                case "diagramFileName":
                    return (
                        <FormElement
                            inputType={
                                diagram[item].value !== null && diagram[item].value.name !== null && diagram[item].value.name !== undefined
                                    ? "uploadReady"
                                    : "upload"
                            }
                            value={diagram[item].value !== null && diagram[item].value !== "" ? diagram[item].value.name : ""}
                            setInput={(value) => {
                                if (value !== "deleteId") {
                                    updateItemParameter({ section, paramName: item, key: "value", value: value });
                                } else {
                                    updateItemParameter({ section, paramName: item, key: "value", value: "" });
                                }
                            }}
                            hintText="Upload Diagram"
                            mandatory={diagram[item].mandatory}
                            colSpan={diagram[item].colSpan}
                            error={diagram[item].error}
                        />
                    );

                default:
                    switch (section) {
                        case "generalInfo":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={itemGeneralInfo[item].inputType}
                                    value={itemGeneralInfo[item].value}
                                    setInput={(value) => {
                                        updateItemParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={itemGeneralInfo[item].hintText}
                                    mandatory={itemGeneralInfo[item].mandatory}
                                    colSpan={itemGeneralInfo[item].colSpan}
                                    options={itemGeneralInfo[item].inputType === "options" ? itemGeneralInfo[item].options : null}
                                    error={itemGeneralInfo[item].error}
                                    rowSpan={item === "vendorLogo" || item === "otherBusinessDetails" ? itemGeneralInfo[item].rowSpan : null}
                                    placeholder={itemGeneralInfo[item].placeholder}
                                    title={itemGeneralInfo[item].title}
                                />
                            );

                        case "uomConversion":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={itemUomConversion[item].inputType}
                                    value={itemUomConversion[item].value}
                                    setInput={(value) => {
                                        updateItemParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={itemUomConversion[item].hintText}
                                    mandatory={itemUomConversion[item].mandatory}
                                    colSpan={itemUomConversion[item].colSpan}
                                    options={itemUomConversion[item].inputType === "options" ? itemUomConversion[item].options : null}
                                    error={itemUomConversion[item].error}
                                />
                            );

                        case "storageOpeningStock":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={itemOpeningStock[item].inputType}
                                    value={itemOpeningStock[item].value}
                                    setInput={(value) => {
                                        updateItemParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={itemOpeningStock[item].hintText}
                                    mandatory={itemOpeningStock[item].mandatory}
                                    colSpan={itemOpeningStock[item].colSpan}
                                    options={itemOpeningStock[item].inputType === "options" ? itemOpeningStock[item].options : null}
                                    error={itemOpeningStock[item].error}
                                />
                            );

                        case "billOfMaterial":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={billOfMaterial[item].inputType}
                                    value={billOfMaterial[item].value}
                                    setInput={(value) => {
                                        updateItemParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={billOfMaterial[item].hintText}
                                    mandatory={billOfMaterial[item].mandatory}
                                    colSpan={billOfMaterial[item].colSpan}
                                    options={billOfMaterial[item].inputType === "options" ? billOfMaterial[item].options : null}
                                    error={billOfMaterial[item].error}
                                />
                            );

                        case "processFlow":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={processFlow[item].inputType}
                                    value={processFlow[item].value}
                                    setInput={(value) => {
                                        updateItemParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={processFlow[item].hintText}
                                    mandatory={processFlow[item].mandatory}
                                    colSpan={processFlow[item].colSpan}
                                    options={processFlow[item].inputType === "options" ? processFlow[item].options : null}
                                    error={processFlow[item].error}
                                />
                            );

                        case "diagram":
                            return (
                                <FormElement
                                    key={item}
                                    inputType={diagram[item].inputType}
                                    value={diagram[item].value}
                                    setInput={(value) => {
                                        updateItemParameter({ section, paramName: item, key: "value", value: value });
                                    }}
                                    hintText={diagram[item].hintText}
                                    mandatory={diagram[item].mandatory}
                                    colSpan={diagram[item].colSpan}
                                    options={diagram[item].inputType === "options" ? diagram[item].options : null}
                                    error={diagram[item].error}
                                    disabled={diagramList.length === 15 ? true : false}
                                />
                            );

                        default:
                            return null;
                    }
            }
        });
    };

    const getPlantAddress = () => {
        var address = itemOpeningStock.plant.options.filter((item) => item.optionId.toString() === itemOpeningStock.plant.value.toString())[0]
            .address;
        return <div> {address}</div>;
    };

    const renderSubSection = (selectedTab) => {
        switch (selectedTab) {
            case "uomConversion":
                return (
                    <React.Fragment>
                        <div className="createItemIndividualTabDetails">
                            {renderFormElements({ elementList: itemParameterList[1], section: selectedTab })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkUomConversionError();
                                }}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "storageOpeningStock":
                return (
                    <React.Fragment>
                        <div className="createItemIndividualTabDetails">
                            {renderFormElements({ elementList: itemParameterList[3], section: selectedTab })}
                            <div style={{ paddingTop: "1.5rem", gridColumn: "span 5", color: "#666", fontSize: "14px" }}>
                                {itemOpeningStock.plant.value !== null &&
                                itemOpeningStock.plant.value !== undefined &&
                                itemOpeningStock.plant.value !== "" &&
                                itemOpeningStock.storage.value !== ""
                                    ? getPlantAddress()
                                    : null}
                            </div>
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkStorageStockErrors();
                                }}
                            />
                            <div
                                style={{
                                    marginTop: "26px",
                                    gridColumn: "span 1",
                                    color: "#666",
                                    background: "#e4e4e4",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    style={{ padding: "10px", width: "1rem", height: "1rem" }}
                                    src={resetIcon}
                                    alt="Reset"
                                    onClick={() => {
                                        var yes = window.confirm("Clear Input data?");

                                        if (yes) {
                                            var emptyOpeningStock = Object.assign(itemOpeningStock);
                                            itemParameterList[3].forEach((item) => {
                                                emptyOpeningStock[item].value = "";
                                                emptyOpeningStock[item].error = false;
                                            });

                                            itemParameterList[4].forEach((item) => {
                                                emptyOpeningStock[item].value = "";
                                                emptyOpeningStock[item].error = false;
                                            });

                                            setItemOpeningStock(emptyOpeningStock);
                                            setStorageStockErrors([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="plantsSubTitle">
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        setIncludeOpeningStock(false);
                                    } else {
                                        setIncludeOpeningStock(true);
                                    }
                                }}
                                checked={includeOpeningStock}
                            />
                            <span>Include Opening Stock </span>
                        </div>
                        {includeOpeningStock ? (
                            <div className="createItemIndividualTabDetails">
                                {renderFormElements({ elementList: itemParameterList[4], section: selectedTab })}
                            </div>
                        ) : null}
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "billOfMaterial":
                return (
                    <React.Fragment>
                        <div className="createItemIndividualTabDetails">
                            {renderFormElements({ elementList: itemParameterList[5], section: selectedTab })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkBillOfMaterialsError();
                                }}
                            />
                            <div
                                style={{
                                    marginTop: "26px",
                                    gridColumn: "span 1",
                                    color: "#666",
                                    background: "#e4e4e4",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    style={{ padding: "10px", width: "1rem", height: "1rem" }}
                                    src={resetIcon}
                                    alt="Reset"
                                    onClick={() => {
                                        var yes = window.confirm("Clear Input data?");

                                        if (yes) {
                                            var emptyBillOfMaterial = Object.assign(billOfMaterial);
                                            itemParameterList[5].forEach((item) => {
                                                emptyBillOfMaterial[item].value = "";
                                                emptyBillOfMaterial[item].error = false;
                                            });
                                            setBillOfMaterial(emptyBillOfMaterial);
                                            setBillOfMaterialErrors([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "processFlow":
                return (
                    <React.Fragment>
                        <div className="createItemIndividualTabDetails">
                            {renderFormElements({ elementList: itemParameterList[6], section: selectedTab })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    checkProcessFlowErrors();
                                }}
                            />
                            <div
                                style={{
                                    marginTop: "26px",
                                    gridColumn: "span 1",
                                    color: "#666",
                                    background: "#e4e4e4",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                <img
                                    style={{ padding: "10px", width: "1rem", height: "1rem" }}
                                    src={resetIcon}
                                    alt="Reset"
                                    onClick={() => {
                                        var yes = window.confirm("Clear Input data?");

                                        if (yes) {
                                            var emptyProcessFlow = Object.assign(processFlow);
                                            itemParameterList[6].forEach((item) => {
                                                emptyProcessFlow[item].value = "";
                                                emptyProcessFlow[item].error = false;
                                            });
                                            setProcessFlow(emptyProcessFlow);
                                            // setItemOpeningStockList([]);
                                            setProcessFlowErrors([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            case "diagram":
                return (
                    <React.Fragment>
                        <div className="createItemIndividualTabDetails">
                            {renderFormElements({ elementList: itemParameterList[7], section: selectedTab })}
                            <FormElement
                                inputType="addButton"
                                value="+ Add"
                                colSpan={2}
                                setInput={() => {
                                    if (diagramList.length === 15) {
                                        window.alert("Upload limit - 15 Pictures");
                                    } else checkDiagramErrors();
                                }}
                                disabled={diagramList.length === 15 ? true : false}
                            />
                        </div>
                        <RenderTable tab={selectedTab} />
                    </React.Fragment>
                );

            default:
                return null;
        }
    };

    const RenderTable = ({ tab }) => {
        switch (tab) {
            case "uomConversion":
                var tableFields = ["uom", "uomCount", "baseUom"];
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>UOM</td>
                                <td>Count</td>
                                <td>Base UOM</td>
                                <td className="itemIndiTabActions">Actions</td>
                            </tr>
                        </thead>
                        {itemUomList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={itemUomList} tab="uomConversion" fields={tableFields} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {itemParameterList[1].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            case "storageOpeningStock":
                var tableFields2 = ["plant", "storage", "batchNo", "stockDate", "quantity", "price"];
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Plant Name</td>
                                <td>Storage Location Name</td>
                                <td>Batch No</td>
                                <td>Date</td>
                                <td>Quantity</td>
                                <td>Price</td>
                                <td className="itemIndiTabActions">Actions</td>
                            </tr>
                        </thead>
                        {itemOpeningStockList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={itemOpeningStockList} tab="storageOpeningStock" fields={tableFields2} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {tableFields2.map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}

                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            case "billOfMaterial":
                var tableFields4 = ["rawItem", "uomConversion", "quantity"];
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Raw Item</td>
                                <td>UOM</td>
                                <td>Quantity</td>
                                <td className="itemIndiTabActions">Actions</td>
                            </tr>
                        </thead>
                        {billOfMaterialList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={billOfMaterialList} tab="billOfMaterial" fields={tableFields4} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {itemParameterList[5].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            case "processFlow":
                var tableFields3 = ["stepNo", "process", "quantity", "processDays", "processHours", "processMinutes"];
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Step No</td>
                                <td>Process</td>
                                <td>Quantity</td>
                                <td>Day</td>
                                <td>HH</td>
                                <td>MM</td>
                                <td className="itemIndiTabActions">Actions</td>
                            </tr>
                        </thead>
                        {processFlowList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={processFlowList} tab="processFlow" fields={tableFields3} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {itemParameterList[6].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            case "diagram":
                tableFields4 = ["diagram", "diagramFileName"];
                return (
                    <table className="createVendorContactsTable">
                        <thead>
                            <tr className="createVendorContactsTableHeader">
                                <td>Diagram Name</td>
                                <td>Diagram File</td>
                                <td className="itemIndiTabActions">Actions</td>
                            </tr>
                        </thead>
                        {diagramList.length > 0 ? (
                            <tbody>
                                <RenderTableRows rows={diagramList} tab="diagram" fields={tableFields4} />
                            </tbody>
                        ) : (
                            <tbody>
                                <tr className="createVendorContactsTableRows">
                                    {itemParameterList[7].map((item) => {
                                        return <td key={item}>&nbsp;</td>;
                                    })}
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                );

            default:
                return null;
        }
    };

    const RenderTableRows = ({ rows, tab, fields }) => {
        if (tab === "diagram") {
            return rows.map((row, j) => {
                return (
                    <tr className="createVendorContactsTableRows" key={j}>
                        {fields.map((key, i) => {
                            return key === "diagramFileName" ? <td key={i}>{row[key]}</td> : <td key={i}>{row[key]}</td>;
                        })}

                        {listRecordActions(tab, j)}
                    </tr>
                );
            });
        } else {
            return rows.map((row, j) => {
                return (
                    <tr className="createVendorContactsTableRows" key={j}>
                        {["storageOpeningStock", "uomConversion", "processFlow", "billOfMaterial"].includes(tab)
                            ? fields.map((key, i) => {
                                  return <td key={i}> {row[key]}</td>;
                              })
                            : Object.keys(row).map((key, i) => {
                                  return <td key={i}>{row[key]}</td>;
                              })}

                        {listRecordActions(tab, j)}
                    </tr>
                );
            });
        }
    };

    var listRecordActions = (tab, j) => {
        return (
            <td className="itemIndiTabActions">
                <img
                    alt="Edit record"
                    className="createVendorContactsAction"
                    src={editIcon}
                    onClick={() => {
                        editSubSectionItem(tab, j, "edit");
                    }}
                />
                <img
                    alt="Delete record"
                    className="createVendorContactsAction"
                    src={deleteIcon}
                    onClick={() => {
                        var reset = window.confirm("Delete record?");
                        if (reset) editSubSectionItem(tab, j, "delete");
                    }}
                />
            </td>
        );
    };

    const editSubSectionItem = (tab, item, action) => {
        if (action === "edit") {
            switch (tab) {
                case "uomConversion":
                    var uomAllClear = true;
                    itemParameterList[1].forEach((item) => {
                        if (itemUomConversion[item].value !== "") {
                            uomAllClear = false;
                        }
                    });
                    if (!uomAllClear) {
                        var yes = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }
                    if (yes || uomAllClear) {
                        var uomParams = Object.assign(itemUomConversion);
                        var uomListCopy = itemUomList.slice();

                        var editObject = uomListCopy.splice(item, 1)[0];
                        ["uom", "uomId", "uomCount", "baseUom", "baseUomId"].forEach((key) => {
                            if (key === "uomId") {
                                uomParams.uom.value = editObject["uomId"];
                            } else if (key === "baseUomId") {
                                uomParams.baseUom.value = editObject["baseUomId"];
                            } else {
                                uomParams[key].value = editObject[key];
                            }
                        });

                        uomParams.uomConversionId.value = editObject.uomConversionId;

                        var newOption = allUomList.filter((item) => item.optionId === editObject["uomId"])[0];
                        uomParams.uom.options.push(newOption);
                        setItemUomConversion(uomParams);
                        setItemUomList(uomListCopy);
                    }
                    break;

                case "storageOpeningStock":

                    var stockAllClear = true;
                    itemParameterList[3].forEach((item) => {
                        if (itemOpeningStock[item].value !== "") {
                            stockAllClear = false;
                        }
                    });
                    itemParameterList[4].forEach((item) => {
                        if (itemOpeningStock[item].value !== "") {
                            stockAllClear = false;
                        }
                    });
                    if (!stockAllClear) {
                        var yes2 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }
                    if (yes2 || stockAllClear) {
                        var openStockParam = Object.assign(itemOpeningStock);
                        var itemOpeningStockListCopy = itemOpeningStockList.slice();

                        var editObject2 = itemOpeningStockListCopy.splice(item, 1)[0];
                        itemParameterList[3].forEach((key) => {
                            if (key === "plant") {
                                openStockParam.plant.value = editObject2["plantId"];
                            } else if (key === "storage") {
                                openStockParam.storage.value = editObject2["storageId"];

                                openStockParam.storage.options = storageLocationList[editObject2.plantId];
                            } else {
                                openStockParam[key].value = editObject2[key];
                            }
                        });
                        openStockParam.address.value = editObject2["address"];

                        if (editObject2.stockDate !== undefined) {
                            setIncludeOpeningStock(true);
                        } else {
                            setIncludeOpeningStock(false);
                        }
                        itemParameterList[4].forEach((key) => {
                            if (editObject2[key]) {
                                openStockParam[key].value = editObject2[key];
                            }
                        });

                        setItemOpeningStock(openStockParam);
                        setItemOpeningStockList(itemOpeningStockListCopy);
                    }
                    break;

                case "processFlow":
                    var processAllClear = true;
                    itemParameterList[6].forEach((item) => {
                        if (processFlow[item].value !== "") {
                            processAllClear = false;
                        }
                    });
                    if (!processAllClear) {
                        var yes3 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }
                    if (yes3 || processAllClear) {
                        var processParams = Object.assign(processFlow);
                        var processFlowListCopy = processFlowList.slice();

                        var editObject3 = processFlowListCopy.splice(item, 1)[0];
                        Object.keys(processParams).forEach((key) => {
                            if (key === "process") {
                                processParams.process.value = editObject3["processId"];
                            } else {
                                processParams[key].value = editObject3[key];
                            }
                        });

                        setProcessFlow(processParams);
                        setProcessFlowList(processFlowListCopy);
                    }
                    break;

                case "billOfMaterial":
                    var billAllClear = true;
                    itemParameterList[5].forEach((item) => {
                        if (billOfMaterial[item].value !== "") {
                            billAllClear = false;
                        }
                    });

                    if (!billAllClear) {
                        var yes4 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }

                    if (yes4 || billAllClear) {
                        var billParams = Object.assign(billOfMaterial);
                        var billListCopy = billOfMaterialList.slice();

                        var editObject4 = billListCopy.splice(item, 1)[0];
                        ["uomConversionId", "rawItemId", "quantity"].forEach((key) => {
                            if (key === "uomConversionId") {
                                billParams.uomConversion.value = editObject4["uomConversionId"];
                                billParams.uomConversion.options = itemSpecifUomList[editObject4.rawItemId];
                            } else if (key === "rawItemId") {
                                billParams.rawItem.value = editObject4["rawItemId"];
                            } else {
                                billParams[key].value = editObject4[key];
                            }
                        });

                        setBillOfMaterial(billParams);
                        setBillOfMaterialList(billListCopy);
                    }
                    break;

                case "diagram":
                    var diagramAllClear = true;
                    itemParameterList[7].forEach((item) => {
                        if (diagram[item].value !== "") diagramAllClear = false;
                    });

                    if (!diagramAllClear) {
                        var yes5 = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
                    }

                    if (yes5 || diagramAllClear) {
                        var diagramParams = Object.assign(diagram);
                        var diagramListCopy = diagramList.slice();

                        var editObject5 = diagramListCopy.splice(item, 1)[0];


                        diagramParams.diagram.value = editObject5.diagram;
                        diagramParams.diagramFileName.value = editObject5.uploadDiagram;

                        setDiagram(diagramParams);
                        setDiagramList(diagramListCopy);
                    }
                    break;

                default:
                    break;
            }
        } else if (action === "delete") {
            switch (tab) {
                case "uomConversion":
                    var uomListCopy2 = itemUomList.slice();
                    var deletingUom = uomListCopy2.splice(item, 1);

                    var newOption2 = allUomList.filter((uom) => uom.optionId === deletingUom[0].uomId);

                    var itemUomCopy = Object.assign(itemUomConversion);
                    itemUomCopy = update(itemUomCopy, { uom: { options: { $push: newOption2 } } });
                    setItemUomConversion(itemUomCopy);

                    setItemUomList(uomListCopy2);
                    break;

                case "storageOpeningStock":
                    var openingStockListCopy = itemOpeningStockList.slice();
                    openingStockListCopy.splice(item, 1);
                    setItemOpeningStockList(openingStockListCopy);
                    break;

                case "processFlow":
                    var processFlowCopy = processFlowList.slice();
                    processFlowCopy.splice(item, 1);
                    setProcessFlowList(processFlowCopy);
                    break;

                case "billOfMaterial":
                    var billOfMaterialListCopy = billOfMaterialList.slice();
                    billOfMaterialListCopy.splice(item, 1);
                    setBillOfMaterialList(billOfMaterialListCopy);
                    break;

                case "diagram":
                    var diagramListCopy2 = diagramList.slice();
                    diagramListCopy2.splice(item, 1);
                    setDiagramList(diagramListCopy2);
                    break;

                default:
                    break;
            }
        }
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="createItemGrid">
                            {renderFormElements({ elementList: itemParameterList[0], section: "generalInfo" })}
                            {/* {renderFormElements({ elementList: itemParameterList[2], section: "generalInfo" })} */}
                    </div>
                    <div className="createVendorTabArea">
                        <div className="createVendorTabs">
                            <RenderTabList tablist={tablist} />
                        </div>
                        {renderSubSection(selectedTab)}
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setGeneralInfoError([]);
                            setUomConversionError([]);
                            setBillOfMaterialErrors([]);
                            setProcessFlowErrors([]);
                            setDiagramErrors([]);
                            setItemServerError([]);

                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItem);
