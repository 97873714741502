import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import CreateMaterialRequisition from "./CreateMaterialRequisition";
import MRSIcon from "../../assets/mrs.svg";

import MaterialRequisitionList from "./MaterialRequisitionList";
import { pendingSoProduction } from "../../redux/PurchaseOrder/PoInfoActions";
import { connect } from "react-redux";
import MaterialRequisitionDetail from "./MaterialRequisitionDetail";
import EditMaterialRequisition from "./EditMaterialRequisition";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const MaterialRequisition = ({ poInfo, pendingSoProduction, isLogged }) => {
    const [currentSection, setCurrentSection] = useState("materialRequisitionList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "materialRequisitionList" };
    var materialRequisition = { buttonName: "Create Request", className: "employeeButton", setSection: setCurrentSection, sectionName: "createMaterialRequisition", callback: () => { pendingSoProduction(null) } };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "materialRequisitionDetail") }, sectionName: "materialRequisitionList" };

    const renderSection = (section) => {
        switch (section) {
            case "createMaterialRequisition":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={MRSIcon} pageTitle="Request Material Requisition" buttonList={[goBackButton]} />
                        <CreateMaterialRequisition setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "materialRequisitionList":
                return (
                    <React.Fragment>
                        {/* <div className="pageTitle">
                         <img className="pageTitleImage" alt="Page Title" src={MRSIcon}></img>
                            <span className="pageTitleText">Material Requisition List</span>
                            <div className="buttonList">
                                <button
                                    className="employeeButton"
                                    onClick={() => {
                                        setCurrentSection("createMaterialRequisition");
                                        pendingSoProduction(null);
                                    }}>                               
                                    Create Request
                                </button>
                            </div>
                         </div> */}
                        <PageTitle imgSrc={MRSIcon} pageTitle={"Material Requisition List"}
                            buttonList={isLogged.access["Material Requisition"] === "Create" || isLogged.access["Material Requisition"] === "Edit" || isLogged.access["Material Requisition"] === "App/Dec" ? [materialRequisition] : null} />
                        <MaterialRequisitionList setSection={(value) => setCurrentSection(value)} setApproval={setApprovalPage} setEditAndApprove={(value)=>setEditAndApprove(value)}/>
                    </React.Fragment>
                );

            case "materialRequisitionDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={MRSIcon}
                            pageTitle={approvalPage ? "Approve Material Requisition" : "Material Requisition Detail"}
                            buttonList={[goBackButton, downloadButton]}
                        />
                        <MaterialRequisitionDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editMaterialRequisition":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={MRSIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Material Requisition Request" : "Edit Material Requisition Request"}
                            buttonList={[goBackButton]}
                        />
                        <EditMaterialRequisition setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Material Requisition" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return { poInfo: state.poInfo, isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
    return { pendingSoProduction: () => dispatch(pendingSoProduction(null)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialRequisition);