import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { poEditInvoiceId, poInvoiceCreateId, poViewInvoiceId, setPoInvoiceTab } from "../../redux/PurchaseOrder/PoInfoActions";
import pageIcon from "../../assets/sheets.svg";
import fetchData from "../../serverCall/fetchData";
import FormElement from "../SmallComponents/FormElement";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";

const PurchaseInvoiceList = ({
    // props
    setSection,
    setLoadPo,
    setApproval,
    setEditAndApprove,

    // state
    isLogged,
    poInfo,

    // dispatch
    setPoInvoiceTab,
    poInvoiceCreate,
    poViewInvoiceId,
    poEditInvoiceId,
    addTokenToState,
}) => {
    const [loading, setLoading] = useState(true);

    const [noInvoiceRecords, setNoInvoiceRecords] = useState(false);
    const [noInvoiceUnapprovedRecords, setNoInvoiceUnapprovedRecords] = useState(false);
    const [noInvoiceApprovedRecords, setNoInvoiceApprovedRecords] = useState(false);
    const [plantId,setPlantId] = useState([]);
    const [poList, setPoList] = useState([]);
    const [invoiceUnApprovedList, setInvoiceUnApprovedList] = useState([]);
    const [approvedInvoiceList, setApprovedInvoiceList] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        //await getPurchaseOrdersList();
    }
    const access = isLogged.access["Purchase Invoice"];
    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPurchaseOrdersList() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/po-list-invoice-pre-create",
            headers: { token: isLogged.accessToken,module: "Purchase Invoice" },
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoInvoiceRecords(true);
            }

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setPoList(data2);
        }
        setLoading(false);
    }
   
    async function getInvoiceUnapprovedList() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/invoice-unapproved-list",
            headers: { token: isLogged.accessToken,module: "Purchase Invoice" },
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoInvoiceUnapprovedRecords(true);
            }

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setInvoiceUnApprovedList(data2);
        }
        setLoading(false);
    }

    async function getInvoiceApprovedList() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/invoice-approved-list",
            headers: { token: isLogged.accessToken,module: "Purchase Invoice" },
        });
        if (data.msg === "success") {
            if (data.asset.length === 0) {
                setNoInvoiceApprovedRecords(true);
            }

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setApprovedInvoiceList(data2);
        }
        setLoading(false);
    }

    const tablist = [
        { id: "purchaseOrderList", name: "Purchase Order List" },
        { id: "invoiceList", name: "Invoice List" },
        { id: "approvedInvoiceList", name: "Approved Invoice List" },
        // { id: "declinedInvoiceList", name: "Declined Invoice List" },
    ];

    useEffect(async () => {
        setLoading(true);
        await checkToken();

        switch (poInfo.poInvoiceTab) {
            case "purchaseOrderList":
                setPoList([]);
                getPurchaseOrdersList();
                break;

            case "invoiceList":
                setInvoiceUnApprovedList([]);
                getInvoiceUnapprovedList([]);

                break;

            case "approvedInvoiceList":
                setApprovedInvoiceList([]);
                getInvoiceApprovedList();
                break;

            default:
                break;
        }
    }, [poInfo.poInvoiceTab]);

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "purchaseOrderList":
                listCopy = poList.slice();
                break;

            case "invoiceUnapprovedList":
                listCopy = invoiceUnApprovedList.slice();
                break;

            case "invoiceApprovedList":
                listCopy = approvedInvoiceList.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "purchaseOrderList":
                setPoList(listCopy);

            case "invoiceUnapprovedList":
                setInvoiceUnApprovedList(listCopy);
                break;

            case "invoiceApprovedList":
                setApprovedInvoiceList(listCopy);
                break;
        }
    };

    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={poInfo.poInvoiceTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setPoInvoiceTab(tab.id)}
                >
                    {tab.name}
                    {tab.id === "contactDetails" ? poInfo.poInvoiceTab === "contactDetails" ? " *" : <span style={{ color: "red" }}> *</span> : null}
                </div>
            );
        });
    };

    const renderSubSection = () => {
        switch (poInfo.poInvoiceTab) {
            case "purchaseOrderList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">Order No</td>
                                    <td className="vendorListHeading">Order Date</td>
                                    <td className="vendorListHeading">QC Checking No</td>
                                    <td className="vendorListHeading">Qc Checking Date</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{poList.length > 0 ? renderPoList() : null}</tbody>
                        </table>
                        {noInvoiceRecords ? (
                            <div className="noRecordsYet">No QC Approved POs available for Invoicing</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );

            case "invoiceList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">Invoice No</td>
                                    <td className="vendorListHeading">Invoice Date</td>
                                    <td className="vendorListHeading">PO No</td>
                                    {/* <td className="vendorListHeading">QC Checking No</td> */}
                                    <td className="vendorListHeading">Vendor Name</td>
                                    {/* <td className="vendorListHeading">Order Type</td> */}
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeading">Invoice Status</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{invoiceUnApprovedList.length > 0 ? renderInvoiceUnapproved() : null}</tbody>
                        </table>
                        {noInvoiceUnapprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );

            case "approvedInvoiceList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <tr className="vendorListHeader">
                                    <td className="vendorListHeading">Invoice No</td>
                                    <td className="vendorListHeading">Invoice Date</td>
                                    <td className="vendorListHeading">PO No</td>
                                    <td className="vendorListHeading">Company Name</td>
                                    <td className="vendorListHeading">Created By</td>
                                    <td className="vendorListHeading">Invoice Status</td>
                                    <td className="vendorListHeading">Approved By</td>
                                    <td className="vendorListHeadingActions">Actions</td>
                                </tr>
                            </thead>
                            <tbody>{approvedInvoiceList.length > 0 ? renderInvoiceApproved() : null}</tbody>
                        </table>
                        {noInvoiceApprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                    </React.Fragment>
                );
        }
    };

    const renderPoList = () => {
        return poList.map((item, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className="vendorList"
                        key={item.poNo}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse("purchaseOrderList", i);
                        }}
                    >
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.poDate}</td>
                        <td className="vendorListInfo">
                            {item.qcNo.map((qc, i) => {
                                return i === item.qcNo.length - 1 ? (
                                    <span>{qc}</span>
                                ) : (
                                    <span>
                                        {qc}
                                        <br />
                                    </span>
                                );
                            })}
                        </td>

                        <td className="vendorListInfo">
                            {item.checkingDate.map((qc, i) => {
                                return i === item.checkingDate.length - 1 ? (
                                    <span>{qc}</span>
                                ) : (
                                    <span>
                                        {qc}
                                        <br />
                                    </span>
                                );
                            })}
                        </td>
                        <td className="vendorListInfo">{item.vendorName}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Create Invoice"
                                className="vendorActionImages"
                                src={pageIcon}
                                title={access === "Create" || access === "Edit"  || access=== "App/Dec"? "Create Invoice" : "Access Denied"}
                                onClick={(e) => { 
                                    e.stopPropagation();
                                    if(access === "Create" || access==="Edit" || access=== "App/Dec")
                                    setSection("createPurchaseInvoice");
                                    poInvoiceCreate(item.poId);
                                    setLoadPo(true);
                                }}
                                style={access === "Create" || access === "Edit" || access === "App/Dec" ?{ cursor: "pointer" } : { cursor: "not-allowed" }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("purchaseOrderList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderPoSubTable tab="unapprovedSo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderInvoiceUnapproved = () => {
        return invoiceUnApprovedList.map((item, index) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className="vendorList"
                        key={item.soId}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse("invoiceUnapprovedList", index);
                        }}
                    >
                        <td className="vendorListInfo">{item.invoiceNo}</td>
                        <td className="vendorListInfo">{item.invoiceDate}</td>
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.vendorName}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.invoiceStatus}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={item.invoiceStatus === "Draft" ? approveIconDisabled : approveIcon}
                                title={item.invoiceStatus === "Draft" ? "Can't approve Draft" : (access === "App/Dec"? "Approve Invoice" : "Access Denied")}
                                style={item.invoiceStatus !== "Draft" && access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.invoiceStatus !== "Draft" && access ==="App/Dec") {
                                        setSection("poInvoiceDetail");
                                        setApproval(true);
                                        poViewInvoiceId(item.invoiceId);
                                    }
                                }}
                            />

                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Invoice Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSection("poInvoiceDetail");
                                    setApproval(false);
                                    poViewInvoiceId(item.invoiceId);
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec"? editIcon : editIconDisabled}
                                title={access === "Edit" || access === "App/Dec" ? "Edit Item" : "No Edit Access"}
                                onClick={(e) => {
                                    if (access === "Edit" || access === "App/Dec") {
                                        e.stopPropagation();
                                        poEditInvoiceId(item.invoiceId);
                                        setSection("editPurchaseInvoice");
                                        poInvoiceCreate(item.poId);
                                        setEditAndApprove(false);
                                    }
                                }}
                                style={access === "Edit" || access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("invoiceUnapprovedList", index);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="unapprovedSo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderInvoiceApproved = () => {
        return approvedInvoiceList.map((item, index) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value==item.plantId) ) || plantId.length==0)
            return (
                <React.Fragment>
                    <tr
                        className="vendorList"
                        key={item.soId}
                        onClick={(e) => {
                            e.stopPropagation();
                            editItemCollapse("invoiceApprovedList", index);
                        }}
                    >
                        <td className="vendorListInfo">{item.invoiceNo}</td>
                        <td className="vendorListInfo">{item.invoiceDate}</td>
                        <td className="vendorListInfo">{item.poNo}</td>
                        <td className="vendorListInfo">{item.vendorName}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.invoiceStatus}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Invoice Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSection("poInvoiceDetail");
                                    setApproval(false);
                                    poViewInvoiceId(item.invoiceId);
                                }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("invoiceApprovedList", index);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approvedSo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderPoSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={"6"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Order Qty</td>
                            <td className="vendorListInnerTableHeading">Accepted Qty</td>
                            <td className="vendorListInnerTableHeading">Unit Price</td>
                            <td className="vendorListInnerTableHeading">Tax (%)</td>
                            <td className="vendorListInnerTableHeading">Net Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.invoiceLineItemId}>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.from}</td>
                                    <td className="vendorListInnertableInfo">{row.to}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.poQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.acceptedQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.unitPrice}</td>
                                    <td className="vendorListInnertableInfo">{row.tax}</td>
                                    <td className="vendorListInnertableInfo">{row.netPrice}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab === "unapprovedSo" ? "7" : "8"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item</td>
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                            <td className="vendorListInnerTableHeading">Unit Price</td>
                            <td className="vendorListInnerTableHeading">Tax (%)</td>
                            <td className="vendorListInnerTableHeading">Net Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.invoiceLineItemId}>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td>

                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                    <td className="vendorListInnertableInfo">{row.unitPrice}</td>
                                    <td className="vendorListInnertableInfo">{row.tax}</td>
                                    <td className="vendorListInnertableInfo">{row.netPrice}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div  style={{width:"250px",marginLeft:"25px"}}>
                <FormElement  setInput={(value)=>{setPlantId(value);}}
                                        inputType={"multiSelect"}
                                        value={plantId}
                                        hintText={"Plant Name"}
                                        options={isLogged.plantOptions}
                                        colSpan={1}
                                        rowSpan={1}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}/>
            </div>
            <div className="vendorListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        setPoInvoiceTab: (poInvoiceTab) => dispatch(setPoInvoiceTab(poInvoiceTab)),
        poInvoiceCreate: (invoiceCreateId) => dispatch(poInvoiceCreateId(invoiceCreateId)),

        poViewInvoiceId: (invoiceId) => dispatch(poViewInvoiceId(invoiceId)),
        poEditInvoiceId: (invoiceId) => dispatch(poEditInvoiceId(invoiceId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceList);
