import React, { useState } from 'react';
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from 'react-redux';
import productionIcon from "../../assets/production.svg";
import ProcessLogList from './processLogList';
import CreateProcessLog from './createProcessLog';
import BOMDetail from '../BillOfMaterials/BOMDetail';
import EditProcessLog from './editProcessLog';

const ProcessLog=({poInfo,isLogged})=>{
    const [currentSection, setCurrentSection] = useState("processLogList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);


    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "processLogList" };
    var createProcessLog = { buttonName: "Create Process Log", className: "employeeButton", setSection: setCurrentSection, sectionName: "createProcessLog" };
    var editButtonTemp = { buttonName: "EDit Process Log", className: "employeeButton", setSection: setCurrentSection, sectionName: "editProcessLog" };

    const renderSection = (section) => {
        switch (section) {
            case "createProcessLog":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle="Create Process Log" buttonList={[goBackButton]} />
                        <CreateProcessLog setSection={(value)=>setCurrentSection(value)}/>
                    </React.Fragment>
                );

            case "processLogList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle={"Process Log List"}
                            buttonList={isLogged.access["Process Log"] === "Create" || isLogged.access["Process Log"] === "Edit" || isLogged.access["Process Log"] === "App/Dec"
                                ? [createProcessLog] : null} />
                        <ProcessLogList setSection={(value)=>setCurrentSection(value)} setApproval={(val)=>setApprovalPage(val)}/>
                    </React.Fragment>
                );

            case "processLogDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={approvalPage ? "Approve Process Log" : "Process Log Detail"}
                            buttonList={[goBackButton]}
                        />
                    </React.Fragment>
                );

            case "editProcessLog":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Production Request" : "Edit Work Order"}
                            buttonList={[goBackButton]}
                        />
                        <EditProcessLog setSection={setCurrentSection}/>
                    </React.Fragment>
                );
            
            case "bomDetail":
                return(<React.Fragment>
                    <PageTitle imgSrc={productionIcon} pageTitle={"BOM Detail"} buttonList={[goBackButton]}/>
                    <BOMDetail module={"Process Log"}/>
                </React.Fragment>)
        }
    };

    return (
        <React.Fragment>
             <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Process Log" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps=(state)=>{
    return{
        isLogged: state.isLogged,
        poInfo: state.poInfo
    }
}

const mapDispatchToProps=()=>{
 return {

 }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProcessLog)