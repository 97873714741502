import React,{ useEffect,useState,Fragment } from "react";
import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import {  validateEmail, validateMandatory, validateMobileNumber  } from "../CommonFunctions/ValidateFields";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const EditSalesLead =({setCurrentSection,isLogged,storeInfo})=>{


    const [leadParamsList,setLeadParamsList] = useState({
        firstName: {
            inputType: "text",
            value: "",
            hintText: "First Name",
            colSpan: 8,
            error: false,
            errorMessage: "Please Enter A First Name",
            mandatory: true
          }, 
          lastName: {
            inputType: "text",
            value: "",
            hintText: "Last Name",
            colSpan: 6,
            error: false,
            errorMessage: "Please Enter A Last Name",
            mandatory: true
          },
        email: {
            inputType: "text",
            value: "",
            hintText: "Email",
            colSpan: 6,
            error: false,
            errorMessage: "Please provide Valid email address",
            mandatory: false
          },
          phone: {
            inputType: "number",
            value: "",
            hintText: "Phone No",
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter A Phone Number",
            mandatory: true
          },
         
          designation: {
            inputType: "text",
            value: "",
            hintText: "Designation",
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter A Designation",
            mandatory: false
          },
          companyName: {
            inputType: "options",
            value: "",
            options:[],
            hintText: "Company Name",
            colSpan: 4,
            error: false,
            errorMessage: "Please Enter A Company Name",
            mandatory: true
          },
         
    })
    const [leadError, setLeadError] = useState([])
    const [modalText,setModalText] = useState("")
    const [submitted,setSubmitted] = useState(false)
    const [isOpen,setIsOpen] = useState(false)

    async function getCustomers() {
      var data = await fetchData({
        requestingPage: "getParents",
        method: "get",
        url: "so-fetch/company-list",
        headers: { token: isLogged.accessToken, module: "Sales Lead" },
      });
      if (data.msg === "success") {
        // let paramsCopy = structuredClone(leadParamsList)
        // let Options = [];
        // data.asset?.forEach((parent) => {
        //   let optionList = { optionId: parent.customerId, optionName: parent.customer };
        //   Options.push(optionList);
  
        // });
        // paramsCopy = update(paramsCopy,{companyName:{options:{$set : data.asset}}})
        // setCustomerList(data.asset)
        // setLeadParamsList(paramsCopy)
        return data.asset
      }
    }

    const salesLeadDetail =async()=>{

        let data = await fetchData({
          requestingPage:"employeeDetail",
          method:"get",
          url:`so-fetch/sales-lead-detail/${storeInfo.salesLeadId}`,
          headers:{token:isLogged.accessToken,module:"Sales Lead"}
        })
    
        if(data.msg === "success"){
            let listCopy = structuredClone(leadParamsList)
               let keys = Object.keys(leadParamsList)
               let customer= await getCustomers()
               keys.map((item)=>{
                   if(item ==="companyName"){
                       let {companyId,companyName} = data?.asset[0]
                       let option = [{
                           value:companyId,
                           label:companyName,
                           optionId:companyId,
                           optionName:companyName
                        }];
                        
                        listCopy= update(listCopy,{companyName:{options:{$set:customer},value:{$set:companyId}}})
                        
                    }else{
                       listCopy= update(listCopy,{[item]:{value:{$set:data.asset[0][item]}}})
                   }
                   setLeadParamsList(listCopy)
               })

        }else{
            console.log(data)
        }
      }


    useEffect(()=>{
      // getCustomers()
      salesLeadDetail()
    },[])


    const submitOkClick =()=>{
      setIsOpen(false);
      setCurrentSection("salesLeadList")
    }

    const checkSalesLeadError = () => {
      var errorList = [];
      var paramsCopy = Object.assign(leadParamsList);
      let parameterList = Object.keys(leadParamsList)

      parameterList.forEach((element) => {
          if (paramsCopy[element].mandatory) {
              paramsCopy = update(paramsCopy, {
                  [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } },
              });
          }
          if (element === "phone") {
              paramsCopy = update(paramsCopy, {
                  [element]: { error: { $set: !validateMobileNumber(paramsCopy[element].value.toString()) } },
              });
          }
          if (element === "email") {
              if (paramsCopy[element].value.length > 0) {
                  paramsCopy = update(paramsCopy, {
                      [element]: { error: { $set: !validateEmail(paramsCopy[element].value) } },
                  });
              } else {
                  paramsCopy = update(paramsCopy, { [element]: { error: { $set: false } } });
              }
          }

          if (paramsCopy[element].error) {
              errorList.push(paramsCopy[element].errorMessage);
          }
      });

      if (errorList.length === 0) {
        let dataToSent = {
          firstName:leadParamsList.firstName.value,
          lastName:leadParamsList.lastName.value,
          email:leadParamsList.email.value,
          phone:leadParamsList.phone.value,
          companyName:leadParamsList.companyName.value,
          designation:leadParamsList.designation.value,
          leadId:storeInfo.salesLeadId
        }
         submitData(dataToSent) 
        } 
          setLeadError(errorList)
          setLeadParamsList(paramsCopy)
  };

  const submitData =async(dataToSent)=>{

    var detail = await fetchData({
      url : 'so-edit/edit-sales-lead',
      headers: {token: isLogged.accessToken, module :"Sales Lead"},
      requestingPage: "BillOfMaterial",
      method: "put",
      data:dataToSent
    });

    setSubmitted(true)
    if(detail.msg === "success"){
      setModalText("SuccessFully Edited The Lead");
      setIsOpen(true)
    }
    else{
      setModalText(detail.desc);
    }
  }

    const renderFormElements = ({ elementList, param }) => {
      return (
        <>
          {elementList.map((element, ind) => {
            return (
              // <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
                <FormElement
                  inputType={param[element].inputType}
                  value={param[element].value}
                  setInput={(value) =>
                    updateParameter(element, "value", value, param)
                  }
                  hintText={param[element].hintText}
                  mandatory={param[element].mandatory}
                  colSpan={param[element].colSpan}
                  options={
                    param[element].inputType === "options" || param[element].inputType === "multiSelect"
                      ? param[element].options
                      : null
                  }
                  error={param[element].error}
                  key={ind}
                />
              // </span>
            );
  
          })}
  
        </>
      );
    };

    const updateParameter = (element, key, value, section) => {

      let salesLeadCopy = Object.assign(leadParamsList);
  
      salesLeadCopy = update(salesLeadCopy, {
        [element]: { [key]: { $set: value } },
      });
      setLeadParamsList(salesLeadCopy);
     
    };
  

return(
  <Fragment>
  <CreateEditModal modalIsOpen={isOpen} modalText={modalText}
         dataSubmitted={submitted} submitOkClick={submitOkClick} />
 <div className="formArea" >
   <div
     style={{
      width: "900px",
      margin: "0 auto 4rem",
      padding: "3rem",
      border: "1px solid lightgray",
      borderRadius: "5px",
      backgroundColor: "white",
      // overflow: "hidden",
     }}>
     <div className = "createCustomerGeneralGrid" >

       {renderFormElements({
         elementList: Object.keys(leadParamsList),
         param: leadParamsList,
       })}
        <button
            className="employeeButton"
            style ={{height: "fit-content",
              marginTop: "30px"}}
            onClick={() => {
              setCurrentSection("createCompanyEditPage");
            }}
        >
          Create New Company
        </button>
     </div>

   </div>
 </div>
 <div className="formSubmitArea">
   <div className="formSubmitInnerArea">
     <p className="formSubmitErrorTextArea">{leadError[0]}</p>
     <button className="submitButton" onClick={checkSalesLeadError}>Submit</button>
   </div></div>
</Fragment>
)
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    storeInfo:state.storeInfo
  };
};

export  default connect(mapStateToProps)(EditSalesLead);