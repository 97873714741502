import React,{useState,Fragment} from 'react';
import {connect} from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageTitle from 'components/SmallComponents/PageTitle';
import TopBanner from 'components/SmallComponents/TopBanner';
import bundleCreationIcon from "assets/bundle-creation-black.svg";
import MenuList from 'components/Menu/MenuList';
import BundleList  from './bundleList';
import EditBundle from './editBundle';
import CreateBundle from './createBundle';

 const BundleCreation = ({isLogged}) => {
  const [currentSection, setCurrentSection] = useState("bundleList");

  var history = useHistory();

  const pushBack = (section) => {
      history.push(section);
  };

  var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "bundleList" }];

  var goBackToPackagingButton = {
      buttonName: "Back",
      className: "goBackButton",
      setSection: pushBack,
      sectionName: "/packaging",
  };

  var createBundleButton = {
      buttonName: "Create Bundle",
      className: "employeeButton",
      setSection: setCurrentSection,
      sectionName: "createBundle",
  };

  const renderSection=(section)=>{
    switch (section) {
      case "createBundle":
        return(
          <Fragment>
            <PageTitle imgSrc={bundleCreationIcon} pageTitle="Create Bundle" buttonList={goBackButton} />
            <CreateBundle setSection={(value) => setCurrentSection(value)} />
          </Fragment>
        )
        
    
      case "editBundle":
        return(
          <Fragment>
            <PageTitle imgSrc={bundleCreationIcon} pageTitle="Edit Bundle" buttonList={goBackButton} />
            <EditBundle setSection={(value) => setCurrentSection(value)} />
          </Fragment>
        )
      case "bundleList":
        return(
        <Fragment>
        <PageTitle
          imgSrc={bundleCreationIcon}
          pageTitle="Bundle Creation"
          buttonList={
              isLogged.access["Bundle Creation"] === "Create" || isLogged.access["Bundle Creation"] === "Edit"
                  ? [createBundleButton, goBackToPackagingButton]
                  : [goBackToPackagingButton]
          }
      />
      <BundleList setSection={(value) => setCurrentSection(value)} />
      </Fragment>
      )
      default:
        <>Page Not Found</>
        break;
    }
  }
  return (
      <Fragment>
          <div className="completePage">
              <MenuList selectedMenu="Store" selectedSubMenu="Packaging" />
              <div className="detailsContainer">
                  <TopBanner />
                  {renderSection(currentSection)}
              </div>
          </div>
      </Fragment>  
      )
}

const mapStateToProps = (state) => ({
  isLogged: state.isLogged
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BundleCreation)