import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import FormHint from "../SmallComponents/FormHint";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
const UploadDocument =({isLogged,poInfo,setSection})=>{

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");
    const [lrDocument, setLrDocument] = useState("");
  const [outwardDocument, setOutwardDocument] = useState("");

  useEffect(()=>{
    getInformation()
  },[])

  async function getInvoiceDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "so-fetch/sales-invoice-detail/" + poInfo.soViewInvoiceId,
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
    });
    if (data.msg === "success") {
      return data.asset[0];
    } else {
      // console.log(data);
    }
  }

  async function getInformation() {
    var invoiceDet = await getInvoiceDetail();
    if (invoiceDet.lrDocument !== null) {
        setLrDocument({ name: invoiceDet.lrDocument });
      }
      if (invoiceDet.outwardDocument !== null) {
        setOutwardDocument({ name: invoiceDet.outwardDocument });
      }
  }

    const submitOkClick = () => {
        setIsOpen(false);
            setSection("invoiceDetail");
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setModalText("Uploading Document. Please wait...");

        var result = await fetchData({
            requestingPage: "createItem",
            method: "put",
            url: "so-edit/sales-invoice-upload",
            headers: { token: isLogged.accessToken,module:"Sales Invoice" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully uploaded Document!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            // setItemServerError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const checkErrors = () => {
        var data2Server = new FormData();
        if (lrDocument !== "" && lrDocument?.size > 0) {
            data2Server.append("lrDocument", lrDocument);
          }
          if (outwardDocument !== "" && outwardDocument?.size > 0) {
            data2Server.append("outwardDocument", outwardDocument);
          }
          data2Server.append("invoiceId",poInfo.soViewInvoiceId)
          if(lrDocument !== "" ||outwardDocument !== ""){

              submitData(data2Server);
          }
    };

    return(
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "95%",
                        margin: "0 auto 4rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        boxSizing: "border-box",
                    }}
                >
                    <div className="itemsUploadButtonsDiv">
                    <div className="createSalesInvoiceGrid2">
                {lrDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint hintText="LR Document" mandatory={false} />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {lrDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setLrDocument("")}
                        title="Remove and Re-upload LR Copy">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="LR Document"
                    setInput={(file) => {
                      setLrDocument(file);
                    }}
                  />
                )}

                {outwardDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint hintText="Outward Document" mandatory={false} />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {outwardDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setOutwardDocument("")}
                        title="Remove and Re-upload Outward Document">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="Outward Document"
                    setInput={(file) => {
                      setOutwardDocument(file);
                    }}
                  />
                )}
              </div> 
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea"></p>
                    <button
                        className="submitButton"
                        onClick={() => {
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      poInfo: state.poInfo,
    };
  };

export default connect(mapStateToProps)(UploadDocument);
