import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { ResponsiveBar } from "@nivo/bar";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";
import { addDaysToDate, dateFormatting } from "components/CommonFunctions/utils";

const StockTransferDashboard = ({isLogged}) => {

  const [queryParams, setQueryParams] = useState({
    showChartBy: {
      inputType: "options",
      value: "MM-YY",
      mandatory: true,
      options: [
        { optionId: "DD-MM-YY", optionName: "Show By Days" },
        { optionId: "MM-YY", optionName: "Show By Months" },
        { optionId: "YYYY", optionName: "Show By Years" },
      ],
      error: false,
      hintText: "Show Chart By",
      isClearable: false,
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: "04-01-" + new Date().getFullYear(),
      hintText: "From Date",
      mandatory: true,
      error: false,
      mindate: true,
      maxdate: true,
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      mandatory: true,
      error: false,
      maxdate: true,
    },
    
    plantId: {
        inputType: "options",
        value: "",
        options: isLogged.plantOptions,
        hintText: "Plant Name",
        mandatory: false,
        error: false,
      },
      storageId: {
        inputType: "options",
        value: "",
        hintText: "Store",
        mandatory: false,
        colSpan: 6,
        error: false,
        options: [],
        errorMessage: "",
      },
      items:{
        inputType: "multiSelect",
        value: [],
        options: [],
        hintText: "Items",
        mandatory: false,
        error: false,
      },
  })

  const [cardData1, setCardData1] = useState([]);
  const [barData, setBarData] = useState([]);
  const [storageLocList, setStorageLocList] = useState({});
  const [itemTrendList,setItemTrendList]=useState([]);


  const renderFormElements = ({ elementList, params }) => {
    return (
      <div className="dashboardCard dashboardParams" >
        {elementList.map((item, ind) => {
          return (
            <div key={ind} >
              <FormElement
                key={item}
                inputType={params[item].inputType}
                value={params[item].value}
                setInput={(value) => {
                  updateSalesQuoteParameters({
                    param: params,
                    paramName: item,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={params[item].hintText}
                mandatory={params[item].mandatory}
                colSpan={params[item].colSpan}
                options={
                  params[item].inputType === "options" ||
                    params[item].inputType === "multiSelect"
                    ? params[item].options
                    : null
                }
                error={params[item].error}
                isClearable={params[item].isClearable}
                maxdate={params[item].maxdate}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "rgb(11,87,180)",
          strokeWidth: 2,
        },
      },
    },
  };

  const renderBarGraph = (data, indexBy, key, title = "Sales Amount",count) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          width: "32.8rem",
          gridColumn: `1/span 1`,
          alignSelf: 'start',
          gridRow: `3 / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
          <ResponsiveBar
            data={data}
            keys={key}
            indexBy={indexBy}
            margin={{ top: 10, right: 0, bottom: 50, left: 65 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            tooltip={(props) => {
              let  { value, indexValue } = props
              return (
                <div className="dashBoardToolTip">
                  {indexValue}
                  <br />
                  {props.data.count || value}
                  <br />
                </div>
              );
            }}
            theme={theme}
            colors={["rgb(11,87,180)"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 1,
              tickPadding: 5,
              tickRotation: -90,
              legend: "",
              legendPosition: "middle",
              legendOffset: 52,
            }}
            axisLeft={{
              tickSize: 1,
              tickPadding: 4,
              tickRotation: -1,
              legend: title,
              legendPosition: "middle",
              legendOffset: -60,
              format: (value) => `   ₹${Number(value) / 100000} L`,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            label={(d) => null}
            layers={["axes", "bars", "markers", "legends"]}
          />
        </div>
      </div>
    );
  };

  //get filtered quotes details
  const getDbResponse =async(qryParams)=>{
    let data = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "so-fetch/stock-dashboard",
      headers: { token: isLogged.accessToken, module: "Stock Transfer Dashboard" },
      data: qryParams,
    });
   if(data.msg ==="success"){
    setCardData1([
      {
        type: "numbers",
        title: "Stock Count",
        value: data.asset.barData.length,
      },
    ])
    setBarData(data.asset.barData)
        setItemTrendList(data.asset.itemTrendList);
    // return data.asset
   }
  }

  useEffect(() => {

    var queryParamsCopy = Object.assign(queryParams);

    if (queryParams.plantId.value !== "") {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: {
          options: { $set: storageLocList[queryParams.plantId.value]||[] },
        },
      });
      setQueryParams(queryParamsCopy);
    } else {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: { options: { $set: [] }, value: { $set: "" } },
      });
      setQueryParams(queryParamsCopy);
    }
  }, [queryParams.plantId.value]);


  useEffect(()=>{

    let {fromDate,toDate,plantId,storageId,items,showChartBy} =queryParams

    fromDate = dateFormatting(fromDate.value)
    toDate = dateFormatting(addDaysToDate(toDate.value,1))

     let qryParams = {
       dates :[fromDate,toDate], 
       showChartBy:showChartBy.value,
       plantId :plantId.value,
       storageId:storageId.value,
       itemIds:items.value.map((it)=>it?.value)
     }
    getDbResponse(qryParams)

  },[queryParams])

  const updateSalesQuoteParameters = ({ param, paramName, key, value }) => {
    let paramsCopy = Object.assign(param);
    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });

    setQueryParams(paramsCopy);
  };

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{itemsTab:"Active"},
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemList.push(itemObj);
      });

    }
    return [itemList];
  }

  const getInformation =async()=>{
    let [itemList] = await getItems();
    var [plantList,storageLocListFormatted] = await getPlants();
    let copy = structuredClone(queryParams)
   
    copy = update(copy,{
        items: { options: { $set: itemList } },
    });
    
    setQueryParams(copy);
    setStorageLocList(storageLocListFormatted);
  }

  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

    }
    return [plantList, storageLocList];
  }

  useEffect(() => {  
    getInformation();
  }, [])

  const renderItemTrendList = (itemTrendList) => {
    return (
      <div
        className="dashboardCard itemTrendList">
        <div className="dashboardCardHeader">
          <span className="dashboardCardTitle">Items Stock Transfer Count</span>
        </div>
        <div className="dashboardAlertBody">
          {itemTrendList?.length > 0 ? (
            itemTrendList.map((stock, ind) => {
                return (
                  <div className="alertStockLine" key={ind}>
                    <span style={{ flex: "1 0" }}>{stock.itemName}</span>
                    <span>{stock.stockCount}</span>
                  </div>
                );
            })
          ) : (
            <div className="noRecordsYet">No Item Has been Transfer</div>
          )}
        </div>
      </div>
    );
};

  const renderSubSection = () => {
    return (
      <>
        <div className="dashboardGrid">
          {renderFormElements({
            elementList: Object.keys(queryParams),
            params: queryParams,
          })}
          <div className="cardsContainer" style={{justifyItems: "center"}}>
            {renderCards(cardData1)}
          </div>
           
          {renderBarGraph(
                barData,
                "createDate",
                ["totalTransfer"],
                "Stock Transfer"
              )}
            {renderItemTrendList(itemTrendList)}
        </div>
      </>
    )
  }

  const renderCards = (data) => {
    return data?.map((item, ind) => {
      return (
        <div
          className="dashboardCard"
          key={item.title}
          style={{ height: "120px", width: "250px" }}>
          <div className="dashboardCardHeader">
            <span className="dashboardCardTitle">{item.title}</span>
          </div>
          <div className="dashboardCardBody">
            <div className="dashSingle">
              <div>    
               {item.value}</div>
            </div>
          </div>
              <div style={{textAlign: "center"}}>{item?.label||""}</div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Dashboard" selectedSubMenu="Stock Transfer Dashboard" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Stock Transfer Dashboard" />
          <span
            className="hintText"
            style={{ color: "GrayText", fontSize: "13px", marginLeft: "40px", marginBottom: "0px" }}>
            *Dashboard data is mainly based on Stock transfer
          </span>
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

export default connect(mapStateToProps)(StockTransferDashboard) 