import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const CreateProcess = ({
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
}) => {
    useEffect(() => {
     
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getRoles();
    }

    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getRoles() {
        var data = await fetchData({
            requestingPage: "itemProcessList",
            method: "get",
            url: "fetch/item-processes",
            headers: { token: isLogged.accessToken,module:"Item Process" },
        });
        if (data.msg === "success") {
            setItemProcessList(data.asset);
        } else {
            console.log(data);
        }
    }

    async function createItemProcess() {
        setDataSubmitted(false);

        var dataSent = await fetchData({
            requestingPage: "createItemProcess",
            method: "post",
            url: "create/item-process",
            headers: { token: isLogged.accessToken,module:"Item Process" },
            data: { processName: newItemProcess },
        });
        if (dataSent.msg === "success") {
            setModalText("Successfully created Item Process!");
            setNewItemProcess("");
            getInformation();
        } else {
            setModalText(`Upload failed: ${dataSent.desc}`);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    const [itemProcessList, setItemProcessList] = useState([]);
    const [newItemProcess, setNewItemProcess] = useState("");

    const renderItemTypeList = () => {
        return itemProcessList.map((processType) => {
            return (
                <tr className="roleList" key={processType.processId}>
                    <td className="roleName">{processType.processName}</td>
                </tr>
            );
        });
    };

    return (
        <div className="rolePagesContainer">
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="roleListArea">
                <table className="roleListTable">
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Process Name</span>
                        </td>
                    </tr>

                    <tr className="createRole">
                        <input
                            className="createRoleInput"
                            value={newItemProcess}
                            onChange={(e) => setNewItemProcess(e.target.value)}
                            autoFocus
                            onKeyUp={(e) => {
                                e.preventDefault();
                                if (e.key === "Enter" || e.keyCode === 13) {
                                    var submit = document.querySelector("#SubmitCreate");
                                    submit.click();
                                }
                            }}
                        />
                        <button
                            className="cancelRoleButton"
                            onClick={() => {
                                setNewItemProcess("");
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            id="SubmitCreate"
                            className="createRoleButton"
                            onClick={() => {
                                if (newItemProcess.trim() !== "") {
                                    createItemProcess();
                                    setIsOpen(true);
                                }
                            }}
                        >
                            Add
                        </button>
                    </tr>

                    {renderItemTypeList()}
                </table>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // editItemType: (itemTypeId) => dispatch(editItemTypeId(itemTypeId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProcess);
