import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const EditSalesReturn = ({
  isLogged,
  poInfo,
  addTokenToState,
  setSection,
  editApprove,
}) => {
  const salesReturnParamsList = [
    ["soNo"],
    ["returnType", "returnDate", "transporterName"],
  ];

  const [salesReturnParams, setSalesReturnParams] = useState({
    soNo: {
      inputType: "options",
      value: "",
      hintText: "Sales Order No",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "Please select Sales Order No",
    },
    returnType: {
      inputType: "options",
      value: "return",
      hintText: "Return Type",
      mandatory: true,
      colSpan: 6,
      error: false,
      options: [{ optionName: "Return", optionId: "return" }],
      errorMessage: "Select return type",
      disabled: true,
    },
    returnDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Return Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      maxdate: true,
      errorMessage: "Please Select return date",
    },

    transporterName: {
      inputType: "text",
      value: "",
      hintText: "Transporter Name",
      mandatory: false,
      colSpan: 6,
      error: false,
      errorMessage: "",
    },
  });

  useEffect(() => {
    getInformation();
  }, []);

  useEffect(() => {
    if (salesReturnParams.soNo.value !== "") {
      getSoDetail();
    }
  }, [salesReturnParams.soNo.value]);

  const [soDetail, setSoDetail] = useState({});
  const [showCustomerDetail, setShowCustomerDetail] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [soReturnDetail, setSoReturnDetail] = useState({});

  const [salesReturnDocument, setSalesReturnDocument] = useState("");

  const history = useHistory();
  async function getInformation() {
    await checkToken();
    var soList = await getSoApprovedList();
    var soReturnDetail2 = await getSoReturnDetail();


    var paramsCopy = Object.assign(salesReturnParams);
    paramsCopy = update(paramsCopy, {
      soNo: {
        options: { $set: soList },
        value: { $set: soReturnDetail2.soId },
      },
      returnDate: { value: { $set: soReturnDetail2.returnDate } },

      transporterName: {
        value: {
          $set:
            soReturnDetail2.transporterName !== null
              ? soReturnDetail2.transporterName
              : "",
        },
      },
    });
    setTotalAmount(soReturnDetail2.totalAmount);
    setInstruction(
      soReturnDetail2.instruction !== null
        ? soReturnDetail2.salesInstruction
        : ""
    );
    setSoReturnDetail(soReturnDetail2);
    setSalesReturnParams(paramsCopy);
    if (soReturnDetail2.returnDocument !== null) {
      setSalesReturnDocument({ name: soReturnDetail2.returnDocument });
    }
  }

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getSoApprovedList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: "so-fetch/so-approved-list-mini",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      var optionArray = [];
      data.asset.forEach((dataItem) => {
        var a = { optionName: dataItem.soNo, optionId: dataItem.soId };
        optionArray.push(a);
      });

      return optionArray;
    } else {
      console.log(data);
    }
  }

  async function getSoDetail() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: "so-fetch/so-detail/" + salesReturnParams.soNo.value,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
   
    if (data.msg === "success") {
      var soDetailCopy = data.asset[0];

      soDetailCopy.lineItems.forEach((item) => {
        var returnLineItem = soReturnDetail.lineItems.filter(
          (line) => line.soLineItemId === item.soLineItemId
        );

        item.returnLineItemId = null;
        item.toSend = false;
        item.quantityError = false;
        item.quantityErrorMessage = "Please enter a valid return quantity";
        item.returnQuantity = "";

        if (returnLineItem.length > 0) {
          item.returnQuantity = returnLineItem[0].quantity;
          item.returnLineItemId = returnLineItem[0].returnLineItemId;
          item.payAmount = returnLineItem[0].payAmount;
        }
      });
      setSoDetail(soDetailCopy);
      setShowCustomerDetail(true);
      // setItemsTotalCost(parseFloat(soDetailCopy.netTotal));
      // setOtherCharges(parseFloat(soDetailCopy.otherCharges));
      // setTransportCharges(parseFloat(soDetailCopy.transportCharges));
      // setTotalAmount(parseFloat(soDetailCopy.totalAmount));
    } else {
      console.log(data);
    }
  }

  async function getSoReturnDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "so-fetch/sales-return-detail/" + poInfo.editSoReturnId,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });
    if (data.msg === "success") {
      // console.log(data.asset[0].quotationNo);
      // setSoReturnDetail(data.asset[0]);
      // setLineItemsList(data.asset[0].lineItems);
      return data.asset[0];
    } else {
      console.log(data);
    }
  }

  const updateSalesReturnParameter = ({ paramName, section, key, value }) => {
    if (section === "general") {
      var paramsCopy = Object.assign(salesReturnParams);

      paramsCopy = update(paramsCopy, {
        [paramName]: { [key]: { $set: value } },
      });

      if (paramName === "soNo" && value === "") {
        setSoDetail({});
        setShowCustomerDetail(false);
      }

      setSalesReturnParams(paramsCopy);
    }
  };

  const updateLineItems = (key, index, value) => {
    var paramsCopy = JSON.parse(JSON.stringify(soDetail));

    paramsCopy = update(paramsCopy, {
      lineItems: { [index]: { [key]: { $set: value } } },
    });

    var updatedList = paramsCopy.lineItems.slice();

    if (!isNaN(updatedList[index].returnQuantity)) {


      var discount = 0;
      !isNaN(parseFloat(updatedList[index].discount))
        ? (discount = parseFloat(updatedList[index].discount) / 100)
        : (discount = 0);

      var amount =
        parseFloat(updatedList[index].returnQuantity) *
          (parseFloat(updatedList[index].unitPrice) -
            discount * parseFloat(updatedList[index].unitPrice)) +
        (parseFloat(updatedList[index].tax) / 100) *
          (parseFloat(updatedList[index].returnQuantity) *
            (parseFloat(updatedList[index].unitPrice) -
              discount * parseFloat(updatedList[index].unitPrice)));

    }

    if (!isNaN(amount)) {
      updatedList = update(updatedList, {
        [index]: { payAmount: { $set: amount } },
      });
    } else {
      updatedList = update(updatedList, {
        [index]: { payAmount: { $set: 0 } },
      });
    }

    var newNetTotal = 0;
    updatedList.forEach((item) => {
      if (!isNaN(item.payAmount)) {
        newNetTotal = parseFloat(newNetTotal) + parseFloat(item.payAmount);
        // } else {
        //     console.log(item, "** Not Valid Number");
        //     newNetTotal = 0;
      }
    });

    paramsCopy = update(paramsCopy, { lineItems: { $set: updatedList } });

    newNetTotal = newNetTotal.toFixed(2);

    setTotalAmount(parseFloat(newNetTotal));


    setSoDetail(paramsCopy);
  };

  const [soReturnError, setSoReturnError] = useState([]);

  const checkErrors = () => {
    var errorList = [];
    var paramsCopy = Object.assign(salesReturnParams);
    ["soNo", "returnType", "returnDate", "transporterName"].forEach((item) => {
      if (paramsCopy[item].mandatory) {
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      }

      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });

    var soDetailCopy = JSON.parse(JSON.stringify(soDetail));

    var proceed = false;

    soDetailCopy?.lineItems?.forEach((line) => {

      if (line.returnQuantity !== "") {
        if (!isNaN(line.returnQuantity) && parseInt(line.returnQuantity) > 0) {
          if (parseInt(line.returnQuantity) > line.quantity) {
            line.quantityError = true;
            line.toSend = false;
            errorList.push(line.quantityErrorMessage);
          } else {
            line.toSend = true;
            line.quantityError = false;
            proceed = true;
          }
        } else {
          line.quantityError = true;
          errorList.push(line.quantityErrorMessage);
          line.toSend = false;
        }
      } else {
        line.toSend = false;
      }
    });

    if (errorList.length === 0 && proceed) {
      var data2server = new FormData();

      data2server.append("returnId", soReturnDetail.returnId);
      data2server.append("soId", soDetailCopy.soId);
      data2server.append("returnType", "Return");
      data2server.append("returnDate", paramsCopy.returnDate.value);
      data2server.append("totalAmount", totalAmount);

      if (paramsCopy.transporterName.value !== "") {
        data2server.append("transporterName", paramsCopy.transporterName.value);
      }

      if (instruction !== "") {
        data2server.append("instruction", instruction);
      }

      if (salesReturnDocument !== "" && salesReturnDocument?.size > 0) {
        data2server.append("salesReturnDocument", salesReturnDocument);
      }

      var lineItemsToServer = [];
      soDetailCopy.lineItems.forEach((item) => {
        var newItem = {};
        if (item.toSend) {
          newItem.soLineItemId = item.soLineItemId;
          newItem.itemId = item.itemId;
          newItem.quantity = parseInt(item.returnQuantity);
          newItem.payAmount = item.payAmount;
          newItem.returnLineItemId = item.returnLineItemId;

          lineItemsToServer.push(newItem);
        }
      });

      data2server.append("returnLineItems", JSON.stringify(lineItemsToServer));
      submitData(data2server);

    } else {
      if (errorList.length === 0) {
        setSoReturnError(["No Data to Submit"]);
        setModalText("No Data to Submit");
        setDataSubmitted(true);
        setIsOpen(true);
      }
    }


    setSoDetail(soDetailCopy);
    setSalesReturnParams(paramsCopy);
    setSoReturnError(errorList);
  };

  const submitData = async (data) => {
    setIsOpen(true);
    setDataSubmitted(false);
    var result = await fetchData({
      requestingPage: "createCustomer",
      method: "put",
      url: "so-edit/sales-return",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
      data: data,
    });

    if (result.msg === "success") {
      if (editApprove) {
        setModalText("Edited Successfully. Approving Sales Return...");
        approveSoReturn();
      } else {
        setModalText("Sales Return Edited Successfully!");
        setDataSubmitted(true);
      }
    } else {
      setModalText(`Upload failed: ${result.desc}`);
      setSoReturnError([result.desc]);
      setDataSubmitted(true);
    }
  };

  async function approveSoReturn() {
    setIsOpen(true);
    setDataSubmitted(false);
    var data = await fetchData({
      requestingPage: "approvePo",
      method: "put",
      url: `so-edit/sales-return-approve/${poInfo.viewSoReturnId}`,
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    setDataSubmitted(true);
    if (data.msg === "success") {
      setModalText("Approved Sales Order Return");
    } else {
      setModalText(data.desc);
      setSoReturnError([data.desc]);
    }
  }

  const RenderCustomerDetail = () => {
    return (
      <React.Fragment>
        <div className="detailTitle">{soDetail.customerName}</div>
        <div className="detailText">
          {`Customer ID: ${soDetail.customerId}`},
          <br /> {soDetail.customerAddress},
          <br /> {soDetail.customerCity} {soDetail.state}{" "}
          {soDetail.customerPinCode !== null
            ? ` - ${soDetail.customerPinCode}`
            : null}
          {soDetail.customerMobile !== null ||
          soDetail.customerPhone !== null ? (
            <br />
          ) : null}
          {soDetail.customerMobile !== null || soDetail.customerPhone !== null
            ? `Phone: `
            : null}
          {soDetail.customerMobile !== null ? soDetail.customerMobile : null}
          {soDetail.customerPhone !== null
            ? `, ${soDetail.customerPhone} `
            : null}
          {soDetail.customerEmail !== null ? <br /> : null}
          {soDetail.customerEmail !== null
            ? `Email Id: ${soDetail.customerEmail}`
            : null}
          {soDetail.customerGstNo !== null ? <br /> : null}
          {soDetail.customerGstNo !== null
            ? `GST No:  ${soDetail.customerGstNo}`
            : null}
        </div>
      </React.Fragment>
    );
  };

  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value}
          setInput={(value) => {
            updateSalesReturnParameter({
              section,
              paramName: element,
              key: "value",
              value: value,
            });
          }}
          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
          maxdate={param[element].maxdate}
        />
      );
    });
  };
  const renderErrorMessage = () => {
    if (soReturnError.length > 0) {
      return soReturnError[0];
    } else return null;
  };

  const submitOkClick = () => {
    setIsOpen(false);
    if (soReturnError.length === 0) {
      setSection("salesOrderList");
    }
    setModalText("Creating Sales Order Return. Please wait...");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Uploading form. Please wait...");
  const lineItemsParams = isLogged.showHideWeight
    ? [
        "itemName",
        "from",
        "to",
        "netWeight",
        "grossWeight",
        "hsnCode",
        "uom",
        "quantity",
        "unitPrice",
        "tax",
        "discount",
        "netPrice",
        "returnQuantity",
        "payAmount",
      ]
    : [
        "itemName",
        "hsnCode",
        "uom",
        "quantity",
        "unitPrice",
        "tax",
        "discount",
        "netPrice",
        "returnQuantity",
        "payAmount",
      ];
  return (
    <React.Fragment>
      <CreateEditModal
        modalIsOpen={modalIsOpen}
        modalText={modalText}
        dataSubmitted={dataSubmitted}
        submitOkClick={submitOkClick}
      />
      <div className="formArea">
        <div
          style={{
            width: "fit-content",
            margin: "0 auto 4rem",
            // padding: "3rem 3rem 1rem 3rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1" }}>
              <div className="createSalesInvoiceGrid">
                {renderFormElements({
                  elementList: salesReturnParamsList[0],
                  param: salesReturnParams,
                  section: "general",
                })}
              </div>

              {soDetail.soDate !== undefined ? (
                <div className="createSalesInvoiceGrid">
                  <div
                    style={{
                      gridColumn: "auto / span 6",
                      display: "flex",
                      alignItems: "center",
                      // paddingLeft: "10px",
                      color: "#333333",
                    }}>
                    Order Date:&nbsp;&nbsp;
                    <span style={{ color: "#333333", fontWeight: "normal" }}>
                      {soDetail.soDate !== undefined ? soDetail.soDate : "-"}
                    </span>
                  </div>
                  <div
                    style={{
                      gridColumn: "auto / span 6",
                      display: "flex",
                      alignItems: "center",
                      // paddingLeft: "10px",
                      color: "#333333",
                    }}>
                    Exp Delivery Date:&nbsp;&nbsp;
                    <span style={{ color: "#333333", fontWeight: "normal" }}>
                      {soDetail.expectedDeliveryDate !== undefined
                        ? soDetail.expectedDeliveryDate
                        : "-"}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="createSalesInvoiceGrid">
                {renderFormElements({
                  elementList: salesReturnParamsList[1],
                  param: salesReturnParams,
                  section: "general",
                })}
              </div>
            </div>

            <div style={{ width: "300px" }}>
              <div className="vendorStoreDetailArea">
                <div className="poVendorAddressDetail">
                  {showCustomerDetail ? <RenderCustomerDetail /> : null}
                </div>
              </div>
            </div>
          </div>

          {soDetail.lineItems?.length > 0 ? (
            <table className="createItemPurchaseTable">
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className={"stickyFirstColumn"}>Item </td>
                  {isLogged.showHideWeight ? (
                    <>
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>
                    </>
                  ) : null}
                  <td>HSN No</td>
                  <td>UOM</td>
                  <td>Quantity</td>
                  <td>Unit Price</td>
                  <td>Tax (%)</td>
                  <td>Discount (%)</td>
                  <td>Net Price</td>
                  <td>Return Quantity</td>
                  <td>Paid Amount</td>
                </tr>
              </thead>
              <tbody>
                {soDetail.lineItems.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsParams.map((key, i) => {
                      switch (key) {
                        case "returnQuantity":
                          return (
                            <td key={i} style={{ width: "0px" }}>
                              <input
                                className={
                                  soDetail.lineItems[j].quantityError
                                    ? "createInwardInputError"
                                    : "createInwardInput"
                                }
                                type="text"
                                value={row[key]}
                                onChange={(e) =>
                                  updateLineItems(key, j, e.target.value)
                                }
                              />
                            </td>
                          );

                        case "payAmount":
                          var discount = 0;
                          !isNaN(parseFloat(row.discount))
                            ? (discount = parseFloat(row.discount) / 100)
                            : (discount = 0);

                          var amount =
                            parseFloat(row.returnQuantity) *
                              (parseFloat(row.unitPrice) -
                                discount * parseFloat(row.unitPrice)) +
                            (parseFloat(row.tax) / 100) *
                              (parseFloat(row.returnQuantity) *
                                (parseFloat(row.unitPrice) -
                                  discount * parseFloat(row.unitPrice)));

                          return (
                            <td key={i}>
                              {row.returnQuantity !== ""
                                ? !isNaN(amount)
                                  ? amount < 0
                                    ? "-"
                                    : amount
                                  : "-"
                                : "-"}
                            </td>
                          );
                        case "itemName":
                          return(
                            <td key={i} className={"stickyFirstColumn"} >{row[key] !== null ? row[key] : '-'}</td>
                          )
                        default:
                          return (
                            <td key={i}>
                              {row[key] !== null ? row[key] : "-"}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          <div className="poNetTotalSurchargeDiv">
            <div className="purchaseInstruction">
              <div>
                No Items: <span>{soDetail.lineItems?.length}</span>
              </div>

              <div style={{ marginTop: "10px" }}>
                Sales Instruction:{" "}
                {soDetail.salesInstruction !== null
                  ? soDetail.salesInstruction
                  : "NA"}
              </div>

              <div style={{ marginTop: "10px" }}>Sales Return Notes</div>
              <textarea
                className="multilineInput"
                value={instruction}
                rows="3"
                cols="30"
                name="text"
                placeholder="Enter notes"
                style={{
                  display: "flex",
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />

              <div className="createSalesInvoiceGrid2">
                {salesReturnDocument !== "" ? (
                  <div
                    className="formElement"
                    style={{ gridColumn: `auto / span ${9}` }}>
                    <FormHint
                      hintText="Sales Return Document"
                      mandatory={false}
                    />
                    <div className="inputDone">
                      <span
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // maxWidth: "168px",
                          textOverflow: "ellipsis",
                        }}>
                        ✅ {salesReturnDocument.name}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "gray" },
                        }}
                        onClick={() => setSalesReturnDocument("")}
                        title="Remove and Re-upload LR Copy">
                        ❌
                      </span>
                    </div>
                  </div>
                ) : (
                  <FormElement
                    colSpan={9}
                    inputType="upload"
                    hintText="Sales Return Document"
                    setInput={(file) => {
                      setSalesReturnDocument(file);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="poSpacer">&nbsp;</div>

            <div className="poNetTotal">
              <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                <span>
                  <b>Total Amount</b>
                </span>
                <div className="poNetTotalAmount">{totalAmount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

          {/* <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var paramsCopy = Object.assign(salesReturnParams);
                                ["soNo", "returnType", "returnDate", "transporterName"].forEach((element) => {
                                    paramsCopy[element].value = "";
                                    paramsCopy[element].error = false;

                                    if (element === "returnType") {
                                        paramsCopy[element].value = "return";
                                    }
                                });
                                setSalesReturnParams(paramsCopy);

                                setSoDetail({});
                                setSoReturnError([]);
                                setInstruction("");
                                setTotalAmount(0);
                                setSalesReturnDocument("");
                            }
                        }}
                    >
                        Reset Fields
                    </button> */}
          {/* <button
                        className="saveButton"
                        onClick={() => {
                            // setPoErrors([]);
                            // setLineItemErrors([]);
                            // checkErrors("Draft");
                        }}
                    >
                        Save draft
                    </button> */}
          <button
            className="submitButton"
            onClick={() => {
              setSoReturnError([]);
              // setLineItemErrors([]);
              checkErrors();
            }}>
            {editApprove ? "Edit & Approve" : " Submit for Approval"}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSalesReturn);
