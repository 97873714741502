import {Fragment, useEffect, useState} from 'react'
import FormElement from '../../SmallComponents/FormElement'
import { connect } from 'react-redux';
import fetchData from '../../../serverCall/fetchData';
import { validateMandatory } from '../../CommonFunctions/ValidateFields';
import { addToStockReportParams } from '../../../redux/PurchaseOrder/PoInfoActions';
import update from "immutability-helper";
import { addToken } from '../../../redux/UserAccount/IsLoggedActions';
import { paramsTemplate } from '../ItemReport/stockReportParams';


const MovementAnalysisParams = ({addToStockReportParams, isLogged, setSection}) => {
const [movementAnalysisParams,setMovementAnalysisParams] = useState({...paramsTemplate,
  selectBy: {
    inputType: "options",
    value: '',
    hintText: "Select By",
    options: [
  { optionId: "1", optionName: "Customer" },
  { optionId: "2", optionName: "Vendor" } ],
    mandatory: false,
    colSpan: 5,
    error: false,
    errorMessage: "Please select By option",
  },
  customerList: {
    inputType: "options",
    value: '',
    hintText: "Customer List",
    mandatory: false,
    colSpan: 5,
    options: [],
    error: false,
    errorMessage: "Select a Customer",
  },
  vendorList: {
    inputType: "options",
    value: '',
    hintText: "Vendor List",
    mandatory: false,
    colSpan: 5,
    options: [],
    error: false,
    errorMessage: "Select a Vendor",
  }
})

    const [storageLocList, setStorageLocList] = useState({});
    const [movementAnalysisError,setMovementAnalysisError] =useState([]);
    const selectByFieldElements = [ "plantName", "storageName", "fromDate", "toDate","itemId", "selectBy"];
    const [selectByField, setselectByField] = useState(selectByFieldElements)
    const [enableChart, setChartEnabled] = useState(false);

    const updateParameter=(element,key,value,section)=>{
      if(section = "movementAnalyis"){
      let paramsCopy  =  Object.assign(movementAnalysisParams);
      paramsCopy = update(paramsCopy,{[element]: {[key]:{$set: value} } });
      if(element==="plantName"){
        paramsCopy = update(paramsCopy,{storageName: {value: {$set: ""},options:{$set: []} } })
      }
      setMovementAnalysisParams(paramsCopy);
    }
      if (section = "graph"){
        let paramsCopy = Object.assign(movementAnalysisParams);
        paramsCopy = update(paramsCopy,{[element]: {[key]:{$set: value} } });
        setMovementAnalysisParams(paramsCopy)
      }
    }

    useEffect(()=>{
    switch(movementAnalysisParams.selectBy.value){
      case "1":
        setselectByField([...selectByFieldElements, "customerList"])
        break;
      case "2":
        setselectByField([...selectByFieldElements, "vendorList"])
        break;
      case "":
        setselectByField([...selectByFieldElements])
    }
  },[movementAnalysisParams.selectBy.value])
    async function getInformation () {
      let [itemList,storageLocList, plantListItem] = await getItemList();
      var movementAnalysisParamsCopy = Object.assign(movementAnalysisParams);
      movementAnalysisParamsCopy = update(movementAnalysisParamsCopy, {
        itemId: { options: { $set: itemList } },
        plantName: { options: { $set: plantListItem } },
        storageName: { options: { $set: [] } },
        fromDate: { value: { $set: new Date() } },
        toDate: { value: { $set: new Date() } },
      });
      let [customerOptionArray, vendorOptionArray] = await getCustomerList();
      movementAnalysisParamsCopy = update(movementAnalysisParamsCopy, {
        customerList: { options: { $set: customerOptionArray } },
        vendorList: { options: { $set: vendorOptionArray } }
      });
      setMovementAnalysisParams(movementAnalysisParamsCopy);
      setStorageLocList(storageLocList);
    }

    useEffect(()=>{
      getInformation();
    },[]);
  
    useEffect(()=>{
      if(movementAnalysisParams.plantName.value.length){
        let storageOptions = [];
        let plantsList=[]
        plantsList = movementAnalysisParams.plantName.value.map(plant=>plant.value)
       if(plantsList.includes(-1)){
         plantsList=movementAnalysisParams.plantName.options.map(plant=>plant.optionId)
       }
       plantsList.forEach((plantId)=>{
        if(plantId!== -1){
          storageOptions.push(...storageLocList[plantId]);
        }
      })
        // movementAnalysisParams.plantName.value.forEach((plantId)=>{
        //   storageOptions.push(...storageLocList[plantId.value]);
        // })
        let paramsCopy = Object.assign(movementAnalysisParams);
        paramsCopy = update(paramsCopy,{storageName : {options: {$set: storageOptions} }});
        setMovementAnalysisParams(paramsCopy);
      }
    },[movementAnalysisParams.plantName.value.length]);
  
    async function getItemList() {
      var itemData = await fetchData({
        requestingPage: "itemList",
        method: "post",
        url: "fetch/items",
        data:{itemsTab:"Active"},
        headers: { token: isLogged.accessToken, module: "Sales Order" },
      });
      var itemList = [];
      if (itemData.msg === "success") {
        itemData.asset.forEach((item) => {
          var itemObj = {};
          itemObj.optionId = item.item.itemId;
          itemObj.optionName =
            "  " + item.item.itemCode + " - " + item.item.itemName;
          itemObj.itemCode = item.item.itemCode;
          itemList.push(itemObj);
        });
  
        var plantsData = await fetchData({
          requestingPage: "plantList",
          method: "get",
          url: "fetch/plants",
          headers: { token: isLogged.accessToken, module: "Import PO" },
        });
  
        var plantListItem = [];
        var storageLocList = {};
  
        if (plantsData.msg === "success") {
          if(plantsData.asset.length){
            plantListItem.splice(0,0,{optionId:-1,optionName:"All"});
          }
          plantsData.asset.forEach((plant) => {
            var plantObj = {};
            plantObj.optionId = plant.plant.plantId;
            plantObj.optionName = plant.plant.plantName;
            plantObj.address =
              plant.plant.plantName +
              ", " +
              plant.plant.city +
              ", " +
              plant.plant.state +
              (plant.plant.zipCode !== null
                ? ` - ${plant.plant.zipCode}, `
                : ", ") +
              plant.plant.country;
            plantListItem.push(plantObj);
  
            
            plant.storage.forEach((store) => {
              var storeObj = {};
              storeObj.optionId = store.storageId;
              storeObj.optionName = store.storageName;
              storeObj.address =
                store.address +
                ", " +
                plant.plant.city +
                ", " +
                plant.plant.state +
                (plant.plant.zipCode !== null
                  ? ` - ${plant.plant.zipCode}, `
                  : ", ") +
                plant.plant.country;
              storeObj.gstNo = plant.plant.gstNo;
              if(storageLocList[plant.plant.plantId]){
                storageLocList[plant.plant.plantId].push(storeObj);
              }else{
                storageLocList[plant.plant.plantId] = [storeObj]
              }
            });
          });
        return [itemList,storageLocList, plantListItem];
      }
    }
  }
  
  useEffect(()=>{
    let paramsCopy = Object.assign(movementAnalysisParams)

      paramsCopy = update(paramsCopy,{
        chartType: { mandatory: {$set: enableChart}},
        xAxis:  { mandatory: {$set: enableChart}},
        yAxis: { mandatory: {$set: enableChart}},
      })
      setMovementAnalysisParams(paramsCopy)
  },[enableChart]);

    const checkMovementAnalysisError=()=>{
      var errorList = [];
        var paramsCopy = Object.assign(movementAnalysisParams);
        let queryParams = {}
        Object.keys(paramsCopy).forEach((item)=>{
          if(paramsCopy[item].mandatory){
            if(["customerList", "vendorList"].includes(item)){
              if(paramsCopy.selectBy.value === "1" && item === "customerList"){
                paramsCopy = update(paramsCopy,{ [item]: {error: {$set:  !validateMandatory(paramsCopy[item].value.toString()) } } })
              }else if(paramsCopy.selectBy.value === "2" && item === "vendorList"){
                paramsCopy = update(paramsCopy,{ [item]: {error: {$set:  !validateMandatory(paramsCopy[item].value.toString()) } } })
              }
            }
            else{
            paramsCopy = update(paramsCopy,{ [item]: {error: {$set:  !validateMandatory(paramsCopy[item].value.toString()) } } })
          }
          queryParams[item] = paramsCopy[item].value 
          }
          else if (paramsCopy[item].value) {
            paramsCopy = update(paramsCopy,{ [item]: {value: {$set: paramsCopy[item].value}} });
          queryParams[item] = paramsCopy[item].value;
          }
          if(paramsCopy[item].error)
            errorList.push(paramsCopy[item].errorMessage);
          });
  
        let emptyCheck = Object.entries(queryParams).every(([key,value])=>value=="");
        if(emptyCheck){
          errorList.push("Enter atleast One Parameter to fetch list")
        }
        if(errorList.length === 0){
          let plantList = queryParams.plantName?.map((plant)=>plant.value)
        queryParams.plantId = plantList.includes(-1)? paramsCopy.plantName?.options.map((plant)=>plant.optionId):plantList;

          // queryParams.plantId = queryParams.plantName?.map((plant)=>plant.value);
          queryParams.storageId = queryParams.storageName?.map((stg)=>stg.value);
          queryParams['enableChart'] = enableChart;
          addToStockReportParams(queryParams);
          setSection("movementAnalysisList")
        }else{
          setMovementAnalysisError(errorList)
        }
    }

    async function getCustomerList() {
      var customerData = await fetchData({
        requestingPage: "customerList",
        method: "get",
        url: "fetch/customer-list",
        headers: { token: isLogged.accessToken, module: "Sales Order" },
      });
      var customerOptionArray = [];
      if (customerData.msg === "success") {
        customerData.asset.forEach((dataItem) => {
          var a = {
            optionName: dataItem.customer,
            optionId: dataItem.customerId,
          };
          customerOptionArray.push(a);
        });
        // customerOptionArray.push({"optionName": "All","optionId":-1})
        var vendorData = await fetchData({
          requestingPage: "vendorList",
          method: "get",
          url: "fetch/purchase-pre-create-vendor-names",
          headers: { token: isLogged.accessToken, module: "Purchase Order" },
      });
      var vendorOptionArray = [];
      if (vendorData.msg === "success") {
          vendorData.asset.forEach((dataItem) => {
              var a = { optionName: dataItem.VendorName, optionId: dataItem.vendorId };
              vendorOptionArray.push(a);
          });
          // vendorOptionArray.push({"optionName": "All","optionId":-1})
      }
        return [customerOptionArray, vendorOptionArray];
      } 
    }
  
    const renderFormElements = ({ elementList, param, section }) => {
      return (
        <>
          {elementList.map((element, ind) => {
            return (
              <span style={{ flex: "50%", maxWidth: ind === 0? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                filterOptions={param[element].filterOptions}
                setInput={(value) => updateParameter(element, "value", value, section)}
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].options
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                key={ind}
                isClearable={param[element].isClearable}
              />
              </span>
            );
          })}
          </>
      );
    };
  
    return (
      <Fragment>
        <div className="formArea" style={{marginTop:"0",padding:"0"}}>
          <div
            style={{
              width: "1000px",
              height: "500px",
              margin: "0 2rem 4rem",
              padding: "1rem",
              border: "1px solid inherit",
              borderRadius: "5px",
              backgroundColor: "inherit",
            }}>
              <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
              {renderFormElements({
                elementList: selectByField,
                param: movementAnalysisParams,
                section : "movementAnalyis"
              })}
            </div>
            <div style ={{ margin: "20px 10px"}}>
            <label className="plantsSubTitle" style={{fontSize: "14px"}}>
            <input type="checkbox" onClick={(e)=> {setChartEnabled(!enableChart);}}/>
            <span>Enable chart in reports</span>
          </label>
          </div>
            <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            {enableChart ? renderFormElements({
                // elementList: ["chartType", "xAxis", "yAxis"],
                elementList: ["chartType"],
                param: movementAnalysisParams,
                section : "graph"
              }) : null }
              </div>
              <div style= {{ marginBottom: "5px"}}>
              <FormElement
              inputType="addButton"
              value="Submit"
              colSpan={5}
              boxWidth="80px"
              setInput={() => {
                setMovementAnalysisError([]);
                checkMovementAnalysisError();
              }}
          />
          </div>
            <p className="formSubmitErrorTextArea" style={{textAlign:"center",fontSize:"16px"}}>
              {movementAnalysisError[0]}
            </p>
            </div>
          </div>
      </Fragment>
    )
  }

  const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>
        dispatch(addToken(accessToken, employeeId)),
        addToStockReportParams: (value)=>dispatch(addToStockReportParams(value))
    };
  };

  export default connect(mapStateToProps,mapDispatchToProps)(MovementAnalysisParams);