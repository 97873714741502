import React, { useEffect, useState } from "react";
import viewIcon from "../../assets/view.svg";
import { addViewProcessId } from 'redux/PurchaseOrder/PoInfoActions';
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";

const EmployeeEfficiencyReportList = ({ setSection, processLogId, poInfo, isLogged }) => {
  const [employeeProcessLogData, setEmployeeProcssLogData] = useState([])

  const fetchRequest = async () => {
    let response = await fetchData({
      method: 'post',
      url: `production-fetch/employee-efficiency-report`,
      data:{ employeeId:poInfo.employeeId,
             itemId:poInfo.employeeEfficiencyItemId
           },
      headers: { module: "Employee Efficiency Report", token: isLogged.accessToken },
      requestingPage: "workOrder",
    })
    if (response.msg === 'success') {
      return response.asset;
    } else {
      console.log(response)
    }
  }

  const getProcessLogDetail = async () => {
    const response = await fetchRequest()
    setEmployeeProcssLogData(response)
  }

  useEffect(() => {
    getProcessLogDetail()
  }, [])

  const CommonColumns = () => {
    return (
      <React.Fragment>
        <td className="vendorListHeading stickyFirstColumn">Process Log Id</td>
        <td className="vendorListHeading">Work Order Id</td>
        <td className="vendorListHeading">Status</td>
      </React.Fragment>
    )
  }
  return (
    <div className="vendorListArea">
      {employeeProcessLogData?.length ?

        <table className="vendorListTable">
          <thead>
            <tr className="vendorListHeader" style={{ zIndex: 1 }}>
              <CommonColumns />
              <td className="vendorListHeadingActions">Actions</td>
            </tr>
          </thead>
          {
            <tbody>
              {
                employeeProcessLogData.map((processLog,index) => {
                  return (

                    <tr className="vendorList" key={index}>
                      <td className="vendorListInfo">{processLog.processLogNo}</td>
                      <td className="vendorListInfo">{processLog.workOrderNo}</td>
                      <td className="vendorListInfo">{processLog.processLogStatus}</td>
                      <td className="vendorListInfoActions"> <img
                        alt="View Record"
                        className="vendorActionImages"
                        src={viewIcon}
                        title="View Production Request"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSection('employeeReportDetail')
                          processLogId(processLog.processLogId)
                        }}
                      /></td>
                    </tr>
                  )
                })

              }

            </tbody>
          }
        </table>
        : <div className="noRecordsYet">No records found</div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    poInfo: state.poInfo,
    isLogged: state.isLogged,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    processLogId: (value) => dispatch(addViewProcessId(value))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEfficiencyReportList);
