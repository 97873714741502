import React,{useState} from "react";
import statementIcon from "../../assets/statement.svg";
import customerLedgerIcon from "../../assets/customer_ledger.svg";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import productionReportIcon from "assets/production-report.svg";
import PageTitle from "components/SmallComponents/PageTitle";
import MenuList from "components/Menu/MenuList";
import TopBanner from "components/SmallComponents/TopBanner";

const ProductionReportModule =({isLogged})=>{
    const history = useHistory();
    const productionReportModules = [
        { menu: "Production Log Report", route: "production-log-report", icon: customerLedgerIcon },
        { menu: "Employee Efficiency Report", route: "employee-efficiency-report", icon: statementIcon },
      ];

      return (
        <React.Fragment>
          <div className="completePage">
          <MenuList selectedMenu="Reports" selectedSubMenu="Production Report" />
          <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={productionReportIcon} pageTitle="Production Report" />
            <span className="itemImagesGrid" style={{ display:"flex",flexWrap:"wrap",maxWidth:"80%" }}>
              {productionReportModules.map((obj,i) =>{
              return(  
                  <React.Fragment key={i}>
                  {
                    <div 
                      className={
                        isLogged.access[obj.menu] !== null
                          ? "singleItem"
                          : "singleItemDisabled"
                      }
                      onClick={() => {
                        if (isLogged.access[obj.menu] !== null) {
                          history.push(`/${obj.route}`);
                        }
                      }}
                      alt={obj.menu}
                      title={
                        isLogged.access[obj.menu] !== null ? obj.menu : "No Access"
                      }
                      >
                      <span className="itemText"></span>
                      <img
                        className={
                          isLogged.access[obj.menu] !== null
                            ? "itemImage"
                            : "itemImageDisabled"
                        }
                        src={obj.icon}
                        alt="Category"
                      />
                      <span className="itemText">{obj.menu}</span>
                    </div>
              }
                  
                  </React.Fragment>  
              )})
              }
            </span>
          </div>
          </div>
      </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
  };

export default connect(mapStateToProps,null)(ProductionReportModule)
