import React, { Fragment, useEffect, useState } from "react";
import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import deleteIcon from "../../assets/delete.svg";
import editIcon from "../../assets/edit.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";


const SalesOpportunityParams = ({ setCurrentSection, isLogged }) => {

  const opportunityStage = ["Appointment Scheduled", "Qualified to Buy", "Presentation Scheduled",
    "Decision Maker Bought-In", "Contract sent", "opportunity Won", "opportunity Lost"]

  const dealStageOptions = opportunityStage.map((item) => {
    return (
      {
        optionId: item,
        optionName: item
      }
    )
  })

  const [storageList, setStorageList] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [leadList, setLeadList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [amount, setAmount] = useState(0)
  const [itemList, setItemList] = useState([])
  // const [itemLists,setItemLists] = useState([])

  const [salesOpportunityParams, setSalesOpportunityParams] = useState({
    opportunityName: {
      inputType: "text",
      value: "",
      hintText: "Opportunity Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Opportunity Name",
      mandatory: true
    },
    companyName: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Company Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Choose A Company Name",
      mandatory: true,
      // disabled:true
    },
    opportunityStage: {
      inputType: "options",
      value: "",
      options: dealStageOptions,
      hintText: "Opportunity Stage",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Stage",
      mandatory: true
    },

    closedate: {
      inputType: "date",
      value: "",
      hintText: "Target Close Date",
      colSpan: 6,
      error: false,
      errorMessage: "Please Choose A Date",
      mandatory: true
    },
    dealOwner: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Deal Owner",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Owner",
      mandatory: true
    },
    priority: {
      inputType: "options",
      value: "",
      options: [
        { optionId: "low", optionName: "Low" },
        { optionId: "medium", optionName: "Medium" },
        { optionId: "high", optionName: "High" },
      ],
      hintText: "Priority",
      colSpan: 6,
      error: false,
      errorMessage: "",
      mandatory: false
    },
    leadId: {
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Lead Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Choose A Lead Name",
      mandatory: true
    },
    plant: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Plant",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Plant",
      mandatory: true
    },
    storage: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Storage",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Storage",
      mandatory: true
    },
  })

  const [lineItemsParams, setLineItemParams] = useState({
    itemCategory: {
      inputType: "options",
      value: "",
      hintText: "Item Category",
      mandatory: false,
      colSpan: 12,
      options: [],
      error: false,
      errorMessage: "Select an Item",
      // callServer: true,
  },
    itemId: {
      inputType: "options",
      value: "",
      options: [],
      hintText: "Item Name",
      colSpan: 6,
      error: false,
      errorMessage: "Please Select A Item",
      mandatory: true
    },
    unitPrice: {
      inputType: "number",
      value: "",
      hintText: "Unit Price",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Price",
      mandatory: true
    },
    quantity: {
      inputType: "number",
      value: "",
      hintText: "Quantity",
      colSpan: 6,
      error: false,
      errorMessage: "Please Enter A Quantity",
      mandatory: true
    },
    
  })

  const submitOkClick = () => {
    setIsOpen(false);
    setCurrentSection("salesDealList")
  }


  const [listItem, setListItem] = useState([])
  const [dealsError, setDealsError] = useState([])
  const [modalText, setModalText] = useState("")
  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Items" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });
    }
    return [plantList, storageLocList];
  }

  async function getEmployees() {
    var data = await fetchData({
      requestingPage: "employeeList",
      method: "POST",
      url: "fetch/employees",
      headers: { token: isLogged.accessToken, module: "Sales Opportunities" },
      data: { filter: "" }
    });
    if (data.msg === "success") {
      return data.asset
    } else {
      console.log(data);
    }
  }

  //get items 
  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{itemsTab:"Active"},
      headers: { token: isLogged.accessToken, module: "Sales Opportunities" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemObj.mrp = item.item.mrp;
        itemObj.categoryId = item.item.categoryId;
        itemList.push(itemObj);
      });
      var [categoryList,subCategoryList] = await getCategory();
      let copy = structuredClone(lineItemsParams);
      copy = update(copy, { itemId: { options: { $set: itemList } },
        itemCategory :{options:{$set :categoryList}} })
      setItemList(itemList);
      setLineItemParams(copy)
    // setItemLists(itemList)
    }
  }

  async function getCategory() {
    var categoryData = await fetchData({
        requestingPage: "getCategory",
        method: "get",
        url: "fetch/categories",
        headers: { token: isLogged.accessToken, module: "Dropdowns" },
    });

    var categoryList = [];
    var subCategoryList = {};

    if (categoryData.msg === "success") {
        categoryData.asset.forEach((categ) => {
            var catObj = {};
            catObj.optionId = categ.category.categoryId;
            catObj.optionName = categ.category.categoryName;
            categoryList.push(catObj);

            subCategoryList[categ.category.categoryId] = [];

            categ.subCategory.forEach((subCateg) => {
                var subCatObj = {};
                subCatObj.optionId = subCateg.subCategoryId;
                subCatObj.optionName = subCateg.subCategoryName;

                subCategoryList[categ.category.categoryId].push(subCatObj);
            });
        });
        return [categoryList, subCategoryList];
    }
}

  const editSubSectionItem = (index, action) => {

    let salesOpportunityLinesCopy = listItem.slice();

    if (action === "edit") {
      let salesParamsCopy = structuredClone(lineItemsParams);
      let item = salesOpportunityLinesCopy.splice(index, 1)
      let { quantity, itemId } = item[0];
      salesParamsCopy = update(salesParamsCopy,
        {
          quantity: { value: { $set: quantity } },
          itemId: { value: { $set: itemId } }
        })

      setLineItemParams(salesParamsCopy);

    }
    if (action === "delete") {
      salesOpportunityLinesCopy.splice(index, 1)
    }
    setListItem(salesOpportunityLinesCopy);
  };

  //set all options in salesOpportunity params inputs
  const setOptionsToSalesDealParams = async () => {
    let copy = structuredClone(salesOpportunityParams)

    let employeeList = await getEmployees();
    let companyList = await getCompanyList();
    // let items = await getItems()

    let [plantList, storageLocListFormatted] = await getPlants()

    setStorageList(storageLocListFormatted)
    copy = update(copy, {
      dealOwner: { options: { $set: employeeList } },
      //  lineItems:{options:{$set: items}},
      plant: { options: { $set: plantList } },
      // leadId:{ options :{ $set: leadList }},
      companyName: { options: { $set: companyList } }
    })

    setSalesOpportunityParams(copy);
  }



  useEffect(() => {
    //calculating total amount depend on line items and their quantity
    let amount = listItem.reduce((acc, val) => {
      acc += val.quantity * val.unitPrice
      return acc;
    }, 0)

    setAmount(amount)

  }, [listItem.length])


  const salesLeadDetail = async () => {

    let data = await fetchData({
      requestingPage: "employeeDetail",
      method: "get",
      url: "so-fetch/sales-lead-list",
      headers: { token: isLogged.accessToken,module: "Sales Lead" }
    })

    if (data.msg === "success") {

      let leadOptions = [];
      data.asset.forEach((lead) => {
        leadOptions.push({
          optionId: lead.leadId, optionName: lead.firstName + lead.lastName,
          companyName: lead.companyName
        });
      });

      setLeadList(leadOptions);
      // return leadOptions;
    }
  }


  const getCompanyList = async () => {
    let data = await fetchData({
      requestingPage: "employeeDetail",
      method: "get",
      url: "so-fetch/company-list",
      headers: { token: isLogged.accessToken, module: "Sales Opportunities" }
    })

    if (data.msg === "success") {
      setCompanyList(data.asset)
      return data.asset
    } else {
      return []
    }
  }

  useEffect(() => {
    setOptionsToSalesDealParams();
    getItems();
    salesLeadDetail()
  }, [])


  const updateParameter = (element, key, value, section) => {

    let salesCopy = Object.assign(section);

    //keys of object of lineItemsParams
    let keyList = Object.keys(lineItemsParams);

    salesCopy = update(salesCopy, {
      [element]: { [key]: { $set: value } },
    });
    if (element === "companyName") {
      // filtering the lead based on company

      let company = companyList.filter((company) => company.optionId === value)

      let leadOptions = leadList.filter((item) => { 
        return item?.companyName === company[0]?.companyName })
    
      salesCopy = update(salesCopy, { leadId: { options: { $set: leadOptions } } })
    }
    else if (element === "itemId") {

      if(value){
        let unitPrice = itemList.filter(item => item.optionId === value)
        salesCopy = update(salesCopy, {
          unitPrice: { value: { $set: unitPrice[0].mrp } }
        })
      }else{
        salesCopy = update(salesCopy, {
          unitPrice: {value: {$set: ""} }
        })
      }
    }
    if(element === "itemCategory"){
      if(value){
        const filteredItems = itemList.filter(item=>{return item.categoryId === value})
        salesCopy = update(salesCopy, {
              itemId: { options: {$set:filteredItems}},
          });
      }else{

        salesCopy = update(salesCopy, {
              itemId: { options: {$set:itemList},
                       value:{$set:""}},
                       unitPrice: {value: {$set: ""} }
          });
      }
  }

    //checking the object whether the salesDealparams or lineItemsParams
    if (keyList.includes(element)) {
      setLineItemParams(salesCopy);
    } else {
      setSalesOpportunityParams(salesCopy);
    }
  };


  const checkLineItemError = (lineItemsParamsList) => {
    let errorList = [];
    let copy = structuredClone(lineItemsParams);
    lineItemsParamsList.map((item) => {
      if (copy[item].mandatory) {
        copy = update(copy,
          {
            [item]:
            {
              error:
                { $set: !validateMandatory(copy[item].value.toString()) }
            }
          })
      }
      if (copy[item].error) {
        errorList.push(copy[item].errorMessage);
      }
    })
    if (errorList.length === 0) {
      let lineItemsCopy = listItem.slice();

      let itemName = copy.itemId.options.filter((list) => list.optionId === copy.itemId.value)
      //if no error add new object to list item to display in the table
      let newItem = {
        itemName: itemName[0].optionName,
        itemId: copy.itemId.value,
        quantity: copy.quantity.value,
        unitPrice: copy.unitPrice.value
      }

      copy = update(copy, { itemId: { value: { $set: "" } }, quantity: { value: { $set: "" } }, unitPrice: { value: { $set: "" } } })
      lineItemsCopy.push(newItem);

      setListItem(lineItemsCopy);
    }
    setDealsError(errorList);
    setLineItemParams(copy)
  }

  const renderFormElements = ({ elementList, param }) => {
    return (
      <>
        {elementList.map((element, ind) => {
          return (
            // <span style={{ flex: "50%", maxWidth: ind === 0 ? "500px" : "250px" }} key={element}>
              <FormElement
                inputType={param[element].inputType}
                value={param[element].value}
                setInput={(value) =>

                  updateParameter(element, "value", value, param)
                }
                hintText={param[element].hintText}
                mandatory={param[element].mandatory}
                colSpan={param[element].colSpan}
                options={
                  param[element].inputType === "options" || param[element].inputType === "multiSelect"
                    ? param[element].options
                    : null
                }
                error={param[element].error}
                disabled={param[element].disabled}
                key={ind}
              />
            // </span>
          );

        })}

      </>
    );
  };

  const lineItemsParamsList = Object.keys(lineItemsParams);

  const RenderTable = () => {
    return (
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Item</td>
            <td>Quantity</td>
            <td>Unit Price</td>
            <td>Actions</td>
          </tr>
        </thead>
        {listItem.length > 0 ? (
          <tbody>
            <RenderTableRows rows={listItem} />
          </tbody>
        ) : (
          <tbody>
            <tr className="createVendorContactsTableRows">
              {lineItemsParamsList.map((item) => {
                return <td key={item}>&nbsp;</td>;
              })}
              {/* <td key={1}>&nbsp;</td> */}
            </tr>
          </tbody>
        )}
      </table>
    );
  };

  const renderSubSection = () => {
    return (
      <React.Fragment>
        <div className="purchaseOrderIndividualItemsArea" 
        style={{gridTemplateColumns : "repeat(27, 1fr)"}}
        >
          {renderFormElements({
            elementList: lineItemsParamsList,
            param: lineItemsParams,
            // section: lineItemsParams,
          })}
          {/* <div style={{ width: "70px" }}> */}
            <FormElement
              inputType="addButton"
              value="+ Add"
              colSpan={3}
              setInput={() => {
                checkLineItemError(lineItemsParamsList);
              }}
            />
            {/* </div> */}
          {/* <div
            style={{
              marginTop: "26px",
              gridColumn: "span 1",
              color: "#666",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}>
          </div> */}
        </div>

        <RenderTable />
      </React.Fragment>
    );
  };

  const RenderTableRows = ({ rows, tab }) => {
    return rows.map((row, j) => {
      return (
        <tr className="createVendorContactsTableRows" key={j}>
          {["itemName", "quantity", "unitPrice"].map((key, i) => {
            return <td key={i}>{row[key] ? row[key] : " - "}</td>;
          })}
          <td>
            <img
              alt="Edit Record"
              className="createVendorContactsAction"
              src={editIcon}
              onClick={() => {
                editSubSectionItem(j, "edit");
              }}
            />
            <img
              alt="Delete Record"
              className="createVendorContactsAction"
              src={deleteIcon}
              onClick={() => {
                var reset = window.confirm("Delete record?");
                if (reset) {
                  editSubSectionItem(j, "delete");
                }
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const submitData = async () => {

    let errorList = [];
    let lineItemsParamsList = ["opportunityName", "opportunityStage", "closedate", "dealOwner", "priority", "leadId", "plant"]
    let copy = structuredClone(salesOpportunityParams);
    lineItemsParamsList.map((item) => {
      if (copy[item].mandatory) {
        copy = update(copy,
          {
            [item]:
            {
              error:
                { $set: !validateMandatory(copy[item].value.toString()) }
            }
          })
      }
      if (copy[item].error) {
        errorList.push(copy[item].errorMessage);
      }
    })
    setDealsError(errorList);
    setSalesOpportunityParams(copy);

    if (errorList.length === 0) {
      let { opportunityName, opportunityStage, closedate, dealOwner, priority, leadId, plant, companyName } = salesOpportunityParams
      let dataToSent = {
        opportunityName: opportunityName.value,
        opportunityStage: opportunityStage.value,
        // amount: amount.value,
        closeDate: closedate.value,
        dealOwner: dealOwner.value,
        priority: priority.value,
        leadId: leadId.value.map(item => item.value),
        // storage: storage.value,
        plant: plant.value,
        companyName: companyName.value,
        lineItems: listItem,
        amount: amount
      }

      let response = await fetchData({
        requestingPage: "employeeList",
        method: "post",
        url: "so-create/sales-opportunities",
        data: dataToSent,
        headers: { token: isLogged.accessToken, module: "Sales Opportunities" }
      })
      setSubmitted(true)
      if (response.msg === "success") {
        setModalText("SuccessFully Created A Opportunity")
        setIsOpen(true);
      } else {
        setModalText(response.desc);
      }

    }
  }

  return (
    <Fragment>
      <CreateEditModal modalIsOpen={isOpen} modalText={modalText}
        dataSubmitted={submitted} submitOkClick={submitOkClick} />
      <div className="formArea" 
      // style={{ marginTop: "0px", padding: "0px" }}
      >
        <div
          style={{
            width: "1000px",
            margin: "0 auto 4rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <p>Associate Deals With :</p>
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
            <div
            className="createPurchaseOrderGrid"
            style={{ gridTemplateRows: "repeat(1, 4rem)" }}
            >
            {renderFormElements({
              elementList: ["companyName", "leadId", "plant"],
              param: salesOpportunityParams,
            })}

            </div>

          </div>
          <p>Opportunity Detail:</p>
          <div style={{ display: "flex", flexWrap: "wrap", gridGap: "10px 30px", gap: "10px 30px" }}>
          <div
            className="createPurchaseOrderGrid"
            style={{ gridTemplateRows: "repeat(1, 4rem)" }}
            >

            {renderFormElements({
              elementList: ["opportunityName", "opportunityStage", "closedate", "dealOwner", "priority"],
              param: salesOpportunityParams,
            })}
            </div>
          </div>


          <div className="purchaseOrderSubSectionArea" style={{width:"fit-content"}}>
            {renderSubSection()}
          </div>
          <div className="poNetTotalSurchargeDiv">
            <div className="poSpacer">&nbsp;</div>
            <div className="poNetTotal">
              <div className="poTotalTitle">
                <span>
                  <b>Amount</b>
                </span>
                <span className="poNetTotalAmount">{amount}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{dealsError[0]}</p>
          <button className="submitButton" onClick={submitData}>Submit</button>
        </div></div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

export default connect(mapStateToProps, null)(SalesOpportunityParams);