import React, { useState } from "react";
import PageTitle from "../SmallComponents/PageTitle";
import "../styles/Role.css";

import MenuList from "../Menu/MenuList";
import RoleList from "./RoleList";
import CreateRole from "./CreateRole";
import EditRole from "./EditRole";
import TopBanner from "../SmallComponents/TopBanner";

import roleIcon from "../../assets/role2.svg";
import { connect } from "react-redux";

const Role = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("roleList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "roleList" }];

    var createRole = {
        buttonName: "Create Role",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createRole",
    };

    var createRoleControlButton = {
        buttonName: "Create Role Control",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createRoleControl",
    };



    const renderSection = (section) => {
        switch (section) {
            case "roleList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={roleIcon}
                            pageTitle="Role List"
                            buttonList={
                                isLogged.roleId === 1
                                    ? [createRole]
                                    : isLogged.access.Role === "Create" || isLogged.access.Role === "Edit"
                                    ? [createRole]
                                    : null
                            }
                        />
                        <RoleList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createRole":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={roleIcon} pageTitle="Create Role" buttonList={goBackButton} />
                        <CreateRole setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editRole":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={roleIcon} pageTitle="Edit Role" buttonList={goBackButton} />
                        <EditRole setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return (
                    <div className="detailsContainer">
                        <div className="roleListArea">
                            <div style={{ float: "right", paddingBottom: "20px" }}>
                                <button className="goBackButton" onClick={() => setCurrentSection("roleList")}>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Role" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Role);
