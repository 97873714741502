import React from "react";
import "../styles/SmallComponents.css";

const InputField = ({ value, setName, type, error, disabled, placeholder, title, align,boxWidth,boxHeight, removeBorder,onEnterKey }) => {
    // console.log(value, type, removeBorder);
    const invalidChars=["e","E","+","-"];
    return (
        <input
            className={error ? "commonInputError" : "commonInput"}
            type={type === "float" || type=== "roundoff" ? "number" : type}
            value={value}
            onChange={(e) => {
                // console.log(e.target.value,type);
                if (type === "number") {
                  setName((parseInt(e.target.value) >= 0 ? parseInt(e.target.value) :  ""));
                } else if(type==="float"){
                    setName((parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : ""))
                } else{
                  setName(e.target.value);
                }
            }}
            style={{ backgroundColor: disabled ? "rgb(244,244,244)" : null, textAlign: align, height:boxHeight ? boxHeight : null,border: removeBorder ? "none" : null , width : boxWidth ? boxWidth : null}}
            disabled={disabled}
            placeholder={placeholder}
            onWheel={e=>e.currentTarget.blur()}
            title={title}
            onKeyDown ={(e)=>{
                if(invalidChars.includes(e.key) && !(type=="text" || type=="password"))
                if(!(type === "roundoff" && e.key === "-" ))
                e.preventDefault();
                if(e.key === "Enter" && onEnterKey)
                                {   
                                    onEnterKey(value);
                                }                    
            }}
        />
    );
};

export default React.memo(InputField);
