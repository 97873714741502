import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import logoNotAvailable from "../../assets/no-stopping.svg";

const SoPendingDetail = ({ isLogged, storeInfo, addTokenToState }) => {
    const [soDetail, setSoDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    const [customerLogo, setCustomerLogo] = useState([]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getSoPendingDetail();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {

            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getSoPendingDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "so-fetch/so-detail/" + storeInfo.viewSoPendingId,
            headers: { token: isLogged.accessToken, module: "Pending SO" },
        });
        if (data.msg === "success") {
           
            setSoDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        getCustomerLogo();
        // eslint-disable-next-line
    }, [soDetail]);

    async function getCustomerLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/customer-logo/" + soDetail.customerId,
            headers: { token: isLogged.accessToken, module: "Pending SO" },
        });
        setCustomerLogo(data);
    }

    const renderSummaryInfo = () => {
        if (soDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {soDetail.soNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                soDetail.soStatus === "Declined"
                                    ? { color: "red" }
                                    : soDetail.soStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                            }
                        >
                            ( {soDetail.soStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {soDetail.soDate}</span>

                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {soDetail.expectedDeliveryDate}</span>

                        <span className="vendorSummaryCategory">Transporter Name</span>
                        <span className="vendorSummaryValue">
                            : {soDetail.transporterName === null || soDetail.transporterName === "" ? "NA" : soDetail.transporterName}
                        </span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        customerLogo !== null && customerLogo !== undefined && customerLogo !== ""
                            ? `data:image/png;base64,${customerLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td className="stickyFirstColumn">Item</td>
                        <td>Item Code</td>
                        {
                        isLogged.showHideWeight ?    
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td></> : ''}
                        <td>UOM</td>
                        <td>Order Qty</td>
                        <td>Outward Qty</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };
const lineItemParams=isLogged.showHideWeight ?  ["itemName", "itemCode", "from", "to", "netWeight", "grossWeight", "uom", "quantity", "outwardQuantity"] : ["itemName", "itemCode", "uom", "quantity", "outwardQuantity"];
    const RenderTableRows = ({ rows, tab }) => {

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i} className={key==='itemName' ? 'stickyFirstColumn' : ''}>{row[key] !== null ? row[key] : "-"}</td>;
                    })}
                </tr>
            );
        });
    };

    return (
        <div className="detailsPageWrapper">
            <div className="detailsPageContainer">
                <div className="vendorSummary">
                    <div className="poSummaryText">{renderSummaryInfo()}</div>
                    <div className="poSummaryText2">
                        <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                            {soDetail.customerName}
                        </span>

                        <span className="poSummaryValue">
                            {`Customer ID: ${soDetail.customerId}`}
                        </span>
                        <span className="poSummaryValue">
                            {soDetail.customerPinCode !== null
                                ? ` ${soDetail.customerAddress} - ${soDetail.customerPinCode}`
                                : soDetail.customerAddress}
                        </span>
                        <span className="poSummaryValue">
                            {soDetail.customerMobile !== null || soDetail.customerPhone !== null ? `\nPhone: ` : null}
                            {soDetail.customerMobile !== null ? soDetail.customerMobile : null}
                            {soDetail.customerPhone !== null ? `, ${soDetail.customerPhone} ` : null}
                        </span>
                        <span className="poSummaryValue">{soDetail.customerEmail !== null ? `Email Id: ${soDetail.customerEmail}` : null}</span>

                        <span className="poSummaryValue">{soDetail.customerGstNo !== null ? `GST No: ${soDetail.customerGstNo}` : null}</span>
                    </div>
                    {renderImageStatus()}
                </div>

                {soDetail.soStatus === "Declined" ? (
                    <div style={{ marginTop: "2rem" }}>
                        <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {soDetail.declineReason}
                    </div>
                ) : null}

                <div className="vendorDetailTabArea">
                    <RenderTable />
                </div>

                <div className="poNetTotalSurchargeDiv">
                    <div className="purchaseInstruction">
                        <div>
                            No Items: <span>{lineItemsList.length}</span>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                            Sales Instruction: {soDetail.salesInstruction !== null ? soDetail.salesInstruction : "-"}
                        </div>
                    </div>
                    <div className="poSpacer">&nbsp;</div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoPendingDetail);
