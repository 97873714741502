import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const EditOutward = ({ editApprove, isLogged, storeInfo, addTokenToState, setSection }) => {
    var outwardParamList = ["orderType", "soId", "orderDate", "opdId", "outwardDate"];

    const [outwardParams, setOutwardParams] = useState({
        orderType: {
            inputType: "text",
            value: "",
            hintText: "Order Type",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please select order type",
            disabled: true,
        },
        soId: {
            inputType: "text",
            value: "",
            hintText: "Sales Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select Sales order",
            disabled: true,
        },
        orderDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Order Date",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        opdId: {
            inputType: "text",
            value: "",
            hintText: "OPD No",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "",
            disabled: true,
            errorMessage: "Select OPD Number",
        },
        outwardDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Outward Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Provide and outward date",
            maxdate: true,
        },
    });

    // const [soOpdList, setSoOpdList] = useState({});

    const [outwardDetail, setOutwardDetail] = useState({});

    const [showCompanyDetail, setShowCompanyDetail] = useState(false);

    const [itemList, setItemList] = useState([]);

    const [outwardErrors, setOutwardErrors] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        await checkToken();
        var outwardDetailCopy = await getOutwardDetail();


        var paramsCopy = Object.assign(outwardParams);
        paramsCopy = update(paramsCopy, {
            orderType: { value: { $set: outwardDetailCopy.orderType } },
            soId: { value: { $set: outwardDetailCopy.orderNo } },
            orderDate: { value: { $set: outwardDetailCopy.orderDate } },
            opdId: { value: { $set: outwardDetailCopy.opdNo } },
            outwardDate: { value: { $set: outwardDetailCopy.outwardDate } },
        });
        setOutwardParams(paramsCopy);
        setItemList(outwardDetailCopy.lineItems);
        setShowCompanyDetail(true);
        setOutwardDetail(outwardDetailCopy);
    };

    const history = useHistory();

    async function checkToken() {
      
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
         
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getOutwardDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "post",
            url: "store-fetch/outward-detail/" + storeInfo.editOutwardId,
            headers: { token: isLogged.accessToken, module: "Outward" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        } else {
            console.log(data);
        }
    }

    const updateOutwardParameter = ({ paramName, key, value }) => {

        var paramsCopy = Object.assign(outwardParams);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });

        setOutwardParams(paramsCopy);
    };

    const RenderCompanyDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">{outwardDetail.companyName}</div>
                <div className="detailText">
                    {`ID: ${outwardDetail.companyId}`},
                    <br /> {outwardDetail.companyAddress},
                    <br /> {outwardDetail.companyCity}
                    {outwardDetail.companyPinCode !== null ? `, - ${outwardDetail.companyPinCode}` : null}
                    {outwardDetail.companyMobile !== null || outwardDetail.companyPhone !== null ? <br /> : null}
                    {outwardDetail.companyMobile !== null || outwardDetail.companyPhone !== null ? `Phone: ` : null}
                    {outwardDetail.companyMobile !== null ? outwardDetail.companyMobile : null}
                    {outwardDetail.companyPhone !== null ? `, ${outwardDetail.companyPhone} ` : null}
                    {outwardDetail.companyEmail !== null ? <br /> : null}
                    {outwardDetail.companyEmail !== null ? `Email Id: ${outwardDetail.companyEmail}` : null}
                    {outwardDetail.companyGstNo !== null ? <br /> : null}
                    {outwardDetail.companyGstNo !== null ? `GST No:  ${outwardDetail.companyGstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Submitting Outward. Please wait..");

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value) => {
                        updateOutwardParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (outwardErrors.length === 0) {
            setSection("outwardList");
        }
        setModalText("Uploading form. Please wait...");
    };
    const lineItemCols = isLogged.showHideWeight ? ["itemName", "from", "to", "netWeight", "grossWeight", "uom", "quantity"] : ["itemName", "uom", "quantity"];
    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable" style={{ margin: "0.5rem auto 1rem auto" }}>
                <thead>
                    <tr className="createVendorContactsTableHeader" key="tableHeader">
                        <td>Item</td>
                        {isLogged.showHideWeight ?
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td></> :''}
                        <td>UOM</td>
                        <td>Quantity</td>
                    </tr>
                </thead>
                {itemList.length > 0 ? (
                    <tbody>
                        <RenderTableRows rows={itemList} tab="contactActions" />
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows" key="emptyRow">
                            {lineItemCols.map((item, i) => {
                                return <td key={i}>&nbsp;</td>;
                            })}
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemCols.map((key, i) => {
                        return <td key={i}>{row[key]}</td>;
                    })}
                </tr>
            );
        });
    };

    const checkOutwardError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(outwardParams);

        outwardParamList.map((item) => {
            if (outwardParams[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        if (errorList.length === 0) {

            var data2server = {
                outwardId: outwardDetail.outwardId,
                outwardDate: paramsCopy.outwardDate.value,
            };
            submitData(data2server);
        }

        setOutwardErrors(errorList);
        setOutwardParams(paramsCopy);
    };

    const submitData = async (data2server) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createPoItem",
            method: "put",
            url: "store-edit/outward",
            headers: { token: isLogged.accessToken, module: "Outward" },
            data: data2server,
        });

        if (result.msg === "success") {
            if (editApprove) {
                approveOutward();
            } else {
                setDataSubmitted(true);
                setModalText("Outward Submitted for Approval");
            }
        } else {
            setOutwardErrors([result.desc]);
            setDataSubmitted(true);
            setModalText(result.desc);
        }
    };

    async function approveOutward() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/outward-approve/${outwardDetail.outwardId}`,
            headers: { token: isLogged.accessToken, module: "Outward" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Outward edited & approved");
        } else {
         
            setModalText(data.desc);
            setOutwardErrors(data.desc);
        }
    }

    const RenderNoItems = () => {
        if (itemList.length > 0) {
            var uniqArr = Array.from(new Set(itemList.map((item) => item.itemId)));
            return <span>{uniqArr.length}</span>;
        } else {
            return <span>0</span>;
        }
    };

    const renderErrorMessage = () => {
        if (outwardErrors.length > 0) return outwardErrors[0];
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div className="createSalesOrderGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                            {renderFormElements({ elementList: outwardParamList, param: outwardParams })}
                        </div>
                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCompanyDetail ? <RenderCompanyDetail /> : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="purchaseOrderSubSectionArea" style={{width:"90%"}}>
                        {/* {renderSubSection()} */}
                        <span>&nbsp;</span>
                        <RenderTable />
                    </div>
                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                Number of unique items:&nbsp;
                                <RenderNoItems />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            // setOpdErrors([]);
                            // checkOpdError();
                            setOutwardErrors([]);
                            checkOutwardError();
                        }}
                    >
                        {editApprove ? "Edit & Approve" : "Submit for Approval"}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOutward);
