import React from "react";

const FormHint = ({ hintText, mandatory }) => {
    return (
        <div style={{ /*paddingBottom: "5px"*/ }}>
            <span className="hintText">{hintText}</span>
            {mandatory ? <span className="mandatory">*</span> : null}
        </div>
    );
};

export default React.memo(FormHint);
