import React, { Fragment, useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import editIcon from "assets/edit.svg";
import { editPremiumCharges } from "redux/Item/ItemInfoActions";
import CreateEditModal from "components/SmallComponents/CreateEditModal";

const EditPremiumCharges = ({
  isLogged,
  editPremiumCharges,

  itemInfo,
  setSection,
  addTokenToState
}) => {
  const [premiumChargesList, setPremiumChargesList] = useState([]);
  const [updatedPremiumCharges, setUpdatedPremiumCharges] = useState({});

  const history = useHistory();
  const checkToken = useCallback(async () => {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }, [isLogged, history, addTokenToState]);

  const getPremiumChargesList = useCallback(async () => {
    var data = await fetchData({
      requestingPage: "premiumCharges",
      method: "get",
      url: "fetch/premium-charges",
      headers: { token: isLogged.accessToken, module: "Premium Charges" },
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        console.log(data);
      } else {
        setPremiumChargesList(data.asset);
        let toEdit = data.asset.filter((row)=>row.premiumId === itemInfo.premiumChargesId)[0];
        setUpdatedPremiumCharges(toEdit);
      }
    } else {
      console.log(data);
    }
   
  }, [isLogged,itemInfo.premiumChargesId])

  const getInformation = useCallback(
    async () => {
      await checkToken();
      await getPremiumChargesList();
    },
    [checkToken, getPremiumChargesList]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const submitUpdatedPremiumCharges = useCallback(async () => {

    var dataSent = await fetchData({
      requestingPage: "premiumCharges",
      method: "put",
      url: "edit/premium-charges/"+itemInfo.premiumChargesId,
      headers: { token: isLogged.accessToken, module: "Premium Charges" },
      data: updatedPremiumCharges,
    });

    setDataSubmitted(true);
    if (dataSent.msg === "success") {
      editPremiumCharges(null);
      setModalText("Saved UOM change successfully!");
      setUpdatedPremiumCharges({});
      getInformation();
    } else {
      setModalText(dataSent.desc);
    }
  }, [isLogged, editPremiumCharges, getInformation, updatedPremiumCharges,itemInfo.premiumChargesId])
  const renderPremiumChargesEditTable = () => {
    return (
      premiumChargesList.map((row, index) => {
        if (row.premiumId === itemInfo.premiumChargesId) {
          return (
            <tr className="uomListData" key={index}>
              <td className="uomSymbolData2">
                <input
                  className="createUom"
                  value={updatedPremiumCharges.premiumNo || ""}
                  onChange={(e) => setUpdatedPremiumCharges({ ...updatedPremiumCharges, premiumNo: e.target.value })}
                  autoFocus
                />
              </td>
              <td className="uomSymbolData2">
                <input
                  className="createUom"
                  value={updatedPremiumCharges.premiumName || ""}
                  onChange={(e) => setUpdatedPremiumCharges({ ...updatedPremiumCharges, premiumName: e.target.value })}
                />
              </td>
              <td className="uomSymbolData2">
                <input
                  className="createUom"
                  type="number"
                  value={updatedPremiumCharges.rate || ""}
                  onChange={(e) => setUpdatedPremiumCharges({ ...updatedPremiumCharges, rate: e.target.value })}
                />
              </td>
             <td className="uomSymbolData2" >
                <button
                  style={{ width: "7rem" }}
                  className="cancelRoleButton"
                  onClick={() => {
                    editPremiumCharges(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ width: "7rem" }}
                  id="SubmitCreate"
                  className="createRoleButton"
                  onClick={() => {
                    if(updatedPremiumCharges.premiumId && updatedPremiumCharges.premiumName && updatedPremiumCharges.rate)
                    submitUpdatedPremiumCharges();
                  }
                  }
                >
                  Save
                </button>
              </td>
            </tr>
          )
        } else {
          return (
            <tr className='uomListData' key={index}>
              <td className="uomSymbolData">{row.premiumNo}</td>
              <td className='uomSymbolData'>{row.premiumName}</td>
              <td className='uomSymbolData'>{row.rate}</td>
              <td className="uomActionData">
                <img
                  className="uomEditImage"
                  alt="Edit Record"
                  src={editIcon}
                  onClick={() => {
                    editPremiumCharges(row.premiumId);
                    setUpdatedPremiumCharges({...row});
                  }}
                  title={"Edit Record"}
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          )
        }
      })
    )
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Updating Premium Charges. Please wait...");

  const submitOkClick = () => {
    setIsOpen(false);
    setModalText("Updating premium charges. Please wait...");
  };
  return (
    <Fragment>
      <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
      <div className="uomListArea">
        <table className="uomListTable">
          <thead>
          <tr className="uomListHeader">
            <td className="uomSymbolHeader">
              <span>Premium No</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Premium Name</span>
            </td>
            <td className="uomSymbolHeader">
              <span>Rate</span>
            </td>
            <td className="uomActionHeader">
              <span>Action</span>
            </td>
          </tr>
          </thead>
          <tbody>
          {renderPremiumChargesEditTable()}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    itemInfo: state.itemInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editPremiumCharges: (value) => dispatch(editPremiumCharges(value)),
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPremiumCharges);