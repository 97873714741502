import React from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import salesReportIcon from "../../assets/sales_report.svg";

import statementIcon from "../../assets/statement.svg";
import customerLedgerIcon from "../../assets/customer_ledger.svg";
import "../styles/item.css";
import { useHistory } from "react-router-dom";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";

const PurchaseReportMenu = ({ isLogged }) => {
  const history = useHistory();

  const purchaseReportParams = [
    { menu: "Vendor Ledger", route: "vendor-ledger", icon: customerLedgerIcon },
    { menu: "Vendor Invoice", route: "vendor-invoice", icon: statementIcon },
  ];

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Reports" selectedSubMenu="Purchase Report" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={salesReportIcon} pageTitle="Purchase Report" />
          <span className="itemImagesGrid" style={{ display:"flex",flexWrap:"wrap",maxWidth:"80%" }}>
            {purchaseReportParams.map((obj,i) =>
              isLogged.access[obj.menu] ? (
                <div key={i}
                  className={
                    isLogged.access[obj.menu] !== null
                      ? "singleItem"
                      : "singleItemDisabled"
                  }
                  onClick={() => {
                    if (isLogged.access[obj.menu] !== null) {
                      history.push(`/${obj.route}`);
                    }
                  }}
                  alt={obj.menu}
                  title={
                    isLogged.access[obj.menu] !== null ? 
                    obj.menu 
                    : "No Access"
                  }>
                  <span className="itemText"></span>
                  <img
                    className={
                      isLogged.access[obj.menu] !== null
                        ? "itemImage"
                        : "itemImageDisabled"
                    }
                    src={obj.icon}
                    alt="Category"
                  />
                  <span className="itemText">{obj.menu}</span>
                </div>
              ) : null
            )}
          </span>
          {/* </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(PurchaseReportMenu);