import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { editDcId } from "../../redux/PurchaseOrder/PoInfoActions";
import DeclineModal from "../SmallComponents/DeclineModal";

const DcDetail = ({isLogged,addTokenToState,setSection,editDcId,poInfo,setEditAndApprove,approval})=>{
    const [dcDetail, setDcDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    const [vendorLogo, setVendorLogo] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getDcDetail();
    }

    const history = useHistory();

    async function checkToken() {
        // console.log("Checking token");
        // console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getDcDetail() {
        var data = await fetchData({
            requestingPage: "dcDetail",
            method: "get",
            url: "dclist-fetch/dc-detail/" + poInfo.viewDcId,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });
        if (data.msg === "success") {
            setDcDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {
        getVendorLogo();
    }, [dcDetail]);

    async function getVendorLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/vendor-logo/" + dcDetail.vendorId,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });
        setVendorLogo(data);
    }

    const renderSummaryInfo = () => {
        if (dcDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        DC Id: {dcDetail.dcNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                dcDetail.requestStatus === "Declined"
                                    ? { color: "red" }
                                    : dcDetail.requestStatus === "Approved"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }
                        >
                            ( {dcDetail.requestStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">DC Order Date</span>
                        <span className="vendorSummaryValue">: {dcDetail.orderDate}</span>
                        <span className="vendorSummaryCategory">DC Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {dcDetail.expectDate}</span>
                        <span className="vendorSummaryCategory">DC No</span>
                        <span className="vendorSummaryValue">: {dcDetail.userDcNo}</span>
                        <span className="vendorSummaryCategory">Production Order No</span>
                        <span className="vendorSummaryValue">: {dcDetail.prodNo}</span>
                        <span className="vendorSummaryCategory">Process Name</span>
                        <span className="vendorSummaryValue">: {dcDetail.processName}</span>
                        
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
                            ? `data:image/png;base64,${vendorLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                   <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td> 
                        <td>UOM</td>
                        <td>Allowed Dc Qty</td>
                        {dcDetail.requestStatus!=="Declined" ? <td>Total DC Qty</td> : null}
                        <td>Current DC Qty</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        let lineItemParams =  ["itemName", "itemCode","from","to","netWeight","grossWeight", "uom", "allowedDcQuantity","previousDc","currentDc"] ;
         if(dcDetail.requestStatus==="Declined")
         lineItemParams.splice(lineItemParams.length-2,1);
        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] !==null ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    async function declineDc() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "declineDc",
            method: "put",
            url: `dclist-edit/dc-decline/${poInfo.viewDcId}`,
            headers: { token: isLogged.accessToken,module: "Purchase Order" },
            data: { reason: declineReason },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("DC Declined");
        } else {
            // console.log(data);
            setModalText(data.desc);
            setErrorMessage([data.desc]);
        }
    }

    async function approveDc() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approveDc",
            method: "put",
            url: `dclist-edit/dc-approve/${poInfo.viewDcId}`,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("DC Approved");
        } else {
            // console.log(data);
            setModalText(data.desc);
            setErrorMessage([data.desc]);
        }
    }
    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button
                        className="declineButton"
                        onClick={() => {
                            setDeclineModalIsOpen(true);
                        }}
                    >
                        Decline
                    </button>
                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editDc");
                            editDcId(poInfo.viewDcId);
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            approveDc();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const declineOkClick = () => {
        if (declineReason.trim().length > 0) {
            setDeclineReasonError(false);
            setDeclineModalIsOpen(false);

            setIsOpen(true);
            setModalText("Declining DC. Please Wait...");
            declineDc();
        } else {
            setDeclineReasonError(true);
        }
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("dcList");
        }
        setModalText("Approving DC. Please wait...");
    };

    const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
    const [declineReason, setDeclineReason] = useState("");
    const [declineReasonError, setDeclineReasonError] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Dc. Please wait...");
    return (
        <React.Fragment>
            <DeclineModal
                openDeclineModal={declineModalIsOpen}
                declineOkClick={declineOkClick}
                declineModalText={"Provide a reason for DC Decline"}
                declineReason={declineReason}
                error={declineReasonError}
                setDeclineReason={setDeclineReason}
                cancelDecline={() => setDeclineModalIsOpen(false)}
            />
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {`${dcDetail.vendorId} - ${dcDetail.vendorName}`}
                            </span>

                            <span className="poSummaryValue">
                                {dcDetail.vendorPinCode !== null
                                    ? `Address: ${dcDetail.vendorAddress}${dcDetail.vendorCity} ${dcDetail.vendorState || ""}- ${dcDetail.vendorPinCode}`
                                    : `Address: ${dcDetail.vendorAddress} ${dcDetail.vendorCity} ${dcDetail.vendorState || ""}`}
                            </span>

                            <span className="poSummaryValue">
                                {dcDetail.vendorMobile !== null || dcDetail.vendorPhone !== null ? `\nPhone: ` : null}
                                {dcDetail.vendorMobile !== null ? dcDetail.vendorMobile : null}
                                {dcDetail.vendorPhone !== null ? `, ${dcDetail.vendorPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">{dcDetail.vendorEmail !== null ? `Email Id: ${dcDetail.vendorEmail}` : null}</span>

                            <span className="poSummaryValue">{dcDetail.vendorGstNo !== null ? `GST No: ${dcDetail.vendorGstNo}` : null}</span>
                        </div>
                        {renderImageStatus()}
                    </div>

                    {dcDetail.requestStatus === "Declined" ? (
                        <div style={{ marginTop: "2rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {dcDetail.declineReason}
                        </div>
                    ) : null}

                    <br/>
                        {/* <div className="purchaseInstruction"> */}
                            <div style={{fontSize:"14px"}}>
                                Number of Items: <span>{lineItemsList.length}</span>
                            </div>
                            <div className="vendorDetailTabArea">
                        <RenderTable />
                        </div>
                            <div style={{ marginTop: "10px" }}>
                                Dc Instruction: {dcDetail.instruction !== null ? dcDetail.instruction : "-"}
                            </div>
                        </div>
                        {/* </div> */}
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editDcId: (dcId) => dispatch(editDcId(dcId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DcDetail);