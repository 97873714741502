import update from "immutability-helper";

const customerObj = { viewCustomerId: null, editCustomerId: null };

const CustomerInfo = (state = customerObj, action) => {
    switch (action.type) {
        case "VIEW_CUSTOMER":
            return update(state, { viewCustomerId: { $set: action.payload.customerId } });

        case "EDIT_CUSTOMER":
            return update(state, { editCustomerId: { $set: action.payload.customerId } });

        default:
            return state;
    }
};

export default CustomerInfo;
