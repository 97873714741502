import React,{useEffect, useState} from "react";
import FormElement from "../SmallComponents/FormElement";
import  update  from "immutability-helper";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import resetIcon from "../../assets/return.svg";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import { useHistory } from "react-router-dom";
import { isSortedArray } from "../CommonFunctions/utils";
import { bomShortCut } from "../../redux/UserAccount/IsLoggedActions";

const CreateWorkOrder=({setSection,isLogged,bomShortCut,poInfo,addTokenToState})=>{
   const [selectedTab,setSelectedTab] = useState("");
    const [workOrderInputs,setWorkOrderInputs] = useState({
                 productionId: {
                    inputType: "options",
                    hintText: "Production Number",
                    value: "",
                    mandatory: true,
                    colSpan: 4,
                    error: false,
                    options: [],
                    errorMessage: "Please Select Error Message to Proceed",
                 },
                 soNo: {
                    inputType: "text",
                    hintText: "So No",
                    value: "",
                    error: false,
                    colSpan: 4 ,
                    disabled: true,
                 },
                 itemId:{
                    inputType: "options",
                    options: [],
                    value: "",
                    colSpan: 4,
                    error: false,
                    errorMessage: "Please Select an Item",
                    hintText: "Item Name",
                    mandatory: true
                 },
                 productionQuantity:{
                  inputType: "float",
                  value: "",
                  hintText: "Production Quantity",
                  colSpan: 4,
                  error: false,
                  errorMessage: "",
                  disabled: true,
                 },
                 bomId: {
                    inputType: "options",
                    options: [],
                    value: "",
                    colSpan: 4,
                    error: false,
                    errorMessage: "",
                    hintText: "Select Bill Of Material",
                     mandatory: true
                 },
                 bomDocumentName:{
                  inputType: "text",
                  value: "",
                  colSpan: 4,
                  error: false,
                  errorMessage: "",
                  disabled: true,
                  hintText: "BOM Document Name",
                  mandatory: false
                 },
                 workOrderStartDate: {
                    inputType: "dateTimePicker",
                    hintText: "Work Order Start date",
                    mandatory: true,
                    colSpan: 4,
                    value:"",
                    error: false,
                    errorMessage: "Please Select Process Start Date",
                    mindate: true,
                 },
                 workOrderEndDate: {
                    inputType: "dateTimePicker",
                    hintText: "Work Order End Date",
                    mandatory: false,
                    colSpan : 4,
                    value:"",
                    error: false,
                    errorMessage: "Please Select an End Date",
                    mindate: false
                 },
                 productionOrderType: {
                  value: ""
                 }
                });
   const [processCardParams,setProcessCardParams] = useState({
      employeeId: {
              inputType: "options",
              hintText: "Employee ID",
              colSpan: 5,
              value: "",
              options: [],
              error: false,
              errorMessage: "Please Select a Employee Name",
              mandatory: true,
            },
            employeeName:{
            inputType: "text",
            hintText: "Employee Name",
            disabled: true,
            mandatory: false,
            colSpan: 6,
            value: "",
            error: false,
            errorMessage: ""
         },
         machineNo: {
            inputType: "text",
            colSpan: 4,
            value: "",
            error: false,
            hintText: "Machine No"
         },
           pickedQuantity:{
            inputType:"float",
            hintText: "Assigned Quantity",
            colSpan: 4,
            mandatory: true,
            error: false,
            errorMessage: "Please Provide Valid Quantity and Less than Production Quantity",
            value: ""
           },
           producedQuantity:{
            inputType:"float",
            hintText: "Produced Quantity",
            colSpan: 4,
            mandatory: true,
            error: false,
            errorMessage: "Please Provide Valid Produced Quantity and Less than Production Quantity",
            value: ""
           },
           rejectedQuantity:{
            inputType:"float",
            hintText: "Rejected Quantity",
            colSpan: 4,
            mandatory: true,
            error: false,
            errorMessage: "Please Provide Valid Passed Quantity and Less than Rejected Quantity",
            value: "",
            disable: true
         },
         repairQuantity:{
            inputType:"float",
            hintText: "Repair Quantity",
            colSpan: 4,
            mandatory: true,
            error: false,
            errorMessage: "Please Provide Valid Repair Quantity and Less than Produced Quantity",
            value: ""
         },
         passedQuantity:{
            inputType:"float",
            hintText: "Passed Quantity",
            colSpan: 4,
            mandatory: true,
            error: false,
            errorMessage: "Please Provide Valid Rejected Quantity and Less than Passed Quantity",
            value: 0,
            disabled: true,
           },
   });
   const [selectedProduction,setSelectedProduction] = useState({});
   const [selectedBOM,setSelectedBOM] = useState({});
   const [selectedItem,setSelectedItem] = useState({});
   const [workOrderErrors,setWorkOrderErrors] = useState([]);
   const [processCardLineItems,setProcessCardLineItems] = useState({});
   const history = useHistory();
   async function checkToken() {
   // console.log(isLogged.accessToken);
   const token2 = await FetchNewToken(isLogged.accessToken);
   if (token2 === "expired") {
      history.push("/");
   } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
   } else {
      console.log("Token not changed");
   }
   }
              
   async function getInformation(){
      await checkToken();
   let productionOptions = await getApprovedProductionList();
   let employeeList = await getEmployees();
   let workOrderInputsCopy = Object.assign(workOrderInputs);
   let processCardParamsCopy = Object.assign(processCardParams);

   workOrderInputsCopy = update(workOrderInputsCopy,{productionId: {options: {$set: productionOptions} },
                                                });
   processCardParamsCopy = update(processCardParamsCopy, {employeeId: {options: {$set: employeeList} } })
   setProcessCardParams(processCardParamsCopy);
   setWorkOrderInputs(workOrderInputsCopy);
   }
async function getApprovedProductionList(){
   var data = await fetchData({
      method: "post",
      url: 'production-create/work-order-pre-create',
      requestingPage: "workOrder",
      headers: {token: isLogged.accessToken, module: "Work Order"}
   });

   if(data.msg==="success"){
      return data.asset;
   } else{
      return [];
   }
} async function getEmployees() {
   var data = await fetchData({
       requestingPage: "employeeList",
       method: "POST",
       url: "fetch/employees",
       headers: { token: isLogged.accessToken,module:"Employee" },
       data: {filter: 'filterByPlant'}
   });
   if (data.msg === "success") {
     return data.asset;
   } else {
      console.log(data);
       return [];
   }
}
   useEffect(()=>{
      getInformation()
   },[])

   const updateWorkOrderParameters=({section,paramName,key,value,wholeObject})=>{
      if(section==="general"){
         let workOrderInputsCopy = Object.assign(workOrderInputs);
         workOrderInputsCopy = update(workOrderInputsCopy,{[paramName]: {[key]: {$set: value} } });
         if(paramName==='productionId' )
         {  
            if(value && wholeObject!==undefined)
            {
               workOrderInputsCopy = update(workOrderInputsCopy,{["soNo"]: {["value"]: {$set: wholeObject.soNo} },
                                                       ["itemId"]: {["options"]: {$set: wholeObject.lineItems} ,["value"]: {$set: ""} },
                                                       ["productionQuantity"]: {["value"]: {$set: ""}},
                                                       ['productionOrderType']: {['value']: {$set: wholeObject.soNo ? 'Make To Order' : 'Make To Stock'}}
                                                      });
               setSelectedProduction(wholeObject);
            }
            else{
               workOrderInputsCopy = update(workOrderInputsCopy,{["soNo"]: {["value"]: {$set: ""} },
                                                      ["itemId"]: {["options"]: {$set: []},["value"]: {$set: ""} },
                                                      ["productionQuantity"]: {["value"]: {$set: ""}},
                                                      ['productionOrderType']: {['value']: {$set: ""} }
                                          });
              setSelectedProduction({});
            }
         } else if(paramName==='bomId'){
            if(value && wholeObject!==undefined){
               workOrderInputsCopy = update(workOrderInputsCopy,{ bomDocumentName: { value: {$set: 
                                                         wholeObject.bomDocumentName} }})
               setSelectedBOM(wholeObject);
               let {fgProcessItems} = wholeObject
               setSelectedTab(fgProcessItems?.[0] || 'Process 1')
               fgProcessItems = fgProcessItems?.reduce((acc,item)=>({...acc,[item]: []}),{}) || ["Process 1"]
               setProcessCardLineItems(fgProcessItems);
            }else{
               workOrderInputsCopy = update(workOrderInputsCopy,{ bomDocumentName: { value: {$set: ""} }})
               setSelectedBOM({});
               setSelectedTab("");
               setProcessCardLineItems({});
            }
         } else if(paramName==='itemId'){
            if(value && wholeObject!==undefined){
            workOrderInputsCopy = update(workOrderInputsCopy,{ productionQuantity: { value: {$set: 
                     wholeObject.productionQuantity} } })
            setSelectedItem(wholeObject);
            } else{
               workOrderInputsCopy = update(workOrderInputsCopy,{ productionQuantity: { value: {$set: ""} } })
               setSelectedItem({});
            }
         }
         setWorkOrderInputs(workOrderInputsCopy);
      } else if(section==='subSection'){
         let processCardParamsCopy = Object.assign(processCardParams);
         
         if(paramName === 'employeeId')
         {  
            if(value && wholeObject!==undefined)
            {
               processCardParamsCopy = update(processCardParamsCopy,{[paramName]: {[key]: {$set: value } } });
            processCardParamsCopy = update(processCardParamsCopy,{employeeName: {value: 
                                                   {$set: wholeObject.employeeName} } });
            }else{
               processCardParamsCopy = update(processCardParamsCopy,{employeeName: {value: 
                  {$set: ""} } });
               }
            } else if(paramName === 'machineNo')
            {
            processCardParamsCopy = update(processCardParamsCopy,{machineNo: {value: 
               {$set: value} } });
            } else if(paramName !== 'passedQuantity' && paramName!=='employeeId' && paramName!== 'machineNo')
            {
            //value = parseFloat(value) > 0 ? parseFloat(value) : 0;
            processCardParamsCopy = update(processCardParamsCopy,{[paramName]: {[key]: {$set: value } } });
            let passedQtyCalc = processCardParamsCopy['producedQuantity'].value - (processCardParamsCopy['repairQuantity'].value+
                                       processCardParamsCopy['rejectedQuantity'].value);
            processCardParamsCopy = update(processCardParamsCopy, {['passedQuantity']: {value: {$set: passedQtyCalc} } })
         }
           
         setProcessCardParams(processCardParamsCopy);
      }
   } ;

async function getBOMOptions(itemId){
   let data = await fetchData({
      method: "post",
      url: 'bill-of-material/get-bom-detail',
      requestingPage: "workOrder",
      data: {fgItemId: itemId},
      headers: {token: isLogged.accessToken, module: "Work Order"}
   });

   if(data.msg==='success'){
      return data.asset;
   }else{
      return [];
   }
}
const getForBomShortcut=()=>{
   let workOrderInputsCopy = Object.assign(workOrderInputs);
   getBOMOptions(workOrderInputs.itemId.value).then((bomOptions)=>{
      workOrderInputsCopy = update(workOrderInputsCopy,{bomId: {options: {$set: bomOptions} } });
      setWorkOrderInputs(workOrderInputsCopy);
   });
}
   useEffect(()=>{
      if(workOrderInputs.itemId.value){
      getForBomShortcut()
   } else{
      let workOrderInputsCopy = Object.assign(workOrderInputs);
      workOrderInputsCopy = update(workOrderInputsCopy,{bomId: {options: {$set: [] },value: {$set: ""} },
                                                         productionQuantity: {value: {$set: "" } } })
      setWorkOrderInputs(workOrderInputsCopy);
   }
   },[workOrderInputs.itemId.value]);

   const renderFormElements = ({ elementList, param, section }) => {
      return elementList.map((element) => {
         if(element !== 'productionOrderType')
            return (
               <FormElement
               key={element}
               inputType={param[element].inputType}
               value={param[element].value !== null ? param[element].value : ""}
               setInput={(value,wholeObject) => {
                  updateWorkOrderParameters({section,paramName: element,
                                 key: "value",value: value,wholeObject});
               }}
               hintText={param[element].hintText}
               mandatory={param[element].mandatory}
               colSpan={param[element].colSpan}
               options={
                  param[element].inputType === "options"
                     ? param[element].options
                     : null
               }
               error={param[element].error}
               rowSpan={
                  element === "vendorLogo" || element === "otherBusinessDetails"
                     ? param[element].rowSpan
                     : null
               }
               placeholder={param[element].placeholder}
               title={param[element].title}
               disabled={param[element].disabled}
               mindate={param[element].mindate}
               />
            );
      });
   };
      
   const [modalIsOpen, setIsOpen] = useState(false);
   const [dataSubmitted, setDataSubmitted] = useState(false);
   const [modalText, setModalText] = useState(
     "Creating Work Order. Please wait..."
   );
   const submitOkClick=()=>{
      setIsOpen(false);
      if(workOrderErrors.length== 0)
      setSection("workOrderList")
   };

   const editSubSectionItem=(index,action)=>{
      let lineCopy = Object.assign(processCardLineItems)
      let lineItemsCopy = lineCopy[selectedTab].slice();
      let processCardParamsCopy = Object.assign(processCardParams);
      if(action==='edit')
      {
         let editObj = lineItemsCopy.splice(index,1)[0];

         Object.keys(processCardParamsCopy).forEach((key)=>{
            processCardParamsCopy = update(processCardParamsCopy,{[key] : {["value"]:  {$set: editObj[key]} } })
         });
         
      }else if(action==='delete'){
         let deleteObj = lineItemsCopy.splice(index,1)[0];
      }
      lineCopy = update(lineCopy,{[selectedTab]: {$set : lineItemsCopy} })
      setProcessCardParams(processCardParamsCopy);
      setProcessCardLineItems(lineCopy);
   }
   const RenderTable=()=>{
      const tableColumns = ['employeeNo','employeeName','machineNo','pickedQuantity','producedQuantity',
                           'repairQuantity','rejectedQuantity','passedQuantity']; 
      return(
      <React.Fragment>
         <table className="createVendorContactsTable">
            <thead>
               <tr className="createVendorContactsTableHeader">
                  <td>Employee Id</td>
                  <td>Employee Name</td>
                  <td>Machine No</td>
                  <td>Picked Quantity</td>
                  <td>Produced Quantity</td>
                  <td>Repair</td>
                  <td>Rejected</td>
                  <td>Passed</td>
                  <td className="itemIndiTabActions">Actions</td>
               </tr>
            </thead>
            
            <tbody>
               {processCardLineItems[selectedTab]?.length > 0 ? (
                  processCardLineItems[selectedTab]?.map((row,i)=>
                  (<tr className="createVendorContactsTableRows" key={i}>
                     
                     {tableColumns.map((col)=>
                     (<td key={col}>{row[col] || 0}</td>)                     
                     )}
                        <td className="itemIndiTabActions">
                           <img
                           alt="Edit Record"
                           className="createVendorContactsAction"
                           src={editIcon}
                           onClick={() => {
                              editSubSectionItem(i, "edit");
                           }}
                           />
                           <img
                           alt="Delete Record"
                           className="createVendorContactsAction"
                           src={deleteIcon}
                           onClick={() => {
                              var reset = window.confirm("Delete record?");
                              if (reset) {
                                 editSubSectionItem(i, "delete");
                              }
                           }}
                           />
                     </td>
                  </tr>)
                  )
               ) :
               (<tr className="createVendorContactTableRows">
                  {Object.keys(processCardParams).map((item)=><td key={item}>&nbsp;</td>)}
                  <td>&nbsp;</td>
               </tr>)}
            </tbody>
         </table>
      </React.Fragment>)
   }

   const checkLineItemError=()=>{

      let processCardParamsCopy = Object.assign(processCardParams);
      let errorList=[];
      const conditionInputs = ['pickedQuantity','producedQuantity',//'testedQuantity',
      'passedQuantity','repairQuantity','rejectedQuantity'];
      Object.keys(processCardParamsCopy).forEach((param)=>{

         if(processCardParamsCopy[param].mandatory){

            processCardParamsCopy = update(processCardParamsCopy, {
               [param]: {
                 error: {
                   $set: !validateMandatory(processCardParamsCopy[param].value.toString()),
                 },
               },
             });
            }
             if(conditionInputs.includes(param) )
             {
               processCardParamsCopy = update(processCardParamsCopy, {
                  [param]: {
                    error: {
                      $set: processCardParamsCopy[param].value > selectedItem.productionQuantity || processCardParamsCopy[param].value <0 ,
                    },
                  },
                });
             }
             if(processCardParamsCopy[param].error){
               errorList.push(processCardParamsCopy[param].errorMessage);
             }
      });
      if(!processCardParamsCopy['pickedQuantity']['value']){
         processCardParamsCopy['pickedQuantity']['error'] =true
         errorList.push('Pick A Quantity to add to List')
      }
      // let orderOfParams = conditionInputs.map((paramName)=> processCardParamsCopy[paramName].value );
      // if(isSortedArray.ascending(orderOfParams))
      // errorList.push("The Sum of A Line Item Should Be Valid and Less Than Production Quantity");
      let conditionCheck1 = processCardParamsCopy["pickedQuantity"].value >= processCardParamsCopy["producedQuantity"].value;
      let conditionCheck2 = processCardParamsCopy["producedQuantity"].value === processCardParamsCopy["passedQuantity"].value + processCardParamsCopy["repairQuantity"].value + processCardParamsCopy["rejectedQuantity"].value;
      let conditionCheck3 = processCardParamsCopy["producedQuantity"].value >=  (processCardParamsCopy["rejectedQuantity"].value + processCardParamsCopy["repairQuantity"].value)
      if( !(conditionCheck1 && conditionCheck2 && conditionCheck3) )
      errorList.push("The Sum of A Line Item Should Be Valid and Less Than Produced Quantity");

      let lineCopy = Object.assign(processCardLineItems);
      let lineItemCopy = lineCopy[selectedTab]?.slice() || [];
      let selectedEmployee = processCardParamsCopy["employeeId"]["options"]
                           .filter(
                              (option) =>
          option["optionId"] === processCardParamsCopy["employeeId"]["value"]
          )[0];
      if(lineItemCopy.findIndex((line)=>line.employeeNo == selectedEmployee.optionName) > -1)
      errorList.push('Employee Already added in this Process')
      if(errorList.length===0)
      {  
         let newLineItem={};
         Object.keys(processCardParamsCopy).forEach((param)=>{
               newLineItem[param] = processCardParamsCopy[param].value
         });
         
         newLineItem['employeeOption'] = selectedEmployee;
         newLineItem['employeeNo'] = selectedEmployee['optionName'];
         lineItemCopy.push(newLineItem);
          processCardParamsCopy = update(processCardParamsCopy,
                                             {employeeId: {value: { $set: "" } },
                                             employeeName: {value: {$set: ""} },
                                             machineNo: {value: {$set: ""} } ,
                                             pickedQuantity: {value: {$set: ""} },
                                             producedQuantity: {value: {$set: ""} }, 
                                             passedQuantity: {value: {$set: 0} }, 
                                             repairQuantity: {value: {$set: ""} },
                                             rejectedQuantity: {value: {$set: ""}} 
                                                         });
         lineCopy[selectedTab] = lineItemCopy;

         setProcessCardLineItems(lineCopy);
      }
         setWorkOrderErrors(errorList);
         setProcessCardParams(processCardParamsCopy);

   }
   const checkErrorsToSubmitWorkOrder=()=>{
      let errorList=[];
      let workOrderInputsCopy = Object.assign(workOrderInputs);

      Object.keys(workOrderInputs).forEach((param)=>{
         if(workOrderInputsCopy[param].mandatory){

            workOrderInputsCopy = update(workOrderInputsCopy, {
               [param]: {
                 error: {
                   $set: !validateMandatory(workOrderInputsCopy[param].value.toString()),
                 },
               },
             });
            };

            if(workOrderInputsCopy[param].error){
               errorList.push(workOrderInputsCopy[param]["errorMessage"])
            }
      });

      if(new Date(workOrderInputsCopy['workOrderStartDate'].value) >= new Date(workOrderInputsCopy['workOrderEndDate'].value))
      errorList.push('Process End Date cannot be Older than Start Date')
      let lineItemsCopy =Object.assign(processCardLineItems);
      
      Object.entries(lineItemsCopy).map(([key,value])=>{
         if(value.length==0){
            errorList.push(`Add atleast one Employee  in ${key}`)
         }else{
         let sumOfLine=value.reduce((prev,curr)=>prev+ curr['pickedQuantity'],0);

         if(sumOfLine > workOrderInputsCopy['productionQuantity'].value)
         errorList.push(`Picked Quantity Exceeded the Total Production Quantity in ${key}`);
         }
      })

      if(errorList.length==0){
         let dataToServer = {
            productionId: workOrderInputsCopy['productionId'].value,
            soNo: workOrderInputsCopy['soNo'].value,
            itemId: workOrderInputsCopy['itemId'].value,
            productionQuantity: workOrderInputsCopy['productionQuantity'].value,
            bomId: workOrderInputsCopy['bomId'].value,
            workOrderStartDate: workOrderInputsCopy['workOrderStartDate'].value,
            workOrderEndDate: workOrderInputsCopy['workOrderEndDate'].value,
            productionOrderType: workOrderInputsCopy['productionOrderType'].value,
            lineItems: lineItemsCopy,
            selectedItem 
         }

         submitWorkOrder(dataToServer);
      }
      setWorkOrderErrors(errorList);
      setWorkOrderInputs(workOrderInputsCopy);
   }
   
   const submitWorkOrder=async(data)=>{
      setIsOpen(true);
    setModalText("Creating Work Order. Please wait...!");

      let response = await fetchData({
         method: 'post',
         requestingPage: 'workOrder',
         headers: {token: isLogged.accessToken, module: "Work Order"},
         data,
         url: 'production-create/create-work-order',
      });

      setDataSubmitted(true);
      if(response.msg==='success'){
        setModalText('Work Order SuccessFully Created')
      } else if(response.msg==='failure'){
         setModalText(response.desc);
         setWorkOrderErrors([response.desc])
      } else{
         setModalText("Please Try Again Later")
         setWorkOrderErrors([])
      }
   }
   // const copyForAllProcess=()=>{
   //    let processCardLineCopy = Object.assign(processCardLineItems);
   //    let firstLine = Object.values(processCardLineCopy)[0]
   //    Object.keys(processCardLineCopy).forEach((key)=>{
   //       processCardLineCopy[key] = firstLine;
   //    });
   //    setProcessCardLineItems(processCardLineCopy);
   //    window.alert('Copied For All Process')
   // }
   // const copyFromPreviousProcess=()=>{
   //    let previousTab = selectedBOM.fgProcessItems.findIndex((elem)=>elem===selectedTab)-1;
   //    previousTab = selectedBOM.fgProcessItems[previousTab];
   //   let processCardLineCopy = Object.assign(processCardLineItems);
   //   processCardLineCopy[selectedTab] = processCardLineCopy[previousTab];
   //   setProcessCardLineItems(processCardLineItems)
   //   window.alert('Copied From Previous Process') 
   // }
   const copyProcess=({tabIndex})=>{
      let processCardLineCopy = Object.assign(processCardLineItems);
      let message;
      if(tabIndex == 0){
         let firstLine = Object.values(processCardLineCopy)[0]
         Object.keys(processCardLineCopy).forEach((key)=>{
            processCardLineCopy[key] = firstLine;
         });
         message="Copied For All Processes"
      }else{
         let previousTab = selectedBOM.fgProcessItems[tabIndex-1];
         processCardLineCopy[selectedTab] = processCardLineCopy[previousTab];
         message = "Copied From Previous Process"
      }
      setProcessCardLineItems(processCardLineCopy);
      window.alert(message);
   }
   const renderSubSection=({tabIndex})=>{

      return(
         <React.Fragment>
            <div className="purchaseOrderIndividualItemsArea" style={{gridAutoRows:"60px"}}>
               {renderFormElements({elementList:Object.keys(processCardParams),param:processCardParams,section:'subSection'})}
               
               <FormElement inputType="addButton" value="+ Add"
                  colSpan={4} setInput={() => { setWorkOrderErrors([]); checkLineItemError(); }} />
            </div>
             {/*PROCESS TABLE*/}
             <RenderTable />
                <FormElement inputType="addButton" value={tabIndex===0 ? "Copy table for All Process" : "Copy from Previous Process"}
               setInput={()=>{copyProcess({tabIndex})}}
               styles={{width:"250px",margin:"5px",}}/> 
         </React.Fragment>
      )
   }

   const renderTabs=()=>{
      const processSteps = selectedBOM.fgProcessItems || ["Process 1"]
      if(workOrderInputs.bomId.value > 0){
        return (<React.Fragment>
                <div className="createPurchaseImportTabArea">
                  <div className="createVendorTabs"  style={{ borderBottom: "1px solid rgb(240,240,240)" }}>
                  { 
                    processSteps.map((tab,index)=>(
                      <div key={Math.random()} className={selectedTab === tab ? "createPurchaseImportTabSelected" : "createPurchaseImportTab"}
                        style={{lineHeight: "15px",fontWeight:"500"}}
                          onClick={()=>{if( processCardLineItems[processSteps[index-1]]?.length || index==0)setSelectedTab(tab)}}>
                          {tab.toUpperCase()}
                      </div>
                    ))
                  }
                  </div>
                <div style={{fontSize:"2px"}}>&nbsp;</div>
                <div className="purchaseOrderSubsectionArea">
                {renderSubSection({tabIndex:  processSteps.findIndex((elem)=>elem===selectedTab)})}
                </div>
                </div>
                </React.Fragment>
            )
      }else{
        return null
      }
    }
   const renderErrorMessage=()=>{
      if(workOrderErrors.length > 0)
      return workOrderErrors[0]
   }
   const newTabForBOMCreation=()=>{
      bomShortCut(workOrderInputs.itemId.value)
      let toBomPath = window.location.href.split('#');
      toBomPath[1] = '/bill-of-material';
      toBomPath = toBomPath.join('#');
      window.open(toBomPath,'_blank')
   }
    return(
        <React.Fragment>
         <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText}
            dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
               <div style={{width: "1000px", margin: "0 auto 2rem",
                        padding: "2rem",border: "1px solid lightgray",
                        borderRadius: "5px",backgroundColor: "white",}}>
                  {/*GENERAL SECTION */}
                  <div style={{ display: "flex",marginBottom: "15px" }}>
                     <div className="createPurchaseOrderGrid"
                           style={{ gridTemplateRows: "repeat(3, 4rem)" }}>
                        {renderFormElements({ elementList: Object.keys(workOrderInputs),
                                                param: workOrderInputs,section: "general",})}
                        {
                           workOrderInputs.itemId.value && workOrderInputs.bomId.options.length == 0 ?
                           <div style={{width: "150px" ,display:"flex",height:"3rem",gridRow: "span 1/ 4",alignItems:"center"}}>
                           <button className="submitButton" onClick={(e)=>{
                              newTabForBOMCreation()
                           }} title="Create BOM for Selected Item" style={{height: "-webkit-fill-available"}}
                           >
                                 Create BOM
                           </button>
                           <img
                           style={{ padding: "10px", width: "1rem", height: "1rem",cursor:"pointer",backgroundColor: "lightgray" }}
                           src={resetIcon}
                           alt="Reset"
                           title="Refresh To get Created BOM of selected Item"
                           onClick={() => {
                              getForBomShortcut();
                           }}
                           />
                           </div> 
                           : ""
                        }
                     </div>
                  </div>
                  {/*Sub section*/}
                  {/* {workOrderInputs.bomId.value ? 
                  <div className="purchaseOrderSubSectionArea">
                     {renderSubSection()}
                  </div> : null } */}
                  {workOrderInputs.bomId.value ? renderTabs(): null}
                  </div>
               </div>
                  <div className="formSubmitArea">
                  
                  <div className="formSubmitInnerArea">
                     <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                     <button
                        className="submitButton"
                        onClick={() => {
                           setWorkOrderErrors([]);
                        checkErrorsToSubmitWorkOrder();
                        }}>
                        Submit
                     </button>
                  </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
   return {
     isLogged: state.isLogged,
     poInfo: state.poInfo,
   };
 };
 const mapDispatchToProps = (dispatch) => {
   return {
     addTokenToState: (accessToken, employeeId) =>dispatch(addToken(accessToken, employeeId)),
       bomShortCut: (itemId)=>dispatch(bomShortCut(itemId))
   };
 };
 
 export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrder);