import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import downIcon from "../../assets/down.svg";
import {
    editWorkOrderId,
    viewWorkOrderId,
  } from "../../redux/PurchaseOrder/PoInfoActions";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FormElement from "../SmallComponents/FormElement";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import Pagination from "../CommonFunctions/pagination";

const WorkOrderList=({setSection,
    setApproval,
    // state
    isLogged,
    poInfo,
    editWorkOrderId,
    viewWorkOrderId,
    // dispatch
    addTokenToState,
  })=>{
        const [workOrderList,setWorkOrderList] =useState([]);
        const [currentPage,setCurrentPage]=useState(1);
        const [PageSize,setRowsPerPage]=useState(10);
        const [loading, setLoading] = useState(true);
        const [noRecords,setNoRecords] = useState(false);
        const [plantId,setPlantId] = useState("");
        const workOrderCurrentPage = useMemo(() => {
            const firstPageIndex = (currentPage - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            return workOrderList.slice(firstPageIndex, lastPageIndex);
          }, [currentPage,workOrderList,PageSize]);
          
          
          useEffect(() => {
            getInformation();
          }, []);

          async function getInformation() {
            await checkToken();
            await getWorkOrdersList();
          }
        
          const history = useHistory();
          async function checkToken() {
            
            const token2 = await FetchNewToken(isLogged.accessToken);
            if (token2 === "expired") {
              history.push("/");
            } else if (token2 !== isLogged.accessToken) {
             
              addTokenToState(isLogged.employeeId, token2);
            } else {
              console.log("Token not changed");
            }
          }
        
          async function getWorkOrdersList() {
            var data = await fetchData({
              requestingPage: "workOrder",
              method: "post",
              url: "production-fetch/work-order-list",
              headers: { token: isLogged.accessToken, module: "Work Order" },
              data: {plantId}
            });
            if (data.msg === "success") {
              if (data.asset.length === 0) {
                setNoRecords(true);
              }
        
              var data2 = [];
              data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
              });
        
              setWorkOrderList(data2);
            }
            setLoading(false);
          }

          const editItemCollapse = ( index) => {
            var listCopy = workOrderCurrentPage.slice()
            let clickedIndex = ((currentPage-1) * PageSize) + index;
            listCopy.forEach((item, j) => {
              if (j === clickedIndex) {
                listCopy[j].collapse = !item.collapse;
              } else {
                listCopy[j].collapse = true;
              }
            });
            setWorkOrderList(listCopy);
          };
          const access = isLogged.access["Work Order"];
          const [order, setOrder] = useState({ col: "workOrderNo", state: true, data: "" });
          const CommonColumns=({data})=>{

            return(
              <>
              <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "workOrderNo",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "workOrderNo",
                        state: order.state,
                      });
                    }}>Work Order No<img
                    className="sortArrow"
                    src={
                         order.col === "workOrderNo"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                                   alt ="sortArrow"
                    /></td>
                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "productionNo",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "productionNo",
                        state: order.state,
                      });
                    }}>
                      Production No
                      <img
                    className="sortArrow"
                    src={
                         order.col === "productionNo"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                                   alt ="sortArrow"
                    />
                    </td>
                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>
                      So No<img
                    className="sortArrow"
                    src={
                         order.col === "soNo"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                                   alt ="sortArrow"
                    />
                    </td>
                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "itemName",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "itemName",
                        state: order.state,
                      });
                    }}>
                      Item Name<img
                    className="sortArrow"
                    src={
                         order.col === "itemName"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                                   alt ="sortArrow"
                    />
                    </td>
                    <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "workOrderStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data ,
                        col: "workOrderStatus",
                        state: order.state,
                      });
                    }}>
                      Work Order status
                      <img
                    className="sortArrow"
                    src={
                         order.col === "workOrderStatus"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                      alt ="sortArrow"
                    />
                    </td>
              </>
            )
          }
    const renderWorkOrderList=()=>{
        return workOrderCurrentPage.map((row,i)=>( 
        <React.Fragment key={i}>
          <tr className="vendorList"  onClick={(e)=>{e.stopPropagation();
          editItemCollapse(i)}}>
              <td className="vendorListInfo stickyFirstColumn">{row.workOrderNo}</td>
              <td className="vendorListInfo">{row.productionNo}</td>
              <td className="vendorListInfo">{row.soNo || "Make To Stock"}</td>
              <td className="vendorListInfo">{row.itemName}</td>
              <td className="vendorListInfo" style={{color:  row.workOrderStatus == 'In Progress' ? '#ff9800' : '#43a047'}}>
                {row.workOrderStatus.toUpperCase()}</td>
              <td className="vendorListInfoActions">  
              <img
                  alt="Edit Record"
                  className="vendorActionImages"
                  src={
                    access === "Edit" || access === "App/Dec"
                      ? editIcon
                      : editIconDisabled
                  }
                  title={
                    access === "Edit" || access === "App/Dec"
                      ? "Edit Production Request"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "Edit" || access === "App/Dec") {
                      editWorkOrderId(row.workOrderId);
                      setSection("editWorkOrder");
                    }
                  }}
                  style={
                    access === "Edit" || access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />
              <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={row.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse(i);
                  }}
                /></td>
          </tr>
        </React.Fragment>
      )
      );
    }

          return(
            <React.Fragment>
              <div className="vendorListArea">
                <table className="vendorListTable">
                  <thead>
                    <tr className="vendorListHeader"  style={{zIndex:1}}>
                    <CommonColumns data={workOrderCurrentPage}/>
                    <td className="vendorListHeadingActions">Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {workOrderCurrentPage.length > 0 ? renderWorkOrderList() : null}
                  </tbody>
                </table>
              </div>
              {workOrderList.length == 0 ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
                <Pagination 
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={workOrderList.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
            </React.Fragment>
          )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    editWorkOrderId: (workOrderId)=>dispatch(editWorkOrderId(workOrderId)),
    viewWorkOrderId: (workOrderId)=>dispatch(viewWorkOrderId(workOrderId))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(WorkOrderList)