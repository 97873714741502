export const DOMAIN = window.location.hostname.split('https://')[0];

export const getServerEndPoint=()=>{
   
    return DOMAIN==='localhost'? 'http://localhost:5000/'
    : `https://${DOMAIN}/api/`;
    
}

 export const serverEndpoint = getServerEndPoint();
