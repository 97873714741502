import React, { useState } from "react";
import MenuList from "../Menu/MenuList";

import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
// import CreatePurchaseOrder from "./CreatePurchaseOrder";
import importPoIcon from "../../assets/importPo.svg";
import CreateImportPo from "./CreateImportPo";
import ImportPoList from "./ImportPoList";
import ImportPoDetail from "./ImportPoDetail";
import EditImportPo from "./EditImportPo";
// import PurchaseOrderList from "./PurchaseOrderList";
// import PurchaseOrderDetail from "./PurchaseOrderDetail";
// import EditPurchaseOrder from "./EditPurchaseOrder";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const ImportPo = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("importPoList");

    const [approvalPage, setApprovalPage] = useState(false);

    const [editAndApprove, setEditAndApprove] = useState(false);

    var createPurchaseOrderButton = [
        { buttonName: "Create Import Po", className: "employeeButton", setSection: setCurrentSection, sectionName: "createImportPo" },
    ];

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "importPoList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "importPODetail") }, sectionName: "importPoList" };

    const renderSection = (section) => {
        switch (section) {
            case "createImportPo":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={importPoIcon} pageTitle="Create Import Po" buttonList={[goBackButton]} />
                        <CreateImportPo setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "importPoList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={importPoIcon} pageTitle="Import PO List"
                            buttonList={isLogged.access["Import PO"] === "Create" || isLogged.access["Import PO"] === "Edit" ?
                                createPurchaseOrderButton : null} />
                        <ImportPoList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={(value) => setApprovalPage(value)}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "importPoDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={importPoIcon} pageTitle="Import Po Detail" buttonList={[goBackButton, downloadButton]} />
                        <ImportPoDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editPurchaseOrder":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={importPoIcon} pageTitle="Edit Import Po" buttonList={[goBackButton]} />
                        <EditImportPo setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );

            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Import" selectedSubMenu="Import PO" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged }
}
export default connect(mapStateToProps, null)(ImportPo);
