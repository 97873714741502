import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import fetchData from "../../serverCall/fetchData";

async function convertHTMLToImageUrl() {
  const tableDivElement = document.getElementsByClassName('detailsPageContainer')[0];
  if (tableDivElement) {
    const canvas = await html2canvas(tableDivElement);
    return canvas.toDataURL('image/png');
  }
  return '';
}

export async function sendPDFMail(isLogged, reportName, params = {}) {
  const pdfName = reportsMap[reportName].filename + '.pdf';
  const imgPos = { left: 15, top: 15 };
  const imgFormat = 'JPEG';
  const options = {
    orientation: '0',
    unit: 'mm',
    format: 'a4'
  };
  const doc = new jsPDF(options);
  doc.setFont('Helvetica', '');
  doc.setFontSize(8);
  const image = new Image();
  image.src = await convertHTMLToImageUrl();
  doc.addImage(image, imgFormat, imgPos.left, imgPos.top, 180, 190);
  const blob = doc.output('blob');

  var formData = new FormData();
  formData.append('fileName', pdfName);
  formData.append('pdf', blob);
  formData.append('fromAddress', params["from"]);
  formData.append('toAddress', params["to"]);
  if (params["cc"]) {
    formData.append('ccAddress', params["cc"]);
  }
  formData.append('subject', params["sub"]);
  formData.append('content', params["content"]);

  // Send to Server
  var data = await fetchData({
    requestingPage: "location",
    method: 'POST',
    url: 'mail/sendmail',
    data: formData,
    headers: { token: isLogged.accessToken, module },
  });
  if (data.msg === "success") {
    return { status: "Success", msg: "Email sent successfully" };
  } else {
    return { status: "Error", msg: data.detail.response };
  }
}

export const reportsMap = {
  "employeeDetails": {
    module: "Employee Details",
    filename: "EmployeeDetails"
  },
  "soDetails": {
    module: "Sales Order",
    filename: "SaleOrderDetail"
  },
  "srDetails": {
    module: "Sales Return",
    filename: "SaleReturnDetail"
  },
  "salesInvoice": {
    module: "Sale Invoice",
    filename: "SaleOrderInvoice"
  },
  "poDetails": {
    module: "Purchase Order",
    filename: "PurchaseOrderDetail"
  },
  "importPODetail": {
    module: "Import Purchase Detail",
    filename: "ImportPurchaseOrderDetail"
  },
  "purchaseImportDetail": {
    module: "Purchase Import Detail",
    filename: "PurchaseImportDetail"
  },
  "prDetails": {
    module: "Purchase Return",
    filename: "PurchaseReturnDetail"
  },
  "purchaseInvoice": {
    module: "Purchase Invoice",
    filename: "PurchaseOrderInvoice"
  },
  "opdDetail": {
    module: "OPD Detail",
    filename: "OPDDetail"
  },
  "inwardDetail": {
    module: "Inward Detail",
    filename: "InwardDetail"
  },
  "outwardDetail": {
    module: "Outward Detail",
    filename: "OutwardDetail"
  },
  "qcDetail": {
    module: "QC Detail",
    filename: "QCDetail"
  },
  "soPendingDetail": {
    module: "SO Pending Detail",
    filename: "SOPendingDetail"
  },
  "mrsPendingDetail": {
    module: "MRS Pending Detail",
    filename: "MRSPendingDetail"
  },
  "stockTransferDetail": {
    module: "Stock Transfer Detail",
    filename: "StockTransferDetail"
  },
  "productionOrderDetail": {
    module: "Production Order Detail",
    filename: "ProductionOrderDetail"
  },
  "materialRequisitionDetail": {
    module: "Material Requisition Detail",
    filename: "MaterialRequisitionDetail"
  }
}
