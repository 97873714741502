import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import purchaseImportIcon from "../../assets/purchaseImport.svg";
import CreatePurchaseImport from "./CreatePurchaseImport";
import PurchaseImportList from "./PurchaseImportList";
import PurchaseImportDetail from "./PurchaseImportDetail";
import EditPurchaseImport from "./EditPurchaseImport";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const PurchaseImport = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("purchaseImportList");
    const [approvalPage,setApprovalPage] = useState(false);
    
    var createPurchaseImportButton = [
        { buttonName: "Create Purchase Import", className: "employeeButton", setSection: setCurrentSection, sectionName: "createPurchaseImport" },
        // { buttonName: "Edit Purchase Import", className: "employeeButton", setSection: setCurrentSection, sectionName: "editPurchaseImport" },
    ];

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "purchaseImportList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "purchaseImportDetail") }, sectionName: "purchaseImportList" };

    const renderSection = (section) => {
        switch (section) {
            case "createPurchaseImport":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseImportIcon} pageTitle="Create Purchase Import" buttonList={[goBackButton]} />
                        <CreatePurchaseImport setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editPurchaseImport":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseImportIcon} pageTitle="Edit Purchase Import" buttonList={[goBackButton]} />
                        <EditPurchaseImport setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "purchaseImportList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseImportIcon} pageTitle="Purchase Import List"
                            buttonList={["Create","Edit","App/Dec"].includes(isLogged.access["Purchase Import"]) ?
                                createPurchaseImportButton : null} />
                        <PurchaseImportList setSection={(value) => setCurrentSection(value)} 
                                setApprovalPage={setApprovalPage}/>
                    </React.Fragment>
                );

            case "purchaseImportDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseImportIcon} pageTitle="Purchase Import Detail" buttonList={[goBackButton, downloadButton]} />
                        <PurchaseImportDetail setSection={(value) => setCurrentSection(value)} approvalPage={approvalPage}/>
                    </React.Fragment>
                );

            default:
                return <div>Page under constrction</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Import" selectedSubMenu="Purchase Import" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged }
}

export default connect(mapStateToProps, null)(PurchaseImport);
