import React from "react";
import Select from "react-select";

const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "chocolate2", label: "Chocolate2" },
    { value: "strawberry2", label: "Strawberry2" },
    { value: "vanilla2", label: "Vanilla2" },
    { value: "chocolate3", label: "Chocolate3" },
    { value: "strawberry3", label: "Strawberry3" },
    { value: "vanilla3", label: "Vanilla3" },
    { value: "chocolate4", label: "Chocolate4" },
    { value: "strawberry4", label: "Strawberry4" },
    { value: "vanilla4", label: "Vanilla4" },
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "black" : "gray",
        backgroundColor: state.isSelected ? "lightgreen" : "white",
        padding: "5px 20px",
    }),
    control: () => ({
        width: "100%",
        borderRadius: "5px",
        border: "1px solid gray",
        boxSizing: "border-box",
        display: "flex",
    }),
    singleValue: (provided, state) => {
        // console.log(state);
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    },
    indicatorSeparator: () => {
        return null;
    },
};

const SearchSelect = () => {
    // console.log(options[4]);
    return (
        <div style={{ width: "20rem", margin: "2rem", height: "10rem" }}>
            <Select options={options} styles={customStyles} onChange={(value) => console.log(value)} defaultValue={options[4]} />
        </div>
    );
};

export default SearchSelect;
