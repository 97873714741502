import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import transportIcon from "../../assets/transport.svg";
import PageTitle from "../SmallComponents/PageTitle";
import "../styles/uom.css";
import TopBanner from "../SmallComponents/TopBanner";
import { connect } from "react-redux";
import TransporterList from "./TransporterList";
import CreateTransporter from "./CreateTransporter";
import EditTransporter from "./EditTransporter";

const Transport = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("transportList");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "transportList" }];
    var createTransportButton = {
        buttonName: "Create Transporter",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createTransporter",
    };

    const renderSection = (section) => {
        switch (section) {
            case "transportList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={transportIcon}
                            pageTitle="Transporters List"
                            buttonList={isLogged.access["Transporter"] === "Create" || isLogged.access["Transporter"] === "Edit" ? [createTransportButton] : null}
                            // buttonList={[createTransportButton]}
                        />
                        <TransporterList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createTransporter":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={transportIcon} pageTitle="Create Transporter" buttonList={goBackButton} />
                        <CreateTransporter setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editTransporter":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={transportIcon} pageTitle="Edit Transporter" buttonList={goBackButton} />
                        <EditTransporter setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page yet to be created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Transporter" />

                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(Transport);
