import React, { useState } from "react";
import TopBanner from "../SmallComponents/TopBanner";

import profile from "../../assets/ProfilePlaceholder2.svg";
import del from "../../assets/delete2.svg";
import dashboard from "../../assets/dashboard.svg";
import masterIcon from "../../assets/master.svg";
import purchaseIcon from "../../assets/purchase.svg";
import salesIcon from "../../assets/sales.svg";
import importIcon from "../../assets/import.svg";
import storeIcon2 from "../../assets/store.svg";

import roleIcon from "../../assets/role2.svg";
import uomIcon from "../../assets/uom2.svg";
import storeIcon from "../../assets/store2.svg";
import itemMenuIcon from "../../assets/item_menu.svg";
import employeeIcon from "../../assets/employee.svg";
import vendorIcon from "../../assets/vendor.svg";
import customerIcon from "../../assets/customer.svg";
import transportIcon from "../../assets/transport.svg";
import clearingIcon from "../../assets/clearing.svg";

import rightArrowIcon from "../../assets/right-arrow.svg";
import leftArrowIcon from "../../assets/left-arrow.svg";

const MenuList2 = ({ collapse, setCollapse }) => {
    const menuItemsList = [
        { menu: "Dashboard", 
          subMenu: [
            { name: "Admin Dashboard", image: dashboard, route: "dashboard" }
        ],
          enable: true,
          image: dashboard },
        {
            menu: "Master Configure",
            subMenu: [
                { name: "Role", image: roleIcon, route: "role" },
                { name: "UOM", image: uomIcon, route: "uom" },
                { name: "Plant & Store", image: storeIcon, route: "plant" },
                { name: "Item Menu", image: itemMenuIcon, route: "itemmenu" },
                { name: "Employee", image: employeeIcon, route: "employee" },
                { name: "Vendor", image: vendorIcon, route: "vendor" },
                { name: "Customer", image: customerIcon, route: "customer" },
                { name: "Transporter", image: transportIcon, route: "transporter" },
                { name: "Clearing Agent", image: clearingIcon, route: "clearing" },
            ],
            enable: true,
            image: masterIcon,
        },
        {
            menu: "Purchase",
            subMenu: [
                { name: "Purchase Order", image: purchaseIcon, route: "purchaseorder" },

                // { name: "Invoice", image: customerIcon },
                // { name: "Purchase Dashboard", image: customerIcon },
                // { name: "Purchase Import", image: purchaseIcon },
            ],
            enable: true,
            image: purchaseIcon,
        },
        {
            menu: "Store",
            subMenu: [
                { name: "Inventory", image: storeIcon2, route: "store" },
                { name: "OPD", image: storeIcon2, route: "opd" },
                { name: "Inward", image: storeIcon2, route: "inward" },
                { name: "Outward", image: storeIcon2, route: "outward" },
                { name: "Quality Checking", image: storeIcon2, route: "quality" },
                { name: "Stock Transfer", image: storeIcon2, route: "transfer" },
            ],
            enable: true,
            image: storeIcon2,
        },
        {
            menu: "Import",
            subMenu: [
                { name: "Import PO", image: importIcon, route: "importpo" },
                { name: "Purchase Import", image: importIcon, route: "purchaseimport" },
            ],
            enable: true,
            image: importIcon,
        },
        {
            menu: "Sales",
            subMenu: [
                { name: "Sales Order", image: salesIcon, route: "salesorder" },
                { name: "Invoice", image: salesIcon, route: "salesinvoice" },
            ],
            enable: true,
            image: salesIcon,
        },
        {
            menu: "Report",
            subMenu: [
                { name: "Sales", image: salesIcon, route: "salesreport" },
                { name: "Purchase", image: purchaseIcon, route: "dashboard" },
                { name: "Inventory", image: salesIcon, route: "dashboard" },
                { name: "Production", image: salesIcon, route: "dashboard" },
            ],
            enable: true,
            image: dashboard,
        },
    ];

    const [selectedMenu, setSelectedMenu] = useState("Master Configure");
    const [selectedSubMenu, setSelectedSubMenu] = useState("Role");

    const renderMenu = () => {
        return menuItemsList.map((menu, menuIndex) => {
            return (
                <div className={menu.menu === selectedMenu ? "indiMenuSelected" : "indiMenu"} onClick={() => setSelectedMenu(menu.menu)}>
                    <img className="indiMenuImage" src={menu.image} alt="Menu" title={menu.menu} />
                    {menu.menu === selectedMenu ? (
                        <React.Fragment>
                            {menuItemsList[menuIndex].subMenu.map((subMenu) => {
                                return (
                                    <div
                                        className={subMenu.name === selectedSubMenu ? "indiMenu2Selected" : "indiMenu2"}
                                        onClick={() => setSelectedSubMenu(subMenu.name)}
                                    >
                                        <img
                                            className={subMenu.name === selectedSubMenu ? "indiMenuImage2selected" : "indiMenuImage2"}
                                            src={subMenu.image}
                                            alt="Sub Menu"
                                            title={subMenu.name}
                                        />
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ) : null}{" "}
                </div>
            );
        });
    };

    return (
        <React.Fragment>
            {/* <TopBanner /> */}
            <div className="menu2Container">
                <div className="menuItems">
                    <div
                        className="menuItemGroup"
                        style={{ height: "3rem", boxSizing: "border-box", border: "none", boxShadow: "0 0 5px 5px lightgray" }}
                    >
                        <div className="menuTitle">
                            <img
                                className="menuImage"
                                src={collapse ? rightArrowIcon : leftArrowIcon}
                                title={collapse ? "Expand" : "Collapse"}
                                onClick={() => setCollapse(!collapse)}
                                alt ="menuImage"
                            />
                        </div>
                    </div>
                    {renderMenu()}
                </div>
            </div>
        </React.Fragment>
    );
};

export default MenuList2;
