import React from "react";
import InputField from "./InputField";
import Modal from "react-modal";

const DeclineModal = ({ openDeclineModal, declineOkClick, declineModalText, setDeclineReason, declineReason, error, cancelDecline }) => {
    // console.log(openDeclineModal, declineOkClick, declineModalText, setDeclineReason, declineReason, error);
    return (
        <Modal
            isOpen={openDeclineModal}
            contentLabel="Example Modal"
            style={{
                overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                content: {
                    textAlign: "center",
                    width: "30%",
                    height: "30%",
                    position: "fixed",

                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",

                    // borderRadius: "5px",
                    boxShadow: "0 0 5px gray",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                },
            }}
        >
            <div style={{ lineHeight: "2rem", fontSize: "20px", marginBottom: "1rem" }}>{declineModalText}</div>

            <textarea
                className={error ? "poDeclineMultilineInputError" : "poDeclineMultilineInput"}
                value={declineReason}
                rows="4"
                cols="30"
                name="text"
                placeholder="Decline Reason"
                style={{
                    padding: "10px",
                    resize: "none",
                    margin: "10px 0 1rem 0",
                    fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                    setDeclineReason(e.target.value);
                }}
            />
            {error ? <span style={{ color: "red", marginBottom: "1rem" }}>Provide reason to decline PO</span> : null}

            <div>
                <button className="cancelButton" onClick={cancelDecline}>
                    Cancel
                </button>
                <span style={{ margin: "0 1rem" }}></span>

                <button className="declineButton" onClick={declineOkClick}>
                    Decline
                </button>
            </div>
        </Modal>
    );
};

export default DeclineModal;
