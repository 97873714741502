import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import DeclineModal from "../SmallComponents/DeclineModal";
import Modal from "react-modal";
import { soEditInvoiceId, soInvoiceCreateId } from "../../redux/PurchaseOrder/PoInfoActions";
import {numToWords,getIndianCurrency} from '../CommonFunctions/utils';

const InvoiceDetail = ({ setSection, approval, setEditAndApprove, addTokenToState, isLogged, poInfo, soInvoiceCreate,setDownloadReportParams, soEditInvoice }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving Invoice. Please wait...");

    const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
    const declineModalText = "Provide a reason for declining Invoice";
    const [declineReason, setDeclineReason] = useState("");
    const [declineReasonError, setDeclineReasonError] = useState(false);
    const [deliveryAddressDetail,setDeliveryAddressDetail] = useState({});

    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [bankDetailsList, setBankDetailsList] = useState([]);
    const [lineItemChunk,setLinItemChunk] =useState([]);
    const [igst,setIgst]=useState(false);
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getInvoiceDetail();
    }

    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getInvoiceDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "so-fetch/sales-invoice-detail/" + poInfo.soViewInvoiceId,
            headers: { token: isLogged.accessToken, module: "Sales Invoice" },
        });
        if (data.msg === "success") {
            //delivery address
            let deliveryAddressCopy ={};
            if(data.asset[0].deliveryAddressId){
                deliveryAddressCopy = data.asset[0].deliveryAddressOptions.filter((op)=>op.optionId === data.asset[0].deliveryAddressId)
                deliveryAddressCopy = {...deliveryAddressCopy[0]}
                deliveryAddressCopy.customerName = deliveryAddressCopy.companyName
            }else{
                deliveryAddressCopy = {...data.asset[0]};
            }

            setDeliveryAddressDetail(deliveryAddressCopy);

            data.asset[0].totalAmount = data.asset[0].totalAmount.toString() 
            let alteredLineItems=data.asset[0].lineItems.map((row)=>{
                                        row.CGST = row.CGST==null ? (row.tax)/2 : row.CGST;
                                        row.SGST = row.SGST==null ? (row.tax)/2 : row.SGST;
                                        row.GST = Number(row.CGST)+Number(row.SGST);
                                        row["quantityuom"] = row["quantity"]+" "+row["uom"];
                                        if (row["GST"] > 0) {
                                          row["amount"] = (row["netPrice"] * 100) / (100 + row["GST"]);
                                        } else {
                                          row["amount"] = row["netPrice"];
                                        }
                                        row.netPrice = parseFloat(row.netPrice);
                                        row.CGSTAmount = ((row["amount"]) * (row.CGST) / 100);
                                        row.SGSTAmount = ((row["amount"]) * (row.SGST) / 100);
                                        row.taxAmount = (row.CGSTAmount + row.SGSTAmount);
                                        row.IGSTAmount = (row.CGSTAmount + row.SGSTAmount);
                                        return row;});
            data.asset[0]["CGSTAmount"] = Number(alteredLineItems.reduce((prev,curr)=>prev+curr["CGSTAmount"],0)).toFixed(2)
            data.asset[0]["SGSTAmount"] = Number(alteredLineItems.reduce((prev,curr)=>prev+curr["SGSTAmount"],0)).toFixed(2)
            data.asset[0]["GSTAmount"] = (parseFloat(data.asset[0]["CGSTAmount"]) + parseFloat(data.asset[0]["SGSTAmount"])).toFixed(2)
                setDownloadReportParams({dealerName: data.asset[0].customerName, orderNo: data.asset[0].invoiceNo})
            let lineChunk=[];
            for(let i=0;i< alteredLineItems.length; i+=16) // 16 items in each page
                lineChunk.push(alteredLineItems.slice(i,i+16));
                            setLinItemChunk(lineChunk);
                setInvoiceDetail(data.asset[0]);
            setBankDetailsList(data.asset[0].bankDetails);
            if(data.asset[0].plantState!==data.asset[0].customerState){
                data.asset[0]["IGSTAmount"] =data.asset[0]["GSTAmount"]
                setIgst(true);
            }
        } else {
            console.log(data);
        }
    }


    const [lrDocument, setLrDocument] = useState("");
    const [outwardDocument, setOutwardDocument] = useState("");

    useEffect(() => {
        if(invoiceDetail.invoiceId)
        getLRDocument();
        // eslint-disable-next-line
    }, [invoiceDetail]);

    async function getLRDocument() {

        if (invoiceDetail?.lrDocument !== null) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",
                url: "image/sales-invoice-lr-document/" + invoiceDetail.invoiceId,
                headers: { token: isLogged.accessToken, module: "Sales Invoice" },
            });

            if (data !== "") {
                setLrDocument(data);
            }
        }
    }

    useEffect(() => {
            if(invoiceDetail.invoiceId)
        getOutwardDocument();
        // eslint-disable-next-line
    }, [invoiceDetail]);

    async function getOutwardDocument() {

        if (invoiceDetail?.outwardDocument !== null) {
            var data = await fetchData({
                requestingPage: "itemImage",
                method: "get",

                url: "image/sales-invoice-outward-document/" + invoiceDetail.invoiceId,
                headers: { token: isLogged.accessToken, module: "Sales Invoice" },
            });
            if (data !== "") {
                setOutwardDocument(data);
            }
        }
    }

    const declineOkClick = () => {
        if (declineReason.trim().length > 0) {
            setDeclineReasonError(false);
            setDeclineModalIsOpen(false);

            setIsOpen(true);
            setModalText("Declining SO. Please Wait...");
            declineInvoice();
        } else {
            setDeclineReasonError(true);
        }
    };

    const submitOkClick = () => {
        setIsOpen(false);

        setSection("salesInvoiceList");
        // }
        setModalText("Approving SO. Please wait...");
    };

    const renderSummaryInfo = () => {
        if (invoiceDetail !== null) {
            return (
                <React.Fragment>
                    <table  className='innerTable' style={{width:"100%",height:"100%"}}>
                    <tbody>
                        <tr>
                            <td >
                                <span className="vendorSummaryValue" style={{ textTransform: "capitalize" }}>
                            Invoice No:</span><br></br>
                                <span className="vendorSummaryCategory">
                                        {invoiceDetail.invoiceNo}&nbsp;
                                </span>
                            </td>
                            <td >
                                <span className="vendorSummaryValue">Invoice Date : </span><br></br>
                                <span className="vendorSummaryCategory">{invoiceDetail.invoiceDate}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Plant Name</span><br/>
                                <span className="vendorSummaryCategory">{invoiceDetail.plantName}</span>
                            </td>
                            <td>
                                <span className="vendorSummaryValue">Credit Days</span><br />
                                <span className="vendorSummaryCategory">{invoiceDetail.customerCreditDays}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Storage Name</span><br></br>
                                <span className="vendorSummaryCategory">{invoiceDetail.soStorageName}</span>
                            </td>
                            <td>
                                <span className="vendorSummaryValue">Expected Delivery Date</span><br />
                                <span className="vendorSummaryCategory">{invoiceDetail.soExpectedDeliveryDate}</span>
                            </td>
                        </tr>  
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Transporter Name </span><br></br>
                                <span className="vendorSummaryCategory">{invoiceDetail.transporterName}</span>
                                <br></br>&nbsp;
                            </td>
                            <td>
                                <span className="vendorSummaryValue">Payment Mode</span><br />
                                <span className="vendorSummaryCategory">{invoiceDetail.paymentMode}</span>
                                <br></br>&nbsp;
                            </td>
                        </tr>                       
                        <tr>
                            <td>
                                <span className="vendorSummaryValue">Dispatched Through</span><br/>
                                <span className="vendorSummaryCategory">{invoiceDetail.dispatchedThrough}</span>
                                <br></br>&nbsp;
                            </td>
                            <td>
                                <span className="vendorSummaryValue">Destination</span><br/>
                                <span className="vendorSummaryCategory">{invoiceDetail.destination}</span>
                                <br></br>&nbsp;
                            </td>
                        </tr>   

                    </tbody>
                    </table>
                </React.Fragment>
            );
        }
    };

    const lineItemParams = isLogged.showHideWeight ? ["itemName", "from", "to", "netWeight", "grossWeight", "hsnCode","GST", "quantityuom", "unitPrice", "uom","amount"] :
["itemName","hsnCode","GST","quantityuom", "unitPrice","uom", "amount"];
 
const RenderTaxRow=(colName)=>{

    return(
        <tr className="invoiceDetailContactsTableRows" key={colName}>
                        <td  style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>
                        {lineItemParams.map((key, i) => {
                            switch(key){
                                case "itemName":
                                    return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}><b><i>{colName}</i></b></td>
                                
                                case "amount":
                                    if(colName=="Less RoundedOff")
                                    {    let roundedOff= invoiceDetail.totalAmount?.split('.')?.[1];
                                        roundedOff = roundedOff || '00'
                                        roundedOff = roundedOff?.toString()?.length > 1 ? roundedOff : roundedOff+'0'
                                    return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}>{'₹ 00.'+(roundedOff)}</td>
                                    }else if(colName=='Less Discount')
                                    {
                                    return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}>{invoiceDetail['finalDiscount']}</td>
                                    }
                                    else
                                    return <td key={i} style={{borderBottom:"none",borderTop:"none",textAlign:"right"}}>{getIndianCurrency(invoiceDetail[colName])}</td>
                                
                                default:
                                return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                            }
                            })}
                    </tr>
    )
}


const RenderBankDetails = () => {
    return bankDetailsList.map((bank,ind) => {
     return (
       <div key={ind}>
           <div className="small-font vendorSummaryValue">
           {`A/c Holder Name`} &emsp;{`: ${bank?.accountHolderName}`}
           </div>
           <div className="small-font vendorSummaryValue">
               {`Bank Name`} &emsp;&emsp;&emsp;&emsp;{`: ${bank?.bankName}`}
           </div>
           <div className="small-font vendorSummaryValue">
               {`A/c No.`} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{`: ${bank?.accountNumber}`}
           </div>
           <div className="small-font vendorSummaryValue" style={{color: "#000", textTransform: "capitalize"}}>
               {`Branch & IFS Code `} &ensp;{`: ${bank?.branch}`}&ensp;&amp;&ensp;{`${bank?.ifscCode}`}
           </div><br></br>
       </div>
     );
   });
 };
const mainItemsTaxRow=igst ? ["IGSTAmount","Less RoundedOff"]  :["CGSTAmount","SGSTAmount","Less RoundedOff"]
 if(invoiceDetail.finalDiscount)
  mainItemsTaxRow.push("Less Discount");
const RenderTable = ({currentPageLineItems,pageNumber}) => {
        return (
            <>
            <table className="innerTable" style={{width:"100%",height:"90%"}}>
                <thead>
                    <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                        <td>S.No.</td>
                        <td>Description of Goods</td>
                        {isLogged.showHideWeight ? (
                    <>
                      <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td>
                    </>
                  ) : null}
                        <td>HSN/SAC No</td>
                        <td>GST (%)</td>
                        <td>Quantity</td>
                        <td>Unit Price</td>
                        <td>Per</td>
                        <td>Amount</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows  currentPageLineItems={currentPageLineItems} pageNumber={pageNumber}  tab="contact" />
                    
                  { pageNumber==lineItemChunk.length ? 
                    <>
                    
                    {mainItemsTaxRow.map((tax)=>RenderTaxRow(tax))}
                   <RenderEmptyRows columns={lineItemParams} emptyRows={6}/>
                            <tr>
                                <td colSpan={lineItemParams.length}>
                                    <div className="small-font">Other Charges</div>
                                    <div className="small-font">Transport Charges </div>
                                 </td>
                                <td>
                                        <div className="medium-font  align-right">{getIndianCurrency(invoiceDetail.otherCharges)}</div>
                                        <div className="medium-font  align-right">{getIndianCurrency(invoiceDetail.transportCharges)}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={lineItemParams.length}> 
                                        <b>Total Amount</b>
                                </td>
                                <td className="align-right">
                                    <div className="poNetTotalAmount align-right" style={{color: "#000"}}><b>{getIndianCurrency(Math.round(invoiceDetail["totalAmount"]))}</b></div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={lineItemParams.length+1}>    
                                    <div className="medium-font">Amount Chargeable(in Words):&emsp; <b>INR&ensp; </b>{amountInWords(invoiceDetail.totalAmount?.split('.')[0])}</div>
                                </td>
                        </tr> 
                        </>
                        : <tr className="invoiceDetailContactsTableRows">
                            <td style={{textAlign:"right"}}colSpan={lineItemParams.length + 1}>continued to Page Number {pageNumber+1}</td>
                          </tr>}
                </tbody>
            </table>
            <div style={{width: "100%",display:"flex",justifyContent:"space-between",marginTop:"10px"}}>
                            <div>
                                    <span className="small-font vendorSummaryRole" style={{color: "#000"}}><b><u>Declaration:</u></b></span>
                                    <div className="medium-font poSummaryValue" style={{maxWidth:"400px",wordBreak:"break-word"}}>We Declare that this invoice shows the actual price of goods described and that all particulars are true and correct</div>
                            </div>
                            <div style={{fontSize:"12px",height:"200px",width:"300px"}}>
                            <span className="small-font vendorSummaryRole" style={{color: "#000"}}><b>Bank Details</b></span>
                            {RenderBankDetails()}
                            </div>
                    </div>
            {pageNumber==lineItemChunk.length ? 
            <>
                    <div  style={{width:"100%",position:"absolute",bottom:"15px"}}>
                            <span className="medium-font vendorSummaryRole" style={{width:"50%",color: "#000", textAlign: "left", marginBottom: "10px"}}>Customer's Seal and Signature</span>
                            <span style={{width: "50%"}}>
                                <div className="medium-font poSummaryValue" style={{color: "#000", textAlign: "right", marginTop: "5px", marginRight: "5px"}}><b>Authorized Signatory</b></div>
                            </span>
                    </div>
                    </> : null}
            </>
        );
    };
    const taxTableParams= igst ?["hsnCode","amount", "GST","IGSTAmount","taxAmount"]: ["hsnCode","amount","CGST","CGSTAmount", "SGST","SGSTAmount","taxAmount"]
    const RenderTaxTable=({rows,pageNumber})=>{
        return (
         <table className="innerTable" style={{width:"100%", color: "#000"}}>
                        <thead>
                            <tr className="invoiceDetailContactsTableHeader" style={{color:"#000"}}>
                                <td rowSpan="2" style={{position: "relative"}}>S.No.</td>
                                <td rowSpan="2" style={{position: "relative"}}>HSN/SAC NO</td>
                                <td rowSpan="2" style={{position: "relative"}}>Taxable Value</td>
                                {igst ? null :<td colSpan={2} >Central Tax</td>}
                                <td colSpan={2}>{igst ? "IGST":"State Tax"}</td>
                                <td rowSpan="2" style={{position: "relative"}}>Total Tax Amount</td></tr>
                            <tr className="invoiceDetailContactsTableHeader" style={{color:"#000"}}>
                                {igst ? null : <><td>Rate(%)</td><td>Amount</td></>}
                                <td >Rate(%)</td>
                                <td >Amount</td></tr>
                        </thead>
                        <tbody> 
                {rows.map((row,i)=>{
                return(
                    <tr className="invoiceDetailContactsTableRows" key={i}>
                        <td style={{borderBottom:"none",borderTop:"none",textAlign:"align-right"}}>{((pageNumber-1)*16)+i+1}</td>
                        { 
                        taxTableParams.map((column,ind)=>{
                            if (column === "amount" || column === "CGSTAmount" || column ==='IGSTAmount'
                            || column === "taxAmount" || column === "SGSTAmount" ) {
                                return (<td className="align-right" style={{borderBottom:"none",borderTop:"none"}} key={ind}>{getIndianCurrency(row[column])}</td>);
                            } else {
                                return (<td className="align-left" style={{borderBottom:"none",borderTop:"none"}} key={ind}>{row[column]}</td>);
                            }
                        })
                        }
                    </tr>
                );
            })}
            <RenderEmptyRows columns={taxTableParams} emptyRows={7}/>
            {pageNumber === lineItemChunk.length ?
            <>
            <tr className="invoiceDetailContactsTableRows">
                <td>&nbsp;</td><td className="align-right">Total</td><td className="align-right">₹{(invoiceDetail["totalAmount"] -invoiceDetail["GSTAmount"]).toFixed(2)}</td>
                {igst ? null :<><td>&nbsp;</td><td className="align-right">₹{invoiceDetail["CGSTAmount"]}</td></>}
                <td>&nbsp;</td><td className="align-right">₹{igst ?invoiceDetail["IGSTAmount"] :invoiceDetail["SGSTAmount"]}</td>
                <td className="align-right">₹{invoiceDetail["GSTAmount"]}</td>
            </tr>
            <tr className="invoiceDetailContactsTableRows">
                <td colSpan={8}>
                        <div className="medium-font">Taxed Amount(in Words): 
                        <b>&emsp;INR&ensp; </b>{amountInWords(invoiceDetail["GSTAmount"]?.toString())}</div>
                </td>
            </tr>
            </> : 
            <tr className="invoiceDetailContactsTableRows">
               <td colSpan={8} className="align-right">continued to Page Number {pageNumber+1}</td> 
                </tr>}
                </tbody>
         </table>
            );
    }
    
    const RenderEmptyRows=({columns, emptyRows})=>{
        return(
             Array.from({length: emptyRows}).map((empty,ind)=>(
                <tr className="invoiceDetailContactsTableRows" key={ind}>
                    <td  style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>
                    {columns.map((key, i) => {
                        return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                    })} 
                </tr>)
                )
        )
    }

    const RenderTableRows = ({ currentPageLineItems,pageNumber }) => {
        if(currentPageLineItems?.length>0){
        return (<>
            {currentPageLineItems.map((row, j) => {
                return (
                    <tr className="invoiceDetailContactsTableRows" key={j}>
                        <td  style={{borderBottom: "none", borderTop: "none"}}>{((pageNumber-1)*16)+j+1}</td>
                        {lineItemParams.map((key, i) => {
                            if (key === 'amount' || key==="unitPrice") {
                                return <td  style={{borderBottom: "none", borderTop: "none"}} className="align-right" key={i}>{row[key] !== null ? getIndianCurrency(row[key]) : "-"}</td>;
                            } else {
                                return <td  style={{borderBottom: "none", borderTop: "none"}} key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                            }
                        })}
                    </tr>
                );
            })}
            <tr className="invoiceDetailContactsTableRows">
                        {lineItemParams.map((key, i) => {
                               return <td  key={i} style={{borderBottom: "none", borderTop: "none"}}>&nbsp;</td>;
                         })}
                         <td style={{textAlign:"right",borderBottom:"none"}}>{getIndianCurrency(currentPageLineItems?.reduce((prev,curr)=>prev+curr["amount"],0))}</td>
                    </tr>
            <RenderEmptyRows columns={lineItemParams} emptyRows={21-currentPageLineItems.length}/>
        </>);
    } else return(<></>)
    };

    async function approveInvoice() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `so-edit/sales-invoice-approve/${invoiceDetail.invoiceId}`,
            headers: { token: isLogged.accessToken, module: "Sales Invoice" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Invoice");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    async function declineInvoice() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "declinePo",
            method: "put",
            url: `so-edit/sales-invoice-decline/${invoiceDetail.invoiceId}`,
            headers: { token: isLogged.accessToken, module: "Sales Invoice" },
            data: { reason: declineReason },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Declined Invoice");
        } else {
            console.log(data);
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage[0];
        }
    };

    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="declineButton"
                        onClick={() => {
                            setDeclineModalIsOpen(true);
                        }}
                    >
                        Decline
                    </button>
                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editSalesInvoice");
                            // editSoId(poInfo.viewSoId);

                            soEditInvoice(invoiceDetail.invoiceId);
                            soInvoiceCreate(invoiceDetail.soId);
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            approveInvoice();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const [diagramImage, setDiagramImage] = useState("");
    const [diagramModalOpen, setDiagramModalOpen] = useState(false);

      const amountInWords = (amount) => {
        if (amount) {
          let result;
          amount = amount.toString().split(".");
          result =
            numToWords(parseInt(amount[0])) +
            (amount[1] > 0
              ? " and " + numToWords(parseInt(amount[1])) + " Paise Only"
              : " Only");
          return result;
        }
      };

      const RenderSeller=()=>{

        return(
            <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>  
            <div className="poSummaryText" style={{maxWidth:"max-content"}}>
                <span className="vendorSummaryCategory" style={{color: "#000", textTransform: "capitalize"}}>Seller</span>
                <span className="poSummaryValue">{`Company: ${invoiceDetail.parentPlantName || invoiceDetail.plantName}`}</span>
                <span className="poSummaryValue">{`Plant: ${invoiceDetail.plantName}`}</span>
                <span className="poSummaryValue">{`${invoiceDetail.plantCity}-${invoiceDetail.plantZipCode}, ${invoiceDetail.plantState}`}</span>
                <span className="poSummaryValue">{`GSTIN/UIN: ${invoiceDetail.plantGstNo}`}</span>
            </div>
        </div>
        )
      };

      const RenderConsigneeAddress=({context,addressDetail={}})=>{
        return(
            <div className="vendorSummary" style={{maxWidth:"max-content", margin: "0",display:"inline-flex",verticalAlign:"top"}}>
            <div className="poSummaryText">
                <span className="vendorSummaryCategory"  style={{color: "#000", textTransform: "capitalize"}}>
                    {`${context} to: ${addressDetail.customerName?.toUpperCase()}`}
                </span>
                <span className="vendorSummaryValue">
                    {`ID: ${addressDetail.customerId}`}
                </span>
                <span className="vendorSummaryValue">
                {addressDetail.customerAddress?.split(',').join(`,\n\n`)}, <br />
                    {addressDetail.customerPinCode !== null
                        ? `${addressDetail.customerCity}  - ${addressDetail.customerPinCode}`
                        : addressDetail.customerCity},
                        {addressDetail.customerState}
                </span>
                <span className="vendorSummaryValue">
                    {addressDetail.customerMobile !== null || addressDetail.customerPhone !== null ? `\nPhone: ` : null}
                    {addressDetail.customerMobile !== null ? addressDetail.customerMobile : null}
                    {addressDetail.customerPhone !== null ? `, ${addressDetail.customerPhone} ` : null}
                </span>
                <span className="vendorSummaryValue">
                    {addressDetail.customerEmail !== null ? `Email Id: ${addressDetail.customerEmail}` : null}
                </span>

                <span className="vendorSummaryValue">
                    {addressDetail.customerGstNo !== null ? `GSTIN/UIN: ${addressDetail.customerGstNo}` : null}
                </span>
                </div> 
            </div>
        )
      }

      const RenderInvoiceDetailTable=()=>{

        return(
            <>
            <table className="invoiceBill" style={{width:"100%"}}>
                 <tbody>
                    <tr>   
                        <td>         
                            <RenderSeller />
                        </td>
                    <td rowSpan={2} style={{border:"none",padding:"0px",height:"100%"}}>
                    {renderSummaryInfo()}
                    </td>
                   </tr>
                    <tr>
                        <td><RenderConsigneeAddress context={"Bill"} addressDetail={invoiceDetail}/></td>
                    </tr>
                    <tr>
                        <td><RenderConsigneeAddress context={"Ship"} addressDetail={deliveryAddressDetail}/></td>    
                    <td style={{verticalAlign:"top",fontSize:"12px"}}>
                        <b style={{fontSize:"14px"}}>Instructions</b> 
                            <br/>
                            {invoiceDetail.salesInstruction ||invoiceDetail.instruction}
                    </td>
                    </tr>
                    </tbody>
                    </table>
                    {invoiceDetail.invoiceStatus === "Declined" ? (
                        <div style={{ marginTop: "1rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {invoiceDetail.declineReason}
                        </div>
                    ) : null}
                </>
        )
      }
      const RenderTaxPageDetails=()=>{
        return(
            <table className="invoiceBill" style={{width:"100%"}}>
                 <tbody>
                    <tr>
                        <td style={{verticalAlign:"top",textAlign:"left",fontSize:"14px",borderRight:"none"}}>Invoice No : {invoiceDetail.invoiceNo}</td>
                        <td style={{textAlign:"center",border:"none"}}> <RenderSeller /></td>
                        <td style={{verticalAlign:"top",textAlign:"right",fontSize:"14px",borderLeft:"none"}}>Invoice Date : {invoiceDetail.invoiceDate}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{textAlign:"center"}}><RenderConsigneeAddress context={"Bill"} addressDetail={invoiceDetail}/></td>
                    </tr>
                </tbody>
            </table>
        )
      }
    return (
        <React.Fragment>
            <DeclineModal
                openDeclineModal={declineModalIsOpen}
                declineOkClick={declineOkClick}
                declineModalText={declineModalText}
                declineReason={declineReason}
                error={declineReasonError}
                setDeclineReason={setDeclineReason}
                cancelDecline={() => setDeclineModalIsOpen(false)}
            />
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <Modal
                isOpen={diagramModalOpen}
                onRequestClose={() => setDiagramModalOpen(false)}
                contentLabel="Example Modal"
                style={{
                    overlay: { backgroundColor: "rgba(128, 128, 128, 0.5)" },
                    content: {
                        textAlign: "center",
                        width: "70%",
                        height: "70%",
                        position: "fixed",
                        top: "15%",
                        left: "15%",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "0 0 5px gray",
                    },
                }}
            >
                <img className="modalImage" src={`data:image/jpeg;base64,${diagramImage}`} alt="Item Diagram" />
            </Modal>

            <div className="detailsPageWrapper">
                { //Invoice Pages
                lineItemChunk.length > 0 ?
                lineItemChunk.map((currentPage,i)=>{                   
                            return(
                            <div className="detailsPageContainer" key={i}>
                                <div className="invoicePageTitle">Tax Invoice</div>
                                <div style={{border:"0.25px solid black",minHeight:"1100px",position:"relative"}}>
                                    <RenderInvoiceDetailTable />
                                    <RenderTable currentPageLineItems={currentPage} pageNumber={i+1}/>
                                </div>
                                    <div style={{width: "100%", textAlign: "center"}}>
                                        <span style={{fontSize: "9px"}}>SUBJECT TO {invoiceDetail.plantCity?.toUpperCase()} JURISDICTION</span>
                                    </div>
                                    <div style={{width: "100%", textAlign: "center"}}>
                                        <span style={{fontSize: "9px"}}>This is a Computer Generated Invoice</span>
                                    </div>
                            </div>
                            )
                            }) : ''
                }
               { 
               //Tax Analysis Pages
               lineItemChunk.length > 0 ? 
               lineItemChunk.map((currentTaxPage,index)=>(
                <div className="detailsPageContainer" key={index}>
                    <div className="invoicePageTitle">Tax Invoice</div>
                    <div className="medium-font"><center>(Tax Analysis)</center></div>
                    <div style={{border:"1px solid black",minHeight:"1100px",position:"relative"}}>
                        <RenderTaxPageDetails />
                        <RenderTaxTable rows={currentTaxPage} pageNumber={index+1}/>  
                         {  index+1===lineItemChunk.length ?    
                          <div  style={{width:"95%",position:"absolute",bottom:"100px",margin:"10px 20px 0px 0px"}}>
                            <span >
                                <div className="medium-font poSummaryValue" style={{color: "#000", textAlign: "right"}}><b>Authorized Signatory</b></div>
                            </span>
                    </div>:''}
                  </div>
                </div>)) : null}
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        soInvoiceCreate: (invoiceCreateId) => dispatch(soInvoiceCreateId(invoiceCreateId)),
        soEditInvoice: (editInvoiceId) => dispatch(soEditInvoiceId(editInvoiceId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
