import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import CreateProduction from "./CreateProduction";
import productionIcon from "../../assets/production.svg";
import ProductionList from "./ProductionList";
import { pendingSoProduction } from "../../redux/PurchaseOrder/PoInfoActions";
import { connect } from "react-redux";
import ProductionDetail from "./ProductionDetail";
import EditProduction from "./EditProduction";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const Production = ({ poInfo, pendingSoProduction, isLogged }) => {
    const [currentSection, setCurrentSection] = useState("productionOrderList");
    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    useEffect(() => {
        if (poInfo.pendingSoProduction !== null) {
            setCurrentSection("createProductionOrder");
        }
    }, []);

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "productionOrderList", callback: () => { pendingSoProduction(null) } };
    var productionOrder = { buttonName: "Create Request", className: "employeeButton", setSection: setCurrentSection, sectionName: "createProductionOrder", callback: () => { pendingSoProduction(null) } };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "productionOrderDetail") }, sectionName: "productionOrderList" };

    const renderSection = (section) => {
        switch (section) {
            case "createProductionOrder":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={productionIcon} pageTitle="Request Production Order" buttonList={[goBackButton]} />
                        <CreateProduction setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "productionOrderList":
                return (
                    <React.Fragment>
                        {/* <div className="pageTitle">
                            <img className="pageTitleImage" alt="Page Title" src={productionIcon}></img>
                            <span className="pageTitleText">Production Order List</span>
                            <div className="buttonList">
                                <button
                                    className="employeeButton"
                                    onClick={() => {
                                        setCurrentSection("createProductionOrder");
                                        pendingSoProduction(null);
                                    }}>
                                    Create Request
                                </button>
                            </div>
                        </div> */}
                        <PageTitle imgSrc={productionIcon} pageTitle={"Production Order List"}
                            buttonList={isLogged.access["Production Order"] === "Create" || isLogged.access["Production Order"] === "Edit" || isLogged.access["Production Order"] === "App/Dec"
                                ? [productionOrder] : null} />
                        <ProductionList setSection={(value) => setCurrentSection(value)} setApproval={(val) => setApprovalPage(val)} />
                    </React.Fragment>
                );

            case "productionOrderDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={approvalPage ? "Approve Production Order" : "Production Order Detail"}
                            buttonList={[goBackButton, downloadButton]}
                        />
                        <ProductionDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editProductionOrder":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={productionIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Production Request" : "Edit Production Request"}
                            buttonList={[goBackButton]}
                        />
                        <EditProduction setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );
            
            default:
                return<></>
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Production Order" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { poInfo: state.poInfo, isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
    return { pendingSoProduction: () => dispatch(pendingSoProduction(null)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Production);
