import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";

import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import fetchData from "../../serverCall/fetchData";
import inventoryIcon from "../../assets/inventory.svg";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const SubContracting = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("subContractingList");

    const [subContractingList, setSubContractingList] = useState(false);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function fetchDetails() {
            await getSubContractingList();
          }
        fetchDetails();
    }, []);

    async function getSubContractingList() {
        var data = await fetchData({
            requestingPage: "createPlant",
            method: "post",
            url: "dclist-fetch/sub-contracting-list",
            headers: { token: isLogged.accessToken,module:"Sub Contracting" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setSubContractingList(data2);
        } else {
            console.log(data);
        }
        setLoading(false);
        // eslint-disable-next-line
    };

    const renderSubSection = () => {
        return (
            <div className="vendorListArea" style={{ margin: "2rem 0 0 0" }}>
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading">DC ID</td>
                            <td className="vendorListHeading">DC Return ID</td>
                            <td className="vendorListHeading">Item Name</td>
                            <td className="vendorListHeading">DC Qty</td>
                            <td className="vendorListHeading">DC Return Qty</td>
                        </tr>
                    </thead>
                    <tbody>{subContractingList.length > 0 ? renderSubContractingList() : null}</tbody>
                </table>
                {null ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        );
    };

    const renderSubContractingList = () => {
        return subContractingList.map((item, i) => {
            return (
                <React.Fragment>
                    <tr className="vendorList" key={item.dcId}>
                        <td className="vendorListInfo">{item.dcId}</td>
                        <td className="vendorListInfo">{item.dcrId}</td>
                        <td className="vendorListInfo">{item.itemName}</td>
                        <td className="vendorListInfo">{item.dcQty}</td>
                        <td className="vendorListInfo">{item.dcrQty}</td>
                    </tr>
                </React.Fragment>
            );
        });
    };

    const renderSection = (section) => {
        switch (section) {
            case "subContractingList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={inventoryIcon} pageTitle="Sub Contracting List" />
                        <div  style={{width:"250px",marginLeft:"25px"}}>
                        </div>
                        <div className="poListArea">{renderSubSection()}</div>
                    </React.Fragment>
                );
            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Production" selectedSubMenu="Sub Contracting" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged }
}
export default connect(mapStateToProps, null)(SubContracting);
