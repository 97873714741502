import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import itemTaxIcon from "../../../assets/tax_black.svg";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import TopBanner from "../../SmallComponents/TopBanner";
import CreateItemTax from "./CreateItemTax";
import EditItemTax from "./EditItemTax";
import ItemTaxList from "./ItemTaxList";

const ItemTax = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("itemTaxList");

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemTaxList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemTypeButton = {
        buttonName: "Create Item Tax",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createItemTax",
    };

    const renderSection = (section) => {
        switch (section) {
            case "itemTaxList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={itemTaxIcon}
                            pageTitle="Item Tax"
                            buttonList={
                                isLogged.access["Item Tax"] === "Create" || isLogged.access["Item Tax"] === "Edit"
                                    ? [createItemTypeButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemTaxList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createItemTax":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemTaxIcon} pageTitle="Create Item Tax" buttonList={goBackButton} />
                        <CreateItemTax setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editItemTax":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={itemTaxIcon} pageTitle="Edit Item Tax" buttonList={goBackButton} />
                        <EditItemTax setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemTax);
