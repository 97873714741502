import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import CreateEditModal from "components/SmallComponents/CreateEditModal";
import { editBundleCreation } from 'redux/Item/ItemInfoActions';
import { addToken } from "redux/UserAccount/IsLoggedActions";
import FormElement from 'components/SmallComponents/FormElement';
import update from "immutability-helper";
import editIcon from "../../../assets/edit.svg";
import deleteIcon from "../../../assets/delete.svg";
import FetchNewToken from "serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "serverCall/fetchData";

const CreateBundle = ({isLogged, addTokenToState, setSection}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");
    const [errors, setErrors] = useState([]);
    const history = useHistory();
    const [sectionList, setSectionList] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [bundleParams, setBundleParams] = useState({
        sectionNo: {
          inputType: "options",
          value: "",
          hintText: "Sections ",
          mandatory: true,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please select Section",
        },
        sectionName: {
          inputType: "text",
          value: "",
          disabled: true,
          hintText: "Section Name",
          mandatory: false,
          colSpan: 4,
          error: false,
        },
        quantity: {
          inputType: "float",
          value: "",
          hintText: "Quantity (Pcs)",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please enter a valid Quantity",
        },
        weight: {
          inputType: "float",
          value: "",
          hintText: "Weight",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please enter a valid weight",
        },
        cuttingLengthId: {
          inputType: "options",
          value: "",
          hintText: "Cutting Length",
          mandatory: true,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please select Cutting Length",
        },
        batchNo: {
          inputType: "text",
          value: "",
          hintText: "Batch No (Ageing No)",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please enter a valid Batch No",
        },
        soId: {
          inputType: "options",
          value: "",
          hintText: "Sales Order",
          mandatory: true,
          colSpan: 6,
          error: false,
          options: [],
          errorMessage: "Please select Sales Order No",
        },
        averageWeight:{
          inputType: "float",
          value: "",
          hintText: "Average Weight",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please Enter Valid Average Weight",
          disabled: true,
        },
        sectionId: {
          value: ""
        }
      });

      async function getSectionList() {
        let data = await fetchData({
          requestingPage: "sectionMaster",
          method: "post",
          url: "fetch/section-master",
          headers: { token: isLogged.accessToken, module: "Section Master" },
        });
        if (data.msg === "success") {
            return data.asset
        }
        else {
         
          return []
        }
      };

      async function getSalesOrderApproved() {
        let salesOrder = await fetchData({
          requestingPage: "customerList",
          method: "post",
          url: "so-fetch/so-approved-list-mini",
          headers: { token: isLogged.accessToken, module: "Bundle Creation" },
        });
    
        if (salesOrder.msg === "success") {
          return salesOrder.asset
        }
        else {
          return []
        }
      };

      async function getCuttingLengthList() {
        let cuttingLengthList = await fetchData({
          requestingPage: "uomList",
          method: "post", url: "fetch/cutting-length",
          headers: { token: isLogged.accessToken, module: "Cutting Length" }
        });
    
        if (cuttingLengthList.msg === "success") {
          return cuttingLengthList.asset
        } else {
          return [];
        }
      }

      const checkToken = useCallback(async () => {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          addTokenToState(isLogged.employeeId, token2);
        } else {
          //console.log("Token not changed");
        }
      }, [isLogged, addTokenToState, history]);

      async function getInformation(){
          await checkToken()
          let sectionList = await getSectionList();
          let cuttingLengthList = await getCuttingLengthList();
          let soIdList = await getSalesOrderApproved()

          setSectionList(sectionList);

          const paramsCopy = update(bundleParams, {
            sectionNo: { options: { $set: sectionList } },
            cuttingLengthId: { options: { $set: cuttingLengthList } },
            soId: {options: { $set: soIdList}}
          });
          setBundleParams(paramsCopy)
      }

      useEffect(() => { getInformation() }, []);

      const submitOkClick = () => {
        setIsOpen(false);
        if (errors.length === 0) {
          setSection("bundleList");
        }
      };

      const updateParameters = ({ param, key, value, wholeObject }) => {
        let bundleParamsCopy = Object.assign(bundleParams);
        bundleParamsCopy = update(bundleParamsCopy, { [param]: { [key]: { $set: value } } });
        if (param === "sectionNo") {
          bundleParamsCopy = update(bundleParamsCopy, {
            sectionName: { value: { $set: wholeObject?.sectionName || "" } },
            sectionId: { value: { $set: wholeObject?.sectionId || "" } }
          });
        }
        if(['quantity','weight'].includes(param)){
          let avgWeight = 0;
          if(bundleParamsCopy.quantity.value && bundleParamsCopy.weight.value){
            avgWeight = bundleParamsCopy.quantity.value / bundleParamsCopy.weight.value;
            avgWeight = parseFloat(avgWeight.toFixed(3));
          }
          bundleParamsCopy = update(bundleParamsCopy,{
            averageWeight: { value: {$set: avgWeight } }
          });
        }
        setBundleParams(bundleParamsCopy);
      }

      const renderFormElements = ({ elementList, param }) => {
        return elementList.map((element) => {
          return (
            <FormElement
              key={element}
              inputType={param[element].inputType}
              value={param[element].value !== null ? param[element].value : ""}
              setInput={(value, wholeObject) => {
                updateParameters({ param: element, key: "value", value: value, wholeObject });
              }}
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={param[element].inputType === "options" ? param[element].options : null}
              error={param[element].error}
              rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
              placeholder={param[element].placeholder}
              title={param[element].title}
              disabled={param[element].disabled}
              mindate={param[element].mindate}
            />
          );
        });
      }

      const RenderTable = () => {
        return (
          <table className="createVendorContactsTable">
            <thead>
              <tr className="createVendorContactsTableHeader">
                <td>Section No</td>
                <td>Section Name</td>
                <td>Cutting Length</td>
                <td>Quantity</td>
                <td>Weight</td>
                <td>Average Weight</td>
              </tr>
            </thead>
            {lineItems.length > 0 ?
              (<tbody>
                <RenderTableRows data={lineItems} />
              </tbody>) :
              (<tbody>
                <tr className="createVendorContactsTableRows">
                  {["", "", "", "", "",""].map((item, i) => {
                    return <td key={i}>&nbsp;</td>;
                  })}
                </tr>
              </tbody>)}
          </table>
        );
      };

      const RenderTableRows =(props) => {
        const { data } = props;
          return data.map((row, j) => {
              return (
                <tr className="createVendorContactsTableRows" key={j}>
                {["sectionNo", "sectionName","cuttingLength", "quantity", "weight", "averageWeight"].map((key, i) => {
                  return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                })}
                </tr>
              );
          });
      };

      const addBundleToLine = () => {
        let linecopy = lineItems.slice();
        let errorList = [];
        ["sectionNo", "sectionName", "quantity", "weight", "cuttingLengthId",'averageWeight'].forEach((item) => {
          if (bundleParams[item].value === "" || bundleParams[item].value === null) {
            bundleParams[item].error = true;
            errorList.push("Provide Valid Invoice Details");
          }
        })
        if (errorList.length === 0) {
          linecopy.push({
            sectionId: bundleParams.sectionNo.value,
            sectionNo: bundleParams.sectionNo.options.filter((row)=>row.optionId==bundleParams.sectionNo.value)[0].optionName.split(" - ")[0],
            sectionName: bundleParams.sectionName.value,
            quantity: bundleParams.quantity.value,
            weight: bundleParams.weight.value,
            cuttingLengthId: bundleParams.cuttingLengthId.value,
            cuttingLength: bundleParams.cuttingLengthId.options.filter((opt)=>opt.optionId == bundleParams.cuttingLengthId.value)[0].optionName,
            averageWeight: bundleParams.averageWeight.value,
          });
    
          let trimmedList = bundleParams.sectionNo.options.filter((item) => item.optionId !== bundleParams.sectionNo.value);
          const bundleParamsCopy = update(bundleParams, {
            sectionNo: { value: { $set: "" }, options: { $set: trimmedList } },
            sectionName: { value: { $set: "" } },
            weight: { value: { $set: "" } },
            cuttingLengthId: { value: { $set: "" } },
            quantity: { value: { $set: "" } },
            averageWeight: {value: {$set: ""} },
          })
          setBundleParams(bundleParamsCopy);
          setLineItems(linecopy);
        } else {
          setErrors(errorList);
        }
      }

      const renderErrorMessage = () => {
        if (errors.length > 0)
          return errors[0]
      };

      const checkErrors=() => {
        let errorList = [];
        let bundleParamsCopy= Object.assign(bundleParams);
    
        ["sectionNo", "sectionName", "quantity", "weight", "cuttingLengthId",].map((item,i)=>{
          if(bundleParamsCopy[item].value!=="")
              errorList.push("Unsaved Bundle Record Found!");
      })
    
        let lineItemsCopy = lineItems.slice();
        lineItemsCopy.forEach((row)=>{
            for (let item in row){
                if(row[item]==null || row[item]==undefined)
                errorList.push("Invalid Bundle Item Found");}
            });
    
        if(lineItems.length<1)
            errorList.push("Select Section No");
    
        if(errorList.length===0 && lineItems.length> 0){
          let bundleLineItems = lineItems.map((line)=>(
            {...line,
              soId: bundleParams.soId.value,
              batchNo: bundleParams.batchNo.value   
            })
          );

          let dataToServer={
            bundleLineItems,
            totalWeight: lineItems.reduce((acc, row) => acc + parseFloat(row.weight || 0), 0),
            totalQuantity: lineItems.reduce((acc, row) => acc + parseFloat(row.quantity || 0), 0),
            soId: bundleParams.soId.value,
            batchNo: bundleParams.batchNo.value
          }
          submitData(dataToServer);
        } else {
          setLineItems(lineItemsCopy);
          setErrors(errorList);
        }
      }

      async function submitData(dataToServer){
        setIsOpen(true)
        setModalText("Creating Bundle... Please Wait...")
    
        let response = await fetchData({
          requestingPage: "sectionMaster",
          method: "post",
          url: "create/bundle-creation",
          data: dataToServer,
          headers: { token: isLogged.accessToken, module: "Bundle Creation" }
        });
    
        if (response.msg === "success") {
          setDataSubmitted(true);
          setModalText("Data Submitted");
          setIsOpen(true);
        } else {
          setModalText(response.desc)
        }
      }
      const renderSubsection=()=>{

        return(
          <Fragment>
            <div className="purchaseOrderIndividualItemsArea" style={{ gridAutoRows: "60px" }}>
              {renderFormElements({
                elementList: ["sectionNo", "sectionName","cuttingLengthId" ,"quantity", "weight",
                  "averageWeight",], param: bundleParams
              })}
              <FormElement
                inputType="addButton"
                value="+ Add"
                colSpan={4}
                setInput={() => {
                  setErrors([]);
                  addBundleToLine();
                }}
              />
            </div>
            <RenderTable />
          </Fragment>
        )
      }
      return (
        <Fragment>
          <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
          <div className="formArea">
            <div
              style={{
                width: "1000px",
                margin: "0 auto 2rem",
                // padding: "3rem 3rem 1rem 3rem",
                padding: "2rem",
                border: "1px solid lightgray",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <div style={{ display: "flex" }}>
                <div
                  className="createPurchaseOrderGrid"
                  style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                  {renderFormElements({ elementList: ["soId", "batchNo"], param: bundleParams, })}
                </div>
              </div>
              <div className='purchaseOrderSubSectionArea'>
                {renderSubsection()}
              </div>
            </div>
          </div>
          <div className="formSubmitArea">
            <div className="formSubmitInnerArea">
              <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
              <button className="submitButton" onClick={() => { setErrors([]); checkErrors(); }}>
                Submit
              </button>
            </div>
          </div>
        </Fragment>
      )
}

const mapStateToProps = (state) => ({
    isLogged: state.isLogged
  })
  
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
      editBundleCreation: (value) => dispatch(editBundleCreation(value))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateBundle);