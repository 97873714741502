export const setOPDTab = (opdTab) => {
    return { type: "SET_OPD_TAB", payload: { opdTab } };
};

export const viewOpdId = (opdId) => {
    return { type: "VIEW_OPD_ID", payload: { opdId } };
};

export const editOpdId = (opdId) => {
    return { type: "EDIT_OPD_ID", payload: { opdId } };
};

export const setOutwardTab = (outwardTab) => {
    return { type: "SET_OUTWARD_TAB", payload: { outwardTab } };
};

export const viewOutwardId = (outwardId) => {
    return { type: "VIEW_OUTWARD_ID", payload: { outwardId } };
};

export const editOutwardId = (outwardId) => {
    return { type: "EDIT_OUTWARD_ID", payload: { outwardId } };
};

export const setQcTab = (qcTab) => {
    return { type: "SET_QC_TAB", payload: { qcTab } };
};

export const viewQcId = (qcId) => {
    return { type: "VIEW_QC_ID", payload: { qcId } };
};

export const editQcId = (qcId) => {
    return { type: "EDIT_QC_ID", payload: { qcId } };
};

export const setTransferTab = (transferTab) => {
    return { type: "SET_TRANSFER_TAB", payload: { transferTab } };
};

export const viewTransferId = (transferId) => {
    return { type: "VIEW_TRANSFER_ID", payload: { transferId } };
};

export const editTransferId = (transferId) => {
    return { type: "EDIT_TRANSFER_ID", payload: { transferId } };
};

export const setInwardTab = (inwardTab) => {
    return { type: "SET_INWARD_TAB", payload: { inwardTab } };
};

export const viewInwardId = (viewInwardId) => {
    return { type: "VIEW_INWARD_ID", payload: { viewInwardId } };
};

export const editInwardId = (editInwardId) => {
    return { type: "EDIT_INWARD_ID", payload: { editInwardId } };
};

export const viewSoPendingId = (viewSoPendingId) => {
    return { type: "VIEW_SO_PENDING_ID", payload: { viewSoPendingId } };
};

export const viewIdnPendingId = (viewIdnPendingId) => {
    return { type: "VIEW_IDN_PENDING_ID", payload: { viewIdnPendingId } };
};

export const viewIdnId = (viewIdnId)=>{
    return {type: "VIEW_IDN_ID", payload: { viewIdnId } };
}

export const editIdnId = (editIdnId)=>{
    return {type: "EDIT_IDN_ID", payload: { editIdnId } };
}

export const pendingIdn = (mrsForIdn) => {
    return { type: "PENDING_IDN", payload: { mrsForIdn } };
};

export const addToCustomerLedgerParams= (customerLedgerParams) =>{
    return {type: "CUSTOMER_LEDGER_PARAMS",payload: {customerLedgerParams}}
}

export const addToOutStandingParams = (outStandingParams)=>{
    return {type: "OUTSTANDING_STATEMENT_PARAMS", payload: {outStandingParams}}
}

export const addToPackingSlipId = (packingId)=>{
    return {type: "PACKING_SLIP_ID", payload: {packingSlipId: packingId}}
}

export const addToOtherCharges =(otherChargesId)=>{
    return {type: "OTHER_CHARGES_ID", payload: {otherChargesId}}
}

export const addToLoadingSlipId =(loadingSlipId)=>{
    return {type: "LOADING_SLIP_ID", payload: {loadingSlipId}}
}

export const addToSalesOpportunityId =(salesOpportunityId)=>{
    return {type: "SALES_OPPORTUNITY_ID", payload: {salesOpportunityId}}
}

export const addToSalesQuoteId =(salesQuoteId)=>{
    return {type: "SALES_QUOTE_ID", payload: {salesQuoteId}}
}

export const addToSalesLeadId =(salesLeadId)=>{
    return {type: "SALES_LEAD_ID", payload: {salesLeadId}}
}