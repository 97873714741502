import React from "react";
import "../styles/PageTitle.css";

const PageTitle = ({ imgSrc, pageTitle, buttonList }) => {
    return (
        <div className="pageTitle">
            <img className="pageTitleImage" alt="Page Title" src={imgSrc}></img>
            <span className="pageTitleText">{pageTitle}</span>
            <div className="buttonList">
                {buttonList?.length > 0
                    ? buttonList.map((button, i) => {
                        if (button && button.iconSrc) {
                            return (
                                <button
                                    key={i}
                                    className="imageIconButton"
                                >
                                    <img key={i}
                                        className="imageIcon"
                                        src={button.iconSrc} alt="Page Icon" onClick={() => {
                                            button.setSection(button.sectionName);
                                            if (button.callback)
                                                button.callback();
                                        }}
                                    />
                                </button>
                            );
                        } else if (button) {
                            return (
                                <button
                                    key={i}
                                    className={button.className}
                                    style={button.style}
                                    onClick={() => {
                                        button.setSection(button.sectionName);
                                        if (button.callback)
                                            button.callback();
                                    }}
                                >
                                    {button.buttonName}
                                </button>
                            );
                        }
                    })
                    : null}
            </div>
        </div>
    );
};

export default React.memo(PageTitle);
