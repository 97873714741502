import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";

import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";

const PriceDetail = ({ isLogged, addTokenToState }) => {
    var inventoryParameterList = ["plant", "storage", "category", "subCategory", "brandName", "itemName", "batchNo"];

    var plantList = [];
    var storageList = [];
    var categoryList = [];
    var subCategoryList = [];
    var brandList = [];
    var itemNameList = [];
    var batchNoList = [];

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [inventoryList, setInventoryList] = useState([]);
    const history = useHistory();

    const [inventoryParams, setInventoryParams] = useState({
        plant: {
            inputType: "options",
            value: "",
            hintText: "Plant",
            options: plantList,
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Please select Plant Name",
        },
        storage: {
            inputType: "options",
            value: "",
            hintText: "Storage",
            options: storageList,
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Please enter Plant Name",
        },
        category: {
            inputType: "options",
            value: "",
            hintText: "Item Main Category",
            mandatory: false,
            colSpan: 4,
            options: categoryList,
            error: false,
            errorMessage: "Please select category",
        },
        subCategory: {
            inputType: "options",
            value: "",
            hintText: "Item SubCategory",
            mandatory: false,
            colSpan: 4,
            options: subCategoryList,
            error: false,
            errorMessage: "Please select subCategory",
        },
        brandName: {
            inputType: "options",
            value: "",
            hintText: "Brand Name",
            mandatory: false,
            colSpan: 4,
            options: brandList,
            error: false,
            errorMessage: "Please select city",
        },
        itemName: {
            inputType: "options",
            value: "",
            hintText: "Item Name",
            mandatory: false,
            colSpan: 4,
            options: itemNameList,
            error: false,
            errorMessage: "Enter Zip code",
        },

        batchNo: {
            inputType: "options",
            value: "",
            hintText: "Batch No",
            mandatory: false,
            colSpan: 4,
            options: batchNoList,
            error: false,
            errorMessage: "Provide GST Number",
        },
    });

    const [filteredInventoryList, setFilteredInventoryList] = useState([]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getInventory();
    }

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getInventory() {
        var data = await fetchData({
            requestingPage: "plantList",
            method: "post",
            url: "store-fetch/inventory-price-list",
            headers: { token: isLogged.accessToken,module: "Inventory" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setInventoryList(data2);
            var paramsCopy = parseLists(data2);
            setInventoryParams(paramsCopy);

            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }

        setLoading(false);
    }

    const parseLists = (inventories) => {

        inventories.forEach((element) => {
            if (element.plant !== null) {
                plantList.push(element.plant);
            }
            if (element.storage !== null) {
                storageList.push(element.storage);
            }
            if (element.category !== null) {
                categoryList.push(element.category);
            }
            if (element.subCategory !== null) {
                subCategoryList.push(element.subCategory);
            }
            if (element.brandName !== null) {
                brandList.push(element.brandName);
            }
            if (element.items.length > 0) {
                element.items.map((item) => {
                    itemNameList.push(item.itemName);
                });
            }
            if (element.batchNo !== null) {
                batchNoList.push(element.batchNo);
            }
        });

        plantList = Array.from(new Set(plantList.map((item) => item)));
        storageList = Array.from(new Set(storageList.map((item) => item)));
        categoryList = Array.from(new Set(categoryList.map((item) => item)));
        subCategoryList = Array.from(new Set(subCategoryList.map((item) => item)));
        brandList = Array.from(new Set(brandList.map((item) => item)));
        itemNameList = Array.from(new Set(itemNameList.map((item) => item)));
        batchNoList = Array.from(new Set(batchNoList.map((item) => item)));

        plantList = plantList.map((item) => {
            return { optionId: item, optionName: item };
        });
        storageList = storageList.map((item) => {
            return { optionId: item, optionName: item };
        });
        categoryList = categoryList.map((item) => {
            return { optionId: item, optionName: item };
        });
        subCategoryList = subCategoryList.map((item) => {
            return { optionId: item, optionName: item };
        });
        brandList = brandList.map((item) => {
            return { optionId: item, optionName: item };
        });
        itemNameList = itemNameList.map((item) => {
            return { optionId: item, optionName: item };
        });
        batchNoList = batchNoList.map((item) => {
            return { optionId: item, optionName: item };
        });


        var paramsCopy = Object.assign(inventoryParams);

        paramsCopy = update(paramsCopy, {
            plant: { options: { $set: plantList } },
            storage: { options: { $set: storageList } },
            category: { options: { $set: categoryList } },
            subCategory: { options: { $set: subCategoryList } },
            brandName: { options: { $set: brandList } },
            itemName: { options: { $set: itemNameList } },
            batchNo: { options: { $set: batchNoList } },
        });

        return paramsCopy;
    };

    useEffect(() => {
        filterList();
    }, [inventoryParams, inventoryParams.itemName]);

    const filterList = () => {
        var inventoryListCopy = inventoryList.slice();

        if (inventoryParams.plant.value !== "") {
            inventoryListCopy = inventoryListCopy.filter((inventory) => inventory.plant === inventoryParams.plant.value);
        }

        if (inventoryParams.storage.value !== "") {
            inventoryListCopy = inventoryListCopy.filter((inventory) => inventory.storage === inventoryParams.storage.value);
        }

        if (inventoryParams.category.value !== "") {
            inventoryListCopy = inventoryListCopy.filter((inventory) => inventory.category === inventoryParams.category.value);
        }

        if (inventoryParams.subCategory.value !== "") {
            inventoryListCopy = inventoryListCopy.filter((inventory) => inventory.subCategory === inventoryParams.subCategory.value);
        }

        if (inventoryParams.brandName.value !== "") {
            inventoryListCopy = inventoryListCopy.filter((inventory) => inventory.brandName === inventoryParams.brandName.value);
        }

        if (inventoryParams.itemName.value !== "") {

            var presentItemsArray = [];
            inventoryListCopy.map((inventory, i) => {
                var presentItems = inventory.items.filter((item) => {
                    if (item.itemName === inventoryParams.itemName.value) {
                        return item;
                    }
                });

                if (presentItems.length > 0) {
                    inventory.items = presentItems;
                    presentItemsArray.push(inventory);
                }
            });

            inventoryListCopy = presentItemsArray;
        }

        if (inventoryParams.batchNo.value !== "") {
            inventoryListCopy = inventoryListCopy.filter((inventory) => inventory.batchNo === inventoryParams.batchNo.value);
        }

        setFilteredInventoryList(inventoryListCopy);
    };

    const updateInventoryParameter = ({ paramName, key, value }) => {
        var paramsCopy = Object.assign(inventoryParams);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        setInventoryParams(paramsCopy);
    };

    const resetSelections = () => {
        var paramsCopy = Object.assign(inventoryParams);

        paramsCopy = update(paramsCopy, {
            plant: { value: { $set: "" } },
            storage: { value: { $set: "" } },
            category: { value: { $set: "" } },
            subCategory: { value: { $set: "" } },
            brandName: { value: { $set: "" } },
            itemName: { value: { $set: "" } },
            batchNo: { value: { $set: "" } },
        });

        setInventoryParams(paramsCopy);
    };

    const renderFormElements = ({ elementList }) => {
        return elementList.map((item) => {
            return (
                <FormElement
                    key={item}
                    inputType={inventoryParams[item].inputType}
                    value={inventoryParams[item].value}
                    setInput={(value) => {
                        updateInventoryParameter({ paramName: item, key: "value", value: value });
                    }}
                    hintText={inventoryParams[item].hintText}
                    mandatory={inventoryParams[item].mandatory}
                    colSpan={inventoryParams[item].colSpan}
                    options={inventoryParams[item].inputType === "options" ? inventoryParams[item].options : null}
                    error={inventoryParams[item].error}
                />
            );
        });
    };

    const renderinventoryList = () => {
        return filteredInventoryList.map((plant, i) => {
            return (
                <React.Fragment>
                    <tr className={plant.collapse ? "vendorList" : "vendorListExpanded"} key={plant.customerId} onClick={() => editPlantCollapse(i)}>
                        <td className="vendorListInfo">{plant.date}</td>
                        <td className="vendorListInfo">{plant.plant}</td>
                        <td className="vendorListInfo">{plant.storage}</td>
                        <td className="vendorListInfo">{plant.category}</td>
                        <td className="vendorListInfo">{plant.subCategory}</td>
                        <td className="vendorListInfo">{plant.brandName}</td>
                        <td className="vendorListInfo">{plant.batchNo}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={plant.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editPlantCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!plant.collapse ? <RenderSubTable data={plant.items} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const editPlantCollapse = (index) => {
        var inventoryListCopy = filteredInventoryList.slice();
        inventoryListCopy.forEach((plant, j) => {
            if (j === index) {
                inventoryListCopy[j].collapse = !plant.collapse;
            }
            // else {
            //     inventoryListCopy[j].collapse = true;
            // }
        });
        setFilteredInventoryList(inventoryListCopy);
    };

    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="8" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item Name</td>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">HSN Code</td>
                            <td className="vendorListInnerTableHeading">Cost</td>
                            <td className="vendorListInnerTableHeading">Sales Price</td>
                            <td className="vendorListInnerTableHeading">Tax Type</td>
                            <td className="vendorListInnerTableHeading">Tax Percentage (%)</td>
                            <td className="vendorListInnerTableHeading">Net Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.itemName}>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnerTableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.to? row.to : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.netWeight? row.netWeight : " - "}</td>
                                    <td className="vendorListInnerTableInfo">{row.grossWeight? row.grossWeight: " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.hsnCode}</td>
                                    <td className="vendorListInnertableInfo">{row.cost !== null ? row.cost : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.salesPrice !== null ? row.salesPrice : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.taxType !== null ? row.taxType : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.taxPercentage !== null ? row.taxPercentage : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.netPrice !== null ? row.netPrice : "-"}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div className="vendorListArea">
                <div className="createPlantGeneralGrid" style={{ margin: "0 2rem 2rem", gridTemplateRows: "repeat(2, 4rem)" }}>
                    {renderFormElements({ elementList: inventoryParameterList })}
                    <FormElement
                        inputType="addButton"
                        value="Reset Selections"
                        colSpan={4}
                        setInput={() => {
                            resetSelections();
                        }}
                    />
                </div>
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading">Date</td>
                            <td className="vendorListHeading">Plant </td>
                            <td className="vendorListHeading">Storage</td>
                            <td className="vendorListHeading">Item Main Category</td>
                            <td className="vendorListHeading">Item Sub Category</td>
                            <td className="vendorListHeading">Brand Name</td>
                            <td className="vendorListHeading">Batch No</td>
                            <td className="vendorListHeadingActions">Action</td>
                        </tr>
                    </thead>
                    <tbody>{inventoryList.length > 0 ? renderinventoryList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceDetail);
