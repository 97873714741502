import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import logoNotAvailable from "../../assets/no-stopping.svg";

const VendorDetail = ({
  // props
  setSection,

  // state
  isLogged,
  vendorInfo,

  // dispatch
  addTokenToState,
}) => {

  const [vendorDetail, setVendorDetail] = useState(null);
  const [vendorContactDetail, setVendorContactDetail] = useState([]);
  const [vendorBankDetail, setVendorBankDetail] = useState([]);
  const [vendorAddInfo, setVendorAddInfo] = useState();

  const [vendorLogo, setvendorLogo] = useState();

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, []);

  async function getInformation() {
    await checkToken();
    await getvendorDetail();
  }

  const history = useHistory();

  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getvendorDetail() {
    var data = await fetchData({
      requestingPage: "vendorDetail",
      method: "get",
      url: "fetch/vendor/" + vendorInfo.viewVendorId,
      headers: { token: isLogged.accessToken, module: "Vendor" },
    });
    if (data.msg === "success") {
     
      setVendorDetail(data.asset.vendorDetail[0]);
      setVendorContactDetail(data.asset.contactDetail);
      setVendorBankDetail(data.asset.bankDetail);
      setVendorAddInfo(data.asset.additionalInfo[0]);
    } else {
      console.log(data);
    }
  }

  useEffect(() => {
    getVendorLogo();
    // eslint-disable-next-line
  }, [vendorDetail]);

  async function getVendorLogo() {
    var data = await fetchData({
      requestingPage: "vendorImage",
      method: "get",
      url: "image/vendor-logo/" + vendorInfo.viewVendorId,
      headers: { token: isLogged.accessToken, module: "Vendor" },
    });
    setvendorLogo(data);
  }

  const renderImageStatus = () => {
    return (
      <div>
        <img
          className="vendorImage"
          src={
            vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
              ? `data:image/png;base64,${vendorLogo}`
              : logoNotAvailable
          }
          alt="Vendor Logo"
        />
        <div className="vendorCurrentStatus">
          <span className="vendorSummaryCategory">Status :</span>
          <span className="vendorStatus">Active</span>
        </div>
      </div>
    );
  };

  const renderSummaryInfo = () => {
    if (vendorDetail !== null) {
      return (
        <React.Fragment>
          <span className="vendorSummaryRole">{vendorDetail.companyName}</span>

          <div className="vendorSummaryGrid">
            <span className="vendorSummaryCategory">Mailing Name</span>
            <span className="vendorSummaryValue">
              : {vendorDetail.mailingName}
            </span>

            <span className="vendorSummaryCategory">Vendor Id</span>
            <span className="vendorSummaryValue">
              : {vendorDetail.vendorId}
            </span>

            <span className="vendorSummaryCategory">Mobile Number</span>
            <span className="vendorSummaryValue">
              :{" "}
              {vendorDetail.mobile !== undefined &&
              vendorDetail.mobile !== null &&
              vendorDetail.mobile.length > 0
                ? vendorDetail.mobile
                : "NA"}{" "}
              {vendorDetail.phone ? ", " + vendorDetail.phone : null}
            </span>

            <span className="vendorSummaryCategory">Email Id</span>
            <span className="vendorSummaryValue">
              :{" "}
              {vendorDetail.email !== undefined &&
              vendorDetail.email !== null &&
              vendorDetail.email.length > 0
                ? vendorDetail.email
                : "NA"}
            </span>

            <span className="vendorSummaryCategory">Website</span>
            <span className="vendorSummaryValue">
              :{" "}
              {vendorDetail.website !== undefined &&
              vendorDetail.website !== null &&
              vendorDetail.website.length > 0
                ? vendorDetail.website
                : "NA"}
            </span>

            <span className="vendorSummaryCategory">GST No</span>
            <span className="vendorSummaryValue">
              :{" "}
              {vendorDetail.gstNo !== null &&
              vendorDetail.gstNo !== undefined &&
              vendorDetail.gstNo.length > 0
                ? vendorDetail.gstNo
                : "NA"}
              {vendorDetail.gstRegisterType !== null &&
              vendorDetail.gstRegisterType !== undefined &&
              vendorDetail.gstRegisterType.length > 0
                ? ` - (${vendorDetail.gstRegisterType})`
                : null}
            </span>

            <span className="vendorSummaryCategory">Credit Days</span>
            <span className="vendorSummaryValue">
              :{" "}
              {vendorDetail.creditDays !== undefined &&
              vendorDetail.creditDays !== null &&
              vendorDetail.creditDays.length > 0
                ? vendorDetail.creditDays
                : "NA"}
            </span>
          </div>
        </React.Fragment>
      );
    }
  };

  const renderAddress = () => {
    if (vendorDetail != null) {
      return (
        <React.Fragment>
          <div className="vendorSummaryAddress">Address </div>

          <div className="vendorSummaryAddressValue">
            {vendorDetail.address}
          </div>
          <div className="vendorSummaryAddressValue">
            {vendorDetail.city}
            {vendorDetail.zipCode !== null
              ? ` - ${vendorDetail.zipCode}`
              : null}
          </div>
          <div className="vendorSummaryAddressValue">
            {vendorDetail.state}, {vendorDetail.country}
          </div>
        </React.Fragment>
      );
    }
  };

  const tablist = [
    { id: "contactDetails", name: "Contact Details" },
    { id: "bankDetails", name: "Bank Details" },
    { id: "additionalInfo", name: "Additional Information" },
  ];
  const [selectedTab, setSelectedTab] = useState("contactDetails");
  const RenderTabList = ({ tablist }) => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            selectedTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={() => setSelectedTab(tab.id)}>
          {tab.name}
        </div>
      );
    });
  };

  const RenderTable = ({ tab }) => {
    switch (tab) {
      case "contactDetails":
        return (
          <table className="vendorDetailContactsTable">
            <thead>
              <tr className="vendorDetailContactsTableHeader">
                <td>Contact Name</td>
                <td>Designation</td>
                <td>Department</td>
                <td>Mobile No</td>
                <td>Email Id</td>
                <td>Experience</td>
                <td>Education Details</td>
              </tr>
            </thead>
            <tbody>
              <RenderTableRows rows={vendorContactDetail} tab="contact" />
            </tbody>
          </table>
        );

      case "bankDetails":
        return (
          <table className="vendorDetailContactsTable">
            <thead>
              <tr className="vendorDetailContactsTableHeader">
                <td>Account Holder Name</td>
                <td>Account Number</td>
                <td>Bank Name</td>
                <td>IFSC/SWIFT Code</td>
                <td>Branch</td>
              </tr>
            </thead>

            <tbody>
              <RenderTableRows rows={vendorBankDetail} tab="bank" />
            </tbody>
          </table>
        );

      case "additionalInfo":
        return (
          <div className="vendorAdditionalInfo">
            <span className="vendorSummaryCategory">Founded Date</span>
            <span>:</span>
            <span className="vendorSummaryValue">
              {vendorAddInfo.foundedDate !== null
                ? new Date(vendorAddInfo.foundedDate).toDateString()
                : "NA"}
            </span>
            <span className="vendorSummaryCategory">Industry Type</span>{" "}
            <span>:</span>
            <span className="vendorSummaryValue">
              {vendorAddInfo.industryType !== null &&
              vendorAddInfo.industryType.length > 0
                ? vendorAddInfo.industryType
                : "NA"}
            </span>
            <span className="vendorSummaryCategory">
              Other Business Details
            </span>
            <span>:</span>
            <span className="vendorSummaryValue">
              {vendorAddInfo.otherBusinessDetails !== null &&
              vendorAddInfo.otherBusinessDetails.length > 0
                ? vendorAddInfo.otherBusinessDetails
                : "NA"}
            </span>
          </div>
        );

      default:
        return <div>No Table rendered</div>;
    }
  };

  var contactDetailKeys = [
    "contactName",
    "designation",
    "department",
    "mobile",
    "email",
    "experience",
    "education",
  ];
  var bankDetailKeys = [
    "accountHolderName",
    "accountNumber",
    "bankName",
    "ifscCode",
    "branch",
  ];

  const RenderTableRows = ({ rows, tab }) => {

    var tableKeys = [];
    if (tab === "contact") tableKeys = contactDetailKeys;
    if (tab === "bank") tableKeys = bankDetailKeys;

    return rows.map((row, j) => {
      return (
        <tr className="vendorDetailContactsTableRows" key={j}>
          {tableKeys.map((key, i) => {
            return <td key={i}>{row[key]}</td>;
          })}
        </tr>
      );
    });
  };

  return (
    <div className="detailsPageWrapper">
      <div className="detailsPageContainer">
        <div className="vendorSummary">
          {renderImageStatus()}
          <div className="vendorSummaryText">{renderSummaryInfo()}</div>
          <div style={{ flex: "1" }} />
          <div className="vendorSummaryText">{renderAddress()}</div>
        </div>

        <div className="vendorDetailTabArea">
          <div className="vendorDetailTabs">
            {/* {renderTabList()} */}
            <RenderTabList tablist={tablist} />
          </div>
          <RenderTable tab={selectedTab} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    vendorInfo: state.vendorInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetail);
