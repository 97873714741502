import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { editTransferId, setTransferTab, viewTransferId } from "../../redux/Store/StoreInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FormElement from "../SmallComponents/FormElement"
// import pageIcon from "../../assets/sheets.svg";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import { handleSort } from "../CommonFunctions/utils";
import Pagination from  '../CommonFunctions/pagination';

const StockTransferList = ({
    // props
    setSection,
    setApproval,

    // state
    isLogged,
    storeInfo,
    setEditAndApprove,

    // dispatch
    setTransferTab,
    addTokenToState,
    viewTransferId,
    editTransferId,
}) => {
    const [loading, setLoading] = useState(true);
    const [transferUnapproved, setTransferUnapproved] = useState([]);
    const [transferApproved, setTransferApproved] = useState([]);
    const [plantId,setPlantId] = useState('');

    const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
    const [noApprovedRecords, setNoApprovedRecords] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const transferUnApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return transferUnapproved.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,transferUnapproved,PageSize]);
      const transferApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return transferApproved.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,transferApproved,PageSize]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getTransferUnapproved();
    }

    const history = useHistory();
    async function checkToken() {
        //   console.log(isLogged.accessToken);
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getTransferUnapproved() {
        var data = await fetchData({
            requestingPage: "stockTransferList",
            method: "post",
            url: "store-fetch/stock-transfer-unapproved-list",
            headers: { token: isLogged.accessToken,module: "Stock Transfer" },
            data:{plantId}
        });
        if (data.msg === "success") {

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setTransferUnapproved(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        }
        setLoading(false);
    }

    async function getTransferApproved() {
        var data = await fetchData({
            requestingPage: "stockTransferList",
            method: "post",
            url: "store-fetch/stock-transfer-approved-list",
            headers: { token: isLogged.accessToken,module: "Stock Transfer" },
            data:{plantId}
        });
        if (data.msg === "success") {

            var data2 = [];
            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setTransferApproved(data2);
            if (data.asset.length > 0) setNoApprovedRecords(false);
            else setNoApprovedRecords(true);
        }
        setLoading(false);
    }

    useEffect(async () => {
        setLoading(true);
        await checkToken();

        switch (storeInfo.transferTab) {
            case "transferList":
                setTransferUnapproved([]);
                getTransferUnapproved();
                break;

            case "approvedTransferList":
                setTransferApproved([]);
                getTransferApproved();
                break;

            default:
                break;
        }
    }, [storeInfo.transferTab,plantId]);

    const setSelectedTab = (tab) => {
        setTransferTab(tab);
    };
    const tablist = [
        { id: "transferList", name: "Stock Transfer List" },
        { id: "approvedTransferList", name: "Approved Stock Transfer List" },
    ];
    const access = isLogged.access["Stock Transfer"];
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={storeInfo.transferTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "transferUnapproved":
                listCopy = transferUnapproved.slice();
                break;

            case "transferApproved":
                listCopy = transferApproved.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "transferUnapproved":
                setTransferUnapproved(listCopy);
                break;

            case "transferApproved":
                setTransferApproved(listCopy);
                break;
        }
    };
    const [order, setOrder] = useState({ col: "transferNo", state: true, data: "" });
    const CommonColumns = ({ data }) => {
        return (
            <tr className="vendorListHeader" style={{ zIndex: "1" }}>
                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                    setOrder({
                        col: "transferNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "transferNo",
                        state: order.state,
                    });
                }}
                >Transfer No<img
                        className="sortArrow"
                        src={
                            order.col === "transferNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "transferDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "transferDate",
                        state: order.state,
                    });
                }}
                >Transfer Date <img
                        className="sortArrow"
                        src={
                            order.col === "transferDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "fromStorage",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "fromStorage",
                        state: order.state,
                    });
                }}
                >From Storage<img
                        className="sortArrow"
                        src={
                            order.col === "from"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "toStorage",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "toStorage",
                        state: order.state,
                    });
                }}
                >To Storage <img
                        className="sortArrow"
                        src={
                            order.col === "toStorage"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                    });
                }}
                >Created By <img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                {storeInfo.transferTab === 'approvedTransferList' ? <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "approvedBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "approvedBy",
                        state: order.state,
                    });
                }}
                >Approved By<img
                        className="sortArrow"
                        src={
                            order.col === "approvedBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td> : ''}
                <td className="vendorListHeadingActions">Actions</td>
            </tr>
        )
    };
    const renderSubSection = () => {
        return (
            <React.Fragment>
                <table className="vendorListTable">
                    <thead>
                        <CommonColumns data={storeInfo.transferTab === 'transferList' ? transferUnApprovedCurrentPage : transferApprovedCurrentPage} />
                    </thead>
                    <tbody>
                        {storeInfo.transferTab === "transferList"
                            ? transferUnapproved.length > 0
                                ? renderTransferUnapproved(transferUnapproved)
                                : null
                            : null}

                        {storeInfo.transferTab === "approvedTransferList"
                            ? transferApproved.length > 0
                                ? renderTransferApproved(transferApproved)
                                : null
                            : null}
                    </tbody>
                </table>
                {noUnapprovedRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
                {storeInfo.transferTab === "transferList"
                            ? transferUnapproved.length > 0
                                ? <Pagination 
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={transferUnapproved.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                               />
                                : null
                            : null}

                        {storeInfo.transferTab === "approvedTransferList"
                            ? transferApproved.length > 0
                                ? <Pagination 
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={transferApproved.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                               />
                                : null
                            : null}
            </React.Fragment>
        );
    };

    const renderTransferUnapproved = () => {
        return transferUnApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.soId}
                        onClick={() => editItemCollapse("transferUnapproved", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.transferNo}</td>
                        <td className="vendorListInfo">{item.transferDate}</td>
                        <td className="vendorListInfo">{`${item.fromStorageName} (${item.fromPlantName})`}</td>
                        <td className="vendorListInfo">{`${item.toStorageName} (${item.toPlantName})`}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={item.soStatus === "Draft" ? approveIconDisabled : approveIcon}
                                title={item.soStatus === "Draft" ? "Can't approve Draft" : (access === "App/Dec"?"Approve Record" :"Access Denied")}
                                style={item.soStatus !== "Draft" && access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access === "App/Dec"){
                                    viewTransferId(item.transferId);
                                    setSection("transferDetail");
                                    setApproval(true);}
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" || access === "App/Dec" ? "Edit Item" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access=== "Edit" || access === "App/Dec")
                                    {editTransferId(item.transferId);
                                    setSection("editTransfer");
                                    setEditAndApprove(false); }
                                }}
                                style={access === "Edit" || access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />

                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View QC Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewTransferId(item.transferId);
                                    setSection("transferDetail");
                                    setApproval(false);
                                }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("transferUnapproved", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="unapproved" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderTransferApproved = () => {
        return transferApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.soId}
                        onClick={() => editItemCollapse("transferApproved", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.transferNo}</td>
                        <td className="vendorListInfo">{item.transferDate}</td>
                        <td className="vendorListInfo">{`${item.fromStorageName} (${item.fromPlantName})`}</td>
                        <td className="vendorListInfo">{`${item.toStorageName} (${item.toPlantName})`}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View QC Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewTransferId(item.transferId);
                                    setSection("transferDetail");
                                    setApproval(false);
                                }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("transferApproved", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approved" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab==="approved" ? "7":"6"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item code</td>
                            <td className="vendorListInnerTableHeading">Item</td>
                            {isLogged.showHideWeight ?
                            <><td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td></> :''}
                            <td className="vendorListInnerTableHeading">HSN Code</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.transferLineItemId}>
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    {isLogged.showHideWeight ?
                                    <><td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </> : ''}
                                    <td className="vendorListInnertableInfo">{row.hsnCode  ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                <span className="listPageParams">
                <FormElement  setInput={(value)=>{setPlantId(value);}}
                                        inputType={"options"}
                                        value={plantId}
                                        hintText={"Plant Name"}
                                        options={isLogged.plantOptions}
                                        colSpan={1}
                                        rowSpan={1}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}/>
                </span>
                <span style={{ flexGrow: 0, width: "80px" }}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
            </div>
            <div className="vendorListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        setTransferTab: (transferTab) => dispatch(setTransferTab(transferTab)),
        viewTransferId: (transferId) => dispatch(viewTransferId(transferId)),
        editTransferId: (transferId) => dispatch(editTransferId(transferId)),

        // setQcTab: (tab) => dispatch(setQcTab(tab)),
        // viewQcId: (qcId) => dispatch(viewQcId(qcId)),
        // editQcId: (qcId) => dispatch(editQcId(qcId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockTransferList);
