import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import fetchData from "../../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import editIcon from "../../../assets/edit.svg";
import { editItemCurrencyId } from "../../../redux/Item/ItemInfoActions";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const EditItemCurrency=({isLogged,setSection,itemInfo,editItemCurrencyId,addTokenToState})=>{
    useEffect(() => {
        
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getCurrencyList();
    }

    const history = useHistory();

    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    };
    async function getCurrencyList(){
        var data = await fetchData({
            requestingPage: "createItemType",
            method: "get",
            url: "fetch/item-currency-list",
            headers: { token: isLogged.accessToken,module:"Item Currency" },
        });
       if(data.msg==="success"){
           setItemCurrencyList(data.asset);
           let initialEdit=data.asset.filter((row)=>row.itemCurrencyId==itemInfo.editItemCurrencyId)[0];
           setItemCurrencyCountry(initialEdit.itemCurrencyCountry);
           setItemCurrency(initialEdit.currencyName);
           setCurrencyValue(initialEdit.itemCurrency);
       }else{
           console.log(data);
       }
    }
    const [itemCurrencyList, setItemCurrencyList] = useState([{itemCurrencyId:1,itemCurrencyCountry:"INR",itemCurrency:1}]);
    const [itemCurrencyCountry, setItemCurrencyCountry] = useState("");
    const [itemCurrency,setItemCurrency]=useState("");
    const [currencyValue,setCurrencyValue]=useState("");
    const editItemCurrency=async()=>{
        var dataSent = await fetchData({
            requestingPage: "createItemType",
            method: "put",
            url: "edit/item-currency/"+ itemInfo.editItemCurrencyId,
            headers: { token: isLogged.accessToken,module:"Item Type" },
            data: { itemCurrencyId : itemInfo.editItemCurrencyId,itemCurrencyCountry: itemCurrencyCountry, itemCurrency: itemCurrency,currencyValue:currencyValue },
        });
        if (dataSent.msg === "success") {
            setModalText("Successfully Edited Item Currency!");
            setItemCurrency("");
            setItemCurrencyCountry("");
            getInformation();
            editItemCurrencyId(null);
        } else {
            setModalText(`Upload failed: ${dataSent.desc}`);
        }
        setDataSubmitted(true);
    };
    const renderItemCurrencyList=()=>{
        return itemCurrencyList.map((row)=>{
            return( row.itemCurrencyId !== itemInfo.editItemCurrencyId ? (
                <tr className="roleList" key={row.itemCurrencyId}>
                    <td className="roleName">{row.itemCurrencyCountry}</td>
                    <td className="roleName">{row.currencyName}</td>
                    <td className="roleName">{row.itemCurrency}</td>
                    <td className="roleAction">
                        <img
                            className="roleEditImage"
                            src={editIcon}
                            alt="Edit Currency"
                            onClick={() => {
                                    editItemCurrencyId(row.itemCurrencyId);
                                    setItemCurrencyCountry(row.itemCurrencyCountry);
                                    setItemCurrency(row.currencyName);
                                    setCurrencyValue(row.itemCurrency);
                                    setSection("editItemCurrency");
                            }}/>
                    </td>
                </tr>) :(
                    <tr className="createRole" key={row.itemCurrencyId}>
                    <td>
                    <input
                        className="createRoleInput"
                        value={itemCurrencyCountry}
                        onChange={(e) => setItemCurrencyCountry(e.target.value)}
                        autoFocus/>
                    </td>
                    <td>
                    <input
                        className="createRoleInput"
                        value={itemCurrency}
                        onChange={(e) => setItemCurrency(e.target.value)}/>
                    </td>
                    <td>
                        <input className="createRoleInput"
                        value={currencyValue}
                        onChange={(e)=>setCurrencyValue(e.target.value)}/>
                    </td>
                    <td>
                        <button
                        className="cancelRoleButton"
                        onClick={(e) => {
                            setItemCurrencyCountry("");setItemCurrency("");setCurrencyValue("");
                            editItemCurrency(null); }}>
                        Cancel
                    </button>
                    <button
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {if (itemCurrencyCountry.trim() !== "" && parseFloat(currencyValue) && itemCurrency.trim()!=="") {
                                    setIsOpen(true);
                                    editItemCurrency();}else{
                                        window.alert("Make sure Country is filled and it's Currency Value is Non Negative Decimal");                                 
                                    }}}>
                        Edit
                    </button>
                    </td>
                    <td>&nbsp;</td>
                </tr>
                )
            );
        });
    };

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    return (
        <div className="rolePagesContainer">
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="roleListArea">
                <table className="roleListTable">
                    <thead>
                    <tr className=" roleListHeader">
                        <td className="roleNameHeader">
                            <span>Country Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Currency Name</span>
                        </td>
                        <td className="roleNameHeader">
                            <span>Currency Value</span>
                        </td>
                        <td className="roleActionHeader">
                            <span>Action</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderItemCurrencyList()}
                    </tbody>
                </table>
            </div>
        </div>
    );
    
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editItemCurrencyId: (itemCurrencyId) => dispatch(editItemCurrencyId(itemCurrencyId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItemCurrency);