import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";


const IdnPendingDetail = ({ isLogged, storeInfo, addTokenToState }) => {
    const [MrsDetail, setMrsDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getMrsDetail();
    }

    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
         
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getMrsDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "mrs-for-idn/request-detail/" + storeInfo.viewIdnPendingId,
            headers: { token: isLogged.accessToken ,module:"Pending MRS"},
        });
        if (data.msg === "success") {
            setMrsDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            console.log("Error in fetching data",data);
        }
    }

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td className="stickyFirstColumn">Item</td>
                        <td>Item Code</td>
                        {isLogged.showHideWeihght ?
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td></> : ''}
                        <td>UOM</td>
                        <td>MRS Quantity</td>
                        <td>IDN Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={ lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };
    const lineItemCols= isLogged.showHideWeight ? ["itemName", "itemCode", "from", "to", "netWeight", "grossWeight", "uom", "quantity", "outwardQuantity"] :  ["itemName", "itemCode","uom", "quantity", "outwardQuantity"];;
    const RenderTableRows = ({ rows }) => {
      
        return  rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemCols.map((key, i) => {
                        return <td key={i} className={key==='itemName' ? 'stickyFirstColumn' : ''}>{row[key]? row[key]: " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const renderSummaryInfo = () => {
        if (MrsDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {MrsDetail.requestNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                MrsDetail.returnStatus === "Declined"
                                    ? { color: "red" }
                                    : MrsDetail.returnStatus === "Approved"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }
                        >
                            ( {MrsDetail.returnStatus} )
                        </span>
                        </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">MRS No</span>
                        <span className="vendorSummaryValue">: {MrsDetail.requestNo}</span>
                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {MrsDetail.orderDate}</span>
                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {MrsDetail.expectDate}</span>
                        <span className="vendorSummaryCategory">Created By</span>
                        <span className="vendorSummaryValue">: {MrsDetail.createdBy}</span>
                        <span className="vendorSummaryCategory">Approved Date</span>
                        <span className="vendorSummaryValue">: {MrsDetail.approvedDate}</span>
                        <span className="vendorSummaryCategory">IDN Status</span>
                        <span className="vendorSummaryValue" style={
                                MrsDetail.idnStatus === "Pending"
                                    ? { color: "red" }
                                    : MrsDetail.idnStatus === "Completed"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }>: {MrsDetail.idnStatus}</span>
                    </div>

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>
                    <div className="mrsNetTotalSurchargeDiv">
                        <div className="MRSInstructions">
                            <b> MRS Instructions</b> : {MrsDetail.instruction !== "" ? MrsDetail.instruction : "NA"}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    return (
        <React.Fragment>
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="MrsSummaryText">{renderSummaryInfo()}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdnPendingDetail);
