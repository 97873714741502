import React, { useEffect, useState } from "react";
import MenuList from "../Menu/MenuList";
import TopBanner from "../SmallComponents/TopBanner";
import PageTitle from "../SmallComponents/PageTitle";
import IdnCreate from "./IdnCreate";
import IdnDetail from "./IdnDetail";
import IdnList from "./IdnList";
import IdnEdit from "./IdnEdit";
import IDNIcon from "../../assets/idn.svg";
import { pendingIdn } from "../../redux/Store/StoreInfoActions";
import { connect } from "react-redux";


const Idn = ({storeInfo,pendingIdn,isLogged}) => {
    const [currentSection, setCurrentSection] = useState("IdnList");
    const [listForCreate,SetListForCreate]=useState([]);

    useEffect(() => {
        if ( storeInfo.mrsForIdn !== null) {
            setCurrentSection("IdnCreate");
        }
    }, []);


    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "IdnList",callback: ()=>{pendingIdn(null)} }];
    var Idn ={buttonName: "Create IDN", className: "employeeButton", setSection: setCurrentSection, sectionName: "IdnCreate", callback:()=>{pendingIdn(null)}}

    const renderSection = (section) => {
        switch (section) {
            case "IdnList":
                return (
                    <React.Fragment>
                         {/* <div className="pageTitle">
                         <img className="pageTitleImage" alt="Page Title image" src={IDNIcon}></img>
                            <span className="pageTitleText">IDN List</span>
                            <div className="buttonList">
                                <button
                                    className="employeeButton"
                                    onClick={() => {
                                        setCurrentSection("IdnCreate");
                                         pendingIdn(null);}}>                               
                                    Create IDN
                                </button>
                            </div>
                         </div> */}
                         <PageTitle imgSrc={IDNIcon} pageTitle={"IDN List"} 
                                    buttonList={isLogged.access["IDN"] === "Create" || isLogged.access["IDN"] ==="Edit"?
                                        [Idn] : null}/>
                        <IdnList setSection={(value) => setCurrentSection(value)} setList={(val)=>SetListForCreate(val)}/>
                    </React.Fragment>
                );

            case "IdnDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={IDNIcon} pageTitle={"IDN Id Detail"} buttonList={goBackButton} />
                        <IdnDetail setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "IdnCreate":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={IDNIcon} pageTitle={"Create IDN"} buttonList={goBackButton}  />
                        <IdnCreate setSection={(value) => setCurrentSection(value)} opList={listForCreate}/>
                    </React.Fragment>
                );
            case "IdnEdit":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={IDNIcon} pageTitle={"Edit IDN"} buttonList={goBackButton}/>
                        <IdnEdit setSection={(value)=> setCurrentSection(value)}/>
                    </React.Fragment>
                )
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="IDN" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { storeInfo: state.storeInfo,
            isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
    return { pendingIdn: () => dispatch(pendingIdn(null)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Idn);
