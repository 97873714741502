import React, { useState } from "react";
import MenuList from "../Menu/MenuList";

import inwardIcon from "../../assets/inward.svg";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import CreateInward from "./CreateInward";
import InwardList from "./InwardList";
import InwardDetail from "./InwardDetail";
import EditInward from "./EditInward";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const Inward = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("inwardList");

    const [approvalPage, setApprovalPage] = useState(false);
    const [editAndApprove, setEditAndApprove] = useState(false);

    var createInward = [{ buttonName: "Create Inward", className: "employeeButton", setSection: setCurrentSection, sectionName: "createInward" }];
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "inwardList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "inwardDetail") }, sectionName: "inwardList" };

    const renderSection = (section) => {
        switch (section) {
            case "createInward":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={inwardIcon} pageTitle="Create Inward" buttonList={[goBackButton]} />
                        <CreateInward setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "inwardList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={inwardIcon} pageTitle="Inward List"
                            buttonList={isLogged.access["Inward"] === "Create" || isLogged.access["Inward"] === "Edit" || isLogged.access["Inward"] === "App/Dec" ? createInward : null} />
                        <InwardList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={(value) => setApprovalPage(value)}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "inwardDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={inwardIcon} pageTitle="Inward Detail" buttonList={[goBackButton, downloadButton]} />
                        <InwardDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                        />
                    </React.Fragment>
                );

            case "editInward":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={inwardIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Inward" : "Edit Inward"}
                            buttonList={[goBackButton]}
                        />
                        <EditInward setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Inward" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged }
}
export default connect(mapStateToProps, null)(Inward);
