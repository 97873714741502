import React, { useEffect, useState } from "react";
import FormElement from "../../SmallComponents/FormElement";
import update from "immutability-helper";
import { useHistory } from "react-router";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const EditItemPurchase = ({ setSection, addTokenToState, isLogged, itemInfo }) => {
    const purchaseParams = ["itemMainCategory", "subCategoryId", "brandId", "costApplicableDate"];

    const [itemPurchaseCost, setItemPurchaseCost] = useState({
        itemMainCategory: {
            inputType: "options",
            value: "",
            hintText: "Item Main Category",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select item main category",
            disabled: true,
        },
        subCategoryId: {
            inputType: "options",
            value: "",
            hintText: "Item Sub Category",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Select a sub category",
            disabled: true,
        },
        brandId: {
            inputType: "options",
            value: "",
            hintText: "Brand Name",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Please select brand name",
            disabled: true,
        },
        costApplicableDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Cost Applicable Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please pick a date",
            mindate: true,
        },
    });


    const [purchaseCostList, setPurchaseCostList] = useState([]);

    const [costList, setCostList] = useState([]);

    const [taxApplicableDate, setTaxApplicableDate] = useState("");

    const taxOptions = [
        { value: "Taxable", label: "Taxable" },
        { value: "Nil-Tax", label: "Nil-Tax" },
        { value: "Exempt", label: "Exempt" },
    ];

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getItems({
            subCategoryId: itemPurchaseCost.subCategoryId.value,
            brandId: itemPurchaseCost.brandId.value,
            costApplicableDate: itemPurchaseCost.costApplicableDate.value,
            taxApplicableDate: taxApplicableDate,
        });
    }, [taxApplicableDate]);

    async function getInformation() {

        await checkToken();
        var purchaseCostItems = await getItems(itemInfo.editItemPurchaseCostObj);

        var categoryList = await getCategory();
        var subCategoryList = await getSubCategory(purchaseCostItems[0].categoryId);
        var brandList = await getBrandNames(purchaseCostItems[0].subCategoryId);

        var purchaseCostParamsCopy = Object.assign(itemPurchaseCost);
        purchaseCostParamsCopy = update(purchaseCostParamsCopy, {
            itemMainCategory: { options: { $set: categoryList }, value: { $set: purchaseCostItems[0].categoryId } },
            subCategoryId: { options: { $set: subCategoryList }, value: { $set: purchaseCostItems[0].subCategoryId } },
            brandId: { options: { $set: brandList }, value: { $set: purchaseCostItems[0].brandId } },
            costApplicableDate: { value: { $set: purchaseCostItems[0].costApplicableDate } },
        });

        setItemPurchaseCost(purchaseCostParamsCopy);
    }

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        }
        return true;
    }

    async function getCategory() {
        var categoryData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/item-category-list",
            headers: { token: isLogged.accessToken,module:"Item Purchase Cost" },
        });

        var categoryList = [];

        if (categoryData.msg === "success") {
            categoryData.asset.forEach((categ) => {
                var catObj = {};
                catObj.optionId = categ.categoryId;
                catObj.optionName = categ.categoryName;
                categoryList.push(catObj);
            });
            return categoryList;
        }
    }

    async function getSubCategory(id) {
        if (id !== "") {
            var subCategoryData = await fetchData({
                requestingPage: "getCategory",
                method: "get",
                url: `fetch/item-subcategory-list/categoryid=${id}`,
                headers: { token: isLogged.accessToken,module:"Item Purchase Cost" },
            });

            var subCategoryList = [];

            if (subCategoryData.msg === "success") {
                subCategoryData.asset.forEach((subCateg) => {
                    var catObj = {};
                    catObj.optionId = subCateg.subCategoryId;
                    catObj.optionName = subCateg.subCategoryName;
                    subCategoryList.push(catObj);
                });

                var newParam = update(itemPurchaseCost, { subCategoryId: { options: { $set: subCategoryList } } });
                setItemPurchaseCost(newParam);
                // return categoryList;
                return subCategoryList;
            }
        }
    }

    async function getBrandNames(id) {
        if (id !== "") {
            var brandData = await fetchData({
                requestingPage: "getCategory",
                method: "get",
                url: `fetch/item-brand-list/subcategoryid=${id}`,
                headers: { token: isLogged.accessToken,module:"Item Purchase Cost" },
            });

            var brandList = [];

            if (brandData.msg === "success") {
                brandData.asset.forEach((brand) => {
                    var brandObj = {};
                    brandObj.optionId = brand.brandId;
                    brandObj.optionName = brand.brandName;
                    brandList.push(brandObj);
                });
                var newParam = update(itemPurchaseCost, { brandId: { options: { $set: brandList } } });
                setItemPurchaseCost(newParam);
                // return categoryList;

                return brandList;
            }
        }
    }

    async function getItems(itemInfoObj) {
        var itemsData = await fetchData({
            requestingPage: "getItemPurchaseCostPre",
            method: "post",
            url: `fetch/item-purchase-cost-pre-edit`,
            headers: { token: isLogged.accessToken, "Content-Type": "application/json",module:"Item Purchase Cost" },
            data: itemInfoObj,
        });

        if (itemsData.msg === "success") {
            var costTaxList = [];
            itemsData.asset.forEach((item) => {
                var costTax = {
                    cost: item.cost,
                    costError: false,
                    costErrorMessage: "Enter a valid cost for item",

                    taxType: item.taxType,
                    taxPercentage: item.taxPercentage,

                    itemId: item.itemId,
                    itemName: item.itemName,
                    // netRate: "",
                    costApplicableDate: item.costApplicableDate,
                    toSend: false,
                    purchaseCostId: item.purchaseCostId,
                };
                costTaxList.push(costTax);
            });

            setCostList(costTaxList);
            setPurchaseCostList(itemsData.asset);

            return itemsData.asset;
        }
    }

    const updateItemParameter = ({ paramName, key, value }) => {
        var itemParams = Object.assign(itemPurchaseCost);
        if (paramName === "costApplicableDate") {
            setTaxApplicableDate(value);
        } else {
            itemParams = update(itemParams, { [paramName]: { [key]: { $set: value } } });
        }

        switch (paramName) {
            case "itemMainCategory":
                itemParams = update(itemParams, {
                    subCategoryId: { value: { $set: "" }, options: { $set: [] } },
                    brandId: { value: { $set: "" }, options: { $set: [] } },
                });
                setPurchaseCostList([]);
                break;

            case "subCategoryId":
                itemParams = update(itemParams, { brandId: { value: { $set: "" }, options: { $set: [] } } });
                setPurchaseCostList([]);
                break;

            case "brandId":
                // setPurchaseCostList;
                break;

            default:
                break;
        }
        setItemPurchaseCost(itemParams);
    };

    const updateCostList = (param, index, value) => {
        var updatedList = costList.slice();
        if (param === "taxType" && value === undefined) {
            updatedList = update(updatedList, { [index]: { [param]: { $set: "" } } });
        } else {
            updatedList = update(updatedList, { [index]: { [param]: { $set: value } } });
        }

        if (param === "taxType") {
            if (value !== 0) {
                updatedList = update(updatedList, { [index]: { taxPercentage: { $set: "0" } } });
            }
        }

        setCostList(updatedList);
    };

    const [purchaseCostError, setPurchaseCostError] = useState([]);

    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(itemPurchaseCost);
        ["itemMainCategory", "subCategoryId", "brandId", "costApplicableDate"].forEach((field) => {
            if (paramsCopy[field].mandatory) {
                paramsCopy = update(paramsCopy, { [field]: { error: { $set: !validateMandatory(paramsCopy[field].value.toString()) } } });
            }

            if (paramsCopy[field].error) {
                errorList.push(paramsCopy[field].errorMessage);
            }
        });

        var costListCopy = costList.slice();

        setItemPurchaseCost(paramsCopy);

        costList.forEach((cost, i) => {
            costListCopy[i].costError = false;
            costListCopy[i].toSend = false;

            var proceed = true;

            // scenario 0
            if (isNaN(cost.cost)) {
                costListCopy[i].costError = true;
                proceed = false;
            }

            if (parseFloat(cost.cost) < 0) {
                costListCopy[i].costError = true;
                proceed = false;
            }

            if (proceed) {
                if (parseFloat(cost.cost) === "") {
                    //  console.log(costListCopy[i], "Do Nothing");
                }

                // 2 --------- scenario 5 & 9
                if (parseFloat(cost.cost) >= 0) {
                    // Can send this record to server
                    costListCopy[i].toSend = true;
                }
            }

            setCostList(costListCopy);

            if (cost.costError) errorList.push(`${cost.costErrorMessage} ${cost.itemName}`);
            setPurchaseCostError(errorList);
        });

        if (errorList.length === 0) {
            var shortlistedArray = [];
            costListCopy.forEach((item, i) => {
                if (item.toSend) {
                    item.costApplicableDate = taxApplicableDate !== "" ? taxApplicableDate : item.costApplicableDate;
                    delete item.costError;
                    delete item.costErrorMessage;
                    delete item.itemName;
                    delete item.toSend;
                    delete item.taxType;
                    delete item.taxPercentage;
                    shortlistedArray.push(item);
                }
            });
            if (shortlistedArray.length > 0) {

                var arrayToServer = [];
                shortlistedArray.forEach((newTaxObj, j) => {
                    var compareToObj = purchaseCostList.filter((compTo) => compTo.purchaseCostId === newTaxObj.purchaseCostId)[0];
                    if (
                        newTaxObj.costApplicableDate !== compareToObj.costApplicableDate ||
                        parseFloat(newTaxObj.cost) !== parseFloat(compareToObj.cost)
                    ) {
                        arrayToServer.push(newTaxObj);
                    } else {
                    }
                });
                if (arrayToServer.length > 0) {
                    prepareDataToSubmit(arrayToServer);
                } else {
                    setDataSubmitted(true);
                    setIsOpen(true);
                    setModalText("No change has been detected.");
                }
            }
        }
    };

    const prepareDataToSubmit = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createItemPurchaseCost",
            method: "put",
            url: "edit/item-purchase-cost",
            headers: { token: isLogged.accessToken,module:"Item Purchase Cost" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully edited Item Purchase Cost!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setPurchaseCostError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const renderFormElements = ({ elementList }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={itemPurchaseCost[element].inputType}
                    value={itemPurchaseCost[element].value}
                    setInput={(value) => {
                        updateItemParameter({ paramName: element, key: "value", value: value });
                    }}
                    hintText={itemPurchaseCost[element].hintText}
                    mandatory={itemPurchaseCost[element].mandatory}
                    colSpan={itemPurchaseCost[element].colSpan}
                    options={itemPurchaseCost[element].inputType === "options" ? itemPurchaseCost[element].options : null}
                    error={itemPurchaseCost[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? itemPurchaseCost[element].rowSpan : null}
                    placeholder={itemPurchaseCost[element].placeholder}
                    title={itemPurchaseCost[element].title}
                    mindate={true}
                    disabled={itemPurchaseCost[element].disabled}
                />
            );
        });
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        if (purchaseCostError.length === 0) {
            setSection("itemPurchaseList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const renderErrorMessage = () => {
        if (purchaseCostError.length > 0) return purchaseCostError[0];
        else return null;
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="createItemPurchaseGrid">{renderFormElements({ elementList: purchaseParams })}</div>

                    <br />
                    {purchaseCostList.length > 0 ? (
                        <table className="createItemPurchaseTable">
                            <thead>
                                <tr className="createVendorContactsTableHeader">
                                    <td>Item Code</td>
                                    <td>Item Name</td>
                                    <td>Weight From (gms)</td>
                                    <td>Weight To (gms)</td>
                                    <td>Net Weight (gms)</td>
                                    <td>Gross Weight (gms)</td>
                                    <td>MRP</td>
                                    <td>Cost</td>
                                    <td>Tax Type</td>
                                    <td>Tax Percentage</td>
                                    <td>Net Rate</td>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseCostList.map((row, j) => (
                                    <tr className="createVendorContactsTableRows" key={j}>
                                        {["itemCode", "itemName", "from","to","netWeight","grossWeight","mrp","cost", "taxType", "taxPercentage", "netRate"].map((key, i) => {
                                            switch (key) {
                                                case "cost":
                                                    return (
                                                        <td key={i} style={{ width: "0px" }}>
                                                            <input
                                                                className={
                                                                    costList[j].costError ? "createPurchaseCostInputError" : "createPurchaseCostInput"
                                                                }
                                                                type="text"
                                                                value={costList[j].cost}
                                                                onChange={(e) => updateCostList("cost", j, e.target.value)}
                                                            />
                                                        </td>
                                                    );

                                                case "netRate":
                                                    return (
                                                        <td key={i}>
                                                            {isNaN(
                                                                parseFloat(costList[j].cost) * ((100 + parseFloat(costList[j].taxPercentage)) / 100)
                                                            )
                                                                ? "-"
                                                                : (
                                                                      parseFloat(costList[j].cost) *
                                                                      ((100 + parseFloat(costList[j].taxPercentage)) / 100)
                                                                  ).toFixed(2)}
                                                        </td>
                                                    );

                                                default:
                                                    return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                textAlign: "center",
                                marginTop: "1rem",
                                // backgroundColor: "rgb(241, 241, 241)",
                                padding: "1rem 0",
                                fontSize: "1.5rem",
                                color: "darkgray",
                                fontStyle: "italic",
                            }}
                        >
                            Please select a value for all the above fields
                        </div>
                    )}
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button className="submitButton" onClick={checkErrors}>
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItemPurchase);
