import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import editIcon from "../../../assets/edit.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import { editItemBrandId } from "../../../redux/Item/ItemInfoActions";

const ItemBrandList = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    editItemBrand,
    addTokenToState,
}) => {
    useEffect(() => {
      
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getItemBrand();
    }

    const history = useHistory();
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getItemBrand() {
        var data = await fetchData({ requestingPage: "itemBrandList", method: "get", url: "fetch/brands", headers: { token: isLogged.accessToken,module:"Item Brand" } });
      
        if (data.msg === "success") {
            setItemBrandList(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const [itemBrandList, setItemBrandList] = useState([]);

    const renderBrandList = (list) => {
        return list.map((row) => {
            return (
                <tr className="uomListData" key={row.brandId}>
                    <td className="uomSymbolData">{row.brandName}</td>
                    <td className="uomSymbolData">{row.description}</td>
                    <td className="uomActionData">
                        <img
                            className="uomEditImage"
                            alt="Edit Record"
                            src={isLogged.access["Item Brand"] === "Edit" ? editIcon : editIconDisabled}
                            onClick={() => {
                                if (isLogged.access["Item Brand"] === "Edit") {
                                    editItemBrand(row.brandId);
                                    setSection("editItemBrand");
                                }
                            }}
                            title={isLogged.access["Item Brand"] === "Edit" ? "Edit Record" : "No Edit Access"}
                            style={isLogged.access["Item Brand"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <React.Fragment>
            <div className="uomListArea">
                <table className="uomListTable">
                    <tr className="uomListHeader">
                        <td className="uomSymbolHeader">Brand Name</td>
                        <td className="uomSymbolHeader">Description</td>
                        <td className="uomActionHeader">Action</td>
                    </tr>
                    {itemBrandList.length > 0 ? renderBrandList(itemBrandList) : null}
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editItemBrand: (brandId) => dispatch(editItemBrandId(brandId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemBrandList);
