import React, { useState } from "react";
import MenuList from "../../Menu/MenuList";
import PageTitle from "../../SmallComponents/PageTitle";
import purchaseCostIcon from "../../../assets/cost_black.svg";
import CreatePurchaseCost from "./CreatePurchaseCost";
import TopBanner from "../../SmallComponents/TopBanner";
import ItemPurchaseList from "./ItemPurchaseList";
import { useHistory } from "react-router";
import EditItemPurchase from "./EditPurchaseCost";
import { connect } from "react-redux";

const ItemPurchase = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("itemPurchaseList");

    var history = useHistory();

    const pushBack = (section) => {
        history.push(section);
    };

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "itemPurchaseList" }];

    var goBackToItemButton = {
        buttonName: "Back",
        className: "goBackButton",
        setSection: pushBack,
        sectionName: "/itemMenu",
    };

    var createItemPurchaseCostButton = {
        buttonName: "Create Purchase Cost",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createPurchaseCost",
    };

    const renderSection = (section) => {
        console.log(section);
        switch (section) {
            case "itemPurchaseList":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={purchaseCostIcon}
                            pageTitle="Item Purchase Cost List"
                            buttonList={
                                isLogged.access["Item Purchase Cost"] === "Create" || isLogged.access["Item Purchase Cost"] === "Edit"
                                    ? [createItemPurchaseCostButton, goBackToItemButton]
                                    : [goBackToItemButton]
                            }
                        />
                        <ItemPurchaseList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createPurchaseCost":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseCostIcon} pageTitle="Create Purchase Cost" buttonList={goBackButton} />
                        <CreatePurchaseCost setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editPurchaseCost":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={purchaseCostIcon} pageTitle="Edit Purchase Cost" buttonList={goBackButton} />
                        <EditItemPurchase setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            default:
                return <h1>Page not yet created</h1>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(ItemPurchase);
