import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";

const IdnDetail = ({ isLogged, storeInfo, addTokenToState }) => {
    const [idnDetail, setIdnDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getIdnDetail();
    }

    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
    async function getIdnDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "idn-fetch/request-detail/" + storeInfo.viewIdnId,
            headers: { token: isLogged.accessToken ,module:"IDN"},
        });
        if (data.msg === "success") {
            setIdnDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
        } else {
            // console.log("Error in Fetching Data",data);
        }
    }

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td className="stickyFirstColumn">Item</td>
                        <td>Item Code</td>
                        {isLogged.showHideWeight ?  
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td></> : ''}
                        <td>UOM</td>
                        <td>MRS Quantity</td>
                        <td>IDN Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={ lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };
    const lineItemParams = isLogged.showHideWeight ? ["itemName", "itemCode", "from", "to", "netWeight", "grossWeight", "uom", "mrsQuantity", "idnQuantity"] :  ["itemName", "itemCode", "uom", "Quantity", "idnQuantity"];
    const RenderTableRows = ({ rows }) => {
      
        return  rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i} className={key==='itemName' ? 'stickyFirstColumn' : ''}>{row[key]? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    const renderSummaryInfo = () => {
        if (idnDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {idnDetail.requestNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                idnDetail.idnStatus === "Pending"
                                    ? { color: "red" }
                                    : idnDetail.idnStatus === "Completed"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }
                        >
                            ( {idnDetail.idnStatus} )
                        </span>
                        </span>
                    <div className="vendorSummaryGrid">
                        <span className="vendorSummaryCategory">IDN No</span>
                        <span className="vendorSummaryValue">: {idnDetail.requestNo}</span>
                        <span className="vendorSummaryCategory">IDN Created By</span>
                        <span className="vendorSummaryValue">: {idnDetail.createdBy}</span>
                        <span className="vendorSummaryCategory">IDN Created Date</span>
                        <span className="vendorSummaryValue">: {idnDetail.idnDate}</span>
                        <span className="vendorSummaryCategory">MRS No</span>
                        <span className="vendorSummaryValue">: {idnDetail.mrsNo}</span>
                        <span className="vendorSummaryCategory">IDN Created Date</span>
                        <span className="vendorSummaryValue">: {idnDetail.idnDate}</span>
                        <span className="vendorSummaryCategory">MRS Requested Date</span>
                        <span className="vendorSummaryValue">: {idnDetail.mrsReqDate}</span>
                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {idnDetail.mrsExpectDate}</span>
                        <span className="vendorSummaryCategory">IDN Status</span>
                        <span className="vendorSummaryValue" style={
                                idnDetail.idnStatus === "Pending"
                                    ? { color: "red" }
                                    : idnDetail.idnStatus === "Completed"
                                    ? { color: "#14c76a" }
                                    : { color: "#333333" }
                            }>: {idnDetail.idnStatus}</span>
                       { idnDetail.editedBy ?
                        (<React.Fragment>
                        <span className="vendorSummaryCategory">Edited By</span>
                        <span className="vendorSummaryValue">: {idnDetail.editedBy}</span>
                        <span className="vendorSummaryCategory">Edited Date</span>
                        <span className="vendorSummaryValue">: {idnDetail.editedDate}</span>
                        </React.Fragment>
                       ):null}
                    </div>
                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>
                    <div className="idnNetTotalSurchargeDiv">
                        <div className="IDNInstructions">
                            <b> IDN Instructions</b> : {idnDetail.instruction !== "" ? idnDetail.instruction : "NA"}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };
    return (
        <React.Fragment>
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="IdnSummaryText">{renderSummaryInfo()}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdnDetail);
