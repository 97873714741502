import update from "immutability-helper";

const poObj = {
    viewPoId: null,
    editPoId: null,

    viewPoReturnId: null,
    editPoReturnId: null,

    poInvoiceCreateId: null,
    poViewInvoiceId: null,
    poEditInvoiceId: null,

    poTab: "poList",
    poInvoiceTab: "purchaseOrderList",

    viewPoImportId: null,
    editPoImportId: null,

    importPurchaseOrderViewId: null,
    importPurchaseOrderEditId: null,

    viewSoId: null,
    editSoId: null,

    viewSoReturnId: null,
    editSoReturnId: null,

    soInvoiceCreateId: null,
    soViewInvoiceId: null,
    soEditInvoiceId: null,

    soTab: "soList",
    soInvoiceTab: "salesOrderList",

    pendingSoProduction: null,
    // pendingIdn: null,

    productionOrderTab: "productionOrderList",
    viewProductionOrderId: null,
    editProductionOrderId: null,

    materialRequisitionTab: "materialRequisitionList",
    viewMaterialRequisitionId: null,
    editMaterialRequisitionId: null,

    dcListTab: "dcUnApprovedList",
    viewDcId: null,
    editDcId: null,

    viewDcReturnId : null,
    editDcReturnId: null,

    viewCusRecId: null,
    editCusRecId: null,

    viewVenPaymentId: null,
    editVenPaymentId: null,

    viewBomId: null,
    editBomId: null,

    editWorkOrderId: null,
    viewWorkOrderId: null,

    processLogId: null,
    importReportParams: null,

    productionReportParams: null,
    stockReportParams: null,

    viewProcessLogId:null,
    employeeId:null,
    employeeEfficiencyItemId:null,

    vendorLedgerParams: null,
    vendorInvoiceParams: null,
};

const PoInfo = (state = poObj, action) => {
    switch (action.type) {
        // ===================================================================================================================
        //                                                   Purchase
        // ===================================================================================================================

        // ======================= PO =======================
        case "VIEW_PO_ID":
            return update(state, { viewPoId: { $set: action.payload.viewPoId } });

        case "EDIT_PO_ID":
            return update(state, { editPoId: { $set: action.payload.editPoId } });

        case "VIEW_PO_RETURN_ID":
            return update(state, { viewPoReturnId: { $set: action.payload.returnId } });

        case "EDIT_PO_RETURN_ID":
            return update(state, { editPoReturnId: { $set: action.payload.returnId } });

        // ======================= PO Invoice =======================
        case "PO_INVOICE_ID":
            return update(state, { poInvoiceCreateId: { $set: action.payload.poInvoiceCreateId } });

        case "PO_VIEW_INVOICE_ID":
            return update(state, { poViewInvoiceId: { $set: action.payload.poViewInvoiceId } });

        case "PO_EDIT_INVOICE_ID":
            return update(state, { poEditInvoiceId: { $set: action.payload.poEditInvoiceId } });
        // =======================PO Vendor Payment================
        case "VIEW_VEN_PAYMENT_ID":
            return update(state, { viewVenPaymentId: {$set: action.payload.venPayId} });

        case "EDIT_VEN_PAYMENT_ID":
            return update(state, { editVenPaymentId: {$set: action.payload.venPayId} });
        // ======================= PO Tabs =======================
        case "SET_PO_TAB":
            return update(state, { poTab: { $set: action.payload.poTab } });

        case "SET_PO_INVOICE_TAB":
            return update(state, { poInvoiceTab: { $set: action.payload.poInvoiceTab } });

        // ======================= PO Inward =======================
        case "VIEW_PO_IMPORT_ID":
            return update(state, { viewPoImportId: { $set: action.payload.viewPoImportId } });

        case "EDIT_PO_IMPORT_ID":
            return update(state, { editPoImportId: { $set: action.payload.editPoImportId } });

        // ======================= Import Po =======================
        case "IMPORT_PURCHASE_ORDER_VIEW_ID":
            return update(state, { importPurchaseOrderViewId: { $set: action.payload.importPurchaseOrderViewId } });

        case "IMPORT_PURCHASE_ORDER_EDIT_ID":
            return update(state, { importPurchaseOrderEditId: { $set: action.payload.importPurchaseOrderEditId } });

        // ===================================================================================================================
        //                                                   Sales
        // ===================================================================================================================

        // ======================= SO =======================
        case "VIEW_SO_ID":
            return update(state, { viewSoId: { $set: action.payload.viewSoId } });

        case "EDIT_SO_ID":
            return update(state, { editSoId: { $set: action.payload.editSoId } });

        case "VIEW_SO_RETURN_ID":
            return update(state, { viewSoReturnId: { $set: action.payload.viewSoReturnId } });

        case "EDIT_SO_RETURN_ID":
            return update(state, { editSoReturnId: { $set: action.payload.editSoReturnId } });

        // ======================= SO Invoice =======================
        case "SO_INVOICE_ID":
            return update(state, { soInvoiceCreateId: { $set: action.payload.soInvoiceCreateId } });

        case "SO_VIEW_INVOICE_ID":
            return update(state, { soViewInvoiceId: { $set: action.payload.soViewInvoiceId } });

        case "SO_EDIT_INVOICE_ID":
            return update(state, { soEditInvoiceId: { $set: action.payload.soEditInvoiceId } });

        // ======================= So Tabs =======================
        case "SET_SO_TAB":
            return update(state, { soTab: { $set: action.payload.soTab } });

        case "SET_SO_INVOICE_TAB":
            return update(state, { soInvoiceTab: { $set: action.payload.soInvoiceTab } });
        //========================Customer Receipt===================
        case "VIEW_CUS_RECEIPT_ID":
            return update(state, { viewCusRecId: {$set: action.payload.cusRecId} });

        case "EDIT_CUS_RECEIPT_ID":
            return update(state, { editCusRecId: {$set: action.payload.cusRecId} });

        // ======================= Production Order for Pending So =======================
        case "PENDING_SO_PRODUCTION":
            return update(state, { pendingSoProduction: { $set: action.payload.pendingSoId } });
        //======================== Pending MRS============================================
        // case "PENDING_IDN":
        //     return update(state, { pendingIdn: { $set: action.payload.pendingIdn } });

        // ======================= Production Order Tabs =======================

        case "PRODUCTION_ORDER_TAB":
            return update(state, { productionOrderTab: { $set: action.payload.productionOrderTab } });

        case "VIEW_PRODUCTION_ORDER_ID":
            return update(state, { viewProductionOrderId: { $set: action.payload.productionId } });

        case "EDIT_PRODUCTION_ORDER_ID":
            return update(state, { editProductionOrderId: { $set: action.payload.productionId } });

        //============================ Material Requisition Tabs =========================
        case "MATERIAL_REQUISITION_TAB" :
            return update(state, { materialRequisitionTab: {$set: action.payload.materialRequisitionTab} });
        
        case "VIEW_MATERIAL_REQUISITION_ID":
            return update(state,{ viewMaterialRequisitionId: {$set: action.payload.materialRequisitionId } });

        case "EDIT_MATERIAL_REQUISITION_ID":
            return update(state, {editMaterialRequisitionId: {$set: action.payload.materialRequisitionId } });

        //============================== Dc List Tabs======================================
        case "DC_LIST_TAB":
            return update(state, { dcListTab: { $set: action.payload.dcTab } });

        case "VIEW_DC_ID":
            return update(state,{ viewDcId: { $set: action.payload.dcId} });

        case "EDIT_DC_ID":
            return update(state, { editDcId:{ $set:action.payload.dcId} });

        case "VIEW_DC_RETURN_ID":
                return update(state,{ viewDcReturnId: { $set: action.payload.dcReturnId} });
    
        case "EDIT_DC_RETURN_ID":
                return update(state, { editDcReturnId:{ $set:action.payload.dcReturnId} });

        case "EDIT_BOM_ID":
            return update(state, { editBomId: {$set: action.payload.bomId} });

        case "VIEW_BOM_ID":
                return update(state, { viewBomId: {$set: action.payload.bomId} });

        case "EDIT_WORK_ORDER_ID":
            return update(state, {editWorkOrderId: {$set: action.payload.workOrderId} });

        case "VIEW_WORK_ORDER_ID":
            return update (state,{viewWorkOrderId: {$set: action.payload.workOrderId} });

        case "PROCESS_LOG_ID":
            return update (state,{processLogId: {$set: action.payload.processLogId} });
            
        case "IMPORT_REPORT_PARAMS":
            return update(state,{importReportParams:{$set: action.payload.importReportParams} });

        case "STOCK_REPORT_PARAMS":
            return update(state,{stockReportParams:{$set: action.payload.stockReportParams} });

        case "PRODUCTION_REPORT_PARAMS":
            return update(state,{productionReportParams: {$set: action.payload.productionReportParams} });

        case "VIEW_PROCESS_LOG_ID":
            return update(state,{viewProcessLogId:{$set:action.payload.viewProcessLogId}})
    
        case "EMPLOYEE_ID":
            return update(state,{employeeId:{$set:action.payload.employeeId}})
            
        case "EMPLOYEE_EFFICIENCY_ITEM_ID":
                return update(state,{employeeEfficiencyItemId:{$set:action.payload.employeeEfficiencyItemId}})

        case "VENDOR_LEDGER_PARAMS":
                return update(state,{vendorLedgerParams:{$set:action.payload.vendorLedgerParams}})

        case "VENDOR_INVOICE_PARAMS":
                return update(state,{vendorInvoiceParams:{$set:action.payload.vendorInvoiceParams}})
            
        default:
            return state;
    }
};

export default PoInfo;
