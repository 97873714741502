import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import Pagination from "../../CommonFunctions/pagination";
import editIcon from "../../../assets/edit.svg";
import downIcon from "../../../assets/down.svg";
import upIcon from "../../../assets/up.svg";
import editIconDisabled from "../../../assets/edit_disabled.svg";
import { editItemTaxObj } from "../../../redux/Item/ItemInfoActions";
import SortUpGreen from "../../../assets/SortUpGreen.svg";
import SortDownGreen from "../../../assets/SortDownGreen.svg";
import { handleSort } from "../../CommonFunctions/utils";
import FormElement from "../../SmallComponents/FormElement";
const ItemTaxList = ({ setSection, isLogged, addTokenToState, editTaxObj }) => {
    const history = useHistory();
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    const [taxList, setTaxList] = useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage] =useState(10);
    const taxListCurrentPage = useMemo(() => {
     
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return taxList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,taxList,PageSize]);
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getTaxList();
    }

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getTaxList() {
        var data = await fetchData({
            requestingPage: "taxList",
            method: "post",
            url: "fetch/item-tax-list",
            headers: { token: isLogged.accessToken,module:"Item Tax" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setTaxList(data2);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const editTaxCollapse = (index) => {

        var taxListCopy = taxList.slice();
        taxListCopy.forEach((purchase, j) => {
            if (j === index) {
                taxListCopy[j].collapse = !purchase.collapse;
            } else {
                taxListCopy[j].collapse = true;
            }
        });
        setTaxList(taxListCopy);
    };
    const [order, setOrder] = useState({ col: "taxApplicableDate", state: true, data: "" });

    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="7" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading">Item Code</td>
                            <td className="vendorListInnerTableHeading">Item Name</td>
                            <td className="vendorListInnerTableHeading">Weight From (gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To (gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight (gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight (gms)</td>
                            <td className="vendorListInnerTableHeading">Tax Type</td>
                            <td className="vendorListInnerTableHeading">Tax Percentage (%)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.itemCode}>
                                    <td className="vendorListInnertableInfo">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    <td className="vendorListInnertableInfo">{row.from? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight? row.netWeight: " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight? row.grossWeight: " - "}</td>
                                    {/* <td className="vendorListInnertableInfo">{row.mrp}</td> */}
                                    {/* <td className="vendorListInnertableInfo">{row.cost}</td> */}
                                    <td className="vendorListInnertableInfo">{row.taxType}</td>
                                    <td className="vendorListInnertableInfo">{row.taxPercentage}</td>
                                    {/* <td className="vendorListInnertableInfo">{row.netRate}</td> */}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const rendorTaxCostList = () => {
        return taxListCurrentPage.map((tax, i) => {
            var oldDate = new Date(tax.taxApplicableDate);
            var now = new Date();

            var editable = oldDate.setHours(0, 0, 0, 0) >= now.setHours(0, 0, 0, 0);
            return (
                <React.Fragment>
                    <tr className={tax.collapse ? "vendorList" : "vendorListExpanded"} key={tax.i} onClick={() => editTaxCollapse(i)}>
                        <td className="vendorListInfo">{tax.taxApplicableDate}</td>  
                        <td className="vendorListInfo">{tax.categoryName}</td>
                        <td className="vendorListInfo">{tax.items[0].subCategoryName}</td>
                        <td className="vendorListAddress">{tax.brandName}</td>
                        <td className="vendorListInfo">
                            {tax.items[0].updatedTime
                                ? new Date(tax.items[0].updatedTime).toISOString().slice(0, 10)
                                : new Date(tax.items[0].createTime).toISOString().slice(0, 10)}
                        </td>
                        <td className="vendorListInfo">{tax.items[0].updatedBy ? tax.items[0].updatedBy : tax.items[0].createdBy}</td>
                        <td className="vendorListInfoActions">
                            {/* <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View tax Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // viewVendorId(tax.vendorId);
                                    setSection("vendorDetail");
                                }}
                            /> */}
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={editable ? (isLogged.access["Item Tax"] === "Edit" ? editIcon : editIconDisabled) : editIconDisabled}
                                title={
                                    editable
                                        ? isLogged.access["Item Tax"] === "Edit"
                                            ? "Edit purchase cost"
                                            : "No Edit Access"
                                        : "Can't edit older records"
                                }
                                style={
                                    editable
                                        ? isLogged.access["Item Tax"] === "Edit"
                                            ? { cursor: "pointer" }
                                            : { cursor: "not-allowed" }
                                        : { cursor: "not-allowed" }
                                }
                                onClick={(e) => {
                                    if (editable) {
                                        if (isLogged.access["Item Tax"] === "Edit") {
                                            e.stopPropagation();
                                            // editVendorId(tax.vendorId);
                                            setSection("editItemTax");
                                            editTaxObj({
                                                taxApplicableDate: tax.taxApplicableDate,
                                                categoryId: tax.categoryId,
                                                subCategoryId: tax.subCategoryId,
                                                brandId: tax.brandId,
                                            });
                                        }
                                    }
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={tax.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editTaxCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!tax.collapse ? <RenderSubTable data={tax.items} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            <span style={{flexGrow:0, width:"80px",marginLeft:"25px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
            <div className="vendorListArea">
                <table className="vendorListTable">
                    <thead>
                        <tr className="vendorListHeader">
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "taxApplicableDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: taxListCurrentPage,
                        col: "taxApplicableDate",
                        state: order.state,
                      });
                    }}>Tax Applicable Date
                    <img
                          className="sortArrow"
                          src={
                               order.col === "taxApplicableDate"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "categoryName",
                        state: !order.state,
                      });
                      handleSort({
                        data: taxListCurrentPage,
                        col: "categoryName",
                        state: order.state,
                      });
                    }}>Item Main Category
                    <img
                          className="sortArrow"
                          src={
                               order.col === "categoryName"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "subCategoryName",
                        state: !order.state,
                      });
                      handleSort({
                        data: taxListCurrentPage,
                        col: "subCategoryName",
                        state: order.state,
                      });
                    }}>Item Sub Category 
                    <img
                          className="sortArrow"
                          src={
                               order.col === "subCategoryName"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "brandName",
                        state: !order.state,
                      });
                      handleSort({
                        data: taxListCurrentPage,
                        col: "brandName",
                        state: order.state,
                      });
                    }}>Brand Name
                    <img
                          className="sortArrow"
                          src={
                               order.col === "brandName"
                               ? order.state
                               ? SortUpGreen : SortDownGreen
                                        : null}
                          /></td>
                            <td className="vendorListHeading">Created / Edited Date</td>
                            <td className="vendorListHeading">Created / Edited By</td>
                            <td className="vendorListHeadingActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody>{taxList.length > 0 ? rendorTaxCostList() : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
            {taxList.length >0 ? <Pagination 
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={taxList.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editTaxObj: (taxObj) => dispatch(editItemTaxObj(taxObj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemTaxList);
