import React, { useEffect, useState,useMemo } from "react";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import {
  soViewInvoiceId,
  soEditInvoiceId,
  soInvoiceCreateId,
  setSoInvoiceTab,
} from "../../redux/PurchaseOrder/PoInfoActions";
import FormElement from "../SmallComponents/FormElement";
import pageIcon from "../../assets/sheets.svg";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";
const SalesInvoiceList = ({
  // props
  setApproval,
  setEditAndApprove,
  setSection,
  setLoadSo,

  // state
  isLogged,
  poInfo,

  // dispatch
  setSalesOrderInvoiceTab,
  soInvoiceCreate,
  soViewInvoice,
  soEditInvoice,
  addTokenToState,
}) => {
  const [loading, setLoading] = useState(true);

  const [noInvoiceRecords, setNoInvoiceRecords] = useState(false);
  const [noInvoiceUnapprovedRecords, setNoInvoiceUnapprovedRecords] =
    useState(false);
  const [noInvoiceApprovedRecords, setNoInvoiceApprovedRecords] =
    useState(false);
  const [noInvoiceDeclinedRecords, setNoInvoiceDeclinedRecords] =
    useState(false);

  const [soList, setSoList] = useState([]);
  const [invoiceUnApprovedList, setInvoiceUnApprovedList] = useState([]);
  const [approvedInvoiceList, setApprovedInvoiceList] = useState([]);
  const [declinedInvoiceList, setDecinedInvoiceList] = useState([]);
  const [plantId, setPlantId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerOptions,setCustomerOptions]=useState([]);
  const [PageSize,setRowsPerPage]=useState(10);
  const [currentPage,setCurrentPage]=useState(1);
  const [fromOrderDate, setFromOrderDate] = useState("");
  const [toOrderDate, setToOrderDate] = useState("");

  const soApprovedCurrentPage = useMemo(() => {
    if(poInfo.soInvoiceTab==="salesOrderList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return soList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,soList,PageSize]);

  const invoiceCurrentPage = useMemo(() => {
    if(poInfo.soInvoiceTab==="invoiceList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return invoiceUnApprovedList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,invoiceUnApprovedList,PageSize]);

  const approvedInvoiceCurrentPage = useMemo(() => {
    if(poInfo.soInvoiceTab==="approvedInvoiceList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return approvedInvoiceList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,approvedInvoiceList,PageSize]);

  const declinedInvoiceCurrentPage = useMemo(() => {
    if(poInfo.soInvoiceTab==="declinedInvoiceList"){
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return declinedInvoiceList.slice(firstPageIndex, lastPageIndex);}
  }, [currentPage,declinedInvoiceList,PageSize]);

  useEffect(async () => {
    await checkToken();
    await getCustomers();
    getInformation();
  }, []);

  async function getInformation() {
    await getSalesInvoiceList();
  }

  const history = useHistory();
  async function checkToken() {
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getCustomers() {
    var data = await fetchData({
        requestingPage: "getParents",
        method: "get",
        url: "fetch/customer-list",
        headers: { token: isLogged.accessToken ,module:"Customer"},
    });
    if (data.msg === "success") {
        var options = [];
        data.asset.forEach((parent) => {
            var newParent = { optionId: parent.customerId, optionName: parent.customer };
            options.push(newParent);
        });
        setCustomerOptions(options);
    }
}

  async function getSalesInvoiceList() {
    var data = await fetchData({
      requestingPage: "invoiceList",
      method: "post",
      url: "so-fetch/sales-invoice-list",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      data: {plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoInvoiceRecords(true);
      }

      setSoList(data.asset);
    }
    setLoading(false);
  }

  async function getInvoiceUnapprovedList() {
    var data = await fetchData({
      requestingPage: "invoiceList",
      method: "post",
      url: "so-fetch/sales-invoice-unapproved-list",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      data: {plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoInvoiceUnapprovedRecords(true);
      }

      var data2 = [];
      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setInvoiceUnApprovedList(data2);
    }
    setLoading(false);
  }

  async function getInvoiceApprovedList() {
    var data = await fetchData({
      requestingPage: "invoiceList",
      method: "post",
      url: "so-fetch/sales-invoice-approved-list",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      data:{plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoInvoiceApprovedRecords(true);
      }

      var data2 = [];
      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setApprovedInvoiceList(data2);
    }
    setLoading(false);
  }

  async function getInvoiceDeclinedList() {
    var data = await fetchData({
      requestingPage: "invoiceList",
      method: "post",
      url: "so-fetch/sales-invoice-declined-list",
      headers: { token: isLogged.accessToken, module: "Sales Invoice" },
      data:{plantId,customerId,fromOrderDate,toOrderDate}
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        setNoInvoiceDeclinedRecords(true);
      }

      var data2 = [];
      data.asset.forEach((element, i) => {
        var newElement = Object.assign(element);
        newElement.collapse = true;
        data2.push(newElement);
      });
      setDecinedInvoiceList(data2);
    }
    setLoading(false);
  }

  const editItemCollapse = (list, index) => {
    var listCopy = [];

    switch (list) {
      case "invoiceUnapprovedList":
        listCopy = invoiceUnApprovedList.slice();
        break;

      case "soApprovedList":
        listCopy = approvedInvoiceList.slice();
        break;

      case "soDeclinedList":
        listCopy = declinedInvoiceList.slice();
        break;
    }

    listCopy.forEach((item, j) => {
      if (j === index) {
        listCopy[j].collapse = !item.collapse;
      } else {
        listCopy[j].collapse = true;
      }
    });

    switch (list) {
      case "invoiceUnapprovedList":
        setInvoiceUnApprovedList(listCopy);
        break;

      case "soApprovedList":
        setApprovedInvoiceList(listCopy);
        break;

      case "soDeclinedList":
        setDecinedInvoiceList(listCopy);
        break;
    }
  };

  const setSelectedTab = (tab) => {
    setSalesOrderInvoiceTab(tab);
  };
  const tablist = [
    { id: "salesOrderList", name: "Sales Order List" },
    { id: "invoiceList", name: "Invoice List" },
    { id: "approvedInvoiceList", name: "Approved Invoice List" },
    { id: "declinedInvoiceList", name: "Declined Invoice List" },
  ];
  const access = isLogged.access["Sales Invoice"];
  useEffect(async () => {
    setLoading(true);
    await checkToken();
    switch (poInfo.soInvoiceTab) {
      case "salesOrderList":
        setSoList([]);
        getSalesInvoiceList();
        break;

      case "invoiceList":
        setInvoiceUnApprovedList([]);
        getInvoiceUnapprovedList([]);

        break;

      case "approvedInvoiceList":
        setApprovedInvoiceList([]);
        getInvoiceApprovedList();
        break;

      case "declinedInvoiceList":
        setDecinedInvoiceList([]);
        getInvoiceDeclinedList();
        break;

      default:
        break;
    }
  }, [poInfo.soInvoiceTab,plantId,customerId,fromOrderDate,toOrderDate]);

  const [order, setOrder] = useState({ col: "soNo", state: true, data: "" });

  const renderSubSection = () => {
    switch (poInfo.soInvoiceTab) {
      case "salesOrderList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>Order No<img
                      className="sortArrow"
                      src={
                        order.col === "soNo"
                          ? order.state
                            ? SortUpGreen
                            : SortDownGreen
                          : null
                      }
                    /></td>
                  <td className="vendorListHeading"onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>Order Date<img
                    className="sortArrow"
                    src={
                      order.col === "soDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "outwardNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "outwardNo",
                        state: order.state,
                      });
                    }}>Outward No<img
                    className="sortArrow"
                    src={
                      order.col === "outwardNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "outwardDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "outwardDate",
                        state: order.state,
                      });
                    }}>Outward Date<img
                    className="sortArrow"
                    src={
                      order.col === "outwardDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>Company Name<img
                    className="sortArrow"
                    src={
                      order.col === "companyName"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "outwardStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "outwardStatus",
                        state: order.state,
                      });
                    }}>Outward Status<img
                    className="sortArrow"
                    src={
                      order.col === "outwardStatus"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "paymentStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "paymentStatus",
                        state: order.state,
                      });
                    }}>Payment Status<img
                    className="sortArrow"
                    src={
                      order.col === "paymentStatus"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "orderType",
                        state: !order.state,
                      });
                      handleSort({
                        data: soApprovedCurrentPage,
                        col: "orderType",
                        state: order.state,
                      });
                    }}>Order Type<img
                    className="sortArrow"
                    src={
                      order.col === "orderType"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>{soList.length > 0 ? renderInvoiceList() : null}</tbody>
            </table>
            {noInvoiceRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "invoiceList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "invoiceNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "invoiceNo",
                        state: order.state,
                      });
                    }}>Invoice No<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "invoiceDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "invoiceDate",
                        state: order.state,
                      });
                    }}>Invoice Date<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading"onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>Order No<img
                    className="sortArrow"
                    src={
                      order.col === "soNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>Order Date<img
                    className="sortArrow"
                    src={
                      order.col === "soDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading"onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>Company Name<img
                    className="sortArrow"
                    src={
                      order.col === "companyName"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>Invoice Status<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceStatus"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>Created By<img
                    className="sortArrow"
                    src={
                      order.col === "createdBy"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "orderType",
                        state: !order.state,
                      });
                      handleSort({
                        data: invoiceCurrentPage,
                        col: "orderType",
                        state: order.state,
                      });
                    }}>Order Type<img
                    className="sortArrow"
                    src={
                      order.col === "orderType"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {invoiceUnApprovedList.length > 0
                  ? renderSoUnapprovedList()
                  : null}
              </tbody>
            </table>
            {noInvoiceUnapprovedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "approvedInvoiceList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "invoiceNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "invoiceNo",
                        state: order.state,
                      });
                    }}>Invoice No<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "invoiceDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "invoiceDate",
                        state: order.state,
                      });
                    }}>Invoice Date<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>Order No<img
                    className="sortArrow"
                    src={
                      order.col === "soNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>Order Date <img
                    className="sortArrow"
                    src={
                      order.col === "soDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>Company Name<img
                    className="sortArrow"
                    src={
                      order.col === "companyName"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "invoiceStatus",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>Invoice Status<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceStatus"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>Created By <img
                    className="sortArrow"
                    src={
                      order.col === "createdBy"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "orderType",
                        state: !order.state,
                      });
                      handleSort({
                        data: approvedInvoiceCurrentPage,
                        col: "orderType",
                        state: order.state,
                      });
                    }}>Order Type<img
                    className="sortArrow"
                    src={
                      order.col === "orderType"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {approvedInvoiceList.length > 0
                  ? renderInvoiceApproved()
                  : null}
              </tbody>
            </table>
            {noInvoiceApprovedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );

      case "declinedInvoiceList":
        return (
          <React.Fragment>
            <table className="vendorListTable">
              <thead>
                <tr className="vendorListHeader"  style={{zIndex:1}}>
                  <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                      setOrder({
                        col: "invoiceNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "invoiceNo",
                        state: order.state,
                      });
                    }}>Invoice No<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "invoiceDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "invoiceDate",
                        state: order.state,
                      });
                    }}>Invoice Date<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "soNo",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "soNo",
                        state: order.state,
                      });
                    }}>Order No <img
                    className="sortArrow"
                    src={
                      order.col === "soNo"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "soDate",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "soDate",
                        state: order.state,
                      });
                    }}>Order Date<img
                    className="sortArrow"
                    src={
                      order.col === "soDate"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "companyName",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "companyName",
                        state: order.state,
                      });
                    }}>Company Name <img
                    className="sortArrow"
                    src={
                      order.col === "companyName"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "invoiceStatuS",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "invoiceStatus",
                        state: order.state,
                      });
                    }}>Invoice Status<img
                    className="sortArrow"
                    src={
                      order.col === "invoiceStatus"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "createdBy",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "createdBy",
                        state: order.state,
                      });
                    }}>Created By<img
                    className="sortArrow"
                    src={
                      order.col === "createdBy"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeading" onClick={(e) => {
                      setOrder({
                        col: "orderType",
                        state: !order.state,
                      });
                      handleSort({
                        data: declinedInvoiceCurrentPage,
                        col: "orderType",
                        state: order.state,
                      });
                    }}>Order Type <img
                    className="sortArrow"
                    src={
                      order.col === "orderType"
                        ? order.state
                          ? SortUpGreen
                          : SortDownGreen
                        : null
                    }
                  /></td>
                  <td className="vendorListHeadingActions">Actions</td>
                </tr>
              </thead>
              <tbody>
                {declinedInvoiceList.length > 0
                  ? renderInvoiceDeclined()
                  : null}
              </tbody>
            </table>
            {noInvoiceDeclinedRecords ? (
              <div className="noRecordsYet">No records found</div>
            ) : loading ? (
              <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
          </React.Fragment>
        );
    }
  };

  const renderInvoiceList = () => {
    return soApprovedCurrentPage?.map((item, i) => {
        return (
          <React.Fragment key={item.soNo}>
            <tr className="vendorList" key={item.soNo}>
              <td className="vendorListInfo stickyFirstColumn">{item.soNo}</td>
              <td className="vendorListInfo">{item.soDate}</td>
              <td className="vendorListInfo">
                {item.outwardNo !== null ? item.outwardNo : "--"}
              </td>
              <td className="vendorListInfo">
                {item.outwardDate !== null ? item.outwardDate : "--"}
              </td>
              <td className="vendorListInfo">{item.companyName}</td>
              <td className="vendorListInfo">
                {item.outwardStatus !== null ? item.outwardStatus : "--"}
              </td>
              <td className="vendorListInfo">
                {item.paymentStatus !== null ? item.paymentStatus : "--"}
              </td>
              <td className="vendorListInfo">{item.orderType}</td>

              <td className="vendorListInfoActions">
                <img
                  alt="Create Invoice"
                  className="vendorActionImages"
                  src={pageIcon}
                  title={
                    access === "Create" ||
                    access === "Edit" ||
                    access === "App/Dec"
                      ? "Create Invoice"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      access === "Create" ||
                      access === "Edit" ||
                      access === "App/Dec"
                    ) {
                      setSection("createInvoice");
                      soInvoiceCreate(item.soId);
                      setLoadSo(true);
                    }
                  }}
                  style={
                    access === "Create" ||
                    access === "Edit" ||
                    access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />
              </td>
            </tr>
          </React.Fragment>
        );
    });
  };

  const renderInvoiceApproved = () => {
    return approvedInvoiceCurrentPage?.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <tr
              className="vendorList"
              key={item.soNo}
              onClick={(e) => editItemCollapse("soApprovedList", i)}>
              <td className="vendorListInfo stickyFirstColumn">{item.invoiceNo}</td>
              <td className="vendorListInfo">{item.invoiceDate}</td>
              <td className="vendorListInfo">{item.soNo}</td>
              <td className="vendorListInfo">{item.soDate}</td>
              <td className="vendorListInfo">{item.companyName}</td>
              <td className="vendorListInfo">{item.invoiceStatus}</td>
              <td className="vendorListInfo">{item.createdBy}</td>
              <td className="vendorListInfo">{item.orderType}</td>

              <td className="vendorListInfoActions">
                <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Invoice Detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    soViewInvoice(item.invoiceId);
                    setSection("invoiceDetail");
                    setApproval(false);
                  }}
                />

                <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={item.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse("soApprovedList", i);
                  }}
                />
              </td>
            </tr>

            <tr>
              {!item.collapse ? (
                <RenderSubTable tab="approvedInvoice" data={item.lineItems} />
              ) : null}
            </tr>
          </React.Fragment>
        );
    });
  };

  const renderInvoiceDeclined = () => {
    return declinedInvoiceCurrentPage?.map((item, i) => {
        return (
          <React.Fragment key={item.soNo}>
            <tr
              className="vendorList"
              onClick={(e) => editItemCollapse("soDeclinedList", i)}>
              <td className="vendorListInfo stickyFirstColumn">{item.invoiceNo}</td>
              <td className="vendorListInfo">{item.invoiceDate}</td>
              <td className="vendorListInfo">{item.soNo}</td>
              <td className="vendorListInfo">{item.soDate}</td>
              <td className="vendorListInfo">{item.companyName}</td>
              <td className="vendorListInfo">{item.invoiceStatus}</td>
              <td className="vendorListInfo">{item.createdBy}</td>
              <td className="vendorListInfo">{item.orderType}</td>

              <td className="vendorListInfoActions">
                <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Invoice Detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    // viewSalesOrderId(item.soId);
                    soViewInvoice(item.invoiceId);
                    setSection("invoiceDetail");
                    setApproval(false);
                  }}
                />

                <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={item.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse("soDeclinedList", i);
                  }}
                />
              </td>
            </tr>

            <tr>
              {!item.collapse ? (
                <RenderSubTable tab="declinedInvoice" data={item.lineItems} />
              ) : null}
            </tr>
          </React.Fragment>
        );
    });
  };

  const renderSoUnapprovedList = () => {
    return invoiceCurrentPage?.map((item, index) => {
        return (
          <React.Fragment>
            <tr
              className="vendorList"
              key={item.soId}
              onClick={(e) => {
                e.stopPropagation();
                editItemCollapse("invoiceUnapprovedList", index);
              }}>
              <td className="vendorListInfo stickyFirstColumn">{item.invoiceNo}</td>
              <td className="vendorListInfo">{item.invoiceDate}</td>
              <td className="vendorListInfo">{item.soNo}</td>
              <td className="vendorListInfo">{item.soDate}</td>
              <td className="vendorListInfo">{item.companyName}</td>
              <td className="vendorListInfo">{item.invoiceStatus}</td>
              <td className="vendorListInfo">{item.createdBy}</td>
              <td className="vendorListInfo">{item.orderType}</td>

              <td className="vendorListInfoActions">
                <img
                  alt="Approve Record"
                  className="vendorActionImages"
                  src={
                    item.invoiceStatus === "Draft"
                      ? approveIconDisabled
                      : approveIcon
                  }
                  title={
                    item.invoiceStatus === "Draft"
                      ? "Can't approve Draft"
                      : access === "App/Dec"
                      ? "Approve Invoice"
                      : "Access Denied"
                  }
                  style={
                    item.invoiceStatus !== "Draft" && access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      item.invoiceStatus !== "Draft" &&
                      access === "App/Dec"
                    ) {
                      setSection("invoiceDetail");
                      setApproval(true);
                      soViewInvoice(item.invoiceId);
                    }
                  }}
                />

                <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Invoice Detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    soViewInvoice(item.invoiceId);
                    setSection("invoiceDetail");
                  }}
                />
                <img
                  alt="Edit Record"
                  className="vendorActionImages"
                  src={
                    access === "Edit" || access === "App/Dec"
                      ? editIcon
                      : editIconDisabled
                  }
                  title={
                    access === "Edit" || access === "App/Dec"
                      ? "Edit Item"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "Edit" || access === "App/Dec") {
                      soEditInvoice(item.invoiceId);
                      soInvoiceCreate(item.soId);
                      setSection("editSalesInvoice");
                      setEditAndApprove(false);
                    }
                  }}
                  style={
                    access === "Edit" || access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />
                <img
                  alt="Toggle Expand"
                  className="vendorActionImages"
                  title="Toggle Expand"
                  src={item.collapse ? downIcon : upIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    editItemCollapse("invoiceUnapprovedList", index);
                  }}
                />
              </td>
            </tr>

            <tr>
              {!item.collapse ? (
                <RenderSubTable tab="unapprovedSo" data={item.lineItems} />
              ) : null}
            </tr>
          </React.Fragment>
        );
    });
  };

  const RenderSubTable = ({ tab, data, reason }) => {
    return (
      <td
        colSpan={tab === "approvedSo" ? "11" : "9"}
        className="vendorListInnerTableWrapper">
        {tab === "declinedSo" ? (
          <div
            style={{
              boxSizing: "border-box",
              width: "100%",
              padding: "10px",
              backgroundColor: "#eee",
            }}>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Reason:&nbsp;&nbsp;&nbsp;
            </span>
            {reason}
          </div>
        ) : null}
        <table className="vendorListInnerTable">
          <thead>
            <tr className="vendorListInnerTableHeader">
              <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
              {isLogged.showHideWeight ? (
                <>
                  {" "}
                  <td className="vendorListInnerTableHeading">
                    Weight From(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Weight To(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Net Weight(gms)
                  </td>
                  <td className="vendorListInnerTableHeading">
                    Gross Weight(gms)
                  </td>{" "}
                </>
              ) : null}
              <td className="vendorListInnerTableHeading">HSN No</td>
              <td className="vendorListInnerTableHeading">UOM</td>
              <td className="vendorListInnerTableHeading">Quantity</td>
              <td className="vendorListInnerTableHeading">Unit Price</td>
              <td className="vendorListInnerTableHeading">Discount (%)</td>
              <td className="vendorListInnerTableHeading">Tax (%)</td>
              <td className="vendorListInnerTableHeading">Net Price</td>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => {
              return (
                <tr className="vendorListInnerTable" key={row.soLineItemId}>
                  <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                  {isLogged.showHideWeight ? (
                    <>
                      {" "}
                      <td className="vendorListInnertableInfo">
                        {row.from ? row.from : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.to ? row.to : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.netWeight ? row.netWeight : " - "}
                      </td>
                      <td className="vendorListInnertableInfo">
                        {row.grossWeight ? row.grossWeight : " - "}
                      </td>{" "}
                    </>
                  ) : null}
                  <td className="vendorListInnertableInfo">{row.hsnCode}</td>
                  <td className="vendorListInnertableInfo">{row.uom}</td>
                  <td className="vendorListInnertableInfo">{row.quantity}</td>
                  <td className="vendorListInnertableInfo">{row.unitPrice}</td>
                  <td className="vendorListInnertableInfo">{row.discount}</td>
                  <td className="vendorListInnertableInfo">{row.tax}</td>
                  <td className="vendorListInnertableInfo">{row.netPrice}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    );
  };

  const RenderTabList = ({ tablist }) => {
    return tablist.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            poInfo.soInvoiceTab === tab.id
              ? "createVendorIndividualTabSelected"
              : "createVendorIndividualTab"
          }
          onClick={() => {setOrder({ col: "invoiceNo", state: true, data: "" });setSelectedTab(tab.id);}}>
          {tab.name}
          {tab.id === "contactDetails" ? (
            poInfo.soInvoiceTab === "contactDetails" ? (
              " *"
            ) : (
              <span style={{ color: "red" }}> *</span>
            )
          ) : null}
        </div>
      );
    });
  };

  const renderPagination=()=>{
    switch(poInfo.soInvoiceTab){
      case "salesOrderList":
         return(soList.length >0   ?  <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={soList.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        /> : null);
  
        case "invoiceList":
          return(
            invoiceUnApprovedList.length >0   ?  <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={invoiceUnApprovedList.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            /> : null
          );
  
        case "approvedInvoiceList":
         return(approvedInvoiceList.length >0   ?  <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={approvedInvoiceList.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        /> : null);
  
        case "declinedInvoiceList":
          return(
            declinedInvoiceList.length >0   ?  <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={declinedInvoiceList.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          /> : null
          );
    }
  };

  return (
    <React.Fragment>
      <div className="poListTabs">
        <RenderTabList tablist={tablist} />
      </div>
       <div style={{ display:"inline-flex",marginLeft:"25px" }} className="listPageParamsGrid">
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setPlantId(value);
          }}
          inputType={"options"}
          value={plantId}
          hintText={"Plant Name"}
          options={isLogged.plantOptions}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setCustomerId(value);
          }}
          inputType={"options"}
          options={customerOptions}
          value={customerId}
          hintText={"Customer Name"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setFromOrderDate(value);
          }}
          inputType={"dateFromEditPage"}
          value={fromOrderDate}
          hintText={"From Date"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span className="listPageParams">
        <FormElement
          setInput={(value) => {
            setToOrderDate(value);
          }}
          inputType={"dateFromEditPage"}
          value={toOrderDate}
          hintText={"To Date"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
        <span style={{flexGrow:0, width:"80px"}}>
        <FormElement
          setInput={(value) => {
            setRowsPerPage(value);
          }}
          inputType={"number"}
          value={PageSize}
          hintText={"Rows Per Page"}
          colSpan={0.5}
          rowSpan={1}
          mandatory={false}
          error={false}
          errorMessage=""
          disabled={false}
        />
        </span>
      </div>
      <div className="vendorListArea">{renderSubSection()}</div>
      {renderPagination()}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    soInvoiceCreate: (invoiceCreateId) =>
      dispatch(soInvoiceCreateId(invoiceCreateId)),

    soViewInvoice: (viewInvoiceId) => dispatch(soViewInvoiceId(viewInvoiceId)),
    soEditInvoice: (editInvoiceId) => dispatch(soEditInvoiceId(editInvoiceId)),

    setSalesOrderInvoiceTab: (tab) => dispatch(setSoInvoiceTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceList);
