import React, { useState } from "react";

import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import SalesLeadList from "./SalesLeadList";
import CreateSalesLead from "./CreateSalesLead";
import customer from "../../assets/customer.svg";
import EditSalesLead from "./EditSalesLead";
import CreateCompany from "./CreateCompany";
import salesLeadIcon from "../../assets/sales-lead.svg";


const SalesLead = () => {
  const [currentSection, setCurrentSection] = useState("salesLeadList");

  var goBackButton = (props) =>
  ({
    buttonName: "Back",
    className: "goBackButton",
    ...props
  });

  var CreateButton = { buttonName: "Create Lead", className: "employeeButton", setSection: setCurrentSection, sectionName: "createSalesLead" }

  const renderSection = (section) => {
    switch (section) {
      case "salesLeadList":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesLeadIcon}
              pageTitle={"Sales Lead"}
              buttonList={[CreateButton]}
            />
            <SalesLeadList setCurrentSection={setCurrentSection} />
          </React.Fragment>
        );
      case "createSalesLead":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesLeadIcon}
              pageTitle={"Create Sales Lead"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesLeadList" })]}
            />
            <CreateSalesLead setCurrentSection={setCurrentSection} />
          </React.Fragment>
        );
        case "createCompany":
          return (
              <React.Fragment>
                  <PageTitle imgSrc={customer} pageTitle="Create Company" buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "createSalesLead" })]} />
                  <CreateCompany setSection={(value) => setCurrentSection(value)} />
              </React.Fragment>
          );
        case "createCompanyEditPage":
          return (
              <React.Fragment>
                  <PageTitle imgSrc={customer} pageTitle="Create Company" buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "editSalesLead" })]} />
                  <CreateCompany setSection={(value) => setCurrentSection(value)} />
              </React.Fragment>
          );
      case "editSalesLead":
        return (
          <React.Fragment>
            <PageTitle
              imgSrc={salesLeadIcon}
              pageTitle={"Edit Sales Lead"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesLeadList" })]}
            />
            <EditSalesLead setCurrentSection={setCurrentSection}/>
          </React.Fragment>
        );
        default :return null
    }
  };

  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Sales Quotes(CRM)" selectedSubMenu="Sales Lead" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
  )
}


export default SalesLead;