import React, { useEffect, useState,useMemo } from "react";
import update from "immutability-helper";
import FormElement from "../SmallComponents/FormElement";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken,showHideWeight } from "../../redux/UserAccount/IsLoggedActions";
import Pagination from '../CommonFunctions/pagination';

const Inventory3 = ({ isLogged, storeInfo, addTokenToState,showHideWeight,setInventoryJSON,setSummaryInvJSON }) => {
    const inventoryParameterList = ["plantName", "storageName", "categoryName", "subCategoryName", "brandName", "itemName"];
    const splitByList = ["split"];
    // KME Domain Specific
    const isKMEDomain = window.location.href.includes('kme') || window.location.href.includes('localhost');

    const [inventoryList, setInventoryList] = useState([]);
    const [showWeight, setShowWeight] = useState(isLogged.showHideWeight);
    const [showRecordsBy,setShowRecordsBy] = useState('both')
    const [isFullScreen,SetIsFullScreen] = useState(false)

    const [splitBy, setSplitBy] = useState({
        split: {
            inputType: "options",
            value: "",
            hintText: "Split Records By",
            options: [
                // { optionId: "plantName", optionName: "Plant" },
                { optionId: "categoryName", optionName: "Item Category" },
                { optionId: "subCategoryName", optionName: "Item Sub Category" },
                { optionId: "brandName", optionName: "Brand Name" },
            ],
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
        },
    });
    const [inventoryParams, setInventoryParams] = useState({
        date: {
            inputType: "dateFromEditPage",
            value: new Date(),
            hintText: "Stock as on Date",
            mandatory: false,
            colSpan: 5,
            error: false,
            maxdate: true,
        },
        plantName: {
            inputType: "multiSelect",
            value: [],
            hintText: "Plant",
            options: [],
            mandatory: false,
            colSpan: 5,
            error: false,
            errorMessage: "Please select Plant Name",
        },
        storageName: {
            inputType: "multiSelect",
            value: [],
            hintText: "Storage",
            options: [],
            mandatory: false,
            colSpan: 5,
            error: false,
            errorMessage: "Please enter Plant Name",
        },
        categoryName: {
            inputType: "multiSelect",
            value: [],
            hintText: "Item Main Category",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Please select categoryName",
        },
        subCategoryName: {
            inputType: "multiSelect",
            value: [],
            hintText: "Item SubCategory",
            mandatory: false,
            colSpan: 5,
            options: [],
            error: false,
            errorMessage: "Please select subCategoryName",
        },
        brandName: {
            inputType: "multiSelect",
            value: [],
            hintText: "Brand Name",
            mandatory: false,
            colSpan: 5,
            options: [],
            error: false,
            errorMessage: "Please select city",
        },
        itemName: {
            inputType: "multiSelect",
            value: [],
            hintText: "Item Name",
            mandatory: false,
            colSpan: 4,
            options: [],
            error: false,
            errorMessage: "Enter Zip code",
        },
    });
    const [qtyColumnsCheck,setQtyColumnsCheck] = useState({
        scrap: {name: "Rejected Qty",checked : false},
        po: {name: "PO Qty",checked: false},
        so: {name: "Blocked Qty",checked: false}
    });
    const handleQtyColumnCheck=({key,e})=>{
        let qtyColumnCopy = Object.assign(qtyColumnsCheck);
        qtyColumnCopy = update(qtyColumnCopy,{[key]: { checked: {$set: e.target.checked} } });
        setQtyColumnsCheck(qtyColumnCopy);
    }
    const storeHeaderColSpan=useMemo(()=>{
        let colSpan = Object.values(qtyColumnsCheck).reduce((acc,obj)=>(acc = obj.checked ? acc+1: acc),1);
        return colSpan;
    },[qtyColumnsCheck])
    const [filteredInventoryList, setFilteredInventoryList] = useState([]);
    const [parsedInventory, setParsedInventory] = useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const parsedInventoryCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return parsedInventory.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,parsedInventory,PageSize]);

    const [storeList, setStoreList] = useState([]);
    const [storageLocList, setStorageLocList] = useState({});

    async function getInformation(){

        await checkToken();
        let  inventoryListCopy = await getInventory();
    
          
        let paramsCopy = await parseListForOptions(inventoryListCopy);
    
        let filteredList = await filterInventory(inventoryListCopy);
    
        let storeList=[];
        filteredList.forEach((element)=>{
            let foundInd = storeList.findIndex(el=>el.plantName === element.plantName);
            if(foundInd > -1){
                // if it does NOT contain the storage name push it in storage name key
                if( ! storeList[foundInd].storageNames.includes(element.storageName))
                storeList[foundInd].storageNames.push(element.storageName)
            }else{
             storeList.push({
                    plantName: element.plantName,
                    storageNames: [element.storageName],
                });
            }
        });
        storeList = storeList.reduce((acc,val)=>[...acc,...val.storageNames,val.plantName],[]);
    
        setStoreList(storeList);
    
        let tableData = await parseInventory(filteredList);
        let reportData = await getSummaryInventory()
        
        setInventoryList(inventoryListCopy);
        setInventoryParams(paramsCopy);
        setParsedInventory(tableData);
        setInventoryJSON(tableData);
        setSummaryInvJSON(reportData);
        
    }

    async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Sales Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantName;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantName] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageName;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantName].push(storeObj);
        });
      });

    }
    return [plantList, storageLocList];
  }

  useEffect(() => {

    var queryParamsCopy = Object.assign(inventoryParams);

    if (inventoryParams.plantName.value.length) {
        let plantList = inventoryParams.plantName.value.map((item)=>item.value);
        let storageList = [];
         plantList.forEach(plant => {
            storageList=storageList.concat(...storageLocList[plant]);
         });

      queryParamsCopy = update(queryParamsCopy, {
        storageName: {
          options: { $set: storageList||[] },
        },
      });
      setInventoryParams(queryParamsCopy);
    } else {
      queryParamsCopy = update(queryParamsCopy, {
        storageName: { options: { $set: [] }, value: { $set: [] } },
      });
      setInventoryParams(queryParamsCopy);
   
    }
  }, [inventoryParams.plantName.value]);


    useEffect(() => {
        getInformation();
    }, []);

    const fullScreen =()=>{
        let element = document.getElementById('inventoryDetails')
        if(element.requestFullscreen){
            element.requestFullscreen();
        }
    }

    const history = useHistory();

    async function checkToken() {
        //console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            //console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            //console.log("Token not changed");
        }
    }

    async function onDateChange(){
        let currentDate = new Date();

        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let dateValue = currentDate.getDate();

        if (month.toString().length === 1) month = `0${month}`;
        if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;

        let formattedDate = `${year}-${month}-${dateValue}`;


        let inventoryListCopy = []
        if (inventoryParams.date.value === formattedDate) {
           inventoryListCopy = await getInventory(false);
        } else {
            inventoryListCopy = await getInventory(true);
        }

        setInventoryList(inventoryListCopy);

        let paramsCopy = await parseListForOptions(inventoryListCopy);

        setInventoryParams(paramsCopy);
    }
    useEffect( () => {
        onDateChange();
    }, [inventoryParams.date]);

    const getInventory = async (date) => {
        let data = await fetchData({
            requestingPage: "createPlant",
            method: "post",
            url: "store-fetch/inventory-list2",
            data: date ? { stockDate: inventoryParams.date.value } : null,
            headers: { token: isLogged.accessToken, module: "Inventory" },
        });
        if (data.msg === "success") {
            return data.asset;
        } else {
            //console.log(data);
        }
    };

    const getSummaryInventory = async()=>{
        let data = await fetchData({
            requestingPage: "createPlant",
            method: "get",
            url: "store-fetch/summaryInventoryList",
            // data: date ? { stockDate: inventoryParams.date.value } : null,
            headers: { token: isLogged.accessToken, module: "Inventory" },
        });
        if (data.msg === "success") {
            return data.asset;
        } else {
            //console.log(data);
        }
    }

    async function onParamsAndListChange(){
 let filteredList = await filterInventory(inventoryList);

        if (splitBy.split.value !== "") {
            renderSplitTables();
        } else {
            let tableData = await parseInventory(filteredList);
            let storeList=[];
        filteredList.forEach((element)=>{
            let foundInd = storeList.findIndex(el=>el.plantName === element.plantName);
            if(foundInd > -1){
                // if it does NOT contain the storage name push it in storage name key
                if( ! storeList[foundInd].storageNames.includes(element.storageName))
                storeList[foundInd].storageNames.push(element.storageName)
            }else{
             storeList.push({
                    plantName: element.plantName,
                    storageNames: [element.storageName],
                });
            }
        });
            storeList = storeList.reduce((acc,val)=>[...acc,...val.storageNames,val.plantName],[]);
            setStoreList(storeList);
            setParsedInventory(tableData);
            setInventoryJSON(tableData);
        }
    }
    useEffect( () => {
       onParamsAndListChange();
    }, [inventoryParams, inventoryList]);

    useEffect(() => {
        if (splitBy.split.value !== "") {
            renderSplitTables();
        }
    }, [splitBy.split]);

    const parseListForOptions = async(inventories) => {

        // let plantList = [];
        // let storageList = [];
        let categoryList = [];
        let subCategoryList = [];
        let brandList = [];
        let itemNameList = [];

        var [plantList,storageLocListFormatted] = await getPlants();
        var queryParamsCopy = Object.assign(inventoryParams);
        queryParamsCopy = update(queryParamsCopy, {
            plantName: {
              options: { $set: plantList||[] },
            },
          });
          setInventoryParams(queryParamsCopy);
        setStorageLocList(storageLocListFormatted);


        inventories.forEach((element) => {
            // if (element.plantName !== null && element.plantName !== undefined) {
            //     plantList.push(element.plantName);
            // }
            // if (element.storageName !== null && element.storageName !== undefined) {
            //     storageList.push(element.storageName);
            // }
            if (element.categoryName !== null && element.categoryName !== undefined) {
                categoryList.push(element.categoryName);
            }
            if (element.subCategoryName !== null && element.subCategoryName !== undefined) {
                subCategoryList.push(element.subCategoryName);
            }
            if (element.brandName !== null && element.brandName !== undefined) {
                brandList.push(element.brandName);
            }
            if (element.itemName !== null && element.itemName !== undefined) {
                itemNameList.push(element.itemName);
            }
        });

        // plantList = Array.from(new Set(plantList.map((item) => item)));

        // storageList = Array.from(new Set(storageList.map((item) => item)));
        categoryList = Array.from(new Set(categoryList.map((item) => item)));
        subCategoryList = Array.from(new Set(subCategoryList.map((item) => item)));
        brandList = Array.from(new Set(brandList.map((item) => item)));
        itemNameList = Array.from(new Set(itemNameList.map((item) => item)));

        // plantList = plantList.map((item) => {
        //     return { optionId: item, optionName: item };
        // });

        // storageList = storageList.map((item) => {
        //     return { optionId: item, optionName: item };
        // });
        categoryList = categoryList.map((item) => {
            return { optionId: item, optionName: item };
        });
        subCategoryList = subCategoryList.map((item) => {
            return { optionId: item, optionName: item };
        });
        brandList = brandList.map((item) => {
            return { optionId: item, optionName: item };
        });
        itemNameList = itemNameList.map((item) => {
            return { optionId: item, optionName: item };
        });


        let paramsCopy = Object.assign(inventoryParams);

        paramsCopy = update(paramsCopy, {
            plantName: { options: { $set: plantList } },
            // storageName: { options: { $set: storageList } },
            categoryName: { options: { $set: categoryList } },
            subCategoryName: { options: { $set: subCategoryList } },
            brandName: { options: { $set: brandList } },
            itemName: { options: { $set: itemNameList } },
        });

        return paramsCopy;
    };

    const filterInventory = async (list) => {
        let inventoryListCopy = list.slice();

        if (inventoryParams.plantName.value.length > 0) {
            let tempPlantsList = [];
            inventoryParams.plantName.value.map((option) => {
                let indiPlant = inventoryListCopy.filter((inventory) => inventory.plantName === option.value);
                tempPlantsList = tempPlantsList.concat(indiPlant);
            });
            inventoryListCopy = Array.from(new Set(tempPlantsList.map((item) => item)));
        }

        if (inventoryParams.storageName.value.length > 0) {
            let tempStorageList = [];
            inventoryParams.storageName.value.map((option) => {
                let indiStorage = inventoryListCopy.filter((inventory) => inventory.storageName === option.value);
                tempStorageList = tempStorageList.concat(indiStorage);
            });
            inventoryListCopy = Array.from(new Set(tempStorageList.map((item) => item)));
        }

        if (inventoryParams.categoryName.value.length > 0) {
            let tempCategoryList = [];
            inventoryParams.categoryName.value.map((option) => {
                let indiCategory = inventoryListCopy.filter((inventory) => inventory.categoryName === option.value);
                tempCategoryList = tempCategoryList.concat(indiCategory);
            });
            inventoryListCopy = Array.from(new Set(tempCategoryList.map((item) => item)));
        }

        if (inventoryParams.subCategoryName.value.length > 0) {
            let tempSubCategoryList = [];
            inventoryParams.subCategoryName.value.map((option) => {
                let indiSubCategory = inventoryListCopy.filter((inventory) => inventory.subCategoryName === option.value);
                tempSubCategoryList = tempSubCategoryList.concat(indiSubCategory);
            });
            inventoryListCopy = Array.from(new Set(tempSubCategoryList.map((item) => item)));
        }

        if (inventoryParams.brandName.value.length > 0) {
            let tempBrandNameList = [];
            inventoryParams.brandName.value.map((option) => {
                let indiBrandName = inventoryListCopy.filter((inventory) => inventory.brandName === option.value);
                tempBrandNameList = tempBrandNameList.concat(indiBrandName);
            });
            inventoryListCopy = Array.from(new Set(tempBrandNameList.map((item) => item)));
        }

        if (inventoryParams.itemName.value.length > 0) {
            let tempItemNameList = [];
            inventoryParams.itemName.value.map((option) => {
                let indiItemName = inventoryListCopy.filter((inventory) => inventory.itemName === option.value);
                tempItemNameList = tempItemNameList.concat(indiItemName);
            });
            inventoryListCopy = Array.from(new Set(tempItemNameList.map((item) => item)));
        }


        return inventoryListCopy;
    };

    const parseInventory = async (data) => {
        let itemList = Array.from(new Set(data.map((record) => record.itemName)));

        let tableData = [];
        itemList.forEach((item) => {
            let itemSubset = data.filter((dataRecord) => dataRecord.itemName === item);

            let tableRecord = {
                itemName: itemSubset[0].itemName,
            };
            let totalQty = 0;
            
            let plantList = itemSubset.reduce((acc,plt)=>{
                if( ! acc.includes(plt.plantName)){
                    acc.push(plt.plantName);
                }
                return acc;
            },[])
            itemSubset.forEach((subsetRecord) => {
                let storeKey = subsetRecord.storageName;
                let storeScrapKey = `${subsetRecord.storageName}_scrap`;
                let storePOKey = `${subsetRecord.storageName}_po`;
                let storeSOKey = `${subsetRecord.storageName}_so`;

                tableRecord.itemCode = subsetRecord.itemCode;
                tableRecord.categoryName = subsetRecord.categoryName;
                tableRecord.subCategoryName = subsetRecord.subCategoryName;
                tableRecord.brandName = subsetRecord.brandName;
                tableRecord.from = subsetRecord.from;
                tableRecord.to = subsetRecord.to;
                tableRecord.netWeight = subsetRecord.netWeight;
                tableRecord.grossWeight = subsetRecord.grossWeight;
                tableRecord.UOM = subsetRecord.UOM;


                tableRecord[`${storeKey}_available`] = subsetRecord.quantity;
                totalQty += subsetRecord.quantity;
                tableRecord[storeScrapKey] = subsetRecord.scrapQuantity;

                tableRecord[storePOKey] = subsetRecord.poQuantity;
                tableRecord[storeSOKey] = subsetRecord.soQuantity;

                plantList.forEach((singlePlant)=>{
    
                    let plantKey = singlePlant;
                    let plantScrapKey = `${singlePlant}_scrap`;
                    let plantPOKey = `${singlePlant}_po`;
                    let plantSOKey = `${singlePlant}_so`;
                    
                    tableRecord[`${plantKey}_available`] = itemSubset.filter((it)=>it.plantName === plantKey).reduce((acc,el)=>acc+el.quantity,0);
                    tableRecord[plantScrapKey] = itemSubset.filter((it)=>it.plantName === plantKey).reduce((acc,el)=>acc+el.scrapQuantity,0);
                    tableRecord[plantPOKey] = itemSubset.filter((it)=>it.plantName === plantKey).reduce((acc,el)=>acc+el.poQuantity,0);
                    tableRecord[plantSOKey] = itemSubset.filter((it)=>it.plantName === plantKey).reduce((acc,el)=>acc+el.soQuantity,0);
                })
            });
            // Adding total qty in inventory
            tableRecord['totalQty'] = totalQty;

            tableData.push(tableRecord);
        });


        return tableData;
    };

    const resetSelections = () => {
        let paramsCopy = Object.assign(inventoryParams);

        paramsCopy = update(paramsCopy, {
            date: { value: { $set: new Date() } },
            plantName: { value: { $set: [] } },
            storageName: { value: { $set: [] } },
            categoryName: { value: { $set: [] } },
            subCategoryName: { value: { $set: [] } },
            brandName: { value: { $set: [] } },
            itemName: { value: { $set: [] } },
        });

        setInventoryParams(paramsCopy);

        let paramsCopy2 = Object.assign(splitBy);
        paramsCopy2 = update(paramsCopy2, { split: { value: { $set: "" } } });
        setSplitBy(paramsCopy2);
    };

    const updateInventoryParameter = ({ param, paramName, key, value }) => {
        let paramsCopy = Object.assign(param);
        paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
        if (paramName === "split") {
            setSplitBy(paramsCopy);
        } else {
            setInventoryParams(paramsCopy);
        }
    };

    const renderFormElements = ({ elementList, params }) => {
        return elementList.map((item) => {
            return (
                <FormElement
                    key={item}
                    inputType={params[item].inputType}
                    value={params[item].value}
                    setInput={(value) => {
                        updateInventoryParameter({ param: params, paramName: item, key: "value", value: value });
                    }}
                    hintText={params[item].hintText}
                    mandatory={params[item].mandatory}
                    colSpan={params[item].colSpan}
                    options={params[item].inputType === "options" || params[item].inputType === "multiSelect" ? params[item].options : null}
                    error={params[item].error}
                    maxdate={params[item].maxdate}
                />
            );
        });
    };

    const groupInventory = (filterBy, val, list) => {
        let filteredList = list.filter((item) => item[filterBy] === val);
        if (filteredList.length > 0) {
            return filteredList;
        } else {
            return null;
        }
    };

    const renderSplitTables = async () => {
        let filterBy = splitBy.split.value;
        let tablesData = [];

        let filteredList = await filterInventory(inventoryList);

        await inventoryParams[filterBy].options.map(async (option) => {
            let groupedList = groupInventory(filterBy, option.optionId, filteredList);
            if (groupedList?.length > 0) {
                let tableData = await parseInventory(groupedList);
                tablesData.push(tableData);
            }
        });
        setFilteredInventoryList(tablesData);
    };

    const checkIfColumnIsPlantName=(colName)=>{
        return inventoryParams.plantName.options.findIndex(el=>el.optionName===colName) > -1
    };

    const getStoreBGColors = (store) => {
        switch(store){
            case 'CBE SHOWROOM 1ST FLOOR':
                return '#A4907C';
            case 'PRIMARY WAREHOUSE (KME)':
                return '#44546A';
            case 'FACTORY STOCK':
                return '#00B050';
            case 'COIMBATORE':
                return '#C00000';
            case 'Chennai Godown-1':
                return '#4072C4';
            case 'REWORK/REPAIR - CHN':
                return '#4072C4';
            case 'SCRAP ROOM - CHN':
                return '#4072C4';
            case 'CHENNAI':
                return '#C00000';
            case 'Ernakulam Godown-1':
                return '#C55A11';
            case 'SCRAP ROOM - ERKM':
                return '#C55A11';
            case 'REWORK/REPAIR - ERKM':
                return '#C55A11';
            case 'ERNAKULAM':
                return '#C00000';
            default:
                return '#00B050';
        }
    }

    const filterColumnsBasedOnShowRecords=()=>{
        switch(showRecordsBy){
            case "both":
                // KME Changes
                if(isKMEDomain) {
                    const storeListTemp = storeList.filter((el) => !el.includes('REWORK/REPAIR') && !el.includes('SCRAP ROOM'));
                    const cbeWareHouseIdx = storeListTemp.findIndex(sl => sl === 'PRIMARY WAREHOUSE (KME)');
                    const cbeFirstFloorIdx = storeListTemp.findIndex(sl => sl === 'CBE SHOWROOM 1ST FLOOR');
                    const cbeFactoryStockIdx = storeListTemp.findIndex(sl => sl === 'FACTORY STOCK');
                    if(cbeWareHouseIdx !== -1 && cbeFirstFloorIdx !== -1 && cbeFactoryStockIdx !== -1) {
                        const cbeWareHouse = storeListTemp.find(sl => sl === 'PRIMARY WAREHOUSE (KME)');
                        const cbeFirstFloor = storeListTemp.find(sl => sl === 'CBE SHOWROOM 1ST FLOOR');
                        const cbeFactoryStock = storeListTemp.find(sl => sl === 'FACTORY STOCK');
                        storeListTemp.splice(cbeFirstFloorIdx, 3, cbeWareHouse, cbeFactoryStock, cbeFirstFloor);
                    }
                    return storeListTemp;
                }
                return storeList;
            case "plantName":
                return storeList.filter((el)=>inventoryParams.plantName.options.findIndex((opt)=>opt.optionName===el) > -1)

            case "storageName":
                return storeList.filter((el)=>inventoryParams.storageName.options.findIndex((opt)=>opt.optionName===el) > -1)

            default:
                return storeList;
        }
        
    }

    const exitFullScreen =()=>{
        let element = document.getElementById('inventoryDetails')  
        if (document.exitFullscreen) {
            document.exitFullscreen();
          }
         SetIsFullScreen(false)
          setRowsPerPage(10);
    }
    const renderinventoryList = () => {
        return parsedInventoryCurrentPage.map((item, i) => {
            return (
                    <tr className="vendorList" style={{ cursor: "none" }} key={item.itemName}>
                        <td className="vendorListInfo" style={{cursor: "auto", padding: "5px 20px",backgroundColor: 'white'}}>
                            {((currentPage-1) * PageSize) + i+1}
                        </td>
                        <td className="vendorListInfo" style={{cursor: "auto",padding: "5px 20px"}}>
                            {item.itemCode}
                        </td>
                        <td className="vendorListInfo stickyFirstColumn" style={{ cursor: "auto", padding: "5px 20px",backgroundColor: 'white' }}>
                            {item.itemName}
                        </td>
                        <td className="vendorListInfo">{item.UOM}</td>
                        {showWeight ? <>     <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                            {item.from}
                        </td>
                            <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                {item.to}
                            </td>
                            <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                {item.netWeight}
                            </td>
                            <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                {item.grossWeight}
                            </td> </> : null}
                           
                        {filterColumnsBasedOnShowRecords().map((store) => {
                            return (
                                <React.Fragment key={store}>
                                    <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px", textAlign: "center" }}>
                                        {item[`${store}_available`] || "--"}
                                    </td>
                                    {Object.entries(qtyColumnsCheck).map(([key,value])=>(
                                   value.checked ?  <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }} key={key}>
                                        {item[`${store}_${key}`] ? item[`${store}_${key}`] : " "}
                                    </td> : null))}
                                </React.Fragment>
                            );
                        })}
                        <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px", textAlign: "center" }}>
                            {item['totalQty'] !== undefined ? item['totalQty'] : "--"}
                        </td>
                    </tr>
            );
        });
    };

    return (
        <>
            <div className="inventoryPageFilterGrid" 
            // style={{display:isFullScreen?"none":"flex"}}
            >
                    {/*#e9e9e9*/}
                <style>
                    {`
                    tr td:nth-child(odd){
                        background-color: #f2eeee
                    }
                    .formAddButton{
                        margin: 1em !important
                    }
                    `}
                </style>
                <div
                    className="createPlantGeneralGrid"
                    style={{
                        margin: "0 0",
                        gridTemplateRows: "repeat(3, minmax(4rem, auto))",
                        flex: "3.5 1 0%",
                        gridTemplateColumns: "repeat(15, 1fr)",
                        gap: "10px 46px"
                    }}
                >
                    {renderFormElements({ elementList: inventoryParameterList, params: inventoryParams })}
                    <FormElement
                        inputType="addButton"
                        value="Reset Selections"
                        colSpan={5}
                        styles={{margin: '1em !important'}}
                        setInput={() => {
                            resetSelections();
                        }}
                    />
                    {
                    Object.entries(qtyColumnsCheck).map(([key,value])=>{
                  return  (<label key={key} className="inventoryChecks" style={{ fontSize: "16px", width: "150%",marginTop: "1em" }}>
                        <input type="checkbox" checked={value.checked} onChange={(e) => { handleQtyColumnCheck({key,e}) }} />
                        <span id="showWeight">{value.name}</span>
                    </label>)})
                    }
                </div>
                <div
                    style={{
                        borderRight: "2px solid lightgray",
                        margin: "1rem 0",
                        boxShadow: "0 0 -5px 2px rgb(235,235,235)",
                    }}
                ></div>
                <div style={{ display: "flex",flexDirection:"column",flex:"1 1 0%" , margin: "0 0 0 1rem",maxHeight:'250px' }}>
                    <div style={{lineHeight:'10px',marginBottom:'2rem'}}>
                            {renderFormElements({ elementList: splitByList, params: splitBy })}
                    </div>
                    <div style={{display:"flex"}}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        boxHeight={'60px'}
                        boxWidth={'100px'}
                        disabled={false}
                    />
                       <div style={{ fontSize: "10px" }}>&nbsp;</div>
                     <FormElement
                        setInput={(value) => {
                            // setRowsPerPage(value);
                            setShowRecordsBy(value)
                        }}
                        inputType='options'
                        value={showRecordsBy}
                        options={[
                            {optionId:"plantName",optionName:"Plant Name"},
                            {optionId:"storageName",optionName:"Store Name"},
                            {optionId:"both",optionName:"Plant and Store"},
                        ]}
                        hintText={"Show Records By"}
                        colSpan={0.5}
                        rowSpan={0}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        boxWidth={'180px'}
                        disabled={false}
                        isClearable={false}
                    /></div>

                    <label key="showWeight" className="inventoryChecks" style={{ fontSize: "16px", width: "150%",marginTop: "1em" }}>
                        <input type="checkbox" checked={isLogged.showHideWeight} onChange={(e) => { setShowWeight(e.target.checked); showHideWeight(e.target.checked); }} />
                        <span id="showWeight">Display Weight</span>
                    </label>
                    <FormElement
                        inputType="addButton"
                        value="Full Screen"
                        colSpan={5}
                        styles={{margin: '1em !important'}}
                        setInput={() => {
                            SetIsFullScreen(true);
                            setRowsPerPage(20);
                            fullScreen();
                        }}
                    />

                </div>
            </div>
            <div id ="inventoryDetails" style={{backgroundColor:"white"}}>
            {
               isFullScreen?
               <div >
                 <FormElement
                        inputType="addButton"
                        value="Back"
                        colSpan={5}
                        styles={{margin: '1em !important',width:"115px",backgroundColor:"grey"}}
                        setInput={() => {
                            // SetIsFullScreen(false);
                            exitFullScreen()
                            
                        }}
                    />
               </div>
               :null
            }
         <div className="vendorListArea" style={{margin:"1rem",marginTop:"0.25rem",maxHeight:isFullScreen?"fit-content":"400px"}} >       
            {splitBy.split.value !== "" ? (
                filteredInventoryList.length > 0 ? (
                    <table className="vendorListTable" style={{ borderCollapse: "unset" }}>
                        <thead style={{position:"sticky",top:"0px",zIndex:"1"}}>
                            <tr className="vendorListHeader" style={{ boxShadow: "none" }}>
                            <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px",backgroundColor: 'white' }}>
                                S. No.
                            </td>
                                <td className="vendorListHeading" rowSpan={2} style={{padding: "10px 20px"}}>
                                    Item Code
                                </td>
                                <td className="vendorListHeading stickyFirstColumn" rowSpan={2} style={{ padding: "10px 20px",top:"0px" }}>
                                    Item Name
                                </td>
                                {showWeight ? <><td className="vendorListHeading"  rowSpan={2} style={{ padding: "10px 20px" }}>
                                    Weight From(gms)
                                </td>
                                    <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px" }}>
                                        Weight To(gms)
                                    </td>
                                    <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px" }}>
                                        Net Weight(gms)
                                    </td>
                                    <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px" }}>
                                        Gross Weight(gms)
                                    </td> </> : null}
                                {filterColumnsBasedOnShowRecords().map((store) => {
                                    return (
                                        <td className="vendorListHeading" key={store} 
                                        style={{ padding: "10px 20px", textAlign: "center" ,
                                        color: checkIfColumnIsPlantName(store) && 'blue'
                                    }} 
                                        colSpan={storeHeaderColSpan}>
                                            {checkIfColumnIsPlantName(store) ? `${store} Total` : store}
                                        </td>
                                    );
                                })}
                                <td className="vendorListHeading" title="All Plants and Stores" rowSpan={2} style={{ padding: "10px 20px", textAlign: "center"}}>Total Qty</td> 
                            </tr>
                            <tr className="vendorListHeader" style={{ boxShadow: "none", position: "unset" }}>
                            {/* <td className="vendorListInfo" style={{backgroundColor: 'white'}}>&nbsp;</td>
                            <td className="vendorListInfo">&nbsp;</td>
                            <td className="vendorListInfo stickyFirstColumn" style={{backgroundColor: 'white'}}>&nbsp;</td>
                            {showWeight ? ["","","",""].map((item,ind)=> <td className="vendorListHeading stickyFirstColumn" key={ind}>&nbsp;</td>) : null}                                 */}
                            {filterColumnsBasedOnShowRecords().map((store,ind) => {
                                    return (
                                        <React.Fragment key={ind}>
                                            <td className="vendorListHeading" style={{ padding: "10px 20px", textAlign: "center" }}>
                                                Available Qty
                                            </td>
                                            {Object.entries(qtyColumnsCheck).map(([key,value])=>(
                                               value.checked ? (<td className="vendorListHeading" key={key} style={{ padding: "10px 20px", textAlign: "center" }}>
                                                                    {value.name}
                                                                </td> ) : null
                                            ))}
                                        </React.Fragment>
                                    );
                                })} 
                            </tr>
                        </thead>
                        {filteredInventoryList.map((item,index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div
                                        style={{
                                            padding: "30px 10px 2px 10px",
                                            fontWeight: "bold",
                                            color: "#59bfcd",
                                            fontSize: "16px",
                                            position:"sticky",
                                            left:"0px"
                                        }}
                                    >
                                        {item[0][splitBy.split.value]}
                                    </div>

                                    <tbody>
                                        {item.map((row,i) => {
                                            return (
                                                <tr className={"vendorList"} key={row.itemName}>
                                                    <td className="vendorListInfo" style={{cursor: "auto",padding: "5px 20px",backgroundColor: 'white'}}>
                                                        {((currentPage - 1) * PageSize) + i +1}
                                                    </td>
                                                    <td className="vendorListInfo" style={{cursor: "auto",padding: "5px 20px"}}>
                                                        {row.itemCode}
                                                    </td>
                                                    <td className="vendorListInfo stickyFirstColumn" style={{ cursor: "auto", padding: "5px 20px",backgroundColor: 'white' }}>
                                                        {row.itemName}
                                                    </td>
                                                    <td className="vendorListInfo" style={{cursor: "auto",padding: "5px 20px"}}>{item.UOM}</td>
                                                    {showWeight ? <>  <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                                        {row.from}
                                                    </td>
                                                        <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                                            {row.to}
                                                        </td>
                                                        <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                                            {row.netWeight}
                                                        </td>
                                                        <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px" }}>
                                                            {row.grossWeight}
                                                        </td> </> : null}
                                                  
                                                    {filterColumnsBasedOnShowRecords().map((store,ind) => {
                                                        return (
                                                            <React.Fragment key={ind}>
                                                                <td
                                                                    className="vendorListInfo"
                                                                    style={{ cursor: "auto", padding: "5px 20px", textAlign: "center" }}
                                                                >
                                                                    {row[`${store}_available`] || "--"}
                                                                </td>
                                                                {Object.entries(qtyColumnsCheck).map(([key,value])=>( 
                                                                    value.checked ?   
                                                                    <td
                                                                    className="vendorListInfo" key={key}
                                                                    style={{ cursor: "auto", padding: "5px 20px", textAlign: "center" }}>

                                                                    {row[`${store}_${key}`] ? row[`${store}_${key}`] : " "}
                                                                     </td> 
                                                                     : null)
                                                                     )}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    <td className="vendorListInfo" style={{ cursor: "auto", padding: "5px 20px", textAlign: "center" }}>
                                                        {row['totalQty'] !== undefined ? row['totalQty'] : "--"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </React.Fragment>
                            );
                        })}
                    </table>
                ) : (
                    <div className="noRecordsYet">No matching records found</div>
                )
            ) : parsedInventory.length > 0 ? (
                <table className="vendorListTable" style={{ borderCollapse: "unset" }}>
                    <thead style={{position:"sticky",top:"0px",zIndex:"1"}}>
                        <tr className="vendorListHeader" style={{ boxShadow: "none" }}>
                            <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px",backgroundColor: 'white' }}>
                                S. No.
                            </td>
                            <td className="vendorListHeading" rowSpan={2} style={{padding: "10px 20px"}}>
                                Item Code
                            </td>
                            <td className="vendorListHeading stickyFirstColumn" rowSpan={2} style={{ padding: "10px 20px",top:"0px",backgroundColor: 'white' }}>
                                Item Name
                            </td>
                            <td className="vendorListHeading" rowSpan={2} style={{padding: "10px 20px", color:'#FFFFFF', backgroundColor: '#FF0000'}}> UOM</td>
                            {showWeight ? <><td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px" }}>
                                Weight From(gms)
                            </td>
                                <td className="vendorListHeading"  rowSpan={2} style={{ padding: "10px 20px" }}>
                                    Weight To(gms)
                                </td>
                                <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px" }}>
                                    Net Weight(gms)
                                </td>
                                <td className="vendorListHeading" rowSpan={2} style={{ padding: "10px 20px" }}>
                                    Gross Weight(gms)
                                </td> </> : null}
                            {filterColumnsBasedOnShowRecords().map((store) => {
                                return (
                                    <td className="vendorListHeading" key={store} 
                                    style={{ padding: "10px 20px", textAlign: "center",
                                    // to differentate plant name by blue color
                                    // color: checkIfColumnIsPlantName(store) && 'blue',
                                    color: isKMEDomain && '#FFFFFF',
                                    backgroundColor: isKMEDomain && getStoreBGColors(store) }} 
                                    colSpan={storeHeaderColSpan}>
                                            {checkIfColumnIsPlantName(store) ? `${store} Total` : store}
                                    </td>
                                );
                            })}
                            <td className="vendorListHeading" title="All Plants and Stores" rowSpan={2} style={{ padding: "10px 20px", textAlign: "center", color:'#FFFFFF', backgroundColor: '#002060'}}>Total Qty</td> 
                        </tr>

                        <tr className="vendorListHeader" style={{ boxShadow: "none" }}>
                             {/* <td className="vendorListInfo" style={{backgroundColor: 'white'}}>&nbsp;</td>
                             <td className="vendorListInfo">&nbsp;</td>
                             <td className="vendorListInfo stickyFirstColumn" style={{backgroundColor: 'white'}}>&nbsp;</td>
                             <td className="vendorListInfo">&nbsp;</td>
                            {showWeight ? ["","","",""].map((item,ind)=> <td className="vendorListHeading stickyFirstColumn" key={ind}>&nbsp;</td>) : null} */}
                            {filterColumnsBasedOnShowRecords().map((store) => {
                                return (
                                    <React.Fragment key={store}>
                                        <td className="vendorListHeading" style={{ padding: "10px 20px", textAlign: "center" }}>
                                            Available Qty
                                        </td>
                                         {Object.entries(qtyColumnsCheck).map(([key,value])=>(
                                               value.checked ? (<td className="vendorListHeading" key={key} style={{ padding: "10px 20px", textAlign: "center" }}>
                                                                    {value.name}
                                                                </td> ) : null
                                            ))}
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>{renderinventoryList()}</tbody>
                </table>
            ) : (
                <div className="noRecordsYet">No matching records found</div>
            )}
        </div>
        {parsedInventory.length > 0 && !splitBy.split.value ? 
        <Pagination 
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={parsedInventory.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
       />
        : ''}
        </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        showHideWeight: (bool) => dispatch(showHideWeight(bool))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Inventory3);
