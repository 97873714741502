import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import TopBanner from "../SmallComponents/TopBanner";
import soPendingIcon from "../../assets/soPending.svg";
import PageTitle from "../SmallComponents/PageTitle";
import SoPendingList from "./SoPendingList";
import SoPendingDetail from "./SoPendingDetail";
import { exportPDF } from "../CommonFunctions/ExportPDF";

const SoPending = () => {
    const [currentSection, setCurrentSection] = useState("soPendingList");

    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "soPendingList" };
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF({}, "soPendingDetail") }, sectionName: "soPendingList" };

    const renderSection = (section) => {
        switch (section) {
            case "soPendingList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={soPendingIcon} pageTitle="Pending SO" />
                        <SoPendingList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "soPendingDetail":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={soPendingIcon} pageTitle={"Pending SO Detail"} buttonList={[goBackButton, downloadButton]} />
                        <SoPendingDetail setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Store" selectedSubMenu="Pending SO" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

export default SoPending;
