import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const CreatePurchaseReturn = ({ setSection, isLogged, addTokenToState }) => {
    // const datesStyle = { gridColumn: "auto / span 6", display: "flex", alignItems: "center", color: "#333333" };

    const purchaseReturnParamsList = ["poNo", "qcId", "prType", "prDate", "batchNo"];
    const [purchaseReturnParams, setPurchaseReturnParams] = useState({
        poNo: {
            inputType: "options",
            value: "",
            hintText: "Purchase Order No",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Purchase Order No",
        },
        qcId: {
            inputType: "options",
            value: "",
            hintText: "QC No",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Quality Checking No",
        },
        prType: {
            inputType: "options",
            value: "",
            hintText: "Return Type",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [
                { optionName: "Return", optionId: "Return" },
                { optionName: "Replace", optionId: "Replace" },
            ],
            errorMessage: "Select return type",
            // disabled: true,
        },
        prDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Return Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            maxdate: false,
            errorMessage: "Please Select return date",
        },

        batchNo: {
            inputType: "text",
            value: "",
            hintText: "Batch No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
    });

    const [qcDetail, setQcDetail] = useState({});
    const [showCustomerDetail, setShowCustomerDetail] = useState(false);
    const [qcList, setQcList] = useState({});

    const [instruction, setInstruction] = useState("");
    const [purchaseReturnDocument, setPurchaseReturnDocument] = useState("");

    const [itemsTotalCost, setItemsTotalCost] = useState(0);
    const [otherCharges, setOtherCharges] = useState(0);
    const [transportCharges, settransportCharges] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [transportChargesError, setTransportChargesError] = useState(false);
    const [otherChargesError, setOtherChargesError] = useState(false);
    const [poReturnError, setPoReturnError] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    useEffect(() => {
        var paramsCopy = Object.assign(purchaseReturnParams);
        if (purchaseReturnParams.poNo.value !== "") {
            paramsCopy = update(paramsCopy, { qcId: { options: { $set: qcList[purchaseReturnParams.poNo.value] }, value: { $set: "" } } });
        } else {
            paramsCopy = update(paramsCopy, { qcId: { options: { $set: [] }, value: { $set: "" } } });
            setShowCustomerDetail(false);
        }
        setPurchaseReturnParams(paramsCopy);
    }, [purchaseReturnParams.poNo.value]);

    useEffect(() => {
        if (purchaseReturnParams.qcId.value !== "") {
            getQcDetail();
        } else {
            setQcDetail({});
            setShowCustomerDetail(false);
        }
    }, [purchaseReturnParams.qcId.value]);

    const history = useHistory();
    async function getInformation() {
        await checkToken();
        var [poList, qcListCopy] = await getPoWithQcList();

        var paramsCopy = Object.assign(purchaseReturnParams);
        paramsCopy = update(paramsCopy, { poNo: { options: { $set: poList } } });
        setPurchaseReturnParams(paramsCopy);

        setQcList(qcListCopy);
    }

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
      
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getPoWithQcList() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/po-qc-list-pr-pre-create",
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            var qcList = {};
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.poNo, optionId: dataItem.poId };
                optionArray.push(a);

                var qcArray = [];
                dataItem.qcList.forEach((qc) => {
                    qcArray.push({ optionId: qc.qcId, optionName: qc.qcNo });
                });
                qcList[dataItem.poId] = qcArray;
            });

            return [optionArray, qcList];
        } else {
            console.log(data);
        }
    }

    async function getQcDetail() {
        var data = await fetchData({
            requestingPage: "customerList",
            method: "get",
            url: "po-fetch/pr-pre-create-detail/" + purchaseReturnParams.qcId.value,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var soDetailCopy = data.asset[0];

            soDetailCopy.qcLineItems.forEach((item) => {
                item.toSend = false;
                item.quantityError = false;
                item.quantityErrorMessage = "Please enter a valid return quantity";
                item.returnQuantity = "";
                item.returnPrice = 0;
            });
            setQcDetail(soDetailCopy);
            setShowCustomerDetail(true);
        } else {
            console.log(data);
        }
    }

    useEffect(() => {

        var _otherCharges = otherCharges;
        var _transportCharges = transportCharges;

        if (otherCharges === "") {
            _otherCharges = 0;
        }

        if (transportCharges === "") {
            _transportCharges = 0;
        }



        var returnVal =
            !isNaN(_otherCharges) && parseFloat(_otherCharges) >= 0
                ? !isNaN(_transportCharges) && parseFloat(_transportCharges) >= 0
                    ? parseFloat(_transportCharges) + parseFloat(_otherCharges) + itemsTotalCost
                    : "-"
                : "-";


        setTotalAmount(returnVal);
    }, [otherCharges, transportCharges, itemsTotalCost]);

    const updatePurchaseReturnParameter = ({ paramName, section, key, value }) => {
        if (section === "general") {
            var paramsCopy = Object.assign(purchaseReturnParams);
            paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });

            setPurchaseReturnParams(paramsCopy);
        }
    };

    const updateLineItems = (key, index, value) => {
        var paramsCopy = JSON.parse(JSON.stringify(qcDetail));

        paramsCopy = update(paramsCopy, { qcLineItems: { [index]: { [key]: { $set: value } } } });

        var price = 0;

        var row = paramsCopy.qcLineItems[index];

        var price = parseFloat(row.unitPrice) * parseFloat(value) + parseFloat(row.unitPrice) * parseFloat(value) * (parseFloat(row.tax) / 100);

        paramsCopy = update(paramsCopy, { qcLineItems: { [index]: { returnPrice: { $set: isNaN(price) ? 0 : price } } } });

        var sum = 0;

        paramsCopy.qcLineItems.map((items) => {
            sum = sum + items.returnPrice;
        });

        setItemsTotalCost(sum);
        setQcDetail(paramsCopy);
    };

    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(purchaseReturnParams);

        purchaseReturnParamsList.forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        var qcDetailCopy = JSON.parse(JSON.stringify(qcDetail));
        var proceed = false;

        qcDetailCopy?.qcLineItems?.forEach((line) => {
            if (line.returnQuantity !== "") {
                if (!isNaN(line.returnQuantity) && parseInt(line.returnQuantity) > 0) {
                    if (parseInt(line.returnQuantity) > line.quantity) {
                        line.quantityError = true;
                        line.toSend = false;
                        errorList.push(line.quantityErrorMessage);
                    } else {
                        line.toSend = true;
                        line.quantityError = false;
                        proceed = true;
                    }
                } else {
                    line.quantityError = true;
                    errorList.push(line.quantityErrorMessage);
                    line.toSend = false;
                }
            } else {
                line.toSend = false;
            }
        });
        setPoReturnError(errorList);

        if (errorList.length === 0 && proceed) {

            var data2server = new FormData();

            data2server.append("poId", purchaseReturnParams.poNo.value);
            data2server.append("qcId", purchaseReturnParams.qcId.value);
            data2server.append("prType", purchaseReturnParams.prType.value);
            data2server.append("prDate", purchaseReturnParams.prDate.value);

            if (purchaseReturnParams.batchNo.value !== "") {
                data2server.append("batchNo", purchaseReturnParams.batchNo.value);
            }

            data2server.append("netTotal", itemsTotalCost);
            data2server.append("totalAmount", totalAmount);

            if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
                errorList.push("Enter a valid number for Transport Charges");
                setTransportChargesError(true);
            } else {
                setTransportChargesError(false);
                data2server.append("transportCharges", transportCharges);
            }

            if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
                errorList.push("Enter a valid number for Other Charges");
                setOtherChargesError(true);
            } else {
                setOtherChargesError(false);
                data2server.append("otherCharges", otherCharges);
            }

            var lineItemsToServer = [];
            qcDetailCopy.qcLineItems.forEach((item) => {
                var newItem = {};
                if (item.toSend) {
                    newItem.qcLineItemId = item.qcLineItemId;
                    newItem.itemId = item.itemId;
                    newItem.returnQuantity = parseInt(item.returnQuantity);
                    newItem.returnPrice = item.returnPrice;

                    lineItemsToServer.push(newItem);
                }
            });
            data2server.append("prLineItems", JSON.stringify(lineItemsToServer));

            if (instruction !== "") {
                data2server.append("instruction", instruction);
            }
            if (purchaseReturnDocument !== "") {
                data2server.append("purchaseReturnDocument", purchaseReturnDocument);
            }

            submitData(data2server);
        } else {
     
            if (errorList.length === 0) {
                setPoReturnError(["Provide atleast one return item to submit"]);
                setModalText("Provide atleast one return item to submit");
                setDataSubmitted(true);
                setIsOpen(true);
            } else {
            }
        }
        setPurchaseReturnParams(paramsCopy);
        setQcDetail(qcDetailCopy);
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "post",
            url: "po-create/purchase-return",
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Purchase Return Created Successfully!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setPoReturnError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value}
                    setInput={(value) => {
                        updatePurchaseReturnParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                />
            );
        });
    };

    const RenderVendorDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">{qcDetail.vendorName}</div>
                <div className="detailText">
                    {/* {`vendor Id: ${qcDetail.vendorId}`} */}
                    {qcDetail.vendorAddress},
                    <br /> {qcDetail.vendorCity} {qcDetail.state} {qcDetail.vendorPinCode !== null ? ` - ${qcDetail.vendorPinCode}` : null}
                    {qcDetail.vendorMobile !== null || qcDetail.vendorPhone !== null ? <br /> : null}
                    {qcDetail.vendorMobile !== null || qcDetail.vendorPhone !== null ? `Phone: ` : null}
                    {qcDetail.vendorMobile !== null ? qcDetail.vendorMobile : null}
                    {qcDetail.vendorPhone !== null ? `, ${qcDetail.vendorPhone} ` : null}
                    {qcDetail.vendorEmail !== null ? <br /> : null}
                    {qcDetail.vendorEmail !== null ? `Email Id: ${qcDetail.vendorEmail}` : null}
                    {qcDetail.vendorGstNo !== null ? <br /> : null}
                    {qcDetail.vendorGstNo !== null ? `GST No:  ${qcDetail.vendorGstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const RenderPlantStorage = () => {
        return (
            <React.Fragment>
                {/* <div className="detailTitle">Storage Detail</div> */}
                <div className="detailText" style={{ padding: "0px", textAlign: "left", fontSize: "14px" }}>
                    <b>Order Date: &nbsp;</b> {qcDetail.poDate}
                </div>
                <div className="detailText" style={{ padding: "0px", textAlign: "left" }}>
                    <b>Checking Date: &nbsp;</b> {qcDetail.checkingDate}
                </div>
                <div className="detailText" style={{ padding: "0px", textAlign: "left" }}>
                    <b>Checking No:&nbsp; </b> {qcDetail.checkingNo}
                </div>
                <div className="detailText" style={{ padding: "0px", textAlign: "left" }}>
                    <b>Storage:&nbsp; </b>
                    {qcDetail.storageName}, {qcDetail.storageAddress}
                </div>
                {/* <div className="detailTitle">Plant Name</div> */}
                <div className="detailText" style={{ paddingTop: "0px", textAlign: "left" }}>
                    <b>Plant: &nbsp;</b>
                    {qcDetail.plantName}, {qcDetail.vendorAddress},{qcDetail.plantCity}
                    {qcDetail.plantZipCode !== null ? ` - ${qcDetail.plantZipCode}` : null}
                </div>
            </React.Fragment>
        );
    };

    const renderErrorMessage = () => {
        if (poReturnError.length > 0) {
            return poReturnError[0];
        } else return null;
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (poReturnError.length === 0) {
            setSection("purchaseOrderList");
        }
        setModalText("Creating Purchase Order Return. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem 3rem 1rem 3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "1" }}>
                            <div className="createSalesInvoiceGrid" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                                {renderFormElements({ elementList: purchaseReturnParamsList, param: purchaseReturnParams, section: "general" })}
                            </div>

                            {showCustomerDetail ? <RenderPlantStorage /> : null}
                        </div>

                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCustomerDetail ? <RenderVendorDetail /> : null}</div>
                            </div>
                        </div>
                    </div>

                    {qcDetail.qcLineItems?.length > 0 ? (
                        <table className="createItemPurchaseTable">
                            <thead>
                                <tr className="createVendorContactsTableHeader">
                                    <td>Item </td>
                                    <td>Item Code</td>
                                    <td>Weight From(gms)</td>
                                    <td>Weight To(gms)</td>
                                    <td>Net Weight(gms)</td>
                                    <td>Gross Weight(gms)</td>
                                    <td>HSN No</td>
                                    <td>UOM</td>
                                    <td>Unit Price</td>
                                    <td>Tax (%)</td>
                                    <td>Net Price</td>
                                    <td>Received Qty</td>
                                    <td>Rejected Qty</td>
                                    <td>Return Qty</td>
                                    <td>Return Price</td>
                                </tr>
                            </thead>
                            <tbody>
                                {qcDetail.qcLineItems.map((row, j) => (
                                    <tr className="createVendorContactsTableRows" key={j}>
                                        {[
                                            "itemName",
                                            "itemCode",
                                            "from",
                                            "to",
                                            "netWeight",
                                            "grossWeight",
                                            "hsnCode",
                                            "uom",
                                            "unitPrice",
                                            "tax",
                                            "netPrice",
                                            "receivedQuantity",
                                            "rejectedQuantity",
                                            "returnQuantity",
                                            "returnPrice",
                                        ].map((key, i) => {
                                            switch (key) {
                                                case "returnQuantity":
                                                    return (
                                                        <td key={i} style={{ width: "0px" }}>
                                                            <input
                                                                className={
                                                                    qcDetail.qcLineItems[j].quantityError
                                                                        ? "createInwardInputError"
                                                                        : "createInwardInput"
                                                                }
                                                                style={{ minWidth: "6rem" }}
                                                                type="text"
                                                                value={row[key]}
                                                                onChange={(e) => updateLineItems(key, j, e.target.value)}
                                                            />
                                                        </td>
                                                    );

                                                default:
                                                    return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{qcDetail.qcLineItems?.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>Purchase Return Instruction</div>
                            <textarea
                                className="multilineInput"
                                value={instruction}
                                rows="3"
                                cols="30"
                                name="text"
                                placeholder="Enter Notes"
                                style={{
                                    display: "flex",
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setInstruction(e.target.value);
                                }}
                            />

                            <div className="createSalesInvoiceGrid2">
                                {purchaseReturnDocument !== "" ? (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${9}` }}>
                                        <FormHint hintText="Purchase Return Document" mandatory={false} />
                                        <div className="inputDone">
                                            <span
                                                style={{
                                                    flex: 1,
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    // maxWidth: "168px",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                ✅ {purchaseReturnDocument.name}
                                            </span>
                                            <span
                                                style={{ paddingRight: "10px", cursor: "pointer", "&:hover": { backgroundColor: "gray" } }}
                                                onClick={() => setPurchaseReturnDocument("")}
                                                title="Remove and Re-upload LR Copy"
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <FormElement
                                        colSpan={9}
                                        inputType="upload"
                                        hintText="Purchase Return Document"
                                        setInput={(file) => {
                                            setPurchaseReturnDocument(file);
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="poSpacer">&nbsp;</div>

                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{itemsTotalCost}</span>
                            </div>
                            <div className="poFormElement">
                                <div className="formElement">
                                    <FormHint hintText="Other Charges" mandatory={false} />
                                    <InputField
                                        type="float"
                                        value={otherCharges}
                                        setName={(value) => setOtherCharges(value)}
                                        align="end"
                                        error={otherChargesError}
                                    />
                                </div>
                                <div className="formElement">
                                    <FormHint hintText="Transport Charges" mandatory={false} />

                                    <InputField
                                        type="float"
                                        value={transportCharges}
                                        setName={(value) => settransportCharges(value)}
                                        align="end"
                                        error={transportChargesError}
                                    />
                                </div>
                            </div>
                            <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <div className="poNetTotalAmount">{totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset all fields?");
                            if (reset) {
                                var paramsCopy = Object.assign(purchaseReturnParams);
                                purchaseReturnParamsList.forEach((element) => {
                                    paramsCopy[element].value = "";
                                    paramsCopy[element].error = false;
                                });
                                setPurchaseReturnParams(paramsCopy);
                                setQcDetail({});
                                setPoReturnError([]);
                                setInstruction("");
                                setTotalAmount(0);
                                setPurchaseReturnDocument("");
                                setOtherCharges(0);
                                settransportCharges(0);
                            }
                        }}
                    >
                        Reset Fields
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setPoReturnError([]);
                            // setLineItemErrors([]);
                            checkErrors();
                        }}
                    >
                        Submit for Approval
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseReturn);
