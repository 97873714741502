import update from "immutability-helper";

const employeeObj = { viewEmployeeId: null, editEmployeeId: null };

const EmployeeInfo = (state = employeeObj, action) => {
    switch (action.type) {
        case "EDIT_EMPLOYEE":
            return update(state, { editEmployeeId: { $set: action.payload.employeeId } });

        case "VIEW_EMPLOYEE":
            return update(state, { viewEmployeeId: { $set: action.payload.employeeId } });

        default:
            return state;
    }
};

export default EmployeeInfo;
