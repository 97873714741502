import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#333",
    padding: "5px 20px",
  }),
  control: (_, state) => ({
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    color: "#333",
    fontsize: "16px",
    border: state.selectProps.error
      ? "1px solid red"
      : "1px solid rgb(221,221,221)",
    boxSizing: "border-box",
    flex: 1,
    display: "flex",
    backgroundColor: state.selectProps.disabled ? "rgb(240,240,240)" : "white",
    whiteSpace: "noWrap",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "#333";
    const fontSize = "16px";

    return { ...provided, opacity, transition, color, fontSize };
  },
  indicatorSeparator: () => {
    return null;
  },
};

const MultiSelectDropdown = ({
  options,
  selected,
  setInput,
  error,
  disabled,
  isCreatableMulti,
  hasSelectAll,
}) => {
  var optionsFormatted = [];
  if (options !== null) {
    options.forEach((option) => {
      var formatedOption = { value: option.optionId, label: option.optionName };
      optionsFormatted.push(formatedOption);
    });
  }
  if (!isCreatableMulti) {
    return (
      <Select
        options={optionsFormatted}
        styles={customStyles}
        onChange={(value) => {
          //console.log(value);
          value !== null ? setInput(value) : setInput([]);
        }}
        error={error}
        value={selected}
        isMulti
        isSearchable={true}
        isClearable={true}
        disabled={disabled}
        isDisabled={disabled}
      />
    );
  } else {
    if (hasSelectAll && optionsFormatted.length > 0) {
      let allOptionValues = optionsFormatted.map((opt) => opt.value);
      optionsFormatted.unshift({
        label: "SELECT ALL",
        value: allOptionValues,
      });
    }
    return (
      <CreatableSelect
        className="basic-multi-select"
        isMulti
        styles={customStyles}
        onChange={(value) => {
          //console.log(value);
          if (value !== null) {
            if (value.some((obj) => obj.label === "SELECT ALL")) {
              setInput([optionsFormatted[0]]);
            } else {
              setInput(value);
            }
          } else {
            setInput([]);
          }
        }}
        error={error}
        value={selected}
        placeholder={"Type and Press Enter to Create Custom value"}
        isSearchable={true}
        isClearable={true}
        disabled={disabled}
        isDisabled={disabled}
        options={optionsFormatted}
      />
    );
  }
};

export default React.memo(MultiSelectDropdown);
