import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import enterotLogo from "../../assets/enterotNewLogo.png";
import person from "../../assets/ProfilePlaceholder.svg";
import {
  addEmpImage,
  addEmpInfo,
  addHomeRoute,
  addToken,
  addUserAccess,
  addPlantOptions,
} from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import {
  validateEmployeeName,
  validatePassword,
} from "../CommonFunctions/ValidateFields";
import "../styles/Login.css";
import eyeShow from "../../assets/eye-show.svg";
import eyeHide from "../../assets/eye-hide.svg";

const Login2 = ({
  addTokenToState,
  addEmployeeInfo,
  addEmpLoyeeImage,
  addAccess,
  addRoute,
  addPlantOptions,
  isLogged
}) => {
  const [accessTemplate, setAccessTemplate] = useState({
    "Sales Dashboard": null,
    "Quote Dashboard":null,
    "Stock Transfer Dashboard":null,
    "Stock Dashboard" :null,
    "Production Dashboard" :null,
    "Sales Report": null,
    "Customer Ledger": null,
    "Outstanding statement": null,
    "Stock Report": null,
    "Item Report": null,
    "Movement Analysis": null,
    "Purchase Report":null,
    "Vendor Ledger":null,
    "Vendor Invoice":null,
    "Import Report": null,
    "Production Report":null,
    "Production Log Report": null,
    "Employee Efficiency Report":null,
    Role: null,
    "Role Control": null,
    "Screen Control": null,
    UOM: null,
    "Plant & Store": null,
    "Item Menu": null,
    "Item Category": null,
    "Item Brand": null,
    "Item Type": null,
    "Item Process": null,
    "Item Purchase Cost": null,
    "Item Price Type": null,
    "Item Tax": null,
    Items: null,
    "Item Sales Price": null,
    "Item Currency": null,
    "Cutting Length": null,
    "Section Master":null,
    "Premium Charges":null,
    "Location":null,
    Employee: null,
    Vendor: null,
    Customer: null,
    Transporter: null,
    "Clearing Agent": null,
    "Sales Order": null,
    "Sales Invoice": null,
    "Other Charges": null,
    "Customer Receipt": null,
    Inventory: null,
    OPD: null,
    Inward: null,
    Outward: null,
    "Quality Checking": null,
    "Stock Transfer": null,
    "Pending SO": null,
    "Pending MRS": null,
    IDN: null,
    "Packaging":null,
    "Bundle Creation":null,
    "Packing Slip": null,
    "Store Dashboard":null,
    "Purchase Order": null,
    "Purchase Invoice": null,
    "Vendor Payments": null,
    "Import PO": null,
    "Purchase Import": null,
    "Import Dashboard":null,
    "Production Order": null,
    "Work Order": null,
    "Process Log": null,
    "Material Requisition": null,
    "DC List": null,
    "Bill Of Material" : null,
    "Sub Contracting": null,
    "Loading Slip" : null,
    "Sales Opportunities" : null,
    "Sales Quote" : null,

  });

  const [employeeName, setemployeeName] = useState("");
  const [employeeNameValid, setemployeeNameValid] = useState(true);
  const [maskPassword,setMaskPassWord]=useState(true);
  const [password, setpassword] = useState("");
  const [passwordValid, setpasswordValid] = useState(true);
  const [serverResult, setServerResult] = useState({
    result: "pending",
    message: "",
  });
  const [callingServerSpinner, setcallingServerSpinner] = useState(false);

  const history = useHistory();

  async function callServer() {
    if (validatePassword(password) && validateEmployeeName(employeeName)) {
      // clear bottom message
      setcallingServerSpinner(true);
      setServerResult({ result: "pending", message: "" });

      // Axios call
      var data = await fetchData({
        requestingPage: "login",
        method: "post",
        url: "auth/login",
        data: {
          employeeId: employeeName,
          accountPassword: Buffer.from(password).toString("base64"),
        },
      });
      // console.log(data);
      setcallingServerSpinner(false);

      if (data.msg === "success") {
        setServerResult({
          result: "success",
          message: "Successfully logged In",
        });
        await addTokenToState(data.token, data.employeeId);
        var url = await getEmpInfo(data.token, data.employeeId);
        addRoute(`/${url || "salesdashboard"}`);
        setTimeout(() => {
          history.push(`/${url || "salesdashboard"}`);
        }, 500);
      } else {
        var errorDesc = "";

        switch (data.desc) {
          case "employeeName not exists":
            errorDesc = "Employee does not exist";
            break;

          case "employeeId not exists":
            errorDesc = "Employee ID does not exist";
            break;

          case "password not matches":
            errorDesc = "Password doesn't match for the given Employee";
            break;

          case "field missing or wrong field name":
            errorDesc = "Field missing or Wrong field name";
            break;

          default:
            errorDesc = "Error: Please try valid login credentials";
        }
        setServerResult({ result: data.msg, message: errorDesc });
      }
    } else {

      setpasswordValid(validatePassword(password));
    }
  }

  async function getEmpInfo(token, employeeId) {
    var info = await fetchData({
      requestingPage: "customerList",
      method: "get",
      url: `fetch/user-profile/${employeeId}`,
      headers: { token: token, module: "Login" },
    });
    if (info.msg === "success") {
      var data = info.asset[0];

      addEmployeeInfo(
        data.employeeId,
        data.employeeName,
        data.role,
        data.roleId,
        data.editableForApproved
      );

      if (data.roleId === 1) {

        addAccess({
          "Sales Dashboard": "View",
          "Quote Dashboard":"View",
          "Stock Transfer Dashboard" :"View",
          "Store Dashboard" :"View",
          "Production Dashboard" :"View",
          "Sales Report": "Edit",
          "Customer Ledger": "Edit",
          "Outstanding statement": "Edit",
          "Stock Report": "Edit",
          "Item Report":"Edit",
          "Movement Analysis": "Edit",
          "Purchase Report":"View",
          "Vendor Ledger":"View",
          "Vendor Invoice":"View",
          "Import Report": "View",
          "Production Report":"View",
          "Production Log Report":"View",
          "Employee Efficiency Report":"View",
          Role: "Edit",
          "Role Control": "Edit",
          "Screen Control": "Edit",
          UOM: "Edit",
          "Plant & Store": "Edit",
          "Item Menu": "View",
          "Item Category": "Edit",
          "Item Brand": "Edit",
          "Item Type": "Edit",
          "Item Process": "Edit",
          Items: "App/Dec",
          "Item Purchase Cost": "Edit",
          "Item Price Type": "Edit",
          "Item Tax": "Edit",
          "Item Sales Price": "Edit",
          "Item Currency": "Edit",
          "Cutting Length":"Edit",
          "Section Master":"Edit",
          "Premium Charges":"Edit",
          "Location":"Edit",
          Employee: "Edit",
          Vendor: "Edit",
          Customer: "Edit",
          Transporter: "Edit",
          "Clearing Agent": "Edit",
          "Sales Order": "App/Dec",
          "Sales Invoice": "App/Dec",
          "Other Charges": "App/Dec",
          "Customer Receipt": "App/Dec",
          Inventory: "View",
          OPD: "App/Dec",
          Inward: "App/Dec",
          Outward: "App/Dec",
          "Quality Checking": "App/Dec",
          "Stock Transfer": "App/Dec",
          "Pending SO": "Edit",
          "Pending MRS": "Edit",
          IDN: "Edit",
          "Packaging":"Edit",
          "Bundle Creation":"Edit",
          "Packing Slip":"Edit",
          "Purchase Order": "App/Dec",
          "Purchase Invoice": "App/Dec",
          "Vendor Payments": "App/Dec",
          "Purchase Dashboard":"View",
          "Import PO": "Edit",
          "Purchase Import": "App/Dec",
          "Import Dashboard":"View",
          "Production Order": "App/Dec",
          "Work Order": "App/Dec",
          "Process Log": "App/Dec",
          "Material Requisition": "App/Dec",
          "DC List": "App/Dec",
          "Bill Of Material": "App/Dec",
          "Sub Contracting": "View",
          plantAccess: null,
          "Loading Slip" : "Edit",
          "Sales Opportunities" :"Edit",
          "Sales Quote" : "Edit",
          "Sales Lead":"Edit",
          
        });

        var redirectUrl = "salesdashboard";

        // Grant access to everything
      } else {
        var accessObjCopy = Object.assign(accessTemplate);
        var redirectUrl = "";

        // if (data.screenAccess[0].subMenu.match(/Item/g)) {
        //     console.log("Fitst object is Item");
        //     redirectUrl = "itemmenu";
        // } else

        //console.log("First Object is", data.screenAccess[0].subMenu);
        if (data.screenAccess.length > 0) {
          data.screenAccess[0]["subMenus"].forEach((element, index) => {
            accessObjCopy[element] = data.screenAccess[0]["access"][index];
          });
          //console.log(accessObjCopy);
          accessObjCopy["plantAccess"] = [...data.screenAccess[0].plantAccess];
          switch (data.screenAccess[0].subMenus[0]) {
            case "Role":
              redirectUrl = "role";
              break;

            case "Role Control":
              redirectUrl = "rolecontrol";
              break;

            case "Screen Control":
              redirectUrl = "screencontrol";
              break;

            case "UOM":
              redirectUrl = "uom";
              break;

            case "Plant & Store":
              redirectUrl = "plant";
              break;

            case "Employee":
              redirectUrl = "employee";
              break;

            case "Item Menu":
              redirectUrl = "itemmenu";
              break;

            case "Vendor":
              redirectUrl = "vendor";
              break;

            case "Customer":
              redirectUrl = "customer";
              break;

            case "Transporter":
              redirectUrl = "transporter";
              break;

            case "Clearing Agent":
              redirectUrl = "clearing";
              break;
            
            case "Sales Dashboard":
              redirectUrl = "salesdashboard";
              break;

            case "Stock Transfer Dashboard":
              redirectUrl = "stockdashboard";
              break;
              
            case "Quote Dashboard":
                redirectUrl = "quotesdashboard";
                break;

            case "Purchase Order":
              redirectUrl = "purchaseorder";
              break;

            case "Purchase Invoice":
              redirectUrl = "purchaseinvoice";
              break;
              
            case "Vendor Payments":
              redirectUrl = "vendorpayments";
              break;

            case "Purchase Dashboard":
              redirectUrl = "purchasedashboard";
              break;

            case "Purchase Report":
              redirectUrl = "purchasereport";
              break;

            case "Vendor Ledger":
              redirectUrl = "vendor-ledger";
              break;

            case "Vendor Invoice":
              redirectUrl = "vendor-invoice";
              break;

            case "Inventory":
              redirectUrl = "store";
              break;

            case "OPD":
              redirectUrl = "opd";
              break;

            case "Outward":
              redirectUrl = "outward";
              break;

            case "Inward":
              redirectUrl = "inward";
              break;

            case "Quality Checking":
              redirectUrl = "quality";
              break;

            case "Stock Transfer":
              redirectUrl = "transfer";
              break;

            case "Pending SO":
              redirectUrl = "sopending";
              break;

            case "Pending MRS":
              redirectUrl = "idnpending";
              break;

            case "IDN":
              redirectUrl = "idn";
              break;
            
            case "Stock Report":
              redirectUrl = "stockreport";
              break;

            case "Movement Analysis":
              redirectUrl = "movement-analysis"
              break;
              
            case "Packing Slip":
              redirectUrl = "packing-slip"
              break;

            case "Loading Slip":
              redirectUrl = "loading-slip"
              break;
              
            case "Import PO":
              redirectUrl = "importpo";
              break;

            case "Purchase Import":
              redirectUrl = "purchaseimport";
              break;

            case "Sales Order":
              redirectUrl = "salesorder";
              break;

            case "Sales Return":
              redirectUrl = "salesorder";
              break;

            case "Sales Invoice":
              redirectUrl = "salesinvoice";
              break;

            case "Other Charges":
              redirectUrl = "othercharges";
              break;

            case "Customer Receipt":
              redirectUrl = "customerreceipts";
              break;

            case "Sales Report":
              redirectUrl = "salesreport";
              break;

            case "Customer Ledger":
              redirectUrl = "customer-ledger";
              break;
              
            case "Outstanding statement":
              redirectUrl = "outstanding-statement";
              break;

            case "Sales Opportunities":
              redirectUrl = "sales-opportunities";
              break;

            case "Sales Quote":
              redirectUrl = "sales-quote";
              break;

            case "Production Report":
              redirectUrl = "production-report";
              break;
            
            case "Prdocution Log Report":
              redirectUrl = "production-log-report";
              break;

            case "Employee Efficiency Report":
              redirectUrl = "employee-efficiency-report";
              break;
              
            case "Production Order":
              redirectUrl = "production";
              break;
            
              case "Work Order":
              redirectUrl = 'work-order';
              break;

            case "Process Log":
              redirectUrl = 'process-log';
              break;

            case "Material Requisition":
              redirectUrl = "materialrequisition";
              break;

            case "DC List":
              redirectUrl = "dclist";
              break;

            case "Bill Of Material":
              redirectUrl = "bill-of-material";
              break;
            case "Sub Contracting":
              redirectUrl = "subcontracting";
              break;

            case "Import Report":
              redirectUrl = "import-report";
              break;

            case "Sales Lead":
              redirectUrl = "sales-lead";
              break;

            case "Production Dashboard":
              redirectUrl = "productiondashboard";
              break;

            case "Store Dashboard":
              redirectUrl = "storedashboard";
              break;
            
            default:
              break;
          }
        }
        addAccess(accessObjCopy);

        // set these access screens to a compatible format that can be read by menulist page and also by each individual page
      }
    }
    var image = await fetchData({
      requestingPage: "employeeImage",
      method: "get",
      url: `image/emp-photo/${data.employeeId}`,
      headers: { token: token, module: "Login" },
    });
    if (image !== "") {
      var empImage = `data:image/png;base64,${image}`;
      addEmpLoyeeImage(empImage);
    } else {
      addEmpLoyeeImage(person);
    }

    //This plant Options is For Plant Filters in List Page
    var plant = await fetchData({
      requestingPage: "screenControl",
      method: "get",
      url: "fetch/plants",
      headers: { token: token, module: "Screen Control" },
    });
    if (plant.msg === "success") {
      addPlantOptions(plant.asset||[]);
    } else {
      console.log(plant);
    }

    return redirectUrl;
  }
  useEffect(()=>{
  if(!(isLogged.homeRoute === '/salesdashboard' && isLogged.access["Sales Dashboard"] === null)){
    history.push(isLogged.homeRoute);
  }
  },[])
  return (
    <div className="container">
      <form
        className="loginColumn"
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        <img className="loginPageLogo" src={enterotLogo} alt="enterot"/>

        <span className="loginHeader">Login</span>
        <input
          className={!employeeNameValid ? "loginInput error" : "loginInput"}
          type="text"
          value={employeeName}
          onChange={(e) => setemployeeName(e.target.value)}
          onBlur={() =>
            setemployeeNameValid(validateEmployeeName(employeeName))
          }
          placeholder="UserName"
        />
        {/* <span className="errorText">
          {" "}
          {!employeeNameValid
            ? "Please enter a valid employee name"
            : "\u00A0"}{" "}
        </span> */}
        <br />
        <div style={{position:"relative"}}>
        <input
          className={!passwordValid ? "loginInput error" : "loginInput"}
          type={maskPassword ? "password" : "text"}
          value={password}
          onFocus={(e) => {
            // setpasswordValid(validatePassword(password));
          }}
          onChange={(e) => {
            setpassword(e.target.value);
            // setpasswordValid(validatePassword(password));
          }}
          onBlur={(e) => setpasswordValid(validatePassword(password))}
          placeholder="Password"
        /><img src={maskPassword ? eyeShow : eyeHide} onClick={(e)=>{setMaskPassWord(!maskPassword)}} 
        className="eyeIcon" width="20px" height="20px" alt="eyeIcon" title="Show/Hide Password"/>
        </div>
        <span className="errorText">
          {!passwordValid ? `Minimum 6 characters` : "\u00A0"}{" "}
        </span>

        <span
          className={
            serverResult.result === "failure" ? "errorText" : "successText"
          }>
          {callingServerSpinner
            ? "Logging in. Please wait..."
            : serverResult.result === "success" ||
              serverResult.result === "failure"
            ? serverResult.message
            : "\u00A0"}
        </span>

        <button
          className={
            employeeNameValid && passwordValid
              ? "loginButton"
              : "loginButton disabled"
          }
          style={{ margin: "auto" }}
          onClick={() => callServer()}>
          Login
        </button>
      </form>
    </div>
  );
};
const mapStateToProps=(state)=>{
  return {
    isLogged: state.isLogged
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
      addEmployeeInfo: (employeeId, employeeName, role, roleId,editableForApproved) =>
      dispatch(addEmpInfo(employeeId, employeeName, role, roleId,editableForApproved)),
    addEmpLoyeeImage: (empImage) => dispatch(addEmpImage(empImage)),
    addAccess: (userAccess) => dispatch(addUserAccess(userAccess)),
    addRoute: (homeRoute) => dispatch(addHomeRoute(homeRoute)),
    addPlantOptions: (options) => dispatch(addPlantOptions(options)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login2);
