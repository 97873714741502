import React, { useState } from "react";
import { connect } from "react-redux";
import MenuList from "../Menu/MenuList";
import TopBanner from "../SmallComponents/TopBanner";
import RoleControlList from "./RoleControlList";
import CreateRoleControl from "./CreateRoleControl";
import EditRoleControl from "./EditRoleControl";
import PageTitle from "../SmallComponents/PageTitle";
import roleControlIcon from "../../assets/role.svg";


const RoleControl = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("roleControl");

    var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "roleControl" }];

    var createRoleControlButton = {
        buttonName: "Create Role Control",
        className: "employeeButton",
        setSection: setCurrentSection,
        sectionName: "createRoleControl",
    };

    const renderSection = (section) => {
        switch (section) {
            case "roleControl":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={roleControlIcon}
                            pageTitle="Role Control Role"
                            buttonList={
                                isLogged.roleId === 1
                                    ? [createRoleControlButton]
                                    : isLogged.access["Role Control"] === "Create" || isLogged.access["Role Control"]=== "Edit"
                                    ? [createRoleControlButton]
                                    : null
                            }
                        />
                        <RoleControlList setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "createRoleControl":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={roleControlIcon} pageTitle="Create Role Control Role" buttonList={goBackButton} />
                        <CreateRoleControl setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );

            case "editRoleControl":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={roleControlIcon} pageTitle="Edit Role Control Role" buttonList={goBackButton} />
                        <EditRoleControl setSection={(value) => setCurrentSection(value)} />
                    </React.Fragment>
                );
        }
    };
    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Master Configure" selectedSubMenu="Role Control" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};

export default connect(mapStateToProps, null)(RoleControl);
