import React, { Fragment, useEffect, useCallback, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import editIcon from "assets/edit.svg";
import {  editCuttingLengthId } from "redux/Item/ItemInfoActions";
import CreateEditModal from "components/SmallComponents/CreateEditModal";
import SearchableDropDown from "components/SmallComponents/SearchableDropDown";

const EditCuttingLength = ({
  isLogged,
  editCuttingLengthId,

  itemInfo,
  setSection,
  addTokenToState
}) => {
  const initialCuttingLength = useMemo(()=>({cuttingLength:"",uomId:""}),[]);
  const [cuttingLengthList, setCuttingLengthList] = useState([]);
  const [updatedCuttingLength, setUpdatedCuttingLength] = useState({...initialCuttingLength});
  const [uomOptionsList, setUomOptionsList] = useState([]);

  const history = useHistory();
  const checkToken = useCallback(async () => {
    
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
     
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }, [isLogged, history, addTokenToState]);

  const getCuttingLength = useCallback(async () => {
    var data = await fetchData({
      requestingPage: "sectionMaster",
      method: "post",
      url: "fetch/cutting-length",
      headers: { token: isLogged.accessToken, module: "Cutting Length" },
    });
    if (data.msg === "success") {
      if (data.asset.length === 0) {
        console.log(data);
      } else {
        setCuttingLengthList(data.asset);
        let toEdit = data.asset.filter((row)=>row.cuttingLengthId === itemInfo.cuttingLengthId)[0];
        setUpdatedCuttingLength(toEdit);
      }
    } else {
      console.log(data);
    }
    var uomList = await fetchData({
      requestingPage: "uomList",
      method: "get", url: "fetch/uom",
      headers: { token: isLogged.accessToken, module: "UOM" }
    });

    if (uomList.msg === "success") {
      setUomOptionsList(uomList.asset);
    } else {
      console.log(uomList);
    }
  }, [isLogged,itemInfo.cuttingLengthId])

  const getInformation = useCallback(
    async () => {
      await checkToken();
      await getCuttingLength();
    },
    [checkToken, getCuttingLength]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  const submitUpdatedCuttingLength = useCallback(async () => {

    var dataSent = await fetchData({
      requestingPage: "editUom",
      method: "put",
      url: "edit/cutting-length/"+itemInfo.cuttingLengthId,
      headers: { token: isLogged.accessToken, module: "Cutting Length" },
      data: updatedCuttingLength,
    });

    setDataSubmitted(true);
    if (dataSent.msg === "success") {
      editCuttingLengthId(null);
      setModalText("Saved Cutting Length change successfully!");
      setUpdatedCuttingLength({...initialCuttingLength});
      getInformation();
    } else {
      setModalText(dataSent.desc);
    }
  }, [isLogged, editCuttingLengthId, getInformation, updatedCuttingLength,itemInfo.cuttingLengthId,initialCuttingLength])
  const renderCuttingLengthEditTable = () => {
    return (
      cuttingLengthList.map((row, index) => {
        if (row.cuttingLengthId === itemInfo.cuttingLengthId) {
          return (
            <tr className="uomListData" key={index}>
              <td className="uomSymbolData2">
                <input
                  className="createUom"
                  type="text"
                  value={updatedCuttingLength.cuttingLength }
                  onChange={(e) => setUpdatedCuttingLength({ ...updatedCuttingLength, cuttingLength: e.target.value })}
                  autoFocus
                />
              </td>
              <td className="uomSymbolData2" style={{ minWidth: "10rem" }}>
                <SearchableDropDown
                  options={uomOptionsList}
                  selected={updatedCuttingLength.uomId}
                  multiple={false}
                  isClearable={true}
                  setInput={(value) => setUpdatedCuttingLength({ ...updatedCuttingLength, uomId: value })}
                />
              </td>
              <td className="uomSymbolData2" >
                <button
                  style={{ width: "7rem" }}
                  className="cancelRoleButton"
                  onClick={() => {
                    editCuttingLengthId(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ width: "7rem" }}
                  id="SubmitCreate"
                  className="createRoleButton"
                  onClick={() => {
                    if(updatedCuttingLength.cuttingLength && updatedCuttingLength.uomId){
                      setIsOpen(true);
                      submitUpdatedCuttingLength();
                    }
                  }
                  }
                >
                  Save
                </button>
              </td>
            </tr>
          )
        } else {
          return (
            <tr className='uomListData' key={index}>
              <td className="uomSymbolData">{row.cuttingLength}</td>
              <td className="uomSymbolData">{row.symbol}</td>
              <td className="uomActionData">
                <img
                  className="uomEditImage"
                  alt="Edit Record"
                  src={editIcon}
                  onClick={() => {
                    editCuttingLengthId(row.cuttingLengthId);
                    setUpdatedCuttingLength({...row});
                  }}
                  title={"Edit Record"}
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          )
        }
      })
    )
  }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Updating Cutting Length. Please wait...");

  const submitOkClick = () => {
    setIsOpen(false);
    setModalText("Updating Cutting Length. Please wait...");
  };
  return (
    <Fragment>
      <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
      <div className="uomListArea">
        <table className="uomListTable">
          <thead>
          <tr className="uomListHeader">
            <td className="uomSymbolHeader">
              <span>Cutting Length</span>
            </td>
            <td className="uomSymbolHeader">
              <span>UOM</span>
            </td>
            <td className="uomActionHeader">
              <span>Action</span>
            </td>
          </tr>
          </thead>
          <tbody>
          {renderCuttingLengthEditTable()}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    itemInfo: state.itemInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editCuttingLengthId: (value) => dispatch(editCuttingLengthId(value)),
    addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCuttingLength);