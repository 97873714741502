import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { editBOMId, viewBOMId } from "../../redux/PurchaseOrder/PoInfoActions";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import viewIcon from "../../assets/view.svg";
import approveIcon from "../../assets/approve2.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FormElement from "../SmallComponents/FormElement";
import Pagination from "../CommonFunctions/pagination";
import { handleSort } from "../CommonFunctions/utils";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";

const BomListPage=({isLogged,poInfo,addTokenToState,editBOMId,viewBOMId,setSection})=>{

const [bomList,setBomList]=useState([]);

    useEffect(()=>{
    getInformation()
    },[])

    async function getInformation(){
        await checkToken();
        await getBOMLIST();
    }
    const history = useHistory();

    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getBOMLIST(){
        var result =await fetchData({
            requestingPage: "BillOfMaterial",
            url: 'bill-of-material/list-page',
            method: 'post',
            headers: { token: isLogged.accessToken,module: "Bill Of Material" },
        });

        if(result && result.msg==='success'){
            setBomList(result.asset);
        } else{
            console.log(result);
        }
    }
    const access = isLogged.access["Bill Of Material"];

    return(
        <React.Fragment>
            <div className="vendorListArea">
            <table className="vendorListTable">
                <thead>
                    <tr className="vendorListHeader">
                        <td className="vendorListHeading">Bom Id</td>
                        <td className="vendorListHeading">Bom Document Name</td>
                        <td className="vendorListHeading">FG Name</td>
                        <td className="vendorListHeading">Number of  RM</td>
                        <td className="vendorListHeading">Created By</td>
                        <td className="vendorListHeading">Created Date</td>
                        <td className="vendorListHeadingActions">Actions</td>
                    </tr>
                    </thead>
                    <tbody>
                        {bomList.length > 0 ? bomList.map((row,ind)=>(
                         <tr className="vendorList" key={ind}>
                            <td className="vendorListInfo">{row.bomNo}</td>
                            <td className="vendorListInfo">{row.bomDocumentName}</td>
                            <td className="vendorListInfo">{row.fgName}</td>
                            <td className="vendorListInfo">{row.rmCount}</td>
                            <td className="vendorListInfo">{row.createdBy}</td>
                            <td className="vendorListInfo">{row.createdDate}</td>
                            <td className="vendorListInfo">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View BOM Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSection("bomDetail");
                                    // setApproval(false);
                                    editBOMId(row.bomId);
                                }}
                                />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec"? editIcon : editIconDisabled}
                                title={access === "Edit"|| access === "App/Dec" ? "Edit Bill of Material" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access === "Edit" || access === "App/Dec"){
                                    editBOMId(row.bomId);
                                    setSection("editBOM");
                                  }
                                }}
                                style= {access === "Edit" || access === "App/Dec"? {cursor: "pointer"} : {cursor: "not-allowed"}}
                            /></td>
                        </tr>))
                        : null}
                    </tbody>
            </table>
            {bomList.length === 0 ? <div className="noRecordsYet">No items available</div>: null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewBOMId: (bomDocId) => dispatch(viewBOMId(bomDocId)),
        editBOMId: (bomDocId) => dispatch(editBOMId(bomDocId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BomListPage);


