import React,{ Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import fetchData from "serverCall/fetchData";
import viewIcon from "assets/view.svg";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import  {addToSalesOpportunityId} from "../../redux/Store/StoreInfoActions"

const SalesDealsList =({isLogged,setCurrentSection,viewSaleOpportunityId,editSalesOpportunityId})=>{
const [dealList,setDealList] = useState([])
const access = isLogged.access["Sales Opportunities"];


//function for get sales opportunity details
  const salesOpportunityDetail =async()=>{

    let data = await fetchData({
      requestingPage:"employeeDetail",
      method:"get",
      url:"so-fetch/sales-opportunity-list",
      headers:{token:isLogged.accessToken}
    })

    if(data.msg === "success"){
      setDealList(data.asset)
    }
  }

  useEffect(()=>{
    salesOpportunityDetail();
  },[])

  const renderDealList =()=>{
    return dealList.map((item,i)=>{
      return (
         <Fragment key ={i}>
            <tr className="vendorList">
        <td className="vendorListInfo">{item.opportunityName}</td>
        <td className="vendorListInfo">{item.dealOwner}</td>
        <td className="vendorListInfo">{new Date(item.closeDate).toLocaleDateString()}</td>
        <td className="vendorListInfo">{item.amount||0}</td>
        <td className="vendorListInfo">{item.plantName}</td>
        <td className="vendorListInfo">{item.priority}</td>

        <td className="vendorListInfoActions">
        <img
                  alt="View Record"
                  className="vendorActionImages"
                  src={viewIcon}
                  title="View Invoice Detail"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewSaleOpportunityId(item.opportunityId)
                    setCurrentSection("salesDealDetail")
                  }}
                />
                 <img
                  alt="Edit Record"
                  className="vendorActionImages"
                  src={
                    access === "Edit" || access === "App/Dec"
                      ? editIcon
                      : editIconDisabled
                  }
                  title={
                    access === "Edit" || access === "App/Dec"
                      ? "Edit Item"
                      : "Access Denied"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (access === "Edit" || access === "App/Dec") {
                      editSalesOpportunityId(item.opportunityId);
                      setCurrentSection("editSalesOpportunity");
                      // pcEdit(item.otherChargesId);
                    }
                  }}
                  style={
                    access === "Edit" || access === "App/Dec"
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed" }
                  }
                />
           </td>
        </tr>
         </Fragment>
      )
    })
  }

    const RenderOtherChargesList=()=>{
        return(
            <Fragment>
              <table className="vendorListTable">
              <thead>
              <tr className="vendorListHeader" style={{zIndex:1}}>
            <td
              className="vendorListHeading stickyFirstColumn"
              >
              Opportunity Name
            </td>
            <td
              className="vendorListHeading">
              Deal Owner
            </td>
            <td className="vendorListHeading">
              Close Date
            </td>    
            <td className="vendorListHeading">
              Amount
            </td>  
            <td className="vendorListHeading">
              Company Name
            </td>   
            <td className="vendorListHeading">
              priority
            </td>   
            <td className="vendorListHeadingActions">Actions</td>
          </tr>
              </thead>
              <tbody>
                {
              renderDealList()
              }</tbody>
          </table>
            </Fragment>
        )
    }

    

    return (
        <React.Fragment>
          <div className="vendorListArea" style={{maxHeight:"450px"}}>
        <React.Fragment>
          {RenderOtherChargesList()}
          {dealList.length ? null 
             : <div className="noRecordsYet">No records found</div>}
        </React.Fragment>
        </div>
        </React.Fragment>
      );
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewSaleOpportunityId: (value) => dispatch(addToSalesOpportunityId(value)),
    editSalesOpportunityId: (value) => dispatch(addToSalesOpportunityId(value)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(SalesDealsList) 