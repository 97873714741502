import React, { useEffect, useState } from "react";
import FormElement from "../SmallComponents/FormElement";
import "../styles/Purchase.css";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import update from "immutability-helper";

import resetIcon from "../../assets/return.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FormHint from "../SmallComponents/FormHint";
import InputField from "../SmallComponents/InputField";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const EditPurchaseOrder = ({ setSection, isLogged, addTokenToState, poInfo, editApprove }) => {
    const purchaseOrderParamList = [
        "vendorId",
        "poDate",
        "plantId",
        "storageId",
        "expectedDeliveryDate",
        "transporterName",
        "quotationNo",
        "quotationDate",
    ];

    const [purchaseOrderParams, setPurchaseOrderParams] = useState({
        vendorId: {
            inputType: "options",
            value: "",
            hintText: "Vendor Name",
            mandatory: true,
            colSpan: 12,
            error: false,
            options: [],
            errorMessage: "Please select a Vendor",
        },
        transporterName: {
            inputType: "text",
            value: "",
            hintText: "Transporter Name",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
        poDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Order Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please pick a Order date",
            mindate: false,
        },
        expectedDeliveryDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Expected Delivery Date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please Select delivery date",
            mindate: false,
        },
        plantId: {
            inputType: "options",
            value: "",
            hintText: "Plant",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please pick a Plant",
        },
        storageId: {
            inputType: "options",
            value: "",
            hintText: "Store",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Please select Store",
        },
        quotationNo: {
            inputType: "text",
            value: "",
            hintText: "Quotation No",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "",
        },
        quotationDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Quotation Date",
            mandatory: false,
            colSpan: 6,
            error: false,
            errorMessage: "Please enter Plant Code",
        },
    });

    const lineItemsParamsList = ["itemCategory","itemId", "from", "to", "netWeight", "grossWeight", "hsnCode", "uomId", "quantity", "cost", "unitPrice", "tax", "netPrice"];
    const [lineItemParams, setLineItemParams] = useState({
        itemCategory: {
            inputType: "options",
            value: "",
            hintText: "Item Category",
            mandatory: false,
            colSpan: 12,
            options: [],
            error: false,
            errorMessage: "Select an Item",
            callServer: true,
        },
        itemId: {
            inputType: "options",
            value: "",
            hintText: "Item",
            mandatory: true,
            colSpan: 12,
            options: [],
            error: false,
            errorMessage: "Select an Item",
            callServer: true,
        }, from: {
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        to: {
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        hsnCode: { inputType: "text", value: "", hintText: "HSN No", mandatory: false, colSpan: 6, error: false, errorMessage: "", disabled: true },
        uomId: {
            inputType: "options",
            value: "",
            hintText: "UOM",
            mandatory: true,
            colSpan: 6,
            options: [],
            error: false,
            errorMessage: "Pick a UOM",
        },
        quantity: {
            inputType: "text",
            value: "",
            hintText: "Quantity",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter a valid quantity",
        },
        cost: {
            inputType: "text",
            value: "",
            hintText: "Cost Price",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        unitPrice: {
            inputType: "text",
            value: "",
            hintText: "Unit Price",
            mandatory: true,
            colSpan: 4,
            error: false,
            errorMessage: "Enter a valid price",
        },
        tax: {
            inputType: "text",
            value: "",
            hintText: "Tax (%)",
            mandatory: false,
            colSpan: 2,
            error: false,
            errorMessage: "Tax not declared for this Item",
            disabled: true,
        },
        netPrice: {
            inputType: "text",
            value: "",
            hintText: "Net Price",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "",
            disabled: true,
        },
        poLineItemId: null,
    });

    const [lineItemsList, setLineItemsList] = useState([]);

    const [vendorDetail, setVendorDetail] = useState({});
    const [showVendorDetail, setShowVendorDetail] = useState(false);

    const [storeDetail, setStoreDetail] = useState({});
    const [showStoreDetail, setShowStoreDetail] = useState(false);

    const [storageLocList, setStorageLocList] = useState({});
    const [itemUomList, setItemUomList] = useState({});

    const [itemsTotalCost, setItemsTotalCost] = useState(0);
    const [otherCharges, setOtherCharges] = useState(0);
    const [transportCharges, settransportCharges] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [purchaseInstructions, setPurchaseInstructions] = useState("");
    const [itemList,setItemList] = useState([])

    const [poDetail, setPoDetail] = useState({});

    const getInformation = async () => {
        setDataSubmitted(false);
        setIsOpen(true);
        setModalText("Getting PO Information");

        await checkToken();
        var vendorList = await getVendorList();
        var [plantList, storageLocListFormatted] = await getPlants();

        var [itemList, itemUomListFormatted] = await getItemUom();
        var poDetail = await getPoDetail();
        var [categoryList,subCategoryList] = await getCategory();

        setStorageLocList(storageLocListFormatted);

        setLineItemsList(poDetail.lineItems);

        var lineItemParamsCopy = Object.assign(lineItemParams);
        lineItemParamsCopy = update(lineItemParamsCopy, 
            { itemId: { options: { $set: itemList } },
            itemCategory :{options :{$set :categoryList}}
        });
        setLineItemParams(lineItemParamsCopy);
        setItemUomList(itemUomListFormatted);

        setItemsTotalCost(parseFloat(poDetail.netTotal));
        setOtherCharges(parseFloat(poDetail.otherCharges));
        settransportCharges(parseFloat(poDetail.transportCharges));
        setTotalAmount(parseFloat(poDetail.totalAmount));
        setPurchaseInstructions(poDetail.purchaseInstruction);

        var purchaseParamsCopy = Object.assign(purchaseOrderParams);
        purchaseParamsCopy = update(purchaseParamsCopy, {
            vendorId: { options: { $set: vendorList }, value: { $set: poDetail.vendorId } },
            poDate: { value: { $set: poDetail.poDate } },
            plantId: { options: { $set: plantList }, value: { $set: poDetail.plantId } },
            storageId: { value: { $set: poDetail?.storageId } },
            expectedDeliveryDate: { value: { $set: poDetail.expectedDeliveryDate } },
            transporterName: { value: { $set: poDetail.transporterName } },
            quotationNo: { value: { $set: poDetail.quotationNo } },
            quotationDate: { value: { $set: poDetail.quotationDate } },
        });
        setPurchaseOrderParams(purchaseParamsCopy);
        setPoDetail(poDetail);
        setIsOpen(false);
        setItemList(itemList)
    };


    async function getCategory() {
        var categoryData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/categories",
            headers: { token: isLogged.accessToken, module: "Dropdowns" },
        });

        var categoryList = [];
        var subCategoryList = {};

        if (categoryData.msg === "success") {
            categoryData.asset.forEach((categ) => {
                var catObj = {};
                catObj.optionId = categ.category.categoryId;
                catObj.optionName = categ.category.categoryName;
                categoryList.push(catObj);

                subCategoryList[categ.category.categoryId] = [];

                categ.subCategory.forEach((subCateg) => {
                    var subCatObj = {};
                    subCatObj.optionId = subCateg.subCategoryId;
                    subCatObj.optionName = subCateg.subCategoryName;

                    subCategoryList[categ.category.categoryId].push(subCatObj);
                });
            });
            return [categoryList, subCategoryList];
        }
    }


    const history = useHistory();

    async function checkToken() {
      
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
         
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getVendorList() {
        var data = await fetchData({
            requestingPage: "vendorList",
            method: "get",
            url: "fetch/purchase-pre-create-vendor-names",
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });
        if (data.msg === "success") {
            var optionArray = [];
            data.asset.forEach((dataItem) => {
                var a = { optionName: dataItem.VendorName, optionId: dataItem.vendorId };
                optionArray.push(a);
            });

            return optionArray;
        } else {
            console.log(data);
        }
    }

    async function getPlants() {
        var plantsData = await fetchData({
            requestingPage: "plantList",
            method: "get",
            url: "fetch/plants",
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });

        var plantList = [];
        var storageLocList = {};

        if (plantsData.msg === "success") {
            plantsData.asset.forEach((plant) => {
                var plantObj = {};
                plantObj.optionId = plant.plant.plantId;
                plantObj.optionName = plant.plant.plantName;
                plantObj.address =
                    plant.plant.plantName +
                    ", " +
                    plant.plant.city +
                    ", " +
                    plant.plant.state +
                    (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                    plant.plant.country;
                plantList.push(plantObj);

                storageLocList[plant.plant.plantId] = [];
                plant.storage.forEach((store) => {
                    var storeObj = {};
                    storeObj.optionId = store.storageId;
                    storeObj.optionName = store.storageName;
                    storeObj.address =
                        store.address +
                        ", " +
                        plant.plant.city +
                        ", " +
                        plant.plant.state +
                        (plant.plant.zipCode !== null ? ` - ${plant.plant.zipCode}, ` : ", ") +
                        plant.plant.country;
                    storeObj.gstNo = plant.plant.gstNo;
                    storageLocList[plant.plant.plantId].push(storeObj);
                });
            });

            return [plantList, storageLocList];
        }
    }

    async function getItemUom() {
        var itemData = await fetchData({ requestingPage: "itemList", method: "post", url: "fetch/items", 
        headers: { token: isLogged.accessToken, module: "Purchase Order" },data:{itemsTab:"Active"} });

        var itemList = [];
        var itemUomList = {};
        if (itemData.msg === "success") {

            itemData.asset.forEach((item) => {
                var itemObj = {};
                itemObj.optionId = item.item.itemId;
                itemObj.optionName = item.item.itemName;
                itemObj.categoryId = item.item.categoryId;
     
                itemList.push(itemObj);

                itemUomList[item.item.itemId] = [];
                item.uom.forEach((uom) => {
                    var uomObj = {};
                    uomObj.optionId = uom.uomId;
                    uomObj.optionName = uom.uom;

                    itemUomList[item.item.itemId].push(uomObj);
                });
            });

            return [itemList, itemUomList];
        }
    }

    async function getVendorDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "fetch/vendor-detail/" + purchaseOrderParams.vendorId.value,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });
        if (data.msg === "success") {
            setShowVendorDetail(true);
            setVendorDetail(data.asset[0]);
        }
    }

    async function getItemDetail(paramCopy) {
        var data = await fetchData({
            requestingPage: "poItemDetail",
            method: "post",
            url: "po-fetch/po-line-item-pre-create",
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
            data: { itemId: lineItemParams.itemId.value, poDate: purchaseOrderParams.poDate.value },
        });
        if (data.msg === "success") {
            paramCopy = update(paramCopy, {
                from: { value: { $set: data.asset[0].from } },
                to: { value: { $set: data.asset[0].to } },
                netWeight: { value: { $set: data.asset[0].netWeight } },
                grossWeight: { value: { $set: data.asset[0].grossWeight } },
                hsnCode: { value: { $set: data.asset[0].hsnCode } },
                cost: { value: { $set: data.asset[0].cost } },
                tax: { value: { $set: data.asset[0].taxPercentage } },
            });

            return paramCopy;
        }
    }

    async function getPoDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: `po-fetch/po-detail/${poInfo.editPoId}`,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
            // data: { itemId: lineItemParams.itemId.value, poDate: purchaseOrderParams.poDate.value },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }
    }

    const RenderVendorDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">Vendor Detail</div>
                <div className="detailText">
                    {`Vendor Id: ${vendorDetail.vendorId}`}
                    <br /> {`Address: ${vendorDetail.address}`},
                    <br /> {vendorDetail.city}, {vendorDetail.state}, {vendorDetail.zipCode !== null ? ` - ${vendorDetail.zipCode}` : null}
                    <br /> {vendorDetail.mobile !== null || vendorDetail.phone !== null ? `\nPhone: ` : null}
                    {vendorDetail.mobile !== null ? vendorDetail.mobile : null}
                    {vendorDetail.phone !== null ? `, ${vendorDetail.phone} ` : null}
                    {vendorDetail.email !== null ? <br /> : null}
                    {vendorDetail.email !== null ? `Email Id: ${vendorDetail.email}` : null}
                    {vendorDetail.gstNo !== null ? <br /> : null}
                    {vendorDetail.gstNo !== null ? `GST No:  ${vendorDetail.gstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const RenderStoreDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">Store Detail</div>
                <div className="detailText">
                    Address: {storeDetail.address}
                    <br />
                    GST No: {storeDetail.gstNo}
                </div>
            </React.Fragment>
        );
    };

    const updatePOParameter = ({ paramName, section, key, value }) => {

        if (section === "general") {
            var purchaseParams = Object.assign(purchaseOrderParams);
            purchaseParams = update(purchaseParams, { [paramName]: { [key]: { $set: value } } });
            if (paramName === "plantId") {
                purchaseParams = update(purchaseParams, { storageId: { value: { $set: "" }, options: { $set: [] } } });
            }
            if (paramName === "poDate") {
                var lineParamsCopy = Object.assign(lineItemParams);
                lineParamsCopy = update(lineParamsCopy, { itemId: { callServer: { $set: true } } });
                setLineItemParams(lineParamsCopy);
            }
            setPurchaseOrderParams(purchaseParams);
        } else {
            var lineParamsCopy = Object.assign(lineItemParams);
            lineParamsCopy = update(lineParamsCopy, { [paramName]: { [key]: { $set: value } } });
            if (paramName === "itemId") {
                lineParamsCopy = update(lineParamsCopy, {
                    uomId: { value: { $set: "" }, options: { $set: [] } },
                    itemId: { callServer: { $set: true } },
                });
            }
            if(paramName === "itemCategory"){
                if(value){
                    const filteredItems = itemList.filter(item=>{return item.categoryId === value})
                    lineParamsCopy = update(lineParamsCopy, {
                        itemId: { options: {$set:filteredItems},value:{$set :""}},
                    });
                }else{

                    lineParamsCopy = update(lineParamsCopy, {
                        itemId: { options: {$set:itemList},
                                 value:{$set:""}},
                    });
                }
            }
            setLineItemParams(lineParamsCopy);
        }
    };

    useEffect(() => {
        getInformation();

    }, []);

    useEffect(() => {
        if (purchaseOrderParams.vendorId.value !== "") {
            getVendorDetail();
        } else {
            setVendorDetail({});
            setShowVendorDetail(false);
        }
    }, [purchaseOrderParams.vendorId.value]);

    useEffect(() => {
        var purchaseParamsCopy = Object.assign(purchaseOrderParams);

        if (purchaseOrderParams.plantId.value !== "") {
            purchaseParamsCopy = update(purchaseParamsCopy, { storageId: { options: { $set: storageLocList[purchaseOrderParams?.plantId?.value]||[] } } });
            setPurchaseOrderParams(purchaseParamsCopy);
        } else {
            purchaseParamsCopy = update(purchaseParamsCopy, { storageId: { options: { $set: [] }, value: { $set: "" } } });
            setPurchaseOrderParams(purchaseParamsCopy);
        }
    }, [purchaseOrderParams?.plantId?.value]);

    useEffect(() => {
        if (purchaseOrderParams.storageId.value !== "") {
            setShowStoreDetail(true);
            setStoreDetail(
                storageLocList[purchaseOrderParams?.plantId?.value]?.filter((arr) => arr?.optionId === purchaseOrderParams?.storageId?.value)[0]||[]
            );
        } else {
            setShowStoreDetail(false);
        }
    }, [purchaseOrderParams.storageId.value]);

    useEffect(() => {
        if (lineItemParams.itemId.value !== "" && lineItemParams.itemId.callServer === true) {
            var lineItemParamsCopy = Object.assign(lineItemParams);
            lineItemParamsCopy = update(lineItemParamsCopy, {
                uomId: { options: { $set: itemUomList[lineItemParams.itemId.value] }, value: { $set: "" } },
            });

            if (purchaseOrderParams.poDate.value !== "") {
                async function getDetail() {
                    var paramCopy = await getItemDetail(lineItemParamsCopy);
                    setLineItemParams(paramCopy);
                }

                getDetail();
            } else {
                setLineItemParams(lineItemParamsCopy);
            }
        } else {
        }
    }, [lineItemParams.itemId.value]);

    useEffect(() => {
        if (purchaseOrderParams.poDate.value !== "" && lineItemParams.itemId.value !== "" && lineItemParams.itemId.callServer === true) {

            var itemParamCopy = Object.assign(lineItemParams);
            async function getDetail() {
                var paramCopy = await getItemDetail(itemParamCopy);
                setLineItemParams(paramCopy);
            }

            getDetail();
        } else {
        }
    }, [purchaseOrderParams.poDate.value]);

    const [transportChargesError, setTransportChargesError] = useState(false);
    const [otherChargesError, setOtherChargesError] = useState(false);
    const [poErrors, setPoErrors] = useState([]);
    const checkErrors = (submitMode) => {

        var errorList = [];
        var paramsCopy = Object.assign(purchaseOrderParams);

        purchaseOrderParamList.forEach((item) => {
            if (paramsCopy[item].mandatory) {
                paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value.toString()) } } });
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        if (isNaN(transportCharges) || parseInt(transportCharges) < 0) {
            errorList.push("Enter a valid number for Transport Charges");
            setTransportChargesError(true);
        } else {
            setTransportChargesError(false);
        }
        if (isNaN(otherCharges) || parseInt(otherCharges) < 0) {
            errorList.push("Enter a valid number for Other Charges");
            setOtherChargesError(true);
        } else {
            setOtherChargesError(false);
        }

        if (errorList.length === 0) {
            var lineItemAllClear = true;
            lineItemsParamsList.forEach((item) => {
                if (lineItemParams[item].value !== "") {
                    lineItemAllClear = false;
                    setLineItemErrors(["Unsaved Item information found"]);
                }
            });

            if (lineItemAllClear) {
                if (lineItemsList.length > 0) {
                    prepareDataToSumbit(submitMode);
                    setIsOpen(true);
                    setModalText("Uploading Data. Please wait...");
                    setDataSubmitted(false);
                } else {
                    setLineItemErrors(["Add atleast one item information"]);
                }
            }
        }

        setPurchaseOrderParams(paramsCopy);
        setPoErrors(errorList);
    };

    const prepareDataToSumbit = (submitMode) => {
        var serverObj = {};
        purchaseOrderParamList.forEach((item) => {
            if (item !== "plantId" && purchaseOrderParams[item].value !== "") {
                serverObj[item] = purchaseOrderParams[item].value;
            }
        });
        serverObj.lineItems = lineItemsList;
        serverObj.otherCharges = otherCharges ||  0;
        serverObj.transportCharges = transportCharges || 0;
        serverObj.netTotal = itemsTotalCost;
        serverObj.purchaseInstruction = purchaseInstructions !== "" ? purchaseInstructions : null;
        serverObj.poStatus = submitMode;
        serverObj.totalAmount = totalAmount;
        serverObj.poId = poDetail.poId;

        submitData(serverObj);
    };

    async function approvePo() {
        // setIsOpen(true);
        // setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `po-edit/po-approve/${poDetail.poId}`,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Purchase Order");
        } else {
            setModalText(data.desc);
            setPoErrors(data.desc);
        }
    }

    const submitData = async (serverObj) => {
        var result = await fetchData({
            requestingPage: "createPoItem",
            url: "po-edit/po",
            method: "put",
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
            data: serverObj,
        });
        if (result.msg === "success") {

            if (editApprove) {
                setModalText("Edit Successful. Approving Purchase Order....");
                approvePo();
            } else {
                setDataSubmitted(true);
                setModalText(serverObj.poStatus === "Draft" ? "Purchase Order Saved" : "Purchase Order Submitted for Approval");
            }
        } else {
            setDataSubmitted(true);
            setModalText(result.desc);
            setPoErrors([result.desc]);
        }
    };

    const [lineItemErrors, setLineItemErrors] = useState([]);
    const checkLineItemError = () => {
        var errorList = [];

        var lineItemParamsCopy = Object.assign(lineItemParams);
        ["itemId", "from", "to", "netWeight", "grossWeight", "uomId", "quantity", "tax", "unitPrice"].forEach((element) => {
            if (lineItemParamsCopy[element].value !== null) {
                lineItemParamsCopy = update(lineItemParamsCopy, {
                    [element]: { error: { $set: !validateMandatory(lineItemParamsCopy[element].value.toString()) } },
                });
            } else {
                if (element === "tax") {
                    lineItemParamsCopy = update(lineItemParamsCopy, { tax: { error: { $set: true } } });
                }
            }

            if (element === "quantity" || element === "unitPrice" || element === "from" || element === "to" || element === "netWeight" || element === "grossWeight") {
                if (lineItemParamsCopy[element].value !== "") {
                    if (isNaN(lineItemParamsCopy[element].value) || parseFloat(lineItemParamsCopy[element].value) < 0) {
                        lineItemParamsCopy = update(lineItemParamsCopy, { [element]: { error: { $set: true } } });
                    } else {
                        lineItemParamsCopy = update(lineItemParamsCopy, { [element]: { error: { $set: false } } });
                    }
                }
            }

            if (lineItemParamsCopy[element].error) {
                errorList.push(lineItemParamsCopy[element].errorMessage);
            }

            setLineItemErrors(errorList);
            setLineItemParams(lineItemParamsCopy);
        });

        if (errorList.length === 0) {
         var newLineItem = {
                itemId: lineItemParamsCopy.itemId.value,
                itemName: lineItemParamsCopy.itemId.options.filter((item) => item.optionId === lineItemParamsCopy.itemId.value)[0].optionName,
                from: lineItemParamsCopy.from.value,
                to: lineItemParamsCopy.to.value,
                netWeight: lineItemParamsCopy.netWeight.value,
                grossWeight: lineItemParamsCopy.grossWeight.value,
                hsnCode: lineItemParamsCopy.hsnCode.value,
                uomId: lineItemParamsCopy.uomId.value,
                uom: itemUomList[lineItemParamsCopy.itemId.value].filter((item) => item.optionId === lineItemParamsCopy.uomId.value)[0].optionName,
                quantity: parseInt(lineItemParamsCopy.quantity.value),
                cost: lineItemParamsCopy.cost.value,
                unitPrice: lineItemParamsCopy.unitPrice.value,
                tax: lineItemParamsCopy.tax.value,
                netPrice:
                    lineItemParams.quantity.value * lineItemParams.unitPrice.value +
                    (lineItemParams.tax.value / 100) * (lineItemParams.quantity.value * lineItemParams.unitPrice.value),
                poLineItemId: lineItemParamsCopy.poLineItemId,
            };

            var lineItemsListCopy = lineItemsList;
            lineItemsListCopy.push(newLineItem);
            setLineItemsList(lineItemsListCopy);

            var itemsCost = 0;
            lineItemsListCopy.forEach((item) => {
                itemsCost = parseFloat(itemsCost) + parseFloat(item.netPrice);
            });
            setItemsTotalCost(itemsCost);

            lineItemsParamsList.forEach((element) => {
                lineItemParamsCopy[element].value = "";
            });
            lineItemsParamsList.poLineItemId = null;
            setLineItemParams(lineItemParamsCopy);
        }
    };

    const editSubSectionItem = (index, action) => {
        if (action === "edit") {
            var lineItemAllClear = true;

            lineItemsParamsList.forEach((item) => {
                if (lineItemParams[item].value !== "") {
                    lineItemAllClear = false;
                }
            });
            if (!lineItemAllClear) {
                var yes = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
            }
            if (lineItemAllClear || yes) {
                var lineItemCopy = Object.assign(lineItemParams);
                var lineItemsListCopy = lineItemsList.slice();

                var editObj = lineItemsListCopy.splice(index, 1)[0];


                ["poLineItemId", "itemId", "from", "to", "netWeight", "grossWeight", "hsnCode", "uomId", "quantity", "cost", "unitPrice", "tax", "netPrice"].forEach((itemKey) => {
                    if (itemKey === "item" || itemKey === "uom") {
                    } else if (itemKey === "poLineItemId") {
                        lineItemCopy[itemKey] = editObj[itemKey];
                    } else {
                        lineItemCopy[itemKey].value = editObj[itemKey];
                    }
                });
                lineItemCopy.itemId.callServer = false;
                lineItemCopy.uomId.options = itemUomList[lineItemCopy.itemId.value];
                setLineItemParams(lineItemCopy);

                var itemsCost = 0;
                lineItemsListCopy.forEach((item) => {
                    itemsCost = itemsCost + parseFloat(item.netPrice);
                });
                setItemsTotalCost(itemsCost);

                setLineItemsList(lineItemsListCopy);
            }
        }
        if (action === "delete") {
            var lineItemsListCopy = lineItemsList.slice();
            lineItemsListCopy.splice(index, 1);

            var itemsCost = 0;
            lineItemsListCopy.forEach((item) => {
                itemsCost = itemsCost + parseFloat(item.netPrice);
            });
            setItemsTotalCost(itemsCost);

            setLineItemsList(lineItemsListCopy);
        }
    };

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={
                        element === "netPrice"
                            ? lineItemParams.quantity.value * lineItemParams.unitPrice.value +
                                (lineItemParams.tax.value / 100) * (lineItemParams.quantity.value * lineItemParams.unitPrice.value) >=
                                0
                                ? lineItemParams.quantity.value * lineItemParams.unitPrice.value +
                                (lineItemParams.tax.value / 100) * (lineItemParams.quantity.value * lineItemParams.unitPrice.value)
                                : "-"
                            : param[element].value
                    }
                    setInput={(value) => {
                        updatePOParameter({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                />
            );
        });
    };

    const RenderTableRows = ({ rows, tab }) => {
        return rows.map((row, j) => {
            return (
                <tr className="createVendorContactsTableRows" key={j}>
                    {["itemName", "from", "to", "netWeight", "grossWeight", "hsnCode", "uom", "quantity", "cost", "unitPrice", "tax", "netPrice"].map((key, i) => {
                        return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                    })}
                    <td>
                        <img
                            alt="Edit Record"
                            className="createVendorContactsAction"
                            src={editIcon}
                            onClick={() => {
                                editSubSectionItem(j, "edit");
                            }}
                        />
                        <img
                            alt="Delete Record"
                            className="createVendorContactsAction"
                            src={deleteIcon}
                            onClick={() => {
                                var reset = window.confirm("Delete record?");
                                if (reset) {
                                    editSubSectionItem(j, "delete");
                                }
                            }}
                        />
                    </td>
                </tr>
            );
        });
    };

    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Item</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>HSN No</td>
                        <td>UOM</td>
                        <td>Quantity</td>
                        <td>Cost Price</td>
                        <td>Unit Price</td>
                        <td>Tax</td>
                        <td>Net Price</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                {lineItemsList.length > 0 ? (
                    <tbody>
                        <RenderTableRows rows={lineItemsList} />
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows">
                            {lineItemsParamsList.map((item) => {
                                return <td key={item}>&nbsp;</td>;
                            })}
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const renderSubSection = () => {
        return (
            <React.Fragment>
                <div className="purchaseOrderIndividualItemsArea" style={{gridTemplateRows: "repeat(2, 4rem)"}}>
                    {renderFormElements({ elementList: lineItemsParamsList, param: lineItemParams, section: "lineItem" })}
                    <FormElement
                        inputType="addButton"
                        value="+ Add"
                        colSpan={5}
                        setInput={() => {
                            checkLineItemError();
                        }}
                    />
                    <div
                        style={{
                            marginTop: "26px",
                            gridColumn: "span 1",
                            color: "#666",
                            background: "rgb(230,230,230)",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            style={{ padding: "10px", width: "1rem", height: "1rem" }}
                            src={resetIcon}
                            alt="Reset"
                            onClick={() => {
                                var yes = window.confirm("Clear Input data?");

                                if (yes) {
                                    var lineItemParamEmpty = Object.assign(lineItemParams);
                                    lineItemsParamsList.forEach((item) => {
                                        lineItemParamEmpty[item].value = "";
                                        lineItemParamEmpty[item].error = false;
                                    });

                                    setLineItemParams(lineItemParamEmpty);
                                    setLineItemErrors([]);
                                }
                            }}
                        />
                    </div>
                </div>
                <RenderTable />
            </React.Fragment>
        );
    };

    useEffect(() => {

        var _otherCharges = otherCharges;
        var _transportCharges = transportCharges;

        if (otherCharges === "") {
            _otherCharges = 0;
        }

        if (transportCharges === "") {
            _transportCharges = 0;
        }


        var returnVal =
            !isNaN(_otherCharges) && parseFloat(_otherCharges) >= 0
                ? !isNaN(_transportCharges) && parseFloat(_transportCharges) >= 0
                    ? parseFloat(_transportCharges) + parseFloat(_otherCharges) + itemsTotalCost
                    : "-"
                : "-";


        setTotalAmount(returnVal);
    }, [otherCharges, transportCharges, itemsTotalCost]);

    const renderErrorMessage = () => {
        if (poErrors.length > 0) return poErrors[0];
        else if (lineItemErrors.length > 0) return lineItemErrors[0];
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (poErrors.length === 0) {
            setSection("purchaseOrderList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem 3rem 1rem 3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="poNumberText">PO Number: {poDetail?.poNo}</div>
                    <div style={{ display: "flex" }}>
                        <div className="createPurchaseOrderGrid">
                            {renderFormElements({ elementList: purchaseOrderParamList, param: purchaseOrderParams, section: "general" })}
                        </div>
                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showVendorDetail ? <RenderVendorDetail /> : null}</div>
                                <div className="poStoreAddressDetail">{showStoreDetail ? <RenderStoreDetail /> : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="purchaseOrderSubSectionArea">{renderSubSection()}</div>
                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{lineItemsList.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>Purchase Instructions</div>
                            <textarea
                                className="multilineInput"
                                value={purchaseInstructions}
                                rows="6"
                                cols="30"
                                name="text"
                                placeholder="Enter your notes"
                                style={{
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setPurchaseInstructions(e.target.value);
                                }}
                            />
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{itemsTotalCost}</span>
                            </div>
                            <div className="poFormElement">
                                <div className="formElement">
                                    <FormHint hintText="Other Charges" mandatory={false} />
                                    <InputField
                                        type="float"
                                        value={otherCharges}
                                        setName={(value) => setOtherCharges(value)}
                                        align="end"
                                        error={otherChargesError}
                                    />
                                </div>
                                <div className="formElement">
                                    <FormHint hintText="Transport Charges" mandatory={false} />

                                    <InputField
                                        type="float"
                                        value={transportCharges}
                                        setName={(value) => settransportCharges(value)}
                                        align="end"
                                        error={transportChargesError}
                                    />
                                </div>
                            </div>

                            <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <div className="poNetTotalAmount">{totalAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    {poDetail.poStatus === "Draft" ? (
                        <button
                            className="saveButton"
                            onClick={() => {
                                setPoErrors([]);
                                setLineItemErrors([]);
                                checkErrors("Draft");
                            }}
                        >
                            Save draft
                        </button>
                    ) : null}

                    <button
                        className="submitButton"
                        onClick={() => {
                            setPoErrors([]);
                            setLineItemErrors([]);
                            checkErrors("Approval Pending");
                        }}
                    >
                        {editApprove ? "Edit & Approve" : " Submit for Approval"}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPurchaseOrder);
