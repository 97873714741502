import React,{Suspense,lazy} from "react";
import { connect } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ClearingAgent from "./components/ClearingAgent/ClearingAgent";

import Customer from "./components/Customer/Customer";
// import Dashboard from "./components/Dashboard/Dashboard";
import Employee from "./components/Employee/Employee";
import ImportPo from "./components/ImportPO/ImportPO";
import Inward from "./components/Inward/Inward";
import Item from "./components/Item/Item/Item";
import ItemBrand from "./components/Item/ItemBrand/ItemBrand";
import ItemCategory from "./components/Item/ItemCategory/ItemCategory";
import ItemMenu from "./components/Item/ItemMenu";
import ItemPriceType from "./components/Item/ItemPriceType/ItemPriceType";
import ItemProcess from "./components/Item/ItemProcess/ItemProcess";
import ItemPurchase from "./components/Item/ItemPurchaseCost/ItemPurchase";
import ItemSalesPrice from "./components/Item/ItemSalesPrice/ItemSalesPrice";
import ItemTax from "./components/Item/ItemTax/ItemTax";
import ItemType from "./components/Item/ItemType/ItemType";
// import Login from "./components/Login/Login";
import Login2 from "./components/Login/Login2";
import MaterialRequisition from "./components/MaterialRequisition/MaterialRequisition";
import MenuList2 from "./components/Menu/MenuList2";
import Outward from "./components/Outward/Outward";
import Plants from "./components/Plant&Store/Plants";
import Production from "./components/Production/Production";
import PurchaseImport from "./components/PurchaseImport/PurchaseImport";
import PurchaseInvoice from "./components/PurchaseInvoice/PurchaseInvoice";
import PurchaseOrder from "./components/PurchaseOrder/PurchaseOrder";
import Quality from "./components/Quality/Quality";
import Role from "./components/Role/Role";
import RoleControl from "./components/RoleControl/RoleControl";
import SalesInvoice from "./components/SalesInvoice/SalesInvoice";
import OtherCharges from "./components/OtherCharges/OtherCharges";
import SalesOrder from "./components/SalesOrder/SalesOrder";
import ScreenControl from "./components/ScreenControl/ScreenControl";
import NoAccess from "./components/SmallComponents/NoAccess";
import SoPending from "./components/SoPending/SoPending";
import IdnPending from "./components/IDNPending/IdnPending";
import Idn from "./components/IDN/Idn";
import StockTransfer from "./components/StockTransfer/StockTransfer";
import ItemReport from "./components/StockReport/ItemReport/stockReport";
import Store from "./components/Store/Store";
import StoreOpd from "./components/StoreOpd/StoreOpd";
import Transporter from "./components/Transporter/Transporter";
import UOM from "./components/UOM/UOM";
import Vendor from "./components/Vendor/Vendor";
import ReadFile from "./tests/ReadFile";
import SearchSelect from "./tests/SearchSelect";
import DcList from "./components/DcList/dcList";
import SubContracting from "./components/SubContracting/subContracting";
import CustomerReceipts from "./components/CustomerReceipts/customerReceipts";
import SalesReport from "./components/SalesReport/SalesReport";
import Location from "./components/Location/location";
import ItemCurrency from "./components/Item/ItemCurrency/ItemCurrency";
import VendorPayments from "./components/VendorPayments/vendorPayments";
import SalesDashboard from "./components/Dashboard/SalesDashboard";
import TemplateDashboard from "./components/Dashboard/DashboardTemplate";
import BillOfMaterial from "./components/BillOfMaterials/billOfMaterials";
import WorkOrder from "./components/WorkOrder/workOrder.js";
import ProcessLog from "./components/ProcessLog/processLog";
import CustomerLedger from "./components/SalesReport/CustomerLedger/CustomerLedger";
import OutStandingStatement from "./components/SalesReport/Outstanding Statement/outStandingStatement";
import ImportReport from "./components/ImportReport/importReport";
import ProductionReport from "./components/ProductionReport/productionReport";
import StockMenu from "./components/StockReport/stockMenu";
import MovementAnalysis from "./components/StockReport/MovementAnalysis/movementAnalysis";
import PackingSlip from "components/Packaging/PackingSlip/PackingSlip";
import SectionMaster from "components/Item/SectionMaster/sectionMaster";
import PremiumCharges from "components/Item/PremiumCharges/premiumCharges";
import BundleCreation  from "components/Packaging/BundleCreation/bundleCreation";
import Packaging from "components/Packaging/packaging";
import  CuttingLength  from "components/Item/CuttingLength/cuttingLength";
import EmployeeEfficiencyReport from "components/ProductionReport/employeeEfficiencyReport";
import ProductionReportModule from "components/ProductionReport/productionReportModule";
import purchaseReport from "components/PurchaseReport/purchaseReport";
import VenderLedgerReport from "components/PurchaseReport/VendorLedger/vendorLedger";
import VenderInvoiceReport from "components/PurchaseReport/VendorInvoice/venderInvoice";
import LoadingPage from "components/Packaging/Loadingslip/LoadingSlip";
import SalesOpportunities from "components/SalesOpportunities/SalesOpportunity"
import SalesQuotes from "components/SalesQuote/SalesQuotes";
import SalesLead from "components/SalesLead/SalesLead";
import QuoteDashboard from "components/Dashboard/QuoteDashboard";
import StockTransferDashboard from "components/Dashboard/StockTransferDashboard";
import PurchaseDashboard from "components/PurchaseDashboard/PurchaseDashboard";
import ImportDashboard from "components/ImportDashboard/ImportDashboard";
import StoreDashboard from "components/StoreDashboard/StoreDashboard";
import ProductionDashboard from "components/ProductionDashboard/ProductionDashboard"

const Home = ({ isLogged }) => {

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login2} />

                {/* <Route exact path="/dashboard" component={Dashboard} /> */}

                <Route exact path="/role" component={isLogged.access?.Role === null ? NoAccess : Role} />
                <Route exact path="/rolecontrol" component={isLogged.access?.["Role Control"] === null ? NoAccess : RoleControl} />
                <Route exact path="/screencontrol" component={isLogged.access?.["Screen Control"] === null ? NoAccess : ScreenControl} />
                <Route exact path="/uom" component={isLogged.access?.UOM === null ? NoAccess : UOM} />
                <Route exact path="/plant" component={isLogged.access?.["Plant & Store"] === null ? NoAccess : Plants} />
                <Route exact path="/itemmenu" component={isLogged.access?.["Item Menu"] === null ? NoAccess : ItemMenu} />
                <Route exact path="/employee" component={isLogged.access?.Employee === null ? NoAccess : Employee} />
                <Route exact path="/vendor" component={isLogged.access?.Vendor === null ? NoAccess : Vendor} />
                <Route exact path="/customer" component={isLogged.access?.Customer === null ? NoAccess : Customer} />
                <Route exact path="/transporter" component={isLogged.access?.Transporter === null ? NoAccess : Transporter} />
                <Route exact path="/clearing" component={isLogged.access?.["Clearing Agent"] === null ? NoAccess : ClearingAgent} />

                <Route exact path="/itemcategory" component={isLogged.access?.["Item Category"] === null ? NoAccess : ItemCategory} />
                <Route exact path="/itembrand" component={isLogged.access?.["Item Brand"] === null ? NoAccess : ItemBrand} />
                <Route exact path="/itemtype" component={isLogged.access?.["Item Type"] === null ? NoAccess : ItemType} />
                <Route exact path="/itemprocess" component={isLogged.access?.["Item Process"] === null ? NoAccess : ItemProcess} />
                <Route exact path="/item" component={isLogged.access?.["Items"] === null ? NoAccess : Item} />
                <Route exact path="/itempurchasecost" component={isLogged.access?.["Item Purchase Cost"] === null ? NoAccess : ItemPurchase} />
                <Route exact path="/itemtax" component={isLogged.access?.["Item Tax"] === null ? NoAccess : ItemTax} />
                <Route exact path="/itempricetype" component={isLogged.access?.["Item Price Type"] === null ? NoAccess : ItemPriceType} />
                <Route exact path="/itemsalesprice" component={isLogged.access?.["Item Sales Price"] === null ? NoAccess : ItemSalesPrice} />
                <Route exact path="/itemcurrency" component={isLogged.access?.["Item Currency"]===null ? NoAccess: ItemCurrency} />
                <Route exact path="/location" component={isLogged.access?.["Location"]===null?NoAccess: Location}/>
                <Route exact path="/templatedashboard" component={TemplateDashboard} />
                <Route exact path="/salesdashboard" component={isLogged.access?.["Sales Dashboard"]===null? NoAccess: SalesDashboard}/>
                <Route exact path="/quotesdashboard" component={isLogged.access?.["Quote Dashboard"]===null? NoAccess: QuoteDashboard}/>
                <Route exact path="/stockdashboard" component={isLogged.access?.["Stock Transfer Dashboard"]===null? NoAccess: StockTransferDashboard}/>

                <Route exact path="/purchaseorder" component={isLogged.access?.["Purchase Order"] === null ? NoAccess : PurchaseOrder} />
                <Route exact path="/purchaseinvoice" component={isLogged.access?.["Purchase Invoice"] === null ? NoAccess : PurchaseInvoice} />
                <Route exact path="/vendorpayments" component={isLogged.access?.["Vendor Payments"]===null ? NoAccess : VendorPayments}/>
                <Route exact path="/purchasedashboard" component={isLogged.access?.["Purchase Dashboard"]===null? NoAccess: PurchaseDashboard}/>

                <Route exact path="/purchasereport" component={isLogged.access?.["Purchase Report"]===null ? NoAccess : purchaseReport}/>
                <Route exact path="/vendor-ledger" component={isLogged.access?.["Vendor Ledger"]===null ? NoAccess : VenderLedgerReport}/>
                <Route exact path="/vendor-invoice" component={isLogged.access?.["Vendor Invoice"]===null ? NoAccess : VenderInvoiceReport}/>

                <Route exact path="/store" component={isLogged.access?.["Inventory"] === null ? NoAccess : Store} />
                <Route exact path="/opd" component={isLogged.access?.["OPD"] === null ? NoAccess : StoreOpd} />
                <Route exact path="/outward" component={isLogged.access?.["Outward"] === null ? NoAccess : Outward} />
                <Route exact path="/inward" component={isLogged.access?.["Inward"] === null ? NoAccess : Inward} />
                <Route exact path="/quality" component={isLogged.access?.["Quality Checking"] === null ? NoAccess : Quality} />
                <Route exact path="/transfer" component={isLogged.access?.["Stock Transfer"] === null ? NoAccess : StockTransfer} />
                <Route exact path="/sopending" component={isLogged.access?.["Pending SO"] === null ? NoAccess : SoPending} />
                <Route exact path="/idnpending" component={isLogged.access?.["Pending MRS"] === null ? NoAccess : IdnPending} />
                <Route exact path="/idn" component={isLogged.access?.["IDN"] === null ? NoAccess : Idn} />
                <Route exact path="/stockreport" component={isLogged.access?.["Stock Report"] === null ? NoAccess : StockMenu} />
                <Route exact path="/items-report" component={isLogged.access?.["Item Report"] === null ? NoAccess : ItemReport} />
                <Route exact path="/movement-analysis" component={isLogged.access?.["Movement Analysis"] === null ? NoAccess : MovementAnalysis} />


                <Route exact path="/salesorder" component={isLogged.access?.["Sales Order"] === null ? NoAccess : SalesOrder} />
                <Route exact path="/salesinvoice" component={isLogged.access?.["Sales Invoice"] === null ? NoAccess : SalesInvoice} />
                <Route exact path="/othercharges" component={isLogged.access?.["Other Charges"] === null ? NoAccess : OtherCharges} />
                <Route exact path="/sales-lead" component={isLogged.access?.["Sales Lead"] === null ? NoAccess : SalesLead} />
                <Route exact path="/sales-opportunities" component={isLogged.access?.["Sales Opportunities"] === null ? NoAccess : SalesOpportunities} />
                <Route exact path="/sales-quote" component={isLogged.access?.["Sales Quote"] === null ? NoAccess : SalesQuotes} />
                {/* <Route exact path="/salesReport" component={isLogged.access?.["Sales Order"] === null ? NoAccess : Report} /> */}
                <Route exact path="/customerreceipts" component={isLogged.access?.["Customer Receipt"] === null ? NoAccess : CustomerReceipts} />
                <Route exact path="/salesreport" component={isLogged.access?.["Sales Report"] === null ? NoAccess : SalesReport} />
                <Route exact path="/customer-ledger" component={isLogged.access?.["Customer Ledger"] === null ? NoAccess : CustomerLedger} />
                <Route exact path="/outstanding-statement" component={isLogged.access?.["Outstanding statement"] === null ? NoAccess : OutStandingStatement} />
                <Route exact path="/production" component={isLogged.access?.["Production Order"] === null ? NoAccess : Production} />
                <Route exact path='/materialrequisition' component={isLogged.access?.["Material Requisition"] === null ? NoAccess : MaterialRequisition} />
                <Route exact path="/dclist" component={isLogged.access?.["DC List"] === null ? NoAccess : DcList} />
                <Route exact path="/bill-of-material" component={isLogged.access?.["Bill of Material"] === null ? NoAccess : BillOfMaterial}/>
                <Route exact path="/subcontracting" component={isLogged.access?.["Sub Contracting"] === null ? NoAccess : SubContracting} />
                <Route exact path="/importpo" component={isLogged.access?.["Import PO"] === null ? NoAccess : ImportPo} />
                <Route exact path="/purchaseimport" component={isLogged.access?.["Purchase Import"] === null ? NoAccess : PurchaseImport} />
                <Route exact path='/work-order' component={isLogged.access?.["Work Order"]===null ? NoAccess: WorkOrder}/>
                <Route exact path='/process-log' component={isLogged.access?.["Process Log"]===null? NoAccess : ProcessLog}/>
                <Route exact path="/import-report" component={isLogged.access?.["Import Report"]===null ? NoAccess : ImportReport}/>
                <Route exact path="/production-report" component={isLogged.access?.["Production Report"] === null ? NoAccess : ProductionReportModule}/>
                <Route exact path="/production-log-report" component={isLogged.access?.["Production Log Report"] === null ? NoAccess :ProductionReport } />
                <Route exact path="/employee-efficiency-report" component={isLogged.access?.["Employee Efficiency Report"] === null ? NoAccess :EmployeeEfficiencyReport } />
                <Route exact path="/section-master" component={isLogged.access?.["Section Master"] === null ?NoAccess : SectionMaster} />
                <Route exact path="/premium-charges" component={isLogged.access?.["Premium Charges"] === null ?NoAccess : PremiumCharges} />
                <Route exact path="/packaging" component={isLogged.access?.["Packaging"] === null ? NoAccess : Packaging}/>
                <Route exact path="/bundle-creation" component={isLogged.access?.["Bundle Creation"] === null ? NoAccess : BundleCreation} />
                <Route exact path="/packing-slip" component={isLogged.access?.["Packing Slip"] === null ? NoAccess : PackingSlip}/>
                <Route exact path="/loading-slip" component={isLogged.access?.["Loading Slip"] === null ? NoAccess : LoadingPage}/>
                <Route exact path="/cutting-length" component={isLogged.access?.["Cutting Length"] === null ? NoAccess : CuttingLength}/>

                <Route exact path="/importdashboard" component={isLogged.access?.["Import Dashboard"]===null? NoAccess: ImportDashboard}/>
                <Route exact path="/storedashboard" component={isLogged.access?.["Store Dashboard"]===null? NoAccess: StoreDashboard}/>
                <Route exact path="/productiondashboard" component={isLogged.access?.["Production Dashboard"]===null? NoAccess: ProductionDashboard}/>
                {/* ================================= Experiments ================================= */}

                {/* <Route exact path="/itemsalesprice" component={ItemSalePriceList} /> */}
                <Route exact path="/menuList" component={MenuList2} />
                <Route exact path="/searchSelect" component={SearchSelect} />

                {/* Tests */}
                <Route exact path="/readfile" component={ReadFile} />
            </Switch>
        </Router>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

export default connect(mapStateToProps, null)(Home);
