import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useHistory } from "react-router";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { connect } from "react-redux";
import fetchData from "../../../serverCall/fetchData";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import axios from "axios";
import { serverEndpoint } from "../../../serverCall/environmentVariables";
import FileDownload from "js-file-download";

const UploadItems = ({ isLogged, addTokenToState, setSection }) => {
    const [itemsFile, setItemsFile] = useState("");
    const [toServerData, setToServerData] = useState([]);
    const [errorData, setErrorData] = useState([]);
    const [excelData, setExcelData] = useState([]);

    const [categoryList, setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState({});
    const [brandList, setBrandList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [itemTypeList, setItemTypeList] = useState([]);
    const taxTypeList = [
        { optionId: "Taxable", optionName: "Taxable" },
        { optionId: "Nil Tax", optionName: "Nil Tax" },
        { optionId: "Exempted", optionName: "Exempted" },
    ];
    const [uomList, setUomList] = useState([]);

    useEffect(() => {
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();

        var [categories, subCategories] = await getCategory();
        setCategoryList(categories);
        setSubCategoryList(subCategories);

        var brands = await getBrands();
        setBrandList(brands);

        var items = await getItems();
        setItemList(items);

        var itemTypes = await getItemType();
        setItemTypeList(itemTypes);

        var defaultUoms = await getUomOnlyList();
        setUomList(defaultUoms);
    }

    const history = useHistory();
    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
        return true;
    }

    async function getCategory() {
        var categoryData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/categories",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var categories = [];
        var subCategories = {};

        if (categoryData.msg === "success") {
            categoryData.asset.forEach((categ) => {
                var catObj = {};
                catObj.optionId = categ.category.categoryId;
                catObj.optionName = categ.category.categoryName;
                categories.push(catObj);

                subCategories[categ.category.categoryId] = [];

                categ.subCategory.forEach((subCateg) => {
                    var subCatObj = {};
                    subCatObj.optionId = subCateg.subCategoryId;
                    subCatObj.optionName = subCateg.subCategoryName;

                    subCategories[categ.category.categoryId].push(subCatObj);
                });
            });
            return [categories, subCategories];
        }
    }

    async function getBrands() {
        var brandData = await fetchData({
            requestingPage: "getBrand",
            method: "get",
            url: "fetch/brand-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var brandList = [];

        if (brandData.msg === "success") {
            brandData.asset.forEach((brand) => {
                var brandObj = {};
                brandObj.optionId = brand.brandId;
                brandObj.optionName = brand.brandName;
                brandList.push(brandObj);
            });

            return brandList;
        }
    }

    async function getItems() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "fetch/items",
            data:{itemsTab:"Active"},
            headers: { token: isLogged.accessToken,module:"Items" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var itemObj = {};
                itemObj.itemCode = element.item.itemCode;
                itemObj.itemName = element.item.itemName;

                data2.push(itemObj);
            });
            return data2;
        } else {
            console.log(data);
        }
    }

    async function getItemType() {
        var itemTypeData = await fetchData({
            requestingPage: "getItemType",
            method: "get",
            url: "fetch/item-type-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var itemTypeList = [];

        if (itemTypeData.msg === "success") {
            itemTypeData.asset.forEach((itemType) => {
                var itemTypeObj = {};
                itemTypeObj.optionId = itemType.itemTypeId;
                itemTypeObj.optionName = itemType.itemTypeName;
                itemTypeList.push(itemTypeObj);
            });

            return itemTypeList;
        }
    }

    async function getUomOnlyList() {
        var uomData = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/uom-list",
            headers: { token: isLogged.accessToken,module:"Items" },
        });

        var uomList = [];

        if (uomData.msg === "success") {
            uomData.asset.forEach((uom) => {
                var uomObj = {};
                uomObj.optionId = uom.uomId;
                uomObj.optionName = uom.uomName;
                uomList.push(uomObj);
            });

            return uomList;
        }
    }

    const readExcel = (file) => {
        setIsOpen(true);
        setModalText("Reading excel file. Please wait...");
        setDataSubmitted(false);
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const workBook = XLSX.read(bufferArray, { type: "buffer" });

                const workSheetName = workBook.SheetNames[0];
                const workSheet = workBook.Sheets[workSheetName];
                const data = XLSX.utils.sheet_to_json(workSheet);

                resolve({ data });
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then(({ data }) => {

            parseData(data);
        });
    };

    const parseData = (data) => {
        var errorList = [];
        var serverList = [];
        var uniqueRows = [];

        data.forEach((row, i) => {
            var oldFlag = false;
            row.Line_Number = row.__rowNum__ + 1;

            uniqueRows.forEach((item) => {
                if (item.Item_Name === row.Item_Name) {
                    row.Item_Name_Error = "Duplicate Name in file";
                    oldFlag = true;
                }
                if (item.Item_Code === row.Item_Code) {
                    row.Item_Code_Error = "Duplicate Code in file";
                    oldFlag = true;
                }
            });
            if (!oldFlag || uniqueRows.length === 0) {
                uniqueRows.push(row);
            } else {
                errorList.push(row);
            }
        });

        uniqueRows.forEach((row, i) => {
            var rowError = false;

            // =================================================
            // Checking if Item Name is Unique
            // =================================================

            if (row.Item_Name !== undefined) {
                var itemInFile = itemList.filter((option) => option.itemName.toLowerCase() === row.Item_Name.toLowerCase())[0];
                if (itemInFile !== undefined) {
                    row.Item_Name_Error = "Name already taken";
                    rowError = true;
                }
            } else {
                row.Item_Name_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Item Code is Unique
            // =================================================

            if (row.Item_Code !== undefined) {
                var itemCodeInFile = itemList.filter((option) => option.itemCode.toLowerCase() === row.Item_Code.trim().toLowerCase())[0];
                if (itemCodeInFile !== undefined) {
                    row.Item_Code_Error = "Code already taken";
                    rowError = true;
                }
            } else {
                row.Item_Code_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Category is Present
            // =================================================

            if (row.Main_Category !== undefined) {
                var categInFile = categoryList.filter((option) => option.optionName.toLowerCase() === row.Main_Category.toLowerCase())[0];
                if (categInFile === undefined) {
                    row.Main_Category_Error = "New Value";
                    rowError = true;
                }
            } else {
                row.Main_Category_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Sub-Category is Present
            // =================================================
            if (!row.Main_Category_Error) {
                var subCategs = subCategoryList[categInFile.optionId];

                if (row.Sub_Category !== undefined) {
                    var subCategInFile = subCategs.filter((option) => option.optionName.toLowerCase() === row.Sub_Category.toLowerCase())[0];

                    if (subCategInFile === undefined) {
                        row.Sub_Category_Error = "New Value";
                        rowError = true;
                    } else {
                        row.subCategoryId = subCategInFile.optionId;
                    }
                } else {
                    row.Sub_Category_Error = "Blank";
                    rowError = true;
                }
            } else {
                row.Sub_Category_Error = "New Value";
            }

            // =================================================
            // Checking if Brand is Present
            // =================================================

            if (row.Brand_Name !== undefined) {
                var brandInFile = brandList.filter((option) => option.optionName.toLowerCase() === row.Brand_Name.toLowerCase())[0];

                if (brandInFile === undefined) {
                    row.Brand_Name_Error = "New Value";
                    rowError = true;
                } else {
                    row.brandId = brandInFile.optionId;
                }
            } else {
                row.Brand_Name_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if ItemType is Present
            // =================================================

            if (row.Item_Type !== undefined) {
                var itemTypeInFile = itemTypeList.filter((option) => option.optionName.toLowerCase() === row.Item_Type.toLowerCase())[0];

                if (itemTypeInFile === undefined) {
                    row.Item_Type_Error = "New Value";
                    rowError = true;
                } else {
                    row.itemTypeId = itemTypeInFile.optionId;
                }
            } else {
                row.Item_Type_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if TaxType is Present
            // =================================================

            if (row.Tax_Type !== undefined) {
                var taxTypeInFile = taxTypeList.filter((option) => option.optionName.toLowerCase() === row.Tax_Type.toLowerCase())[0];

                if (taxTypeInFile === undefined) {
                    row.Tax_Type_Error = "New Value";
                    rowError = true;
                }
            }

            // =================================================
            // Checking if TaxPercentage is Present
            // =================================================

            if (row.Tax_Percentage !== undefined) {
                if (isNaN(row.Tax_Percentage)) {
                    row.Tax_Percentage_Error = "Not a number";
                    rowError = true;
                }
            }

            // =================================================
            // Checking if MRP is Present
            // =================================================

            if (row.MRP !== undefined) {
                if (isNaN(row.MRP)) {
                    row.MRP_Error = "Not a number";
                    rowError = true;
                }
            } else {
                row.MRP_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Default_UOM is Present
            // =================================================

            if (row.Default_UOM !== undefined) {
                var uomInFile = uomList.filter((option) => option.optionName.toLowerCase() === row.Default_UOM.toLowerCase())[0];

                if (uomInFile === undefined) {
                    row.Default_UOM_Error = "New value";
                    rowError = true;
                } else {
                    row.defaultUomId = uomInFile.optionId;
                }
            } else {
                row.Default_UOM_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Conversion_UOM is Present
            // =================================================

            if (row.Conversion_UOM !== undefined) {
                var uomInFile = uomList.filter((option) => option.optionName.toLowerCase() === row.Conversion_UOM.toLowerCase())[0];

                if (uomInFile === undefined) {
                    row.Conversion_UOM_Error = "New value";
                    rowError = true;
                } else {
                    row.uomId = uomInFile.optionId;
                }
            } else {
                row.Conversion_UOM_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Count is Present
            // =================================================

            if (row.Count !== undefined) {
                if (isNaN(row.Count)) {
                    row.Count_Error = "Not a number";
                    rowError = true;
                }
            } else {
                row.Count_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Checking if Conversion_Base_UOM is Present
            // =================================================

            if (row.Conversion_Base_UOM !== undefined) {
                var uomInFile = uomList.filter((option) => option.optionName.toLowerCase() === row.Conversion_Base_UOM.toLowerCase())[0];

                if (uomInFile === undefined) {
                    row.Conversion_Base_UOM_Error = "New value";
                    rowError = true;
                } else {
                    row.baseUom = uomInFile.optionId;
                }
            } else {
                row.Conversion_Base_UOM_Error = "Blank";
                rowError = true;
            }

            // =================================================
            // Adding Lines to ErrorList & numbering them
            // =================================================
            // row.Line_Number = i + 2;
            if (rowError) {
                errorList.push(row);
            } else {
                serverList.push(row);
            }

        });

        errorList.sort((a, b) => (a.Line_Number > b.Line_Number ? 1 : -1));

        setExcelData(data);
        setErrorData(errorList);
        setToServerData(serverList);
        setIsOpen(false);
    };

    const checkErrors = () => {
        if (toServerData.length > 0) {
            var toServer = [];
            toServerData.forEach((row) => {

                var objToServer = {};
                objToServer.itemCode = row.Item_Code;
                objToServer.itemName = row.Item_Name;

                objToServer.subCategoryId = row.subCategoryId;
                objToServer.brandId = row.brandId;
                objToServer.itemTypeId = row.itemTypeId;

                if (row.Tax_Type !== undefined) {
                    objToServer.taxType = row.Tax_Type;
                }
                if (row.Tax_Percentage !== undefined && !isNaN(row.Tax_Percentage)) {
                    objToServer.taxPercentage = row.Tax_Percentage;
                }

                objToServer.mrp = row.MRP;
                objToServer.defaultUomId = row.defaultUomId;
                objToServer.uomId = row.uomId;
                objToServer.baseUom = row.baseUom;
                objToServer.uomCount = row.Count;

                objToServer.taxApplicableDate = formatDate(row.Tax_Applicable_Date);

                if (row.Model_Name !== undefined) {
                    objToServer.modelName = row.Model_Name;
                }
                if (row.HSN_Code !== undefined) {
                    objToServer.hsnCode = row.HSN_Code;
                }

                if (row.Net_Weight !== undefined && !isNaN(row.Net_Weight)) {
                    objToServer.netWeight = row.Net_Weight;
                }
                if (row.Gross_Weight !== undefined && !isNaN(row.Gross_Weight)) {
                    objToServer.grossWeight = row.Gross_Weight;
                }
                if (row.Min_Stock_Level !== undefined && !isNaN(row.Min_Stock_Level)) {
                    objToServer.minStockLevel = row.Min_Stock_Level;
                }
                if (row.Max_Stock_Level !== undefined && !isNaN(row.Max_Stock_Level)) {
                    objToServer.maxStockLevel = row.Max_Stock_Level;
                }
                if (row.Dimension_Length !== undefined && !isNaN(row.Dimension_Length)) {
                    objToServer.itemLength = row.Dimension_Length;
                }
                if (row.Dimension_Breadth !== undefined && !isNaN(row.Dimension_Breadth)) {
                    objToServer.itemWidth = row.Dimension_Breadth;
                }
                if (row.Dimension_Height !== undefined && !isNaN(row.Dimension_Height)) {
                    objToServer.itemHeight = row.Dimension_Height;
                }
                if (row.Description !== undefined) {
                    objToServer.description = row.Description;
                }

                toServer.push(objToServer);
            });
            submitData(toServer);
        } else {
            setIsOpen(true);
            setModalText("No valid item(s) to submit");
            setItemServerError(["No valid item(s) to submit"]);
            setDataSubmitted(true);
        }
    };

    const [itemServerError, setItemServerError] = useState([]);

    const submitOkClick = () => {
        setIsOpen(false);
        if (itemServerError.length === 0) {
            setSection("itemList");
        }
        setModalText("Uploading form. Please wait...");
        setItemServerError([]);
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setModalText("Uploading items. Please wait...");

        var result = await fetchData({
            requestingPage: "createItem",
            method: "post",
            url: "create/items",
            headers: { token: isLogged.accessToken,module:"Items" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully uploaded Items!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setItemServerError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const downloadTemplate = async () => {

        axios({
            method: "get",
            url: serverEndpoint + "image/items-upload-template",
            headers: { token: isLogged.accessToken,module:"Items" },
            responseType: "arraybuffer",
        }).then((response) => {
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            FileDownload(blob, "Template_Items_Upload.xlsx"); // npm " js-file-download" responsible for downloading the file for the user
        });
    };

    const formatDate = (dateValue) => {
        var date = new Date();
        date.setTime((dateValue - 25569) * 24 * 3600 * 1000);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dateValue = date.getDate();
        if (month.toString().length === 1) month = `0${month}`;
        if (dateValue.toString().length === 1) dateValue = `0${dateValue}`;
        var formattedDate = `${year}-${month}-${dateValue}`;
        return formattedDate;
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const renderErrorMessage = () => {
        if (itemServerError.length > 0) {
            return itemServerError[0];
        } else {
            return null;
        }
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "95%",
                        margin: "0 auto 4rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        boxSizing: "border-box",
                    }}
                >
                    <div className="itemsUploadButtonsDiv">
                        <div className="itemUpload">
                            <div className="itemsUploadText">Download Template</div>
                            <div className="itemsUploadGrid">
                                <button className="inputLabel" style={{ gridColumn: "auto / span 4" }} onClick={() => downloadTemplate()}>
                                    Download
                                </button>
                                {/* <FormElement
                                    inputType="upload"
                                    hintText="Download"
                                    colSpan="4"
                                    hideHint=""
                                    setInput={(value) => setItemsFile(value)}
                                /> */}
                            </div>
                        </div>
                        <div className="itemUpload">
                            <div className="itemsUploadText">Upload Items</div>
                            <div className="itemsUploadGrid">
                                {itemsFile !== "" ? (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${4}` }}>
                                        <div className="inputDone">
                                            <span
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "25rem",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                ✅ {itemsFile.name}
                                            </span>
                                            <span
                                                style={{
                                                    paddingRight: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    "&:hover": { backgroundColor: "gray" },
                                                    maxWidth: "25rem",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    boxSizing: "border-box",
                                                }}
                                                onClick={() => {
                                                    setItemsFile("");

                                                    setErrorData([]);
                                                    setExcelData([]);
                                                }}
                                                title="Remove and Re-upload LR Copy"
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="formElement" style={{ gridColumn: `auto / span ${4}`, gridRow: `auto / span ${1}` }}>
                                        <React.Fragment>
                                            <label className={"inputLabel"} htmlFor="upload_file">
                                                Upload
                                            </label>

                                            <input
                                                type="file"
                                                name="items"
                                                accept=".xls, .xlsx"
                                                onChange={(event) => {
                                                    setItemsFile(event.target.files[0]);
                                                    readExcel(event.target.files[0]);
                                                }}
                                                id="upload_file"
                                            />
                                        </React.Fragment>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {errorData.length > 0 ? (
                        <React.Fragment>
                            <div
                                style={{
                                    margin: "2rem 0",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    gap: "10px 5px",
                                    textAlign: "center",
                                    // fontWeight: "bold",
                                    // fontStyle: "italic",
                                }}
                            >
                                <div>
                                    <span>No of items in file</span>
                                    <span>&nbsp;&nbsp;:&nbsp;&nbsp;{excelData.length}</span>
                                </div>

                                <div>
                                    <span style={{ color: "#14c76a" }}>No of valid items</span>
                                    <span style={{ color: "#14c76a" }}>&nbsp;&nbsp;:&nbsp;&nbsp;{excelData.length - errorData.length}</span>
                                </div>
                                <div>
                                    <span style={{ color: "red" }}>No of Items with errors</span>
                                    <span style={{ color: "red" }}>&nbsp;&nbsp;:&nbsp;&nbsp;{errorData.length}</span>
                                </div>
                            </div>
                            {/* <h4 style={{ margin: "1rem 0 0.5rem 0 " }}>Errors in items shown below</h4> */}
                        </React.Fragment>
                    ) : null}

                    {errorData.length > 0 ? (
                        <React.Fragment>
                            <div className="itemsTable">
                                <table
                                    style={{
                                        width: "100%",
                                        // border: "1px solid lightgray",
                                        borderCollapse: "collapse",
                                        fontSize: "12px",
                                        overflow: "auto",
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th className="itemsTableHeaderBold" colspan="3"></th>
                                            <th className="itemsTableHeader" colspan="13" style={{ backgroundColor: "lightgray", fontSize: "16px" }}>
                                                ERRORS
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="itemsTableHeaderBold">Line_No</th>
                                            <th className="itemsTableHeaderBold">Item_Code</th>
                                            <th className="itemsTableHeaderBold">Item_Name</th>
                                            <th className="itemsTableHeader">Item_Code</th>
                                            <th className="itemsTableHeader">Item_Name</th>
                                            <th className="itemsTableHeader">Category</th>
                                            <th className="itemsTableHeader">Sub_Category</th>
                                            <th className="itemsTableHeader">Brand_Name</th>
                                            <th className="itemsTableHeader">Item_Type</th>
                                            <th className="itemsTableHeader">Tax_Type</th>
                                            <th className="itemsTableHeader">Tax_Percentage</th>
                                            <th className="itemsTableHeader">MRP</th>
                                            <th className="itemsTableHeader">Default_UOM</th>
                                            <th className="itemsTableHeader">Conversion_UOM</th>
                                            <th className="itemsTableHeader">Count</th>
                                            <th className="itemsTableHeader">Conversion_Base_UOM</th>​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​ ​​​
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorData.map((data) => {
                                            var date = new Date();

                                            if (data.Tax_Applicable_Date !== undefined) {
                                                date.setTime((data.Tax_Applicable_Date - 25569) * 24 * 3600 * 1000);
                                            }

                                            return (
                                                <tr key={data.Item_Code}>
                                                    <td className="itemsTableValueBold">{data.Line_Number}</td>
                                                    <td className="itemsTableValueBold">{data.Item_Code}</td>
                                                    <td className="itemsTableValueBold">{data.Item_Name}</td>
                                                    <td className="itemsTableValue">{data.Item_Code_Error}</td>
                                                    <td className="itemsTableValue">{data.Item_Name_Error}</td>
                                                    <td className="itemsTableValue">{data.Main_Category_Error}</td>
                                                    <td className="itemsTableValue">{data.Sub_Category_Error}</td>
                                                    <td className="itemsTableValue">{data.Brand_Name_Error}</td>
                                                    <td className="itemsTableValue">{data.Item_Type_Error}</td>
                                                    <td className="itemsTableValue">{data.Tax_Type_Error}</td>
                                                    <td className="itemsTableValue">{data.Tax_Percentage_Error}</td>
                                                    <td className="itemsTableValue">{data.MRP_Error}</td>
                                                    <td className="itemsTableValue">{data.Default_UOM_Error}</td>
                                                    <td className="itemsTableValue">{data.Conversion_UOM_Error}</td>
                                                    <td className="itemsTableValue">{data.Count_Error}</td>
                                                    <td className="itemsTableValue">{data.Conversion_Base_UOM_Error}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: "2rem" }}>
                                {toServerData.length > 0
                                    ? "* You may still proceed to submit. The above listed items will be omitted during submission"
                                    : "No valid items found to upload. Please fix the above listed errors and try again"}
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="cancelButton"
                        onClick={() => {
                            var reset = window.confirm("Do you want to reset?");
                            if (reset) {
                                setItemsFile("");

                                setErrorData([]);
                                setExcelData([]);
                            }
                        }}
                    >
                        Reset Fields
                    </button>
                    <button
                        className="submitButton"
                        onClick={() => {
                            // setGeneralInfoError([]);
                            // setUomConversionError([]);
                            // setBillOfMaterialErrors([]);
                            // setProcessFlowErrors([]);
                            // setDiagramErrors([]);
                            // setItemServerError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadItems);
