import React, { useEffect, useState } from "react";
import resetIcon from "../../assets/return.svg";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import FormElement from "../SmallComponents/FormElement";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const EditScreenControl = ({ isLogged, roleInfo, addTokenToState, setSection }) => {
    // const masterList = [
    //     "Role",
    //     "Role Control",
    //     "Screen Control",
    //     "UOM",
    //     "Plant & Store",
    //     "Item Menu",
    //     "Item Category",
    //     "Item Brand",
    //     "Item Type",
    //     "Item Process",
    //     "Item Purchase Cost",
    //     "Item Price Type",
    //     "Item Tax",
    //     "Items",
    //     "Item Sales Price",
    //     "Item Currency",
    //     "Location",
    //     "Employee",
    //     "Vendor",
    //     "Customer",
    //     "Transporter",
    //     "Clearing Agent",
    //     "Sales Dashboard",
    //     "Purchase Order",
    //     "Purchase Invoice",
    //     "Vendor Payments",
    //     "Inventory",
    //     "OPD",
    //     "Inward",
    //     "Outward",
    //     "Quality Checking",
    //     "Stock Transfer",
    //     "Pending SO",
    //     "Pending MRS",
    //     "IDN",
    //     "Import PO",
    //     "Purchase Import",
    //     "Sales Order",
    //     "Sales Invoice",
    //     "Customer Receipt",
    //     "Production Order",
    //     "Material Requisition",
    //     "DC List",
    // ];
    const [masterList,setMasterList]=useState([]);

    const selectAll=["View","Create","Edit","App/Dec"];
    const [selectAllObj,setSelectAllObj]=useState({selectedState :{ checked: false, access: null } });
   useEffect(()=>{
   var copyMasterObj=Object.assign(masterObj);
   if(selectAllObj.selectedState.checked==true){
       masterList.forEach((menu)=>{
        if(selectAllObj.selectedState.access == "App/Dec" && !appDecModules.includes(menu))
        copyMasterObj=update(copyMasterObj,{[menu]:{checked: {$set: true},access: {$set: "Edit"}}});
        else
        copyMasterObj=update(copyMasterObj,{[menu]:{checked: {$set: true},access:{$set: selectAllObj.selectedState.access} } });       });  
   } else{
       masterList.forEach((menu)=>{
           copyMasterObj=update(copyMasterObj,{[menu]:{checked: {$set: false},access:{$set: null} } });
    });
   }  setMasterObj(copyMasterObj);
   },[selectAllObj]);
    const [masterObj, setMasterObj] = useState({
        "Sales Dashboard":{checked: false,access: null},
        "Quote Dashboard":{checked: false,access: null},
        "Stock Transfer Dashboard":{checked : false, access : null},
        "Purchase Dashboard":{checked: false,access: null},
        "Import Dashboard":{checked :false, access:null},
        "Store Dashboard":{checked :false,access:null},
        "Production Dashboard":{checked:false,access:null},

        "Stock Report":{checked : false, access :null},
        "Item Report":{checked:false,access:null},
        "Movement Analysis":{checked : false, access :null},
        "Import Report":{checked:false,access:null},
        "Production Report":{checked:false,access:null},
        "Production Log Report":{checked :false,access: null},
        "Employee Efficiency Report":{checked :false,access:null},
        "Sales Report":{checked:false, access: null},
        "Customer Ledger":{checked:false, access: null},
        "Outstanding statement":{checked:false, access: null},
        "Purchase Report" :{checked : false, access :null},
       "Vendor Ledger" :{checked : false, access :null},
       "Vendor Invoice" :{checked : false, access :null}, 
        
        "Role": { checked: false, access: null },
        "Role Control":{checked: false, access: null},
        "Screen Control":{checked :false,access: null},
        "UOM": { checked: false, access: null },
        "Plant & Store": { checked: false, access: null },
        "Item Menu":{checked: false, access: null},
        "Item Category": { checked: false, access: null },
        "Item Brand": { checked: false, access: null },
        "Item Type": { checked: false, access: null },
        "Item Process": { checked: false, access: null },
        "Item Purchase Cost": { checked: false, access: null },
        "Item Price Type": { checked: false, access: null },
        "Item Tax": { checked: false, access: null },
        "Items": { checked: false, access: null },
        "Item Sales Price": { checked: false, access: null },
        "Item Currency": {checked: false, access: null},
        "Cutting Length": {checked :false,access: null},
        "Section Master":{checked:false,access:null},
        "Premium Charges":{checked:false,access:null},
        "Location":{checked:false,access: null},
        "Employee": { checked: false, access: null },
        "Vendor": { checked: false, access: null },
        "Customer": { checked: false, access: null },
        "Transporter":{checked: false, access: null},
        "Clearing Agent": {checked: false, access: null},
        "Purchase Order": {checked: false, access: null},
        "Purchase Invoice": {checked : false, access :null},
        "Vendor Payments": {checked: false,access: null},
        "Inventory": {checked: false, access: null},
        
        "OPD": {checked : false, access :null},
        "Inward": {checked : false, access :null},
        "Outward": {checked : false, access :null},
        "Quality Checking": {checked : false, access :null},
        "Stock Transfer":{checked : false, access :null},
        "Pending SO":{checked : false, access :null},
        "Pending MRS":{checked : false, access :null},
        "IDN":{checked : false, access :null},
        "Packaging":{checked:false,access:null},
        "Bundle Creation":{checked:false,access:null},
        "Packing Slip":{checked:false,access:null},
        "Import PO":{checked : false, access :null},
        "Purchase Import":{checked : false, access :null},
        "Sales Order":{checked : false, access :null},
        "Sales Invoice":{checked : false, access :null},
        "Other Charges":{checked : false, access :null},
        "Customer Receipt":{checked:false,access:null},
        "Production Order":{checked : false, access :null},
        "Work Order":{checked: false,access: null},
        "Process Log": {checked: false,access: null},
        "Material Requisition":{checked : false, access :null},
        "DC List": {checked: false, access: null},
        "Bill Of Material":{checked: false,access: null},
        "Sub Contracting":{checked : false, access :null},
        "Loading Slip" :{checked : false, access : null},
        "Sales Opportunities" :{checked : false, access : null},
        "Sales Quote" :{checked : false, access : null},
        "Sales Lead":{checked : false, access : null},
    });
    const [screenControlParams,setScreenControlParams]=useState({
        plantId:{
            inputType: "options",
            value: "",
            hintText:"Plant Name",
            options:[],
            mandatory: true,
            colSpan: 3,
            placeholder: "Select Plant",
            error: false,
            errorMessage: "Please Select Plant Name",
            disabled: true,
        },
        roleId:{
            inputType:"options",
            value: "",
            hintText:"Role Name",
            options:[],
            mandatory:true,
            colSpan:3,
            placeholder: "Select Role",
            error: false,
            errorMessage:"Please Select Role Name",
            disabled: true,
        },
        empNo:{
            inputType:"options",
            value:"",
            hintText:"Employee No",
            options:[],
            mandatory: true,
            colSpan:3,
            placeholder: "Select Employee",
            error: false,
            errorMessage:"Please Select Employee No",
            disabled: true,
        }
    });
    const [cloneParams,setCloneParams]=useState(JSON.parse(JSON.stringify(screenControlParams)));
    cloneParams.plantId.disabled=false;
    cloneParams.roleId.disabled=false;
    cloneParams.empNo.disabled=false;
    const updateMasterObj = (value, obj) => {
        var newObj = Object.assign(masterObj);
        var updatedObj = update(newObj, { [obj]: { access: { $set: value }, checked: { $set: true } } });
        setMasterObj(updatedObj);
    };

    const resetSelection = (e, obj) => {
        var newObj = Object.assign(masterObj);
        var updatedObj = update(newObj, { [obj]: { access: { $set: null }, checked: { $set: false } } });
        setMasterObj(updatedObj);
    };

    const [editForRoles,setEditForRolesCheck] = useState(false);
    const [cloneRoles,setCloneRoles]=useState([]);
    const [cloneCheck,setCloneCheck] = useState(false);
    const [screenControlError, setScreenControlError] = useState([]);
    const [plantOptions,setPlantOptions]=useState([]);
    const [plantAccess,setPlantAccess]=useState([]);
    const[editEmp,setEditEmp]=useState([]);
    const [editable,setEditable] = useState(false);
   

    useEffect(() => {
        // get roles that don't have any screen controls for creating new
        let listArray =[];
        Object.keys(isLogged.access).map((key)=>{
            if(isLogged.access[key]!=null && key!="plantAccess")
            listArray.push(key);})
        setMasterList(listArray);
        getInformation();
    }, []);

    async function getInformation() {
        await checkToken();
        await getPlants();
        await getScreenControlRolesPreEdit(roleInfo.editScreenControlId);
        await getCloneRolesPreCreate();
    }

    const history = useHistory();
    async function checkToken() {
        
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
        return true;
    }

   
    async function getPlants(){
        var plant=await fetchData({
        requestingPage: "screenControl",
        method: "get",
        url: "fetch/plant",
        headers:{token: isLogged.accessToken,module: "Screen Control"}
        });
        if(plant.msg==="success"){
            var optionArray=[];
            plant.asset.forEach((dataItem)=>{
                var a = { optionName: dataItem.plant_name, optionId: dataItem.plant_id };
                optionArray.push(a);
            });
            setPlantOptions(optionArray)
        } else{
            console.log(plant);
        }
    }

//For Clone Role Name Options
useEffect(()=>{
    if(cloneParams.plantId.value!=""){
        var tempRoleOptions=[];
        cloneRoles.forEach((v)=>{
            if(v.plantId == cloneParams.plantId.value){
                var tempRoleObj ={optionId: v.roleId, optionName: v.roleName};
                var canGroup = !(tempRoleOptions.some((sub)=>sub.optionId === tempRoleObj.optionId));
                if(canGroup)
                tempRoleOptions.push(tempRoleObj);
            }
          });
          updateParams({section: "clone",paramName:"roleId",key:"options",value:tempRoleOptions});}
},[cloneParams.plantId.value]);

//For Clone Employee No Options
useEffect(()=>{
    var tempEmpOptions=[];
    if(cloneParams.roleId.value!==""){
    cloneRoles.forEach((v)=>{
        if(v.roleId === cloneParams.roleId.value  && v.plantId === cloneParams.plantId.value){
            var tempEmpObj={optionId: v.empNo,optionName:v.empNo};
            tempEmpOptions.push(tempEmpObj);
            }
        });
        updateParams({section:"clone",paramName: "empNo",key:"options",value:tempEmpOptions});}
        else if(cloneParams.roleId.value=""){
            updateParams({section:"clone",paramName:"empNo",key:"options",value:[]});
        }
},[cloneParams.roleId.value]);

//Invoke Permissions Of Selected Employee
    useEffect(()=>{
        var masterObjCopy=Object.assign(masterObj);
    if(cloneParams.empNo.value!=="" && cloneCheck){
        var yes = window.confirm(`Do You want To copy Screen Control Permissions of ${cloneParams.empNo.value}`);
        if(yes){
        (async function getSelectedEmpPermissions(){
           var empDetail = await fetchData({
            requestingPage: "screenControl",
            method: "get",
            url: `fetch/screen-control-role-pre-edit/${cloneParams.plantId.value}/${cloneParams.roleId.value}/${cloneParams.empNo.value}`,
            headers: {token: isLogged.accessToken,module: "Screen Control" },});
        
        if(empDetail.msg="success"){
            var subMenuArray=empDetail.asset[0].subMenus;
            var accessArray=empDetail.asset[0].access;
            subMenuArray.forEach((menu,index)=>{
                if(accessArray[index]!=="null")
                masterObjCopy=update(masterObjCopy,{[menu]:{checked:{$set:true},access:{$set:accessArray[index]} } });
                else
                masterObjCopy=update(masterObjCopy,{[menu]:{checked:{$set:false},access:{$set: null} } });
            });
            var tempPlant=[];
            empDetail.asset[0].plantAccess.map((value)=>{
            var tempObj=plantOptions.filter((obj)=>obj.optionId==value)[0]
             tempPlant.push({label:tempObj.optionName,value:tempObj.optionId})
            });
            setPlantAccess(tempPlant);
            setMasterObj(masterObjCopy);
            window.alert("Permissions Of Selected Employee No. is Copied!");
           } else{console.log("Error in fetching Data")}})(); }
       } else if(cloneParams.empNo.value === "" && cloneCheck){
           masterList.forEach((menu,index)=>{
            masterObjCopy=update(masterObjCopy,{[menu]:{checked:{$set:false},access:{$set:null} } });
           });
           setMasterObj(masterObjCopy);
           setPlantAccess([]);
           }
    },[cloneParams.empNo.value,cloneCheck]);

    async function getCloneRolesPreCreate(){
        var data2=await fetchData({
            requestingPage: "screenControl",
            method: "get",
            url: "fetch/screen-control-role-list-editable",
            headers: {token: isLogged.accessToken,module: "Screen Control" },
        });
        if(data2.msg==="success"){
            setCloneRoles(data2.asset);
             var optionArray2 = [];
             data2.asset.forEach((dataItem)=>{
            var tempPlantObj2={optionId:dataItem.plantId,optionName:dataItem.plantName};
            var canGroup2 = !(optionArray2.some((obj2)=>obj2.optionId === tempPlantObj2.optionId));
            if(canGroup2){
                optionArray2.push(tempPlantObj2);
                }   
            });
                optionArray2=optionArray2.sort((curr,next)=>{return parseFloat(curr.optionId)-parseFloat(next.optionId)});
                updateParams({section:"clone",paramName:"plantId",key:"options",value:optionArray2});
        }
    }
    

    async function getScreenControlRolesPreEdit(selectedEmp) {
        let empDetail =selectedEmp;
        var data = await fetchData({
            requestingPage: "screenControl",
            method: "get",
            url: `fetch/screen-control-role-pre-edit/${empDetail.plantId}/${empDetail.roleId}/${empDetail.empNo}`,
            headers: { token: isLogged.accessToken,module: "Screen Control" },
        });
        if (data.msg === "success") {
            setEditEmp(data.asset);
            var masterObjCopy = Object.assign(masterObj);
            var accessArray = data.asset[0]["access"];
            var subMenuArray = data.asset[0]["subMenus"];
            subMenuArray.forEach((subMenuName,index)=>{
                if(accessArray[index]!=="null")
                masterObjCopy = update(masterObjCopy,{[subMenuName]: { checked:{ $set: true},access: {$set: accessArray[index]} } });
            });
            var generalParams=Object.assign(screenControlParams);
            generalParams=update(generalParams,{empNo:{value: {$set: data.asset[0].empNo},options:{$set: [{optionId: data.asset[0].empNo,optionName: data.asset[0].empNo}]} } ,
                                                plantId:{value: {$set: data.asset[0].plantId},options:{$set: [{optionId:data.asset[0].plantId,optionName:data.asset[0].plantName}]} },
                                                roleId:{value: {$set: data.asset[0].roleId},options:{$set: [{optionId:data.asset[0].roleId,optionName:data.asset[0].roleName}]} }});                     
            setMasterObj(masterObjCopy);
            setScreenControlParams(generalParams);
        }
    }
    useEffect(()=>{
        if(!cloneCheck)
        {  
            updateParams({section:"clone",paramName:"plantId",key:"options",value:[]});
            updateParams({section:"clone",paramName:"roleId",key:"options",value:[]});
            updateParams({section:"clone",paramName:"empNo",key:"options",value:[]});
            updateParams({section:"clone",paramName:"plantId",key:"value",value:""});
            updateParams({section:"clone",paramName:"roleId",key:"value",value:""});
            updateParams({section:"clone",paramName:"empNo",key:"value",value:""});
           
            if(editEmp && editEmp.length!==0){
            let masterObjCopy= Object.assign(masterObj);
            let accessArray = editEmp[0]["access"];
            let subMenuArray = editEmp[0]["subMenus"];
            subMenuArray.forEach((subMenuName,index)=>{
                masterObjCopy = update(masterObjCopy,{[subMenuName]: { checked:{ $set: true},access: {$set: accessArray[index]} } });
            });    setMasterObj(masterObjCopy);         
            }
        }
    },[cloneCheck]);


useEffect(()=>{
 if(editEmp.length>0 && plantOptions.length>0){
    var tempPlant=[];
     editEmp[0].plantAccess.map((value)=>{
        var tempObj=plantOptions.filter((obj)=>obj.optionId==value)[0]
    tempPlant.push({label:tempObj?.optionName || "" ,value:tempObj?.optionId || ""})
    });
    setPlantAccess(tempPlant);
 }
},[editEmp])

    useEffect(()=>{
        if(!cloneCheck)
        {  
            updateParams({section:"clone",paramName:"plantId",key:"options",value:[]});
            updateParams({section:"clone",paramName:"roleId",key:"options",value:[]});
            updateParams({section:"clone",paramName:"empNo",key:"options",value:[]});
            updateParams({section:"clone",paramName:"plantId",key:"value",value:""});
            updateParams({section:"clone",paramName:"roleId",key:"value",value:""});
            updateParams({section:"clone",paramName:"empNo",key:"value",value:""});
           
            if(editEmp && editEmp.length!==0){
            let masterObjCopy= Object.assign(masterObj);
            let accessArray = editEmp[0]["access"];
            let subMenuArray = editEmp[0]["subMenus"];
            subMenuArray.forEach((subMenuName,index)=>{
                masterObjCopy = update(masterObjCopy,{[subMenuName]: { checked:{ $set: true},access: {$set: accessArray[index]} } });
            });    
            var tempPlant=[];
            editEmp[0].plantAccess.map((value)=>{
                    var tempObj=plantOptions.filter((obj)=>obj.optionId==value)[0]
                   tempPlant.push({label:tempObj.optionName,value:tempObj.optionId})
                               });
            setPlantAccess(tempPlant);
            setMasterObj(masterObjCopy);         
            }
        }
    },[cloneCheck]);


    const checkErrors = () => {
        var errorList=[];
        var generalParams=Object.assign(screenControlParams);
        ["plantId","roleId","empNo"].forEach((item)=>{
            if(generalParams[item].mandatory){
                generalParams =update(generalParams,{[item]: {error: {$set: !validateMandatory(generalParams[item].value.toString()) } }});
            }
            if(generalParams[item].error){
                errorList.push(generalParams[item].errorMessage);
            }
        });
        var permissionChecked =(masterList.every((subMenu)=>masterObj[subMenu]["access"]===null));
        if(permissionChecked) {errorList.push("Grant Permission For Atleast One Module")} else{console.log("Go Ahead")} 
        if (errorList.length == 0) {
            var arrayToServer = [];
            var menusAndAccess =Object.entries(masterObj).filter(([key,value])=>value.access!==null);
            var subMenuArray = JSON.stringify(menusAndAccess.map((keyAndValue)=>keyAndValue[0]));
            var accessArray = JSON.stringify(menusAndAccess.map((keyAndValue)=>keyAndValue[1]["access"]));
            var plantAccessArray = plantAccess.length>0 ? plantAccess.map((plant)=>plant.value) : [];
            if(!editForRoles){
                var newScreenControl={
                    empNo: generalParams.empNo.value,
                    plantId: generalParams.plantId.value,
                    roleId: generalParams.roleId.value,
                    subMenus: subMenuArray,
                    access: accessArray,
                    plantAccess: plantAccessArray,
                    editableForApprove :editable
                }
                arrayToServer.push(newScreenControl);
            } else
            {
                cloneRoles.forEach((item)=>{
                    if(item.plantId===generalParams.plantId.value && item.roleId===generalParams.roleId.value){
                        var newScreenControl={
                            empNo: item.empNo,
                            plantId: generalParams.plantId.value,
                            roleId: generalParams.roleId.value,
                            subMenus: subMenuArray,
                            access: accessArray,
                            plantAccess: plantAccessArray,
                            editableForApprove :editable
                        }
                        arrayToServer.push(newScreenControl);
                    }
                });
            }
            if (arrayToServer.length > 0) {
                setIsOpen(true);
                submitData(arrayToServer);
            } else {
                setScreenControlError([errorList[0]]);
            }
        }  else {
            setScreenControlError([errorList[0]]);
        }
        
    };

    const submitData = async (data) => {
        setDataSubmitted(false);

        var result = await fetchData({
            requestingPage: "createScreenControl",
            method: "put",
            url: "edit/screen-control",
            headers: { token: isLogged.accessToken,module: "Screen Control" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully edited Screen Control!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setScreenControlError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (screenControlError.length === 0) {
            setSection("screenControlList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const renderErrorMessage = () => {
        if (screenControlError.length > 0) return screenControlError[0];
    };

    const updateParams=({section,paramName,key,value})=>{
        if(section === "general"){
            var generalParams = Object.assign(screenControlParams);
            if(paramName=="plantId" && generalParams.plantId.value!=""){
                generalParams = update(generalParams,{[paramName]: {[key]:{$set : value} } });
                generalParams =update(generalParams,{roleId: {value : {$set: ""}},
                                                    empNo: {value: {$set: ""}} });
            }else{
            generalParams = update(generalParams,{[paramName]: {[key]:{$set : value} } });}
            setScreenControlParams(generalParams);
        } else if(section === "clone")
        {
            var copyParams = Object.assign(cloneParams);
            if(paramName=="plantId" && copyParams.plantId.value !=""){
                copyParams =update(copyParams,{[paramName]: {[key]: {$set:value} } });
                copyParams =update(copyParams,{roleId: {value : {$set: ""}},
                                                empNo: {value: {$set: ""}} });
            }else{
            copyParams =update(copyParams,{[paramName]: { [key]:{$set: value} } });}
            setCloneParams(copyParams);
        }
    }

    const itemMenus=["Item Category","Item Brand","Item Type","Item Process","Item Purchase Cost","Item Price Type","Item Tax","Items","Item Sales Price","Item Currency","Cutting Length","Section Master", "Premium Charges"];
    const storeStockMenus=["Item Report","Movement Analysis"];
    const packagingMenus=["Bundle Creation","Loading Slip","Packing Slip"];
    const salesReportMenus=["Customer Ledger","Outstanding statement"];
    const productionReportSubMenus=["Production Log Report","Employee Efficiency Report"];
    const purchaseOrderMenus =["Vendor Ledger","Vendor Invoice"]

    const renderFormElements = ({ elementList, params, section }) =>{
        return  elementList.map((element)=>{ 
             if(!(element==="empNo" && editForRoles === true) && section ==="general" || section === 'clone' && cloneCheck=== true)
             return( 
                     <FormElement style={{width:"60px !important"}}
                         key={element}
                         inputType={params[element].inputType}
                         value={params[element].value !== null ? params[element].value : ""}
                         setInput={(value)=>{updateParams({section,paramName: element, key:"value", value}); }}
                         hintText={params[element].hintText}
                         mandatory={params[element].mandatory}
                         colSpan={params[element].colSpan}
                         options={
                                 params[element].inputType === "options"
                                 ? params[element].options
                                 : null
                                 }
                         error={params[element].error}
                         rowSpan={
                                 element === "vendorLogo" || element === "otherBusinessDetails"
                                 ? params[element].rowSpan
                                 : 0
                                 }
                         placeholder={params[element].placeholder}
                         title={params[element].title}
                         disabled={params[element].disabled}
                         mindate={params[element].mindate} /> 
                 );
         });
        
     };
     const NotifySubMenu=({MenuName})=>{
        switch (MenuName) {
            case "Item Menu":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Item Menu To Enable Sub Menus in Item</td>
            case "Stock Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Stock Report To Enable Sub Menus in Stock Report</td>
            case "Packaging":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Packaging To Enable Sub Menus in Packaging</td>
            case "Sales Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Sales Report To Enable Sub Menus in Sales Report</td>
            case "Production Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Production Report To Enable Sub Menus in Production Report</td>
            case "Purchase Report":
                return <td className="screenControlModuleName" style={{fontWeight:"500"}}> Select Production Report To Enable Sub Menus in Production Report</td>
            default:
                return null;
        }
    };

    const menuDisabledCheck=(menuName)=>{
        if(itemMenus.includes(menuName)){
            return  masterObj["Item Menu"]?.["checked"]===false ? true  : false
        }else if(storeStockMenus.includes(menuName)){
            return masterObj["Stock Report"]?.["checked"]===false ? true : false
        }else if(packagingMenus.includes(menuName)){
            return masterObj["Packaging"]?.["checked"]===false ? true  : false
        }else if(salesReportMenus.includes(menuName)){
            return masterObj["Sales Report"]?.["checked"]===false ? true : false
        }else if(productionReportSubMenus.includes(menuName)){
            return masterObj["Production Report"]?.["checked"]===false ? true : false
        }else if(purchaseOrderMenus.includes(menuName)){
            return masterObj["Purchase Report"]?.["checked"]===false ? true : false
        }
        // else if(SalesOpportunitiesMenus.includes(menuName)){
        //     return masterObj["Purchase Report"]?.["checked"]===false ? true : false
        // }
        else{
            return false;
        }
    }

    const appDecModules= ["Items","Purchase Order","Purchase Import","Purchase Invoice","Vendor Payments","OPD","Inward","Outward","Quality Checking","Stock Transfer","Sales Order","Sales Invoice","Other Charges","Customer Receipt","Production Order","Material Requisition","DC List", "Sub Contracting", "Stock Report"];

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div className="screenControl">
                <table className="screenControlTable">
                        <thead>
                            <tr>
                                <td>
                                  <label key="includeEmp" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox"  onClick={(e)=> {setEditForRolesCheck(!editForRoles);}}/>
                                     <span id="includeEmp">Edit For All Employees of this Role?</span>
                                  </label>
                                </td> 
                                <td>
                                  <label key="cloneCheck" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox"  onClick={(e)=>{setCloneCheck(!cloneCheck);}}/>
                                    <span id="cloneCheck">Wish To Clone Permissions From?</span>
                                  </label>  
                                </td>
                                <td>
                                    <label key="plantAccess" className="plantsSubTitle" style={{fontSize: "14px",width:"250px"}}>
                                        <span id="plantAccess">Plant Access</span>
                                    </label>
                                </td>
                            </tr>   
                        </thead>
                        <tbody>
                            <tr>
                                <td>    
                            <div className="screenControlOptionsWrapper">           
                                {renderFormElements({elementList:Object.keys(screenControlParams),params:screenControlParams,section:"general"})}   
                            </div>
                                </td>
                                <td>
                            <div className="screenControlOptionsWrapper">
                                {renderFormElements({elementList:Object.keys(cloneParams),params:cloneParams,section:"clone"})}
                            </div>
                                </td>
                                <td style={{width: "250px"}}>
                                    <FormElement 
                                        setInput={(value)=>{setPlantAccess(value);}}
                                        inputType={"multiSelect"}
                                        value={plantAccess}
                                        hintText={"Plant Names"}
                                        options={plantOptions}
                                        colSpan={3}
                                        rowSpan={2}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                               <label key="editableForApprove" className="plantsSubTitle" style={{fontSize: "14px"}}>
                                    <input type="checkbox"  onClick={(e)=>setEditable(!editable)}/>
                                    <span id="editableForApprove">Editable For Approved Items </span>
                                </label> 

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                    {/* <h3 className="screenControlModuleName">Master Configure</h3> */}
                    <table className="screenControlTable">
                        <thead>
                            <tr>
                                <th className="screenControlTableHead">Module</th>
                                <th className="screenControlTableHead">View</th>
                                <th className="screenControlTableHead">+Create</th>
                                <th className="screenControlTableHead">+Edit</th>
                                <th className="screenControlTableHead">Approve/Decline</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                                <td className="screenControlModuleName" style={{fontWeight:"600"}}>Select All</td>
                                {
                                    selectAll.map((selection)=>{
                                        return(
                                            <td className="screenControlTableData">
                                                <input
                                                type="radio"
                                                name={"selectAll"}
                                                value={selection}
                                                checked={selectAllObj.selectedState.access === selection ? true : false}
                                                onChange={(e) => {setSelectAllObj({selectedState:{checked : true,access: selection}}) }}
                                            />
                                            </td>
                                        )
                                    })
                                }
                                <td className="screenControlTableData">
                                            <div className="screenControlTableReset">
                                                <span
                                                    style={{ padding: "0.25rem" }}
                                                    src={resetIcon}
                                                    alt="Reset"
                                                    onClick={(e) => setSelectAllObj({selectedState :{ checked: false, access: null } })}
                                                >
                                                    X
                                                </span>
                                            </div>
                                        </td>
                            </tr>
                            {masterList && masterList.length> 0 && masterList.map((obj) => {
                                return (
                                    <tr>
                                        <td className="screenControlTableText">{obj}</td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="View"
                                                checked={masterObj[obj]?.access === "View" ? true : false}
                                                onChange={(e) => updateMasterObj(e.target.value, obj)}
                                                disabled={menuDisabledCheck(obj)}
                                            />
                                        </td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="Create"
                                                checked={masterObj[obj]?.access === "Create" ? true : false}
                                                onChange={(e) => updateMasterObj(e.target.value, obj)}
                                                disabled={menuDisabledCheck(obj)}
                                            />
                                        </td>
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="Edit"
                                                checked={masterObj[obj]?.access === "Edit" ? true : false}
                                                onChange={(e) => updateMasterObj(e.target.value, obj)}
                                                disabled={menuDisabledCheck(obj)}
                                            />
                                        </td>
                                        {appDecModules.includes(obj) ?
                                        <td className="screenControlTableData">
                                            <input
                                                type="radio"
                                                name={obj}
                                                value="App/Dec"
                                                checked={masterObj[obj]?.access === "App/Dec" ? true : false}
                                                onChange={(e) => updateMasterObj(e.target.value, obj)}
                                                disabled={menuDisabledCheck(obj)}
                                            />
                                        </td> : <td>&nbsp;</td>}
                                        <td className="screenControlTableData">
                                            <div className="screenControlTableReset">
                                                <span
                                                    style={{ padding: "0.25rem" }}
                                                    src={resetIcon}
                                                    alt="Reset"
                                                    onClick={(e) => resetSelection(e, obj)}
                                                >
                                                    X
                                                </span>
                                            </div>
                                        </td> 
                                        <NotifySubMenu MenuName={obj}/>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                    <button
                        className="submitButton"
                        onClick={() => {
                            setScreenControlError([]);
                            checkErrors();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditScreenControl);
