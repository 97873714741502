import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import viewIconDisabled from "../../assets/view_disabled.svg";
import { editEmployee, viewEmployee } from "../../redux/Employee/EmployeeInfoActions";
import FormElement from "../SmallComponents/FormElement";
import Pagination from "../CommonFunctions/pagination";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import { handleSort } from "../CommonFunctions/utils";
const EmployeeList = ({
    // props
    setSection,

    // state
    isLogged,

    // dispatch
    addTokenToState,
    viewEmployeeId,
    editEmployeeId,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getEmployees();
    }

    const history = useHistory();
    const [noRecords, setNoRecords] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage,setCurrentPage]=useState(1);
    const [plantId,setPlantId] = useState("");
    const [employeeFilter,setEmployeeFilter]=useState("");
    const [PageSize,setRowsPerPage]=useState(20);
    const [selectedTab,setSelectedTab] = useState("Active Employee");
    const employeeListCurrentPage = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return employeeList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,employeeList,PageSize]);
    
    async function checkToken() {
       

        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getEmployees() {
        var data = await fetchData({
            requestingPage: "employeeList",
            method: "POST",
            url: "fetch/employees",
            headers: { token: isLogged.accessToken,module:"Employee" },
            data: {filter: "",tab :selectedTab === "Active Employee"? "Active" : "Inactive"}
        });
        if (data.msg === "success") {
            setEmployeeList(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

        useEffect(()=>{
            if(plantId!=""){
                setEmployeeList(employeeList.filter((obj)=>obj.plant_id===plantId))
            }else{
                getEmployees()
            }
        },[selectedTab,plantId]);

        useEffect(()=>{
            if(employeeFilter!=""){
                setEmployeeList(employeeList.filter((obj)=>obj.name.toLowerCase().includes(employeeFilter.toLowerCase())))
            }else{
                getEmployees()
            }
        },[selectedTab,employeeFilter])
    const renderEmployeeList = () => {
        return employeeListCurrentPage.map((employee) => {
            return (
                <tr className="employeeList" key={employee.id}>
                    <td className="employeeName">{employee.employee_no}</td>
                    <td className="employeeName">{employee.plant_name}</td>
                    <td className="employeeName">{employee.name}</td>
                    <td className="employeeName">{employee.mobile}</td>
                    <td className="employeeName">{employee.email}</td>
                    <td className="employeeName">{employee.role}</td>
                    <td className="employeeName">{employee.emp_status}</td>
                    <td className="employeeAction">
                        <img
                            className="employeeActionImage"
                            src={employee.id === 1 ? editIconDisabled : isLogged.access.Employee === "Edit" ? editIcon : editIconDisabled}
                            alt="Edit Employee Detail"
                            title={
                                employee.id === 1 ? "Can't edit Admin User" : isLogged.access.Employee === "Edit" ? "Edit Employee" : "No Edit Access"
                            }
                            style={
                                employee.id === 1
                                    ? { cursor: "not-allowed" }
                                    : isLogged.access.Employee === "Edit"
                                    ? { cursor: "pointer" }
                                    : { cursor: "not-allowed" }
                            }
                            onClick={() => {
                                if (employee.id !== 1 && isLogged.access.Employee === "Edit") {
                                    editEmployeeId(employee.id);

                                    // update to employee page on editRole
                                    setSection("editEmployee");
                                }
                            }}
                        />
                        <img
                            className="employeeActionImage"
                            src={employee.id === 1 ? viewIconDisabled : viewIcon}
                            alt="View Employee Detail"
                            title={employee.id === 1 ? "Can't view Admin User" : "View Employee"}
                            style={employee.id === 1 ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                            onClick={() => {
                                if (employee.id !== 1) {
                                    // set which employee to edit in Redux
                                    viewEmployeeId(employee.id);

                                    // update to employee page on editRole
                                    setSection("employeeDetail");
                                }
                            }}
                        />
                    </td>
                </tr>
            );
        });
    };
    const [order, setOrder] = useState({ col: "employee_no", state: true, data: "" });

    const tabList=[ {id: "receiptUnApprovedList",name:"Active Employee"},
                    {id: "receiptApprovedList",name:"InActive Employee"}];

    const RenderTabList = ({ tabList }) => {
        return tabList.map((tab) => {
            return (
                <div className={selectedTab === tab.name ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    key={tab.id} onClick={(e) => setSelectedTab(tab.name)}>
                    {tab.name}
                </div>
            );
        });
    };

    return (
        <React.Fragment>
            <div key={1} className="poListTabs">
                <RenderTabList tabList={tabList}/>
            </div>
            <div style={{ display: "inline-flex", width: "600px",marginLeft:"25px" }} className="purchaseOrderGrid">
                <span style={{ width: "250px" }}>
                    <FormElement setInput={(value) => { setPlantId(value); }}
                        inputType={"options"}
                        value={plantId}
                        hintText={"Plant Name"}
                        options={isLogged.plantOptions}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false} />
                </span>
                <span>
                    <FormElement
                        setInput={(value) => { setEmployeeFilter(value) }}
                        inputType={"text"}
                        value={employeeFilter}
                        hintText={"Employee Name"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
                <span style={{ flexGrow: 0, width: "80px"}}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
            </div>
            <div className="employeeListArea">
                <table className="employeeListTable">
                    <thead>
                    <tr className=" employeeListHeader" style={{cursor:"pointer"}}>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "employee_no",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "employee_no",
                        state: order.state,
                      });
                    }}>Employee Id 
                        <img
                            className="sortArrow"
                            src={
                                order.col === "employee_no"
                                ? order.state
                                ? SortUpGreen : SortDownGreen
                                            : null}
                                        /></td>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "plant_name",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "plant_name",
                        state: order.state,
                      });
                    }}>Employee Plant <img
                    className="sortArrow"
                    src={
                         order.col === "plant_name"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "name",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "name",
                        state: order.state,
                      });
                    }}>Employee Name <img
                    className="sortArrow"
                    src={
                         order.col === "name"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "mobile",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "mobile",
                        state: order.state,
                      });
                    }}>Mobile number <img
                    className="sortArrow"
                    src={
                         order.col === "mobile"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "email",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "email",
                        state: order.state,
                      });
                    }}>Official Email Id <img
                    className="sortArrow"
                    src={
                         order.col === "email"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "role",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "role",
                        state: order.state,
                      });
                    }}>Role <img
                    className="sortArrow"
                    src={
                         order.col === "role"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>
                        <td className="employeeNameHeader" onClick={(e) => {
                      setOrder({
                        col: "emp_status",
                        state: !order.state,
                      });
                      handleSort({
                        data: employeeListCurrentPage,
                        col: "emp_status",
                        state: order.state,
                      });
                    }}>Status <img
                    className="sortArrow"
                    src={
                         order.col === "emp_status"
                         ? order.state
                         ? SortUpGreen : SortDownGreen
                                  : null}
                    /></td>

                        <td className="employeeActionHeader">Action</td>
                    </tr>
                    </thead>
                    <tbody>
                    {employeeList.length > 0 ? renderEmployeeList() : null}
                    </tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
            {
                employeeList.length > 0 ? <Pagination 
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={employeeList.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
                /> : null
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // editRoleId: (roleId) => dispatch(editRoleNumber(roleId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewEmployeeId: (employeeId) => dispatch(viewEmployee(employeeId)),
        editEmployeeId: (employeeId) => dispatch(editEmployee(employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
