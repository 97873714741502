import React,{Fragment,useState,useEffect} from 'react';
import { connect } from 'react-redux';
import {useHistory} from 'react-router-dom';
import fetchData from '../../serverCall/fetchData';
import FetchNewToken from '../../serverCall/FetchNewToken';
import { addToken } from '../../redux/UserAccount/IsLoggedActions';

const convertToLinearWihId=(arr,parentId="")=>{
  
    return arr.reduce((acc,parent,index)=>{
          const {childBOM ,...rest}	= parent;
          rest.newId = parentId + (index+1);
          acc.push({...rest})
      if(childBOM.childBOMObject?.childBOMDetails?.rawMaterialLineItems?.length){
            childBOM.childBOMObject.childBOMDetails.rawMaterialLineItems.forEach((it,cindex)=>{
              it.newId =  rest.newId+'.'+(cindex+1) 
            }); 
            acc.push(...convertToLinearWihId(childBOM.childBOMObject.childBOMDetails.rawMaterialLineItems,rest.newId+'.'));
          }
         return acc;
      },[])
    }
const  convertToNonLinear=(arr, parentId = '')=> {
        
    return arr.map((parent, index) => {
          const { childBOM, ...rest } = parent;
          rest.newId = parentId + (index + 1);
          if (childBOM.childBOMObject?.childBOMDetails?.rawMaterialLineItems?.length) {
            childBOM.childBOMObject.childBOMDetails.rawMaterialLineItems.forEach((it, cindex) => {
                it.newId = rest.newId + '.' + (cindex + 1);
              }
            );
            convertToNonLinear(
              childBOM.childBOMObject.childBOMDetails.rawMaterialLineItems,
              rest.newId + '.'
            );
          }
          parent = { ...rest, childBOM };
          return parent;
        }, []);
}
const RenderRawMaterialsRow=({rows,setRawMaterials,rawMaterials})=>{
    const expandRow=(row)=>{
        row.isExpanded = true;
        setRawMaterials(rawMaterials)
    }
    const collapseRow=(row)=>{
        row.isExpanded = false;
        setRawMaterials(rawMaterials)
    }
    const ExpandCollapseToggle = ({ row, expandRow, collapseRow }) => {
        if (row.isExpanded === true) {
          return (
            <button  onClick={() => collapseRow(row)}>
              <sup>_</sup>
            </button>
          );
        } else {
          return (
            <button onClick={() => expandRow(row)}>
              +
            </button>
          );
        }
      };

      if (rows) {
        return rows.map((row) => {

          return (
            <Fragment key={Math.random()}>
              <tr key={row.id} className='vendorDetailContactsTableRows'>
                <td className='bomLevelColumn'>
                  {row.childBOM?.childBOMObject?.bomId ? (
                    <ExpandCollapseToggle
                      row={row}
                      expandRow={expandRow}
                      collapseRow={collapseRow}
                    />
                  ) : <span>&nbsp;&nbsp;</span>}
                  <span>{row.newId}</span>
                </td>
                <td>{row.item}</td>
                <td>{row.uom}</td>
                <td>{row.quantity}</td>
                <td>&nbsp;</td>
              </tr>
              {row.isExpanded  && row.childBOM?.childBOMObject?.bomId ? (
                <RenderRawMaterialsRow rows={row.childBOM?.childBOMObject?.childBOMDetails?.rawMaterialLineItems} setRawMaterials={setRawMaterials} rawMaterials={rawMaterials}/>
              ) : null}
            </Fragment>
          );
        });
      } else {
        return null;
      }
}
const BOMDetail = ({isLogged,poInfo,addTokenToState,module="Bill Of Material"}) => {
  
    const history = useHistory();
    const [bomDetail,setBomDetail]=useState({});
    const [finishedGoods,setFinishedGoods]=useState([]);
    const [rawMaterials,setRawMaterials] = useState([]);
    const [scrapMaterials,setScrapMaterials] = useState([]);
    const [processList,setProcessList]=useState([]);
    const finishedGoodColumns = isLogged.showHideWeight ? 
                                ['item','uom','from','to',
                                'netWeight','grossWeight',
                                'quantity','allocation']
                    : ['item','uom','quantity','allocation'];
    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          // console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
        } else {
          // console.log("Token not changed");
        }
      }
      async function getBOMDetail(){
        var detail = await fetchData({
            url : 'bill-of-material/get-bom-detail',
            headers: {token: isLogged.accessToken, module},
            requestingPage: "BillOfMaterial",
            method: "post",
            data: {bomId:poInfo.editBomId}
          });
        if(detail && detail.msg==='success'){
              let raMaterialsTemp=[];
            setBomDetail(detail.asset.storeDetails);
            setFinishedGoods([detail.asset.finishedGoods]);
            raMaterialsTemp = convertToNonLinear(detail.asset.rawMaterialLineItems);
            setRawMaterials(raMaterialsTemp);
            setScrapMaterials(detail.asset.scrapLineItems || [])
            setProcessList(detail.asset.storeDetails.fgProcessItems || []);
            return detail.asset;
          }else{
            // console.log(detail);
            return {};
          }
      }

      async function getInformation(){
        await checkToken();
        await getBOMDetail();
      }

      useEffect(()=>{
        getInformation();
      },[])

    const renderSummaryInfo=()=>{

        return(
            <Fragment>
                <span className='vendorSummaryRole'>{bomDetail.bomNo}</span>
                <div className='vendorSummaryGrid'>
                    <span className='vendorSummaryCategory'>Document Name</span>
                    <span className='vendorSummaryValue'>{bomDetail.bomDocumentName}</span>
                    <span className='vendorSummaryCategory'>Finished Good Store</span>
                    <span className='vendorSummaryValue'>{bomDetail.fgStoreName}</span>
                    <span className='vendorSummaryCategory'>Raw Goods Store</span>
                    <span className='vendorSummaryValue'>{bomDetail.rmStoreName}</span>
                    <span className='vendorSummaryCategory'>Scrap/Reject Store</span>
                    <span className='vendorSummaryValue'>{bomDetail.scrapStoreName}</span>
                </div>
            </Fragment>
        )
    }

    const renderFinishedOrScrapGoods=({data,name})=>{
        
        return(
            <Fragment>
                  <div className="vendorSummary">
                      <div className="poSummaryText" style={{margin: "5px 20px"}}>
                        <span
                          className="vendorSummaryRole"
                          style={{
                            textTransform: "capitalize",
                            padding: "5px",
                            color: "#1f43e5",
                          }}>                  
                        {name}
                        </span>
                      </div>
                  </div>
                <table className='vendorDetailContactsTable' style={{marginTop: "0px"}}>
                    <thead>
                        <tr className='vendorDetailContactsTableHeader'>
                            <td>Item</td>
                            {isLogged.showHideWeight ? 
                            <><td>Weight From</td>
                            <td>Weight To</td>
                            <td>Net Weight</td>
                            <td>Gross Weight</td>
                            </> : null}
                            <td>UOM</td>
                            <td>Quantity</td>
                            <td>Cost Allocation</td>
                            <td>Alternate Items</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item,index)=>(
                        <tr className='vendorDetailContactsTableRows' key={Math.random()}>
                            {finishedGoodColumns.map((col)=>(
                            <td key={Math.random()}>{item[col]}</td>)
                            )}
                            <td>&nbsp;</td>
                        </tr>)
                        )}
                    </tbody>
                </table>
            </Fragment>
        )
    }

 

    const renderRawGoods=()=>{

        return(
            <Fragment>
                <table className='vendorDetailContactsTable' style={{marginTop:"0px"}}>
                    <thead>
                    <tr className='vendorDetailContactsTableHeader'>
                            <td>BOM Level</td>
                            <td>Item</td>
                            {isLogged.showHideWeight ? 
                            <><td>Weight From</td>
                            <td>Weight To</td>
                            <td>Net Weight</td>
                            <td>Gross Weight</td>
                            </> : null}
                            <td>UOM</td>
                            <td>Quantity</td>
                            <td>Alternate Items</td>
                        </tr>
                    </thead>
                    <tbody>
                        {<RenderRawMaterialsRow rows={rawMaterials.slice()} rawMaterials={rawMaterials.slice()} setRawMaterials={setRawMaterials} />}
                    </tbody>
                </table>
            </Fragment>
        )
    }
    const renderProcessList=()=>{
        return(<Fragment>
              <table className='vendorDetailContactsTable' style={{marginTop:"0px"}}>
                <thead>
                  <tr className='vendorDetailContactsTableHeader'>
                      <td>Process Order</td>
                      <td>Process Name</td>
                  </tr>
                </thead>
                <tbody>
                  {processList.map((item,index)=>
                  (<tr className='vendorDetailContactsTableRows' key={Math.random()}>
                      <td>{index+1}</td>
                      <td>{item}</td>
                  </tr>)
                  )}
                </tbody>
              </table>
              </Fragment>)
    }
    return (
    <Fragment>
        <div className="detailsPageWrapper">
            <div className="detailsPageContainer">
                <div className="vendorSummary">
                    <div className="poSummaryText">{renderSummaryInfo()}</div>
                </div>
                <div className='vendorDetailTabArea'>
                    {renderFinishedOrScrapGoods({data:finishedGoods,name: "Finished Goods"})}
                </div>
                <div className='vendorDetailTabArea'>
                    <div className="vendorSummary">
                      <div className="poSummaryText" style={{margin:"5px 20px"}}>
                        <span
                          className="vendorSummaryRole"
                          style={{
                            textTransform: "capitalize",
                            padding: "5px",
                            color: "#1f43e5",
                          }}>                  
                          Raw Materials
                        </span>
                      </div>
                    </div>
                    {renderRawGoods()}
                </div>
                {scrapMaterials.length > 0 ? 
                <div className='vendorDetailTabArea'>
                    {renderFinishedOrScrapGoods({data:scrapMaterials,name: "Scrap Goods"})}
                </div> : null}
                {processList.length >0 ?<div className='vendorDetailTabArea'>
                    <div className="vendorSummary">
                      <div className="poSummaryText" style={{margin:"5px 20px"}}>
                        <span
                          className="vendorSummaryRole"
                          style={{
                            textTransform: "capitalize",
                            padding: "5px",
                            color: "#1f43e5",
                          }}>                  
                          Finished Good Process 
                        </span>
                      </div>
                    </div>
                    {renderProcessList()}
                </div> : null}
            </div>
        </div>
    </Fragment>
  )
}

const mapStateToProps=(state)=>{

    return{
        isLogged: state.isLogged,
        poInfo: state.poInfo
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(BOMDetail)