import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import resetIcon from "../../assets/return.svg";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";

import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FormHint from "../SmallComponents/FormHint";

const EditQc = ({
    // props
    loadInward,
    setSection,
    editApprove,

    // state
    isLogged,
    storeInfo,

    // dispatch
    addTokenToState,
}) => {
    const lineItemParams = isLogged.showHideWeight ?["itemId", "itemCode", "from", "to", "netWeight", "grossWeight", "hsnCode", "uom", "receivedQuantity", "checkingQuantity", "acceptedQuantity", "rejectedQuantity"] :  ["itemId", "itemCode", "hsnCode", "uom", "receivedQuantity", "checkingQuantity", "acceptedQuantity", "rejectedQuantity"];
    const qcParamList = [
        ["orderType", "inwardId",'productionId'],
        ["checkingDate", "batchNo"],
        lineItemParams,
        ["unit", "description", "quality"],
    ];

    const [qualityList, setQualityList] = useState([]);
    const [productionDetail,setProductionDetail] = useState({});
    const [qcParams, setQcParams] = useState({
        orderType: {
            inputType: "options",
            value: "",
            hintText: "Order Type",
            mandatory: true,
            colSpan: 9,
            error: false,
            options: [
                { optionId: "Purchase Order", optionName: "Purchase Order" },
                { optionId: "Sales Return", optionName: "Sales Return" },
                { optionId: "Plant Transfer", optionName: "Plant Transfer" },
                { optionId : "Production Order",optionName: "Production  Order"}
            ],
            errorMessage: "Please select order type",
            disabled: false,
        },
        inwardId: {
            inputType: "options",
            value: "",
            hintText: "Inward No",
            mandatory: true,
            colSpan: 9,
            error: false,
            options: [],
            errorMessage: "Please select Inward no",
            disabled: false,
        },
        productionId: {
            inputType: "options",
            value: "",
            hintText: "Production No",
            mandatory: true,
            colSpan: 9,
            error: false,
            options: [],
            errorMessage: "Please select a Production Order",
            disabled: false,
        },
        checkingDate: {
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Checking Date",
            mandatory: true,
            colSpan: 9,
            error: false,
            // mindate: true,
            maxdate: true,
            errorMessage: "Please Select Checking date",
        },
        batchNo: {
            inputType: "text",
            value: "",
            hintText: "Batch No",
            mandatory: false,
            colSpan: 9,
            error: false,
            options: [],
            errorMessage: "",
        },
        qcLineItemId: { value: null },

        itemId: {
            inputType: "options",
            value: "",
            hintText: "Item Name",
            mandatory: true,
            colSpan: 8,
            error: false,
            options: [],
            errorMessage: "Select an Item",
        },
        itemCode: {
            inputType: "text",
            value: "",
            hintText: "Item Code",
            mandatory: false,
            colSpan: 4,
            error: false,
            options: [],
            errorMessage: "",
            disabled: true,
        }, from: {
            inputType: "text",
            value: "",
            hintText: "Weight From(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        to: {
            inputType: "text",
            value: "",
            hintText: "Weight To(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        netWeight: {
            inputType: "text",
            value: "",
            hintText: "Net Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        grossWeight: {
            inputType: "text",
            value: "",
            hintText: "Gross Weight(gms)",
            mandatory: false,
            colSpan: 4,
            error: false,
            errorMessage: "Select Order date above to autofill this field",
            disabled: true,
        },
        hsnCode: {
            inputType: "text",
            value: "",
            hintText: "HSN Code",
            mandatory: false,
            colSpan: 4,
            error: false,
            options: [],
            errorMessage: "",
            disabled: true,
        },
        uom: {
            inputType: "text",
            value: "",
            hintText: "Uom",
            mandatory: false,
            colSpan: 4,
            error: false,
            options: [],
            errorMessage: "",
            disabled: true,
        },
        receivedQuantity: {
            inputType: "float",
            value: "",
            hintText: "Received Quantity",
            mandatory: false,
            colSpan: 4,
            error: false,
            options: [],
            errorMessage: "",
            disabled: true,
        },
        checkingQuantity: {
            inputType: "float",
            value: "",
            hintText: "Checking Quantity",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Enter a valid checking quantity",
        },
        acceptedQuantity: {
            inputType: "float",
            value: "",
            hintText: "Accepted Quantity",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Enter a valid accepted quantity",
        },
        rejectedQuantity: {
            inputType: "float",
            value: "",
            hintText: "Rejected Quantity",
            mandatory: true,
            colSpan: 6,
            error: false,
            options: [],
            errorMessage: "Enter a valid rejected quantity",
        },

        unit: { inputType: "text", hintText: "Unit No", value: `Unit ${qualityList.length + 1}`, removeBorder: false, colSpan: 3, disabled: true },
        description: {
            inputType: "text",
            value: "",
            hintText: "Description",
            mandatory: false,
            colSpan: 13,
            error: false,
            options: [],
            errorMessage: "Enter a Quality Description",
        },
        quality: {
            inputType: "text",
            value: "",
            hintText: "Qualiity (%)",
            mandatory: false,
            colSpan: 5,
            error: false,
            options: [],
            errorMessage: "Enter a valid quality percent",
        },
    });


    const [inwardDetail, setInwardDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    const [showCompanyDetail, setShowCompanyDetail] = useState(false);

    const [instruction, setInstruction] = useState("");
    const [qcDocument, setQcDocument] = useState("");

    const [qcDetail, setQcDetail] = useState({});
    const [qcErrors, setQcErrors] = useState([]);
    
    const getProductionDetail = async(prqId)=>{
        var response= await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: `production-fetch/request-detail/`+prqId,
            headers: {token: isLogged.accessToken,module: "Quality Checking"}
        })
        if(response.msg ='success')
        return response.asset[0];
    }

    useEffect(async () => {
        setIsOpen(true);
        setModalText("Getting Quality Checking Information. Please wait...");

        await checkToken();

        if (loadInward.load) {

            var paramsCopy = Object.assign(qcParams);
            var inwardDetail,productionDetailObj;
            var inwardList = await getInwardNo(loadInward.orderType);
            const key = loadInward.orderType =='Production Order' ? 'productionId':"inwardId";
            if(loadInward.orderType!=='Production Order')
            inwardDetail = await getInwardDetail(loadInward.orderType, loadInward.inwardId);
            else
            productionDetailObj = await getProductionDetail(loadInward.inwardId)

            var qcDetailCopy = await getQcDetail();

            var itemList = [];
            inwardDetail.lineItems.forEach((item) => {
                itemList.push({
                    optionId: item.itemId,
                    optionName: item.itemName,
                    itemCode: item.itemCode,
                    hsnCode: item.hsnCode,
                    from: item.from,
                    to: item.to,
                    netWeight: item.netWeight,
                    grossWeight: item.grossWeight,
                    uom: item.uom,
                    receivedQuantity: item.receivedQuantity,
                    inwardLineItemId: item.inwardLineItemId,
                });
            });


            qcDetailCopy.qcLineItems.forEach((qcItem) => {
                var itemObj2 = itemList.filter((item) => item.optionId === qcItem.itemId)[0];

                itemList = itemList.filter((item) => item.optionId !== qcItem.itemId);

                qcItem.itemOption = itemObj2;
            });


            paramsCopy = update(paramsCopy, {
                orderType: { disabled: { $set: true }, value: { $set: loadInward.orderType } },
                [key]: { options: { $set: inwardList }, disabled: { $set: true }, value: { $set: loadInward.inwardId } },

                itemId: { options: { $set: itemList }, value: { $set: "" } },
                itemCode: { value: { $set: "" } },
                hsnCode: { value: { $set: "" } },
                from: { value: { $set: "" } },
                to: { value: { $set: "" } },
                netWeight: { value: { $set: "" } },
                grossWeight: { value: { $set: "" } },
                uom: { value: { $set: "" } },
                receivedQuantity: { value: { $set: "" } },

                checkingDate: { value: { $set: qcDetailCopy.checkingDate } },
                batchNo: { value: { $set: qcDetailCopy.batchNo !== null ? qcDetailCopy.batchNo : "" } },
            });

            setQcParams(paramsCopy);
            setQcDetail(qcDetailCopy);
            setLineItemsList(qcDetailCopy.qcLineItems);
            if(inwardDetail)
            setInwardDetail(inwardDetail);
            else if(productionDetailObj){
                setProductionDetail(productionDetailObj)
            }
            if(inwardDetail)
            setShowCompanyDetail(true);
            setInstruction(qcDetailCopy.instruction);
            if (qcDetailCopy.qcDocument !== null) {
                setQcDocument({ name: qcDetailCopy.qcDocument });
            }
        }

        setIsOpen(false);
        setModalText("Updating Quality Checking information....");
    }, []);

    useEffect(async () => {
        if (!loadInward.load) {
            var paramsCopy = Object.assign(qcParams);
            const OrderType = qcParams.orderType.value;
            if (OrderType !== "") {
                var inwardList = await getInwardNo(qcParams.orderType.value);
                const key = OrderType=='Production Order' ? 'productionId':"inwardId";
                paramsCopy = update(paramsCopy, { [key]: { options: { $set: inwardList }, value: { $set: "" } } });
            } else {
                paramsCopy = update(paramsCopy, { inwardId: { options: { $set: [] }, value: { $set: "" } } });
            }

            setQcParams(paramsCopy);
        }
    }, [qcParams.orderType.value]);

    useEffect(async () => {
        if (!loadInward.load) {
            var paramsCopy = Object.assign(qcParams);

            if (paramsCopy.inwardId.value !== "") {
                resetItemErrors();
                var inwardDetail = await getInwardDetail(paramsCopy.orderType.value, paramsCopy.inwardId.value);
                var itemList = [];
                inwardDetail.lineItems.forEach((item) => {
                    itemList.push({
                        optionId: item.itemId,
                        optionName: item.itemName,
                        itemCode: item.itemCode,
                        itemId: item.itemId,
                        itemName: item.itemName,
                        hsnCode: item.hsnCode,
                        from: item.from,
                        to: item.to,
                        netWeight: item.netWeight,
                        grossWeight: item.grossWeight,
                        uom: item.uom,
                        receivedQuantity: item.receivedQuantity,
                        inwardLineItemId: item.inwardLineItemId,
                    });
                });

                paramsCopy = update(paramsCopy, {
                    itemId: { options: { $set: itemList }, value: { $set: "" } },
                    itemCode: { value: { $set: "" } },
                    hsnCode: { value: { $set: "" } },
                    from: { value: { $set: "" } },
                    to: { value: { $set: "" } },
                    netWeight: { value: { $set: "" } },
                    grossWeight: { value: { $set: "" } },
                    uom: { value: { $set: "" } },
                    receivedQuantity: { value: { $set: "" } },
                });
                setInwardDetail(inwardDetail);
                setShowCompanyDetail(true);
            } else {
                paramsCopy = update(paramsCopy, { itemId: { options: { $set: [] }, value: { $set: "" } } });
                setShowCompanyDetail(false);
                setInwardDetail({});
                setLineItemsList([]);
                setQualityList([]);
            }

            setQcParams(paramsCopy);
        }
    }, [qcParams.inwardId.value]);

    useEffect(async()=>{
        if(!loadInward.loadId){
            var paramsCopy = Object.assign(qcParams);
        const prqId = qcParams.productionId.value
        if(prqId){
            resetItemErrors();
            var productionDetailObj = await getProductionDetail(prqId);
            var itemList=[];
            productionDetailObj.lineItems.forEach((row)=>{
                itemList.push({
                    optionId: row.itemId,
                    optionName: row.itemName,
                    itemCode: row.itemCode,
                    itemId:row.itemId,
                    itemName: row.itemName,
                    hsnCode: row.hsnCode,
                    from: row.from,
                    to: row.to,
                    netWeight: row.netWeight,
                    grossWeight: row.grossWeight,
                    uom: row.uom,
                    receivedQuantity: row.quantity,
                    inwardLineItemId: row.requestLineItemId,
                });
            })

            paramsCopy = update(paramsCopy, {
                itemId: { options: { $set: itemList }, value: { $set: "" } },
                itemCode: { value: { $set: "" } },
                hsnCode: { value: { $set: "" } },
                from: { value: { $set: "" } },
                to: { value: { $set: "" } },
                netWeight: { value: { $set: "" } },
                grossWeight: { value: { $set: "" } },
                uom: { value: { $set: "" } },
                receivedQuantity: { value: { $set: "" } },
                batchNo: {value: {$set: productionDetailObj.batchNo || ""} }
            });
            setProductionDetail(productionDetailObj)
        }else{
            paramsCopy = update(paramsCopy, { itemId: { options: { $set: [] }, value: { $set: "" } },
            batchNo: {value: {$set: "" } } });
            setShowCompanyDetail(false);
            setProductionDetail({});
            setLineItemsList([]);
            setQualityList([]);
        }
        setQcParams(paramsCopy)
    }
    },[qcParams.productionId.value])

    useEffect(() => {
        var paramsCopy = Object.assign(qcParams);

        if (qcParams.itemId.value !== "") {
            var currentItem = qcParams.itemId.options.filter((item) => item.optionId === qcParams.itemId.value)[0];

            paramsCopy = update(paramsCopy, {
                itemCode: { value: { $set: currentItem.itemCode } },
                hsnCode: { value: { $set: currentItem.hsnCode || "" } },
                from: { value: { $set: currentItem.from || "" } },
                to: { value: { $set: currentItem.to || ""} },
                netWeight: { value: { $set: currentItem.netWeight || "" } },
                grossWeight: { value: { $set: currentItem.grossWeight || "" } },
                uom: { value: { $set: currentItem.uom } },
                receivedQuantity: { value: { $set: currentItem.receivedQuantity } },
            });

            setQcParams(paramsCopy);
        } else {

            paramsCopy = update(paramsCopy, {
                itemCode: { value: { $set: "" } },
                hsnCode: { value: { $set: "" } },
                from: { value: { $set: "" } },
                to: { value: { $set: "" } },
                netWeight: { value: { $set: "" } },
                grossWeight: { value: { $set: "" } },
                uom: { value: { $set: "" } },
                receivedQuantity: { value: { $set: "" } },
                itemCode: { value: { $set: "" } },
                hsnCode: { value: { $set: "" } },
                uom: { value: { $set: "" } },
                receivedQuantity: { value: { $set: "" } },
            });

            setQcParams(paramsCopy);
        }
    }, [qcParams.itemId.value]);

    const getInwardNo = async (url) => {
        var data = await fetchData({
            requestingPage: "vendorList",
            method: "get",
            url: `store-fetch/qc-pre-create-inward-list/${url}`,
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
        });
        if (data.msg === "success") {
            var inwardList = [];
            if(url !== 'Production Order')
            {data.asset.forEach((item) => {
                inwardList.push({ optionId: item.inwardId, optionName: item.inwardNo });
            });}
            else
            {inwardList = data.asset};
            return inwardList;
        }
    };

    const getInwardDetail = async (orderType, orderId) => {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: `store-fetch/inward-detail/${orderType}/${orderId}`,
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
        });
        if (data.msg === "success") {
            return data.asset[0];
        }
    };

    async function getQcDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "store-fetch/qc-detail/" + storeInfo.editQcId,
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
        });
        if (data.msg === "success") {

            data.asset[0].qcLineItems.forEach((item) => {

                var qualityList = JSON.stringify(data.asset[0].qcSamples.filter((qual) => qual.qcLineItemId === item.qcLineItemId));
                item.qualityItems = qualityList;
            });

            return data.asset[0];
        } else {
            console.log(data);
        }
    }

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    const updateParamaters = ({ paramName, section, key, value }) => {
        // if (section === "general") {
        var paramsCopy = Object.assign(qcParams);

        var proceed = true;

        if (paramName === "orderType" || paramName === "inwardId" || paramName == 'productionId') {
            if (paramsCopy.inwardId.value !== "" || paramsCopy.productionId.value !== "") {
                proceed = false;
                var yes = window.confirm("Changing this will reset all current information. Continue?");
                paramsCopy = update(paramsCopy, {
                    itemId: { value: { $set: "" } },
                    checkingQuantity: { value: { $set: "" } },
                    acceptedQuantity: { value: { $set: "" } },
                    rejectedQuantity: { value: { $set: "" } },
                    unit: { value: { $set: "Unit 1" } },
                    description: { value: { $set: "" } },
                    quality: { value: { $set: "" } },
                });
            }
        }

        if (paramName === "itemId") {
            if (paramsCopy.itemId.value !== "" && qualityList.length > 0) {
                proceed = false;
                var yes = window.confirm("Quality information provided below will be linked to the new item. Proceed?");

                if (yes) {
                    var qualityListCopy = JSON.parse(JSON.stringify(qualityList));
                    var itemObj2 = paramsCopy.itemId.options.filter((item) => item.optionId === value)[0];

                    qualityListCopy.forEach((item) => {
                        item.inwardLineItemId = itemObj2.inwardLineItemId;
                    });

                    setQualityList(qualityListCopy);
                }
            }
        }

        if (proceed || yes) {
            paramsCopy = update(paramsCopy, { [paramName]: { [key]: { $set: value } } });
            setQcParams(paramsCopy);
        }
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Submitting quality check. Please wait..");

    const submitOkClick = () => {
        setIsOpen(false);
        if (qcErrors.length === 0) {
            setSection("qualityList");
        }
        setModalText("Uploading form. Please wait...");
    };

    const checkLineItemError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(qcParams);

        ["itemId", "from", "to", "netWeight", "grossWeight", "checkingQuantity", "acceptedQuantity", "rejectedQuantity"].forEach((item) => {
            if(paramsCopy[item].mandatory)
            paramsCopy = update(paramsCopy, { [item]: { error: { $set: !validateMandatory(paramsCopy[item].value?.toString()) } } });

            if (item === "checkingQuantity" || item === "acceptedQuantity" || item === "rejectedQuantity") {
               

                if (paramsCopy[item].value === "" || isNaN(paramsCopy[item].value) || parseFloat(paramsCopy[item].value) < 0) {
                    paramsCopy = update(paramsCopy, { [item]: { error: { $set: true } } });
                } else {
                    paramsCopy = update(paramsCopy, { [item]: { error: { $set: false } } });
                }
            }

            if (paramsCopy[item].error) {
                errorList.push(paramsCopy[item].errorMessage);
            }
        });

        ["description", "quality"].forEach((item) => {
            if (paramsCopy[item].value !== "") {
                errorList.push("Unsaved sample quality information found");
            }
        });
        let equalityCheck = (paramsCopy['checkingQuantity'].value <= paramsCopy['receivedQuantity'].value)
        && (paramsCopy['receivedQuantity'].value == (paramsCopy['acceptedQuantity'].value+paramsCopy['rejectedQuantity'].value));

        if(!equalityCheck)
        {
        errorList.push('Item Quantity Not Equally Distributed');
        ['checkingQuantity','acceptedQuantity','rejectedQuantity'].forEach((param)=>{
        paramsCopy = update(paramsCopy,{[param]: {['error']: {$set: true} } })
        })
        }
        if (errorList.length === 0) {

            var itemObj2 = paramsCopy.itemId.options.filter((item) => item.optionId === paramsCopy.itemId.value)[0];

            var itemObj = {
                qcLineItemId: paramsCopy.qcLineItemId.value,
                itemId: paramsCopy.itemId.value,
                checkingQuantity: paramsCopy.checkingQuantity.value,
                acceptedQuantity: paramsCopy.acceptedQuantity.value,
                rejectedQuantity: paramsCopy.rejectedQuantity.value,
                hsnCode: itemObj2.hsnCode,
                itemCode: itemObj2.itemCode,
                itemName: itemObj2.optionName,
                from: paramsCopy.from.value,
                to: paramsCopy.to.value,
                netWeight: paramsCopy.netWeight.value,
                grossWeight: paramsCopy.grossWeight.value,
                receivedQuantity: itemObj2.receivedQuantity,
                uom: itemObj2.uom,
                itemOption: itemObj2,
                inwardLineItemId: itemObj2.inwardLineItemId,
            };

            if (qualityList.length > 0) {
                itemObj.qualityItems = JSON.stringify(qualityList);
                setQualityList([]);
                paramsCopy = update(paramsCopy, { unit: { value: { $set: "Unit 1" } } });
            }

            var itemOptions = paramsCopy.itemId.options.filter((item) => item.optionId !== paramsCopy.itemId.value);

            paramsCopy = update(paramsCopy, {
                itemId: { options: { $set: itemOptions }, value: { $set: "" } },
                itemCode: { value: { $set: "" } },
                from: { value: { $set: "" } },
                to: { value: { $set: "" } },
                netWeight: { value: { $set: "" } },
                grossWeight: { value: { $set: "" } },
                hsnCode: { value: { $set: "" } },
                uom: { value: { $set: "" } },
                receivedQuantity: { value: { $set: "" } },
                checkingQuantity: { value: { $set: "" } },
                acceptedQuantity: { value: { $set: "" } },
                rejectedQuantity: { value: { $set: "" } },
            });

            var lineItemsListCopy = lineItemsList.slice();
            lineItemsListCopy.push(itemObj);
            setLineItemsList(lineItemsListCopy);
        }
        setQcErrors(errorList);
        setQcParams(paramsCopy);
    };

    const checkQualityError = () => {
        var errorList = [];
        var paramsCopy = Object.assign(qcParams);

        ["description", "quality"].forEach((element) => {
            paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });

            if (element === "quality") {
                if (isNaN(paramsCopy[element].value)) {
                    paramsCopy = update(paramsCopy, { [element]: { error: { $set: "true" } } });
                    errorList.push("Enter a valid quality percentage.");
                }
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (paramsCopy.itemId.value !== "") {
            if (errorList.length === 0) {             

                var qualityListCopy = Object.assign(qualityList);
                var qualityObj = {
                    description: paramsCopy.description.value,
                    quality: paramsCopy.quality.value,
                    inwardLineItemId: paramsCopy.itemId.options.filter((item) => item.optionId === paramsCopy.itemId.value)[0].inwardLineItemId,
                };

                qualityListCopy.push(qualityObj);
                setQualityList(qualityListCopy);

                paramsCopy = update(paramsCopy, {
                    description: { value: { $set: "" } },
                    quality: { value: { $set: "" } },
                    unit: { value: { $set: `Unit ${qualityList.length + 1}` } },
                });
            }
        } else {
            errorList.push("Select item from above values");
        }


        setQcParams(paramsCopy);
        setQcErrors(errorList);
    };

    const checkErrors = () => {
        var paramsCopy = Object.assign(qcParams);
        var errorList = [];

        qcParamList[2].forEach((item) => {
            if (paramsCopy[item].value !== "") {
                errorList.push("Unsaved item information found");
            }
        });

        ["description", "quality"].forEach((item) => {
            if (paramsCopy[item].value !== "") {
                errorList.push("Unsaved quality information found");
            }
        });

        qcParamList[0].forEach((element) => {
            if(
                (element == 'productionId' && paramsCopy['orderType'].value  == 'Production Order') 
                || 
                (element == 'inwardId' && paramsCopy['orderType'].value !== 'Production Order')
                )
            paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        qcParamList[1].forEach((element) => {
            if (paramsCopy[element].mandatory) {
                paramsCopy = update(paramsCopy, { [element]: { error: { $set: !validateMandatory(paramsCopy[element].value.toString()) } } });
            }

            if (paramsCopy[element].error) {
                errorList.push(paramsCopy[element].errorMessage);
            }
        });

        if (lineItemsList.length < 1) {
            errorList.push("Add atleast one item information");
        }

        if (errorList.length === 0) {

            var data2server = new FormData();

            data2server.append("qcId", qcDetail.qcId);
            data2server.append("orderType", paramsCopy.orderType.value);
            data2server.append("inwardIdOrProductionId", paramsCopy.inwardId.value || paramsCopy.productionId.value);
            data2server.append("checkingDate", paramsCopy.checkingDate.value);

            if (paramsCopy.batchNo.value !== "") {
                data2server.append("batchNo", paramsCopy.batchNo.value);
            }

            if (instruction !== "") {
                data2server.append("instruction", instruction);
            }

            if (qcDocument !== "" && qcDocument.size > 0) {
                data2server.append("qcDocument", qcDocument);
            }

            var sampleList = [];
            var lineItemsList2server = [];

            lineItemsList.forEach((item) => {
                var lineObj = {
                    qcLineItemId: item.qcLineItemId,
                    inwardLineItemIdOrProductionId: item.inwardLineItemId,
                    itemId: item.itemId,
                    checkingQuantity: parseInt(item.checkingQuantity),
                    acceptedQuantity: parseInt(item.acceptedQuantity),
                    rejectedQuantity: parseInt(item.rejectedQuantity),
                };

                lineItemsList2server.push(lineObj);

                if (item.qualityItems !== undefined) {
                    var samples = JSON.parse(item.qualityItems);

                    samples.forEach((sample) => {
                        var sampleObj = {
                            inwardLineItemIdOrProductionId: sample.inwardLineItemId,
                            description: sample.description,
                            quality: sample.quality,
                        };
                        sampleList.push(sampleObj);
                    });
                }
            });
            data2server.append("qcLineItems", JSON.stringify(lineItemsList2server));

            if (sampleList.length > 0) {
                data2server.append("qcSamples", JSON.stringify(sampleList));
            }
            submitData(data2server);
        }

        setQcErrors(errorList);
        setQcParams(paramsCopy);
    };

    const submitData = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createCustomer",
            method: "put",
            url: "store-edit/qc",
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
            data: data,
        });

        if (result.msg === "success") {
            if (editApprove) {
                approveQc();
                setModalText("QC Edited Successfully! Approving...");
            } else {
                setModalText("QC Edited Successfully!");
                setDataSubmitted(true);
            }
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setQcErrors([result.desc]);
            setDataSubmitted(true);
        }
    };

    async function approveQc() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `store-edit/qc-approve/${qcDetail.qcId}`,
            headers: { token: isLogged.accessToken, module: "Quality Checking" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Quality Checking Approved");
        } else {
            setModalText(data.desc);
            setQcErrors(data.desc);
        }
    }

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
            if( (element == 'productionId' && qcParams.orderType.value == 'Production Order')
                 || (element == 'inwardId' && qcParams.orderType.value !== 'Production Order')   
                || !['productionId','inwardId'].includes(element)
                )
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value}
                    setInput={(value) => {
                        updateParamaters({ section, paramName: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                    maxdate={param[element].maxdate}
                    removeBorder={param[element].removeBorder}
                />
            );
        });
    };

    const RenderDate = () => {
        return inwardDetail.orderDate !== undefined ? (
            <div className="createSalesInvoiceGrid">
                <div
                    style={{
                        gridColumn: `auto / span 9`,
                        display: "flex",
                        alignItems: "center",
                        // paddingLeft: "10px",
                        color: "#333333",
                    }}
                >
                    Order Date:&nbsp;&nbsp;
                    <span style={{ color: "#333333", fontWeight: "normal" }}>
                        {inwardDetail.orderDate !== undefined ? inwardDetail.orderDate : "-"}
                    </span>
                </div>
                <div
                    style={{
                        gridColumn: `auto / span 9`,
                        display: "flex",
                        alignItems: "center",
                        // paddingLeft: "10px",
                        color: "#333333",
                    }}
                >
                    Inward Date:&nbsp;&nbsp;
                    <span style={{ color: "#333333", fontWeight: "normal" }}>
                        {inwardDetail.inwardDate !== undefined ? inwardDetail.inwardDate : "-"}
                    </span>
                </div>
            </div>
        ) : null;
    };

    const RenderVendorDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">{inwardDetail.companyName}</div>
                <div className="detailText">
                    {`Customer ID: ${inwardDetail.companyId}`},
                    <br /> {inwardDetail.companyAddress},
                    <br /> {inwardDetail.companyCity} {inwardDetail.companyPinCode !== null ? ` - ${inwardDetail.companyPinCode}` : null}
                    {inwardDetail.companyMobile !== null || inwardDetail.companyPhone !== null ? <br /> : null}
                    {inwardDetail.companyMobile !== null || inwardDetail.companyPhone !== null ? `Phone: ` : null}
                    {inwardDetail.companyMobile !== null ? inwardDetail.companyMobile : null}
                    {inwardDetail.companyPhone !== null ? `, ${inwardDetail.companyPhone} ` : null}
                    {inwardDetail.companyEmail !== null ? <br /> : null}
                    {inwardDetail.companyEmail !== null ? `Email Id: ${inwardDetail.companyEmail}` : null}
                    {inwardDetail.companyGstNo !== null ? <br /> : null}
                    {inwardDetail.companyGstNo !== null ? `GST No:  ${inwardDetail.companyGstNo}` : null}
                </div>
            </React.Fragment>
        );
    };

    const resetItemErrors = () => {
        var paramsCopy = Object.assign(qcParams);
        qcParamList[2].forEach((item) => {
            paramsCopy = update(paramsCopy, { [item]: { value: { $set: "" }, error: { $set: false } } });
        });
        setQcParams(paramsCopy);
    };
    const renderSubSection = () => {
        return (
            <div className="purchaseOrderIndividualItemsArea" style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                {renderFormElements({ elementList: qcParamList[2], param: qcParams, section: "general" })}
                <FormElement
                    inputType="addButton"
                    value="+ Add"
                    colSpan={5}
                    setInput={() => {
                        checkLineItemError();
                    }}
                />
                <div
                    style={{
                        marginTop: "26px",
                        gridColumn: "span 1",
                        color: "#666",
                        background: "rgb(230,230,230)",
                        cursor: "pointer",
                    }}
                >
                    <img
                        style={{ padding: "10px", width: "1rem", height: "1rem" }}
                        src={resetIcon}
                        alt="Reset"
                        onClick={() => {
                            resetItemErrors();
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderQualitySection = () => {
        return (
            <React.Fragment>
                <div style={{ margin: "0 4rem", backgroundColor: "rgb(244,244,244)" }}>
                    <div className="purchaseOrderIndividualItemsArea" style={{ backgroundColor: "rgb(244,244,244)", margin: "0 1rem" }}>
                        {renderFormElements({ elementList: qcParamList[3], param: qcParams, section: "general" })}
                        <FormElement
                            inputType="addButton"
                            value="+ Add"
                            colSpan={3}
                            setInput={() => {
                                // checkLineItemError();
                                checkQualityError();
                            }}
                        />
                        {/* <div
                    style={{
                        marginTop: "26px",
                        gridColumn: "span 1",
                        color: "#666",
                        background: "rgb(230,230,230)",
                        cursor: "pointer",
                    }}
                    >
                    <img style={{ padding: "10px", width: "1rem", height: "1rem" }} src={resetIcon} alt="Reset" onClick={() => {}} />
                </div> */}
                    </div>
                    <RenderQualityTable />
                    &nbsp;
                </div>
            </React.Fragment>
        );
    };

    const editSubSectionItem = (index, action) => {
        var itemListCopy = lineItemsList.slice();
        var paramsCopy = Object.assign(qcParams);

        if (action === "edit") {
            var proceed = true;

            ["itemId", "checkingQuantity", "acceptedQuantity", "rejectedQuantity"].forEach((element) => {
                if (paramsCopy[element].value !== "") {
                    proceed = false;
                }
            });

            if (!proceed) {
                var yes = window.confirm("Unsaved information found. Overwrite?");
            }

            if (proceed || yes) {
                var editObj = itemListCopy.splice(index, 1)[0];

                var itemOptions = paramsCopy.itemId.options;
                itemOptions.push(editObj.itemOption);

                paramsCopy = update(paramsCopy, {
                    itemId: { value: { $set: editObj.itemId }, options: { $set: itemOptions } },
                    qcLineItemId: { value: { $set: editObj.qcLineItemId } },
                    checkingQuantity: { value: { $set: editObj.checkingQuantity } },
                    acceptedQuantity: { value: { $set: editObj.acceptedQuantity } },
                    rejectedQuantity: { value: { $set: editObj.rejectedQuantity } },
                });

                if (editObj.qualityItems !== undefined && editObj.qualityItems.length > 0) {
                    var qtylist = JSON.parse(editObj.qualityItems);
                    setQualityList(qtylist);
                }
                setQcParams(paramsCopy);
                setLineItemsList(itemListCopy);
            }
        }
        if (action === "delete") {
            var editObj = itemListCopy.splice(index, 1)[0];

            var itemOptions = paramsCopy.itemId.options;
            itemOptions.push(editObj.itemOption);

            paramsCopy = update(paramsCopy, {
                itemId: { options: { $set: itemOptions } },
            });

            setLineItemsList(itemListCopy);
        }
    };

    const editQualitySubsection = (index, action) => {
        var qualityListCopy = qualityList.slice();

        var paramsCopy = Object.assign(qcParams);

        if (action === "edit") {
            var lineItemAllClear = true;
            ["description", "quality"].forEach((item) => {
                if (qcParams[item].value !== "") lineItemAllClear = false;
            });

            if (!lineItemAllClear) {
                var yes = window.confirm("Unsaved data found. Are you sure you want to overwrite it?");
            }

            if (lineItemAllClear || yes) {
                var editObj = qualityListCopy.splice(index, 1)[0];

                paramsCopy = update(paramsCopy, {
                    unit: { value: { $set: `Unit ${qualityListCopy.length + 1}` } },
                    description: { value: { $set: editObj.description } },
                    quality: { value: { $set: editObj.quality } },
                });

                setQualityList(qualityListCopy);
                setQcParams(paramsCopy);
            }
        }
        if (action === "delete") {
            qualityListCopy.splice(index, 1);
            setQualityList(qualityListCopy);
            paramsCopy = update(paramsCopy, { unit: { value: { $set: `Unit ${qualityListCopy.length + 1}` } } });

            setQcParams(paramsCopy);
        }
    };

    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Item</td>
                        <td>Item Code</td>
                        {isLogged.showHideWeight ?
                        <><td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td></> :null}
                        <td>HSN Code</td>
                        <td>UOM</td>
                        <td>Received Quantity</td>
                        <td>Checking Quantity</td>
                        <td>Accepted Quantity</td>
                        <td>Rejected Quantity</td>
                        <td className="createVendorContactsAction">Actions</td>
                    </tr>
                </thead>
                {lineItemsList.length > 0 ? (
                    <tbody>
                        {lineItemsList.map((row, j) => {
                            return (
                                <tr className="createVendorContactsTableRows" key={j}>
                                    {qcParamList[2].map((key, i) => {
                                        return <td key={i}>{key === "itemId" ? row.itemName : row[key] !== null ? row[key] : "-"}</td>;
                                    })}
                                    <td>
                                        <img
                                            alt="Edit Record"
                                            className="createVendorContactsAction"
                                            src={editIcon}
                                            onClick={() => {
                                                editSubSectionItem(j, "edit");
                                            }}
                                        />
                                        <img
                                            alt="Delete Record"
                                            className="createVendorContactsAction"
                                            src={deleteIcon}
                                            onClick={() => {
                                                var reset = window.confirm(
                                                    "All  of item information and sample quality information for this item will be deleted. Proceed?"
                                                );
                                                if (reset) {
                                                    editSubSectionItem(j, "delete");
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows">
                            {qcParamList[2].map((item) => {
                                return <td key={item}>&nbsp;</td>;
                            })}
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const RenderQualityTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader" style={{ backgroundColor: "white" }}>
                        <td>Unit</td>
                        <td>Description</td>
                        <td>Quality (%)</td>
                        <td className="createVendorContactsAction">Actions</td>
                    </tr>
                </thead>
                {qualityList.length > 0 ? (
                    <tbody>
                        {qualityList.map((row, j) => {
                            return (
                                <tr className="createVendorContactsTableRows" style={{ backgroundColor: "white" }} key={j}>
                                    {["unit", "description", "quality"].map((key, i) => {
                                        return key === "unit" ? <td key={key}>{`Unit ${j + 1}`}</td> : <td key={i}>{row[key]}</td>;
                                    })}
                                    <td>
                                        <img
                                            alt="Edit Record"
                                            className="createVendorContactsAction"
                                            src={editIcon}
                                            onClick={() => {
                                                editQualitySubsection(j, "edit");
                                            }}
                                        />
                                        <img
                                            alt="Delete Record"
                                            className="createVendorContactsAction"
                                            src={deleteIcon}
                                            onClick={() => {
                                                var reset = window.confirm("Delete record?");
                                                if (reset) {
                                                    editQualitySubsection(j, "delete");
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="createVendorContactsTableRows" style={{ backgroundColor: "white" }}>
                            {["", "", "", ""].map((item,index) => {
                                return <td key={index}>&nbsp;</td>;
                            })}
                        </tr>
                    </tbody>
                )}
            </table>
        );
    };

    const renderErrorMessage = () => {
        if (qcErrors.length > 0) {
            return qcErrors[0];
        } else return null;
    };
    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
                <div
                    style={{
                        width: "1000px",
                        margin: "0 auto 2rem",
                        padding: "2rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "1" }}>
                            <div style={{ display: "flex", flex: 1 }}>
                                <div className="createSalesInvoiceGrid">
                                    {renderFormElements({ elementList: qcParamList[0], param: qcParams, section: "general" })}
                                </div>
                            </div>

                            <RenderDate />

                            <div style={{ display: "flex" }}>
                                <div className="createSalesInvoiceGrid">
                                    {renderFormElements({ elementList: qcParamList[1], param: qcParams, section: "general" })}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{showCompanyDetail ? <RenderVendorDetail /> : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="purchaseOrderSubSectionArea" style={{width:"90%"}}>
                        {renderSubSection()}
                        {renderQualitySection()}
                        <div style={{ marginTop: "1rem" }}>
                            <RenderTable />
                        </div>
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction" style={{maxWidth:"unset"}}>
                            <div>
                                No Items: <span>{lineItemsList.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>Instruction</div>
                            <textarea
                                className="createInwardMultilineInput"
                                value={instruction || ""}
                                rows="3"
                                cols="30"
                                name="text"
                                placeholder="Enter instructions"
                                style={{
                                    display: "flex",
                                    padding: "10px",
                                    resize: "none",
                                    marginTop: "10px",
                                    height: "114px",
                                    fontFamily: "sans-serif",
                                }}
                                onChange={(e) => {
                                    setInstruction(e.target.value);
                                }}
                            />

                            <div style={{ display: "flex" }}>
                                <div className="createSalesInvoiceGrid2">
                                    {qcDocument !== "" ? (
                                        <div className="formElement" style={{ gridColumn: `auto / span ${9}` }}>
                                            <FormHint hintText="QC Document" mandatory={false} />
                                            <div className="inputDone">
                                                <span
                                                    style={{
                                                        flex: 1,
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        // maxWidth: "168px",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    ✅ {qcDocument.name}
                                                </span>
                                                <span
                                                    style={{ paddingRight: "10px", cursor: "pointer", "&:hover": { backgroundColor: "gray" } }}
                                                    onClick={() => setQcDocument("")}
                                                    title="Remove and Re-upload LR Copy"
                                                >
                                                    ❌
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <FormElement
                                            colSpan={9}
                                            inputType="upload"
                                            hintText="QC Document"
                                            setInput={(file) => {
                                                setQcDocument(file);
                                            }}
                                        />
                                    )}
                                </div>

                                <div style={{ width: "300px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="submitButton"
                        onClick={() => {
                            setQcErrors([]);
                            checkErrors("Approval Pending");
                        }}
                    >
                        {editApprove ? "Edit & Approve" : "Submit for Approval"}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQc);
