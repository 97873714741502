import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { addToken } from '../../../redux/UserAccount/IsLoggedActions';
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import fetchData from '../../../serverCall/fetchData';

const MovementAnalysisList = ({ isLogged,setSection,poInfo,movementAnalysisParams}) => {
    const [lineItemChunk,setLinItemChunk] =useState([]);
    const [chartData,setChartData]=useState([]);
    const [itemData, setItemData] = useState(null);
    const [loading, setLoading] = useState(true);

    
  async function getItemDetail() {
    if(movementAnalysisParams.itemId){

      var data = await fetchData({
          requestingPage: "itemDetail",
          method: "get",
          url: "fetch/item/" + movementAnalysisParams.itemId,
          headers: { token: isLogged.accessToken,module:"Items" },
      });
      if (data.msg === "success") {
          setItemData(data.asset.item[0]);
      }
    }
  }


  const splitLineItems = (TableData) => {
    let lineCopy = lineItemChunk.slice();
    let closedObject = updatedTableData(TableData);
    for (let i = 0; i < closedObject.length; i += 20) // 20 items in each page
    {
      lineCopy.push(closedObject.slice(i, i + 20));
    }
    setLinItemChunk(lineCopy);
    setChartData(TableData)
  }

  const updatedTableData = (TableData) => {
    let closed = 0;
    const updatedData = TableData.map((row) => {
      let closedObject = { quantity: 0, value: 0 };
      if (row.groupType === "Inward") {
        closed += parseFloat(row.quantity);
        closedObject.quantity = closed
        return { ...row, Closing: { ...closedObject } };
      } else {
        closed = closed - parseFloat(row.quantity);
        closedObject.quantity = closed
        return { ...row, Closing: { ...closedObject } };
      }
    }
    );
    return updatedData
  }
    async function getInformation () {
      await getItemDetail();

      let response = await fetchData({
        requestingPage: "stockReport",
        method: "POST",
        url: "store-fetch/movement-analysis",
        headers: { token: isLogged.accessToken,module:"Movement Analysis" },
        data: movementAnalysisParams
      });

      if(response.msg==="success"){
        // let filteredRecord =response.asset.reduce((acc,curr)=>{
        //     if(acc.length > 0){
        //       acc.map((dup,index)=>{
        //           if(dup.customerName === curr.customerName && dup.itemName === curr.itemName ){
        //               if(acc[index].groupType !== curr.groupType){
        //                 acc[index].groupType = 'Both'
        //                 acc[index][curr.groupType==="Inward"? "InwardQty":"OutwardQty"]= curr.quantity
        //                 acc[index][curr.groupType==="Inward"? "InwardPrice":"OutwardPrice"]= curr.price
        //                 acc[index][acc.groupType==="Inward"? "InwardQty":"OutwardQty"]= acc.quantity
        //                 acc[index][acc.groupType==="Inward"? "InwardPrice":"OutwardPrice"]= acc.price
        //               }else{
        //                 acc[index].quantity += curr.quantity
        //                 acc[index].price += curr.price
        //               }
        //           }else{
        //             acc.push(curr)
        //           }
        //       })
        //     }else{
        //       acc.push(curr)
        //     }
        //     return acc
        // },[])

        // splitLineItems(filteredRecord)
        splitLineItems(response.asset)

      }else{
        setSection("movementAnalysisParams")
      }
      setLoading(false);

      /*FOR DEV */
      // for(let i=0;i<35 ;i++){
      //   TableData[i] = TableData[i%3]
      //   }
      // splitLineItems(TableData);
      /*FOR DEV*/
    }
    useEffect(()=>{
      getInformation()
    },[]);

  const renderChart = (type) => {
    var tempChartData = [];
    var data;
    switch (type) {
      case 'pie':
        data = [
          {
            id: 'Inward',
            label: 'Inward',
            value: chartData.reduce((total, obj) => { 
                        if (obj.groupType === 'Inward') {
                          return obj.quantity + total;
                        } else {
                          return total + 0;
                        }
                      }, 0)
          },
          {
            id: 'Outward',
            label: 'Outward',
            value: chartData.reduce((total, obj) => { 
                        if (obj.groupType === 'Outward') {
                          return obj.quantity + total;
                        } else {
                          return total + 0;
                        }
                      }, 0)
          },
        ];
        return renderPieChart(data);
      case 'bar':
        data = chartData.map((Imp) => {
          return {
            'Customer': Imp.customerName, 'Price': Imp.price,
            'Quantity': Imp.quantity
          }
        }
        );
        return renderBarChart(data);
      case 'line':
        const inwards = chartData.filter((i) => i.groupType === 'Inward');
        const outwards = chartData.filter((i) => i.groupType === 'Outward');
        data = {
          id: 'Inward',
          data: inwards.map((Imp) => ({x: Imp.date, y: Imp.price }))
        };
        tempChartData.push(data);
        data = {
          id: 'Outward',
          data: outwards.map((Imp) => ({x: Imp.date, y: Imp.price }))
        };
        tempChartData.push(data);
        // break;
        return renderLineChart(tempChartData);
      default:
        return null;
    }
  }

  const renderLineChart = (data) => {

    return (
      <Fragment>
        <div
          className="dashboardCard"
          style={{
            height: "390px",
            gridColumn: `1/span 3`,
            alignSelf: 'start',
            gridRow: `3 / span 2`,
            padding: "2rem",
          }}>
          <div className="dashboardGraphBody">
            <ResponsiveLine
              data={data}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
              curve="linear"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Date',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Price',
                legendOffset: -55,
                legendPosition: 'middle',
              }}
              isInteractive={true}
              colors={{ scheme: 'category10' }}
              pointSize={10}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </Fragment>
    )
  }
  const renderBarChart = (data) => {

    return (
      <Fragment>
        <div
          className="dashboardCard"
          style={{
            height: "390px",
            gridColumn: `1/span 3`,
            alignSelf: 'start',
            gridRow: `3 / span 2`,
            padding: "2rem",
          }}>
          <div className="dashboardGraphBody">
            <ResponsiveBar
              data={data}
              keys={['Quantity', 'Price']}
              indexBy="Customer"
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              padding={0.3}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: 'nivo' }}
              groupMode='grouped'
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.6
                  ]
                ]
              }}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Storage',
                legendPosition: 'middle',
                legendOffset: 32
              }}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Quantity',
                legendPosition: 'middle',
                legendOffset: -55
              }}
              animate={true}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              motionStiffness={90}
              motionDamping={15}
              motionConfig="wobbly"
            />
          </div>
        </div>
      </Fragment>
    )
  }
  const renderPieChart = (data) => {

    return (
      <Fragment>
        <div
          className="dashboardCard"
          style={{
            height: "390px",
            gridColumn: `1/span 3`,
            alignSelf: 'start',
            gridRow: `3 / span 2`,
            padding: "2rem",
          }}>
          <div className="dashboardGraphBody">
            <ResponsivePie
              data={data}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    0.2
                  ]
                ]
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    2
                  ]
                ]
              }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: 'rgba(255, 255, 255, 0.3)',
                  size: 4,
                  padding: 1,
                  stagger: true
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: 'rgba(255, 255, 255, 0.3)',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
                }
              ]}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'row',
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          </div>
        </div>
      </Fragment>
    )
  }
  const RenderMovementanalysislTable = ({PageNumber}) => {
    const date = `${movementAnalysisParams?.fromDate?.toString().split('T')[0] + ' to ' + movementAnalysisParams?.toDate?.toString().split('T')[0]}`;
    return (
        <table className="invoiceBill" style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div style={{ display: "flex" }}>
                  <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
                    {
                      itemData?.item?
                    <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
                      <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Stock Item: {itemData?.item}</span>
                    </div>
                    :
                    <></>

                    }
                  </div>
                  <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top", marginLeft: "auto" }}>
                    <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
                      {/* <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Seller Or Vendor Name</span> */}
                      { (movementAnalysisParams.fromDate || movementAnalysisParams.toDate) ? <span className="poSummaryValue">{date}</span> : `` }
                      { movementAnalysisParams.plantName?.length ? <span className="poSummaryValue">{(movementAnalysisParams.plantName).map((pl) => pl.label).toString()}</span> : `` }
                      { movementAnalysisParams.storageName?.length ? <span className="poSummaryValue">{(movementAnalysisParams.storageName).map((sl) => sl.label).toString()}</span> : `` }
                      <span className="poSummaryValue">Page Number : {PageNumber}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    )
  }


  const RenderTable = ({ currentPageLineItems, PageNumber }) => {
    let inward = {
      quantity: currentPageLineItems?.reduce((acc, curr) => { if (curr.groupType === 'Inward') { return acc + parseFloat(curr.quantity || 0) } else { return acc + 0}}, 0),
      value: currentPageLineItems?.reduce((acc, curr) => { if (curr.groupType === 'Inward') { return acc + parseFloat(curr.price || 0) } else { return acc + 0}}, 0)
    }
    let outward = {
      quantity: currentPageLineItems?.reduce((acc, curr) => { if (curr.groupType === 'Outward') { return acc + parseFloat(curr.quantity || 0) } else { return acc + 0}}, 0),
      value: currentPageLineItems?.reduce((acc, curr) => { if (curr.groupType === 'Outward') { return acc + parseFloat(curr.price || 0) } else { return acc + 0}}, 0)
    }
    let closed = {
      quantity: currentPageLineItems?.reduce((acc, curr) => acc + parseFloat(curr.Closing?.quantity || 0), 0),
      value: currentPageLineItems?.reduce((acc, curr) => acc + parseFloat(curr.Closing?.value || 0), 0)
    }
    return (
      <table className="innerTable" style={{ width: "100%", height: "90%" }}>
        <thead>
          <tr className="invoiceDetailContactsTableHeader" style={{ color: "#000" }}>
            {
              itemData?null:
              <td rowSpan="2" style={{ position: "relative", border: "0px", borderBottom: "1px solid" }}>Item Name</td>
            }
            {movementAnalysisParams.selectBy || movementAnalysisParams.itemId?
            <>
            <td rowSpan="2" style={{ position: "relative", border: "0px", borderBottom: "1px solid" }}>Date</td>
            
            <td rowSpan="2" style={{ position: "relative", border: "0px", borderBottom: "1px solid" }}>Particulars</td>
            </>:null
          }

            <td rowSpan="2" style={{ position: "relative", border: "0px", borderBottom: "1px solid" }}>Vch Type</td>
            <td rowSpan="2" style={{ position: "relative", border: "0px", borderBottom: "1px solid" }}>Vch No</td>
            <td colSpan="2">Inwards</td>
            <td colSpan="2">Outwards</td>
            <td colSpan="2">Closing</td>
          </tr>
          <tr className="invoiceDetailContactsTableHeader" style={{ color: "#000" }}>
            <td >Quantity</td>
            <td >Value</td>
            <td >Quantity</td>
            <td >Value</td>
            <td >Quantity</td>
            <td >Value</td></tr>
        </thead>
        <tbody>
          {currentPageLineItems.map((item, index) =>
            <tr className="invoiceDetailContactsTableRows" key={index}>
              {
                itemData?null:
                <td style={{ border: "none" }}>{item.itemName}</td>
                
              }
              {movementAnalysisParams.selectBy || movementAnalysisParams.itemId?
              <>
              <td style={{ border: "none", textAlign: "center" }}>{item.date}</td>
              <td style={{ border: "none", textAlign: "center" }}>{item.customerName}</td>
              </>:null
            }
              <td style={{ border: "none", textAlign: "center" }}>{item.orderType || item.voucherType}</td>
              <td style={{ border: "none", textAlign: "center" }}>{item.orderNo || item.voucherNo}</td>
              <td style={{ border: "none", borderLeft: "1px solid", textAlign: "center" }}>{item.groupType==='Inward' ? item.quantity : ''}</td>
              <td style={{ border: "none", textAlign: "center" }}>{item.groupType==='Inward' ? item.price : ''}</td>
              <td style={{ border: "none", borderLeft: "1px solid", textAlign: "center" }}>{item.groupType==='Outward' ? item.quantity : ''}</td>
              <td style={{ border: "none", textAlign: "center" }}>{item.groupType==='Outward' ? item.price : ''}</td>
              <td style={{ border: "none", borderLeft: "1px solid", textAlign: "center" }}>{item.Closing?.quantity}</td>
              <td style={{ border: "none", textAlign: "center" }}>{item.Closing?.value}</td>
            </tr>

          )}
          <tr className="invoiceDetailContactsTableRows">
            <td colSpan={itemData?4:3} className="align-right">Total</td>
            <td className="align-center">Nos {inward.quantity}</td>
            <td className="align-center">₹ {inward.value}</td>
            <td className="align-center">Nos {outward.quantity}</td>
            <td className="align-center">₹ {outward.value}</td>
            <td className="align-center">Nos {inward.quantity - outward.quantity}</td>
            <td className="align-center">₹ {inward.value - outward.value}</td>
          </tr>
            <tr className="invoiceDetailContactsTableRows">
              <td colSpan={itemData?10:11} style={{textAlign:"right"}}>
                {PageNumber === lineItemChunk.length ? "End" : `Continued To Page Number ${PageNumber+1}`}
              </td>
            </tr>
        </tbody>
      </table>
    )
  }
    return (
        <Fragment>
            <div className="detailsPageWrapper">
              {lineItemChunk.length?
              
              <>
                  {lineItemChunk.map((currentPage,index)=>(
                  <div className="detailsPageContainer" key={index}>
                      <div style={{ border: "0.25px solid black", minHeight: "1100px", position: "relative" }}>
                      <div>
                              <RenderMovementanalysislTable PageNumber={index+1}/>
                          </div>
                          <RenderTable currentPageLineItems={currentPage} PageNumber={index+1}/>
                      </div>
                  </div>
                  ))}
            
              {movementAnalysisParams.enableChart && 
              <div className="detailsPageContainer">
              <div className='dashboardGrid'>
                  {chartData.length > 0 && movementAnalysisParams.enableChart ? renderChart(movementAnalysisParams.chartType) : null}
                  </div>
              </div>}
              </>
              :
              loading ? (
                <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) :
              <div className="noRecordsYet">No records found</div>             
            }
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
        movementAnalysisParams: state.poInfo.stockReportParams
    };
};

const mapDispatchToProps = (dispatch) => {

    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MovementAnalysisList);