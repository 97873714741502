/*eslint-disable*/
import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { editQcId, setQcTab, viewQcId } from "../../redux/Store/StoreInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { exportData } from "../CommonFunctions/ExportExcel";
import FormElement from "../SmallComponents/FormElement";
import pageIcon from "../../assets/sheets.svg";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import downloadIcon from "../../assets/download.svg";
import approveIcon from "../../assets/approve2.svg";
import approveIconDisabled from "../../assets/approve.svg";
import { handleSort } from "../CommonFunctions/utils";
import Pagination from '../CommonFunctions/pagination';
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";

const QcList = ({
    // props
    setSection,
    setLoadInward,
    setApproval,
    setEditAndApprove,

    // state
    isLogged,
    storeInfo,

    // dispatch
    setQcTab,
    addTokenToState,
    viewQcId,
    editQcId,
}) => {
    const [loading, setLoading] = useState(true);
    const [qcUnapproved, setQcUnapproved] = useState([]);
    const [qcApproved, setQcApproved] = useState([]);
    const [inwardApproved, setInwardApproved] = useState([]);
    const [plantId,setPlantId] = useState();
    const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
    const [noApprovedRecords, setNoApprovedRecords] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const inwardApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return inwardApproved.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,inwardApproved,PageSize]);
      const qcUnApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return qcUnapproved.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,qcUnapproved,PageSize]);
      const qcApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return qcApproved.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,qcApproved,PageSize]);
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        //await getInwardApproved();
    }

    const history = useHistory();
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getInwardApproved() {
        let data = await fetchData({
            requestingPage: "qcList",
            method: "post",
            url: "store-fetch/qc-pre-create-inward-approved-list",
            headers: { token: isLogged.accessToken,module: "Quality Checking" },
            data: {plantId}
        });
        if (data.msg === "success") {
            let data2 = [];

            data.asset.forEach((element, i) => {
                let newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setInwardApproved(data2);
            if (data.asset.length > 0) setNoApprovedRecords(false);
            else setNoApprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getQcUnapproved() {
        let data = await fetchData({
            requestingPage: "qcList",
            method: "post",
            url: "store-fetch/qc-unapproved-list",
            headers: { token: isLogged.accessToken,module: "Quality Checking" },
            data:{plantId}
        });
        if (data.msg === "success") {

            let data2 = [];
            data.asset.forEach((element, i) => {
                let newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setQcUnapproved(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        } else{
            console.log(data);
        }
        setLoading(false);
    }

    async function getQcApproved() {
        let data = await fetchData({
            requestingPage: "qcList",
            method: "post",
            url: "store-fetch/qc-approved-list",
            headers: { token: isLogged.accessToken,module: "Quality Checking" },
            data:{plantId}
        });
        if (data.msg === "success") {

            let data2 = [];
            data.asset.forEach((element, i) => {
                let newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });

            setQcApproved(data2);
            if (data.asset.length > 0) setNoApprovedRecords(false);
            else setNoApprovedRecords(true);
        }
        setLoading(false);
    }

    useEffect( () => {
        setLoading(true);

        switch (storeInfo.qcTab) {
            case "inwardList":
                setInwardApproved([]);
                getInwardApproved();
                break;

            case "qcList":
                setQcUnapproved([]);
                getQcUnapproved();
                break;

            case "approvedQcList":
                setQcApproved([]);
                getQcApproved();
                break;

            default:
                break;
        }
    }, [storeInfo.qcTab,plantId]);

    const setSelectedTab = (tab) => {
        setQcTab(tab);
    };
    const tablist = [
        { id: "inwardList", name: "Inward List" },
        { id: "qcList", name: "QC List" },
        { id: "approvedQcList", name: "Approved QC List" },
    ];
    const access = isLogged.access["Quality Checking"];
    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={storeInfo.qcTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => setSelectedTab(tab.id)}
                >
                    {tab.name}
                </div>
            );
        });
    };

    const editItemCollapse = (list, index) => {
        let listCopy = [];

        switch (list) {
            case "inwardApproved":
                listCopy = inwardApproved.slice();
                break;

            case "qcList":
                listCopy = qcUnapproved.slice();
                break;

            case "qcApprovedList":
                listCopy = qcApproved.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "inwardApproved":
                setInwardApproved(listCopy);
                break;

            case "qcList":
                setQcUnapproved(listCopy);
                break;

            case "qcApprovedList":
                setQcApproved(listCopy);
                break;
        }
    };
    const [order, setOrder] = useState({ col: "checkingNo", state: true, data: "" });

    const CommonColumns = ({ data, tab }) => {

        return (
            <tr className="vendorListHeader" style={{ zIndex: "1" }}>
                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                    setOrder({
                        col: "checkingNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "checkingNo",
                        state: order.state,
                    });
                }}
                >Checking No<img
                        className="sortArrow"
                        src={
                            order.col === "checkingNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "checkingDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "checkingDate",
                        state: order.state,
                    });
                }}
                >Checking Date<img
                        className="sortArrow"
                        src={
                            order.col === "checkingDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "orderNo",
                        state: order.state,
                    });
                }}
                >Order No <img
                        className="sortArrow"
                        src={
                            order.col === "orderNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "inwardNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "inwardNo",
                        state: order.state,
                    });
                }}
                >Inward No<img
                        className="sortArrow"
                        src={
                            order.col === "inwardNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "companyName",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "companyName",
                        state: order.state,
                    });
                }}
                >Company Name<img
                        className="sortArrow"
                        src={
                            order.col === "companyName"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderType",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "orderType",
                        state: order.state,
                    });
                }}
                >Order Type<img
                        className="sortArrow"
                        src={
                            order.col === "orderType"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                    });
                }}
                >Created By<img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                {tab === 'approved' ? <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "approvedBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "approvedBy",
                        state: order.state,
                    });
                }}
                >Approved By<img
                        className="sortArrow"
                        src={
                            order.col === "approvedBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td> : null}
                <td className="vendorListHeadingActions">Actions</td>
            </tr>
        )
    }
    const renderSubSection = () => {
        switch (storeInfo.qcTab) {
            case "inwardList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                            <tr className="vendorListHeader" style={{ zIndex: "1" }}>
                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                    setOrder({
                        col: "inwardNO",
                        state: !order.state,
                    });
                    handleSort({
                        data: inwardApprovedCurrentPage,
                        col: "inwardNo",
                        state: order.state,
                    });
                }}
                >Inward No<img
                        className="sortArrow"
                        src={
                            order.col === "inwardNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "inwardDate",
                        state: !order.state,
                    });
                    handleSort({
                        data:inwardApprovedCurrentPage,
                        col: "inwardDate",
                        state: order.state,
                    });
                }}
                >Inward Date<img
                        className="sortArrow"
                        src={
                            order.col === "inwardDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "opdNo",
                        state: !order.state,
                    });
                    handleSort({
                        data:inwardApprovedCurrentPage,
                        col: "opdNo",
                        state: order.state,
                    });
                }}
                >OPD No<img
                        className="sortArrow"
                        src={
                            order.col === "opdNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                    <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "companyName",
                        state: !order.state,
                    });
                    handleSort({
                        data :inwardApprovedCurrentPage,
                        col: "companyName",
                        state: order.state,
                    });
                }}
                >Company Name<img
                        className="sortArrow"
                        src={
                            order.col === "companyName"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderType",
                        state: !order.state,
                    });
                    handleSort({
                        data:inwardApprovedCurrentPage,
                        col: "orderType",
                        state: order.state,
                    });
                }}
                >Order Type <img
                        className="sortArrow"
                        src={
                            order.col === "orderType"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data:inwardApprovedCurrentPage,
                        col: "createdBy",
                        state: order.state,
                    });
                }}
                >Created By<img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeadingActions">Actions</td>
            </tr>
                            </thead>
                            <tbody>{inwardApproved.length > 0 ? renderInwardApproved() : null}</tbody>
                        </table>
                        {inwardApproved.length === 0 ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={inwardApproved.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "qcList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <CommonColumns data={qcUnApprovedCurrentPage} tab={'unapproved'}/>
                            </thead>
                            <tbody>{qcUnapproved.length > 0 ? renderQcUnapprovedList() : null}</tbody>
                        </table>
                        {qcUnapproved.length === 0 ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={qcUnapproved.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "approvedQcList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                                <CommonColumns data={qcApprovedCurrentPage} tab={'approved'}/>
                            </thead>
                            <tbody>{qcApproved.length > 0 ? renderQcApprovedList() : null}</tbody>
                        </table>
                        {noApprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={qcApproved.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            default:
                return <div style={{ padding: "2rem", textAlign: "center" }}>Section Under Construction</div>;
        }
    };

    const renderInwardApproved = () => {
        return inwardApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment key={item.inwardNo}>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        onClick={() => editItemCollapse("inwardApproved", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.orderNo}</td>
                        <td className="vendorListInfo">{item.inwardNo}</td>
                        <td className="vendorListInfo">{item.inwardDate}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Create Invoice"
                                className="vendorActionImages"
                                src={pageIcon}
                                title={access === "Create" || access === "Edit" || access === "App/Dec" ? "Create QC" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(access === "Create" || access=== "Edit" || access==="App/Dec")
                                    {setSection("createQc");
                                    // soInvoiceCreate(item.soId);
                                    setLoadInward({ load: true, orderType: item.orderType, inwardId: item.inwardId });}
                                }}
                                style= {access === "Create" || access === "Edit" || access === "App/Dec"? {cursor : "pointer"} : {cursor : "not-allowed"}}
                            />
                            {/* <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Inward Detail"
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // viewInward(`${item.orderType}/${item.inwardId}`);
                                    // setSection("inwardDetail");
                                    // setApproval(false);
                                }}
                            /> */}

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("inwardApproved", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approvedSo" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={"7"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item code</td>
                            <td className="vendorListInnerTableHeading">Item</td>
                            {isLogged.showHideWeight ?
                            <><td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td></> : null}
                            <td className="vendorListInnerTableHeading">HSN No</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.inwardLineItemId}>
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    {isLogged.showHideWeight ?
                                    <><td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td></> :null}
                                    <td className="vendorListInnertableInfo">{row.hsnCode !== null ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const renderQcUnapprovedList = () => {
        return qcUnApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment key={item.inwardNo}>
                    <tr className={item.collapse ? "vendorList" : "vendorListExpanded"} onClick={() => editItemCollapse("qcList", i)}>
                        <td className="vendorListInfo stickyFirstColumn">{item.checkingNo}</td>
                        <td className="vendorListInfo">{item.checkingDate}</td>
                        <td className="vendorListInfo">{item.orderNo}</td>
                        <td className="vendorListInfo">{item.inwardNo}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={item.soStatus === "Draft" ? approveIconDisabled : approveIcon}
                                title={item.soStatus === "Draft" ? "Can't approve Draft" : (access === "App/Dec"?"Approve Record" :"Access Denied")}
                                style={item.soStatus !== "Draft" && access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.soStatus !== "Draft" && access === "App/Dec") {
                                        viewQcId(`${item.orderType}/${item.qcId}`);
                                        setSection("qcDetail");
                                        setApproval(true);
                                    }
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access === "App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" || access==="App/Dec" ? "Edit Item" : "Access Denied"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (access === "Edit" || access=== "App/Dec") {
                                        editQcId(`${item.orderType}/${item.qcId}`);
                                        setSection("qcEdit");
                                        setEditAndApprove(false);
                                        setLoadInward({ load: true, orderType: item.orderType, inwardId: item.inwardId });
                                    }
                                }}
                                style={access === "Edit" || access==="App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />

                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View QC Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewQcId(`${item.orderType}/${item.qcId}`);
                                    setSection("qcDetail");
                                    setApproval(false);
                                }}
                            />

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("qcList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderQcListSubTable tab="qcUnApproved" data={item.qcLineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderQcApprovedList = () => {
        return qcApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment   key={item.inwardNo}>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        onClick={() => editItemCollapse("qcApprovedList", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.checkingNo}</td>
                        <td className="vendorListInfo">{item.checkingDate}</td>
                        <td className="vendorListInfo">{item.orderNo}</td>
                        <td className="vendorListInfo">{item.inwardNo}</td>
                        <td className="vendorListInfo">{item.companyName}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View QC Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewQcId(`${item.orderType}/${item.qcId}`);
                                    setSection("qcDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Export Record"
                                className="vendorActionImages"
                                src={downloadIcon}
                                title="Export QC Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    exportData(isLogged, "qcApprovedSingleItem", { url: item.qcId.toString() });
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("qcList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderQcListSubTable tab="qcApproved" data={item.qcLineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const RenderQcListSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab==='qcApproved' ? "9" : "8"} className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item code</td>
                            <td className="vendorListInnerTableHeading">Item</td>
                            { isLogged.showHideWeight ? 
                            <><td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td> </> :null }
                            <td className="vendorListInnerTableHeading">HSN Code</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Received Quantity</td>
                            <td className="vendorListInnerTableHeading">Checking Quantity</td>
                            <td className="vendorListInnerTableHeading">Accepted Quantity</td>
                            <td className="vendorListInnerTableHeading">Rejected Quantity</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.inwardLineItemId}>
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemCode}</td>
                                    <td className="vendorListInnertableInfo">{row.itemName}</td>
                                    {isLogged.showHideWeight ?
                                    <><td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td></> : null}
                                    <td className="vendorListInnertableInfo">{row.hsnCode !== null ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.inwardQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.checkingQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.acceptedQuantity}</td>
                                    <td className="vendorListInnertableInfo">{row.rejectedQuantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                <span className="listPageParams">
                    <FormElement setInput={(value) => { setPlantId(value); }}
                        inputType={"options"}
                        value={plantId}
                        hintText={"Plant Name"}
                        options={isLogged.plantOptions}
                        colSpan={1}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false} />
                </span>
                <span style={{ flexGrow: 0, width: "80px" }}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
            </div>
            <div className="vendorListArea">{renderSubSection()}</div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        setQcTab: (tab) => dispatch(setQcTab(tab)),
        viewQcId: (qcId) => dispatch(viewQcId(qcId)),
        editQcId: (qcId) => dispatch(editQcId(qcId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QcList);
