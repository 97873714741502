import update from "immutability-helper";

const vendorObj = { viewVendorId: null, editVendorId: null };

const VendorInfo = (state = vendorObj, action) => {
    switch (action.type) {
        case "VIEW_VENDOR":
            return update(state, { viewVendorId: { $set: action.payload.vendorId } });

        case "EDIT_VENDOR":
            return update(state, { editVendorId: { $set: action.payload.vendorId } });

        default:
            return state;
    }
};

export default VendorInfo;
