import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";

const IdnEdit =({ setSection, isLogged, storeInfo, addTokenToState }) =>{
    const idnParamsList = ["mrsNo", "mrsReqDate", "mrsExpectedDate", "idnDate"];
    const lineItemsParamsList = isLogged.showHideWeight ? [
      "itemName",
      "itemCode",
      "from",
      "to",
      "netWeight",
      "grossWeight",
      "uom",
      "mrsQuantity",
      "idnQuantity",
    ] : ["itemName",
    "itemCode",
    "uom",
    "mrsQuantity",
    "idnQuantity",];
      const [lineItems, setLineItems] = useState([]);
     
      const [idnError, setIdnError] = useState([]);
      const [instruction, setInstruction] = useState("");
      const [idnDetail, setIdnDetail] = useState({});
  
    const [idnParams, setIdnParams] = useState({
        mrsNo: {
          inputType: "text",
          value: idnDetail && idnDetail.mrsNo,
          hintText: "MRS No",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please Select one of the Approved MRS Lists",
          disabled: true
        },
        mrsReqDate: {
          inputType: "text",
          value: idnDetail && idnDetail.mrsReqDate,
          hintText: " Request date",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please select Request date for request",
          mindate: true,
          disabled: true,
        },
        mrsExpectedDate: {
          inputType: "text",
          value: idnDetail && idnDetail.mrsExpectDate,
          hintText: "Expected request complete date",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please select expected date for completing request",
          mindate: true,
          disabled: true,
        },
        idnDate: {
          inputType: "text",
          value: idnDetail && idnDetail.idnDate,
          hintText: "IDN Date",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please select expected date for completing request",
          mindate: true,
          disabled: true,
        },
      });
    
   
      useEffect(() => {
        getInformation();
    }, []);
  useEffect(()=>{
    setIdnParams({
        mrsNo: {
          inputType: "text",
          value: idnDetail && idnDetail.mrsNo,
          hintText: "MRS No",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please Select one of the Approved MRS Lists",
          disabled: true
        },
        mrsReqDate: {
          inputType: "text",
          value: idnDetail && idnDetail.mrsReqDate,
          hintText: " Request date",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please select Request date for request",
          mindate: true,
          disabled: true,
        },
        mrsExpectedDate: {
          inputType: "text",
          value: idnDetail && idnDetail.mrsExpectDate,
          hintText: "Expected request complete date",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please select expected date for completing request",
          mindate: true,
          disabled: true,
        },
        idnDate: {
          inputType: "text",
          value: idnDetail && idnDetail.idnDate,
          hintText: "IDN Date",
          mandatory: true,
          colSpan: 6,
          error: false,
          errorMessage: "Please select expected date for completing request",
          mindate: true,
          disabled: true,
        }});
  },[idnDetail]);

    async function getInformation() {
        await checkToken();
        await getIdnDetail();
        
    }
    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
    async function getIdnDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "idn-fetch/request-detail/" + storeInfo.editIdnId,
            headers: { token: isLogged.accessToken,module:"IDN" },
        });
        if (data.msg === "success") {
            setIdnDetail(data.asset[0]);
            setLineItems(data.asset[0].lineItems);
        } else {
            console.log("Error in Fetching data",data);
        }
    }

    const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
          return (
            <FormElement
              key={element}
              inputType={param[element].inputType}
              value={param[element].value !== null ? param[element].value : ""}
    
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={
                param[element].inputType === "options"
                  ? param[element].options
                  : null
              }
              error={param[element].error}
              rowSpan={
                element === "vendorLogo" || element === "otherBusinessDetails"
                  ? param[element].rowSpan
                  : null
              }
              placeholder={param[element].placeholder}
              title={param[element].title}
              disabled={param[element].disabled}
              mindate={param[element].mindate}
            />
          );
        });
      };
      const updateLineItems = (param, index, value) => {
        var updatedList = lineItems.slice();
    
        updatedList = update(updatedList, {  [index]: { [param]: { $set: value } } });
        setLineItems(updatedList);
      };
      const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(idnParams);
        idnParamsList.forEach((item) => {
          if (paramsCopy[item].mandatory)
            paramsCopy = update(paramsCopy, {
              [item]: {
                error: {
                  $set: !validateMandatory(paramsCopy[item].value.toString()),
                },
              },
            });
          if (paramsCopy[item].error) {
            errorList.push(paramsCopy[item].errorMessage);
          }
        });
        var lineItemsCopy = lineItems.slice();
        let lineItemAllClear = true;
        lineItemsCopy.forEach((item) => {
          
          if (item["idnQuantity"]==="" || isNaN(item["idnQuantity"]) || parseInt(item["idnQuantity"]) < 0 || parseInt(item["idnQuantity"]) > parseInt(item["mrsQuantity"]) ) {
              item.idnQuantityError = true;
              lineItemAllClear = false;
              item.idnQuantityErrorMessage="Provide valid IDN Quantity and must be lesser than IDN Quantity";
              errorList.push(item.idnQuantityErrorMessage);
            } else {
              item.idnQuantityError = false;
              item.toSend = true;
            }
          
        });
        if (errorList.length === 0) {
          var data2Server = {
            requestId : storeInfo.editIdnId,
            mrsNo: idnParams.mrsNo.value,
            mrsReqDate: idnParams.mrsReqDate.value,
            mrsExpectedDate: idnParams.mrsExpectedDate.value,
            idnDate: idnParams.idnDate.value,
            instruction: instruction,
          };
    
          var itemsToServer = [];
          lineItemsCopy.forEach((item) => {
            if (item.toSend) {
              var newItem = {
                requestLineItemId : item.requestLineItemId,
                itemId: item.itemId,
                uomId: item.uomId,
                mrsQuantity: item.mrsQuantity,
                idnQuantity: parseInt(item.idnQuantity),
              };
              itemsToServer.push(newItem);
            }
          });
    
          if (itemsToServer.length > 0) {
            data2Server.lineItems = itemsToServer;
            submitData(data2Server);
          } else {
            errorList.push("Provide request quantity for atleast one item");
          }
        }
    
        setLineItems(lineItemsCopy);
        setIdnError(errorList);
        setIdnParams(paramsCopy);
      };
    
      const renderErrorMessage = () => {
        if (idnError.length > 0) return idnError[0];
      };
      const [modalIsOpen, setIsOpen] = useState(false);
      const [dataSubmitted, setDataSubmitted] = useState(false);
      const [modalText, setModalText] = useState("Updating IDN. Please wait...");
    
      const submitOkClick = () => {
        setIsOpen(false);
        if (idnError.length === 0) {
          setSection("IdnList");
        }
      };
    
      const submitData = async (data) => {
        setIsOpen(true);
        setModalText("Updating IDN. Please wait...!");
    
        var result = await fetchData({
          requestingPage: "createIdn",
          url: "idn-edit/request",
          method: "put",
          headers: { token: isLogged.accessToken,module:"IDN" },
          data: data,
        });
        if (result.msg === "success") {
          setDataSubmitted(true);
          setModalText("IDN Edited successfully!");
        } else {
          console.log("Error occurred while Updating",result);
        }
      };    

      return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="formArea">
        <div
          style={{
            width: "1000px",
            margin: "0 auto 2rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className="createPurchaseOrderGrid"
              style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
              {renderFormElements({
                elementList: idnParamsList,
                param: idnParams,
                section: "general",
              })}
            </div>
          </div>
          {lineItems.length > 0 ? (
            <table
              className="createItemPurchaseTable"
              style={{ margin: "2rem auto" }}>
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td>Item </td>
                  <td>Item Code</td>
                  {isLogged.showHideWeight ? <>          <td>Weight From(gms)</td>
                  <td>Weight To(gms)</td>
                  <td>Net Weight(gms)</td>
                  <td>Gross Weight(gms)</td> </> : null}
                  <td>UOM</td>
                  <td>MRS Qty</td>
                  <td>IDN Qty</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsParamsList.map((col, i) => {
                      switch (col) {
                        case "idnQuantity":
                          return (
                            <td key={i} style={{ width: "0px" }}>
                              <input
                                className={
                                  lineItems[j].idnQuantityError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="text"
                                value={row[col]}
                                onChange={(e) =>
                                  updateLineItems(
                                    "idnQuantity",
                                    j,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          );
                        case 'itemName':
                          return <td key={i} className='stickyFirstColumn'>{row[col]}</td>
                        default:
                          return <td key={i}>{row[col]  ? row[col] : "-"}</td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          <div className="poNetTotalSurchargeDiv">
            <div className="idnInstruction">
              <div style={{ marginTop: "10px" }}>
                Internal Delivery Note Instruction
              </div>
              <textarea
                className="multilineInput"
                value={instruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  width:"400px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button
            className="submitButton"
            onClick={() => {
              checkErrors();
            }}>
            Submit
          </button>
        </div>
      </div>

        </React.Fragment>
      );      
}
const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdnEdit);