import React, { useEffect, useState } from "react";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import CreateEditModal from "../../SmallComponents/CreateEditModal";
import update from "immutability-helper";
import { useHistory } from "react-router";
import FormElement from "../../SmallComponents/FormElement";
import { connect } from "react-redux";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../../CommonFunctions/ValidateFields";

const EditSalesPrice = ({ setSection, isLogged, itemInfo, addTokenToState }) => {
    const salePriceParams = ["itemMainCategory", "subCategoryId", "brandId", "priceTypeId", "priceApplicableDate"];

    const [ItemSalePrice, setItemSalePrice] = useState({
        itemMainCategory: {
            inputType: "options",
            value: "",
            hintText: "Item Main Category",
            mandatory: true,
            colSpan: 8,
            options: [],
            error: false,
            errorMessage: "Please select item main category",
            disabled: true,
        },
        subCategoryId: {
            inputType: "options",
            value: "",
            hintText: "Item Sub Category",
            mandatory: true,
            colSpan: 8,
            options: [],
            error: false,
            errorMessage: "Select a sub category",
            disabled: true,
        },
        brandId: {
            inputType: "options",
            value: "",
            hintText: "Brand Name",
            mandatory: true,
            colSpan: 8,
            options: [],
            error: false,
            errorMessage: "Please select brand name",
            disabled: true,
        },
        priceTypeId: {
            inputType: "options",
            value: "",
            hintText: "Price Type",
            mandatory: true,
            colSpan: 8,
            options: [],
            error: false,
            errorMessage: "Please select a Price Type",
        },
        priceApplicableDate: {
            inputType: "dateFromEditPage",
            value: null,
            hintText: "Price Applicable Date",
            mandatory: true,
            colSpan: 8,
            error: false,
            errorMessage: "Please pick a date",
            mindate: true,
        },
    });

    const [salePriceObjFromServer, setsalePriceObjFromServer] = useState({});
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading form. Please wait...");

    const [salePriceList, setSalePriceList] = useState([]);
    const [salesList, setSalesList] = useState([]);


    useEffect(() => {
        setIsOpen(true);
        setModalText("Getting Sales Price Information...");
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();

        var salePriceObjServer = await getItemSales();

        var categoryList = await getCategory();

        var subCategoryList = await getSubcategory(salePriceObjServer[0].categoryId);

        var brandList = await getBrandNames(salePriceObjServer[0].subCategoryId);

        var priceTypeList = await getPriceTypes();

        var salePriceParamsCopy = Object.assign(ItemSalePrice);
        salePriceParamsCopy = update(salePriceParamsCopy, {
            itemMainCategory: { options: { $set: categoryList }, value: { $set: salePriceObjServer[0].categoryId } },
            subCategoryId: { options: { $set: subCategoryList }, value: { $set: salePriceObjServer[0].subCategoryId } },
            brandId: { options: { $set: brandList }, value: { $set: salePriceObjServer[0].brandId } },
            priceTypeId: { options: { $set: priceTypeList }, value: { $set: salePriceObjServer[0].priceTypeId } },
            priceApplicableDate: { value: { $set: salePriceObjServer[0].priceApplicableDate } },
        });

        setItemSalePrice(salePriceParamsCopy);
        setsalePriceObjFromServer(salePriceObjServer);
        setIsOpen(false);
        setModalText("Uploading form. Please wait...");
    }

    const history = useHistory();

    async function checkToken() {
        //  console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            //  console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            //  console.log("Token not changed");
        }
        return true;
    }

    async function getItemSales() {
        var data = await fetchData({
            requestingPage: "getSalesPrice",
            method: "post",
            url: "fetch/item-sales-price-pre-edit",
            headers: { token: isLogged.accessToken,module:"Item Sales Price" },
            data: itemInfo.editItemSalesPriceObj,
        });
        if (data.msg === "success") {

            var salePriceInfoList = [];
            data.asset.forEach((item) => {
                var salePrice = {
                    cost: item.cost,
                    taxType: item.taxType,
                    taxPercentage: item.taxPercentage,
                    itemId: item.itemId,
                    itemName: item.itemName,

                    salesPrice: item.price,
                    salesPriceError: false,
                    salesPriceErrorMessage: "Enter a valid Sales price",
                    salesPriceId: item.salesPriceId,
                    priceTypeId: item.priceTypeId,

                    priceApplicableDate: item.priceApplicableDate,
                    toSend: false,
                };
                salePriceInfoList.push(salePrice);
            });


            setSalesList(salePriceInfoList);
            setSalePriceList(data.asset);

            return data.asset;
        }
    }

    async function getCategory() {
        var categoryData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/item-category-list",
            headers: { token: isLogged.accessToken,module:"Item Sales Price" },
        });

        var categoryList = [];

        if (categoryData.msg === "success") {
            categoryData.asset.forEach((categ) => {
                var catObj = {};
                catObj.optionId = categ.categoryId;
                catObj.optionName = categ.categoryName;
                categoryList.push(catObj);
            });
            //  console.log(categoryList);
            return categoryList;
        }
    }

    async function getPriceTypes() {
        var priceTypeData = await fetchData({
            requestingPage: "getCategory",
            method: "get",
            url: "fetch/price-types",
            headers: { token: isLogged.accessToken,module:"Item Sales Price" },
        });

        var priceTypeList = [];

        if (priceTypeData.msg === "success") {
            priceTypeData.asset.forEach((categ) => {
                var priceObj = {};
                priceObj.optionId = categ.priceTypeId;
                priceObj.optionName = categ.priceType;
                priceTypeList.push(priceObj);
            });
            return priceTypeList;
        }
    }

    async function getSubcategory(id) {
        if (id !== "") {
            var subCategoryData = await fetchData({
                requestingPage: "getCategory",
                method: "get",
                url: `fetch/item-subcategory-list/categoryid=${id}`,
                headers: { token: isLogged.accessToken,module:"Item Sales Price" },
            });

            var subCategoryList = [];

            if (subCategoryData.msg === "success") {
                subCategoryData.asset.forEach((subCateg) => {
                    var catObj = {};
                    catObj.optionId = subCateg.subCategoryId;
                    catObj.optionName = subCateg.subCategoryName;
                    subCategoryList.push(catObj);
                });

                return subCategoryList;
            }
        }
    }

    async function getBrandNames(id) {
        if (id !== "") {
            var brandData = await fetchData({
                requestingPage: "getCategory",
                method: "get",
                url: `fetch/item-brand-list/subcategoryid=${id}`,
                headers: { token: isLogged.accessToken,module:"Item Sales Price" },
            });

            var brandList = [];

            if (brandData.msg === "success") {
                brandData.asset.forEach((brand) => {
                    var brandObj = {};
                    brandObj.optionId = brand.brandId;
                    brandObj.optionName = brand.brandName;
                    brandList.push(brandObj);
                });

                return brandList;
            }
        }
    }

    const updateItemParameter = ({ paramName, key, value }) => {
        var itemParams = Object.assign(ItemSalePrice);
        itemParams = update(itemParams, { [paramName]: { [key]: { $set: value } } });

        switch (paramName) {
            case "itemMainCategory":
                itemParams = update(itemParams, {
                    subCategoryId: { value: { $set: "" }, options: { $set: [] } },
                    brandId: { value: { $set: "" }, options: { $set: [] } },
                });
                setSalePriceList([]);
                break;

            case "subCategoryId":
                itemParams = update(itemParams, { brandId: { value: { $set: "" }, options: { $set: [] } } });
                setSalePriceList([]);
                break;

            case "brandId":
                // setSalePriceList;
                break;

            default:
                break;
        }
        setItemSalePrice(itemParams);
    };

    const updateSalePriceItem = (param, index, value) => {
        var updatedList = salesList.slice();

        updatedList = update(updatedList, { [index]: { [param]: { $set: value } } });

        setSalesList(updatedList);
    };

    const submitOkClick = () => {
        setIsOpen(false);
        // if (salePriceError.length === 0) {
        setSection("itemSalesPriceList");
        // }
        setModalText("Uploading form. Please wait...");
    };

    const [salePriceError, setSalePriceError] = useState([]);

    const checkErrors = () => {
        var errorList = [];
        var paramsCopy = Object.assign(ItemSalePrice);
        ["itemMainCategory", "subCategoryId", "brandId", "priceTypeId", "priceApplicableDate"].forEach((field) => {
            if (paramsCopy[field].mandatory) {
                paramsCopy = update(paramsCopy, { [field]: { error: { $set: !validateMandatory(paramsCopy[field].value.toString()) } } });
            }

            if (paramsCopy[field].error) {
                errorList.push(paramsCopy[field].errorMessage);
            }
        });

        var salesListCopy = salesList.slice();

        setItemSalePrice(paramsCopy);

        salesList.forEach((sale, i) => {
            salesListCopy[i].salesPriceError = false;
            salesListCopy[i].toSend = false;

            var proceed = true;

            if (isNaN(sale.salesPrice)) {
                salesListCopy[i].salesPriceError = true;
                proceed = false;
            }

            if (parseFloat(sale.salesPrice) < 0) {
                salesListCopy[i].salesPriceError = true;
                proceed = false;
            }

            if (proceed) {
                if (parseFloat(sale.salesPrice) === "") {
                    //  console.log(salesListCopy[i], "Do Nothing");
                }

                if (parseFloat(sale.salesPrice) >= 0) {
                    // Can send this record to server
                    salesListCopy[i].toSend = true;
                }
            }

            setSalesList(salesListCopy);

            if (sale.salesPriceError) errorList.push(`${sale.salesPriceErrorMessage} ${sale.itemName}`);
        });

        setSalePriceError(errorList);

        if (errorList.length === 0) {
            var shortlistedArray = [];
            salesListCopy.forEach((item, i) => {
                if (item.toSend) {
                    item.priceApplicableDate = ItemSalePrice.priceApplicableDate.value;
                    item.price = item.salesPrice;
                    item.priceTypeId = ItemSalePrice.priceTypeId.value;
                    delete item.salesPriceError;
                    delete item.salesPriceErrorMessage;
                    delete item.itemName;
                    delete item.toSend;
                    delete item.taxType;
                    delete item.taxPercentage;
                    delete item.cost;
                    delete item.salesPrice;
                    shortlistedArray.push(item);
                }
            });

            if (shortlistedArray.length > 0) {
                var arrayToServer = [];

                shortlistedArray.forEach((newSalesObj) => {
                    var compareToObj = salePriceList.filter((compTo) => compTo.salesPriceId === newSalesObj.salesPriceId)[0];

                    if (
                        newSalesObj.priceApplicableDate !== compareToObj.priceApplicableDate ||
                        parseFloat(newSalesObj.price) !== parseFloat(compareToObj.price) ||
                        newSalesObj.priceTypeId !== compareToObj.priceTypeId
                    ) {
                        arrayToServer.push(newSalesObj);
                    } else {
                        console.log("---- Both objects are same");
                    }
                });

                if (arrayToServer.length > 0) {
                    prepareDataToSubmit(arrayToServer);
                } else {
                    setDataSubmitted(true);
                    setIsOpen(true);
                    setModalText("No change has been detected.");
                }
            }
        }
    };

    const prepareDataToSubmit = async (data) => {
        setIsOpen(true);
        setDataSubmitted(false);
        var result = await fetchData({
            requestingPage: "createItemPurchaseCost",
            method: "put",
            url: "edit/item-sales-price",
            headers: { token: isLogged.accessToken,module:"Item Sales Price" },
            data: data,
        });

        if (result.msg === "success") {
            setModalText("Successfully edited Item Sales Price!");
        } else {
            setModalText(`Upload failed: ${result.desc}`);
            setSalePriceError([result.desc]);
        }
        setDataSubmitted(true);
    };

    const renderFormElements = ({ elementList }) => {
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={ItemSalePrice[element].inputType}
                    value={ItemSalePrice[element].value}
                    setInput={(value) => {
                        updateItemParameter({ paramName: element, key: "value", value: value });
                    }}
                    hintText={ItemSalePrice[element].hintText}
                    mandatory={ItemSalePrice[element].mandatory}
                    colSpan={ItemSalePrice[element].colSpan}
                    options={ItemSalePrice[element].inputType === "options" ? ItemSalePrice[element].options : null}
                    error={ItemSalePrice[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? ItemSalePrice[element].rowSpan : null}
                    placeholder={ItemSalePrice[element].placeholder}
                    title={ItemSalePrice[element].title}
                    mindate={true}
                    disabled={ItemSalePrice[element].disabled}
                />
            );
        });
    };

    const renderErrorMessage = () => {
        if (salePriceError.length > 0) {
            return salePriceError[0];
        } else return null;
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="formArea">
                <div
                    style={{
                        width: "1100px",
                        margin: "0 auto 4rem",
                        padding: "3rem",
                        border: "1px solid lightgray",
                        borderRadius: "5px",
                        backgroundColor: "white",
                    }}
                >
                    <div className="createItemSalePriceGrid">{renderFormElements({ elementList: salePriceParams })}</div>

                    <br />
                    {salePriceList.length > 0 ? (
                        <table className="createItemPurchaseTable">
                            <thead>
                                <tr className="createVendorContactsTableHeader">
                                    <td>Item Code</td>
                                    <td>Item Name</td>
                                    <td>Weight From (gms)</td>
                                    <td>Weight To (gms)</td>
                                    <td>Net Weight (gms)</td>
                                    <td>Gross Weight (gms)</td>
                                    <td>MRP</td>
                                    <td>Purchase Cost</td>
                                    <td>Sales Price</td>
                                    <td>Tax Type</td>
                                    <td>Tax Percentage</td>
                                    <td>Net Price</td>
                                </tr>
                            </thead>
                            <tbody>
                                {salePriceList.map((row, j) => (
                                    <tr className="createVendorContactsTableRows" key={j}>
                                        {["itemCode", "itemName","from","to","netWeight","grossWeight", "mrp", "cost", "salesPrice", "taxType", "taxPercentage", "netRate"].map(
                                            (key, i) => {
                                                switch (key) {
                                                    case "salesPrice":
                                                        return (
                                                            <td key={i} style={{ width: "0px" }}>
                                                                <input
                                                                    className={
                                                                        salesList[j].salesPriceError
                                                                            ? "createPurchaseCostInputError"
                                                                            : "createPurchaseCostInput"
                                                                    }
                                                                    type="text"
                                                                    value={salesList[j].salesPrice}
                                                                    onChange={(e) => updateSalePriceItem("salesPrice", j, e.target.value)}
                                                                />
                                                            </td>
                                                        );

                                                    case "netRate":
                                                        return (
                                                            <td key={i}>
                                                                {isNaN(
                                                                    parseFloat(salesList[j].salesPrice) *
                                                                        ((100 + parseFloat(salesList[j].taxPercentage)) / 100)
                                                                )
                                                                    ? "-"
                                                                    : (
                                                                          parseFloat(salesList[j].salesPrice) *
                                                                          ((100 + parseFloat(salesList[j].taxPercentage)) / 100)
                                                                      ).toFixed(2)}
                                                            </td>
                                                        );

                                                    case "cost":
                                                        return (
                                                            <td key={i}>
                                                                {isNaN(
                                                                    parseFloat(salesList[j].cost) *
                                                                        ((100 + parseFloat(salesList[j].taxPercentage)) / 100)
                                                                )
                                                                    ? "-"
                                                                    : (
                                                                          parseFloat(salesList[j].cost) *
                                                                          ((100 + parseFloat(salesList[j].taxPercentage)) / 100)
                                                                      ).toFixed(2)}
                                                            </td>
                                                        );

                                                    default:
                                                        return <td key={i}>{row[key] !== null ? row[key] : "-"}</td>;
                                                }
                                            }
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                textAlign: "center",
                                marginTop: "1rem",
                                // backgroundColor: "rgb(241, 241, 241)",
                                padding: "1rem 0",
                                fontSize: "1.5rem",
                                color: "darkgray",
                                fontStyle: "italic",
                            }}
                        >
                            Please select a value for all the above fields
                        </div>
                    )}
                </div>
            </div>

            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button className="submitButton" onClick={checkErrors}>
                        Submit
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSalesPrice);
