import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { editOutwardId, setOutwardTab, viewOutwardId } from "../../redux/Store/StoreInfoActions";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { exportData } from "../CommonFunctions/ExportExcel";
import FormElement from "../SmallComponents/FormElement";
import downIcon from "../../assets/down.svg";
import upIcon from "../../assets/up.svg";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import viewIcon from "../../assets/view.svg";
import downloadIcon from "../../assets/download.svg";
import approveIcon from "../../assets/approve2.svg";
import SortUpGreen from "../../assets/SortUpGreen.svg";
import SortDownGreen from "../../assets/SortDownGreen.svg";
import { handleSort } from "../CommonFunctions/utils";
import Pagination from "../CommonFunctions/pagination";

const OutwardList = ({
    // state
    isLogged,
    storeInfo,
    setEditAndApprove,

    // dispatch
    addTokenToState,
    setOutwardTab,
    viewOutwardId,
    editOutwardId,

    // props
    setTab,
    setSection,
    setApproval,
}) => {
    const [noUnapprovedRecords, setNoUnapprovedRecords] = useState(false);
    const [noApprovedRecords, setNoApprovedRecords] = useState(false);
    const [plantId,setPlantId] = useState('');
    const [outwardList, setOutwardList] = useState([]);
    const [outwardApprovedList, setOutwardApprovedList] = useState([]);
    const [currentPage,setCurrentPage]=useState(1);
    const [PageSize,setRowsPerPage]=useState(10);
    const outwardCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return outwardList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,outwardList,PageSize]);
      const outwardApprovedCurrentPage  = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return outwardApprovedList.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,outwardApprovedList,PageSize]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        await checkToken();
        await getOutwardUnapproved();
    };

    const history = useHistory();
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getOutwardUnapproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "store-fetch/outward-unapproved-list",
            headers: { token: isLogged.accessToken, module: "Outward" },
            data: {plantId}
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setOutwardList(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    async function getOutwardApproved() {
        var data = await fetchData({
            requestingPage: "itemList",
            method: "post",
            url: "store-fetch/outward-approved-list",
            headers: { token: isLogged.accessToken,module:"Outward" },
            data:{plantId}
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setOutwardApprovedList(data2);
            if (data.asset.length > 0) setNoUnapprovedRecords(false);
            else setNoUnapprovedRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    }

    const tablist = [
        { id: "outwardList", name: "Outward List" },
        { id: "approvedOutwardList", name: "Approved Outward List" },
    ];
    const access = isLogged.access["Outward"];
    const setSelectedTab = (tab) => {
        setOutwardTab(tab);
    };

    useEffect(async () => {
        setLoading(true);
        await checkToken();

        switch (storeInfo.outwardTab) {
            case "outwardList":
                setOutwardList([]);
                getOutwardUnapproved();
                break;

            case "approvedOutwardList":
                setOutwardApprovedList([]);
                getOutwardApproved();
                break;

            default:
                break;
        }
    }, [storeInfo.outwardTab,plantId]);

    const editItemCollapse = (list, index) => {
        var listCopy = [];

        switch (list) {
            case "outwardList":
                listCopy = outwardList.slice();
                break;

            case "outwardApprovedList":
                listCopy = outwardApprovedList.slice();
                break;
        }

        listCopy.forEach((item, j) => {
            if (j === index) {
                listCopy[j].collapse = !item.collapse;
            } else {
                listCopy[j].collapse = true;
            }
        });

        switch (list) {
            case "outwardList":
                setOutwardList(listCopy);
                break;

            case "outwardApprovedList":
                setOutwardApprovedList(listCopy);
                break;
        }
    };

    const RenderTabList = ({ tablist }) => {
        return tablist.map((tab) => {
            return (
                <div
                    key={tab.id}
                    className={storeInfo.outwardTab === tab.id ? "createVendorIndividualTabSelected" : "createVendorIndividualTab"}
                    onClick={() => {
                        setSelectedTab(tab.id);
                        switch (tab.id) {
                            case "outwardList":
                                setTab("outwardList");
                                return;

                            case "approvedOutwardList":
                                setTab("approvedOutwardList");
                                return;

                            default:
                                setTab("");
                        }
                    }}
                >
                    {tab.name}
                </div>
            );
        });
    };

    const RenderSubTable = ({ tab, data, reason }) => {
        return (
            <td colSpan={tab==='approved' ? 8 : 7 } className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader">
                            <td className="vendorListInnerTableHeading stickyFirstColumn">Item</td>
                            {isLogged.showhideWeight ? <>
                            <td className="vendorListInnerTableHeading">Weight From(gms)</td>
                            <td className="vendorListInnerTableHeading">Weight To(gms)</td>
                            <td className="vendorListInnerTableHeading">Net Weight(gms)</td>
                            <td className="vendorListInnerTableHeading">Gross Weight(gms)</td>
                            </> : ''
                            }
                            <td className="vendorListInnerTableHeading">HSN No</td>
                            <td className="vendorListInnerTableHeading">UOM</td>
                            <td className="vendorListInnerTableHeading">Quantity</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.itemId}>
                                    <td className="vendorListInnertableInfo stickyFirstColumn">{row.itemName}</td>
                                    {isLogged.showhideWeight ?
                                    <><td className="vendorListInnertableInfo">{row.from ? row.from : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.to ? row.to : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.netWeight ? row.netWeight : " - "}</td>
                                    <td className="vendorListInnertableInfo">{row.grossWeight ? row.grossWeight : " - "}</td> </>: ''}
                                    <td className="vendorListInnertableInfo">{row.hsnCode ? row.hsnCode : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.uom}</td>
                                    <td className="vendorListInnertableInfo">{row.quantity}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const renderOutwardUnapprovedList = () => {
        return outwardCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.opdId}
                        onClick={() => editItemCollapse("outwardList", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.outwardNo}</td>
                        <td className="vendorListInfo">{item.outwardDate}</td>
                        <td className="vendorListInfo">{item.opdNo}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={approveIcon}
                                title={access === "App/Dec" ? "Approve Record" :"Access Denied"}
                                style={access==="App/Dec"? { cursor: "pointer" }:{cursor:"not-allowed"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (item.soStatus !== "Draft" && access === "App/Dec") {
                                        viewOutwardId(item.orderType + "/" + item.outwardId);
                                        setSection("outwardDetail");
                                        setApproval(true);
                                    }
                                }}
                            />
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Record"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewOutwardId(item.orderType + "/" + item.outwardId);
                                    setSection("outwardDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" ||access==="App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" ||access==="App/Dec"? "Edit Item" : "No Edit Access"}
                                onClick={(e) => {e.stopPropagation();
                                    if (access === "Edit" || access==="App/Dec") {
                                        editOutwardId(item.orderType + "/" + item.outwardId);
                                        setSection("editOutward");
                                        setEditAndApprove(false);
                                    }
                                }}
                                style={access === "Edit" || access === "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("outwardList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="unapproved" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };

    const renderOutwardApprovedList = () => {
        return outwardApprovedCurrentPage.map((item, i) => {
            return (
                <React.Fragment>
                    <tr
                        className={item.collapse ? "vendorList" : "vendorListExpanded"}
                        key={item.opdId}
                        onClick={() => editItemCollapse("outwardApprovedList", i)}
                    >
                        <td className="vendorListInfo stickyFirstColumn">{item.outwardNo}</td>
                        <td className="vendorListInfo">{item.outwardDate}</td>
                        <td className="vendorListInfo">{item.opdNo}</td>
                        <td className="vendorListInfo">{item.orderType}</td>
                        <td className="vendorListInfo">{item.createdBy}</td>
                        <td className="vendorListInfo">{item.approvedBy}</td>

                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Outward Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewOutwardId(item.orderType + "/" + item.outwardId);
                                    setSection("outwardDetail");
                                    setApproval(false);
                                }}
                            />
                            <img
                                alt="Export Record"
                                className="vendorActionImages"
                                src={downloadIcon}
                                title="Export Outward Detail"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    exportData(isLogged, "outwardApprovedSingleItem", { url: String(item.outwardId), method: "POST" });
                                }}
                            />
                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={item.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editItemCollapse("outwardApprovedList", i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!item.collapse ? <RenderSubTable tab="approved" data={item.lineItems} /> : null}</tr>
                </React.Fragment>
            );
        });
    };
    const [order, setOrder] = useState({ col: "outwardNo", state: true, data: "" });
    
    const CommonColumns = ({ data, tab }) => {

        return (
            <tr className="vendorListHeader" style={{ zIndex: "1" }}>
                <td className="vendorListHeading stickyFirstColumn" onClick={(e) => {
                    setOrder({
                        col: "outwardNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "outwardNo",
                        state: order.state,
                    });
                }}
                >Outward No<img
                        className="sortArrow"
                        src={
                            order.col === "outwardNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "outwardDate",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "outwardDate",
                        state: order.state,
                    });
                }}
                >Outward Date<img
                        className="sortArrow"
                        src={
                            order.col === "outwardDate"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "opdNo",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "opdNo",
                        state: order.state,
                    });
                }}
                >OPD No<img
                        className="sortArrow"
                        src={
                            order.col === "opdNo"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "orderType",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "orderType",
                        state: order.state,
                    });
                }}
                >Order Type <img
                        className="sortArrow"
                        src={
                            order.col === "orderType"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "createdBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "createdBy",
                        state: order.state,
                    });
                }}
                >Created By<img
                        className="sortArrow"
                        src={
                            order.col === "createdBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td>
                {tab === 'approved' ? <td className="vendorListHeading" onClick={(e) => {
                    setOrder({
                        col: "approvedBy",
                        state: !order.state,
                    });
                    handleSort({
                        data,
                        col: "approvedBy",
                        state: order.state,
                    });
                }}
                >Approved By<img
                        className="sortArrow"
                        src={
                            order.col === "approvedBy"
                                ? order.state
                                    ? SortUpGreen : SortDownGreen
                                : null}
                    /></td> : ''}
                <td className="vendorListHeadingActions">Actions</td>
            </tr>
        )
    };

    const renderSubSection = (tab) => {
        switch (tab) {
            case "outwardList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                               <CommonColumns data={outwardCurrentPage} tab={'unapproved'}/>
                            </thead>
                            <tbody>{outwardList.length > 0 ? renderOutwardUnapprovedList() : null}</tbody>
                        </table>
                        {noUnapprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={outwardList.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );

            case "approvedOutwardList":
                return (
                    <React.Fragment>
                        <table className="vendorListTable">
                            <thead>
                               <CommonColumns tab={'approved'} data={outwardApprovedCurrentPage}/>
                            </thead>
                            <tbody>{outwardApprovedList.length > 0 ? renderOutwardApprovedList() : null}</tbody>
                        </table>
                        {noApprovedRecords ? (
                            <div className="noRecordsYet">No records found</div>
                        ) : loading ? (
                            <div className="loader" style={{ margin: "1rem auto" }}></div>
                        ) : null}
                        <Pagination 
                         className="pagination-bar"
                         currentPage={currentPage}
                         totalCount={outwardApprovedList.length}
                         pageSize={PageSize}
                         onPageChange={page => setCurrentPage(page)}
                        />
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div style={{ display: "inline-flex", marginLeft: "25px" }} className="listPageParamsGrid">
                <span className="listPageParams">
                    <FormElement setInput={(value) => { setPlantId(value); }}
                        inputType={"options"}
                        value={plantId}
                        hintText={"Plant Name"}
                        options={isLogged.plantOptions}
                        colSpan={1}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false} />
                </span>
                <span style={{ flexGrow: 0, width: "80px" }}>
                    <FormElement
                        setInput={(value) => {
                            setRowsPerPage(value);
                        }}
                        inputType={"number"}
                        value={PageSize}
                        hintText={"Rows Per Page"}
                        colSpan={0.5}
                        rowSpan={1}
                        mandatory={false}
                        error={false}
                        errorMessage=""
                        disabled={false}
                    />
                </span>
            </div>
            <div className="vendorListArea">{renderSubSection(storeInfo.outwardTab)}</div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),

        setOutwardTab: (tab) => dispatch(setOutwardTab(tab)),
        viewOutwardId: (opdId) => dispatch(viewOutwardId(opdId)),
        editOutwardId: (opdId) => dispatch(editOutwardId(opdId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutwardList);
