import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "redux/UserAccount/IsLoggedActions";
import fetchData from "serverCall/fetchData";
import FetchNewToken from "serverCall/FetchNewToken";
import viewIcon from "assets/view.svg";
import editIcon from "assets/edit.svg";
import editIconDisabled from "assets/edit_disabled.svg";
import downIcon from "assets/down.svg";
import upIcon from "assets/up.svg";
import { editPoImportId, viewPoImportId } from "redux/PurchaseOrder/PoInfoActions";
import FormElement from "components/SmallComponents/FormElement";
import approveIcon from "assets/approve2.svg";


const PurchaseImportList = ({ setSection, isLogged, addTokenToState, viewPoImportId, editPoImportId,setApprovalPage }) => {
    const [purchaseImportList, setPurchaseImportList] = useState([]);

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getPurchaseImport();
    }

    const history = useHistory();
    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);
    const [plantId,setPlantId] = useState([]);
    const [purchaseImportTab,setPurchaseImportTab] = useState("unapproved");
    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    const getPurchaseImport = useCallback(async()=> {
        var data = await fetchData({
            requestingPage: "vendorList",
            method: "get",
            url: "po-fetch/purchase-import-list3/"+purchaseImportTab,
            headers: { token: isLogged.accessToken,module: "Purchase Import" },
        });
        if (data.msg === "success") {
            var data2 = [];

            data.asset.forEach((element, i) => {
                var newElement = Object.assign(element);
                newElement.collapse = true;
                data2.push(newElement);
            });
            setPurchaseImportList(data2);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            console.log(data);
        }
        setLoading(false);
    },[isLogged,purchaseImportTab]);

    const editImportCollapse = (index) => {

        var importListCopy = purchaseImportList.slice();
        importListCopy.forEach((vendor, j) => {
            if (j === index) {
                importListCopy[j].collapse = !vendor.collapse;
            } else {
                importListCopy[j].collapse = true;
            }
        });
        setPurchaseImportList(importListCopy);
    };
    useEffect(()=>{
        getPurchaseImport();
    },[purchaseImportTab,getPurchaseImport]);
    const access = useMemo(()=>isLogged.access?.["Purchase Import"],[isLogged.access])
    const renderPurchaseImportList = () => {
        return purchaseImportList.map((purchaseImport, i) => {
            if( (plantId.length> 0 && plantId.some((obj)=>obj.value===purchaseImport.plantId) ) || plantId.length===0)
            return (
                <React.Fragment key={i}>
                    <tr
                        className={purchaseImport.collapse ? "vendorList" : "vendorListExpanded"}
                        key={purchaseImport.vendorId}
                        onClick={() => editImportCollapse(i)}
                    >
                        <td className="vendorListInfo">{purchaseImport.poNo}</td>
                        <td className="vendorListInfo">{purchaseImport.poDate}</td>
                        <td className="vendorListInfo">{purchaseImport.noOfInvoice}</td>
                        <td className="vendorListInfo">{purchaseImport.sellerName}</td>
                        <td className="vendorListInfo">{purchaseImport.shipmentDate}</td>
                        <td className="vendorListInfo">{purchaseImport.createdBy}</td>
                        <td className="vendorListInfoActions">
                            <img
                                alt="View Record"
                                className="vendorActionImages"
                                src={viewIcon}
                                title="View Import Detail"
                                onClick={(e) => {
                                    e.stopPropagation();

                                    viewPoImportId(purchaseImport.importId);
                                    setSection("purchaseImportDetail");
                                }}
                            />
                            {purchaseImportTab ==='unapproved' ?
                            <>
                            <img
                                alt="Approve Record"
                                className="vendorActionImages"
                                src={approveIcon}
                                title={
                                    access === "App/Dec"
                                    ? "Approve Purchase Import"
                                    : "Access Denied"
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (access === "App/Dec") {
                                    setSection("purchaseImportDetail");
                                    setApprovalPage(true);
                                    viewPoImportId(purchaseImport.importId);
                                    }
                                }}
                                style={
                                    access === "App/Dec"
                                    ? { cursor: "pointer" }
                                    : { cursor: "not-allowed" }
                                }
                                />
                            <img
                                alt="Edit Record"
                                className="vendorActionImages"
                                src={access === "Edit" || access=== "App/Dec" ? editIcon : editIconDisabled}
                                title={access === "Edit" || access=== "App/Dec" ? "Edit Purchase Import" : "No Edit Access"}
                                onClick={(e) => {
                                    if (access === "Edit") {
                                        e.stopPropagation();
                                        editPoImportId(purchaseImport.importId);
                                        setSection("editPurchaseImport");
                                    }
                                }}
                                style={access === "Edit" || access=== "App/Dec" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                            /> </>: null}

                            <img
                                alt="Toggle Expand"
                                className="vendorActionImages"
                                title="Toggle Expand"
                                src={purchaseImport.collapse ? downIcon : upIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editImportCollapse(i);
                                }}
                            />
                        </td>
                    </tr>

                    <tr>{!purchaseImport.collapse ? <RenderSubTable data={purchaseImport.lineItems} /> : null}</tr>
                </React.Fragment>
            ) 
            else return null;
        });
    };

    const RenderSubTable = ({ data }) => {
        return (
            <td colSpan="9" className="vendorListInnerTableWrapper">
                <table className="vendorListInnerTable">
                    <thead>
                        <tr className="vendorListInnerTableHeader" style={{ whiteSpace: "normal" }}>
                            <td className="vendorListInnerTableHeading">Invoice Number</td>
                            <td className="vendorListInnerTableHeading">Invoice Date</td>
                            <td className="vendorListInnerTableHeading">Balance Payment Dollar</td>
                            <td className="vendorListInnerTableHeading">Balance Payment Rupee</td>
                            <td className="vendorListInnerTableHeading">Latest Balance Payment Date</td>
                            <td className="vendorListInnerTableHeading">Port Arrival Date</td>
                            <td className="vendorListInnerTableHeading">Total Container</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (
                                <tr className="vendorListInnerTable" key={row.purchaseId}>
                                    <td className="vendorListInnertableInfo">{row.invoiceNo !== null ? row.invoiceNo : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.invoiceDate !== null ? row.invoiceDate : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.balancePaymentDollar !== null ? row.balancePaymentDollar : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.balancePaymentRupee !== null ? row.balancePaymentRupee : "-"}</td>
                                    <td className="vendorListInnertableInfo">
                                        {row.latestBalancePaymentDate !== null ? row.latestBalancePaymentDate : "-"}
                                    </td>
                                    <td className="vendorListInnertableInfo">{row.portArrivalDate !== null ? row.portArrivalDate : "-"}</td>
                                    <td className="vendorListInnertableInfo">{row.totalContainer !== null ? row.totalContainer : "-"}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </td>
        );
    };

    const tablist = [
        { id: "unapproved", name: "Purchase Import List" },
        {id: "approved",name: "Approved Purchase Import List",},
      ];
    
      const RenderTabList = ({ tablist }) => {
          return tablist.map((tab) => {
            console.log(purchaseImportTab,tab.id);
          return (
            <div
              key={tab.id}
              className={
                purchaseImportTab === tab.id
                  ? "createVendorIndividualTabSelected"
                  : "createVendorIndividualTab"
              }
              onClick={() => setPurchaseImportTab(tab.id)}>
              {tab.name}
            </div>
          );
        });
      };

    return (
        <React.Fragment>
            <div className="poListTabs">
                <RenderTabList tablist={tablist} />
            </div>
            <div  style={{width:"250px",marginLeft:"25px",marginBottom:"10px"}}>
                <FormElement  setInput={(value)=>{setPlantId(value);}}
                                        inputType={"multiSelect"}
                                        value={plantId}
                                        hintText={"Plant Name"}
                                        options={isLogged.plantOptions}
                                        colSpan={1}
                                        rowSpan={1}
                                        mandatory={false}
                                        error={false}
                                        errorMessage=""
                                        disabled={false}/>
            </div>
        <div className="vendorListArea" style={{marginTop:0}}>
            <table className="vendorListTable">
                <thead>
                    <tr className="vendorListHeader">
                        <td className="vendorListHeading">Contract No</td>
                        <td className="vendorListHeading">Contract Date</td>
                        <td className="vendorListHeading">No Of Invoice</td>
                        <td className="vendorListHeading">Seller Name</td>
                        <td className="vendorListHeading">Shipment Date</td>
                        <td className="vendorListHeading">Created By</td>
                        <td className="vendorListHeadingActions">Actions</td>
                    </tr>
                </thead>
                <tbody>{purchaseImportList.length > 0 ? renderPurchaseImportList() : null}</tbody>
            </table>
            {noRecords ? (
                <div className="noRecordsYet">No records found</div>
            ) : loading ? (
                <div className="loader" style={{ margin: "1rem auto" }}></div>
            ) : null}
        </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        viewPoImportId: (poImportId) => dispatch(viewPoImportId(poImportId)),
        editPoImportId: (poImportId) => dispatch(editPoImportId(poImportId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseImportList);
