import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../../serverCall/fetchData";
import FetchNewToken from "../../../serverCall/FetchNewToken";
import CreateEditModal from "../../SmallComponents/CreateEditModal";

const CreateItemPriceType = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    // editUom,
    addTokenToState,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getItemPriceType();
    }

    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
           
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

    async function getItemPriceType() {
        var data = await fetchData({
            requestingPage: "itemPriceTypeList",
            method: "get",
            url: "fetch/price-types",
            headers: { token: isLogged.accessToken,module:"Item Price Type" },
        });
        if (data.msg === "success") {
            setItemPriceTypeList(data.asset);
        } else {
            console.log(data);
        }
    }

    const [itemPriceTypeList, setItemPriceTypeList] = useState([]);

    const [newPriceType, setNewPriceType] = useState({
        priceType: "",
        description: "",
    });

    const renderPriceTypeList = (list) => {
        return list.map((row) => {
            return (
                <tr className="uomListData" key={row.priceTypeId}>
                    <td className="uomSymbolData">{row.priceType}</td>
                    <td className="uomSymbolData">{row.description}</td>
                </tr>
            );
        });
    };

    const renderCreatePriceTypeInput = () => {
        return (
            <tr className="uomListData">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newPriceType.priceType}
                        onChange={(e) => setNewPriceType({ ...newPriceType, priceType: e.target.value })}
                        autoFocus
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                </td>
                <td className="uomSymbolData3">
                    <input
                        className="createUom2"
                        value={newPriceType.description}
                        onChange={(e) => setNewPriceType({ ...newPriceType, description: e.target.value })}
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                    <button
                        className="cancelRoleButton"
                        onClick={() => {
                            setNewPriceType({ priceType: "", description: "" });
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if (newPriceType.priceType.trim() !== "" && newPriceType.description.trim() !== "") {
                                createItemPrice();
                                setIsOpen(true);
                            } else {
                                window.alert("Make sure PriceType and Description data is filled");
                            }
                        }}
                    >
                        Add
                    </button>
                </td>
            </tr>
        );
    };

    async function createItemPrice() {
        setDataSubmitted(false);

        var dataSent = await fetchData({
            requestingPage: "createItemPrice",
            method: "post",
            url: "create/price-type",
            headers: { token: isLogged.accessToken,module:"Item Price Type" },
            data: newPriceType,
        });
        if (dataSent.msg === "success") {
            setModalText("Successfully created Item Price Type!");
            setNewPriceType({ priceType: "", description: "" });
            getInformation();
        } else {
           
            setModalText(`Upload failed: ${dataSent.desc}`);
        }
        setDataSubmitted(true);
    }

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Uploading data. Please wait...");
    };

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Uploading data. Please wait...");

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="uomListArea">
                <table className="uomListTable">
                    <tr className="uomListHeader">
                        <td className="uomSymbolHeader">Price Type</td>
                        <td className="uomSymbolHeader">Description</td>
                    </tr>
                    {renderCreatePriceTypeInput()}
                    {renderPriceTypeList(itemPriceTypeList)}
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateItemPriceType);
