import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import { editTransporterId, editUomAction } from "../../redux/Item/ItemInfoActions";
import CreateEditModal from "../SmallComponents/CreateEditModal";

const EditTransporter = ({
    // props
    setSection,

    // state
    itemInfo,
    isLogged,

    // dispatch
    addTokenToState,
    editTranspId,
}) => {
    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getTransporters();
    }

    const history = useHistory();

    async function checkToken() {
       
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getTransporters() {
        var data = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/transporter-list",
            headers: { token: isLogged.accessToken, module: "Transporter"},
        });
        if (data.msg === "success") {
            setTransporterList(data.asset);
            setNewTransporter(data.asset.filter((i) => i.transporterId === itemInfo.editTransporterId)[0]);
        } else {
            // console.log(data);
        }
    }

    const [transporterList, setTransporterList] = useState([]);

    const [newTransporter, setNewTransporter] = useState({ transporterName: "", description: "" });


    const renderTransporterList = (list) => {
        return list.map((row) => {
            return row.transporterId !== itemInfo.editTransporterId ? (
                <tr className="uomListData" key={row.transporterId}>
                    <td className="uomSymbolData">{row.transporterName}</td>
                    <td className="uomSymbolData">{row.description}</td>
                    <td className="uomActionData">
                        <img
                            className="uomEditImage"
                            src={editIcon}
                            alt="Edit Record"
                            onClick={() => {
                                editTranspId(row.transporterId);
                                setNewTransporter(transporterList.filter((i) => i.transporterId === row.transporterId)[0]);
                            }}
                        />
                    </td>
                </tr>
            ) : (
                renderCreateUomInput()
            );
        });
    };

    const renderCreateUomInput = () => {
        return (
            <tr className="uomListData" key="CreateUom">
                <td className="uomSymbolData2">
                    <input
                        className="createUom"
                        value={newTransporter?.transporterName}
                        onChange={(e) => setNewTransporter({ ...newTransporter, transporterName: e.target.value })}
                        autoFocus
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                </td>
                <td className="uomSymbolData3">
                    <input
                        className="createUom2"
                        value={newTransporter.description}
                        onChange={(e) => setNewTransporter({ ...newTransporter, description: e.target.value })}
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.key === "Enter" || e.keyCode === 13) {
                                var submit = document.querySelector("#SubmitCreate");
                                submit.click();
                            }
                        }}
                    />
                    <button
                        className="cancelRoleButton"
                        onClick={() => {
                            editTranspId(null);
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        id="SubmitCreate"
                        className="createRoleButton"
                        onClick={() => {
                            if (newTransporter.transporterName.trim() !== "" && newTransporter.description.trim() !== "") {
                                editTransporterData();
                                setIsOpen(true);
                            } else {
                                window.alert("Make sure Transporter Name and Description is provided");
                            }
                        }}
                    >
                        Save
                    </button>
                </td>
                <td className="uomActionData">&nbsp;</td>
            </tr>
        );
    };

    async function editTransporterData() {
        var newTransporterCopy = Object.assign(newTransporter);
        newTransporterCopy.transporterId = newTransporterCopy.transporterId;

        var dataSent = await fetchData({
            requestingPage: "editUom",
            method: "put",
            url: "edit/transporter",
            headers: { token: isLogged.accessToken,module: "Transporter" },
            data: newTransporterCopy,
        });


        setDataSubmitted(true);
        if (dataSent.msg === "success") {
            editTranspId(null);
            setModalText("Saved transporter changes successfully!");
            setNewTransporter({ transporterName: "", description: "" });
            getInformation();
        } else {
            setModalText(dataSent.desc);
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Updating transporter. Please wait...");

    const submitOkClick = () => {
        setIsOpen(false);
        setModalText("Updating transporter. Please wait...");
    };

    return (
        <React.Fragment>
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />

            <div className="uomListArea">
                <table className="uomListTable">
                    <tr className="uomListHeader">
                        <td className="uomSymbolHeader">Symbol</td>
                        <td className="uomSymbolHeader">Description</td>
                        <td className="uomActionHeader">Action</td>
                    </tr>

                    {renderTransporterList(transporterList)}
                </table>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        itemInfo: state.itemInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editTranspId: (uomId) => dispatch(editTransporterId(uomId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTransporter);
