import React, { useState, useEffect } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import dashboard from "../../assets/dashboard.svg";
import "../styles/Dashboard.css";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { useHistory } from "react-router-dom";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import {exportToCSV} from "../CommonFunctions/ExportExcel";
import {
  dateFormatting,
  addDaysToDate,
  getIndianCurrency,
} from "../CommonFunctions/utils";

const ProductionDashboard = ({ isLogged, addTokenToState }) => {
  const history = useHistory();
  const [barData, setBarData] = useState([]);
  const [cardData1, setCardData1] = useState([]);
  const [itemTrendList,setItemTrendList]=useState([]);
  const [trendList,setTrendList]= useState({ivTrendList:[],returnTrendList:[]})
  const [queryParams, setQueryParams] = useState({
    showChartBy: {
      inputType: "options",
      value: "MM-YY",
      mandatory: true,
      options: [
        { optionId: "DD-MM-YY", optionName: "Show By Days" },
        { optionId: "MM-YY", optionName: "Show By Months" },
        { optionId: "YYYY", optionName: "Show By Years" },
      ],
      error: false,
      hintText: "Show Chart By",
      isClearable: false,
    },
    fromDate: {
      inputType: "dateFromEditPage",
      value: "04-01-" + new Date().getFullYear(),
      hintText: "From Date",
      mandatory: true,
      error: false,
      mindate: true,
      maxdate: true,
    },
    toDate: {
      inputType: "dateFromEditPage",
      value: new Date(),
      hintText: "To Date",
      mandatory: true,
      error: false,
      maxdate: true,
    },
    plantId: {
      inputType: "options",
      value: "",
      options: isLogged.plantOptions,
      hintText: "Plant Name",
      mandatory: false,
      error: false,
    },
    storageId: {
      inputType: "options",
      value: "",
      hintText: "Store",
      mandatory: false,
      colSpan: 6,
      error: false,
      options: [],
      errorMessage: "",
    },
    orderType: {
      inputType: "options",
      value: "Make To Order",
      options: [{optionId:"Make To Order",optionName:"Make To Order"},
                {optionId:"Make To Stock",optionName:"Make To Stock"}],
      hintText: "Order Type",
      mandatory: false,
      error: false,
    },
    items:{
      inputType: "multiSelect",
      value: [],
      options: [],
      hintText: "Items",
      mandatory: false,
      error: false,
    },
  });


  var IReportButton = {buttonName: "Export" ,className: "exportButton", 
  setSection: async()=>{await exportToCSV(trendList.ivTrendList,`Purchase Invoice Items Report-  ${new Date().toDateString()}`)}    
  , sectionName: "SalesInvoiceDashboard"}

  useEffect(() => {
    getInformation();
  }, []);

  const [storageLocList, setStorageLocList] = useState({});

  async function getPlants() {
    var plantsData = await fetchData({
      requestingPage: "plantList",
      method: "get",
      url: "fetch/plants",
      headers: { token: isLogged.accessToken, module: "Purchase Order" },
    });

    var plantList = [];
    var storageLocList = {};

    if (plantsData.msg === "success") {
      plantsData.asset?.forEach((plant) => {
        var plantObj = {};
        plantObj.optionId = plant.plant.plantId;
        plantObj.optionName = plant.plant.plantName;
        plantObj.address =
          plant.plant.plantName +
          ", " +
          plant.plant.city +
          ", " +
          plant.plant.state +
          (plant.plant.zipCode !== null
            ? ` - ${plant.plant.zipCode}, `
            : ", ") +
          plant.plant.country;
        plantList.push(plantObj);

        storageLocList[plant.plant.plantId] = [];
        plant.storage.forEach((store) => {
          var storeObj = {};
          storeObj.optionId = store.storageId;
          storeObj.optionName = store.storageName;
          storeObj.address =
            store.address +
            ", " +
            plant.plant.city +
            ", " +
            plant.plant.state +
            (plant.plant.zipCode !== null
              ? ` - ${plant.plant.zipCode}, `
              : ", ") +
            plant.plant.country;
          storeObj.gstNo = plant.plant.gstNo;
          storageLocList[plant.plant.plantId].push(storeObj);
        });
      });

    }
    return [plantList, storageLocList];
  }

  async function getInformation() {
    await checkToken();
    let [itemList] = await getItems();
    var [plantList,storageLocListFormatted] = await getPlants();
    
    let queryParamsCopy = Object.assign(queryParams);
    queryParamsCopy = update(queryParamsCopy, {
      items: { options: { $set: itemList } },
      orderType :{value :{$set: "Make To Order"}}
    });
    setQueryParams(queryParamsCopy);
    setStorageLocList(storageLocListFormatted);
  }

  useEffect(() => {

    var queryParamsCopy = Object.assign(queryParams);

    if (queryParams.plantId.value !== "") {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: {
          options: { $set: storageLocList[queryParams.plantId.value]||[] },
        },
      });
      setQueryParams(queryParamsCopy);
    } else {
      queryParamsCopy = update(queryParamsCopy, {
        storageId: { options: { $set: [] }, value: { $set: "" } },
      });
      setQueryParams(queryParamsCopy);
    }
  }, [queryParams.plantId.value]);

  async function getItems() {
    let itemData = await fetchData({
      requestingPage: "itemList",
      method: "post",
      url: "fetch/items",
      data:{itemsTab:"Active"},
      headers: { token: isLogged.accessToken, module: "Purchase Order" },
    });

    let itemList = [];
    if (itemData.msg === "success") {

      itemData.asset.forEach((item) => {
        let itemObj = {};
        itemObj.optionId = item.item.itemId;
        itemObj.optionName =
          "  " + item.item.itemCode + " - " + item.item.itemName;
        itemObj.itemCode = item.item.itemCode;
        itemList.push(itemObj);
      });

    }
    return [itemList];
  }

  async function checkToken() {
    console.log("Checking token");
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
      console.log("New Token");
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }

  async function getSoData(qryParams) {
    let data1 = await fetchData({
      requestingPage: "dashboard",
      method: "post",
      url: "production-fetch/production-order-dashboard",
      headers: { token: isLogged.accessToken, module: "Production Dashboard" },
      data: qryParams,
    });
    if (data1.msg === "success") {
      setBarData(data1.asset.barData);
      setCardData1([
        {
          type: "numbers",
          title: "Production Order Count",
          value: getIndianCurrency(data1.asset.totalApprovedCount, false),
        },
        {
          type: "numbers",
          title: "Total Production Quantity",
          value: data1.asset.totalQty,
        },
      ]);
      setItemTrendList(data1.asset.itemTrendList);
     
    } else {
      console.log(data1);
    }
  }

  
  useEffect(() => {
    let saleCopy = Object.assign(queryParams);
    let fromDate = dateFormatting(saleCopy.fromDate.value),
      // converting date and adding 1 date for between operator in backend
      toDate = dateFormatting(addDaysToDate(saleCopy.toDate.value, 1));

    let qryParams = {
      dates: [fromDate, toDate],
      plantId: saleCopy.plantId.value,
      // customerId: saleCopy.customerId.value,
      showChartBy: saleCopy.showChartBy.value,
      itemIds: saleCopy.items.value.map((it)=>it?.value), // since it is multi select extract only values,
      storageId:saleCopy.storageId.value,
      orderType:saleCopy.orderType.value || "Make To Order"
    };
    if (fromDate.length === 10 && toDate.length === 10) {
      getSoData(qryParams);
    }
  }, [queryParams]);
  
  const renderItemTrendList = (itemTrendList) => {
    return (
      <div
        className="dashboardCard itemTrendList">
        <div className="dashboardCardHeader">
          <span className="dashboardCardTitle">Items Production Count</span>
        </div>
        <div className="dashboardAlertBody">
          {itemTrendList.length > 0 ? (
            itemTrendList.map((stock, ind) => {
                return (
                  <div className="alertStockLine" key={ind}>
                    <span style={{ flex: "1 0" }}>{stock.itemName}</span>
                    {/* <span style={{paddingRight: "1em"}}>{Number(stock.rate).toLocaleString('en-IN',{maximumFractionDigits:2,currency:"INR",style:"currency"})}</span> */}
                    <span>{stock.purchaseCount}</span>
                  </div>
                );
            })
          ) : (
            <div className="noRecordsYet">No Item Has been Sold</div>
          )}
        </div>
      </div>
    );
};

  const renderCards = (data) => {
    return data?.map((item,ind) => {
      return (
        <div
          className="dashboardCard"
          key={item.title}
          style={{ height: "120px", width: "250px" }}>
          <div className="dashboardCardHeader">
            <span className="dashboardCardTitle">{item.title}</span>
          </div>
          <div className="dashboardCardBody">
            <div className="dashSingle">
              <div>{item.value}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  const updateSaleOrderParameters = ({ param, paramName, key, value }) => {
    let paramsCopy = Object.assign(param);
    paramsCopy = update(paramsCopy, {
      [paramName]: { [key]: { $set: value } },
    });
    setQueryParams(paramsCopy);
  };

  const renderFormElements = ({ elementList, params,styles={} }) => {
    return (
      <div className="dashboardCard dashboardParams" style={styles}>
        {elementList.map((item,ind) => {
          return (
            <div key={ind}>
              <FormElement
                key={item}
                inputType={params[item].inputType}
                value={params[item].value}
                setInput={(value) => {
                  updateSaleOrderParameters({
                    param: params,
                    paramName: item,
                    key: "value",
                    value: value,
                  });
                }}
                hintText={params[item].hintText}
                mandatory={params[item].mandatory}
                colSpan={params[item].colSpan}
                options={
                  params[item].inputType === "options" ||
                  params[item].inputType === "multiSelect"
                    ? params[item].options
                    : null
                }
                error={params[item].error}
                isClearable={params[item].isClearable}
                maxdate={params[item].maxdate}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "rgb(11,87,180)", //rgb(87 118 232) //    color:rgb(88, 80, 245)
          strokeWidth: 2,
        },
      },
    },
  };
  const renderBarGraph = (data, indexBy, key, title) => {
    return (
      <div
        className="dashboardCard"
        style={{
          height: "390px",
          width: "32.8rem",
          gridColumn: `1/span 1`,
          alignSelf: 'start',
          gridRow: `auto / span 2`,
          padding: "2rem",
        }}>
        <div className="dashboardGraphBody">
          <ResponsiveBar
            data={data}
            keys={key}
            indexBy={indexBy}
            margin={{ top: 10, right: 0, bottom: 50, left: 65 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            tooltip={({ value, indexValue }) => {
              return (
                <div className="dashBoardToolTip">
                  {indexValue}
                  <br />
                  {value}
                  <br />
                </div>
              );
            }}
            theme={theme}
            colors={["rgb(11,87,180)"]}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 1,
              tickPadding: 5,
              tickRotation: -90,
              legend: "",
              legendPosition: "middle",
              legendOffset: 52,
            }}
            axisLeft={{
              tickSize: 1,
              tickPadding: 4,
              tickRotation: -1,
              legend: "Production Count",
              legendPosition: "middle",
              legendOffset: -60,
              format: (value) => `   ${Number(value) / 100000} L`,
            }}
            labelSkipWidth={12}
            labelSkipHeight={0}
            label={(d) => null}
            layers={["axes", "bars", "markers", "legends"]}
          />
        </div>
      </div>
    );
  };

  const renderSubSection = () => {
    // switch (selectedTab) {
    //   case "Purchase Order":
        return (
          <>
            <div className="dashboardGrid">
            {renderFormElements({
              elementList: ['showChartBy','fromDate','toDate'], 
              params: queryParams,
            })}
            {renderFormElements({
              elementList: ['orderType','items','plantId','storageId'], 

              params: queryParams,
              styles: {gridRow: '2/2'}
            })}
              <div className="cardsContainer"
              style={{gridTemplateRows: "auto",
                gridGap: "11px"
            }}
              >{renderCards(cardData1)}</div>
              {renderItemTrendList(itemTrendList)}
              {renderBarGraph(
                barData,
                "poCreateDate",
                ["totalQty"],
                "Purchase Order"
              )}
              {/* {renderPieChart(
                pieChartData1,
                { datum: "data.color" },
                "Outward Status",
                "1"
              )}
              {renderPieChart(
                pieChartData2,
                { datum: "data.color" },
                "Invoice Status",
                "2"
              )} */}
            </div>
          </>
        );
  };
  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Production" selectedSubMenu="Production Dashboard" />
        <div className="detailsContainer">
          <TopBanner />
          <PageTitle imgSrc={dashboard} pageTitle="Production Dashboard"/>
          <span
            className="hintText"
            style={{ color: "GrayText", fontSize: "13px",marginLeft:"40px",marginBottom:"0px" }}>
            *Dashboard data is mainly based on Approved Orders
          </span>
          {/* <div className="poListTabs">
            <RenderTabList />
          </div> */}
          {renderSubSection()}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isLogged: state.isLogged };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductionDashboard);
