
import React, { Fragment, useEffect, useState } from "react";
import { simpleDateTime } from "../CommonFunctions/utils";
import { connect } from "react-redux";
import fetchData from "../../serverCall/fetchData";

const EmployeeEfficiencyDetail = ({isLogged,poInfo}) => {
    const [processLogDetail, setProcessDetail] = useState()
    const [selectedTab, setSelectedTab] = useState("Process 1");
    const [processLogLineItems, setProcessLogLineItems] = useState({})
    const [workOrderLineDetail, setWorkOrderLineDetail] = useState({});
    const [processSteps, setProcessSteps] = useState([])

    const renderSummaryInfo = () => {

        return (
            <Fragment>
                <span className="vendorSummaryRole"
                >
                    { processLogDetail?.processLogDetail.employeeNo}
                </span>
                <div className="vendorSummaryGrid">
                    <span className="vendorSummaryCategory">Employee Name</span>
                    <span className="vendorSummaryValue">: { processLogDetail?.processLogDetail.employeeName}</span>

                    <span className="vendorSummaryCategory">Work Order</span>
                    <span className="vendorSummaryValue">: { processLogDetail?.processLogDetail.workOrderNo}</span>

                    <span className="vendorSummaryCategory">Item Name</span>
                    <span className="vendorSummaryValue">: { processLogDetail?.processLogDetail.itemName}</span>
                    <span className="vendorSummaryCategory">Efficiency Rate</span>
                    <span className="vendorSummaryValue">: { processLogDetail?.processLogDetail.efficiencyRate}</span>
                </div>
            </Fragment>
        )
    }
//getting detail of processlog
 async function getProcessLogDetail(){
        let response = await fetchData({
          method: 'post',
          url: 'production-fetch/process-log-detail/'+poInfo.viewProcessLogId,
          headers: {module: "Employee Efficiency Report",token: isLogged.accessToken},
          requestingPage: "workOrder",
        })
        if(response.msg==='success'){
          return response.asset;
        }else{
          console.log(response)
        }
      }

      const fetchingRequest =async()=>{
        const response = await getProcessLogDetail()
        setProcessDetail(response)
        renderFunction(response)
      }

      useEffect(()=>{
        fetchingRequest()
      },[])

    const renderFunction = (processLogDetail) => {
        let processLogLineCopy = {};
        let workOrderLineDetail = {};
        let allProcessTotalHrs = 0;
        let processLogDetailCopy = Object.assign(processLogDetail);
        //calculations per process and assigning line items per process
        processLogDetail.processLogLine.forEach((value, index) => {
            processLogLineCopy[value.process] = value.processLogLine.sort((a, b) => new Date(a["sessionStartTime"]) - new Date(b["sessionStartTime"]))
            workOrderLineDetail[value.process] = value.workOrderLine;
            let totalPerProcess = 0;

            //calculate total hours from sessions per process
            workOrderLineDetail[value.process]["totalHrsPerProcess"] = value.processLogLine.reduce((acc,sesLine)=>{
                acc+=sesLine.duration;
                 totalPerProcess = acc;
                return acc;
            },0);

            // add current process hours to total process of process log
            allProcessTotalHrs += totalPerProcess;

            //efficiency rate per process
            workOrderLineDetail[value.process]["efficiencyRatePerProcess"] = ((8/totalPerProcess)*100 ).toFixed(2);
        });

        
        let itemProcess = processLogDetail.processLogLine.reduce((acc, value) => ([...acc, value.process]), [])
        
        //average working hours by combining all process working hours  and it's count
        let meanOfAllProcessHrs = allProcessTotalHrs/ itemProcess.length;

        //total efficiency of employee for this process log
        let totalEfficiency = ((8/meanOfAllProcessHrs)*100).toFixed(2);
        
        //setting efficiency rate of a employee
        processLogDetailCopy.processLogDetail["efficiencyRate"] = totalEfficiency;

        setProcessDetail(processLogDetailCopy)
        setSelectedTab(itemProcess[0]);
        setProcessSteps(itemProcess)
        setProcessLogLineItems(processLogLineCopy);
        setWorkOrderLineDetail(workOrderLineDetail);
        
    }



    const RenderTable = () => {
        return (
            <table className="createVendorContactsTable">
                <thead>
                    <tr className="createVendorContactsTableHeader">
                        <td>Session Start Time</td>
                        <td>Session End Time</td>
                        <td>Session Quantity</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        processLogLineItems[selectedTab]?.map((row, index) => (
                            <tr className="createVendorContactsTableRows" key={index}>
                                {['sessionStartTime', 'sessionEndTime', 'sessionQuantity']
                                    .map((col) => (<td key={col}>
                                        {col.includes('Time') ? simpleDateTime(row[col]) : row[col]}
                                    </td>))}
                            </tr>))}
                </tbody>
            </table>
        );
    };


    const renderTabs = () => {
        return (
            <React.Fragment>
                <div className="createPurchaseImportTabArea">
                    <div className="createVendorTabs" style={{ borderBottom: "1px solid rgb(240,240,240)" }}>
                        {
                            processSteps.map((tab, index) => (
                                <div key={index} className={selectedTab === tab ? "createPurchaseImportTabSelected" : "createPurchaseImportTab"}
                                    style={{ lineHeight: "15px", fontWeight: "500" }}
                                    onClick={() => { setSelectedTab(tab);
                                    }}>
                                    {tab.toUpperCase()}
                                </div>
                            ))
                        }
                    </div>
                    <div style={{ fontSize: "2px" }}>&nbsp;</div>
                    <div className="vendorSummary">
                        <div className="vendorSummaryText">

                        <div className="vendorSummaryGrid" style={{ padding: "15px 0px 15px 0px" }}>
                        <span className="vendorSummaryCategory">Machine No</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.machineNo}</span>

                        <span className="vendorSummaryCategory">Picked Quantity</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.pickedQuantity}</span>

                        <span className="vendorSummaryCategory">Passed Quantity</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.passedQuantity}</span>

                        <span className="vendorSummaryCategory">Rejected Quantity</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.rejectedQuantity}</span>

                        <span className="vendorSummaryCategory">Produced Quantity</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.producedQuantity}</span>

                        <span className="vendorSummaryCategory">Repaired Quantity</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.repairQuantity}</span>
                        </div>
                        </div>
                        <div className="vendorSummaryText">
                        <div className="vendorSummaryGrid" style={{ padding: "15px 0px 15px 0px"}}>
                        <span className="vendorSummaryCategory">Total Hours Per Process</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.totalHrsPerProcess.toFixed(2)}</span>

                        <span className="vendorSummaryCategory">Efficiency Rate Per Process</span>
                        <span className="vendorSummaryValue">: {workOrderLineDetail[selectedTab]?.efficiencyRatePerProcess}</span>
                        </div>
                        </div>
                    </div>

                    <div className="purchaseOrderSubsectionArea">
                        <RenderTable />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                    </div>
                    {renderTabs()}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  return {
      isLogged: state.isLogged,
      poInfo:state.poInfo
    };
  };

export default connect(mapStateToProps)(EmployeeEfficiencyDetail);