import React, { useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";

import TopBanner from "../SmallComponents/TopBanner";
import SalesInvoiceList from "./SalesInvoiceList";
import salesInvoiceIcon from "../../assets/invoice.svg";
import CreateInvoice from "./CreateInvoice";
import InvoiceDetail from "./InvoiceDetail";
import EditInvoice from "./EditInvoice";
import { connect } from "react-redux";
import { exportPDF } from "../CommonFunctions/ExportPDF";
import UploadDocument from "./UploadInvoiceDocument"


const SalesInvoice = ({ isLogged }) => {
    const [currentSection, setCurrentSection] = useState("salesInvoiceList");

    const [approvalPage, setApprovalPage] = useState(false);

    const [editAndApprove, setEditAndApprove] = useState(false);

    const [loadSo, setLoadSo] = useState(false);
    const [downloadReportParams,setDownloadReportParams]=useState({});
    var goBackButton = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "salesInvoiceList" };
    var goBackBtn = { buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "invoiceDetail" };
    var UploadButton = { buttonName: "Upload Document", className: "employeeButton", setSection: setCurrentSection, sectionName: "salesInvoiceUpload" };
    var salesInvoice = { buttonName: "Create Invoice", className: "employeeButton", setSection: setCurrentSection, sectionName: "createInvoice", callback: () => setLoadSo(false) }
    var downloadButton = { buttonName: "Download", className: "exportButton", setSection: async () => { await exportPDF(isLogged, "salesInvoice",downloadReportParams) }, sectionName: "salesInvoiceList" };

    const renderSection = (section) => {
        switch (section) {
            case "createInvoice":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salesInvoiceIcon} pageTitle="Create Invoice" buttonList={[goBackButton]} />
                        <CreateInvoice setSection={(value) => setCurrentSection(value)} loadSo={loadSo} />
                    </React.Fragment>
                );

            case "salesInvoiceList":
                return (
                    <React.Fragment>
                        <PageTitle imgSrc={salesInvoiceIcon} pageTitle={"Invoice List"}
                            buttonList={isLogged.access["Sales Invoice"] === "Create" || isLogged.access["Sales Invoice"] === "Edit" || isLogged.access["Sales Invoice"] === "App/Dec" ?
                                [salesInvoice] : null} />
                        <SalesInvoiceList
                            setSection={(value) => setCurrentSection(value)}
                            setApproval={setApprovalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setLoadSo={setLoadSo}
                        />
                    </React.Fragment>
                );

            case "invoiceDetail":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesInvoiceIcon}
                            pageTitle={approvalPage ? "Approve Invoice" : "Invoice Detail"}
                            buttonList={[UploadButton,salesInvoice,goBackButton, downloadButton]}
                        />
                        <InvoiceDetail
                            setSection={(value) => setCurrentSection(value)}
                            approval={approvalPage}
                            setEditAndApprove={(value) => setEditAndApprove(value)}
                            setDownloadReportParams={(params)=>setDownloadReportParams(params)}
                        />
                    </React.Fragment>
                );

            case "editSalesInvoice":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesInvoiceIcon}
                            pageTitle={editAndApprove ? "Edit & Approve Invoice" : "Edit Invoice"}
                            buttonList={[goBackButton]}
                        />
                        <EditInvoice setSection={(value) => setCurrentSection(value)} editApprove={editAndApprove} />
                    </React.Fragment>
                );
            case "salesInvoiceUpload":
                return (
                    <React.Fragment>
                        <PageTitle
                            imgSrc={salesInvoiceIcon}
                            pageTitle={"Upload Document"}
                            buttonList={[goBackBtn]}
                        />
                        <UploadDocument setSection={(value) => setCurrentSection(value)}/>
                    </React.Fragment>
                );

            default:
                return <div>Page yet to be built</div>;
        }
    };

    return (
        <React.Fragment>
            <div className="completePage">
                <MenuList selectedMenu="Sales" selectedSubMenu="Sales Invoice" />
                <div className="detailsContainer">
                    <TopBanner />
                    {renderSection(currentSection)}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return { isLogged: state.isLogged };
};
export default connect(mapStateToProps, null)(SalesInvoice);

