import React, { Fragment, useState } from "react";
// import SalesDealsList from "../SalesDeal/SalesOpportunityList";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import SalesQuotesParams from "./CreateSalesQuote";
import SalesQuoteList from "./SalesQuoteList";
import SalesQuoteDetail from "./SalesQuoteDetail";
import EditSalesQuoteParams from "./EditSalesQuotes"
import salesQuoteIcon from "../../assets/sales-quote.svg";

const SalesQuotes = () => {

  const [currentSection, setCurrentSection] = useState("salesQuoteList");

  var goBackButton = (props) =>
  ({
    buttonName: "Back",
    className: "goBackButton",
    ...props
  });

  var CreateButton = { buttonName: "Create Quote", className: "employeeButton", setSection: setCurrentSection, sectionName: "CreateSalesQuote" }

  const renderSection = (section) => {
    switch (section) {
      case "salesQuoteList":
        return (
          <Fragment>
            <PageTitle
              imgSrc={salesQuoteIcon}
              pageTitle={"Sales Quote List"}
              buttonList={[CreateButton]}
            />
            <SalesQuoteList setCurrentSection={setCurrentSection} />
          </Fragment>
        );
      case "CreateSalesQuote":
        return (
          <Fragment>
            <PageTitle
              imgSrc={salesQuoteIcon}
              pageTitle={"Create Sales Quote"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesQuoteList" })]}
            />
            <SalesQuotesParams setCurrentSection={setCurrentSection} />
          </Fragment>
        );
      case "salesQuoteDetail":
        return (
          <Fragment>
            <PageTitle
              imgSrc={salesQuoteIcon}
              pageTitle={"Sales Quote Detail"}
              buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesQuoteList" })]}
            />
            <SalesQuoteDetail setCurrentSection={setCurrentSection} />
          </Fragment>
        );

      default: return (
        <Fragment>
          <PageTitle
            imgSrc={salesQuoteIcon}
            pageTitle={"Edit Sales Quote"}
            buttonList={[goBackButton({ setSection: setCurrentSection, sectionName: "salesQuoteList" })]}
          />
          <EditSalesQuoteParams setCurrentSection={setCurrentSection} />
        </Fragment>
      )
    }
  }
  return (
    <React.Fragment>
      <div className="completePage">
        <MenuList selectedMenu="Sales Quotes(CRM)" selectedSubMenu="Sales Quote" />
        <div className="detailsContainer">
          <TopBanner />
          {renderSection(currentSection)}
        </div>
      </div>
    </React.Fragment>
  )
}


export default SalesQuotes;