import React,{useState,useEffect} from "react";
import {connect} from 'react-redux';
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { simpleDateTime } from "../CommonFunctions/utils";
import editIcon from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import { editBOMId } from "../../redux/PurchaseOrder/PoInfoActions";

const CreateProcessLog=({isLogged,addTokenToState,setSection,editBOMId})=>{

    const history=useHistory();
    const [selectedTab,setSelectedTab]=useState("Process 1");
    const [processLogInputs,setProcessLogInputs]=useState({
      workOrderId: {
        inputType: "options",
        hintText: "Work Order",
        colSpan: 4,
        error: false,
        errorMessage: "Please Select a Work Order Option",
        options:[],
        value: "",
        bomId: "",
        mandatory: true,
        processSteps: ["Process 1"]
      },
      machineNo: {
        inputType: "text",
        hintText: "Machine No.",
        colSpan: 4,
        error: false,
        errorMessage: "",
        value: "",
        mandatory: false,
        disabled: true
      },
      itemName:{
        inputType: "text",
        hintText: "Item Name",
        colSpan: 4,
        error: false,
        mandatory: false,
        value: "",
        disabled: true,
      },
      pickedQuantity: {
        inputType: "float",
        hintText: "Assigned Quantity",
        colSpan: 4,
        error: false,
        errorMessage: "",
        value: "",
        mandatory: true,
        disabled: true
      },
      producedQuantity: {
        inputType: "float",
        hintText: "Produced Quantity",
        colSpan: 4,
        error: false,
        errorMessage: "",
        value: "",
        mandatory: true,
        disabled: true
      },
      passedQuantity: {
        inputType: "float",
        hintText: "Passed Quantity",
        colSpan: 4,
        error: false,
        errorMessage: "",
        value: "",
        mandatory: true,
        disabled: true
      },
      repairQuantity: {
        inputType: "float",
        hintText: "Repair Quantity",
        colSpan: 4,
        error: false,
        errorMessage: "",
        value: "",
        mandatory: true,
        disabled: true
      },
      rejectedQuantity: {
        inputType: "float",
        hintText: "Rejected Quantity",
        colSpan: 4,
        error: false,
        errorMessage: "",
        value: "",
        mandatory: true,
        disabled: true
      },
    });
    const [processLogError,setProcessLogError] = useState([]);
    const [editIndex,setEditIndex]=useState(null);
    const [processLogLineParams,setProcessLogLineParams]=useState({
      sessionStartTime:{
        inputType: 'dateTimePicker',
        value: "",
        hintText: "Session StartTime",
        error: false,
        errorMessage: "Please Select a start session",
        colSpan: 6,
        mandatory: true
      },
      sessionEndTime:{
        inputType: 'dateTimePicker',
        value: new Date(),
        hintText: "Session EndTime",
        error: false,
        errorMessage: "Please Select a End session",
        colSpan: 6,
        mandatory: true,
      },
      sessionQuantity: {
        inputType: 'float',
        value: "",
        hintText: "Session Quantity",
        colSpan: 4,
        error: false,
        mandatory: true,
        errorMessage: "Please Update a Valid Quantity"
      }
    })
    const [processLogLineItems,setProcessLogLineItems]=useState({});
    const [workOrderLineDetail,setWorkOrderLineDetail]=useState({});
    async function checkToken() {
        console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
          history.push("/");
        } else if (token2 !== isLogged.accessToken) {
          console.log("New Token");
          addTokenToState(isLogged.employeeId, token2);
        } else {
          console.log("Token not changed");
        }
      }
    
      async function getInformation(){
        await checkToken();
        await getProcessLogPreCreate()
      }

      useEffect(()=>getInformation(),[])
      useEffect(()=>{
        let processLogInputsCopy = Object.assign(processLogInputs);
        let currentProducedQuantity = processLogLineItems[selectedTab]?.reduce((prAcc,process)=>{
          return prAcc+process.sessionQuantity;
        },0)

        currentProducedQuantity =currentProducedQuantity - (processLogInputsCopy['repairQuantity']['value']+ processLogInputsCopy['rejectedQuantity']['value'])
        processLogInputsCopy = update(processLogInputsCopy,{['producedQuantity']: {['value']: {$set: currentProducedQuantity} } })
        setProcessLogInputs(processLogInputsCopy)
      },[processLogLineItems[selectedTab]?.length])
      async function getProcessLogPreCreate(){
        let response = await fetchData({
          method: 'post',
          url: 'production-create/process-log-pre-create',
          headers: {module: "Process Log",token: isLogged.accessToken},
          requestingPage: "workOrder",
        })
        if(response.msg==='success'){
          let processLogInputsCopy = Object.assign(processLogInputs);
          processLogInputsCopy= update(processLogInputsCopy,{workOrderId: {options:{$set: response.asset} } })
          setProcessLogInputs(processLogInputsCopy)
        }else{
          console.log(response)
        }
      }

      async function getWorkOrderDetail(id){
        let detailResponse = await fetchData({
           method: "POST",
           url: 'production-fetch/work-order-detail/'+id,
           requestingPage: "workOrder",
           headers: {token: isLogged.accessToken, module: "Work Order"}
        });

        if(detailResponse.msg=='success'){
           return detailResponse.asset;
        }else{
           console.log(detailResponse);
           return {};
        }
     }

      const updateProcessLogInputs=({section,paramName,key,value,wholeObject={}})=>{
        if(section==='general'){
          let processLogInputsCopy = Object.assign(processLogInputs);
          processLogInputsCopy = update(processLogInputsCopy,{[paramName]: {[key]:{$set: value} } });
          if( paramName=='workOrderId'){
            setWorkOrderLineDetail(wholeObject)
            wholeObject.fgProcess = Object.keys(wholeObject.lineItem)
            let firstProcessDetails = wholeObject.lineItem[wholeObject.fgProcess?.[0]]?.[0];
            processLogInputsCopy = update(processLogInputsCopy,{
                                        ['workOrderId']: {['bomId']:{$set: wholeObject.bomId || "" }, ['processSteps']: {$set: ['Process 1']}},
                                        ['itemName']: {['value']: {$set: wholeObject.itemName || ""} },
                                        ['pickedQuantity']: {['value']: {$set: firstProcessDetails.pickedQuantity || 0} } ,
                                        ['producedQuantity']: {['value']: {$set: firstProcessDetails.producedQuantity  || 0} } ,
                                        ['passedQuantity']: {['value']: {$set: firstProcessDetails.passedQuantity  || 0} } ,
                                        ['repairQuantity']: {['value']: {$set: firstProcessDetails.repairQuantity  || 0} } ,
                                        ['rejectedQuantity']: {['value']: {$set: firstProcessDetails.rejectedQuantity  || 0} } ,
                                        ['machineNo']: {['value']: {$set: firstProcessDetails.machineNo || ""}}
                                      })

          if(wholeObject.lineItem && Object.keys(wholeObject.lineItem)?.length){
            let fgProcess = Object.keys(wholeObject.lineItem)
            processLogInputsCopy = update(processLogInputsCopy,{['workOrderId']:{['processSteps']:{$set: fgProcess } } })
            setSelectedTab(fgProcess[0] )
            fgProcess = fgProcess?.reduce((acc,item)=>({...acc,[item]: []}),{}) || ["Process 1"]
            setProcessLogLineItems(fgProcess)
          }else{
            setProcessLogLineItems({"Process 1": []})
          }
          }

          setProcessLogInputs(processLogInputsCopy);
        }else {
          let processLogLineParamsCopy = Object.assign(processLogLineParams);
          processLogLineParamsCopy  = update(processLogLineParamsCopy,{[paramName]: {[key]: {$set: value} } })
          setProcessLogLineParams(processLogLineParamsCopy)
        }
      }

      const renderFormElements = ({ elementList, param, section }) => {
        return elementList.map((element) => {
          return (
            <FormElement
              key={element}
              inputType={param[element].inputType}
              value={param[element].value !== null ? param[element].value : ""}
              setInput={(value,wholeObject) => {
                updateProcessLogInputs({
                  section,
                  paramName: element,
                  key: "value",
                  value: value,
                  wholeObject
                });
              }}
              hintText={param[element].hintText}
              mandatory={param[element].mandatory}
              colSpan={param[element].colSpan}
              options={
                param[element].inputType === "options"
                  ? param[element].options
                  : null
              }
              error={param[element].error}
              rowSpan={
                element === "vendorLogo" || element === "otherBusinessDetails"
                  ? param[element].rowSpan
                  : null
              }
              placeholder={param[element].placeholder}
              title={param[element].title}
              disabled={param[element].disabled}
              mindate={param[element].mindate}
            />
          );
        });
      };

      
      const checkLineItemError=()=>{
        let errorList=[];
        let lineParamsCopy = Object.assign(processLogLineParams);
        let processLogLine = Object.assign(processLogLineItems);
        Object.keys(lineParamsCopy).forEach((item)=>{
          if(lineParamsCopy[item].mandatory)
          lineParamsCopy = update(lineParamsCopy,{[item]: 
            {error: {$set: !validateMandatory(lineParamsCopy[item].value.toString())} } 
          });
          if(lineParamsCopy[item].error)
          errorList.push(lineParamsCopy[item].errorMessage);
        })
        if(new Date(lineParamsCopy['sessionStartTime'].value) >= new Date(lineParamsCopy['sessionEndTime'].value))
        errorList.push(['Session Start Time Should be Lesser Than End Time']);

        if(processLogLine[selectedTab]?.at(-1)){
          if(new Date(processLogLine[selectedTab]?.at(-1)['sessionEndTime']) > new Date(lineParamsCopy['sessionStartTime'].value))
              errorList.push(['Session Timings Should be only in Increasing Order'])
        }
        if(workOrderLineDetail.fgProcess[0]!==selectedTab){
          let previousIndex = workOrderLineDetail.fgProcess.findIndex((elem)=>elem===selectedTab)-1;
            let previousLineItems = processLogLine[workOrderLineDetail.fgProcess[previousIndex]].slice();
            let previousCount = previousLineItems.reduce((acc,line)=>acc+line.sessionQuantity,0);
            let currentCount = processLogLine[selectedTab]?.slice() || [];
            let newLine = {}
          Object.keys(lineParamsCopy).forEach((item)=>{
            newLine[item] = lineParamsCopy[item].value;
          })
          currentCount.push(newLine);
          currentCount = currentCount.reduce((acc,currline)=>acc+currline.sessionQuantity,0);

          if(currentCount > previousCount){
            errorList.push('Current Process Quantity Exceeded Previous Process Quantity')
          }
        }

        if(errorList.length==0){
          let processLogLineCopy = processLogLine[selectedTab]?.slice();
          let newLine = {}
          Object.keys(lineParamsCopy).forEach((item)=>{
            newLine[item] = lineParamsCopy[item].value;
            lineParamsCopy[item].value=""
          })
          if(!editIndex)
          {processLogLineCopy.push(newLine);}
          else{
            processLogLineCopy.splice(editIndex,0,newLine)
            setEditIndex(-1);
          }
          processLogLine[selectedTab] = processLogLineCopy 
          setProcessLogLineItems(processLogLine);
        }
        setProcessLogError(errorList)
        setProcessLogLineParams(lineParamsCopy)
      }
      const editSubSectionItem=(index,action)=>{
        let processLogLine  = Object.assign(processLogLineItems);
         let   processLogLineCopy = processLogLine[selectedTab].slice();
        if(action=='delete'){
          processLogLineCopy.splice(index,1);
        }else if(action =='edit'){
          let editObj = processLogLineCopy.splice(index,1)[0]
          let processLogLineParamsCopy=Object.assign(processLogLineParams)
          Object.keys(processLogLineParams).forEach((param)=>{
            processLogLineParamsCopy = update(processLogLineParamsCopy,{[param]: {['value']: {$set: editObj[param]} } })
          })
          setProcessLogLineParams(processLogLineParamsCopy)
        }
        processLogLine = update(processLogLine, { [selectedTab] :{$set:  processLogLineCopy} } );
        setProcessLogLineItems(processLogLine)
      }
const RenderTable=()=>{

  return(
    <React.Fragment>
      <table className="createVendorContactsTable">
        <thead>
          <tr className="createVendorContactsTableHeader">
            <td>Session Start Time</td>
            <td>Session End Time</td>
            <td>Session Quantity</td>
            <td className="itemIndiTabActions">Actions</td>
          </tr>
        </thead>
        <tbody>
          {processLogLineItems[selectedTab]?.length > 0 ?
          processLogLineItems[selectedTab]?.map((row,index)=>(
            <tr className="createVendorContactsTableRows" key={index}>
              {Object.keys((processLogLineParams))
                                  .map((col)=>(<td key={col}>
                                                  {col.includes('Time') ? simpleDateTime(row[col]) : row[col]}
                                              </td>))}
              <td className="itemIndiTabActions">
                           <img
                           alt="Edit Record"
                           className="createVendorContactsAction"
                           src={editIcon}
                           onClick={() => {
                            setEditIndex(index)
                            editSubSectionItem(index, "edit");
                          }}
                           />
                           <img
                           alt="Delete Record"
                           className="createVendorContactsAction"
                           src={deleteIcon}
                           onClick={() => {
                              var reset = window.confirm("Delete record? The Time log needs to be manually entered in order");
                              if (reset) {
                                editSubSectionItem(index, "delete");
                              }
                           }}
                           />
                     </td>
            </tr>))
            :
            <tr className="createVendorContactsTableRows">{Object.keys((processLogLineParams))
                                .map((col)=>(<td key={col}>&nbsp;</td>))}
                                <td>&nbsp;</td>
            </tr>
          }
        </tbody>
      </table>
    </React.Fragment>
  )
}
const renderSubSection=()=>{
  
  return(<React.Fragment>
                  <div className="purchaseOrderIndividualItemsArea">
                  {renderFormElements({
                    elementList: Object.keys(processLogLineParams),
                    param: processLogLineParams,
                    section: "lineItem",
                  })}
                  <FormElement
                    inputType="addButton"
                    value="+ Add"
                    colSpan={4}
                    setInput={() => {
                      setProcessLogError([])
                      checkLineItemError();
                    }}
                  />
                  </div>
                  <RenderTable />
              </React.Fragment>)
      }
      const renderErrorMessage=()=>{
        if(processLogError.length > 0)
        return processLogError[0]
     }
  const checkErrorsToSubmitProcessLog=()=>{
        let errorList=[];
        let processLogInputsCopy = Object.assign(processLogInputs);
          for(let key in processLogInputsCopy){
            if(processLogInputsCopy[key].mandatory){
              processLogInputsCopy = update(processLogInputsCopy,{[key]: {['error']: 
                {   $set: !validateMandatory(processLogInputsCopy[key].value.toString())}
                  }
                })
              }
              if(processLogInputsCopy[key]['error'])
              errorList.push(processLogInputsCopy[key]['errorMessage'])
            }
          
          let currentProducedQuantity = processLogInputsCopy['producedQuantity'].value
          if(currentProducedQuantity > processLogInputsCopy['pickedQuantity'].value){
            errorList.push(['Produced Quantity cannot Be Greater Than assigned Quantity'])
            processLogInputsCopy = update(processLogInputsCopy,{['pickedQuantity']: {['error']: 
            { $set: currentProducedQuantity > processLogInputsCopy['pickedQuantity'].value}
          },
          ['producedQuantity']:  {['error']: 
          {   $set: currentProducedQuantity > processLogInputsCopy['pickedQuantity'].value}
        }
      })
          }
          if(Object.keys(processLogLineParams).some((param)=>processLogLineParams[param]['value']!=''))
          errorList.push(['Unsaved Time Log Found'])

          let lineLength = Object.entries(processLogLineItems).reduce((acc,[process,processLine])=>acc+processLine.length,0)
          if(!lineLength){
            errorList.push(['Enter Atleast One Session Time Log'])
            }
    
          if(errorList.length==0){
            let dataToServer = {workOrderLineDetail}

            // for(let param in processLogInputsCopy){
            //   dataToServer[param]= processLogInputsCopy[param].value
              
            // } 
            dataToServer.lineItems = Object.assign(processLogLineItems);
            submitData(dataToServer);
          }
      setProcessLogInputs(processLogInputsCopy);
      setProcessLogError(errorList);
    }
    const submitData=async(data)=>{
      setIsOpen(true);
    setModalText("Creating Process Order. Please wait...!");

      let response = await fetchData({
         method: 'post',
         requestingPage: 'workOrder',
         headers: {token: isLogged.accessToken, module: "Process Log"},
         data,
         url: 'production-create/create-process-log',
      });

      setDataSubmitted(true);
      if(response.msg==='success'){
        setModalText('Process Log SuccessFully Created')
      } else if(response.msg==='failure'){
         setModalText(response.desc);
         setProcessLogError([response.desc])
         console.log(response)
      } else{
         setModalText("Please Try Again Later")
         setProcessLogError([response.desc])
         console.log(response);
         console.log(data)
      }
    }
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState(
      "Creating Process Log. Please wait..."
    );
    
    const submitOkClick = () => {
      setIsOpen(false);
      if (processLogError.length === 0) {
        setSection("processLogList");
      }
    };
    const changeTab=({tab})=>{
      let processLogInputsCopy = Object.assign(processLogInputs);

      ['machineNo','pickedQuantity',
      'producedQuantity','passedQuantity',
      'repairQuantity','rejectedQuantity'].forEach((item)=>{
        processLogInputsCopy = update(processLogInputsCopy,{[item]: {"value" : {$set: workOrderLineDetail.lineItem?.[tab]?.[0]?.[item] || 0 } } });
      })
      processLogInputsCopy = update(processLogInputsCopy, {machineNo: {value: {$set: processLogInputsCopy.machineNo.value || ""}}})
      setProcessLogInputs(processLogInputsCopy)

      setSelectedTab(tab)
    }
    const renderTabs=()=>{
      const {processSteps} = processLogInputs.workOrderId
      console.log(processSteps)
      if(processLogInputs.workOrderId.value > 0){
        return (<React.Fragment>
                <div className="createPurchaseImportTabArea">
                  <div className="createVendorTabs"  style={{ borderBottom: "1px solid rgb(240,240,240)" }}>
                  { 
                    processSteps.map((tab,index)=>(
                      <div key={Math.random()} className={selectedTab === tab ? "createPurchaseImportTabSelected" : "createPurchaseImportTab"}
                        style={{lineHeight: "15px",fontWeight:"500"}}
                          onClick={()=>{if( processLogLineItems[processSteps[index-1]]?.length || index==0) changeTab({tab})}}>
                          {tab.toUpperCase()}
                      </div>
                    ))
                  }
                  </div>
                <div style={{fontSize:"2px"}}>&nbsp;</div>
                <div className="purchaseOrderSubsectionArea">
                {renderSubSection()}
                </div>
                </div>
                </React.Fragment>
            )
      }else{
        return null
      }
    }
    return(
        <React.Fragment>
          <CreateEditModal
            modalIsOpen={modalIsOpen}
            modalText={modalText}
            dataSubmitted={dataSubmitted}
            submitOkClick={submitOkClick}
          />
          <div className="formArea">
            <div
            style={{
              width: "1000px",
              margin: "0 auto 2rem",
              padding: "2rem",
              border: "1px solid lightgray",
              borderRadius: "5px",
              backgroundColor: "white",
            }}>

              <div style={{ display: "flex" }}>
                <div
                  className="createPurchaseOrderGrid"
                  style={{ gridTemplateRows: "repeat(2, 4rem)" }}>
                  {renderFormElements({
                    elementList: Object.keys(processLogInputs),
                    param: processLogInputs,
                    section: "general",
                  })}
                  {processLogInputs.workOrderId.bomId ? <FormElement
                    inputType="addButton"
                    value="View bOM"
                    colSpan={3}
                    setInput={() => {
                      editBOMId(processLogInputs.workOrderId.bomId)
                     setSection('bomDetail')
                    }}
                  /> : null}
                </div>
              </div>
              {/* {
              processLogInputs.workOrderId.value ? 
              <div className="purchaseOrderSubSectionArea">
                  {renderSubSection()}
              </div> : null
              } */}
              {renderTabs()}
            </div>
          </div>
          <div className="formSubmitArea">
                  <div className="formSubmitInnerArea">
                     <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
                     <button
                        className="submitButton"
                        onClick={() => {
                           setProcessLogError([]);
                           checkErrorsToSubmitProcessLog();
                        }}>
                        Submit
                     </button>
                  </div>
          </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    poInfo: state.poInfo,
    storeInfo: state.storeInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
    editBOMId: (value)=>dispatch(editBOMId(value))
  };
};

export default connect (mapStateToProps,mapDispatchToProps)(CreateProcessLog)