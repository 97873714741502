import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import InputField from "../SmallComponents/InputField";

const EditDcReturn=({isLogged,setSection,poInfo,storeInfo,addTokenToState,editApprove})=>{
    const lineItemsParamsList = ["itemName","itemCode","from","to","netWeight","grossWeight","uom","prodQty","currentDc","totalReturnDc","currentReturnDc"];
    const [dcReturnParams,setDcReturnParams]=useState({
        dcReturnId:{value:""},
        dcOrderNo:{
            inputType: "options",
            value: "",
            options: [],
            hintText: "Dc Order Id",
            mandatory: true,
            error: false,
            colSpan: 4,
            errorMessage: "Please Select Dc No To Create Dc Return",
            disabled: true
        },
        userDcReturnNo:{
            inputType: "text",
            value:"",
            hintText:"DC Return No",
            mandatory: true,
            error: false,
            colSpan: 4,
            errorMessage: "Please Enter Dc Return No"
        },
        dcReturnDate:{
            inputType: "dateFromEditPage",
            value: "",
            hintText: "Return Dc date",
            mandatory: true,
            colSpan: 6,
            error: false,
            errorMessage: "Please select Returned Dc date",
            mindate: true,
        }
    });

    const [lineItems,setLineItems]=useState([]);
    const [dcReturnDetail,setDcReturnDetail] = useState({});
    const [dcReturnError,setDcReturnError] = useState([]);
    const [dcReturnRemarks,setDcReturnRemarks] = useState("");
    const history = useHistory();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Editing DC Return. Please wait...");
    useEffect(()=>{getInformation();},[])


    async function getInformation(){
        await checkToken();
        var dcReturnDetail = await getDcReturnDetail();
        var paramsCopy = Object.assign(dcReturnParams);
        paramsCopy=update(paramsCopy,{dcReturnId:{value: {$set: dcReturnDetail.dcrId} },
                                    dcOrderNo: {options: {$set: [{optionId:dcReturnDetail.dcId,optionName:dcReturnDetail.dcNo}] },value: {$set: dcReturnDetail.dcId} },
                                     userDcReturnNo: {value:{$set: dcReturnDetail.userDcReturnNo } },
                                     dcReturnDate: {value: {$set: dcReturnDetail.dcReturnDate} } 
                                    });
    setDcReturnParams(paramsCopy);
    setDcReturnRemarks(dcReturnDetail.remarks);
    setDcReturnDetail(dcReturnDetail);
    }
    
    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }

     async function getDcReturnDetail(){
         var result = await fetchData({
             requestingPage: "dcDetail",
             method: "get",
             url: "dclist-fetch/dc-return-detail/"+poInfo.editDcReturnId,
             headers: { token: isLogged.accessToken,module: "DC List" },
         });
         if(result.msg==="success"){
             result.asset[0].lineItems.forEach((row)=>row["editQty"]=0);
             setLineItems(result.asset[0].lineItems);
            //  console.log("after edit", result.asset[0].lineItems);
         }else{
             console.log(result);
         }
         return result.asset[0];
        }

     const updateDcReturnParameter = ({section,param,key,value})=>{
        if(section === "general" && param !=="dcOrderNo"){
            var paramsCopy = Object.assign(dcReturnParams);
            paramsCopy = update(paramsCopy,{ [param]: {[key]: {$set: value} } });
            setDcReturnParams(paramsCopy);
        }
     }

     const renderFormElements=({elementList,param,section})=>{
        return elementList.map((element) => {
            return (
                <FormElement
                    key={element}
                    inputType={param[element].inputType}
                    value={param[element].value !== null ? param[element].value : ""}
                    setInput={(value) => {
                        updateDcReturnParameter({ section, param: element, key: "value", value: value });
                    }}
                    hintText={param[element].hintText}
                    mandatory={param[element].mandatory}
                    colSpan={param[element].colSpan}
                    options={param[element].inputType === "options" ? param[element].options : null}
                    error={param[element].error}
                    rowSpan={element === "vendorLogo" || element === "otherBusinessDetails" ? param[element].rowSpan : null}
                    placeholder={param[element].placeholder}
                    title={param[element].title}
                    disabled={param[element].disabled}
                    mindate={param[element].mindate}
                />
            );
        });
     }

     const updateEditQty=(rowIndex,value)=>{
        var lineParamsCopy = lineItems.slice();
        lineParamsCopy = update(lineParamsCopy, {[rowIndex]:{["editQty"]:{$set: parseInt(value) || 0} } });
        setLineItems(lineParamsCopy);
        }

     const updateLineItems=(action,rowIndex,value)=>{
        let lineCopy = lineItems.slice();
           if(action === "increment"){
                   lineCopy=update(lineCopy,{[rowIndex]:{["totalReturnDc"]: {$set: lineCopy[rowIndex]["totalReturnDc"]+value}  , 
                                                        ["currentReturnDc"]: {$set: lineCopy[rowIndex]["currentReturnDc"]+value}  }   
                                                        })
           } else if(action === "decrement"){
            lineCopy=update(lineCopy,{[rowIndex]:{["totalReturnDc"]: {$set: lineCopy[rowIndex]["totalReturnDc"]-value}  , 
                                      ["currentReturnDc"]: {$set: lineCopy[rowIndex]["currentReturnDc"]-value}  }   
            })
           }
           setLineItems(lineCopy);
     }

     const renderErrorMessage = () => {
        if (dcReturnError.length > 0) return dcReturnError[0];
      };

     const submitOkClick = () => {
     setIsOpen(false);
     if (dcReturnError.length === 0) {
      setSection("dcList");
     }
     };

     const checkErrors=()=>{
         var errorList=[];
         var dcReturnObj = Object.assign(dcReturnParams);
         Object.keys(dcReturnObj).forEach((item)=>{
             if(dcReturnObj[item].mandatory){
                dcReturnObj = update(dcReturnObj,{[item]: {error: {$set: !validateMandatory(dcReturnObj[item].value.toString())} } })
             }
             if(dcReturnObj[item].error){
             errorList.push(dcReturnObj[item].errorMessage);
             }
         });
         var lineItemsCopy = lineItems.slice();
         var LineCheckCondition1 = lineItemsCopy.some((row)=>row["currentReturnDc"]<0);
         if(LineCheckCondition1)
         errorList.push("Provide Valid Dc Return Quantity");
         var LineCheckCondition2 = lineItemsCopy.some((row)=>row["currentDc"]<row["totalReturnDc"]);
         if(LineCheckCondition2)
         errorList.push("The Total Return Dc Quantity For an Item cannot be Greater than Current Dc Quantity");
         if(errorList.length===0 && lineItemsCopy.length> 0)
         {
            var data2Server={
                dcReturnId: dcReturnObj.dcReturnId.value,
                userDcReturnNo: dcReturnObj.userDcReturnNo.value,
                dcId: dcReturnObj.dcOrderNo.value,
                dcReturnDate: dcReturnObj.dcReturnDate.value,
                notes:dcReturnRemarks,
            }
            lineItemsCopy=lineItemsCopy.map((row)=>{
                return {
                    dcrLineId: row.dcrLineId,
                    dcrId: dcReturnObj.dcReturnId.value,
                    dcLineId: row.dcLineId,
                    totalReturnDc: row.totalReturnDc,
                    currentReturnDc: row.currentReturnDc,
                }
            });

            data2Server.lineItems=lineItemsCopy;
            submitData(data2Server);
        }
         else
         {
             setDcReturnError(errorList);
             setDcReturnParams(dcReturnObj);
             setLineItems(lineItemsCopy);
         }
     };

     const submitData=async(data2Server)=>{
         setIsOpen(true);
         var result=await fetchData({
             requestingPage: "editDc",
             url: "dclist-edit/edit-dc-return",
             method: "put",
             headers: {token: isLogged.accessToken, module: "DC List"},
             data: data2Server,
         });

         setDataSubmitted(true);
         if(result.msg==="success"){
             if(editApprove)
             approveDcReturn();
         setModalText("DC Return Edited SuccessFully");
         }else{
         setDataSubmitted(true);
         setDcReturnError([result.desc]);
         setModalText(result.desc);
        //  console.log("Error in Submitting data",result);
        }
     };

     const approveDcReturn=async()=>{
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approveDc",
            method: "put",
            url: `dclist-edit/approve-dc-return/${poInfo.editDcReturnId}`,
            headers: { token: isLogged.accessToken,module: "DC List" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("DC Return Approved");
        } else {
            // console.log(data);
            setModalText(data.desc);
            setDcReturnError([data.desc]);
        }
     }
     const RenderVendorDetail = () => {
        return (
            <React.Fragment>
                <div className="detailTitle">Vendor Detail</div>
                <div className="detailText">
                    {<><b>{`Vendor Id: `}</b>{`${dcReturnDetail.vendorId}- ${dcReturnDetail.vendorName}`}</>}
                    <br />
                    {dcReturnDetail.address !== null ? <><b>{`Address: `}</b> {dcReturnDetail.vendorAddress}</> : null },
                    <br /> {dcReturnDetail.vendorCity}, {dcReturnDetail.vendorState || ""}, {dcReturnDetail.vendorPinCode !== null ? ` - ${dcReturnDetail.vendorPinCode}` : null}
                    <br /> <b>{dcReturnDetail.vendorMobile !== null || dcReturnDetail.vendorPhone !== null ? `\nPhone: ` : null}</b>
                    {dcReturnDetail.vendorMobile !== null ? dcReturnDetail.vendorMobile : null}
                    {dcReturnDetail.vendorPhone !== null ? `, ${dcReturnDetail.vendorPhone} ` : null}
                    {dcReturnDetail.vendorEmail !== null ? <br /> : null}
                    {dcReturnDetail.vendorEmail !== null ? <><b>{`Email Id:`}</b>{dcReturnDetail.vendorEmail}</> : null}
                    {dcReturnDetail.vendorGstNo !== null ? <br /> : null}
                    {dcReturnDetail.vendorGstNo !== null ? <><b>{`GST No: `}</b>{dcReturnDetail.vendorGstNo}</> : null}
                </div>
            </React.Fragment>
        );
     };
     return (
        <React.Fragment>
        <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
        <div className="formArea">
            <div
              style={{
                width: "1000px",
                margin: "0 auto 2rem",
                padding: "2rem",
                border: "1px solid lightgray",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
                <div style={{ display: "flex" }}>
                    <div className="createPurchaseOrderGrid" style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
                        {renderFormElements({elementList: ["dcOrderNo","userDcReturnNo","dcReturnDate"],param: dcReturnParams,section: "general"})}
                    </div>
                    <div style={{ width: "300px" }}>
                            <div className="vendorStoreDetailArea">
                                <div className="poVendorAddressDetail">{lineItems.length > 0 ? <RenderVendorDetail /> : null}</div>
                            </div>
                    </div>
                </div>
            
                {dcReturnDetail.orderDate !== undefined ? (
                        <div className="createSalesInvoiceGrid">
                            <div
                            style={{
                                gridColumn: "auto / span 3",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}>
                            DC No:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcReturnDetail.userDcNo !== undefined ? dcReturnDetail.userDcNo : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 4",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}>
                            DC Order Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcReturnDetail.orderDate !== undefined ? dcReturnDetail.orderDate : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 5",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}
                        >
                           DC Exp Delivery Date:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcReturnDetail.expectDate !== undefined ? dcReturnDetail.expectDate : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 4",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}
                        >
                           Process:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcReturnDetail.processName !== undefined ? dcReturnDetail.processName : "-"}
                            </span>
                        </div>
                        <div
                            style={{
                                gridColumn: "auto / span 4",
                                display: "flex",
                                fontSize: "14px",
                                alignItems: "center",
                                color: "#333333",
                            }}
                        >
                           Production Order:&nbsp;&nbsp;
                            <span style={{ color: "#333333", fontWeight: "normal" }}>
                                {dcReturnDetail.prodNo !== undefined ? dcReturnDetail.prodNo : "-"}
                            </span>
                        </div>
                    </div>
                    ) : null}
                    {lineItems.length > 0 ? (
                <table className="createItemPurchaseTable" style={{ margin: "2rem auto" }}>
                  <thead>
                    <tr className="createVendorContactsTableHeader">
                      <td>Item Name</td>
                      <td>Item Code</td>
                      { isLogged.showHideWeight ? <> <td>Weight From(gms)</td>
                      <td>Weight To(gms)</td>
                      <td>Net Weight(gms)</td>
                      <td>Gross Weight(gms)</td> </> : null}
                      <td>UOM</td>
                      <td>Production Qty</td>
                      <td>Current Dc Qty</td>
                      <td>Total Return Dc Quantity</td>
                      <td>Current Return Dc Quantity</td>
                      <td>Edit Return Qty</td>
                    </tr>
                  </thead>
                  <tbody>

                      
                  {
                  lineItems.map((row,i)=>(
                     
                        <tr className="createVendorContactsTableRows" key={i}>
                                {
                                lineItemsParamsList.map((col,j)=>{
                                if(["from","to","netWeight","grossWeight"].includes(col))
                                    {   
                                    return isLogged.showHideWeight ?  <td key={j}>{row[col] !==null ? row[col] : "-"}</td> :  null
                                    }
                                    else {
                                        return <td key={j} className={col==="itemName" ? 'stickyFirstColumn' : ''}>{row[col] !==null ? row[col] : "-"}</td>            
                                       }
                                    })
                                }
                                <td style={{display:"flex",maxWidth: "10px",justifyContent:"center"}}>
                                    <button onClick={(e)=>{updateLineItems("increment",i,lineItems[i]["editQty"])}}>+</button>

                                        <InputField type="number" value={lineItems[i].editQty} align="center" boxWidth="90px"
                                            setName={(value) => updateEditQty(i,parseInt(value) || 0)}/>
                                    <button onClick={(e)=>{updateLineItems("decrement",i,lineItems[i]["editQty"])}}>-</button>
                                </td>
                        </tr>)
                    )}
                  </tbody>
                  </table>):null}
                  <div className="poNetTotalSurchargeDiv">
                    <div className="dcReturnRemarks" style={{width:'400px'}}>
                        <div style={{ marginTop: "10px" }}>Remarks</div>
                        <textarea className="multilineInput" rows="6" cols="30" name="text" placeholder="Enter Remarks here"
                        style={{ padding: "10px",resize: "none", marginTop: "10px",height: "114px",fontFamily: "sans-serif",}}
                        value={dcReturnRemarks} onChange={(e) => {setDcReturnRemarks(e.target.value);}} />
                    </div>
                </div>
            </div>
        </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button className="submitButton"
            onClick={() => {setDcReturnError([]);checkErrors();}}>
            {editApprove ? "Edit & Approve":"Submit For Approval"}  
          </button>
        </div>
      </div>
        </React.Fragment>
        )
    }

const mapStateToProps = (state) => {
    return {
      isLogged: state.isLogged,
      storeInfo: state.storeInfo,
      poInfo: state.poInfo,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) =>
        dispatch(addToken(accessToken, employeeId)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditDcReturn);