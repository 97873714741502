import { Fragment,useState } from "react";
import MenuList from "../Menu/MenuList";
import PageTitle from "../SmallComponents/PageTitle";
import TopBanner from "../SmallComponents/TopBanner";
import ProductionReportParams from "./productionReportParams";
import ProductionReportList from "./productionReportList";
import productionReportIcon from "../../assets/production-report.svg"
import { useHistory } from "react-router-dom";

const ProdutionReport = () => {
    const [currentSection,setCurrentSection] = useState("productionReportParams");
    const history = useHistory();

    var goBackButton = (props) =>
    ({
      buttonName: "Back",
      className: "goBackButton",
      ...props
    });
    const renderSection=(currentSection)=>{
        switch(currentSection){
            case "productionReportParams":
                return(
                    <Fragment>
                    <PageTitle imgSrc={productionReportIcon} pageTitle={"Production Reports"}
                        buttonList={[goBackButton({setSection:(value)=>history.push(value),sectionName:"production-report"})]}/>
                     <ProductionReportParams setSection={setCurrentSection}/>
              </Fragment>
                );

            case "productionReportList":
                return(
                    <Fragment>
                        <PageTitle imgSrc={productionReportIcon} pageTitle={"Production Report List"}
                            buttonList={[goBackButton({setSection:setCurrentSection,sectionName:"productionReportParams"})]}
                            />
                            <ProductionReportList />
                    </Fragment>
                )

            default:
                return <>Page Not Found</>;
        }
    }

  return (
    <Fragment>
        <div className="completePage">
            <MenuList selectedMenu={"Reports"} selectedSubMenu={"Production Report"}/>
            <div className="detailsContainer">
                <TopBanner />
                {renderSection(currentSection)}
            </div>
        </div>
    </Fragment>
  )
}

export default ProdutionReport