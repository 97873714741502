import React, { useEffect, useState,useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import logoNotAvailable from "../../assets/no-stopping.svg";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import { editPoId } from "../../redux/PurchaseOrder/PoInfoActions";
import DeclineModal from "../SmallComponents/DeclineModal";

const PurchaseOrderDetail = ({ approval, poInfo, isLogged, addTokenToState, setSection, setEditAndApprove, editPoId ,
    setDownloadReportParams
}) => {
    const [poDetail, setPoDetail] = useState({});
    const [lineItemsList, setLineItemsList] = useState([]);
    const [vendorLogo, setVendorLogo] = useState([]);
    const [plantProfile,setPlantProfile]=useState()

    useEffect(() => {
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getPoDetail();
    }

    const history = useHistory();

    async function checkToken() {
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            addTokenToState(isLogged.employeeId, token2);
        } else {
            console.log("Token not changed");
        }
    }
    async function getPlantProfile({plantId,plantProfile}) {
        if (plantId && plantProfile) {
            var data = await fetchData({
                requestingPage: "plantImage",
                method: "get",
                url: "image/plant-profile-photo/" + plantId,
                headers: { token: isLogged.accessToken, module: "Plant & Store" },
            });
            if (data.msg !== "failure") setPlantProfile(data);
        }
    }
    async function getPoDetail() {
        var data = await fetchData({
            requestingPage: "vendorDetail",
            method: "get",
            url: "po-fetch/po-detail/" + poInfo.viewPoId,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });
        if (data.msg === "success") {
            setPoDetail(data.asset[0]);
            setLineItemsList(data.asset[0].lineItems);
            setDownloadReportParams({dealerName: data.asset[0].vendorName, orderNo: data.asset[0].poNo})
            await getPlantProfile({plantId:data.asset[0].plantId,plantProfile: data.asset[0].plantProfile});
        } else {
        }
    }

    useEffect(() => {
        getVendorLogo();
        // eslint-disable-next-line
    }, [poDetail]);

    async function getVendorLogo() {
        var data = await fetchData({
            requestingPage: "vendorImage",
            method: "get",
            url: "image/vendor-logo/" + poDetail.vendorId,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });
        setVendorLogo(data);
    }

    const renderSummaryInfo = () => {
        if (poDetail !== null) {
            return (
                <React.Fragment>
                    <span className="vendorSummaryRole" style={{ textTransform: "capitalize" }}>
                        {poDetail.poNo}{" "}
                        <span
                            className="vendorSummaryCategory"
                            style={
                                poDetail.poStatus === "Declined"
                                    ? { color: "red" }
                                    : poDetail.poStatus === "Approved"
                                        ? { color: "#14c76a" }
                                        : { color: "#333333" }
                            }
                        >
                            ( {poDetail.poStatus} )
                        </span>
                    </span>
                    <div className="vendorSummaryGrid">
                        {/* <span className="vendorSummaryCategory">PO Number</span> */}

                        <span className="vendorSummaryCategory">Order Date</span>
                        <span className="vendorSummaryValue">: {poDetail.poDate}</span>

                        <span className="vendorSummaryCategory">Expected Delivery Date</span>
                        <span className="vendorSummaryValue">: {poDetail.expectedDeliveryDate}</span>

                        <span className="vendorSummaryCategory">Transporter Name</span>
                        <span className="vendorSummaryValue">
                            : {poDetail.transporterName === null || poDetail.transporterName === "" ? "NA" : poDetail.transporterName}
                        </span>

                        <span className="vendorSummaryCategory">Store Details</span>
                        <span className="vendorSummaryValue">: {poDetail.storageAddress}</span>
                    </div>
                </React.Fragment>
            );
        }
    };

    const renderImageStatus = () => {
        return (
            <div>
                <span style={{ flex: "1" }}>&nbsp;</span>

                <img
                    className="poVendorImage"
                    src={
                        vendorLogo !== null && vendorLogo !== undefined && vendorLogo !== ""
                            ? `data:image/png;base64,${vendorLogo}`
                            : logoNotAvailable
                    }
                    alt="Vendor Logo"
                />
            </div>
        );
    };

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td>Item</td>
                        <td>Weight From(gms)</td>
                        <td>Weight To(gms)</td>
                        <td>Net Weight(gms)</td>
                        <td>Gross Weight(gms)</td>
                        <td>UOM</td>
                        <td>HSN No</td>
                        <td>Quantity</td>
                        <td>Unit Price</td>
                        <td>Tax (%)</td>
                        <td>Net Price</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={lineItemsList} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        var lineItemParams = ["itemName", "from", "to", "netWeight", "grossWeight", "uom", "hsnCode", "quantity", "unitPrice", "tax", "netPrice"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    async function approvePo() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "approvePo",
            method: "put",
            url: `po-edit/po-approve/${poInfo.viewPoId}`,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Approved Purchase Order");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const [errorMessage, setErrorMessage] = useState([]);

    const renderErrorMessage = () => {
        if (errorMessage.length > 0) {
            return errorMessage;
        }
    };
    const ApproveButtons = () => {
        return (
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>

                    <button
                        className="declineButton"
                        onClick={() => {
                            setDeclineModalIsOpen(true);
                        }}
                    >
                        Decline
                    </button>
                    <button
                        className="saveButton"
                        onClick={() => {
                            setEditAndApprove(true);
                            setSection("editPurchaseOrder");
                            editPoId(poInfo.viewPoId);
                        }}
                    >
                        Edit
                    </button>

                    <button
                        className="submitButton"
                        onClick={() => {
                            approvePo();
                        }}
                    >
                        Approve
                    </button>
                </div>
            </div>
        );
    };

    const submitOkClick = () => {
        setIsOpen(false);
        if (errorMessage.length === 0) {
            setSection("purchaseOrderList");
        }
        setModalText("Approving PO. Please wait...");
    };

    const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
    const [declineModalText, setDeclineModalText] = useState("Provide a reason for PO Decline");
    const [declineReason, setDeclineReason] = useState("");
    const [declineReasonError, setDeclineReasonError] = useState(false);

    const declineOkClick = () => {
        if (declineReason.trim().length > 0) {
            setDeclineReasonError(false);
            setDeclineModalIsOpen(false);

            setIsOpen(true);
            setModalText("Declining PO. Please Wait...");
            declinePo();
        } else {
            setDeclineReasonError(true);
        }
    };

    async function declinePo() {
        setIsOpen(true);
        setDataSubmitted(false);
        var data = await fetchData({
            requestingPage: "declinePo",
            method: "put",
            url: `po-edit/po-decline/${poInfo.viewPoId}`,
            headers: { token: isLogged.accessToken, module: "Purchase Order" },
            data: { reason: declineReason },
        });

        setDataSubmitted(true);
        if (data.msg === "success") {
            setModalText("Declined Purchase Order");
        } else {
            setModalText(data.desc);
            setErrorMessage(data.desc);
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [modalText, setModalText] = useState("Approving PO. Please wait...");

    const footerPlants=useMemo(()=>{

        if(isLogged.plantOptions && poDetail.plantName){
        let filtered = isLogged.plantOptions.filter((obj)=>obj.optionName!==poDetail.plantName);
        return filtered.map((obj)=>obj.optionName).join(',');
        }else{
          return " "
        }
      },[isLogged.plantOptions,poDetail.plantName]);
    const renderPlantDetails=({key,className})=>{
        return(
          <div className={ className  }>
         { className==='saleOrderHeader' ?  <div>{poDetail[`${key}Name`]}</div>: null }
        {className ==='saleOrderHeader' ?  <div style={{fontSize: "12px"}}>{poDetail[`${key}Address`]}</div> : null}
                <div style={{fontSize: "12px"}}>{className==='saleOrderFooter' ? <><span>Head Office:</span> {poDetail[`${key}Address`]}</> : null }{poDetail[`${key}City`]},{poDetail[`${key}State`]},{poDetail[`${key}Country`]} - {poDetail[`${key}ZipCode`]}</div>
                <div style={{fontSize:"12px"}}>GST: {poDetail[`${key}GstNo`]}</div>
                <div style={{fontSize: "12px"}}>{poDetail[`${key}Email`]},{poDetail[`${key}ContactNo`]}</div>
          </div>
        )
      }
      const renderPlantImageStatus=()=>{
            return (
                <div style={{width:"80px",height: "80px",display:"inline-block"}}>
                  <img
                    className="vendorHeaderImage"
                    src={
                      plantProfile !== null && plantProfile !== undefined && plantProfile !== ""
                        ? `data:image/png;base64,${plantProfile}`
                        : logoNotAvailable
                    }
                    alt="Vendor Logo"
                  />
                  <div className="vendorCurrentStatus">
                  </div>
                </div>
              );
      }
    return (
        <React.Fragment>
            <DeclineModal
                openDeclineModal={declineModalIsOpen}
                declineOkClick={declineOkClick}
                declineModalText={declineModalText}
                declineReason={declineReason}
                error={declineReasonError}
                setDeclineReason={setDeclineReason}
                cancelDecline={() => setDeclineModalIsOpen(false)}
            />
            <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">
                <div style={{display:"flex"}}>
          {renderPlantImageStatus()}
            {renderPlantDetails({key:'plant',className: 'saleOrderHeader' })}
            </div>
          <hr style={{border: "1px solid #dfd9d9",margin: "10px 0px"}}/>
                    <div className="vendorSummary">
                        <div className="poSummaryText">{renderSummaryInfo()}</div>
                        <div className="poSummaryText2">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                {poDetail.vendorName}
                            </span>

                            <span className="poSummaryValue">
                                {`Vendor ID: ${poDetail.vendorId}`}
                            </span>
                            <span className="poSummaryValue">
                                {poDetail.vendorPinCode !== null ? ` ${poDetail.vendorAddress} - ${poDetail.vendorPinCode}` : poDetail.vendorAddress}
                            </span>
                            <span className="poSummaryValue">
                                {poDetail.vendorMobile !== null || poDetail.vendorPhone !== null ? `\nPhone: ` : null}
                                {poDetail.vendorMobile !== null ? poDetail.vendorMobile : null}
                                {poDetail.vendorPhone !== null ? `, ${poDetail.vendorPhone} ` : null}
                            </span>
                            <span className="poSummaryValue">{poDetail.vendorEmail !== null ? `Email Id: ${poDetail.vendorEmail}` : null}</span>

                            <span className="poSummaryValue">{poDetail.vendorGstNo !== null ? `GST No: ${poDetail.vendorGstNo}` : null}</span>

                            <span className="poSummaryValue">
                                Quotation No: {poDetail.quotationNo === null || poDetail.quotationNo === "" ? "NA" : poDetail.quotationNo}
                            </span>

                            <span className="poSummaryValue">
                                Quotation Date: {poDetail.quotationDate === null || poDetail.quotationDate === "" ? "NA" : poDetail.quotationDate}
                            </span>
                        </div>
                        {renderImageStatus()}
                    </div>

                    {poDetail.poStatus === "Declined" ? (
                        <div style={{ marginTop: "2rem" }}>
                            <span style={{ color: "red", fontWeight: "bold" }}>Reason : </span> {poDetail.declineReason}
                        </div>
                    ) : null}

                    <div className="vendorDetailTabArea">
                        <RenderTable />
                    </div>

                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                No Items: <span>{lineItemsList.length}</span>
                            </div>

                            <div style={{ marginTop: "10px" }}>
                                Purchase Instruction: {poDetail.purchaseInstruction !== null ? poDetail.purchaseInstruction : "-"}
                            </div>
                        </div>
                        <div className="poSpacer">&nbsp;</div>
                        <div className="poNetTotal">
                            <div className="poTotalTitle">
                                <span>
                                    <b>Net Total</b>
                                </span>
                                <span className="poNetTotalAmount">{poDetail.netTotal}</span>
                            </div>
                            <div className="poDetailChargesDiv">
                                <div className="poText">
                                    <span>Other Charges</span>
                                    <span>{poDetail.otherCharges}</span>
                                </div>

                                <div className="poText">
                                    <span>Transport Charges </span>
                                    <span>{poDetail.transportCharges}</span>
                                </div>
                            </div>

                            <div className="poTotalTitle" style={{ margin: "1rem 0 0 0" }}>
                                <span>
                                    <b>Total Amount</b>
                                </span>
                                <div className="poNetTotalAmount">{poDetail.totalAmount}</div>
                            </div>
                        </div>
                    </div>
                    <hr style={{margin: "10px 0px",border: "1px solid #dfd9d9"}}/>
          <div style={{display:"flex",flexDirection:"column"}}>
                
               {poDetail.parentPlantName ? renderPlantDetails({key:'parentPlant',className: 'saleOrderFooter'}) : renderPlantDetails({key: 'plant',className: 'saleOrderFooter' })}
              <div style={{fontSize: "12px",textAlign:"center",margin:"5px"}}>
              <span>Branches : </span>{footerPlants}
              </div>
            </div>
                </div>
            </div>
            {approval ? <ApproveButtons /> : null}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        poInfo: state.poInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
        editPoId: (poId) => dispatch(editPoId(poId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDetail);
