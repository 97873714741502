import React, { Fragment, useEffect,useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from 'serverCall/fetchData';
import Modal from "react-modal";

const OtherChargesDetail = ({setSection, addTokenToState, isLogged,storeInfo,setDownloadReportParams}) => {

    const [otherChargesDetail,setOtherChargesDetail] = useState([]);
    async function getOtherChargesDetail(){
        var psDetail = await fetchData({
          requestingPage: "customerList",
          method: "get",
          url: "so-fetch/other-charges/"+storeInfo.otherChargesId,
          headers: { token: isLogged.accessToken, module: "Other Charges" },
        });
        console.log(psDetail.asset,"detail")
        setOtherChargesDetail(psDetail.asset);
        setDownloadReportParams({
            dealerName: `Other Charge Detail ${psDetail.asset.companyName}`,
            orderNo: psDetail.asset.psDate
        })
        return psDetail.asset
      }
      useEffect(()=>{
        getOtherChargesDetail();
      },[])

    const renderSummaryInfo=()=>{

        return(
            <table className='innerTable' style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td >
                            <span className="vendorSummaryValue">Packing Slip Date : </span><br></br>
                            <span className="vendorSummaryCategory">
                                {otherChargesDetail?.psDate}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <span className="vendorSummaryValue" style={{ textTransform: "capitalize" }}>
                                Invoice No:</span><br></br>
                            <span className="vendorSummaryCategory">
                                {otherChargesDetail.invoiceNo}
                             
                                &nbsp;
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="vendorSummaryValue"> Sale order No</span><br />&nbsp;
                            <span className="vendorSummaryCategory">
                                {otherChargesDetail.soNo}
                            </span>
                        </td>
                    </tr><tr>
                        <td><span className="vendorSummaryValue">Vehicle No</span><br />&nbsp;

                            <span className="vendorSummaryCategory">
                                {otherChargesDetail.vehicleNo}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style={{ border: "none" }}>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
        )
    }

   const RenderMainSection=()=>{

    return(
        <table  className="invoiceBill" style={{width:"100%"}}>
            <tbody>
                <tr>
                    <td>
                        <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
                            <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
                                <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Seller</span>
                                <span className="poSummaryValue">{`Company: ${otherChargesDetail.parentPlantName ||otherChargesDetail.plantName}`}</span>
                                <span className="poSummaryValue">{`Plant: ${ otherChargesDetail.plantName}`}</span>
                                <span className="poSummaryValue">{` Address: ${otherChargesDetail.plantState} ${otherChargesDetail.plantCity} ${otherChargesDetail.plantZipCode}`}</span>
                                <span className="poSummaryValue">{`GSTIN/UIN: ${otherChargesDetail.plantGstNo}`}</span>
                            </div>
                        </div>
                    </td>
                    <td rowSpan={2} style={{border:"none",padding:"0px",height:"100%"}}>
                    {renderSummaryInfo()}
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="vendorSummary" style={{ maxWidth: "max-content", margin: "0", display: "inline-flex", verticalAlign: "top" }}>
                            <div className="poSummaryText" style={{ maxWidth: "max-content" }}>
                                <span className="vendorSummaryCategory" style={{ color: "#000", textTransform: "capitalize" }}>Customer</span>
                                <span className="poSummaryValue">{`Company: ${otherChargesDetail.companyName}`}</span>
                                <span className="poSummaryValue">{`Customer Id: ${otherChargesDetail.customerId}`}</span>
                                <span className="poSummaryValue">{`Phone No: ${otherChargesDetail.customerPhone || otherChargesDetail.customerMobile}`}</span>
                                <span className="poSummaryValue">{`Address: ${otherChargesDetail.customerAddress},  ${otherChargesDetail.customerCity} ${otherChargesDetail.customerState}`}</span>
                                {otherChargesDetail.cusomerEmail && <span>Email: {otherChargesDetail.customerEmail}</span>}
                                <span className="poSummaryValue">{`GSTIN/UIN: ${otherChargesDetail.customerGstNo}`}</span>
                            </div>
                        </div>
                    </td>
                </tr>
              
            </tbody>
        </table>
    )
   }

   
   const RenderTable=({singlePageLineItems,pageNumber})=>{
       if(singlePageLineItems){

       const totalBundleCount = Object.values(singlePageLineItems)?.reduce((acc, val) => acc + val.length, 0);
       const totalWeight = Object.values(singlePageLineItems)?.reduce((acc, val) => acc.concat(val), []).reduce((total, { netWeight }) => total + netWeight, 0)
       return(
           <>
        <table className="innerTable" style={{width:"100%",height:"90%"}}>
            <thead>
                <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                    <td>Sno.</td>
                    <td>Bundles</td>
                    <td>Weight (KGS)</td>
                    <td>Rate</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
                {
                    Object.entries(singlePageLineItems)?.map(([sectionKey,sectionLine],index)=>(
                    <React.Fragment key={index}>
                     {sectionLine.map((line,sNoIndex)=>(
                         <tr className="invoiceDetailContactsTableRows">
                             <td>{line.serialNumber}</td> {/*S. No */}
                             <td>{(line.bundleId+'').padStart(6,0)}</td>
                             <td>{line.totalWeight}</td>
                             <td>{line.rate}</td>
                             <td>{line.totalWeight * line.rate}</td>
                         </tr>
                     ))}
                     {/*Bundle Footer */}
                        <tr className="invoiceDetailContactsTableRows">&nbsp;</tr>
                        <tr className="invoiceDetailContactsTableRows">
                            <td>&nbsp;</td>
                            <td>Total</td>
                            <td>{sectionLine.reduce((total, obj) => obj.totalWeight + total,0)}</td>
                            <td>&nbsp;</td>
                            <td>{sectionLine.reduce((sum, currentItem) => sum + (currentItem.totalWeight * currentItem.rate), 0)}</td>
                        </tr>
                    </React.Fragment>
                    ))
                }
            </tbody>
        </table>
        <table className="innerTable" style={{width:"100%",height:"90%"}}>
            <thead>
                <tr className="invoiceDetailContactsTableHeader" style={{color: "#000"}}>
                    <td>Sno.</td>
                    <td>Section No</td>
                    <td>Section Name</td>
                    <td>Qty (KGS)</td>
                    <td>Rate</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
                {
                    Object.entries(singlePageLineItems)?.map(([sectionKey,sectionLine],index)=>(
                    <React.Fragment key={index}>
                     {sectionLine.map((line,sNoIndex)=>(
                         <tr className="invoiceDetailContactsTableRows">
                             <td>{line.serialNumber}</td> {/*S. No */}
                             <td>{(line.sectionNo)}</td>
                             <td>{line.sectionName}</td>
                             <td>{line.quantity}</td>
                             <td>{line.premiumRate}</td>
                             <td>{line.quantity * line.premiumRate}</td>
                         </tr>
                     ))}
                     {/*Bundle Footer */}
                        <tr className="invoiceDetailContactsTableRows">&nbsp;</tr>
                        <tr className="invoiceDetailContactsTableRows">
                            <td>&nbsp;</td>
                            <td>Total</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>{sectionLine.reduce((total, obj) => (obj.quantity * obj.premiumRate) + (obj.totalWeight * obj.rate) + total,0)}</td>
                        </tr>
                    </React.Fragment>
                    ))
                }
            </tbody>
        </table>
        <div style={{display:"flex",justifyContent:"space-between",fontSize:"14px"}}>
               <span>{`Total Bundle Count: ${totalBundleCount} `}</span>   
              {otherChargesDetail.detailPageLineItems[pageNumber+1] ? <span>Continued to Page No {pageNumber+1}</span> : null}
               <span >{`Total Weight: ${totalWeight}`}</span>
            </div>
            </>
       )
            } else{
                return null;
            }
   }
    return(
        <Fragment>
            <div className="detailsPageWrapper">

                {
                    otherChargesDetail.detailPageLineItems?.length > 0 ?
                    otherChargesDetail.detailPageLineItems.map((currPage,pageIndex)=>(
                        <Fragment>
                        <div className="detailsPageContainer">
                            <div className="invoicePageTitle">Premium List</div>
                            <div style={{ border: "0.25px solid black", minHeight: "1100px", position: "relative" }}>
                                {/*main content */
                                pageIndex ===0 && <RenderMainSection />
                                }
                                <RenderTable singlePageLineItems={currPage} pageNumber={pageIndex+1}/>
                            </div>
                        </div>
                        </Fragment>
                    )) 
                    
                    :null
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo:state.storeInfo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(OtherChargesDetail)