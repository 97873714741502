import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import fetchData from "serverCall/fetchData";

const SalesDealDetail = ({ storeInfo, isLogged }) => {

    const [dealDetail, setDealDetail] = useState({})
    //get details of sales quote
    async function getSalesDealDetail() {
        let data = await fetchData({
            requestingPage: "employeeDetail",
            method: "get",
            url: `so-fetch/sales-quote-detail/` + storeInfo.salesQuoteId,
            headers: { token: isLogged.accessToken, module: "Sales Quote" }
        })
        if (data.msg === "success") {
            setDealDetail(data.asset)
        }
    }

    useEffect(() => {
        getSalesDealDetail()
    }, [])

    const renderSummaryInfo = () => {
        if (dealDetail !== null) {
            return (
                <Fragment>
                    <div className="vendorSummaryGrid">

                    <span className="vendorSummaryCategory">Quote Start Date :</span>
                            <span className="vendorSummaryValue">{new Date(dealDetail.createTime).toLocaleDateString()}</span>
                            <span className="vendorSummaryCategory">Quote End Date :</span>
                            <span className="vendorSummaryValue">{new Date(dealDetail.expirationDate).toLocaleDateString()}</span>

                            <span className="vendorSummaryCategory"><strong>Seller :</strong></span>
                        <span className="vendorSummaryValue">
                            <span className="vendorSummaryRole" style={{ textTransform: "uppercase" }}>
                                <strong>{dealDetail.plantName}</strong>
                            </span>
                            <div>
                                {dealDetail.plantAddress}

                            </div>
                        </span>

                        <span className="vendorSummaryCategory"><strong>Buyers:</strong></span>
                            <span className="vendorSummaryValue">&nbsp;</span>
                            <span className="vendorSummaryCategory">Company Name :</span>

                            <span className="vendorSummaryValue">
                            {dealDetail.leadList?.[0]?.companyName}
                            </span>
                            <span className="vendorSummaryCategory">Leads:</span>
                          
                            <span className="vendorSummaryValue">
                            {dealDetail.leadList?.map((customer) => {
                                return (
                                    <Fragment>
                                        <span className="poSummaryValue" style={{ textTransform: "uppercase" }}>
                                            {customer.label}
                                        </span>

                                        <span className="poSummaryValue">
                                            {customer.email}<br />
                                        </span>
                                        </Fragment>

                                )
                            })}
                            </span>   
                    </div>
                </Fragment>
            )
        }
    }

    const RenderTable = () => {
        return (
            <table className="vendorDetailContactsTable">
                <thead>
                    <tr className="vendorDetailContactsTableHeader">
                        <td >Item Code</td>
                        <td >ItemName</td>
                        <td>Unit Price</td>
                        <td >Quantity</td>
                        <td >MRP</td>
                    </tr>
                </thead>
                <tbody>
                    <RenderTableRows rows={dealDetail?.lineItemsList || []} tab="contact" />
                </tbody>
            </table>
        );
    };

    const RenderTableRows = ({ rows, tab }) => {
        let lineItemParams = ["itemCode", "itemName", "unitPrice", "quantity", "MRP"];

        return rows.map((row, j) => {
            return (
                <tr className="vendorDetailContactsTableRows" key={j}>
                    {lineItemParams.map((key, i) => {
                        return <td key={i}>{row[key] !== null ? row[key] : " - "}</td>;
                    })}
                </tr>
            );
        });
    };

    return (
        <Fragment>
            <div className="detailsPageWrapper">
                <div className="detailsPageContainer">

                    <div className="vendorSummaryRole" style={{ textTransform: "uppercase", textAlign: "center" }}>
                        <h2> {dealDetail.quoteName}</h2>
                    </div>
                    <div className="vendorSummary">
                        <div className="poSummaryText">
                            {renderSummaryInfo()}
                        </div>

                        <div className="poSummaryText2" >
                            <b>Prepared by :{dealDetail.dealOwner}</b>
                            <span className="poSummaryValue">
                                {`Name:    ${dealDetail.employeeName}`}<br />
                                {`Email:    ${dealDetail.employeeEmail || ""}`}<br />
                            </span>
                        </div>
                    </div>
                    <div className="vendorDetailTabArea">
                        <RenderTable />

                    </div>
                    <div className="poNetTotalSurchargeDiv">
                        <div className="purchaseInstruction">
                            <div>
                                Terms And Conditions
                                <span>
                                    {dealDetail.purchaseTerm}</span>
                            </div>
                        </div>

                        <div className="poSpacer">&nbsp;</div>

                        <div className="poNetTotal">

                            <div className="SoDetailChargesDiv">
                                <div className="poText">
                                    <span>Fee</span>
                                    <span>{dealDetail?.fee || 0}</span>
                                </div>
                                <div className="poText">
                                    <span>Tax</span>
                                    <span>{dealDetail.tax || 0}</span>
                                </div>
                                <div className="poText">
                                    <span>Discount </span>
                                    <span>{dealDetail.discount || 0}</span>
                                </div>

                            </div>

                            <div className="poTotalTitle">
                                <span>
                                    <b>Total</b>
                                </span>
                                <span className="poNetTotalAmount">{dealDetail?.totalAmount || 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        storeInfo: state.storeInfo
    };
};


export default connect(mapStateToProps)(SalesDealDetail);