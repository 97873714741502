import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import fetchData from "../../serverCall/fetchData";
import FetchNewToken from "../../serverCall/FetchNewToken";
import editIcon from "../../assets/edit.svg";
import editIconDisabled from "../../assets/edit_disabled.svg";
import { editClearingAgentId, editTransporterId, editUomAction } from "../../redux/Item/ItemInfoActions";

const AgentList = ({
    // props
    setSection,

    // state
    // roleInfo,
    isLogged,

    // dispatch
    editAgentId,
    addTokenToState,
}) => {
    useEffect(() => {
        // console.log("Starting");
        getInformation();
        // eslint-disable-next-line
    }, []);

    async function getInformation() {
        await checkToken();
        await getAgents();
    }

    const history = useHistory();

    const [noRecords, setNoRecords] = useState(false);
    const [loading, setLoading] = useState(true);

    async function checkToken() {
        // console.log("Checking token");
        const token2 = await FetchNewToken(isLogged.accessToken);
        if (token2 === "expired") {
            history.push("/");
        } else if (token2 !== isLogged.accessToken) {
            // console.log("New Token");
            addTokenToState(isLogged.employeeId, token2);
        } else {
            // console.log("Token not changed");
        }
    }

    async function getAgents() {
        var data = await fetchData({
            requestingPage: "uomList",
            method: "get",
            url: "fetch/clearing-agent-list",
            headers: { token: isLogged.accessToken ,module:"Clearing Agent" },
        });
        if (data.msg === "success") {
            setAgentList(data.asset);
            if (data.asset.length > 0) setNoRecords(false);
            else setNoRecords(true);
        } else {
            // console.log(data);
        }
        setLoading(false);
    }

    const [agentList, setAgentList] = useState([]);

    const renderAgentList = (list) => {
        return list.map((row) => {
            return (
                <tr className="uomListData" key={row.clearingAgentId}>
                    <td className="uomSymbolData">{row.clearingAgentName}</td>
                    <td className="uomSymbolData">{row.description}</td>
                    <td className="uomActionData">
                        <img
                            className="uomEditImage"
                            alt="Edit Record"
                            src={isLogged.access["Clearing Agent"] === "Edit" ? editIcon : editIconDisabled}
                            onClick={() => {

                                if (isLogged.access["Clearing Agent"] === "Edit") {
                                    editAgentId(row.clearingAgentId);
                                    setSection("editAgent");
                                }
                            }}
                            style={isLogged.access["Clearing Agent"] === "Edit" ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                        />
                    </td>
                </tr>
            );
        });
    };

    return (
        <React.Fragment>
            <div className="uomListArea">
                <table className="uomListTable">
                    <thead>
                        <tr className="uomListHeader">
                            <td className="uomSymbolHeader">Clearing Agent Name</td>
                            <td className="uomSymbolHeader">Description</td>
                            <td className="uomActionHeader">Action</td>
                        </tr>
                    </thead>
                    <tbody>{agentList.length > 0 ? renderAgentList(agentList) : null}</tbody>
                </table>
                {noRecords ? (
                    <div className="noRecordsYet">No records found</div>
                ) : loading ? (
                    <div className="loader" style={{ margin: "1rem auto" }}></div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        // roleInfo: state.roleInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editAgentId: (clearingAgentId) => dispatch(editClearingAgentId(clearingAgentId)),
        addTokenToState: (accessToken, employeeId) => dispatch(addToken(accessToken, employeeId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentList);
