import React,{useState} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';
import PageTitle from 'components/SmallComponents/PageTitle';
import MenuList from 'components/Menu/MenuList';
import TopBanner from 'components/SmallComponents/TopBanner';
import sectionMasterIcon from "assets/section-master-black.svg";
import CreateSectionMaster from "./createSectionMaster";
import EditSectionMAster from "./editSectionMaster";
import SectionMasterList from "./sectionMasterList";


export const SectionMaster = ({isLogged}) => {
  const [currentSection, setCurrentSection] = useState("sectionMasterList");

  var history = useHistory();

  const pushBack = (section) => {
      history.push(section);
  };

  var goBackButton = [{ buttonName: "Back", className: "goBackButton", setSection: setCurrentSection, sectionName: "sectionMasterList" }];

  var goBackToItemButton = {
      buttonName: "Back",
      className: "goBackButton",
      setSection: pushBack,
      sectionName: "/itemMenu",
  };

  var createSectionMasterButton = {
      buttonName: "Create Section Master",
      className: "employeeButton",
      setSection: setCurrentSection,
      sectionName: "createSectionMaster",
  };

  const renderSection = (section) => {
      switch (section) {
          case "sectionMasterList":
              return (
                  <React.Fragment>
                      <PageTitle
                          imgSrc={sectionMasterIcon}
                          pageTitle="Section Master"
                          buttonList={
                              isLogged.access["Section Master"] === "Create" || isLogged.access["Section Master"] === "Edit"
                                  ? [createSectionMasterButton, goBackToItemButton]
                                  : [goBackToItemButton]
                          }
                      />
                      <SectionMasterList setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          case "createSectionMaster":
              return (
                  <React.Fragment>
                      <PageTitle imgSrc={sectionMasterIcon} pageTitle="Create Section Master" buttonList={goBackButton} />
                      <CreateSectionMaster setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          case "editSectionMaster":
              return (
                  <React.Fragment>
                      <PageTitle imgSrc={sectionMasterIcon} pageTitle="Edit Section Master" buttonList={goBackButton} />
                      <EditSectionMAster setSection={(value) => setCurrentSection(value)} />
                  </React.Fragment>
              );

          default:
              return <h1>Page not yet created</h1>;
      }
  };

  return (
      <React.Fragment>
          <div className="completePage">
              <MenuList selectedMenu="Master Configure" selectedSubMenu="Item Menu" />
              <div className="detailsContainer">
                  <TopBanner />
                  {renderSection(currentSection)}
              </div>
          </div>
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  isLogged : state.isLogged
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SectionMaster)