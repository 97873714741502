import React, { useEffect, useState } from "react";
import CreateEditModal from "../SmallComponents/CreateEditModal";
import FormElement from "../SmallComponents/FormElement";
import update from "immutability-helper";
import fetchData from "../../serverCall/fetchData";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { addToken } from "../../redux/UserAccount/IsLoggedActions";
import { validateMandatory } from "../CommonFunctions/ValidateFields";
import FetchNewToken from "../../serverCall/FetchNewToken";
import { pendingIdn } from "../../redux/Store/StoreInfoActions";

const IdnCreate = ({ opList,setSection,storeInfo, isLogged, addTokenToState }) => {
  const idnParamsList = ["mrsNo", "mrsReqDate", "mrsExpectedDate", "idnDate"];
  const lineItemsParamsList = isLogged.showHideWeight ? [
    "itemName",
    "itemCode",
    "from",
    "to",
    "netWeight",
    "grossWeight",
    "uom",
    "quantity",
    "idnQuantity",
  ] : ["itemName","itemCode","uom", "quantity","idnQuantity",];
  const [idnDetail, setIdnDetail] = useState({});
  const [idnParams, setIdnParams] = useState({
    mrsNo: {
      inputType: "options",
      value:"",
      hintText: "MRS No",
      options: [],
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please Select one of the Approved MRS Lists",
      disabled:false
    },
    mrsReqDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: " Request date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please select Request date for request",
      mindate: true,
      disabled: true,
    },
    mrsExpectedDate: {
      inputType: "dateFromEditPage",
      value: "",
      hintText: "Expected request complete date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please select expected date for completing request",
      mindate: true,
      disabled: true,
    },
    idnDate: {
      inputType: "dateFromEditPage",
      value: (new Date().toLocaleDateString("en-GB", {day: "numeric",month: "short",year: "numeric",}).replace(/ /g, "-")).toString(),
      hintText: "IDN Date",
      mandatory: true,
      colSpan: 6,
      error: false,
      errorMessage: "Please select expected date for completing request",
      mindate: true,
      disabled: true,
    },
  });
  

  const [lineItems, setLineItems] = useState([]);
  const [optList, setOptList] = useState([]);
  const [idnError, setIdnError] = useState([]);
  const [instruction, setInstruction] = useState("");

  const history = useHistory();
  async function checkToken() {
   
    const token2 = await FetchNewToken(isLogged.accessToken);
    if (token2 === "expired") {
      history.push("/");
    } else if (token2 !== isLogged.accessToken) {
     
      addTokenToState(isLogged.employeeId, token2);
    } else {
      console.log("Token not changed");
    }
  }
  useEffect(() => GetInformation(),[]);

  async function GetInformation() {
    await checkToken();
        var paramsCopy = Object.assign(idnParams);
        if(idnParams.mrsNo.options.length <1){

            var results = await getMrsApprovedList();
            setOptList(results);
            var listForIdnOptions =[];
            results.map((i) => {
              if(i.idnStatus !== "Completed" && !(opList.includes(i.requestNo)))
              listForIdnOptions.push( { optionId: i.requestNo,optionName: i.requestNo});
              });
            paramsCopy = update(paramsCopy, { mrsNo: { options: { $set: listForIdnOptions } } });
            setIdnParams(paramsCopy);
            return results;
      } 
    }

    async function getMrsApprovedList() {
    var data = await fetchData({
      requestingPage: "customerList",
      method: "post",
      url: "mrs-for-idn/request-list",
      headers: { token: isLogged.accessToken,module:"IDN" },
    });
    if (data.msg === "success") {
      return data.asset;
    } else{setIdnError("Error in Fetching Data");}
  }
    useEffect(async()=>{
      if(storeInfo.mrsForIdn !== null)
      {
        var selMrs = storeInfo.mrsForIdn;
        var paramsCopy = Object.assign(idnParams);
        paramsCopy= update(paramsCopy,{
          mrsNo : {inputType:{$set: "text"} ,value: {$set: selMrs.requestNo},disabled : {$set: true} },
          mrsReqDate: {value: {$set : selMrs.orderDate} },
          mrsExpectedDate: {value: {$set : selMrs.expectDate} },
        });
        setIdnParams(paramsCopy);
        var lineParamsCopy = Object.assign(lineItems);
        lineParamsCopy = selMrs.lineItems;
        setLineItems(lineParamsCopy); 
      }
    },[idnParams]);
  useEffect(async () => {
    if (idnParams.mrsNo.value !== "" && !(storeInfo.mrsForIdn)) {
      var paramsCopy = Object.assign(idnParams);
      var selMrs; 
      selMrs= optList.filter(
        (i) => { if(idnParams.mrsNo.value === i["requestNo"]) return i["requestNo"][0];
      });
      paramsCopy = update(paramsCopy, {
        mrsReqDate: { value: { $set: selMrs[0].orderDate } },
        mrsExpectedDate: { value: { $set: selMrs[0].expectDate } },
      });
      setIdnParams(paramsCopy);
      var lineParamsCopy = Object.assign(lineItems);
      lineParamsCopy = selMrs[0].lineItems;
      setLineItems(lineParamsCopy);
    }
  }, [idnParams.mrsNo]);

  const updateLineItems = (param, index, value) => {
    var updatedList = lineItems.slice();

    updatedList = update(updatedList, {  [index]: { [param]: { $set: value } } });
    setLineItems(updatedList);
  };

  const updateIdn = ({section, param, key, value}) => {
    if (section === "general") {
      var paramsCopy = Object.assign(idnParams);
      paramsCopy = update(paramsCopy, { [param] : { [key]: { $set: value } } });
      setIdnParams(paramsCopy);
    }
  };
  const renderFormElements = ({ elementList, param, section }) => {
    return elementList.map((element) => {
      return (
        <FormElement
          key={element}
          inputType={param[element].inputType}
          value={param[element].value !== null ? param[element].value : ""}
          setInput={(value) => {  updateIdn({ section, param: element, key: "value", value }); }}

          hintText={param[element].hintText}
          mandatory={param[element].mandatory}
          colSpan={param[element].colSpan}
          options={
            param[element].inputType === "options"
              ? param[element].options
              : null
          }
          error={param[element].error}
          rowSpan={
            element === "vendorLogo" || element === "otherBusinessDetails"
              ? param[element].rowSpan
              : null
          }
          placeholder={param[element].placeholder}
          title={param[element].title}
          disabled={param[element].disabled}
          mindate={param[element].mindate}
        />
      );
    });
  };
  const checkErrors = () => {
    var errorList = [];
    var paramsCopy = Object.assign(idnParams);
    idnParamsList.forEach((item) => {
      if (paramsCopy[item].mandatory)
        paramsCopy = update(paramsCopy, {
          [item]: {
            error: {
              $set: !validateMandatory(paramsCopy[item].value.toString()),
            },
          },
        });
      if (paramsCopy[item].error) {
        errorList.push(paramsCopy[item].errorMessage);
      }
    });
    var lineItemsCopy = lineItems.slice();
    let lineItemAllClear = true;
    lineItemsCopy.forEach((item) => {
        if (!item["idnQuantity"] || isNaN(item["idnQuantity"]) || parseInt(item["idnQuantity"]) < 0 || parseInt(item["idnQuantity"]) > parseInt(item["quantity"]) ) {
          item.idnQuantityError = true;
          lineItemAllClear = false;
          item.idnQuantityErrorMessage="Provide Valid Idn quantity and must be lesser than MRS quantity";
          errorList.push(item.idnQuantityErrorMessage);
        } else {
          item.idnQuantityError = false;
          item.toSend = true;
        }
      
    });
    if (errorList.length === 0) {
      var data2Server = {
        mrsNo: idnParams.mrsNo.value,
        mrsReqDate: idnParams.mrsReqDate.value,
        mrsExpectedDate: idnParams.mrsExpectedDate.value,
        idnDate: idnParams.idnDate.value,
        instruction: instruction,
      };

      var itemsToServer = [];
      lineItemsCopy.forEach((item) => {
        if (item.toSend) {
          var newItem = {
            itemId: item.itemId,
            uomId: item.uomId,
            mrsQuantity: item.quantity,
            idnQuantity: item.idnQuantity,
          };
          itemsToServer.push(newItem);
        }
      });
      if (itemsToServer.length > 0) {
        data2Server.lineItems = itemsToServer;
        submitData(data2Server);
      } else {
        errorList.push("Provide IDN quantity for atleast one item");
      }
    }

    setLineItems(lineItemsCopy);
    setIdnError(errorList);
    setIdnParams(paramsCopy);
  };

  const renderErrorMessage = () => {
    if (idnError.length > 0) return idnError[0];
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [modalText, setModalText] = useState("Creating IDN. Please wait...");

  const submitOkClick = () => {
    setIsOpen(false);
    if (idnError.length === 0) {
      setSection("IdnList");
    }
  };

  const submitData = async (data) => {
    setIsOpen(true);
    setModalText("Creating IDN. Please wait...!");

    var result = await fetchData({
      requestingPage: "createIdn",
      url: "idn-create/request",
      method: "post",
      headers: { token: isLogged.accessToken,module:"IDN" },
      data: data,
    });
    setDataSubmitted(true);
    if (result.msg === "success") {
      setModalText("IDN created successfully!");
    } else {
      setIdnError([result.desc]);
      setModalText(result.desc);
     
    }
  };

  return ( 
    <React.Fragment>
      <CreateEditModal modalIsOpen={modalIsOpen} modalText={modalText} dataSubmitted={dataSubmitted} submitOkClick={submitOkClick} />
      <div className="formArea">
        <div
          style={{
            width: "1000px",
            margin: "0 auto 2rem",
            padding: "2rem",
            border: "1px solid lightgray",
            borderRadius: "5px",
            backgroundColor: "white",
          }}>
          <div style={{ display: "flex" }}>
            <div
              className="createPurchaseOrderGrid"
              style={{ gridTemplateRows: "repeat(1, 4rem)" }}>
              {renderFormElements({
                elementList: idnParamsList,
                param: idnParams,
                section: "general",
              })}
            </div>
          </div>
          {lineItems.length > 0 ? (
            <table
              className="createItemPurchaseTable"
              style={{ margin: "2rem auto" }}>
              <thead>
                <tr className="createVendorContactsTableHeader">
                  <td className="stickyFirstColumn">Item </td>
                  <td>Item Code</td>
                  {isLogged.showHideWeight ? <>          <td>Weight From(gms)</td>
                  <td>Weight To(gms)</td>
                  <td>Net Weight(gms)</td>
                  <td>Gross Weight(gms)</td> </> : null}
                  <td>UOM</td>
                  <td>MRS Qty</td>
                  <td>IDN Qty</td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((row, j) => (
                  <tr className="createVendorContactsTableRows" key={j}>
                    {lineItemsParamsList.map((col, i) => {
                      switch (col) {
                        case "idnQuantity":
                          return (
                            <td key={i} style={{ width: "0px" }}>
                              <input
                                className={
                                  lineItems[j].idnQuantityError
                                    ? "createPurchaseCostInputError"
                                    : "createPurchaseCostInput"
                                }
                                type="text"
                                value={row[col]}
                                onChange={(e) =>
                                  updateLineItems("idnQuantity",j,e.target.value)
                                }
                              />
                            </td>
                          );
                          case "itemName" : 
                          return(
                            <td key={i} className='stickyFirstColumn'>{row[col]}</td>
                          )
                        default:
                          return <td key={i}>{row[col]  ? row[col] : "-"}</td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

          <div className="poNetTotalSurchargeDiv">
            <div className="idnInstruction">
              <div style={{ marginTop: "10px" }}>
                Internal Delivery Note Instruction
              </div>
              <textarea
                className="multilineInput"
                value={instruction}
                rows="6"
                cols="30"
                name="text"
                placeholder="Enter your notes"
                style={{
                  padding: "10px",
                  resize: "none",
                  marginTop: "10px",
                  width:"400px",
                  height: "114px",
                  fontFamily: "sans-serif",
                }}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <p className="formSubmitErrorTextArea">{renderErrorMessage()}</p>
          <button
            className="submitButton"
            onClick={() => {
              checkErrors();
            }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    storeInfo: state.storeInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addTokenToState: (accessToken, employeeId) =>
      dispatch(addToken(accessToken, employeeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdnCreate);

